
          <template>

<v-card flat tile class="transparent">
<v-breadcrumbs class="white" :items="items" divider="-"></v-breadcrumbs>
</v-card>
</template>

<script>
import router from '@/router/index.js'
import { setTimeout } from 'timers'

export default {
props: ['CurrentRecordName'],
    data() {
        return{
          items: [],
          defaultitems: [],
        }
    },


    watch:{
        '$route' (){
            this.GetNavigation()
        }
    },

    created() {
        this.GetNavigation()
    },
    methods: {

       GetNavigation: function (){
           console.log('it changed')

           this.items = Object.assign([],this.defaultitems)
          setTimeout(() => {

        let checkingurl = this.$route.path
         console.log(checkingurl)
           if(checkingurl === '/BusinessUnits'){
               let NewItem = {
                   text: 'BusinessUnits',
                    disabled: false,
                    to: '/BusinessUnits',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/BusinessUnit/'+this.$route.params.id) {


            let NewItem = {
                   text: 'BusinessUnits',
                    disabled: false,
                    to: '/BusinessUnits',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/BusinessUnit/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }
           if(checkingurl === '/CurrentCustomers'){
               let NewItem = {
                   text: 'CurrentCustomers',
                    disabled: false,
                    to: '/CurrentCustomers',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/CurrentCustomer/'+this.$route.params.id) {


            let NewItem = {
                   text: 'CurrentCustomers',
                    disabled: false,
                    to: '/CurrentCustomers',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/CurrentCustomer/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }
           if(checkingurl === '/CustomerClaims'){
               let NewItem = {
                   text: 'CustomerClaims',
                    disabled: false,
                    to: '/CustomerClaims',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/CustomerClaim/'+this.$route.params.id) {


            let NewItem = {
                   text: 'CustomerClaims',
                    disabled: false,
                    to: '/CustomerClaims',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/CustomerClaim/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }
           if(checkingurl === '/Dealerships'){
               let NewItem = {
                   text: 'Dealerships',
                    disabled: false,
                    to: '/Dealerships',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/Dealership/'+this.$route.params.id) {


            let NewItem = {
                   text: 'Dealerships',
                    disabled: false,
                    to: '/Dealerships',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/Dealership/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }
           if(checkingurl === '/SalesContracts'){
               let NewItem = {
                   text: 'SalesContracts',
                    disabled: false,
                    to: '/SalesContracts',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/SalesContract/'+this.$route.params.id) {


            let NewItem = {
                   text: 'SalesContracts',
                    disabled: false,
                    to: '/SalesContracts',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/SalesContract/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }
           if(checkingurl === '/ServiceJobs'){
               let NewItem = {
                   text: 'ServiceJobs',
                    disabled: false,
                    to: '/ServiceJobs',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/ServiceJob/'+this.$route.params.id) {


            let NewItem = {
                   text: 'ServiceJobs',
                    disabled: false,
                    to: '/ServiceJobs',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/ServiceJob/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }else if(checkingurl === '/Users'){
               let NewItem = {
                   text: 'Users',
                    disabled: false,
                    to: '/Users',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/User/'+this.$route.params.id) {


            let NewItem = {
                   text: 'Users',
                    disabled: false,
                    to: '/Users',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/User/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }

        }, 500)
       }
       }
    }

</script>

<style>
.fixed {
position: fixed;
}
</style>
    