
<template>
<v-card  :flat="NoticeSelect" :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent stickytopconscious' : 'transparent'" width="100%" height="100%">
    
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            src="@/assets/RABaseBG.jpeg"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">GP Combrink Vehicle Sales & Finance</h1>
                <h4 class="headline">Articles</h4>
              </v-col>
            </v-row>
            </v-parallax>
 <v-layout class="justify-center" v-if="!NoticeSelect"> 
    <v-card flat class="transparent" width="90%">
    <v-layout class="justify-end">
    <v-btn outlined color="accent"  :to="$route.name !== 'GroupSingle' ? '/NewArticle' : '/Group/NewArticle/'+$route.params.id" target="_blank" v-if="UserModerator || CanCreate"><v-icon>mdi-plus</v-icon>New Article</v-btn>
    </v-layout>
   
<v-list v-if="!NoticeSelect">
     <v-list-item  v-for="article in articlesDated" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.description">
                </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="$route.name !== 'GroupSingle' ? '/Article/'+article.id : '/Group-Article/'+$route.params.id+'/Article/'+article.id">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>

    </v-card>
    </v-layout>
    <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="articlesDated" item-text="title" v-model="selectedSocialItem" return-object label="Article"/>
</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['CanCreate','NoticeSelect'],
    components: {
    
        },
    data() {
        return {
          InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
           UserRecord: {},
            ArticleTitle: '',
            NewArticleDialog: false,
            Forums: [],
          NewCommentInput: '',
          NewReplyInput: '',
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
            articles: [],
            selectedcoverimage: '',
            coverimagelocalurl: '',
            SiteModerators: [],
            ArticleModerator: false
        }
        
    },

    created(){
      console.log('will check UserRecord.',this.UserRecord)
        this.IntranetViewToggle()
        document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
       
      this.GetRequestingUser()


    },

    watch: {
      UserRecord (value) {
        if (value !== null && value !== undefined) {
          console.log('has UserRecord')
          this.UsersModeration()
        }
      }
    // WritingPost (value) {
    //   if (value !== null && value !== undefined && value === true) {
    //     // this.inputwidth = '90%'
    //     // let postelmnt = document.getElementById('postinput')
    //     setTimeout(() => {
    //         this.postelmnt.focus()
    //       }, 100); 

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
  },

    computed: {
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserModerator(){
        if(this.userLoggedIn.ModerationPermissions){
          let match = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Articles')
          if(match){
            return true
          }
          else{
            return this.userIsAdmin
          }
        }
        else{
          return this.userIsAdmin
        }
      },
      articlesDated(){
        return this.articles.map(article => {
          if(article.pubDate){
            article.pubDate = this.TimestampFormatterSTRINGDayText(article.PublishDate.toDate())
          }
          
      
          return article
        })
      },
      
      PostsTimeline () {
        return this.Posts.map(post => {          
          if(typeof post.createdon.toDate !== 'undefined'){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        }).slice().reverse()
      },

    },

    methods: {
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.authorid = userdetails.id

              vm.author = userdetails.Name+' '+userdetails.Surname
              
              console.log('this is user name man '+vm.author)
            })
        }
      })
      },
      UsersModeration(){
        let vm = this
        this.GetSiteModerators().then(function(result) {
          if(result){
            console.log('checking if moderator')
            let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
            if(UserModeratorobj){
              if(UserModeratorobj.ModerationPermissions.includes('Articles')){
                vm.ArticleModerator = true
              }
              
            }
           if(vm.$route.name !== 'GroupSingle' && vm.$route.name !== 'GroupNoticeNew'){
              vm.InternalLinkType = 'Article'
              vm.SharedCollection = 'sitearticles'
              vm.LinkComponent = 'SiteArticleShared'
              vm.GetArticles(db.collection('sitearticles'))
            }
            else{
              vm.InternalLinkType = 'Group-Article/'+vm.$route.params.id+'/Article'
              vm.SharedCollection = 'grouparticles'
              vm.LinkComponent = 'GroupArticleShared'
              vm.GetArticles(db.collection('grouparticles').where('groupid','==',vm.$route.params.id))
            }
          }
        })
      },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        db.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
        IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
        onarticlecoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        GetArticles(col){
        col.where('PublishStatus','==','Published (Internal)').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })

          col.where('PublishStatus','==','Published (Public)').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
          
          col.where('PublishStatus','==','Draft').where('moderatorrolesarrayQuery','array-contains',this.UserRecord.id).onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        
        },
        StoreArticle(){
        let postelmnt = document.getElementById('NewArticleValue')
        console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        let NewArticle = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.ArticleTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          creatorname: this.UserRecord.Full_Name,
          description: postinput,
          createdon: new Date(),
          Comments: [],
        }
        if(this.UserRecord.Profile_Photo){
          NewArticle.creatorimg = this.UserRecord.Profile_Photo
        }
        let vm = this
        this.UpdateArticleCoverImage(this.selectedcoverimage).then(function(result) {
            if(result){
                NewArticle.coverimage = result
               db.collection('grouparticles').add(NewArticle)
               console.log(NewArticle.post)
                vm.CancelNewArticleDialog()
            }          
          })  
       
        
      
        },

        UpdateArticleCoverImage(picturefile){
      let vm = this
      return new Promise(function(resolve, reject) {
      var storageRef = firebase.storage().ref('Group/' + 'ArticleCover/'+picturefile.name);
      var uploadTask = storageRef.put(picturefile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          
          
          resolve(url)
              })
                
      })
    },
        CancelNewArticleDialog(){
            this.ArticleTitle = ''
            this.NewArticleDialog = false
        },
        ActivateNewArticleDialog(){
            this.NewArticleDialog = true
            this.postelmnt = document.getElementById("NewArticleValue")  
            setTimeout(() => {
                document.getElementById("NewArticleValue").addEventListener("paste", function(e) {
                    e.preventDefault();
                    if (e.clipboardData && e.clipboardData.getData) {
                        var text = e.clipboardData.getData("text/plain");
                        document.execCommand("insertHTML", false, text);
                    } else if (window.clipboardData && window.clipboardData.getData) {
                        var text = window.clipboardData.getData("Text");
                        insertTextAtCursor(text);
                    }
                    })
            }, 100);        
          
        },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },      
        
    },
}

</script>

<style>

</style>          
    
         
    