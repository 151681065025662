<template>

<v-main class="grey lighten-3 fill-height">
    <v-btn @click="GetHello()">Hello</v-btn>
<v-btn @click="GetLinkedIn()">LinkedIn</v-btn>
<a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_liteprofile&client_id=77csklbgwo7buu&redirect_uri=%20%20%20https%3A%2F%2Fnene-motors.firebaseapp.com%20">
    <img src="@/assets/LinkedInSignin.png"/>
</a>
Auht Code is {{AuthCode}}
<v-btn @click="SignIn()">Load Data</v-btn>
<v-row class="justify-center">
         <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" v-for="page in PagesArray" :key="page.itemObjKey" >
           <v-card class="mx-4"  round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                <v-card-title class="justify-center" style="align:center;">
            <v-avatar tile size="70" class="transparent mx-3" >
                <v-img contain :src="page.picture.data.url"/>
            </v-avatar>
                </v-card-title>
            </v-card>
            <v-card rounded >
                <v-card-title class="primary white--text justify-end overline">
                     {{page.name}}
                </v-card-title>
                 <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                   {{page.fan_count}}  Followers                   
                </v-card-title>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.newlikecolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-thumb-up-outline
                            </v-icon>
                            <span slot="badge"> {{page.new_like_count}} </span>
                            </v-badge> New Likes                   
                </v-card-subtitle>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.unreadnotifycolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-bell-check
                            </v-icon>
                            <span slot="badge"> {{page.unread_notif_count}} </span>
                            </v-badge> Unread Notifications                   
                </v-card-subtitle>
                <v-divider>
                </v-divider>
                <v-card-actions>
                  
                    <v-spacer>
                    </v-spacer>
               
                         <v-btn class="blue--text" target="_blank" :to="'//www.facebook.com/'+page.name+'-'+page.id">
                           <v-icon color="blue">mdi-facebook</v-icon>View Page
                         </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
      </v-row>
</v-main>
</template>

<script>


export default {
    data(){
        return{
            fooApp: '',
            FollowersCount: '',
            FBprovider: '',
            provideruser: '',
            PagesArray: []
        }
    },
    computed:{
        
    },
    created(){
     
        const queryString = window.location.search;
        console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code')
        console.log(code);
        this.AuthCode = code
        let user = this.$store.state.user
        user.LinkedInToken = this.AuthCode
        this.$store.commit('SetUserStateObj', user)
        this.$router.push('/SocialMediaPlatforms')
    },
    methods:{
      
        }
    
}
</script>

<style>

</style>

    