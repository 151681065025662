
  <template>
  <v-app class="grey lighten-4" :dark="AppisDarkMode">
  <v-dialog max-width="800" v-model="UserInfoRequiredDialog" persistent>
      <v-card>
        <v-card-title>Required Information</v-card-title>
        <v-card-text>
          <v-form id="infoform">
              <v-list>
                <v-list-item v-for="info in UserRequiredInfo" :key="info.itemObjKey">
                  <v-text-field :rules="[rules.required]" :label="info" v-model="UserObj[info]" />
                </v-list-item>
              </v-list>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn>Cancel</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn @click="UpdateRequiredUserInformation()" dark class="green">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PublicNavbar v-if="!LandingPageViewActive && !SignedIn && !FocusedView && !IntranetView && RouterReady"/>
    <IntranetNavbar :FriendsList="FriendsList" :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" 
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" @IntranetViewToggle="IntranetViewToggle"
    @DeactivateSearch="DeactivateSearch" @ActivateSearch="ActivateSearch" :UserRecord="UserRecord"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead"
    :MyActivitiesArray="MyActivitiesArray" :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications"
    v-if="SignedIn && !FocusedView && IntranetView && RouterReady && !UserisGuest"/>
    
    
    <Navbar :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" 
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" 
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty"
    :MyActivitiesArray="MyActivitiesArray"
    v-if="SignedIn && !FocusedView && !IntranetView && RouterReady && !UserisGuest"/>
    <BreadCrumbs style="z-index:2;" :CurrentRecordName="CurrentRecordName" v-if="SignedIn && !FocusedView && !IntranetView && RouterReady && !UserisGuest && $route.name !== 'MyDashboard' && $route.name !== 'DashboardBuilder' && $route.name !== 'myprofile' && $route.name !== 'GroupDashboard'" class="breadcrumbstop" />
    <v-icon color="warning" class="soloactionicon" @click="$router.push('/')" v-if="SignedIn && UserisGuest && $route.name !== 'MyAccount'" style="position: fixed;
    top: 50px;
    left: 20px;background-color:white;
    z-index: 5" x-large>mdi-home</v-icon>
    
   <v-card tile flat :style="BGImageCurrent"  :class="'fullapp '+BGImageClass" height="100%" v-if="!IntranetView && !SearchView && RouterReady">
      <div class="cloudbg" v-if="RendersClouds"
        :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div><div :class="SunorMoon" :style="SunorMoonTexture"  v-if="RendersClouds"/>
      <router-view :style="$route.name === 'MyAccount' ? '' : 'z-index:1;'" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView" @LandingPageView="LandingPageView" :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName"
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList"
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
      @AssignAuth="AssignAuth" :AppisDarkMode="AppisDarkMode" :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @FocusedViewToggle="FocusedViewToggle" 
      class="transparent"
    @ToggleAppDarkMode="ToggleAppDarkMode"></router-view>
    </v-card>
    <v-card tile flat color="background"  style="padding-bottom:180px" class="fullapp"  height="100%"  v-if="IntranetView && !SearchView && RouterReady">
      <router-view :style="$route.name === 'MyAccount' ? '' : 'z-index:1;'" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView"  :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName" :AppisDarkMode="AppisDarkMode" 
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList"
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
      :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @FocusedViewToggle="FocusedViewToggle"></router-view>
    </v-card>

    <AppNotifications :StandbyDialog="StandbyDialog" :UploadingFiles="UploadingFiles" :snackbars="snackbars"
    :UserisGuest="UserisGuest" :Notifications="Notifications" @TriggerDashboardbyNotification="TriggerDashboardbyNotification"
 :UploadingSingleFile="UploadingSingleFile" :AppisDarkMode="AppisDarkMode" :Processing="Processing"
 :snackbar="snackbar" :snackbarmultiLine="snackbarmultiLine" :snackbartimeout="snackbartimeout" :snackbartext="snackbartext" :snackbartop="snackbartop" :snackbarpath="snackbarpath" 
 @CloseSnackbar="CloseSnackbar"/>   
    <SearchComponent  class="footermargin" :UserRecord="UserRecord" v-if="SearchView" :ActiveSearch="ActiveSearch" />
    
    <PublicFooterComponent v-if="PublicFooterEngage"/>
    <v-footer
      :dark="AppisDarkMode"
      fixed
      app
      height="50px"
    >
      <span><span class="font-weight-light hidden-sm-and-down"> GP Combrink Vehicle Sales & Finance </span><span class="font-weight-light hidden-md-and-up"> GP Combrink V&F </span>© 2021</span><v-spacer></v-spacer>
      <span>Powered by</span><pre> </pre><a href="https://www.rapidapps.co.za/" style="color:white" target="_blank" class="txtlink">Rapid<span class="font-weight-light">Apps™</span></a>
    </v-footer>
  </v-app>
</template>
<script>
import db from '@/main'
import Navbar from './components/Navbar'
import IntranetNavbar from './components/IntranetNavbar'
import PublicNavbar from './components/PublicNavbar'
import firebase from 'firebase';
import BreadCrumbs from './components/BreadCrumbs'
import SearchComponent from './components/SystemNavigation/SearchComponent'
import AppNotifications from './components/System/AppNotifications'
import PublicFooterComponent from './components/System/PublicFooterComponent'
export default {
  components: { PublicFooterComponent,SearchComponent,Navbar,BreadCrumbs,PublicNavbar,IntranetNavbar,AppNotifications },
  name: 'App',
  data () {
    return {      
      HasPublicFooter: false,
      NotificationObj: '',
      SiteModerators: [],
      MyGoals: [],
      MyGoalsisEmpty: false,
      MyActivitiesArray: [],
      Notifications: [],
      snackbars: [],
      Processing: false,
      UploadingSingleFile: false,
      UploadingFiles: false,
      snackbarmultiLine: true,
      snackbartimeout: 4000,
      snackbarpath: '',
      snackbartext: 'Here is a snackbar',
      snackbartop: true,
      StandbyDialog: false,
      snackbar: false,
  ActiveSession: '',
  CustomRolesDefault: 
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
      BURolesDefault: [
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
      ],
      AppisDarkMode: false,
      SignedIn: true,
      FocusedView: false,
      IntranetView: false,
      UserRecord: {},
      SearchView: false,
      RouterReady: false,
      CurrentRecordName: '',
      LandingPageViewActive: false,
      carddarkcolor: 'grey darken-4',
      cardlightcolor: 'white',
      UserInfoRequiredDialog: false,
       UserObj: '',
       rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },

    }
  },
  watch: {
      userLoggedIn (value) {
        if (value !== null && value !== undefined) {
         //this.CheckAuth()
         this.UserObj = Object.assign({},value)
        }
      },
    UserInfoRequired (value) {
        if (value !== null && value !== undefined) {
         this.UserInfoRequiredDialog = true
        }
      }
    },
  computed: {
    PublicFooterEngage(){
      return this.HasPublicFooter && !this.userLoggedIn && this.RouterReady && this.$route.name !== 'login' && this.$route.name !== 'PhoneLogin' && this.$route.name !== 'signup' && this.$route.name !== 'UserInvite'
    },
    UserRequiredInfo(){
    return this.$store.state.UserRequiredInfo
  },
  UserInfoRequired(){
    return this.$store.state.UserInfoRequired
  },
  AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return this.carddarkcolor
        }
        else{
          return this.cardlightcolor
        }
      },
  UnreadNotifications(){
            return this.Notifications.filter(not => {
                return !not.Read || not.Read === false
            })
        },
  UserisGuest(){
    return this.userLoggedIn ? this.userLoggedIn.IsGuestUser : 'undefined' 
    
  },
  FriendsList(){
    // if(this.userLoggedIn && this.userLoggedIn.id){
      return this.UsersStore.map(user => {
          this.FriendshipsStore.map(friendship => {
            console.log(user.id, friendship[user.id])
          if(friendship[user.id]){
            user.IsFriend = true
          }
        })
        return user
    }).filter(user => {
      console.log(user)
      console.log(this.userLoggedIn,user.id)
      return user.id !== this.userLoggedIn.id && user.IsFriend
    })
    // }
    
  },
  FriendshipsStore() {
      return this.$store.getters.getFriendshipsArray
    },
  UsersStore() {
      return this.$store.getters.getUsersArray
    },
  userLoggedIn () {
      return this.$store.getters.user
    },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
  RendersClouds(){
    if(this.$route.name === 'myprofile' || this.$route.name === 'MyAccount' || this.$route.name === 'login' || this.$route.name === 'PhoneLogin' || this.$route.name === 'signup' || this.$route.name === 'UserInvite' || this.$route.name === 'PasswordReset' || this.$route.meta && this.$route.meta.type === 'Data Tables'){
      return true
    }
    else{
      return false
    }
  },
  SunorMoonTexture(){
      var daytimecolor;
        var time = new Date().getHours();
        console.log(time)
        if (time < 10) {
          return  ``
        } 
        else if (time < 18) {
          return  ``
        } else {
          return  ``
        }
        
  
    },
    SunorMoon(){
      var daytimecolor;
        var time = new Date().getHours();
        console.log(time)
        if (time < 10 && !this.userLoggedIn) {
          return  'MorningSun'
        } 
        else if (time < 18 && !this.userLoggedIn) {
          return  'MiddaySun'
        } else if(!this.userLoggedIn) {
          return  'Moon'
        }
        
  
    },
    BGImageClass(){
      // if(!this.userLoggedIn){
      //   return 'BGImage'
      // }
      // else 
      if(this.$route.params.id){      
        return 'BGImage footermargin relative'
      }
      else if(this.$route.name === 'MyDashboard'){
        return 'BGImage relative'
      }
      else{
        var daytimecolor;
        var time = new Date().getHours();
        console.log(time)
        if (time < 10) {
          daytimecolor = "morning";
        } 
        else if (time < 18) {
          daytimecolor = "blue"
        } else {
          daytimecolor = 'night'
        }  
        if(!this.userLoggedIn){     
          return 'BGImage relative '+daytimecolor
        }
        else{
          return 'BGImage footermargin relative '+daytimecolor
        }
      }
    },


    BGImageCurrent(){
      if(this.$route.params.id || this.IntranetView){
        if(this.AppisDarkMode){
       return { backgroundImage: 'url(' + require('@/assets/RASolidA.jpeg') + ')' ,backgroundSize: 'cover'}
        }
        else{
          return { backgroundImage: 'url(' + require('@/assets/RASolidA-Light.jpeg') + ')' ,backgroundSize: 'cover'}
        }
      }
      else{
        return ''
      }
    }
  },
  created(){
    //If not website only, then auth matters
    this.CheckAuth()
    window.addEventListener('scroll', this.RecordSlider)
    window.addEventListener('scroll', this.handleScrollRecordToolbar)
    //<!-- window.onload = this.RecordBannerParallax() -->
  },
  methods: {
    UpdateRequiredUserInformation(){
      this.UserRequiredInfo.map(info => {
        db.collection('users').doc(this.UserObj.id).update({
          [info]: this.UserObj[info]
        })
      })
      this.UserInfoRequiredDialog = false
    },
    TriggerDashboardbyNotification(payload){
      this.NotificationObj = payload
      console.log(this.NotificationObj)
      if(this.UserisGuest && this.$route.name !== 'MyAccount'){
       this.$router.push('/MyAccount') 
       setTimeout(() => {
         this.NotificationObj = ''
       }, 300);
      }
      else if(!this.UserisGuest && this.$route.name !== 'MyDashboard'){
        this.$router.push('/MyDashboard') 
        setTimeout(() => {
         this.NotificationObj = ''
       }, 300);
      }
      
    },
    ActivateNotificationSnackbar(boolean,snackbarcontent){
      // this.snackbar = boolean
      if(boolean === true){
        this.snackbars.push(snackbarcontent)
      }
    },
    ActivateSnackbar(boolean,snackbarcontent){
      this.snackbar = boolean
      if(boolean === true){
        this.snackbartimeout = snackbarcontent.snackbartimeout
        this.snackbartext = snackbarcontent.snackbartext
        this.snackbartop = snackbarcontent.snackbartop
        this.snackbarpath = snackbarcontent.snackbarpath
        this.snackbarmultiLine = snackbarcontent.snackbarmultiLine
        let timeout = 4000
        if(this.snackbartimeout){
          timeout = this.snackbartimeout
        }
        setTimeout(() => {
        this.snackbartimeout = 4000
        this.snackbartext = ''
        this.snackbartop = true
        this.snackbarpath = ''
        this.snackbarmultiLine = true
        this.snackbar = false
        }, timeout);
      }
      
    },
    ActivateStandbyDialog(boolean){
      this.StandbyDialog = boolean
    },
    ActivateProcessing(boolean){
      this.Processing = boolean
    },
    ActivateUploadingSingleFileNotification(boolean){
      this.UploadingSingleFile = boolean
    },
    ActivateMultipleFilesUploadNotification(boolean){
      this.UploadingFiles = boolean
    },
    CloseSnackbar(){
      this.ActivateSnackbar(false)
    },
    LandingPageView(boolean){
      this.LandingPageViewActive = boolean
    },
    RecordSlider(event) {
      var invertedenvlopetopright = document.getElementById("invertedenvlopetopright");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopright){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopright.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetoprightforwards')){
            console.log('already has this class')
          }
          else{
            invertedenvlopetopright.setAttribute('class','secondary invertedenvlopetopright invertedenvlopetoprightforwards')
          }
          
        }
        else {
          invertedenvlopetopright.setAttribute('class','secondary invertedenvlopetopright invertedenvlopetoprightbackwards');
        }
      }
      var invertedenvlopetopleft = document.getElementById("invertedenvlopetopleft");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopleft){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopleft.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetopleftforwards')){
            console.log('already has this class')
          }
          else{
            invertedenvlopetopleft.setAttribute('class','secondary invertedenvlopetopleft invertedenvlopetopleftforwards')
          }
          
        }
        else {
          invertedenvlopetopleft.setAttribute('class','secondary invertedenvlopetopleft invertedenvlopetopleftbackwards');
        }
      }
      var invertedenvlopetopcenter = document.getElementById("invertedenvlopetopcenter");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopcenter){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopcenter.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetopcenterforwards')){
            console.log('already has this class')
          }
          else{
            invertedenvlopetopcenter.setAttribute('class','white invertedenvlopetopcenter invertedenvlopetopcenterforwards')
          }
          
        }
        else {
          invertedenvlopetopcenter.setAttribute('class','white invertedenvlopetopcenter invertedenvlopetopcenterbackwards');
        }
      } 
    },
    PushRecordName(name){
      console.log('pushing recordname',name)
      this.CurrentRecordName = name
    },
    ToggleAppDarkMode(){
  if(this.AppisDarkMode){
    this.AppisDarkMode = false
  
  }
  else{
    this.AppisDarkMode = true
  }
},
    ActivateSearch(search){
      console.log('yep I activate search view')
      console.log(search)
      this.SearchView = true
      this.ActiveSearch = search
      console.log(this.SearchView)
      console.log(this.ActiveSearch)
    },
    DeactivateSearch(){
      console.log('yep I deactivate or try to')
      this.SearchView = false
      this.ActiveSearch = ''
    },
    FocusedViewToggle(boolean){
        this.FocusedView = boolean    
    },
    IntranetViewToggle(boolean,tointranet){       
        if(this.IntranetView === true && boolean === false && this.userLoggedIn){
           this.IntranetView = boolean 
           if(this.UserisGuest){
             this.$router.push('/MyAccount')
           }
           else{
             this.$router.push('/MyProfile')
           }
         
       }  
       else if(this.IntranetView === false && boolean === true && this.userLoggedIn){
         this.IntranetView = boolean
         if(tointranet){
           this.$router.push('/Intranet')
         }
         
       } 
    },
    RecordBannerParallax(){
      setTimeout(() => {
      var recordbanner = document.getElementById("recordbanner");
      var recordparallax = document.getElementById("recordparallax");
      console.log('recordbanner',recordbanner)
      console.log('recordparallax',recordparallax)
      if(!recordbanner && recordparallax){
          recordparallax.style.top = '110px'
        }
        }, 500);
    },
    handleScrollRecordToolbar(event) {
      var recordtoolbar = document.getElementById("recordtoolbar");
      var recordtoolbar2 = document.getElementById("recordtoolbar2");
      var recordownershipbanner1 = document.getElementById("recordownershipbanner1");
      var recordownershipbanner2 = document.getElementById("recordownershipbanner2");
      let logobar = document.getElementById('logobar')
      let belowlogobar = document.getElementById('belowlogobar')
      let socialsitequicknav = document.getElementById('socialsitequicknav')
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        if(logobar && belowlogobar && this.IntranetView){
          logobar.style.display = 'none'
          belowlogobar.style.top = '50px'
          if(socialsitequicknav){
            socialsitequicknav.style.top = '115px'
          }
        }
        if(recordtoolbar){
        recordtoolbar.style.top = '50px'
        }
        if(recordtoolbar2){
        recordtoolbar2.style.top = '65px'
        }
        if(recordownershipbanner1){
        recordownershipbanner1.style.top = '110px'
        }
        if(recordownershipbanner2){
        recordownershipbanner2.style.top = '110px'
        }
      }
      else {
        if(logobar && belowlogobar && this.IntranetView){
          logobar.style.display = 'block'
          belowlogobar.style.top = "160px"
          if(socialsitequicknav){
            socialsitequicknav.style.top = '225px'
          }
        }
        if(recordtoolbar){
        recordtoolbar.style.top = '100px'
        }
        if(recordtoolbar2){
        recordtoolbar2.style.top = '110px'
        }
        if(recordownershipbanner1){
        recordownershipbanner1.style.top = '160px'
        }
        if(recordownershipbanner2){
        recordownershipbanner2.style.top = '160px'
        }
      }
    },
    //Basically, if not a website only, then this applies
    async CheckAuth(){
      let vm = this
    await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          console.log(user)
          
          vm.$store.commit('SetUserStateObj', user)
           user.getIdToken().then(function(idToken) {
            vm.$store.commit('SetUserIdToken', idToken)
          });
          vm.AssignAuth(user)
          console.log('seeing signed in')
        }
        else{
          //vm.$router.push('/Home')
          vm.SignedIn = false
          vm.RouterReady = true
          console.log('seeing as NOT signed in')
        }
    })
  },
  
  GetUserNotifications(query,timeout){
    //alert('yay')
    this.Notifications = []
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {         
          let notedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.Notifications.find(obj => obj.id === notedoc)
          if(!oncheck){
            this.Notifications.push(notedoc)
          }
          console.log(notedoc.id,notedoc.Read,notedoc.Message,notedoc.Content)
          console.log(notedoc.Read === false)
          if(notedoc.Read === false){            
            // this.focusednotificationssnackbarcontent.title = notedoc.Message
            // this.focusednotificationssnackbarcontent.body = notedoc.Content
            // this.focusednotificationssnackbar = true
            let snackbarcontent = {
              timeout: timeout,
              text: notedoc.Content,
              title: notedoc.Message,
              show: true,
              IsNotification: true,
              notificationid: notedoc.id,
              thumbnail: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FEAeK2ov547ZbO55ZJ6ok%2Flogo?alt=media&token=e148d98b-a440-4881-840a-fa79c954f21e'
            }
            if(notedoc.fileurl){
              snackbarcontent.Route = notedoc.fileurl
              snackbarcontent.Target = '_blank'
              snackbarcontent.isHref = true
            }
            else{
              snackbarcontent.Route = notedoc.Path
              snackbarcontent.Target = 'self'
            }
            this.snackbars.push(snackbarcontent)
          }          
        }
         if (change.type === 'modified') {
           let notedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let notobj = this.Notifications.find(obj => obj.id === change.doc.id)
          let notindex = this.Notifications.indexOf(notobj)
          this.Notifications.splice(notindex,1,notedoc)
        }
      })
    })
    },
    NotificationRead(notification){
      if(notification.Read === false){
       db.collection('notifications').doc(notification.id).update({
        Read: true
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = true
      this.$router.push(notification.Path)

      }

    },
  
    MarkasUnread(notification){
      if(notification.Read === true && notification.Type === 'Task Created'){
       db.collection('notifications').doc(notification.id).update({
        Read: false
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = false


      }
    },
    SignoutNotifications(){
    this.Notifications = []
  },
  GetSiteModerators(){
        db.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          
        })
      },
  AssignAuth(user){
      let vm = this
    console.log('assigning auth')
         let payload = {                
        query: db.collection('users'),
        arraymutation: 'setUsersArray',
        getter: 'getUsersArray',
            arrayname: 'Users'
      }
    let friendhipspayload = {                
        query: db.collection('friendships').where(user.uid,'==',true),
        arraymutation: 'setFriendshipsArray',
        getter: 'getFriendshipsArray',
            arrayname: 'Friendships'
      }
          vm.$store.dispatch('SetUserObj',user.uid).then(function(result) {
          if(result){
            let Session = localStorage.getItem('activesession')
            if (Session === null) {
              vm.ActiveSession = new Date()
              localStorage.setItem('activesession', vm.ActiveSession);
            }
            else{
              vm.ActiveSession = Session
            }
            console.log(result)
            vm.$store.dispatch('SetUserBUObj',result.Business_Unitid).then(function(BUResult) {
              let userroles = vm.userLoggedIn.rolesarrayQuery
              if(vm.UserisGuest){             
                vm.SignedIn = true
                vm.RouterReady = true      
                if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                 
                    vm.$router.push('/MyAccount')                    
                }       
                 let notificationsquery = db.collection('notifications').where('Ownerid', '==', vm.userLoggedIn.id)
                    vm.GetUserNotifications(notificationsquery,10000)
                    if(!vm.FriendshipsStore || vm.FriendshipsStore && vm.FriendshipsStore.length === 0){
                      vm.$store.dispatch('GetCollectionArray',friendhipspayload)
                    }
                     if(vm.UsersStore.length === 0){
                      vm.$store.dispatch('GetCollectionArray',payload)
                    }
              }
              else{
                vm.ProcessBalance(BUResult,user,vm,friendhipspayload,payload)                
              }
              
          })
          }
          
        })
      
    },  
    ProcessBalance(BUResult,user,vm,friendhipspayload,payload){
    
      if(BUResult){
                vm.GetSiteModerators()
                vm.GetUserAccess()
                vm.RouterReady = true
                vm.SignedIn = true
                if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                    vm.$router.push('/MyProfile')
                }
                let onlineobj = {
                  status: 'online',
                  uid: user.uid
                }
                if(!vm.FriendshipsStore || vm.FriendshipsStore && vm.FriendshipsStore.length === 0){
                  vm.$store.dispatch('GetCollectionArray',friendhipspayload)
                }
                if(vm.UsersStore.length === 0){
                  vm.$store.dispatch('GetCollectionArray',payload)
                }
                
                vm.$store.dispatch('UserLoginStatus',onlineobj)
                let notificationsquery = db.collection('notifications').where('Ownerid', '==', vm.userLoggedIn.id)
                vm.GetUserNotifications(notificationsquery,10000)
                let activitiesquery = db.collection('activities').where('Ownerid', '==', vm.userLoggedIn.id)
                vm.GetUserActivities(activitiesquery)
                //GetMyTeamActivities - would actually return my activities as well mind you... 
                //usersubordinates().hasAny([RecordOwner()])
                // getUserRoleData().SubordinateArrayDBrules.keys()
                let goalsquery = db.collection('goals').where('UserListIDs', 'array-contains', vm.userLoggedIn.id)
                vm.GetUserGoals(goalsquery)
              }
    },
  
    GetUserGoals(query){
      let vm = this
      query.onSnapshot(res => {
        let listlength = res.docs.length
        if(listlength === 0){
          this.MyGoalsisEmpty = true
        }
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let obj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          obj.Targets = []
          this.GetGoalTargets(obj).then(result => {
              console.log(obj,result)
              vm.MyGoals.push(obj)
          })
        }
      })
    })
    },
    GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').doc(vm.userLoggedIn.id).onSnapshot(snapshot => {
                  let targetobj = snapshot.data()
                  let targetslength = targetobj.Targets.length
                  targetobj.Targets.map((target,i) => {
                  obj.Targets.push(target)                  
                  //console.log(targetslength,i-1+2)
                      if(i-1+2 === targetslength){                             
                          resolve('Got all targets for ',obj.id)  
                      }
                    })  
                })
            })

        },
    GetUserActivities(query){
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
    }, 
    GetUserAccess(Business_Unitid){
      let vm = this
      let routes = vm.$router.options.routes
        
        let userroles = vm.userLoggedIn.rolesarrayQuery
       
        routes.map(route => {
          if(route.Security && route.Security.SecurityType === 'Custom Roles'){
            // console.log('this one is custom roles')
            this.CustomRolesDefault.map(defrole => {
              // console.log(defrole)
              //   console.log(route.Security[defrole.Name])
              if(route.Security[defrole.Boolean]){
                
                vm.CustomRolesCheck(route.Security[defrole.Name],userroles).then(PermissionLevel => {
                  if(PermissionLevel === 1){
                  let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }  
                        // console.log(payload)              
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                  }
                }) 
              }
              else{
                let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        } 
                        // console.log(payload)               
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
              } 
              if(defrole.Action === 'List'){
                console.log('this one is List for custom roles',route,defrole,userroles,vm.userIsAdmin)
                if(route.Security[defrole.Boolean]){
                  // console.log(defrole.Boolean)
                  vm.CustomRolesCheck(route.Security[defrole.Name],userroles).then(PermissionLevel => {
                  if(PermissionLevel === 1){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name)
                    }
                    // console.log(payload)
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  })
                }
                else{
                  let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name)
                    }
                    // console.log(payload)
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                }
              }             
            })
            
          }
          else if(route.Security && route.Security.SecurityType === 'Business Unit'){
            let RoleObject = {}
            let length = this.BURolesDefault.length
         
            this.BURolesDefault.map((defrole,i) => {
              RoleObject[defrole.Name] = route.Security[defrole.Name]
              // console.log(defrole.Name,RoleObject[defrole.Name])
              if(defrole.Action !== 'List'){
                
                // 
                vm.BURolesCheck(RoleObject,defrole,userroles).then(PermissionsObject => {
                  // console.log(PermissionsObject)
                  if(defrole.PermissionLevel === PermissionsObject.PermissionLevel){
                      let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+defrole.Boolean,
                        boolean: true
                      }
                      // console.log(payload)
                      vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                  }                  
                })
              }              
              if(i-1+2 === length){
                
                vm.BUListRolesCheck(RoleObject,userroles).then(PermissionsObject => {
                  if(PermissionsObject.PermissionLevel === 4){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name)
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  
                  else if(PermissionsObject.PermissionLevel === 3){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where('Business_Unitid', 'in', vm.userBUChildren)
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(PermissionsObject.PermissionLevel === 2){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where('Business_Unitid', '==', vm.userBUID)
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(PermissionsObject.PermissionLevel === 1){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where('Ownerid', '==', this.userLoggedIn.id)
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
              })
              }
            })
              
          }
          else if(route.Security && route.Security.SecurityType === 'Signed In'){
            let payload = {
                      collectionname: route.name.split(' ').join('').toLowerCase(),
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name.split(' ').join('').toLowerCase())
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
          }
          else if(route.Security && route.Security.SecurityType === 'Business Unit'){

          }
        })
    },
    CustomRolesCheck(Roles,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        // console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        UserRoles.map((role,i) => {
          // console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest || vm.userIsAdmin || vm.userIsEagleViewer){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
    BURolesCheck(RoleObject,defrole,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        return new Promise(function(resolve, reject) {
        UserRoles.map((role,i) => {
          let roletest = RoleObject[defrole.Name].find(obj => obj == role)
           
          if(typeof roletest !== 'undefined' || vm.userIsAdmin){
              PermissionLevel = defrole.PermissionLevel
          }
         
            if(i-1+2 === Roleslength){
              
              let PermissionsObject = {
                PermissionLevel: PermissionLevel,
              }
              // console.log('Roleslength done',PermissionsObject)
              resolve(PermissionsObject)
            }
          })
        })
      }, 
    BUListRolesCheck(RoleObject,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        UserRoles.map((role,i) => {
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
           
          if(typeof alltest !== 'undefined' || vm.userIsAdmin || vm.userIsEagleViewer){
              PermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(PermissionLevel<3){
              PermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(PermissionLevel<2){
              PermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(PermissionLevel<1){
              PermissionLevel = 1
            }          
          }
            if(i-1+2 === Roleslength){
              let PermissionsObject = {
                PermissionLevel: PermissionLevel,
              }
              resolve(PermissionsObject)
            }
          })
        })
      }, 



  
  }
}
</script>
<style>
.IntranetHeader{
  color: greenyellow  ;
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));
  border-top: 5px solid greenyellow;
}
.v-application a {
  color: #FFA200 !important;
}
.dbnavlist .v-list .v-list-item--active{
    color: #FFA200 !important;
}
.relative{
  position: relative;
}
.cloudbg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0.1;
  }
  
}
.stdparallaxgradient{
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));
}
.stdparallaxgradientinvert{
  background-image: linear-gradient(to right bottom, rgb(248, 239, 191), rgb(0,132,255, 1));
}
.announcementpulse{
  animation: announcepulse 2s infinite;
}
@keyframes announcepulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(230, 196, 5, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(211, 197, 3, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}

}
.BGImage{
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.centertext {
text-align:center;
}
.slightmargin{
  padding: 25px;
}
.elevatedsectionheader{
  z-index: 2;
  box-shadow: 0 2px 8px 0 rgb(100, 100, 100);
}
.overlayed{
  margin-top: -60px;
}
.stickytopconscious{
  top: 160px
}
.publicnav{
  top: 100px
}
.recordparallax{
  top: 160px;
}
.recordparallax2{
  top: 50px;
}
.pagetop{
  top: 50px;
}
.breadcrumbstop{
  top: 50px;
  z-index: 1;
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 10px;
  z-index: 4
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 4
}
.stickytopbanner2{
  position: fixed;
  top: 100px;
  z-index: 4
}
.stickytopbanner3{
  position: fixed;
  top: 160px;
  z-index: 4
}
.stickytopbanner4{
  position: fixed;
  top: 220px;
  z-index: 4
}
.leftpadded{
   right: 40px;
}
.footermargin{  
 
  padding-bottom: 150px;
}
.bottomspace{
  padding-bottom: 40px;
}
.focusedviewborder{
  border: solid;
  border-width: 50px;
}
.incompletedmandatory{
  border: red;
  color: white;
  font-size: 20px;
  animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
   appearance: none;

  width: 250px;
  height: 20px;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress[value]::-webkit-progress-value {
  background:
	   -webkit-linear-gradient(-45deg, 
	                           transparent 33%, rgba(0, 0, 0, .1) 33%, 
	                           rgba(0,0, 0, .1) 66%, transparent 66%),
	   -webkit-linear-gradient(top, 
	                           rgba(255, 255, 255, .25), 
	                           rgba(0, 0, 0, .25)),
	   -webkit-linear-gradient(left, #FFCDD2, #E53935);

    border-radius: 2px; 
    background-size: 35px 20px, 100% 100%, 100% 100%;
    -webkit-animation: animate-stripes 5s linear infinite;
        animation: animate-stripes 5s linear infinite;
}
@-webkit-keyframes animate-stripes {
   0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes animate-stripes {
   0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
h2{
  color: black;
}
.fixedquicknav{
  position: fixed;
  top: 160px;
}
.txtlink {
    color: rgb(253, 253, 253);
    text-decoration: none;
    font-size: 16px;
}
.rmgradient {
  background-image: linear-gradient(to BOTTOM, #468FBE ,#FFCDD2);
}
.foldertab {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px; 
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: #8f91a1;
  
}
.paddedtab {
  padding: 10px;
  background-image: linear-gradient(to bottom, #8f91a1 ,white);
  
}


.folderyellow{
    clip-path: polygon(0 0, 30% 0, 34% 30%, 100% 30%, 100% 100%, 0 100%);
}
.documenttypeshadow{
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.documenttypeshadowinvert{
    filter: drop-shadow(1px -3px 3px rgba(50, 50, 0, 0.2));
}
.documenttypeshadowinvertcolor{
    filter: drop-shadow(1px -3px 3px rgba(255, 255, 253, 0.2));
}
/* .stripe-4 {
  color: white;
  background: repeating-linear-gradient(
    -55deg,
    #FFA000,
    #FFA000 10px,
    #FF8F00 10px,
    #FF8F00 20px
  ); */
  .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.articleoverline {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 500;
  font-size:  1.36em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.articlecaption {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 500;
  font-size:  0.68em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.largeoverline {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 500;
  font-size:  1.7000000000000002em;
  line-height: 1.0; 
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}

.mediumoverline {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 300;
  font-size:  1.02em;
  letter-spacing: 0.1666666667;

}
.justaboveoverline {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 300;
  font-size:  0.8160000000000001em;
  letter-spacing: 0.1666666667;

}
.navoverline {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 300;
  font-size:  0.68em;
  letter-spacing: 0.1666666667;

}
.v-application {
  font-family: "Maven Pro", Tahoma , sans-serif !important;
  line-height: 1.5;
}
.v-application .display-4 {
  font-size:  4.08rem !important;
  font-weight: 300;
  line-height: 6rem;
  letter-spacing: -0.015625em !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .display-3 {
  font-size:  2.5500000000000003rem !important;
  font-weight: 300;
  line-height: 3.75rem;
  letter-spacing: -0.0083333333em !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .display-2 {
  font-size:  2.04rem !important;
  font-weight: 400;
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .display-1 {
  font-size:  1.445rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .headline {
  font-size:  1.02rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .title {
  font-size:  0.8500000000000001rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .v-card__title {
  word-break: normal !important;
}
.v-application .subtitle-2 {
  font-size:  0.5950000000000001rem !important;
  font-weight: 500;
  letter-spacing: 0.0071428571em !important;
  line-height: 1.375rem;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .subtitle-1 {
  font-size:  0.68rem !important;
  font-weight: normal;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-application .body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: "Maven Pro", Tahoma , sans-serif !important;
}
.v-application .body-1 {
  font-size: 1rem !important;
  font-weight: 400;
  letter-spacing: 0.03125em !important;
  line-height: 1.5rem;
  font-family: "Maven Pro", Tahoma , sans-serif !important;
}
.v-data-table{
  font-family: "Maven Pro", Tahoma ,  sans-serif;
  color:#757575;
}
.v-data-table .text-start{
  font-family: "Maven Pro", Tahoma ,  sans-serif;
  font-size: 0.85rem !important;
  color:#757575;
}
.v-data-table__mobile-row__header{
  font-size: 0.51rem !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  font-family: "Michroma", Tahoma , sans-serif !important;
}
.v-data-table__mobile-row{
  font-family: "Maven Pro", Tahoma ,  sans-serif;
  font-size: 1rem !important;
  color:#757575;
}
.v-application .caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-family: "Maven Pro", Tahoma , sans-serif !important;
}
.v-application .overline {
  font-size: 0.75rem !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: "Maven Pro", Tahoma , sans-serif !important;
}

/* .subtleoverline {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 300;
  font-size:  0.7480000000000001em;
  letter-spacing: 0.1666666667;

} */
.subtleoverline {
  font-size:  0.51em;
  font-weight: 300;
  letter-spacing: 0.1666666667em !important;
  font-family: "Michroma", Tahoma , sans-serif !important;
}

.listoutline{
  border-bottom: 0.5px solid #3498db;
}
i.v-icon.actionicon {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactionicon {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.MorningSun {
  position: absolute;
  top: 160px;
  height: 105px;
  width: 105px;
  background-color: rgba(243, 230, 108,0.95);
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
.MiddaySun {
  position: absolute;
  top: 160px;
  height: 110px;
  width: 110px;
  background-color: #f5e16d;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px #f5ec6d, 0 0 20px rgba(245, 236, 109,0.5), 0 0 30px #f3e66c, 0 0 40px rgba(245, 236, 109,0.5), 0 0 50px rgba(245, 236, 109,0.5), 0 0 60px rgba(245, 236, 109,0.5), 0 0 70px rgba(245, 236, 109,0.5);
  }
  
  to {
    box-shadow: 0 0 20px #f5ec6d, 0 0 30px rgba(243, 218, 108, 0.5), 0 0 40px rgba(243, 218, 108, 0.5), 0 0 50px rgba(243, 218, 108, 0.5), 0 0 60px rgba(243, 218, 108, 0.5), 0 0 70px rgba(243, 218, 108, 0.5), 0 0 80px rgba(243, 218, 108, 0.5);
  }
}
.Moon {
  position: absolute;
  top: 160px;
  height: 100px;
  width: 100px;
  background-color: rgba(238, 237, 234);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 1px 4px 15px rgb(241, 241, 217);
}
.FormFields{
  font-family: 'Segoe UI', Tahoma, Georgia, Verdana, sans-serif;
  color:#757575;
}
.intranetnavlinks {
  font-family: "Michroma", Tahoma , sans-serif;
  font-weight: 500;
  font-size:  8.16px;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
  
}
.topcut{
  margin-top: -150px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.topcutsml{
  margin-top: -300px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.topimg{
  margin-bottom: 300px;
}
.invertedenvlopetopcenter{
 margin-top: -300px;
 z-index: -1  ;
}
.invertedenvlopetopcenterforwards{
  animation: invertedenvlopetopcenteranim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetopcenterbackwards{
  animation: invertedenvlopetopcenteranimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetopcenteranim {
  0% {
   margin-top: -300px;
  }



  100% {
   margin-top: -500px;
    
  }
}
@keyframes invertedenvlopetopcenteranimrev {
  0% {
    margin-top: -500px;
  }



  100% {
   margin-top: -300px;
    
  }
}
.envelopeheaderforwards{
  animation: envelopeheaderanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.envelopeheaderbackwards{
  animation: envelopeheaderanimrev 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes envelopeheaderanim {
  0% {
    margin-left: 0px;
  }



  100% {
   margin-top: 200px;
    
  }
}
@keyframes envelopeheaderanimrev {
  0% {
    margin-left: 200px;
  }



  100% {
   margin-top: 0px;
    
  }
}
.invertedenvlopetopleft{
 
  clip-path: polygon(0 0, 25% 0, 0 100%);
}
.invertedenvlopetopleftforwards{
  animation: invertedenvlopetopleftanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetopleftbackwards{
  animation: invertedenvlopetopleftanimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetopleftanim {
  0% {
    clip-path: polygon(0 0, 25% 0, 0 100%);
  }



  100% {
   
    clip-path: polygon(0 0, 5% 0, 0 100%);
  }
}
@keyframes invertedenvlopetopleftanimrev {
  0% {
    clip-path: polygon(0 0, 5% 0, 0 100%);
  }



  100% {
   
    clip-path: polygon(0 0, 25% 0, 0 100%);
  }
}

.invertedenvlopetopright {
 background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));

 clip-path: polygon(100% 0, 75% 0, 100% 100%);
}
.invertedenvlopetoprightforwards{
  animation: invertedenvlopetoprightanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetoprightbackwards{
  animation: invertedenvlopetoprightanimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetoprightanim {
  0% {
    clip-path: polygon(100% 0, 75% 0, 100% 100%);
  }



  100% {
   
    clip-path: polygon(100% 0, 95% 0, 100% 100%);
  }
}
@keyframes invertedenvlopetoprightanimrev {
  0% {
    clip-path: polygon(100% 0, 95% 0, 100% 100%);
  }



  100% {
   
    clip-path: polygon(100% 0, 75% 0, 100% 100%);
  }
}

/* .invertedenvlopetopleft{
  clip-path: polygon(0 0, 50% 0, 0 100%);
} */
.tag-wrap {
 
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.inverted{
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  
}
.straight {
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.parallaxbgcontentgrad {
  background-image: linear-gradient(to right, rgba(1,0,15,0.835171568627451), rgba(9,46,54,0.47942927170868344));
}
.chat-container {
  position: fixed;
  bottom: 60px;
  left: 50px;
  z-index: 5
}
.dashboard-straighten-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 5
}
.dashboard-edit-container{
  position: fixed;
  bottom: 60px;
  right: 110px;
  z-index: 5
}
.dashboard-filters-container{
  position: fixed;
  bottom: 120px;
  right: 50px;
  z-index: 5
}
.dashboard-colors-container{
  position: fixed;
  bottom: 120px;
  right: 110px;
  z-index: 5
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #3498db;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
.basepulse {
  color: red;
  animation: basepulseanim 2s;
  animation-iteration-count: infinite
}
@keyframes basepulseanim {
	0% {
    opacity: 0.5;
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(252, 2, 2, 0.7);
	}

	50% {
    opacity: 1.0;
		transform: scale(2);
    color: 0 0 0 10px rgba(189, 14, 14, 0.568);
	}

	100% {
    opacity: 0.5;
		transform: scale(0.95);
    color: 0 0 0 0 rgba(173, 44, 44, 0.274);
	}
}
.detailslistoutline:nth-child(odd) {
  background: rgb(238, 238, 238);
}
.carouseloutline:nth-child(odd) {
  background: rgb(255, 255, 255);
}
.carouseloutline:nth-child(even) {
  background: rgb(0, 0, 0);
}
.carouseloutline :hover{
  background-color: rgba(168, 167, 167, 0.4);
}
.TaggedField{
 
    font-weight: bold;
    text-decoration: underline;
    height: 15px;
    padding-top: 1px;
    padding-right: 2px;
    padding-left: 2px;
    overflow: hidden;
    margin-bottom: 0px;
    vertical-align: middle;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value{
    font-size: 24px;
    color: rgb(0, 0, 0);
    line-height: 18px;
    margin-bottom: 6px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title{
      font-size: 12px;
    font-weight: bold;
    color: gray;
}
.fullapp {
    max-width: 100%;
    overflow: hidden;
}
.createnotice-container {
  position: fixed;
  top: 240px;
  right: 50px;
  z-index: 100
}
.whitelabel .v-input__slot{
  background-color: white!important;
} 
</style>
    