
<template>
<v-row
    class="fill-height"
    align="top"
    justify="center"
     style="padding-top: 170px"
    >
        <v-card flat width="100%">
            <v-card-title class="primary--text">
                Results for {{ActiveSearch}}...
            </v-card-title>
            <v-card-subtitle class="pop--text">
                {{FinalResultsString}}
            </v-card-subtitle>
            <v-card-text>
                <v-layout  row class="justify-center">
                <v-flex class="my-3 mx-3" xs12 sm6 md4 lg3 v-for="result in FinalResults" :key="result.itemObjKey">
                    <v-card class="secondary"  max-width="360px">
                        <v-card-title class="mediumoverline primary white--text">
                            {{result.Origin}}
                            <v-spacer></v-spacer>
                            <v-avatar
                            color="pop"
                            size="50" 
                            class="elevatedsectionheader"                     
                            >
                            <v-icon x-large color="white">{{result.Icon}}</v-icon>
                        </v-avatar>
                        </v-card-title>

                 
                   <v-layout class="justify-center overlayed borderthicknobottom mx-3">
                        <v-card class="transparent" tile flat height="80px" width="100%">
                        </v-card>
                        </v-layout>
                        <v-layout class="justify-center borderthicknotop mx-3">
                       <v-list  dark width="100%" tile>
                            <v-list-item v-for="single in result.Array.slice(0,1)" :key="single.itemObjKey">
                                <v-list-item-content>
                                {{result.Prop.toUpperCase()}}
                                </v-list-item-content>
                                <v-list-item-content class="justify-end" v-if="single.Path">
                                   RECORD
                                </v-list-item-content>
                                 <v-list-item-content v-if="single.WikiPath" class="justify-end">
                                   PAGE
                                </v-list-item-content>
                                <v-list-item-content v-if="single.URLPath" class="justify-end">
                                   URL
                                </v-list-item-content>
                            </v-list-item>
                    </v-list>
                    </v-layout>
                        <v-layout class="justify-center borderthicknotop mx-3">                            
                        <v-list dense class="transparent my-1" width="100%" tile>
                            <v-list-item  v-for="single in result.Array" :key="single.itemObjKey" class="basicoutlined">
                                <v-list-item-content>
                                {{single[result.Prop]}}
                                </v-list-item-content>
                                <v-list-item-avatar  v-if="single.Path">
                                    <v-btn fab icon :href="single.Path"><v-icon>mdi-database</v-icon></v-btn>
                                </v-list-item-avatar>
                                 <v-list-item-avatar v-if="single.WikiPath">
                                    <v-btn fab icon :href="single.WikiPath"><v-icon>mdi-wikipedia</v-icon></v-btn>
                                 </v-list-item-avatar>
                                 <v-list-item-avatar v-if="single.URLPath">
                                    <v-btn fab icon :href="single.URLPath" target="_blank"><v-icon>mdi-file-link-outline</v-icon></v-btn>
                                 </v-list-item-avatar>
                            </v-list-item>
                        </v-list>
                        </v-layout>
                    </v-card>
                </v-flex>
                </v-layout>

                                     
            </v-card-text>
        </v-card>
</v-row>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import router from '@/router/index.js'
import { setTimeout } from 'timers'

export default {
    props: ['ActiveSearch'],
    data() {
        return{
        siteblogsresult: [],
        siteclassifiedsresult: [],
        sitegroupsresult: [],
        siteeventsresult: [],
        siteforumsresult: [],
        sitearticlesresult: [],
        businessunitsresult: [],
        currentcustomersresult: [],
        customerclaimsresult: [],
        dealershipsresult: [],
        salescontractsresult: [],
        servicejobsresult: [],
        usersresult: [],
           items: [
        {
          text: 'Welcome',
          disabled: false,
          href: '/Welcome',
        },
      ],
      defaultitems: [
        {
          text: 'Welcome',
          disabled: false,
          href: '/Welcome',
        },
      ],
        }
    },

    computed: {
        FinalResultsString(){
            let firststring =  this.FinalResultsArray.length
            let secondstring = ''
            if(this.FinalResultsArray.length>1){
                secondstring = ' results'
            }
            else if(this.FinalResultsArray.length===1){
                secondstring = ' result'
            }
            else if(this.FinalResultsArray.length===0){
                secondstring = ' results'
            }
            secondstring = secondstring+' found in '
            let thirdstring = this.FinalResults.length
            let fourthstring = ''
            if(this.FinalResults.length>1){
                fourthstring = ' places'
            }
            else if(this.FinalResults.length===1){
                fourthstring = ' place'
            }
            else if(this.FinalResults.length===0){
                fourthstring = ' places'
            }
            let finalstring = firststring+secondstring+thirdstring+fourthstring
            return finalstring
        },
        FinalResultsArray(){
            return this.FinalResults.map(result => {
                return result.Array
            }).flat()
        },
        FinalResults(){
            let arr = []
            if(this.sitearticlesresult.length > 0){
                let SiteArticleObj = {
                    Social: true,
                    Origin: 'Articles',
                    Icon: 'mdi-blogger',
                    Prop: 'title',
                    Array: this.sitearticlesresult.map(result => {
                        result.WikiPath = '/Article/'+result.id
                        return result
                    })
                }
                
                arr.push(SiteArticleObj)
            }
            if(this.siteblogsresult.length > 0){
                let SiteBlogObj = {
                    Social: true,
                    Origin: 'Blogs',
                    Icon: 'mdi-blogger',
                    Prop: 'title',
                    Array: this.siteblogsresult.map(result => {
                        result.WikiPath = '/Blog/'+result.id
                        return result
                    })
                }
                
                arr.push(SiteBlogObj)
            }
            
            if(this.sitegroupsresult.length > 0){
                let SiteGroupObj = {
                    Social: true,
                    Origin: 'Groups',
                    Icon: 'mdi-groups',
                    Prop: 'name',
                    Array: this.sitegroupsresult.map(result => {
                        result.WikiPath = '/Group/'+result.id
                        return result
                    })
                }
                
                arr.push(SiteGroupObj)
            }
            if(this.siteclassifiedsresult.length > 0){
                let SiteClasfdObj = {
                    Social: true,
                    Origin: 'Classifieds',
                    Icon: 'mdi-certificate-outline',
                    Prop: 'Title',
                    Array: this.siteclassifiedsresult.map(result => {
                        result.WikiPath = '/Classified/'+result.id
                        return result
                    })
                }
                
                arr.push(SiteClasfdObj)
            }
            
            if(this.siteeventsresult.length > 0){
                let SiteEventObj = {
                    Social: true,
                    Origin: 'Events',
                    Icon: 'mdi-calendar',
                    Prop: 'name',
                    Array: this.siteeventsresult.map(result => {
                        result.WikiPath = '/Event/'+result.id
                        return result
                    })
                }
               
                arr.push(SiteEventObj)
            }
            if(this.siteforumsresult.length > 0){
                let SiteforumObj = {
                    Social: true,
                    Origin: 'SiteForum',
                    Icon: 'mdi-forum-outline',
                    Prop: 'name',
                    Array: this.siteforumsresult.map(result => {
                        result.WikiPath = '/Forum/'+result.id
                        return result
                    })
                }
               
                arr.push(SiteforumObj)
            }
            
            
            if(this.businessunitsresult.length > 0){
                let BusinessUnitObj = {
                    Origin: 'BusinessUnits',
                    Icon: 'mdi-security-network',
                    Prop: 'Name',
                    Array: this.businessunitsresult.map(result => {
                        result.Path = '/BusinessUnit/'+result.id
                        return result
                    })
                }
                //console.log('BusinessUnitObj',BusinessUnitObj)
                arr.push(BusinessUnitObj)
            }
            if(this.currentcustomersresult.length > 0){
                let CurrentCustomerObj = {
                    Origin: 'CurrentCustomers',
                    Icon: 'mdi-tag-faces',
                    Prop: 'Name',
                    Array: this.currentcustomersresult.map(result => {
                        result.Path = '/CurrentCustomer/'+result.id
                        result.WikiPath = '/CurrentCustomer-Wiki/'+result.Name.split(' ').join('-')
                        return result
                    })
                }
                //console.log('CurrentCustomerObj',CurrentCustomerObj)
                arr.push(CurrentCustomerObj)
            }
            if(this.customerclaimsresult.length > 0){
                let CustomerClaimObj = {
                    Origin: 'CustomerClaims',
                    Icon: 'mdi-shield-car',
                    Prop: 'Title',
                    Array: this.customerclaimsresult.map(result => {
                        result.Path = '/CustomerClaim/'+result.id
                        result.WikiPath = '/CustomerClaim-Wiki/'+result.Title.split(' ').join('-')
                        return result
                    })
                }
                //console.log('CustomerClaimObj',CustomerClaimObj)
                arr.push(CustomerClaimObj)
            }
            if(this.dealershipsresult.length > 0){
                let DealershipObj = {
                    Origin: 'Dealerships',
                    Icon: 'mdi-car-multiple',
                    Prop: 'Dealer_Name',
                    Array: this.dealershipsresult.map(result => {
                        result.Path = '/Dealership/'+result.id
                        return result
                    })
                }
                //console.log('DealershipObj',DealershipObj)
                arr.push(DealershipObj)
            }
            if(this.salescontractsresult.length > 0){
                let SalesContractObj = {
                    Origin: 'SalesContracts',
                    Icon: 'mdi-bank',
                    Prop: 'Name',
                    Array: this.salescontractsresult.map(result => {
                        result.Path = '/SalesContract/'+result.id
                        result.WikiPath = '/SalesContract-Wiki/'+result.Name.split(' ').join('-')
                        return result
                    })
                }
                //console.log('SalesContractObj',SalesContractObj)
                arr.push(SalesContractObj)
            }
            if(this.servicejobsresult.length > 0){
                let ServiceJobObj = {
                    Origin: 'ServiceJobs',
                    Icon: 'mdi-tools',
                    Prop: 'Job_Number',
                    Array: this.servicejobsresult.map(result => {
                        result.Path = '/ServiceJob/'+result.id
                        return result
                    })
                }
                //console.log('ServiceJobObj',ServiceJobObj)
                arr.push(ServiceJobObj)
            }
            if(this.usersresult.length > 0){
                let UserObj = {
                    Origin: 'Users',
                    Icon: 'mdi-face',
                    Prop: 'Full_Name',
                    Array: this.usersresult.map(result => {
                        result.Path = '/User/'+result.id
                        result.WikiPath = '/SiteMember/'+result.id
                        return result
                    })
                }
                //console.log('UserObj',UserObj)
                arr.push(UserObj)
            }
            return arr
        },
    },


    watch:{
        'ActiveSearch' (){
            this.GetNavigation()
        }
    },

    created() {
        this.GetNavigation()
    },
    methods: {

       GetNavigation: function (){
           console.log('it changed')

           this.items = Object.assign([],this.defaultitems)
          setTimeout(() => {

        let checkingurl = this.$route.path
        this.siteclassifiedsresult = []
        db.collection('classifieds').where('TitleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteclassifiedsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        
        this.siteforumsresult = []
          // db.collection('siteforumdiscussions').where('titleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
        //             const changes = res.docChanges();
        //             changes.forEach(change => {
        //                 if (change.type === 'added') {
        //                 this.siteforumsresult.push({
        //                     ...change.doc.data(),
        //                     id: change.doc.id
        //                 })
        //                 }


        //             })
        //             })
                    db.collection('siteforumdiscussions').where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteforumsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        this.siteeventsresult = []
         db.collection('sitevents').where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteeventsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
                    db.collection('sitevents').where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteeventsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        
        this.sitegroupsresult = []
         db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members').where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.sitegroupsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
            db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public').where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.sitegroupsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
            db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members').where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
        
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.sitegroupsresult.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }


            })
            })
            db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public').where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
        
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.sitegroupsresult.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }


            })
            })
        this.siteblogsresult = []
         db.collection('siteblogs').where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('titleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteblogsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
                    db.collection('siteblogs').where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteblogsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        this.sitearticlesresult = []
         db.collection('sitearticles').where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('titleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.sitearticlesresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
                    db.collection('sitearticles').where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.sitearticlesresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
         console.log(checkingurl)
            if(this.$store.state.BusinessUnitsQuery) {
                this.businessunitsresult = []
                    this.$store.state.BusinessUnitsQuery.where('NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.businessunitsresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.businessunitsresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.CurrentCustomersQuery) {
                this.currentcustomersresult = []
                    this.$store.state.CurrentCustomersQuery.where('NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.currentcustomersresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.currentcustomersresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.CustomerClaimsQuery) {
                this.customerclaimsresult = []
                    this.$store.state.CustomerClaimsQuery.where('TitleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.customerclaimsresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.customerclaimsresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.DealershipsQuery) {
                this.dealershipsresult = []
                    this.$store.state.DealershipsQuery.where('Dealer_NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.dealershipsresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.dealershipsresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.SalesContractsQuery) {
                this.salescontractsresult = []
                    this.$store.state.SalesContractsQuery.where('NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.salescontractsresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.salescontractsresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.ServiceJobsQuery) {
                this.servicejobsresult = []
                    this.$store.state.ServiceJobsQuery.where('Job_NumberQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.servicejobsresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.servicejobsresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.UsersQuery) {
                this.usersresult = []
                    this.$store.state.UsersQuery.where('Full_NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.usersresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.usersresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           

        }, 500)
       }
       }
    }

</script>

<style>
.fixed {
position: fixed;
}
</style>
    