<template>

<v-main class="grey lighten-3 fill-height">
    <v-toolbar color="pink" height="150">        
        <v-list dense v-if="UserLinkedInProfile" max-width="300" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
        <v-list-item-subtitle class="overline">
            Linked In Session
        </v-list-item-subtitle>
            <v-list-item>
            <!-- <v-list-item-content > -->
            <v-list-item-avatar class="blue" tile size="80">
                <img v-if="UserLinkedInProfile.Profile_Photo "
                :src="UserLinkedInProfile.Profile_Photo "    
                style="object-fit: cover;"          
                >
                <img v-if="!UserLinkedInProfile.Profile_Photo "
                src="/assets/BlankProfile_Photo.png"   
                style="object-fit: cover;"           
                >                      
                </v-list-item-avatar>
            <!-- </v-list-item-content> -->
            <v-list-item-content >
                <v-list-item-title>{{UserLinkedInProfile.Full_Name}}</v-list-item-title>                    
                <v-list-item-subtitle>
                <router-link  :to="'//www.linkedin.com'">View My Profile</router-link>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{UserLinkedInProfile.Email}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                Online: Online Now
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list dense v-if="!UserLinkedInProfile">
            <v-list-item-subtitle>
            Linked In
        </v-list-item-subtitle>
        <a :href="ComputedLinkedInSignup">
            <img src="@/assets/LinkedInSignin.png"/>
        </a>
        </v-list>
        <v-list dense v-if="FacebookUser" max-width="300" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
        <v-list-item-subtitle class="overline">
            Facebook Session
        </v-list-item-subtitle>
            <v-list-item>
                
            <!-- <v-list-item-content > -->
            <v-list-item-avatar class="blue" tile size="80">
                <img v-if="FacebookUser.photoURL "
                :src="FacebookUser.photoURL "    
                style="object-fit: cover;"          
                >
                <img v-if="!FacebookUser.photoURL "
                src="/assets/BlankProfile_Photo.png"   
                style="object-fit: cover;"           
                >                      
                </v-list-item-avatar>
            <!-- </v-list-item-content> -->
            <v-list-item-content >
                <v-list-item-title>{{FacebookUser.displayName}}</v-list-item-title>                    
                <v-list-item-subtitle>
                <router-link  :to="'//Facebook'">View My Profile</router-link>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{FacebookUser.email}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                Online: Online Now
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-btn @click="SignIn()">Load Data</v-btn>
            </v-list-item>
        </v-list>
        <v-list dense v-if="!FacebookUser">
            <v-list-item-subtitle>
            Facebook
        </v-list-item-subtitle>
        <v-btn @click="SignIn()">Load Data</v-btn>
        </v-list>
    </v-toolbar>
  


<v-row class="justify-center" style="padding-top:50px;">
         <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" v-for="page in ReadyPagesArray" :key="page.itemObjKey" >
           <v-card class="mx-4"  round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                <v-card-title class="justify-center" style="align:center;">
            <v-avatar tile size="70" class="transparent mx-3" >
                <v-img v-if="page.picture" contain :src="page.picture"/>
            </v-avatar>
                </v-card-title>
            </v-card>
            <v-card rounded >
                <v-card-title class="primary white--text justify-end overline">
                     {{page.name}}
                </v-card-title>
                 <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                   {{page.fan_count}}  Followers                   
                </v-card-title>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.newlikecolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-thumb-up-outline
                            </v-icon>
                            <span slot="badge"> {{page.new_like_count}} </span>
                            </v-badge> New Likes                   
                </v-card-subtitle>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.unreadnotifycolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-bell-check
                            </v-icon>
                            <span slot="badge"> {{page.unread_notif_count}} </span>
                            </v-badge> Unread Notifications                   
                </v-card-subtitle>
                <v-divider>
                </v-divider>
                <v-card-actions>
                  
                    <v-spacer>
                    </v-spacer>
               
                         <v-btn class="blue--text" target="_blank" :to="page.location">
                           <v-icon color="blue">{{page.providericon}}</v-icon>View Page
                         </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
      </v-row>
</v-main>
</template>

<script>
import firebase from 'firebase';
import axios from 'axios'
import db from '@/main'
import vueappfbid from '@/main' 
import FB from 'fb'; 

export default {
    data(){
        return{
            Today: '',
            LinkedInOrganizations: [],
            UserLinkedInProfile: '',
            fooApp: '',
            FollowersCount: '',
            FBprovider: '',
            FacebookUser: '',
            PagesArray: [],
            LinkedInTokenString: "AQSpYr_wv621WatJ-SoMfQXzN6s5rbmf0S42bQHP3Jd9YeCpDjAgMLLT2agG2xLsQd1IsrD-nAUIKl4kCeJZpSUn9JIKvyVECgKWgkA48_JIV-SxIs3uATZwsl4e8CCXLrb4AVf2ttdQ1s4HHuBZxMmoCkQHk_dnamRUvsxaNhXzf-USmr0K4a-YwuVYm2IFBDSpfllF7Tv9smrLILI"
        }
    },
    computed:{
        ComputedLinkedInSignup(){
            let protocol = window.location.protocol.split(':')[0]
            let domain = window.location.hostname
            console.log(protocol)
             if(window.location.port){
                 domain = domain+':'+window.location.port
             } 
            return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_liteprofile%20r_emailaddress%20r_organization_social%20r_1st_connections_size%20rw_organization_admin%20r_basicprofile%20r_ads%20rw_ads%20w_member_social%20w_organization_social%20r_ads_reporting&client_id=77k4r4k3qszurq&redirect_uri="+protocol+"%3A%2F%2F"+domain+"%2FLinkedInAuth"
        },
        ReadyPagesArray(){
            let array1 = this.PagesArray
            .map(page => {
                page.providericon = 'mdi-facebook'
                page.location = '//www.facebook.com/'+page.name+'-'+page.id
                page.picture = page.picture.data.url
                return page
            })
            let array2 = this.LinkedInOrganizations
            .map(item => {
                let obj = {
                    name: item.localizedName,
                    picture: item.picture,
                    fan_count: item.fan_count,
                    newlikecolor: 'red',
                    new_like_count: 20,
                    unreadnotifycolor: 'green',
                    unread_notif_count: 16,
                    providericon: 'mdi-linkedin ',
                    location: '//www.linkedin.com/company/'+item.id+'/admin/'

                }
                return obj
            })
            return array1.concat(array2)
        },
    },
    created(){
        this.Today = new Date()
        console.log(this.$store.state.user)
        this.$store.state.user.getIdToken().then(token => {
            //console.log(token)
            if(this.$store.state.user.LinkedInToken){
                this.LinkedInTokenString = this.$store.state.user.LinkedInToken
                console.log(this.LinkedInTokenString)
                console.log(this.$store.state.user.LinkedInAccessToken)
                if(!this.$store.state.user.LinkedInAccessToken){
                    this.GetLinkedInAccess()
                }
                else{
                    //console.log('already has token here '+this.$store.state.user.LinkedInAccessToken)
                    this.GetMyLinkedInProfile(this.$store.state.user.LinkedInAccessToken)
                }
                
            }
              
            let urldomain = window.location.protocol+'//'+window.location.hostname
            if(window.location.port){
                urldomain = urldomain+':'+window.location.port
            }
            console.log(urldomain)
        })
       
        this.FBprovider = new firebase.auth.FacebookAuthProvider();
        this.fooApp = FB.extend({appId: process.env.VUE_APP_FACEBOOK_APP_ID, appSecret: process.env.VUE_APP_FACEBOOK_APP_SECRET})
       this.FacebookUser = this.$store.state.user.providerData.find(obj => obj.providerId === 'facebook.com')
            console.log(this.$store.state.user) 
            if(this.$store.state.user.FacebookAccessToken){
                this.fooApp.setAccessToken(this.$store.state.user.FacebookAccessToken)
                this.GetFollowers()
            } 
        console.log('vueappfbid',typeof process.env.VUE_APP_FACEBOOK_APP_SECRET)
    },
    methods:{
        
        GetMyLinkedInProfile(token){
            let vm = this
            let days = 7
            let end = new Date(Date.now() - (1 * 24*60*60*1000));
            let startdate = new Date(Date.now() - (days * 24*60*60*1000));
            let start = startdate.getTime()
            console.log(start)  
            let emailobj = {
                  url: 'https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))',
                  code: token
              }            
            let profileobj = {
                  url: 'https://api.linkedin.com/v2/me',
                  code: token
              }
            let pictureobj = {
                  url: 'https://api.linkedin.com/v2/me?projection=(id,profilePicture(displayImage~digitalmediaAsset:playableStreams))',
                  code: token
              }
            let organizationsobj = {
                  url: 'https://api.linkedin.com/v2/organizationAcls?q=roleAssignee&role=ADMINISTRATOR&state=APPROVED&projection=(*,elements*(*,organization~(localizedName),organization~(logoV2),organization~(id),organization~(vanityName),organization~(primaryOrganizationType),organization~(localizedWebsite)))',
                  code: token
              }
            const functions = firebase.functions();
            const GetLinkedInInformation = functions.httpsCallable('GetLinkedInInformation');
           
            console.log('access token from store',token)            
            GetLinkedInInformation(profileobj).then(result => {
              //console.log('Final Result from cloud function',result)
              //console.log('Now going to try get the emadil')
              
              GetLinkedInInformation(emailobj).then(emailresult => {
              //console.log('email Result from cloud function',emailresult)
              //console.log('proper email is '+emailresult.data.elements[0]['handle~'].emailAddress)
              vm.UserLinkedInProfile = {
                  Name: result.data.localizedFirstName,
                  Surname: result.data.localizedLastName,
                  Email: emailresult.data.elements[0]['handle~'].emailAddress,
                  Full_Name: result.data.localizedFirstName+' '+result.data.localizedLastName
              }
              if(result.data.profilePicture){
                  GetLinkedInInformation(pictureobj).then(profpicresult => {
                      //console.log(profpicresult)
                      if(profpicresult && profpicresult.data){
                        let prop = profpicresult.data.profilePicture['displayImage~']
                        let index = prop.elements.length-1
                        let image = prop.elements[index].identifiers[0].identifier
                        //console.log(prop)
                        //console.log(image)
                        if(prop && prop.elements[index] && prop.elements[index].identifiers[0] && prop.elements[index].identifiers[0].identifier){
                            vm.UserLinkedInProfile.Profile_Photo = image
                            //console.log(vm.UserLinkedInProfile)
                        }   
                      }
                      
                  })                
              }
              

               GetLinkedInInformation(organizationsobj).then(organizationsresult => {
                //yconsole.log('sorganizationsresult',organizationsresult)
                
                let array = organizationsresult.data.elements
                //console.log(typeof array,array.length)
                //console.log(typeof array)                
                 array.map(elmnt => {
                     //console.log(elmnt['organization~'])
                    let obj = elmnt['organization~']
                    let logobj = {
                        url: 'https://api.linkedin.com/v2/organizations/'+obj.id+'?projection=(coverPhotoV2(original~:playableStreams,cropped~:playableStreams,cropInfo),logoV2(original~:playableStreams,cropped~:playableStreams,cropInfo))',
                        code: token
                    }
                    GetLinkedInInformation(logobj).then(orglogoresult => {
                        //console.log('orglogoresult',orglogoresult)
                        if(orglogoresult.data.logoV2){
                        let prop = orglogoresult.data.logoV2['original~']
                        let index = prop.elements.length-1
                        let image = prop.elements[index].identifiers[0].identifier
                        //console.log(prop)
                        //console.log(image)
                        obj.picture = image
                        }
                        let followersobj = {
                        url: 'https://api.linkedin.com/v2/networkSizes/urn:li:organization:'+obj.id+'?edgeType=CompanyFollowedByMember',
                        code: token
                        }
                        GetLinkedInInformation(followersobj).then(followersresult => {
                            //console.log('followersresult',followersresult)
                            obj.fan_count = followersresult.data.firstDegreeSize
                            // let date = new Date()   
                            
                                                  
                            let followerstatsobj = {
                                //https://api.linkedin.com/v2/organizationalEntityShareStatistics?q=organizationalEntity&organizationalEntity={organization URN}
                            //url: 'https://api.linkedin.com/v2/organizationalEntityFollowerStatistics?q=organizationalEntity&organizationalEntity=urn:li:organization:'+obj.id+'&timeIntervals=(timeRange:(start:'+start+',end:'+end+'),timeGranularityType:DAY)',
                            url: 'https://api.linkedin.com/v2/organizationalEntityFollowerStatistics?q=organizationalEntity&organizationalEntity=urn:li:organization:'+obj.id,
                            code: token
                        }
                        GetLinkedInInformation(followerstatsobj).then(followerstatsresult => {
                            console.log('followerstatsresult',followerstatsresult)
                            obj.followerbyclass = followerstatsresult.data.elements[0]
                            
                            vm.LinkedInOrganizations.push(obj)
                        })
                        })
                    })
                })   
                
               })
              })
            })
        },
        async GetLinkedInAccess(){
            const functions = firebase.functions();
            const GetLinkedInAccessToken = functions.httpsCallable('GetLinkedInAccessToken');
            // 'https://nene-motors.firebaseapp.com/LinkedInAuth'
            let urldomain = window.location.protocol+'//'+window.location.hostname
            if(window.location.port){
                urldomain = urldomain+':'+window.location.port
            }
            console.log(urldomain)
            let callobj = {
                code: this.LinkedInTokenString,
                state: 987654321,
                redirectUri: urldomain+'/LinkedInAuth',
                }
            console.log(callobj)
            GetLinkedInAccessToken(callobj).then(result => {
              console.log(result)
              if(result.data.access_token){
               let user = this.$store.state.user
                user.LinkedInAccessToken = result.data.access_token
                this.$store.commit('SetUserStateObj', user) 
                console.log('user',user)  
                this.GetMyLinkedInProfile(user.LinkedInAccessToken)
              }
            
            })
       
          
        },
        
        
    SignIn(){
        let vm = this
        firebase
        .auth().currentUser.linkWithPopup(this.FBprovider).then((result) => {
            // Accounts successfully linked.
            var credential = result.credential;
            var user = result.user;
            // ...
            let stateuser = vm.$store.state.user
            stateuser.FacebookAccessToken = credential.accessToken
            vm.$store.commit('SetUserStateObj', stateuser) 
            console.log(user,credential)
            vm.fooApp.setAccessToken(credential.accessToken)            
            }, function(error) {
                console.log(error)
                if(error.code === "auth/provider-already-linked"){
                    
                    //alert(error.message)
                    let storeuser = vm.$store.state.user
                    // console.log()
                    vm.FacebookUser = storeuser.providerData.find(obj => obj.providerId === 'facebook.com')
                    console.log(vm.FacebookUser)                    
                    firebase
                    .auth()
                     .signInWithPopup(vm.FBprovider)
                        .then((result) => {
                            /** @type {firebase.auth.OAuthCredential} */
                            var credential = result.credential;

                            // The signed-in user info.
                            var user = result.user;
                            console.log(user)

                            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                            var accessToken = credential.accessToken;
                            console.log(accessToken)
                            let stateuser = vm.$store.state.user
                            stateuser.FacebookAccessToken = accessToken
                            vm.$store.commit('SetUserStateObj', stateuser) 
                            //console.log(user,credential)
                            vm.fooApp.setAccessToken(accessToken)
                            vm.GetFollowers()

                            // ...
                        })
                        .catch((error) => {
                            console.log(error)
                            // Handle Errors here.
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            // The email of the user's account used.
                            var email = error.email;
                            // The firebase.auth.AuthCredential type that was used.
                            var credential = error.credential;

                            // ...
                        });
                }
                // An error happened.
                });
        
    },
    Unlink(){
        firebase
        .auth().currentUser.unlink('facebook.com').then(() => {
        // Auth provider unlinked from account
        // ...
        }).catch((error) => {
        // An error happened
        // ...
        });
    },
        GetFollowers(){ 
            let vm = this
            //this.SignIn()
            console.log('vm.FacebookUser',vm.FacebookUser)
            let storeuser = vm.$store.state.user
                    // console.log()
                    vm.FacebookUser = storeuser.providerData.find(obj => obj.providerId === 'facebook.com')
                    if(vm.FacebookUser){
                        console.log(vm.FacebookUser)
         
                        vm.fooApp.api('/'+vm.FacebookUser.uid+'/accounts?', 
                            'GET',
                        {"fields":"name,access_token,id,picture,new_like_count,fan_count"
                        }, function (newres) {
                            if(!newres || newres.error) {
                                console.log(!newres ? 'error occurred' : newres.error);
                                return;
                            }
                        
                            //var finalaccessToken = newres.access_token;
                            // vm.fooApp.setAccessToken(accessToken)
                            console.log(newres)
                            vm.PagesArray = newres.data.map(page => {
                            
                                if(page.new_like_count && page.new_like_count>0){
                                    page.newlikecolor = 'pop'
                                }
                                else{
                                    page.newlikecolor = 'grey lighten-3'
                                }
                                let newfbapp = FB.extend({appId: process.env.VUE_APP_FACEBOOK_APP_ID, appSecret: process.env.VUE_APP_FACEBOOK_APP_SECRET})
                                newfbapp.setAccessToken(page.access_token)
                                newfbapp.api(
                                '/'+page.id,
                                'GET',
                                {"fields":"conversations,unread_notif_count",accessToken:page.access_token},
                                function(response) {
                                    page.conversations = response.conversations
                                    page.unread_notif_count = response.unread_notif_count
                                    if(page.unread_notif_count && page.unread_notif_count>0){
                                    page.unreadnotifycolor = 'pop'
                                        }
                                    else{
                                        page.unreadnotifycolor = 'grey lighten-3'
                                    }
                                    

                                    
                                    // Insert your code here
                                    console.log(response)
                                }
                                );
                                return page
                            })
                        });
                    }
                    
        },
       
        }
    
}
</script>

<style>

</style>

    