
<template>
    <v-content>
        <v-dialog transition="fab-transition" v-model="AddNewParentLookupDialog" width="100%">
      <v-layout class="justify-center">
    <v-card width="100%" class="stickytopbanner">
      <v-card-title>
        Add New {{ParentTitle}}
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              Serial Number
            </v-list-item-content>
            <v-list-item-content>
                <v-text-field style="font-size:14px;color:grey;" dense v-model="NewParentPrimary.Value" :label="NewParentPrimary.Name">
                </v-text-field>
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
            <v-list-item-content v-for="field in NewParentAdditional.slice(0,2)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="NewParentAdditional.length>3">
            <v-list-item-content v-for="field in NewParentAdditional.slice(2,4)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
             <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="NewParentAdditional.length>5">
            <v-list-item-content v-for="field in NewParentAdditional.slice(4,6)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="NewParentAdditional.length>7">
            <v-list-item-content v-for="field in NewParentAdditional.slice(6,8)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-layout class="justify-center" style="font-size:12px;color:grey;">
        This will create the {{ParentTitle}} and link it to {{Job_Number}} as a Parent 
      </v-layout>
      <v-card-actions>
        <v-layout class="justify-end">
          <v-btn @click="CancelAddParentDialog()" color="red" dark>Cancel</v-btn><v-btn  color="green" dark @click="AddParentandLink()">Save</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
      </v-layout>
    </v-dialog>
    <v-dialog max-width="800" v-model="ViewExistingLookupDialog">
      <v-card flat width="100%" height="100%">
            <div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
              <v-card-title class="mediumoverline white--text">
                Lookup Records - {{RelatedObj.Link}}
              </v-card-title>
              <v-card-subtitle class="overline white--text">
                Choose below a new Parent {{FieldFilter}} for {{RelatedObj[RelatedObj.identifier]}}
            </v-card-subtitle></div>
        
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item class='listoutline'>
                      <v-list-item-content class="overline">               
                      Record:
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox class="FormFields" v-model="RelatedObj" readonly filled label="Record" autocomplete="off" :items="RelatedObjArray" :item-text="RelatedObj.identifier"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item class='listoutline' v-if="MailRecordFilter !== 'Users'">
                      <v-list-item-content class="overline">               
                      Field:
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-text-field class="FormFields" filled readonly v-model="FieldFilter" label="Field" autocomplete="off"></v-text-field>  
                      </v-list-item-content>              
                   </v-list-item>              
                   
                  </v-list>
                   </v-layout>
                  <v-layout class="justify-center grey lighten-3">
                  <v-card width ="80%" outlined flat>
                    
                    <v-data-table
                      :items-per-page="itemsPerPage"
                      :headers="LookupArrayHeaders"
                      :items="LookupArraySearched"
                      class="elevation-1"
                      show-select
                        single-select
                        v-model="SelectedRecords"
                    >
                      <template v-slot:top>
                    <v-toolbar  flat rounded src="@/assets/RABaseBG.jpeg" elevation="12" color="primary white--text">
                        <v-toolbar-title  class="mediumoverline">{{FieldFilter}} Records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field dark
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>         
                    </v-card>
                </v-layout>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSelection()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="LinkParent()">OK <v-icon>mdi-check</v-icon></v-btn>
                </v-layout>
        </v-card>
      </v-dialog>
    </v-content>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['ParentCollection','ParentTitle','ParentLookup','LookupArrayHeaders','LookupArray','FieldFilter','ViewExistingLookupDialog','RelatedObj','AddNewParentLookupDialog','NewParentPrimary','NewParentAdditional'],
    data() {
        return {
          itemsPerPage: 5,
            RelatedRecordFilter: '',
            
            SelectedRecords: [],
            search: '',
        }
    },

    created(){

    },

    computed: {
        LookupArraySearched(){
            return this.LookupArray.filter(item => {
                if(this.search){
                    console.log(this.search,this.ParentLookup.Prop,item[this.ParentLookup.Prop])
                    return item[this.ParentLookup.Prop].toLowerCase().includes(this.search.toLowerCase())
                }
                else{
                    return item
                }
            })
        }
    },

    methods: {
        LinkParent(){
            let field = this.ParentLookup
            field.Value = this.SelectedRecords[0]
            console.log(this.SelectedRecords[0])
            let ParentObj = {                
            }
            ParentObj[field.LookupFieldName] = {[field.Prop]: field.Value[field.Prop],id: field.Value.id}
            ParentObj[field.Propid] = field.Value.id
            this.$emit('LinkParent',ParentObj)
            this.CancelSelection()
        },
        CancelSelection(){
            this.$emit('CancelSelection')
        },
        CancelAddParentDialog(){
            this.$emit('CancelAddParentDialog')
        },

        AddParentandLink(){
      let ParentObj = {[this.NewParentPrimary.Prop] : this.NewParentPrimary.Value }
      this.NewParentAdditional.map(field => {
        if(field.Type !== 'Lookup'){
        ParentObj[field.Prop] = field.Value
        }
        if(field.Type === 'Lookup'){
        ParentObj[field.LookupFieldName] = {[field.Prop]: field.Value[field.Prop],id: field.Value.id}
        ParentObj[field.Propid] = field.Value.id
        }
      })
      console.log(ParentObj)
      console.log('will save on this',this.RelatedObj)
      // db.collection(this.ParentCollection).add(ParentObj).then(doc => {
      //   db.collection('capitalequipmentjobs').update({
      //     [this.NewParentPrimary.Prop]: ParentObj
      //   })
      // })
    },
    }
}
</script>
<style>
.stickytopbanner{
  position: fixed;
  top: 70px;
  z-index: 100
}
</style>

<style scoped>
.v-data-table{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#757575;
}
</style>

    