
import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import db from '@/main'
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  //plugins: [createPersistedState()],
  state: {
    //<!-- user: {
    //  authenticated: false,
    //  id: null,
    //  Full_Name: null
    //    }, -->
    IsAdmin: false,
    IsEagleViewer: false,
    SiteContentAdmin: false,
    BusinessProfileAdmin: false,
    userobj: null,
    buobj: null,
    LastRoute: null,
    useridtoken: null,
    user: null,
    status: null,
    error: null,
    RMDocumentsArray: [],
    FriendshipsArray: [],
    BusinessUnitsArray: [],
    BusinessUnitsCanCreate: false,
    BusinessUnitsCanView: false,
    BusinessUnitsCanList: false,
    BusinessUnitsCanEdit: false,
    BusinessUnitsCanDelete: false,
    BusinessUnitsQuery: '',
    CurrentCustomersArray: [],
    CurrentCustomersCanCreate: false,
    CurrentCustomersCanView: false,
    CurrentCustomersCanList: false,
    CurrentCustomersCanEdit: false,
    CurrentCustomersCanDelete: false,
    CurrentCustomersQuery: '',
    CustomerClaimsArray: [],
    CustomerClaimsCanCreate: false,
    CustomerClaimsCanView: false,
    CustomerClaimsCanList: false,
    CustomerClaimsCanEdit: false,
    CustomerClaimsCanDelete: false,
    CustomerClaimsQuery: '',
    DealershipsArray: [],
    DealershipsCanCreate: false,
    DealershipsCanView: false,
    DealershipsCanList: false,
    DealershipsCanEdit: false,
    DealershipsCanDelete: false,
    DealershipsQuery: '',
    SalesContractsArray: [],
    SalesContractsCanCreate: false,
    SalesContractsCanView: false,
    SalesContractsCanList: false,
    SalesContractsCanEdit: false,
    SalesContractsCanDelete: false,
    SalesContractsQuery: '',
    ServiceJobsArray: [],
    UsersArray: [],
    UsersCanCreate: false,
    UsersCanView: false,
    UsersCanList: false,
    UsersCanEdit: false,
    UsersCanDelete: false,
    UsersQuery: '',
    UserInfoRequired: false,
    UserRequiredInfo: [],

  },
  mutations: {
    setUserRequiredInfo(state, payload) {
      console.log(payload)
      state.UserRequiredInfo = payload
    },
    setUserInfoRequired (state, payload) {
      console.log(payload)
      state.UserInfoRequired = payload
    },
    setUserAdmin (state, payload) {
      console.log(payload)
      state.IsAdmin = payload
    },
    setUserEagleView (state, payload) {
      console.log(payload)
      state.IsEagleViewer = payload
    },
    setSiteContentAdmin (state, payload) {
      console.log(payload)
      state.SiteContentAdmin = payload
    },
    setBusinessProfileAdmin (state, payload) {
      console.log(payload)
      state.BusinessProfileAdmin = payload
    },
    SetUserStateObj (state, payload) {
      state.user = payload
    },
    SetUserIdToken (state, payload) {
      state.useridtoken = payload
    },
    SetLastRoute(state, payload) {
      console.log(typeof payload.Date.toDate)
      console.log(typeof payload.Date)
      if(typeof payload.toDate !== 'undefined'){
       let d = payload.Date.toDate()  
        var hr = d.getHours();      
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        payload.Date = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min; 
      }
      
      state.LastRoute = payload
    },

    setUserObj (state, payload) {
      console.log(payload)
      state.userobj = payload
    },
    setUserBUObj (state, payload) {
      console.log(payload)
      state.buobj = payload
    },
    setCollectionInteractPermission (state,payload){
      console.log(payload)
      state[payload.queryname] = payload.boolean
    },
    setCollectionQuery (state, payload) {
      console.log(payload)
      state[payload.queryname] = payload.query
    },
    setCollectionArray (state, payload) {
      console.log(payload)
      state[payload.arrayname] = payload.array
    },
    setUser (state, payload) {
      state.user = payload
      // state.user.id = payload.id
      // state.user.BusinessUnit = payload.BusinessUnit
      // state.user.FullName = payload.FirstName+' '+payload.Surname
    },

    removeUser (state) {
      state.user = null
    },

    setStatus (state, payload) {
      state.status = payload
    },

    setError (state, payload) {
      state.error = payload
    },
    //SET_USER (state, user) {
      //state.authUser = user

    //},
   //authUser(state, user) {
      //state.user.id = user.uid
      //state.user.Full_Name = user.Full_Name
      //state.user.authenticated = true
     // console.log(state.user.Full_Name)
    //}, -->

  },
  actions: {
    signInClientAppAction ({ commit }, payload) {
      console.log(payload)
      return new Promise(function(resolve, reject) { 
      let check = firebase.apps.find(obj => obj.name === payload.SystemName) 
      console.log('yes this one',check)    
      if(!check){
        const clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)
        clientApp.auth().signInWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          resolve(response)
         
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      }
      else{
        const clientApp = check  
        clientApp.auth().signInWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          resolve(response)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      }
    })
    },
    UserLoginStatus({ commit }, payload) {
    console.log('will try set status')
      db.collection('users').doc(payload.uid).update({
          onlinestatus: payload.status
        }) 
},
    GetUsersArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      db.collection('users').onSnapshot(res => {
        let arraylength = res.docs.length
        let array = this.getters.getUsersArray
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              let baseobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              baseobj.groups = []
              console.log(baseobj.id)
              // array.push(baseobj)
              
              db.collection('groupmembers').doc(baseobj.id).collection('groups').onSnapshot(groupsres => {
                
                let groupslength = groupsres.docs.length
                console.log(groupslength)
                const groupchanges = groupsres.docChanges();
                groupchanges.forEach(groupchange => {
                    if (groupchange.type === 'added') {
                        baseobj.groups.push({
                          ...groupchange.doc.data(),
                          id: groupchange.doc.id
                        })
                      }
                      console.log(baseobj)
                      console.log(groupslength,baseobj.groups.length)
                      if(baseobj.groups.length === groupslength){
                        array.push(baseobj)
                        console.log('this one '+baseobj.id+' now has all groups')
                      }
                      
                    })
                    if(groupslength === 0){
                      console.log('this one '+baseobj.id+' has NO groups')
                      array.push(baseobj)
                    }
                    let setcollection = {
                      arrayname : 'Users', 
                      array: array
                    }   
                  commit('setCollectionArray', setcollection)  
                  if(array.length === arraylength){
                    resolve('Completed')
                  }          
              })
            }
          })        
          
      })       
    })
  },
    GetCollectionArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      payload.query.onSnapshot(res => {
        let arraylength = res.docs.length
        if(!res.docs){
          resolve('Completed')
          let setcollection = {
            arrayname : payload.arrayname, 
            array: []
          }   
          commit('setCollectionArray', setcollection)
        }
        else{
         let array = []
          if(this.getters[payload.getter]){
            array = this.getters[payload.getter]
          }
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              array.push({
                ...change.doc.data(),
                id: change.doc.id
              })
            }
            if (change.type === 'modified') {
              //console.log('HERE IS A MODIFIED DOC DUDE')
              let arrayobj = array.find(obj => obj.id == change.doc.id)
              let arrayobjindex = array.indexOf(arrayobj)
              //console.log(arrayobj)
              //console.log(arrayobjindex)
              // console.log({
              //   ...change.doc.data(),
              //   id: change.doc.id
              // })
              // array[arrayobjindex] = Object.assign({
              //   ...change.doc.data(),
              //   id: change.doc.id
              // })
              array.splice(arrayobjindex, 1, {
                ...change.doc.data(),
                id: change.doc.id
              });
            }
      
      
          })        
          let setcollection = {
            arrayname : payload.arrayname, 
            array: array
          }   
          commit('setCollectionArray', setcollection)  
          if(array.length === arraylength){
            resolve('Completed')
          } 
        }
                  
      })       
    })
  },
    SetUserObj ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          db.collection('users').doc(payload).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = payload
            console.log(userdata)
             if(!userdata.Full_Name){
               let info = []
                if(this.getters.getRequiredInformation){
                  info = this.getters.getRequiredInformation
                }
                info.push('Full_Name')                          
                commit('setUserRequiredInfo',info)
                commit('setUserInfoRequired', true) 
              }
              db.collection('userroles').doc(payload).onSnapshot(snapshot => {
              let userroles = snapshot.data()
              let queryroles = userroles.rolesarrayQuery
              userdata.rolesarrayQuery = userroles.rolesarrayQuery
              userdata.rolesarrayDBRules = userroles.rolesarrayDBRules
              userdata.Business_Unit = userroles.Business_Unit
              userdata.Business_Unitid = userroles.Business_Unitid
              userdata.IsGuestUser = userroles.IsGuestUser
              userdata.CanInviteUsers = userroles.CanInviteUsers
              if(userdata.CanExport){
              userdata.CanExport = userroles.CanExport
              }
              commit('setSiteContentAdmin', userroles.SiteContentAdmin) 
              commit('setBusinessProfileAdmin', userroles.setBusinessProfileAdmin)
              commit('setUserEagleView', userroles.IsEagleViewer)
              commit('setUserAdmin', userroles.IsSystemAdmin)
              if(userdata.IsSystemAdmin){
                userdata.IsSystemAdmin = userroles.IsSystemAdmin
              }
              if(userdata.IsTeamActivityManager){
              userdata.IsTeamActivityManager = userroles.IsTeamActivityManager
              }
              userdata.SubordinateArrayDBrules = userroles.SubordinateArrayDBrules
              userdata.Subordinatearrayquery = userroles.Subordinatearrayquery
             // let AdminTest = queryroles.find(obj => obj === 'System Admin')   
              // if(typeof AdminTest !== 'undefined'){
              //   commit('setUserAdmin', true)
              // }
              // let EagleTest = queryroles.find(obj => obj === 'Eagle view')   
              //   if(typeof EagleTest !== 'undefined'){
              //     commit('setUserEagleView', true)
              //   }
              db.collection('sitemoderators').doc(userdata.id).onSnapshot(modsnapshot => {
                let moderatordata = modsnapshot.data()
                if(moderatordata && moderatordata.ModerationPermissions){
                  userdata.ModerationPermissions = moderatordata.ModerationPermissions
                  console.log(userdata.id)
                  db.collection('groupmembers').doc(userdata.id).collection('groups').onSnapshot(groupssnapshot => {
                    console.log('getting groupmembers collection',groupssnapshot.docs.length)
                        userdata.groups = []
                        let listlength = groupssnapshot.docs.length
                        if(listlength === 0){
                          commit('setUserObj', userdata)   
                          resolve(userdata)
                        }
                        else{
                          const changes = groupssnapshot.docChanges();
                          changes.forEach(change => {
                            if (change.type === 'added') {
                              userdata.groups.push({
                                ...change.doc.data(),
                                id: change.doc.id
                              })
                            }
                            if(userdata.groups.length === listlength){
                              commit('setUserObj', userdata)   
                              resolve(userdata)
                            }
                          })
                        }
                    })
                   
                }
                else{
                  db.collection('groupmembers').doc(userdata.id).collection('groups').onSnapshot(groupssnapshot => {
                    userdata.groups = []
                    let listlength = groupssnapshot.docs.length
                    if(listlength === 0){
                      commit('setUserObj', userdata)   
                      resolve(userdata)
                    }
                    else{
                      const changes = groupssnapshot.docChanges();
                      changes.forEach(change => {
                        if (change.type === 'added') {
                          userdata.groups.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                        }
                        if(userdata.groups.length === listlength){
                          commit('setUserObj', userdata)   
                          resolve(userdata)
                        }
                      })
                    }
                })
                }
              })         
                 
            })     
          })   
        })
    },
    SetUserBUObj ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          db.collection('businessunits').doc(payload).onSnapshot(snapshot => {
            let budata = snapshot.data()
            budata.id = payload
            console.log(budata)            
            commit('setUserBUObj', budata)    
            resolve('Completed setUserBUObj')        
          })   
        })
    },
    SetUserQueryPermission({ commit },payload) {
        let setcollectionquery = {
          queryname : payload.collectionstate, 
          query: payload.query
        } 
        commit('setCollectionQuery', setcollectionquery) 
      
    },
    SetUserInteractPermission({ commit },payload) {
      let setcollectioninteract = {
        queryname : payload.collectionstate, 
        boolean: payload.boolean
      } 
      commit('setCollectionInteractPermission', setcollectioninteract) 
    
    },
    createUserDoc({ commit },payload) {
      commit('setStatus', 'loading')
      const userdoc = {
        Title: payload.Title,
        Name: payload.Name,
        Surname: payload.Surname,
        BusinessUnit: payload.BusinessUnit,
        EmployeeCode: payload.EmployeeCode,
        Position: payload.Position,
        Gender: payload.Gender
      }
      console.log('userdoc')
      console.log(userdoc)
      db.collection('users').add(userdoc).then({

      })
    },

    createSystemActivity({ commit },payload) {
      commit('setStatus', 'loading')
      const systemactivity = {
        creatorid: payload.user.id,
        creatorname: payload.user.Full_Name,
        post: `<a href="/SiteMember/`+payload.user.id+`">`+payload.user.Full_Name+`</a> `+payload.contentvar+`<a href="`+payload.location+payload.docid+`">`+payload.docname+`</a>`,
        postvar: payload.contentvar+`<a href="`+payload.location+payload.docid+`">`+payload.docname+`</a>`,
        type: payload.type,
        createdon: new Date()
      }
      if(payload.user.Profile_Photo){        
        systemactivity.creatorimg = payload.user.Profile_Photo
      }
      db.collection('systemactivities').add(systemactivity).then({

      })
    },

    createNotification({ commit },payload) {
      commit('setStatus', 'loading')
      let NewNotification = {
        [payload.itemidprop]: payload.itemid,
        Type: payload.Type,
        Header: payload.Header,
        Content: payload.Content,
        CreatorFullName: payload.CreatorFullName,
        CreatorID: payload.CreatorID,
        Owner: payload.Owner,
        Ownerid: payload.Ownerid,
        Read: false,
        Path: payload.Path,
        Message: payload.Message,
        Date: new Date()
        }
        if(payload.ActiveProcessNumber){
          NewNotification.ActiveProcessNumber = payload.ActiveProcessNumber
        }
        if(payload.topicid){
          NewNotification.topicid = payload.topicid
        }
        if(payload.fileurl){
          NewNotification.fileurl = payload.fileurl
        }
        if(payload.IsLiason){
          NewNotification.IsLiason = payload.IsLiason
          NewNotification.Liasonid = payload.Liasonid
        }
        db.collection('notifications').add(NewNotification).then(notedoc => {

        })
    },


    signUpbyAdminImportAction ({ commit }, payload) {
      return new Promise((resolve, reject) => {
       
        var config = {
  apiKey: "AIzaSyCb6vny25sEZv03H56qXwAJR-gjFpJ9e7s",
  authDomain: "nene-motors.firebaseapp.com",
  databaseURL: "https://nene-motors.firebaseapp.com ",
  projectId: "nene-motors",
  storageBucket: "nene-motors.appspot.com",
  messagingSenderId: "771937702576",
  appId: "1:771937702576:web:30a3915e5492c98189a175",
  measurementId: "G-5VQ15W1C39"
};
        // const secondaryApp = firebase.initializeApp(config, "Secondary");
        if (firebase.apps.length === 1) {
          let secondaryApp = firebase.initializeApp(config, "Secondary")
          secondaryApp.auth().createUserWithEmailAndPassword(payload.Email,  payload.password)
        .then((response) => {
          const userdocid = response.user.uid
          // alert('success')
          const systemactivity = {
            creatorid: userdocid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+userdocid+`">`+payload.Full_Name+`</a> joined GP Combrink Vehicle Sales & Finance`,
            type: `New User`,
            createdon: new Date()
          }
          console.log(systemactivity)
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.Profile_Photo
          }
          console.log('logging more from store before create')
          console.log(response)
          resolve(response);
          db.collection('users').doc(userdocid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email
          })
          db.collection('userroles').doc(userdocid).set({
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          console.log('here is userdocid',userdocid)
          
          db.collection('systemactivities').add(systemactivity).then({
            
          })
            
        }).catch((error) => {
       
          alert(error.message)
          reject(error);
        })
        }
        else{
          let secondaryApp = firebase.apps[1]
          secondaryApp.auth().createUserWithEmailAndPassword(payload.Email, payload.password)
        .then((response) => {
          // alert('success')
          const userdocid = response.user.uid
          const systemactivity = {
            creatorid: userdocid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+userdocid+`">`+payload.Full_Name+`</a> joined GP Combrink Vehicle Sales & Finance`,
            type: `New User`,
            createdon: new Date()
          }
          console.log(systemactivity)
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.Profile_Photo
          }
          console.log('logging more from store before create')
          console.log(response)
          resolve(response);
          db.collection('users').doc(userdocid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email,
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          
            
        }).catch((error) => {
       
          alert(error.message)
          reject(error);
        })
        }
        
      
      })
    },

    signUpbyAdminAction ({ commit }, payload) {
      commit('setStatus', 'loading')
      firebase.auth().createUserWithEmailAndPassword(payload.Email, payload.password)
        .then((response) => {
          alert('success')
          const userdocid = response.user.uid
          db.collection('users').doc(response.user.uid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email,
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          const systemactivity = {
            creatorid: response.user.uid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+response.user.uid+`">`+payload.Full_Name+`</a> joined GP Combrink Vehicle Sales & Finance`,
            type: `New User`,
            createdon: new Date()
          }
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.user.Profile_Photo
          }
          db.collection('systemactivities').add(systemactivity).then({
    
          })
        })

        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    },

    signInAction ({ commit }, payload) {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          db.collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = response.user.uid
            console.log(userdata)
            commit('setUserObj', userdata)
          })
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    },


    signOutAction ({ commit }) {
      firebase.auth().signOut()
        .then((response) => {
          commit('setUser', null)
          commit('setStatus', 'success')
          commit('setError', null)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    }
  },

  getters: {
    getRequiredInformation(state){
      return state.UserRequiredInfo
    },
    getArrayByDynamic: (state) => (ArrayName) => {
      return state[ArrayName];
    },
    getFriendshipsArray(state){
      return state.FriendshipsArray
    },
    getRMDocumentsArray(state){
      return state.RMDocumentsArray
    },
    
    getBusinessUnitsArray(state){
      return state.BusinessUnitsArray
   },
    getCurrentCustomersArray(state){
      return state.CurrentCustomersArray
   },
    getCustomerClaimsArray(state){
      return state.CustomerClaimsArray
   },
    getDealershipsArray(state){
      return state.DealershipsArray
   },
    getSalesContractsArray(state){
      return state.SalesContractsArray
   },
    getServiceJobsArray(state){
      return state.ServiceJobsArray
   },
    getUsersArray(state){
      return state.UsersArray
   },
    userBUID (state) {
      return state.userobj.Business_Unitid
    },
    userBUChildren (state) {
      return state.buobj.childarrayQuery
    },
    userBUParents (state) {
      return state.buobj.childarrayQuery
    },
    status (state) {
      return state.status
    },

    user (state) {
      return state.userobj
    },

    error (state) {
      return state.error
    }
  }
})

  