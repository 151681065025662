
<template>
<v-main class="grey lighten-3" style="margin-top:-60px;margin-bottom:-150px">
    <!-- <v-card class="grey lighten-4 stickytopconscious" width="100%" height="100%" flat> -->
    <!-- <v-card class="transparent"> -->
      <!-- <div  class="my-10"> -->
        <v-card class="transparent" width="100%" flat tile
        style="margin-bottom:-50px;z-index:0">
          <v-img src="@/assets/RASolidA-Light.jpeg"  
        height="150">
            <v-img
        height="150"
        src="@/assets/RAInvert-BG.png"
            contain
        >
              
             
            </v-img>  
          </v-img>
        </v-card>
        <v-layout row wrap class="justify-center">
            <v-card min-width="100%" dark elevation="2" shaped class="blue-grey lighten-1">
                <v-card-title class="display-2 white--text" style="text-transform: uppercase">
                    Banner Ad Builder
                </v-card-title>
                <v-card-text>
                    <ul>
                          <li>
                            <i>Sidebar (logo,image,text,actionbutton)</i>
                          </li>
                          <li>
                            <i>TopBar (gif,action button)</i>
                          </li>
                          <li>TopBar (caption,actionbutton,logo)
                          </li>
                          
                        </ul>
                </v-card-text>
            </v-card>
        </v-layout>
                <v-card color="#FFFFFF" class="my-2" width="100%" flat>
            <v-layout row wrap class="justify-center mx-2">
                <v-flex lg10 md11 sm11 xs12 class="mx-2">
                  <v-card  height="100%" style="padding-right:20px;padding-left:20px"
                  class="transparent" flat                  
                  >
                  <v-card-title>New Banner Ad</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="BannerName" label="Name"/>
                        <v-select label="Type" :items="NewBannerTypes" item-text="Name" return-object v-model="BannerType"/>
                         <v-select label="Link Type" :items="LinkTypes" item-text="Name" return-object v-model="LinkType"/>
                         <v-select v-if="LinkType && LinkType.Name === 'Group'" label="Group" :items="SiteGroups" item-text="Name" return-object v-model="LinkSource"/>
                         <v-select v-if="LinkType && LinkType.Name === 'Page'" label="Page" :items="PageRouteComponents" item-text="Name" return-object v-model="LinkSource"/>
                         <v-select v-if="LinkType && LinkType.Name === 'Landing Page'" label="Page" :items="LandingPages" item-text="Name" return-object v-model="LinkSource"/>
                         
                         <!-- <v-select item-text="Name" return-object v-model="Link"/> -->
                         <v-switch label="Featered on Specific Records" v-model="RecordSpecific" />
                         <v-autocomplete v-if="!RecordSpecific" label="Feature" :items="SiteRouteComponents" item-text="name" return-object multiple v-model="BannerComponents"/>
                         <v-autocomplete v-if="RecordSpecific" label="Record Types" :items="RecordSpecificTypes" item-text="Name" return-object v-model="FeatureType"/>
                         <v-autocomplete v-if="RecordSpecific && FeatureType && FeatureType.Name === 'Wiki'" label="Feature" :items="WikiRouteComponents" item-text="name" return-object multiple v-model="BannerComponents"/>
                         <v-text-field v-if="RecordSpecific && FeatureType && FeatureType.Name === 'Wiki' && BannerComponents"  v-model="RecordName" label="Record Name"/>
                         <input type="file"  @change="BannerImageselected($event)"/>
                        
                        <v-select label="Banner Image" item-text="Name" :items="SystemAssets" v-model="BannerImage" return-object/>
                        <v-menu      
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="400px"
                                >
                                <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-image-edit-outline</v-icon>
                                </template>
                                <v-card>
                                 <v-row>
                                            <v-col
                                             v-for="asset in SystemAssets" :key="asset.itemObjKey"
                                            class="d-flex child-flex"
                                            cols="4"
                                            >
                                            <v-img @click="AssignBannerImage(asset)" height="100" contain :src="asset.Path"/>
                                            </v-col>
                                        </v-row>
                                </v-card>
                            </v-menu>
                            <v-menu
                        v-model="ExpiryDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="due_date">
                        <template v-slot:activator="{ on }">
                          <v-text-field                            
                            v-model="ExpiryDate"
                            label="Expiry Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="due_date"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="ExpiryDate" @input="ExpiryDatemenu = false"></v-date-picker>
                      </v-menu>
                        <v-select label="Publish Type" :items="PublishTypes" v-model="PublishType"/>
                         <v-btn @click="SaveBannerAdFileCheck()">Save</v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>
            </v-layout>
                </v-card> 
                <v-carousel v-model="model" height="400" v-if="BannerType && BannerType.Name === 'TopBar (HTML)'">
                      <v-carousel-item
                      >
                      <v-img
                            :src="RelevantImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-btn @click="BannerPreview = !BannerPreview">Preview</v-btn>
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3" v-if="BannerPreview">
                                {{BannerHeader}}                                
                                </v-card-title>
                                <v-card-title v-if="!BannerPreview">
                                    <v-text-field label="Header" v-model="BannerHeader"/>
                                </v-card-title>
                              
                               <v-card-text v-if="BannerPreview" style="font-size: 10px" class="white--text overline mx-3">
                                {{BannerDescription}}
                               </v-card-text>
                               <v-card-text  v-if="!BannerPreview">
                                <v-textarea v-model="BannerDescription" label="Description" />
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="BannerLink">{{ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
          </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: [],
    components: {
        },
    data() {
    return {  
        BannerType: '',
        ExpiryDatemenu: false, 
        ExpiryDate: '',
        AssetName: '',
        SystemAssets: [],
        SampleBannerImagefile: '',
        BannerImageFile: '',
        RecordSpecificTypes: [
            {
            ID: 1000001,
            Name: 'Wiki'
            }
        ],
        PublishType: '',
        PublishTypes: [
            'Public',
            'Members Only'
        ],
        RecordName: '',
        FeatureType: '',
        RecordSpecific: false,
        SiteRouteComponents: [],
        BannerComponents: [],   
        BannerImage: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRASolidA?alt=media&token=917741bf-7928-4b75-8baa-5564ef93fafd',
        ActionButtonName: 'Find out More',
        BannerLink: '/Groups/0erBwGHT5CzGEFSucUKf',
        BannerPreview: true,
        BannerHeader: '',
        LinkType: '',
        BannerName: '',
        BannerDescription: '',
        NewBannerTypes: [
            {
                ID: 1000001,
                Name: 'Sidebar'
            },
            {
                ID: 1000002,
                Name: 'Topbar (GIF)'
            },
            {
                ID: 1000003,
                Name: 'TopBar (HTML)'
            },
        ],
        LandingPages: [],
        SiteGroups: [],
        LinkTypes: [
            {
                ID: 1000001,
                Name: 'Page'
            },
            {
                ID: 1000002,
                Name: 'Group'
            },
            {
                ID: 1000003,
                Name: 'Article'
            },
            {
                ID: 1000004,
                Name: 'Wiki'
            },
            {
                ID: 1000005,
                Name: 'Landing Page'
            },
            {
                ID: 1000006,
                Name: 'External'
            },
        ],
        
        UserRecord: '',
        rules: {
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            AssetName: ''
            

            }
    }, 

    computed:{
        ExpiryDateObject(){
                return this.DateFormatter(this.ExpiryDate)
            }, 
       BannerImageSimple(){
            if(this.BannerImage && this.BannerImage.Path){
                return this.BannerImage.Path
            }
            else if(this.BannerImage){
                return this.BannerImage
            }
        },
        RelevantImage(){
            if(!this.SampleBannerImagefile && this.BannerImageSimple){
                return this.BannerImageSimple
            }
            else{
                return this.SampleBannerImagefile
            }
        },
        WikiRouteComponents(){
            return this.SiteRouteComponents.filter(comp => {
                return typeof comp.meta !== 'undefined' && comp.meta.type === 'Wiki Pages'
            })
        },
        PageRouteComponents(){
            return this.SiteRouteComponents.filter(comp => {
                return typeof comp.meta !== 'undefined' && comp.meta.type === 'Pages'
            }).map(comp => {
                comp.Name = comp.meta.subcat
                return comp
            })
        },
      SimpleBannerComponents(){
          return this.BannerComponents.map(comp => {
              let obj = {
                  Name: comp.name,
                  path: comp.path,
                  meta: comp.meta
              }
              return obj
          })
      },
      SimpleBannerComponentsNames(){
          return this.BannerComponents.map(comp => {
              let obj = {
                  Name: comp.name,
                  path: comp.path,
                  meta: comp.meta
              }
              return obj.Name
          })
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()
      

    },
    
    methods: {
        AssignBannerImage(asset){
            this.BannerImage = asset
        },
        BannerImageselected(){
            
            var value = prompt("Please choose a name for future refence to this asset", "");
                if (value != null) {
                this.AssetName = value
                this.BannerImageFile = event.target.files[0]
            this.SampleBannerImagefile = URL.createObjectURL(this.BannerImageFile)
                }
            
        },
        SaveBannerAdFileCheck(){
            if(this.BannerImageFile){
                this.PrepareThumbnail(this.BannerImageFile).then(thumburl => {
                    let Assetshorttype = this.BannerImageFile.type.split('image/').join('')
                    let NewAsset = {Name: this.AssetName, Path: thumburl, ShortType: Assetshorttype, FullName: this.AssetName.split(' ').join('_')+'.'+Assetshorttype}
                        // this.SystemAssets.push(NewAsset)
                        db.collection('systemassets').doc(this.AssetName.split(' ').join('_')).set(NewAsset).then(doc => {
                                console.log('updated asset')
                        })
                    this.BannerImage = thumburl
                    this.SaveBannerAd()
                })
            }
            else{
                this.SaveBannerAd()
            }
        },
        PrepareThumbnail(file){
            let vm = this
            return new Promise(function(resolve, reject) {
            let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 1000
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref('BannerAds/'+vm.$route.params.id+'/'+file.name+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => { 
                        resolve(thumburl)
                    })
                })
                
            }
            })
        },
        SaveBannerAd(){
            let BannerAdObj = {
              Expired: false,
                ExpiryDate: this.ExpiryDateObject,
                BannerImage: this.BannerImageSimple,
                ActionButtonName: this.ActionButtonName,
                BannerLink: this.BannerLink,
                BannerHeader: this.BannerHeader,
                LinkType: this.LinkType,
                BannerType: this.BannerType,
                BannerTypeID: this.BannerType.ID,
                BannerName: this.BannerName,
                BannerDescription: this.BannerDescription,
                
                RecordSpecific: this.RecordSpecific,
                LinkSource: this.LinkSource,
                PublishType: this.PublishType
            }
            if(this.FeatureType && this.RecordSpecific){
                BannerAdObj.FeatureType = this.FeatureType
                BannerAdObj.BannerComponents = this.SimpleBannerComponents
                BannerAdObj.BannerComponentsNames = this.SimpleBannerComponentsNames      
                BannerAdObj.RecordName = this.RecordName          
            }
            else{
                BannerAdObj.BannerComponents = this.SimpleBannerComponents
                BannerAdObj.BannerComponentsNames = this.SimpleBannerComponentsNames
            }
            if(this.LinkType.Name === 'Landing Page'){
                BannerAdObj.LinkSource = {
                    id: this.LinkSource.id,
                    Name: this.LinkSource.Name
                }
                BannerAdObj.BannerLink = '/LandingPage/'+this.LinkSource.id
            }
            console.log(BannerAdObj)
            db.collection('bannerads').doc(this.$route.params.id).set(BannerAdObj).then(doc => {
                console.log('yipee set the doc '+doc.id)
            })
        },
        PrepareNewBannerAd(){
            let ref = db.collection('bannerads').doc()
        let newdocid = ref.id
        this.BannerAdView = true
        this.NewBannerAdID = newdocid
        
        },
        PrepareNewLandingPage(){
            let ref = db.collection('landingpages').doc()
        let newdocid = ref.id
        this.LandingPageView = true
        this.NewLandingePageID = newdocid
        this.$router.push('/BannerAd/New/'+newdocid)
        let LinkedfromObj = {
                        Type: "Password Link",
                        id: this.$route.params.id,
                        Created_On: passwordresetdata.Created_On.toDate(),
                        ExpiryDate: ExpiryDate,
                        CanAction: true,
                        ActionText: 'You can always Request another Password Reset?',
                        ActionLink: '/Login'
                    }
                    console.log(LinkedfromObj)
                    this.$router.push({ name: 'newbannerad', params: {LinkedfromObj: LinkedfromObj }})
        
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
            if(this.userLoggedIn){
               this.UserRecord = this.userLoggedIn
              console.log(this.UserRecord)
              this.GetSiteGroups() 
              this.GetSiteComponents()
              this.GetLandingPages()
              this.GetBannerAd()
              this.GetSystemAssets()
            }
        },
        GetSystemAssets(){
            db.collection('systemassets').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.SystemAssets.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
        GetBannerAd(){
            db.collection('bannerads').doc(this.$route.params.id).onSnapshot(snapshot => {
                let BannerAdObj = snapshot.data()
                if(BannerAdObj){
                    
                this.BannerComponents = BannerAdObj.BannerComponents.map(comp => {
                    if(comp.Name){
                        comp.name = comp.Name
                    }
                    return comp
                })
                console.log(this.BannerComponents)
                this.ExpiryDate = format(BannerAdObj.ExpiryDate.toDate(),'yyyy-MM-dd')
                this.BannerImage = BannerAdObj.BannerImage
                this.ActionButtonName = BannerAdObj.ActionButtonName
                this.BannerLink = BannerAdObj.BannerLink
                this.BannerHeader = BannerAdObj.BannerHeader
                this.LinkType = BannerAdObj.LinkType
                this.BannerType = BannerAdObj.BannerType 
                this.BannerName = BannerAdObj.BannerName
                this.BannerDescription = BannerAdObj.BannerDescription
                this.RecordSpecific = BannerAdObj.RecordSpecific
                this.LinkSource = BannerAdObj. LinkSource
                this.PublishType = BannerAdObj.PublishType
                this.FeatureType = BannerAdObj.FeatureType
            }
            if(BannerAdObj.RecordName){                  
                this.RecordName = BannerAdObj.RecordName          
            }

            })
        },
        GetSiteComponents(){
            this.SiteRouteComponents = this.$router.options.routes
        },
        GetLandingPages(){
            db.collection('landingpages').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.LandingPages.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
        GetSiteGroups(){
            db.collection('groups').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.SiteGroups.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.imgcontentgrad {
  background-image: linear-gradient(to right, rgba(255,0,0,0.5), rgb(238,255,0, 1));
}
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}
.IntranetHeader{
  color: greenyellow;
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));
  /* background-color: rgb(77, 147, 238); */
  border-top: 5px solid orangered;
}
#my-lax .v-img__content {
  background: linear-gradient(135deg,  rgba(231,60,126,0.8), rgb(35,166,213, 0.5));
}
.clip-svg{
  mask-size: contain;
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert?alt=media&token=54d7ec67-2109-4eda-93c0-7bf98f55dbf8);
  mask-repeat: no-repeat;
  mask-position: center;
}
</style>

        

    