        <template>
    <div>
        <v-navigation-drawer app height="100%" v-model="Navigation" :permanent="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md" :dark="MyDashDark" >
            <v-list-item :class="ThemeColor">
                <v-list-item-content>
                    <v-list-item-title class="justify-space-between'" style="display: flex;align-items: center;">
                        <h1 :class="ThemeHeaderColor+'--text mediumoverline'">{{ComputedDashboardTitle}}</h1>
                    </v-list-item-title>
                    <v-list-item-subtitle :class="ThemeHeaderColor+'--text'">
                        {{GroupTitle}}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                    <v-icon @click="ReloadPage()" small :class="ThemeHeaderColor+'--text'">mdi-refresh</v-icon>
                </v-list-item-avatar>                 
                <v-list-item-avatar size="30">
                        <v-dialog
                            max-width="400"
                            >
                            <template v-slot:activator="{ on }">
                                <v-icon :dark="MyDashDark" :class="ThemeHeaderColor+'--text'" v-on="on">mdi-cogs</v-icon>
                            </template>
                            <v-card max-width="400">
                            <v-autocomplete :items="LibraryColors" label="Theme Color" @change="UpdateThemeColors()" v-model="ThemeColor">
                                <template v-slot:item="{ item }">
                                    <span :class="item">{{item}}
                                        <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                        </p>
                                        </span>
                                </template>
                            </v-autocomplete>
                            <v-autocomplete :items="LibraryColors" label="Header Color" @change="UpdateThemeColors()" v-model="ThemeHeaderColor">
                                <template v-slot:item="{ item }">
                                    <span :class="item">{{item}}
                                        <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                        </p>
                                        </span>
                                </template>
                            </v-autocomplete>
                            <v-switch v-model="MyDashDark" @change="UpdateThemeColors()" label="Always Dark"/>
                            </v-card>
                            </v-dialog>
                </v-list-item-avatar>
            </v-list-item>
            <v-list
            class="transparent"
            dense                
            nav
                v-for="item in ComputedNavigation"
            :key="item.title"
            >
            <v-list-item class="overline">
            <v-list-item-icon>
                <v-icon x-large :color="ThemeColor">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title :class="ThemeColor+'--text'">{{RouteName}} {{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider>
                </v-divider>
                <v-list
                dense>
                <v-list-item 
                class="justify-start"
                @click="ActivateSection(child)"
                    v-for="child in item.Children"
                    :key="child.title"
                >
                <v-list-item-icon>                        
                        <v-icon size="20">{{ child.icon }}</v-icon><v-badge color="warning" class="mx-1" v-if="child.notifications" overlap>
                        <span slot="badge"> {{child.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>                            
                        <v-list-item-title style="font-weight:400;">{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list>
            </v-list>
                <v-divider>
            </v-divider>
            
        </v-navigation-drawer>
        <v-main :dark="MyDashDark" :class="ThemeColor+' lighten-5 fill-height'" style="padding-top:60px;">
            <v-card width="100%" height="100%" :dark="MyDashDark" :flat="MyDashDark" :class="MyDashDark ? 'transparent' : ''" tile>
                <v-card-title :class="ThemeColor+' justify-space-between '+ThemeHeaderColor+'--text mediumoverline'">
                    My {{ActiveSession.title}}<v-icon :class="ThemeHeaderColor+'--text'" @click="Navigation = !Navigation">mdi-menu</v-icon>
                </v-card-title>
                <v-card-text>
                
                    <component v-if="ActiveSession"  :is="ActiveSessionComponent"  :MenuItem="ActiveSession" :UsersArray="UsersArray"
                    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities"
                    :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                    :TeamView="TeamView" :ThemeColor="ThemeColor" :ActiveSession="ActiveSession" :ThemeHeaderColor="ThemeHeaderColor"
                    :AppisDarkMode="MyDashDark" :NotificationObj="NotificationObj"/>
                </v-card-text>
                <v-card-text style="display:none">
                    <span v-for="item in FinalNavigation" :key="item.itemObjKey">
                    <component :is="item.ComponentName" :UsersArray="UsersArray"
                    @PushArray="PushArray" :MenuItem="item" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" 
                    :TeamView="TeamView" :ThemeColor="ThemeColor" :ActiveSession="ActiveSession" :ThemeHeaderColor="ThemeHeaderColor"
                    :AppisDarkMode="MyDashDark" />
                    
                </span>
                </v-card-text>
            </v-card>
        </v-main>
    </div>
  
</template>


<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import MyGoals from '@/components/Dashboards/MyDashboard/MyGoals'
import MyActivePipeline from '@/components/Dashboards/MyDashboard/MyActivePipeline'
import MyArticles from '@/components/Dashboards/MyDashboard/MyArticles'
import MyBlogs from '@/components/Dashboards/MyDashboard/MyBlogs'
import MyActivities from '@/components/Dashboards/MyDashboard/MyActivities'
import MyTeamActivities from '@/components/Dashboards/MyDashboard/MyTeamActivities'
import MyNoticeBoardPosts from '@/components/Dashboards/MyDashboard/MyNoticeBoardPosts'
import MySocialStats from '@/components/Dashboards/MyDashboard/MySocialStats'

export default {
    props: ['AppisDarkMode','UsersArray','Notifications','MyActivitiesArray','MyGoals','MyGoalsisEmpty','SiteModerators','NotificationObj','UnreadNotifications'],
    components: {
        MyGoals,
        MyActivePipeline,
        MyArticles,
        MyBlogs,
        MyTeamActivities,
        MyActivities,
        MyNoticeBoardPosts,
        MySocialStats
        },
    data(){
        return{
            GotEntities: false,
            SystemEntities: [],
            GroupTitle: '',
            Navigation: true,
            MyDashDark: false,
            ThemeHeaderColor: 'white',
            LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
      ThemeColor: 'blue',
            ActiveSession: '',
            items: [       
            { title: 'Pipelines', icon: 'mdi-pipe',RenderonProp: true, RenderProp: 'EntitieswithPipelines',
            Children: [
            // { title: 'Leads', icon: 'mdi-pipe',ComponentName: 'MyActivePipeline' },
            ]
            },
            ],
        }
    },
    computed:{
        UserSiteModerator(){
            return this.SiteModerators.find(obj => obj.id === this.userLoggedIn.id) || this.userIsAdmin
        },
        EntitieswithPipelines(){
            return this.SystemEntities
            .filter(entity => {
                return entity.EntityProcesses.length > 0
            })
        },
        RouteName(){
            if(this.$route.params.id){
                return 'Group'
            }
            else{
                return 'My'
            }
        },
        ComputedDashboardTitle(){
            if(this.$route.params.id){
                return 'Group Dashboard'
            }
            else{
               return 'My Dashboard' 
            }
            
        },
        
        ActiveSessionComponent() {
            return this.ActiveSession.ComponentName
        },
        ComputedQuery(){
            if(this.$route.params.id && this.ActiveSession && this.ActiveSession.groupcollectionname){
                return "db.collection('"+this.ActiveSession.groupcollectionname+"').where('Administrators','array-contains',"+this.userLoggedIn.id+").where('"+this.ActiveSession.groupcolfield+"',==,"+this.$route.params.id+")"
            }
            else if(this.ActiveSession && this.ActiveSession.sitecollectionname){
               return "db.collection('"+this.ActiveSession.sitecollectionname+"').where('"+this.ActiveSession.sitecolfield+"',==,"+this.userLoggedIn.id+")" 
            }
        },
        FinalNavigation(){
            return this.PreparedNavigation
            .map(obj => {
                if(obj.ComponentName === 'MyActivities'){ 
                    //actually now, here we need to seriously figure out, what obj.title is. 
                    //if obj.title  === Team Activities then team count, otherwise...the below...you get?                  
                    obj.Array = this.MyActivitiesArray.map(record => {
                        record.Notice = `<ul>`
                        record.NotificationCount = 0
                        let unreadnotifications = this.Notifications
                                .filter(not => {
                                    return not.taskid === record.id
                                })
                                console.log(unreadnotifications)
                                record.NotificationCount = Number(record.NotificationCount)+Number(unreadnotifications.length)
                                unreadnotifications.map(not => {
                                    record.Notice = record.Notice+`<li>New Task - `+record.name+` by `+not.CreatorFullName+`</li>`
                                    return not
                                })
                            let today = new Date()
                            let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                            if(record.activitytype === 'Task' && record.status === 'Open'){
                                record.icon = 'mdi-clipboard-list-outline'
                                record.duedatestring = this.TimestampFormatterSTRING(record.duedate.toDate())
                                record.duedateobj = new Date(record.duedatestring)
                                if(record.duedateobj < today){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Task is overdue - action required</li>`
                                }
                                else if(record.duedateobj < weekfromnow){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Task is due in 7 days</li>`
                                }
                                
                                //but still need "task created" that is "unread"
                            }
                            else if(record.activitytype === 'Appointment' && record.status === 'Open'){
                                record.icon = 'mdi-calendar-check-outline'
                                let unreadnotifications = this.Notifications
                                .filter(not => {
                                    return not.appointmentid === record.id
                                })
                                console.log('unreadnotifications',unreadnotifications)
                                record.NotificationCount = Number(record.NotificationCount)+Number(unreadnotifications.length)
                                unreadnotifications.map(not => {
                                    record.Notice = record.Notice+`<li>New Appointment - `+record.name+` by `+not.CreatorFullName+`</li>`
                                    return not
                                })
                                record.startdatestring = this.TimestampFormatterSTRING(record.start.toDate())
                                record.startdateobj = new Date(record.startdatestring)
                                record.enddatestring = this.TimestampFormatterSTRING(record.end.toDate())
                                record.enddateobj = new Date(record.enddatestring)
                                if(record.enddateobj < today){
                                record.NotificationCount = record.NotificationCount-1+2
                                record.Notice = record.Notice+`<li>Appointment end date already passed - consider closing</li>`
                                }
                                else if(record.startdateobj < today){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Appointment has already started</li>`
                                }
                                else if(record.startdateobj <= weekfromnow){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Appointment Start less than week from now - Get ready!</li>`
                                }
                                
                            }  
                            record.Notice = record.Notice+`</ul>`
                            return record
                    })
                    console.log(obj)
                    obj.notificationsarray = obj.Array
                    .filter(record => {
                        return record.NotificationCount>0
                    })
                    obj.notifications = obj.notificationsarray.length
                }               
                return obj 
            })
        },
        PreparedNavigation(){
            return this.ComputedNavigation
            .filter(item => {
                return item.GroupsEnabled
            })
            .map(item => {
                return item.Children
            }).flat()
        },
        ComputedNavigation(){
            return this.items
            .filter(item => {
                if(this.$route.params.id){
                    return item.GroupsEnabled
                }
                else{
                    return item
                }
            })
            .filter(item => {
                if(item.title === 'Pipelines'){
                    if(this.EntitieswithPipelines && this.EntitieswithPipelines.length > 0){
                      
                        return item
                    }
                }
                else{
                    return item
                }
            })
            .map(item => {
                if(item.title === 'Pipelines'){
                    // alert('yay')
                item.Children = this.EntitieswithPipelines.map(obj => {
                    obj.DataArray.map(record=> {
                            let notificationslist = this.UnreadNotifications.filter(obj => {return obj.clientrecords === record.id})
                            record.NotificationCount = notificationslist.length
                            record.Notifications = notificationslist
                            if(record.NotificationCount>0){
                                record.NotifyColor = 'red'
                            }
                            else{
                                record.NotifyColor = 'grey'
                                
                            }
                            return record
                        })
                    obj.notifications = obj.DataArray.map(record => {
                        //alert(record.NotificationCount)
                        return record.NotificationCount
                    }).reduce((a, b) => a + b, 0)
                    return obj
                })
                
                // obj.RecordPrimaryField =
                }
                return item
            })
            .filter(item => {
                if(item.title === 'Pipelines'){
                    item.Children = item.Children.map(obj => {                        
                         if(this.$store.state[obj.storequery]) {
                             obj.CanAccess = true
                            }
                        return obj
                    })
                    .filter(obj => {
                        return obj.CanAccess
                    })
                }
                return item
            })
            .filter(item => {
                return item.Children.length > 0
            })
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
    },
    watch: {
        NotificationObj (value) {
            if (value) {
                console.log(value)
                if(value.IsLiason){
                    let pipelinesobj = this.ComputedNavigation.find(obj => obj.title === 'Pipelines')
                    console.log(pipelinesobj)
                    let mypipelineobj = pipelinesobj.Children.find(obj => obj.topicid === value.ActiveSessionid)
                    console.log(mypipelineobj)
                    this.ActiveSession = mypipelineobj
                    
                }
                // this.ActiveSession = 
            }
        },
        
    },
    created(){
        if(this.UserSiteModerator){
            let socialobj = { title: 'Posts', icon: 'mdi-clipboard-pulse-outline',GroupsEnabled: true,
            Children: [
            
            { title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
            { title: 'Blogs', icon: 'mdi-blogger',ComponentName: 'MyBlogs',sitecollectionname: 'siteblogs',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupblogs',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Articles', icon: 'mdi-blogger',ComponentName: 'MyArticles',sitecollectionname: 'sitearticles',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouparticles',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { title: 'Notices', icon: 'mdi-poll-box',ComponentName: 'MyNoticeBoardPosts',sitecollectionname: 'notices',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupnotices',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            ]
            }
            this.items.push(socialobj)
        }
        this.GetEntities()
        if(this.userLoggedIn.MyDashboardThemeColor){
            this.ThemeColor = this.userLoggedIn.MyDashboardThemeColor
        }
        if(this.userLoggedIn.MyDashDark){
            this.MyDashDark = this.userLoggedIn.MyDashDark
        }
        if(this.userLoggedIn.ThemeHeaderColor){
            this.ThemeHeaderColor = this.userLoggedIn.ThemeHeaderColor
        }
        if(this.$route.params.id){
            this.GetGroup()
        }
        setTimeout(() => {
            this.ComputedNavigation.map(item => {
                let obj = item.Children.find(obj => obj.ComponentName === 'MyActivities')
                if(obj){
                    this.ActiveSession = obj
                }
            })
        }, 1000);
        
    },
    methods:{
        ReloadPage(){
        location.reload()
        //supposedly this does hard reload but some say it does not work in Chrome
        //location.reload(true)
        //this little below item removes cache as well
        //window.location = window.location.href+'?eraseCache=true';
        },
        GetClientLiason(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('clientliason').where('relatedid','==',obj.id).onSnapshot(commres => {
                let listlength = commres.docs.length
                if(listlength === 0){
                    resolve(obj)
                }
                const comchanges = commres.docChanges();
                comchanges.forEach(comchange => {
                    if (comchange.type === 'added') {
                        let liasonobj = {
                                ...comchange.doc.data(),
                                id: comchange.doc.id
                                }
                                let oncheck = obj.ClientLiason.find(obj => obj.id === liasonobj.id)
                                if(!oncheck){
                                    obj.ClientLiason.push(liasonobj)
                                }
                        if(obj.TimelineDialog){
                            obj.TimelineDialog = false
                            setTimeout(() => {
                                obj.TimelineDialog = true
                            }, 500);
                        }
                        else{
                            obj.TimelineDialog = false
                        }
                    }
                    if(obj.ClientLiason.length === listlength){
                        resolve(obj)
                    }
                })
                    
                })
            })
        },
        ClientRecordsandLiason(array,ClientLookupField,entitycollection,DisplayName){
            //alert(array.length)
            //seems like this would in fact be the best place to put the check for clientlookupfield. 
            //if client lookupfield exists then user one colleciton, if not then intenral type colleciton
            let routeobj = this.$router.options.routes.find(obj => obj.name === entitycollection)
            let vm = this
            let newarray = []
            let arraylength = array.length
            return new Promise(function(resolve, reject) {
                if(ClientLookupField){
                    array.map(obj => {
                    if(routeobj.Security && routeobj.Security.SecurityType === 'Custom Roles'){
                        obj.CanEdit = vm.$store.state[DisplayName.split(' ').join('')+'CanEdit']
                    }
                    else if(routeobj.Security && routeobj.Security.SecurityType === 'Business Unit'){
                        let editAll = vm.$store.state[DisplayName.split(' ').join('')+'editAll']
                        let editUnitdown = vm.$store.state[DisplayName.split(' ').join('')+'editUnitdown']
                        let editUserUnit = vm.$store.state[DisplayName.split(' ').join('')+'editUserUnit']
                        let editOwner = vm.$store.state[DisplayName.split(' ').join('')+'editOwner']
                        if(editAll){
                            obj.CanEdit = true
                        }
                        else if(editUnitdown){
                            if(vm.userBUChildren.includes(obj.Business_Unitid)){
                            obj.CanEdit = true
                            }
                        }
                        else if(editUserUnit){
                            if(obj.Business_Unitid === vm.userBUID){
                            obj.CanEdit = true
                            }
                        }
                        else if(editOwner){
                            if(obj.Ownerid === vm.userLoggedIn.id){
                            obj.CanEdit = true
                            }
                        }
                    }   
                    obj.ClientLiason = []
                    console.log('checking for clientrecordata')
                    db.collection('clientrecords').doc(obj.id).onSnapshot(snapshot => {
                        let clientrecordata = snapshot.data()
                        console.log('clientrecordata',clientrecordata)
                        if(clientrecordata && clientrecordata.name){
                            obj.HasClientRecord = true
                        }
                        else{
                            if(newarray.length === arraylength){
                                resolve(newarray)
                            }
                        }
                        vm.GetClientLiason(obj).then(result => {
                            newarray.push(result)
                            if(newarray.length === arraylength){
                                resolve(newarray)
                            }
                        })                        
                    })   
                })
                }
                else{
                    array.map(obj => {
                    if(routeobj.Security && routeobj.Security.SecurityType === 'Custom Roles'){
                        obj.CanEdit = vm.$store.state[DisplayName.split(' ').join('')+'CanEdit']
                        console.log(DisplayName.split(' ').join('')+'CanEdit')
                    }
                    else if(routeobj.Security && routeobj.Security.SecurityType === 'Business Unit'){
                        let editAll = vm.$store.state[DisplayName.split(' ').join('')+'editAll']
                        let editUnitdown = vm.$store.state[DisplayName.split(' ').join('')+'editUnitdown']
                        let editUserUnit = vm.$store.state[DisplayName.split(' ').join('')+'editUserUnit']
                        let editOwner = vm.$store.state[DisplayName.split(' ').join('')+'editOwner']
                        if(editAll){
                            obj.CanEdit = true
                        }
                        else if(editUnitdown){
                            if(vm.userBUChildren.includes(obj.Business_Unitid)){
                            obj.CanEdit = true
                            }
                        }
                        else if(editUserUnit){
                            if(obj.Business_Unitid === vm.userBUID){
                            obj.CanEdit = true
                            }
                        }
                        else if(editOwner){
                            if(obj.Ownerid === vm.userLoggedIn.id){
                            obj.CanEdit = true
                            }
                        }
                    }                    
                    let query = db.collection(entitycollection).doc(obj.id).collection('InternalLiaison')
                    obj.InternalLiaison = []
                    console.log('checking for InternalLiaison data')
                    vm.GetInternalRecordLiason(obj,query).then(result => {
                            newarray.push(result)
                            if(newarray.length === arraylength){
                                resolve(newarray)
                            }
                        }) 
                    })
                }
            
            })
        },
        GetProcessBuildInfo(obj){
            let vm = this
            //alert('infoman')
            // alert(obj.storedata)
            console.log(obj.storequery,vm.$store.state[obj.storequery])
            return new Promise(function(resolve, reject) {
                obj.DataArray = []
                if(vm.$store.state[obj.storedata] && vm.$store.state[obj.storedata].length>0){
                    vm.ClientRecordsandLiason(vm.$store.state[obj.storedata],obj.ClientLookupField,obj.collection,obj.DisplayName).then(liasonandrecordsresult => {
                        obj.DataArray = liasonandrecordsresult
                        resolve(obj)
                    })
                }
                else{
                    if(vm.$store.state[obj.storequery]){
                        //this means the user cna query, so now it calls liaison for that entity
                        //the issue is, if Internal Type it returns the data, but it does not know to call the subcolelction rather than the clientliason collection. 
                        let payload = {                
                        query: vm.$store.state[obj.storequery],
                        arraymutation: 'set'+obj.storedata,
                        getter: 'get'+obj.storedata,
                        arrayname: obj.storedata
                        }
                        vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                            if(vm.$store.state[obj.storedata].length === 0){
                                resolve(obj)
                            }
                            else{
                            vm.ClientRecordsandLiason(vm.$store.state[obj.storedata],obj.ClientLookupField,obj.collection,obj.DisplayName).then(liasonandrecordsresult => {
                                obj.DataArray = liasonandrecordsresult
                                resolve(obj)
                            })   
                            }
                        })
                    }
                    else{
                        
                        resolve(obj)
                    }
                    
                }
            })
        },
        GetInternalRecordLiason(obj,query){
            let vm = this
            return new Promise(function(resolve, reject) {
                query.onSnapshot(commres => {
                let listlength = commres.docs.length
                if(listlength === 0){
                    resolve(obj)
                }
                const comchanges = commres.docChanges();
                comchanges.forEach(comchange => {
                    if (comchange.type === 'added') {
                        let liasonobj = {
                                ...comchange.doc.data(),
                                id: comchange.doc.id
                                }
                                let oncheck = obj.InternalLiaison.find(obj => obj.id === liasonobj.id)
                                if(!oncheck){
                                    obj.InternalLiaison.push(liasonobj)
                                }
                        if(obj.TimelineDialog){
                            obj.TimelineDialog = false
                            setTimeout(() => {
                                obj.TimelineDialog = true
                            }, 500);
                        }
                        else{
                            obj.TimelineDialog = false
                        }
                    }
                    if(obj.InternalLiaison.length === listlength){
                        resolve(obj)
                    }
                })
                    
                })
            })
        },
        RecordsKeepWatch(arrayprop,obj){    
            console.log('will now watch store for '+obj.id+' on get'+arrayprop,this.$store.getters['get'+arrayprop])
            
            let getter = 'get'+arrayprop

            let stateprop = '$store.getters.'+getter
            console.log(stateprop)
            this.$watch(stateprop, function (newValue, oldValue) {
                console.log(newValue, oldValue)
                // if(newValue !== oldValue){
                    let pipelinesobj = this.ComputedNavigation.find(item => item.title === 'Pipelines')
                    let mypipelineobj = pipelinesobj.Children.find(item => item.topicid === obj.id) 
                    console.log(mypipelineobj)
                    if(mypipelineobj){
                    this.ClientRecordsandLiason(newValue,mypipelineobj.ClientLookupField,mypipelineobj.collection,obj.DisplayName).then(liasonandrecordsresult => {
                        mypipelineobj.DataArray = liasonandrecordsresult                    
                        })  
                    }   
                    else{
                        //console.log(mypipelineobj.DataArray)
                        mypipelineobj.DataArray = newValue
                    }
                // }
                
                 
            }, {deep:true})
        },
        GetEntities(){
            let vm = this
            vm.$emit('ActivateProcessing',true)
            //alert('getting entities')
            return new Promise(function(resolve, reject) {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    vm.$emit('ActivateProcessing',false)
                }
                //alert(arraylength)
                const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let build = change.doc.data()
                        build.id = change.doc.id
                        vm.GetAllEntityProps(build).then(function(allpropsresult) {
                            if(allpropsresult){
                            //   console.log("%c "+build.id, "background: blue; color: white")
                            //  console.log(build)
                            let oncheck = vm.SystemEntities.find(obj => obj.id == build.id)
                            if(!oncheck){
                                if(build.EntityProcesses.length > 0 && build.datasethasrecordowner){
                                    // .Children
                                    let obj = { title: build.DisplayName, icon: build.dataseticon,ComponentName: 'MyActivePipeline',storequery: build.DisplayName.split(' ').join('')+'Query',storedata: build.DisplayName.split(' ').join('')+'Array',id: build.id }
                                        obj.DisplayName = build.DisplayName
                                        obj.collection = build.DisplayName.split(' ').join('').toLowerCase()
                                        let routeobj = vm.$router.options.routes.find(item => item.name === obj.collection)
                                        obj.RecordPrimaryField = build.AllFields.find(item => item.Primary)
                                        obj.EntityProcesses = build.EntityProcesses
                                        obj.DigitizedForms = build.DigitizedForms
                                        obj.automations = build.automations
                                        obj.SingleName = build.SingleName
                                        if(routeobj.Security && routeobj.Security.SecurityType){
                                         obj.SecurityType = routeobj.Security.SecurityType
                                        }
                                        obj.DocumentRegisters = build.DocumentRegisters.map(reg => {
                                            if(reg.CreatePermission === 'Inherited'){
                                                if(obj.SecurityType === 'Custom Roles'){
                                                    obj.CanCreate = vm.$store.state[DisplayName.split(' ').join('')+'CanEdit'] || vm.userIsAdmin
                                                }
                                                else if(obj.SecurityType === 'Business Unit'){
                                                    obj.CanCreate = true
                                                     obj.CreateUndetermined = true
                                                }
                                                //this is incomplete considering, user may be able to save table, but if BU structure then only know once open record
                                            }
                                            else if(reg.CreatePermission === 'Admin'){
                                                reg.CanCreate = vm.userIsAdmin
                                            }
                                            else if(reg.CreatePermission === 'Custom Role'){
                                                reg.CustomCreateRoles.map(role => {
                                                    let usercheck = vm.userLoggedIn.rolesarrayQuery.find(userrole => userrole === role)
                                                    if(usercheck || vm.userIsAdmin){
                                                        reg.CanCreate = true
                                                    }
                                                })
                                            }
                                            return reg
                                        }).filter(reg => {return reg.CanCreate})
                                        obj.Galleries = build.Galleries.map(reg => {
                                            if(reg.CreatePermission === 'Inherited'){
                                                if(obj.SecurityType === 'Custom Roles'){
                                                    obj.CanCreate = vm.$store.state[DisplayName.split(' ').join('')+'CanEdit'] || vm.userIsAdmin
                                                }
                                                else if(obj.SecurityType === 'Business Unit'){
                                                    obj.CanCreate = true
                                                     obj.CreateUndetermined = true
                                                }
                                                //this is incomplete considering, user may be able to save table, but if BU structure then only know once open record
                                            }
                                            else if(reg.CreatePermission === 'Admin'){
                                                reg.CanCreate = vm.userIsAdmin
                                            }
                                            else if(reg.CreatePermission === 'Custom Role'){
                                                reg.CustomCreateRoles.map(role => {
                                                    let usercheck = vm.userLoggedIn.rolesarrayQuery.find(userrole => userrole === role)
                                                    if(usercheck || vm.userIsAdmin){
                                                        reg.CanCreate = true
                                                    }
                                                })
                                            }
                                            return reg
                                        }).filter(reg => {return reg.CanCreate})                                        
                                        obj.buildid = build.buildid
                                        if(build.ClientLookupField){
                                            obj.ClientLookupField = build.ClientLookupField
                                            obj.LiaisonProp = 'ClientLiason'
                                            
                                        }
                                        else{
                                            obj.LiaisonProp = 'InternalLiaison'
                                        }
                                        obj.topicid = build.id                                        
                                        obj.AllFields = build.AllFields
                                        if(build.HasWebForm){
                                            obj.HasWebForm = build.HasWebForm
                                        }
                                        let statusfield = build.AllFields.find(obj => obj.Name === 'Status')
                                        if(statusfield){
                                            build.StatusField = statusfield
                                        }
                                        vm.GetProcessBuildInfo(obj).then(processentityres => {
                                            vm.SystemEntities.push(processentityres)
                                            vm.RecordsKeepWatch(obj.storedata,obj)
                                            console.log(vm.SystemEntities.length)
                                            if(vm.SystemEntities.length === arraylength){
                                                vm.GotEntities = true    
                                                vm.$emit('ActivateProcessing',false)
                                                resolve('Method SystemEntities  Finished for ' + vm.SystemEntities.length)
                                                }
                                        })
                                        
                                }
                                else{
                                   vm.SystemEntities.push(build)  
                                   if(vm.SystemEntities.length === arraylength){
                                    vm.GotEntities = true    
                                    vm.$emit('ActivateProcessing',false)
                                    resolve('Method SystemEntities  Finished for ' + vm.SystemEntities.length)
                                    }
                                }
                               
                            }
                            
                            }
                            })
                    }
                    
            })
            })
            })
        },
        GetEntityAllFields(build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGalleries(build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetEntityProcesses(build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('EntityProcesses').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetAllEntityProps(build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(build).then(function(allfieldsresult) {
                if(allfieldsresult){
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(build).then(function(subcolresult) {
                        // console.log(subcolresult)
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(build).then(function(formsresult) {
                            vm.GetDocumentRegisters(build).then(function(docregresult) {  
                                vm.GetGalleries(build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(build).then(function(automationsresult) {
                                    console.log(automationsresult)
                                vm.GetGoalMeasurements(build).then(function(goalsresult) {
                                    if(allfieldsresult && subcolresult && processesresult && formsresult && automationsresult && goalsresult && docregresult && galleryres){
                                        resolve('got the props for '+build.id)
                                    }
                                })
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            
                            //console.log(targetslength,i-1+2)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
        GetGroup(){
            db.collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                let groupdata = snapshot.data()
                //alert(groupdata.name)
                this.GroupTitle = groupdata.name
            })
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        PushArray(item,array){
            //console.log('getting for ',item.ComponentName,array)

            let obj = this.PreparedNavigation.find(obj => obj.ComponentName === item.ComponentName)
            //console.log(obj)
            console.log('yay for '+obj.ComponentName,array.length,obj)
            obj.Array = array.map(record => {
                console.log('record',record)
                record.Notice = `<ul>`
                if(obj.ComponentName === 'MyBlogs' || obj.ComponentName === 'MyArticles'){
                    //share, likes, commnets
                    record.NotificationCount = 0
                }
                else if(obj.ComponentName === 'MyEvents'){
                    
                    // people joined, event coming up this week
                    record.NotificationCount = 0
                    
                    record.Attending_Users.map(subcol => {
                        if(!subcol.AdminNotificationCleared){
                            record.NotificationCount = record.NotificationCount-1+2,
                            record.Notice = record.Notice+`<li>New Attendance - `+subcol.Full_Name+`</li>`
                        }
                    })
                    record.startdatestring = this.TimestampFormatterSTRING(record.start.toDate())
                    record.startdateobj = new Date(record.startdatestring)
                    record.enddatestring = this.TimestampFormatterSTRING(record.end.toDate())
                    record.enddateobj = new Date(record.enddatestring)
                    console.log('record.enddateobj',record.enddateobj)
                    let today = new Date()
                    let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                    if(record.enddateobj < today){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Event end date already passed - consider closing</li>`
                    }
                    else if(record.startdateobj < today){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Event has already started</li>`
                    }
                    else if(record.startdateobj <= weekfromnow){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Event Start less than week from now - Get ready!</li>`
                    }
                }
                else if(obj.ComponentName === 'MyForumThreads'){
                    // comments on forums are from notification in app bar
                    let unreadnotifications = this.Notifications
                    .filter(not => {
                        return not.forumid === record.id
                    })
                    console.log(unreadnotifications)
                    record.NotificationCount = unreadnotifications.length
                    unreadnotifications.map(not => {
                        record.Notice = record.Notice+`<li>New Comment - `+not.CreatorFullName+`</li>`
                        //return not
                    })
                }
                else if(obj.ComponentName === 'MyPolls'){
                    // New Vote, Poll End Date reached, All votes in
                    record.NotificationCount = 0
                    record.Votes.map(subcol => {
                        if(!subcol.AdminNotificationCleared){
                            record.NotificationCount = record.NotificationCount-1+2,
                            record.Notice = record.Notice+`<li>New Vote - `+subcol.Voter.Full_Name+`</li>`
                        }
                    })
                    record.enddatestring = this.TimestampFormatterSTRING(record.PollEndDate.toDate())
                    record.enddateobj = new Date(record.enddatestring)
                    let today = new Date()
                    let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                    if(record.enddateobj < today){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Poll Due date is reached - consider closing</li>`
                    }
                    else if(record.enddateobj <= weekfromnow){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Poll Due Date less than week from now - Get ready!</li>`
                    }
                }
                else if(obj.ComponentName === 'MyNoticeBoardPosts'){
                    // reached due date, and anything created more than a month ago
                    record.NotificationCount = 0
                    let today = new Date()
                    record.createdatestring = this.TimestampFormatterSTRING(record.createdon.toDate())
                    record.createdateobj = new Date(record.createdatestring)
                    let monthago = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30)
                    let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                    if(record.createdateobj < monthago){
                            record.NotificationCount = record.NotificationCount-1+2
                            record.Notice = record.Notice+`<li>Notice Created more than 30 days ago - consider action</li>`
                        }
                    if(record.HasDueDate){
                        console.log('record.HasDueDate',record)                        
                        record.enddatestring = this.TimestampFormatterSTRING(record.DueDate.toDate())
                        record.enddateobj = new Date(record.enddatestring)
                       
                        if(record.enddateobj < today){
                            record.NotificationCount = record.NotificationCount-1+2
                            record.Notice = record.Notice+`<li>Notice Due date is reached - consider closing</li>`
                        }
                        else if(record.enddateobj <= weekfromnow){
                            record.NotificationCount = record.NotificationCount-1+2
                            record.Notice = record.Notice+`<li>Notice Due Date less than week from now - Get ready!</li>`
                        }   
                        
                    }
                    
                }
                
                record.Notice = record.Notice+`</ul>`
                return record
            })
            obj.notificationsarray = obj.Array
            .filter(record => {
                return record.NotificationCount>0
            })
            // .map(record => {
            //     return record.NotificationCount
            // }).reduce((a, b) => a + b, 0)
            // if(obj.notificationscheck > 0){
                obj.notifications = obj.notificationsarray.length
            // }
            // console.log(this.items)
            this.Navigation = false
            this.Navigation = true
            // item.Array = array
        },
        // GetQuery(item){
        //     if(this.$route.params.id && item && item.groupcollectionname){
        //         return db.collection(item.groupcollectionname).where('Administrators','array-contains',this.userLoggedIn.id).where(item.groupcolfield,'==',this.$route.params.id)
        //     }
        //     else if(item && item.sitecollectionname){
        //        return db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
        //     }
        // },
        ActivateMultipleFilesUploadNotification(boolean){
            this.$emit('ActivateMultipleFilesUploadNotification',boolean)
        },
        ActivateUploadingSingleFileNotification(boolean){
            this.$emit('ActivateUploadingSingleFileNotification',boolean)
        },
        ActivateSection(item){
            if(item.title === 'Team Activities'){
                this.TeamView = true
            }
            this.ActiveSession = item
            this.ActiveSession.StatusField = this.ActiveSession.AllFields.find(obj => obj.id === 'Status')
            this.ActiveSession.StatusOptions = this.ActiveSession.StatusField.Options
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            },
        UpdateThemeColors(){
            db.collection('users').doc(this.userLoggedIn.id).update({
                MyDashboardThemeColor: this.ThemeColor,
                MyDashDark: this.MyDashDark,
                ThemeHeaderColor: this.ThemeHeaderColor
            })
        }
    }

}
</script>

<style>

</style>

    