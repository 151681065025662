          
<template>
  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat  :class="CheckView" style="padding-bottom:100px;">
  <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel> 

  <v-card flat width="100%">
       
        <v-card class="transparent" width="100%"  flat>
            <v-parallax
            height="150"
              v-if="Delivery_Photo"
            :src="Delivery_Photo.url"
            >
               <div class="delivery_photocontentgrad">
              <v-row
              align="center"
              justify="center"
            >
              <v-col class="text-center" cols="12">
                <h1 class="display-1 font-weight-thin mb-4">My Vehicle</h1>
                <h4 class="subheading">Context</h4>
              </v-col>
            </v-row>
            </div>
            </v-parallax>      
      </v-card>
       
        <v-card class="transparent" width="100%"  flat>
            <v-layout row wrap class="justify-center mx-3 my-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
              <v-dialog width="800">
                  <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  Submit Claim
                </v-btn>
                  </template>
                <v-card max-width="800" >
                <v-expand-transition>
                    <div
                        v-if="FormProcessing"
                        :class="'d-flex transition-fast-in-fast-out '+ThemeColor+' v-card--reveal mediumoverline white--text'"
                        style="height: 100%;"
                    >
                        <v-progress-circular size="130"
                        :width="5"
                        :color="ThemeHeaderColor"
                        indeterminate
                        >Processing</v-progress-circular>
                    </div>
                    </v-expand-transition>
                <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :RelatedEntity="'claims'"
                      :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"
                      @ConfirmSubmission="ConfirmSubmission" :PageName="'Claim Form'" @ProcessingData="ProcessingData"
                      :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="0"
                      :tab="MappedClaim_FormTab" :i="0"
                      />
                </v-card>
                </v-dialog>
                
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%"  flat>
            <v-layout row wrap class="justify-center mx-3 my-3">
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
              <v-img
              class="mx-3 my-3" 
              contain
              :src="Delivery_PhotoThumbURL"
              lazy-src="https://picsum.photos/350/165?random"
              max-height="150"
            ><template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="pop"></v-progress-circular>
                </v-row>
              </template>
              </v-img>
                
          </v-flex>
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
                    <v-text-field v-if="EditingClient" v-model="Client" label="Client"/>
            <v-chip class="my-4" large color="links white--text" v-if="!EditingClient">{{Client}}</v-chip><br>
              
                    <v-text-field v-if="EditingBrand" v-model="Brand" label="Brand"/>
            <v-chip class="my-4" large color="links white--text" v-if="!EditingBrand">{{Brand}}</v-chip><br>
              
                    <v-text-field v-if="EditingModel" v-model="Model" label="Model"/>
            <v-chip class="my-4" large color="links white--text" v-if="!EditingModel">{{Model}}</v-chip><br>
                
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%"  flat>
            <v-layout row wrap class="justify-center mx-3 my-3">
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              <p class="mediumoverline mx-1 my-3">VIN Number</p>
                    <v-text-field v-if="EditingVIN_Number" v-model="VIN_Number" label="VIN Number"/>          
          <p v-if="!EditingVIN_Number">{{VIN_Number}}</p>
              <p class="mediumoverline mx-1 my-3">Engine Number</p>
                    <v-text-field v-if="EditingEngine_Number" v-model="Engine_Number" label="Engine Number"/>          
          <p v-if="!EditingEngine_Number">{{Engine_Number}}</p>
              <p class="mediumoverline mx-1 my-3">Dealer</p>
                    <v-text-field v-if="EditingDealer" v-model="Dealer" label="Dealer"/>          
          <p v-if="!EditingDealer&& Dealer">{{Dealer}}</p>
                
          </v-flex>
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
              <v-layout row class="fill-height align-center mx-2">
                   <v-list two-line subheader light align="left" width="100%">                     
                      <v-list-item v-for="item in Multiple_File_Upload_1" :key="item.itemObjKey" class="basicoutlined">
                        <v-layout row class="justify-start">
                          <v-list-item-avatar>
                            <v-icon>mdi-file-document</v-icon>
                            </v-list-item-avatar>
                           <v-list-item-content>
                                <v-list-item-title>{{item.MultipleFileUpload1PrimarySelectedOption.Name}}</v-list-item-title>
                            </v-list-item-content>                             
                              <v-list-item-action>                             
                                    <v-btn icon @click="GetShortLivedURL(item)">
                                    <v-icon color="grey lighten-1">mdi-download</v-icon>
                                  </v-btn>                              
                              </v-list-item-action>                           
                          </v-layout>
                        </v-list-item>
                    </v-list>
                </v-layout>
                
          </v-flex>          
          </v-layout>      
      </v-card>
       <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
    <v-btn @click="NavigatetoWikiDataMode()" v-if="userLoggedIn && !UserisGuest" icon x-large><v-icon  color="blue" x-large>mdi-database</v-icon>
       </v-btn>

  </v-layout>
  </v-card>
  

  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SiteArticlesFeed from '@/components/Articles/SiteArticle/SiteArticleFeed';
import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTabReadOnly';

export default {
  props: ['AppisDarkMode','UserisGuest'],
  components: {
    SiteArticlesFeed,
    WebFormTab
  },
  data() {
    return {
      Claim_FormTab: {
        Name: 'Web Form',
        FormName: 'Claim Form',
        FromDatabase: true,
        DarkForm: false,
        BGColor: {
              hexa: '#ffffff'},
        HeaderColor: {hex: '#000000'},
        EntityForm: true,
        RelatedEntity: {id: 'customerclaims'},
        FormSubmitted:false,
        DefaultOwner: {
  "Surname": "Louw",
  "Name": "Jacqueline",
  "Full_Name": "Jacqueline Louw",
  "Email": "jacquelinelouw@rapidapps.co.za",
  "id": "XqeOhF4FWRgA5ha6NM0ACTStWDi1"
},
        SingleName:'Customer Claim',
        FormFields: [
  {
    "LookupFieldName": "Full_Name",
    "id": "Client",
    "OnWebForm": true,
    "IsHeader": true,
    "UsedonForm": true,
    "Type": "Lookup",
    "RelatedLocation": "User",
    "Created": false,
    "Name": "Client",
    "DisplayName": "Client",
    "RelatedRecord": "Users",
    "RelatedBuildID": "Users",
    "Primary": false,
    "MappedField": {
      "Name": "Client",
      "Primary": false
    }
  },
  {
    "RelatedLocation": "Sales Contract",
    "Created": false,
    "Primary": false,
    "Name": "Contract",
    "IsHeader": true,
    "Type": "Lookup",
    "DisplayName": "Contract",
    "id": "Contract",
    "RelatedBuildID": "Sales_Contracts",
    "LookupFieldName": "Name",
    "MappedField": {
      "Name": "Name",
      "Primary": true
    },
    "RelatedRecord": "Sales Contracts",
    "UsedonForm": true,
    "OnWebForm": true
  },
  {
    "Type": "Date",
    "id": "Date_of_Symptom",
    "DisplayName": "Date of Symptom",
    "Created": false,
    "Primary": false,
    "OnWebForm": true,
    "Name": "Date_of_Symptom",
    "IsHeader": true,
    "UsedonForm": true
  },
  {
    "IsHeader": true,
    "Created": false,
    "Name": "Description",
    "Type": "Multiple Lines Text",
    "Primary": false,
    "OnWebForm": true,
    "UsedonForm": true,
    "DisplayName": "Description",
    "id": "Description"
  },
  {
    "Created": true,
    "Name": "Title",
    "UsedonForm": true,
    "DisplayName": "Title",
    "id": "Title",
    "IsHeader": true,
    "OnWebForm": true,
    "Primary": true,
    "IsMandatory": true,
    "Type": "Single Line Text",
    "IsSearch": true
  }
],
        StatusDefault: {
  "ID": 1000001,
  "Options": [
    {
      "ID": 1000003,
      "Level": {
        "id": 2,
        "name": "Status Reason",
        "FieldName": "Status_Reason",
        "children": []
      },
      "LevelFieldName": "Status_Reason",
      "Name": "Open"
    }
  ],
  "Level": {
    "children": [
      {
        "name": "Status Reason",
        "children": [],
        "FieldName": "Status_Reason",
        "id": 2
      }
    ],
    "name": "Status",
    "id": 1,
    "FieldName": "Status"
  },
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "Name": "Active"
},
        Status_ReasonDefault: {
  "LevelFieldName": "Status_Reason",
  "Level": {
    "FieldName": "Status_Reason",
    "name": "Status Reason",
    "children": [],
    "id": 2
  },
  "ID": 1000003,
  "Name": "Open"
},
      },
      
      FormProcessing: false,
      AutomationCreatedSnackbar: false,
      AutomationSnackbarDetails: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },

      RelevantTopBannerAds: [],
      salescontractdata: {},
      salescontractswikiarray: [],
      Delivery_Photo: '',
      Delivery_Photo: '',
      Delivery_PhotoThumbURL: '',
      Client: '',
      Brand: '',
      Model: '',
      VIN_Number: '',
      Engine_Number: '',
      Dealer: '',
      Multiple_File_Upload_1: '',
    }
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(!this.UserisGuest){
      let query = db.collection('salescontractswiki')
      this.GetSalesContract(query)
    }
    else{
      let field = 'Clientid'
      let query = db.collection('salescontractswiki').where(field,'==',this.userLoggedIn.id)
      this.GetSalesContract(query)
    }
    if(this.userLoggedIn){
      this.IntranetViewToggle(true)
    }
    else{
      this.IntranetViewToggle(false)
    }
  },

  computed: {
    
    CheckView(){
      if(!this.userLoggedIn) {
        return 'publicnav'
      }
      else if(this.UserisGuest){
        return ''
      }
      else {
        return 'stickytopconscious'
      }
      
    },
    userLoggedIn () {
        return this.$store.getters.user
      },
      
        MappedClaim_FormTab(){
      this.Claim_FormTab.FormFields = this.Claim_FormTab.FormFields.map(field => {
        if(field.MappedField){
          let mappedfield = field.MappedField.Name
          //alert(mappedfield+'id')
          if(field.Type === 'Lookup'){
            field.Array = []
            if(!field.MappedField.Primary){
             field.Response = {
              [field.LookupFieldName]: this[mappedfield],
              id: this.salescontractdata[mappedfield+'id']
            } 
            }
            else{
              field.Response = {
              [field.LookupFieldName]: this.salescontractdata.Name,
              id: this.salescontractdata.id
              }               
            }
            
            field.Array.push(field.Response )
          }
          else if(field.Type !== 'Date'){
            field.Response = this[mappedfield]
          }
          else{
            field.Response = this[mappedfield].toDate()
          }
        }
        return field
      })
      return this.Claim_FormTab
    },        
    },
  
  methods: {
    CurrencyFormatter(value,currency,shortcurrency){
      const currencyformatter = this.PrepareCurrency(currency)
      let formalformat = currencyformatter.format(value)
      return formalformat.split(currency).join(shortcurrency)
    },
    PrepareCurrency(currency){			
      currency
      return new Intl.NumberFormat('en-US', {			
            style: 'currency',			
            currency: currency,			
            minimumFractionDigits: 2			
        })
       
    },	
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          window.open(result.data[0])
        })
    },
    ProcessingData(boolean){
            this.FormProcessing = boolean
        },
    ConfirmSubmission(tab,obj,docid){
            console.log(tab,obj,docid)
            tab.FormSubmitted = true
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record <a :href="'/`+tab.RelatedEntity.SingleName+`/'+`+docid+`" target="_blank">here</a>`
        },

    SubmitEnquiry(){
            let NewEnquiryObject ={
              CreatedOn: new Date(),
              // SubmissionType: 'Web Form',
              // SubmissionForm: 'Enquiry',
              // SubmissionPage: 'Home',Name: this.Name,
              Surname: this.Surname,
              Email: this.Email,
              Telephone_Number: this.Telephone_Number,
              Message: this.Message,
            }
            let data = {
              EnquiryObject: NewEnquiryObject,
            }
            console.log(NewEnquiryObject)
            const functions = firebase.functions();
            const UnauthorizedEnquiry = functions.httpsCallable('UnauthorizedEnquiry');
            UnauthorizedEnquiry(data).then(result => {
                    console.log('sent enquiry')
                    console.log(result);
                    let oldform = document.getElementById('Enquiryform')
                    let formheight = oldform.style.height
                    console.log('formheight',formheight)
                    let newform = document.getElementById('PostEnquiryform')
                    oldform.style.display = "none"
                    newform.style.display = "block"
                    newform.style.height = formheight
                    }).catch(error => {
                    console.log("error",error);
                    });
            
            
          },
    NavigatetoWikiDataMode(){
      confirm('This will navigate you to the backend of this record. Do you want to continue?') && this.ProcessNavigateWikiDataMode()
    },
    ProcessNavigateWikiDataMode(){
      let newpath = '/'+this.$route.path.split('/')[1].split('-Wiki')[0]+'/'+this.salescontractdata.id
      console.log(newpath)
      this.IntranetViewToggle(false)
      this.$router.push(newpath)
    },
    IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    BreakPointCol(int){
        if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
        return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return int
        }
        else{
          return int
        }
      },
    getImgUrl(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      GetSalesContract(query){
        let vm = this
      return new Promise(function(resolve, reject) {
        query.get().then(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.salescontractswikiarray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
             resolve(vm.salescontractswikiarray)
              })
          }).then(snapshot => {
          vm.salescontractdata = snapshot.find(obj => obj.Name.split(' ').join('') === vm.$route.params.id.split(' ').join(''))
        var salescontractdata = vm.salescontractdata
        vm.$emit('PushRecordName',vm.salescontractdata.Name)
        if(vm.userLoggedIn){
          let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
          let RecordSpecificBannerAds =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',true).where('RecordName','==',vm.salescontractdata.Name)
          vm.GetBannerAds(RouteBannerAdsRef)
          vm.GetBannerAds(RecordSpecificBannerAds)
         
        }
        if(!vm.userLoggedIn){
          let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
          let PublicRecordSpecificBannerAds =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',true).where('RecordName','==',vm.salescontractdata.Name)
          vm.GetBannerAds(PublicRouteBannerAdsRef)
          vm.GetBannerAds(PublicRecordSpecificBannerAds)
         
        }
        if(salescontractdata.Delivery_Photo){
          this.Delivery_Photo = salescontractdata.Delivery_Photo
            }
        
        
        if(salescontractdata.Delivery_Photo){
          this.Delivery_Photo = salescontractdata.Delivery_Photo
            }
          if(salescontractdata.Delivery_Photo){
          this.Delivery_PhotoThumbURL = salescontractdata.Delivery_PhotoThumbURL
          }
        
        if(salescontractdata.Client){
          this.Client = salescontractdata.Client
            }
        
        if(salescontractdata.Brand){
          this.Brand = salescontractdata.Brand
            }
        
        if(salescontractdata.Model){
          this.Model = salescontractdata.Model
            }
        
        if(salescontractdata.VIN_Number){
          this.VIN_Number = salescontractdata.VIN_Number
            }
        
        if(salescontractdata.Engine_Number){
          this.Engine_Number = salescontractdata.Engine_Number
            }
        
        if(salescontractdata.Dealer){
          this.Dealer = salescontractdata.Dealer
            }
        
        if(salescontractdata.Multiple_File_Upload_1){
          this.Multiple_File_Upload_1 = salescontractdata.Multiple_File_Upload_1
            }
        
        
      })
      },
         
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
  }
}


</script>

<style>
.delivery_photocontentgrad {
  background-image: linear-gradient(to right, rgba(255,0,0,0.5), rgb(255,0,0, 1));
}




















#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.stickytopconscious{
  top: 100px
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}
</style>
          
            
        