
<template>
    
             <v-card class="transparent footermargin" flat>
            
              <v-card class="my-3"  :href="'/SystemTraining/' + article.id" v-for="article in RelatedBlogs.slice(0,Slice)" :key="article.id">
                 <v-card-title>                
                <span class="subheader">{{ article.title }}</span>
                 </v-card-title>
                <v-img
                  max-height="100px"
                  v-bind:src="article.image"
                ></v-img>
                 <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                 </v-card-text>
                <v-card-actions>
                <v-chip x-small :to="'/userprofile/' + article.authorid" medium color="primary" class="white--text">
                  {{article.author}}
                </v-chip>
                               <v-chip x-small color="success" class="white--text">
                  {{article.PublishStatus}}
                </v-chip>                
                
                
              </v-card-actions>
            </v-card>
           
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','Origin','clientDB','Filter','Excerpt','Slice'],
    components: {
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
          
            groupblogs: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
        RelatedBlogs () {
            return this.groupblogs
            // .filter(blog => {
            //     let bloggroup = this.groups.find(obj => obj.id === blog.Groupid)
            //     blog.category = bloggroup.GroupCategory
            //     console.log(blog.grouptype)
            //     let blogmatch = this.Filter.find(obj => obj.Name === blog.grouptype)
            //     if(blogmatch){
            //         return blog
            //     }
            // })
        },

        BlogExcerpt () {
          return this.RelatedBlogs.filter(blog => {
            return blog.content === this.blog.content
          })
        }
    },

    created() {
      this.getGroups()
        this.getGroupBlogs()
        console.log(this.userid)
},
    methods: {
      getGroups(){
            
           console.log(this.Filter)
           let query = db.collection('groups')
           query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.groups.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
        },
         getGroupBlogs() {
           let query = db.collection('systemtraining')
         
           query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.groupblogs.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('System_Training_Documents/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('systemtraining').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>
    