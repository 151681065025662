
<template>
<v-content>
  
  <v-dialog v-model="ViewTicket" max-width="600px" v-if="editedItem">
     <v-card width="100%">
       <v-card-title>View Ticket - GP Combrink Vehicle Sales & Finance</v-card-title>
       <v-layout class="justify-center">
      <v-img src='@/assets/logo.png' contain height="100px">
        </v-img>
        </v-layout>
       <v-card-text>
      
          <v-layout row>
              <table>
                  <td>
                      <p style="font-size:16px;">Logged By:</p>
                  </td>
                  <td>
                      {{editedItem.User.Full_Name}}
                  </td>
              </table>
              <table>
                  <td>
                      <p style="font-size:16px;">Logged On:</p>
                  </td>
                  <td>
                      {{editedItem.Created_On.toDate()}}
                  </td>
              </table>
              </v-layout>
          <v-layout row class="my-3">
            <b>Comment:</b>
          </v-layout>
          <v-layout row>
            <p style="font-size:24px;">{{editedItem.Description}}</p>
          </v-layout>
          <v-layout row class="my-3 mx-3 justify-end">
            <i>This ticket is logged as a {{editedItem.Priority}} Priority</i>
          </v-layout> 
 
          <v-layout row>
           <v-checkbox v-model="editedItem.IsRoadmapItem" label="Convert to Roadmap"></v-checkbox>
          </v-layout>         
          </v-card-text>
          <v-layout class="justify-center">
          <v-card width="90%" flat class="transparent">
          <v-card-text width="80%" class="basicoutlined" id="FeedbackBox" v-html="editedItem.Feedback" contenteditable> 

          </v-card-text>
          </v-card>
          </v-layout>
          <v-card-actions>
            <v-layout class="justify-end">            
            <v-btn @click="CancelProcessUpgradetoRoadmap()" dark color="warning">Cancel</v-btn>
            <v-btn @click="ProcessUpgradetoRoadmap()" dark color="success">Submit</v-btn>
            </v-layout>
          </v-card-actions> 
     </v-card>
  </v-dialog>
  <v-data-table
    :headers="headers"
    :items="ComputedSystemTickets"
    class="elevation-1"
    :single-select="singleSelect"
    item-key="Name"
    group-by="Priority"
    show-group-by
    show-select
    v-model="ScopeMarkedforRollup"
  >
    <template v-slot:top>
      <v-toolbar flat color="blue darken-4 white--text">
        <v-toolbar-title>Project Scope</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-list dark class="transparent">
                  <v-list-item>
                      <v-list-item-content>
                        <v-switch dark v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                      </v-list-item-content>
                   <v-list-item-content v-if="ScopeMarkedforRollup.length>0">
                        <v-btn @click="ConvertToRoadmap()">Convert to Roadmap Item</v-btn>
                      </v-list-item-content>
                  </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <!-- <v-text-field dark
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon> -->
        <v-spacer></v-spacer>
        <v-menu>
          <template v-slot:activator="{ on }">
             <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
          </template>
          <v-list color="primary">
            
            <v-list-item>
                <v-list-item-content @click="ActivateProjectScopeForm()">
                  Add Scope
                </v-list-item-content>
              <v-list-item-content>
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-content>
              </v-list-item>
              
              <v-list-item @click="PrepScopeItemsForTasks()">
                  <v-list-item-content>                    
                    Rollup as Tasks
                  </v-list-item-content>
                  <v-list-item-content>                    
                    <v-icon>mdi-backup-restore</v-icon>
                  </v-list-item-content>
              </v-list-item>
           </v-list>
        </v-menu>
      </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small v-if="item"
        class="mr-2"
         @click="PrepareScopeData(item)"
      >
        mdi-cogs
      </v-icon>
    </template>
    <template v-slot:[`item.User`]="{ item }">
    <a :href="'/User/'+item.id" target="_blank">{{item.User.Full_Name}}</a>
    </template>
    <template v-slot:[`item.Status`]="{ item }">
    <span v-if="item.RoadMapTask"><v-icon @click="NavigatetoRoadmap(item)">mdi-road-variant</v-icon>{{item.Status}}</span>
    <span v-if="!item.RoadMapTask">{{item.Status}}</span>
    </template>
    <template v-slot:[`item.Created_On`]="{ item }">
    {{TimestampFormatterSTRING(item.Created_On.toDate())}}
    </template>
  </v-data-table>
</v-content>   
</template>

<script>
import firebase from 'firebase';
import format from 'date-fns/format'
import db from '@/main'

export default {
    props: ['UserRecord','UsersArray','View','RelatedObj','SystemRoadmapsBuilt','LastRoadmapTaskID','SystemObj'],
  components: {
  },
    data() {
      // User: this.UserRecord,
      //     Description: this.Description,
      //     RelatedObj: this.RelatedObj,
      //     View: this.View,
      //     ViewURL: window.location.href,
      //     Created_On: new Date(),
      //     Priority: this.Priority
        return {
          STDActionTypes: [
  {
    "Name": "Bug Fixes",
    "Icon": "mdi-bug",
    "Color": "red"
  },
  {
    "Name": "Additional Features",
    "Icon": "mdi-plus",
    "Color": "green"
  },
  {
    "Name": "Field Management",
    "Icon": "mdi-clipboard-list",
    "Color": "warning"
  },
  {
    "Name": "Style and Layout",
    "Icon": "mdi-palette-advanced",
    "Color": "purple"
  },
  {
    "Name": "Dashboards and Reporting",
    "Icon": "mdi-desktop-mac-dashboard",
    "Color": "indigo"
  },
  {
    "Name": "Content Management",
    "Icon": "mdi-content-copy",
    "Color": "red"
  },
  {
    "Name": "Data Management",
    "Icon": "mdi-database",
    "Color": "blue"
  },
  {
    "Name": "System Setup",
    "Icon": "mdi-view-dashboard",
    "Color": "red"
  },
  {
    "Name": "Navigation",
    "Icon": "mdi-sign-direction",
    "Color": "pink"
  },
  {
    "Name": "Security",
    "Icon": "mdi-security-network",
    "Color": "cyan"
  }
],
          ProjectScope: [],
          editedItem: '',
          ViewTicket: false,
          SystemTickets: [],
          headers: [
        { text: 'Description', value: 'Description'},
        { text: 'User', value: 'User'},
        { text: 'Priority', value: 'Priority'},
        { text: 'Created On', value: 'Created_On'},
        { text: 'Status', value: 'Status'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
          ScopeMarkedforRollup: [],
          singleSelect: false,
          TicketLoggedSnackbar: false,
          LogTicketDialog: false,
          RoadMapDialog: false,
          Priority: '',
          Description: '',
          TaskCategory: '',
          TaskName: '',
          TaskStatus: '',
          selectedProjectScopeItem: '',
          TaskDueDatemenu: false,
          TaskDueDate: '',
          TaskDescription: '',
          StatusTypes: [
            'Incoming',
            'Executing',
            'QA',
            'Publish',
            'Complete'
            ],
            
            rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            
        }
    },
    created(){
      this.getTickets()
      this.CheckRouting()
    },
    computed: {

      ComputedSystemTickets(){
             return this.SystemTickets.map(ticket => {
               if(ticket.IsRoadmapItem){
                 if(!ticket.RoadMapTask){
                   ticket.Priority = 'Roadmap Request - '+ticket.Priority
                 }
                 
               }
               return ticket
             })
           }     
    },
    mounted () {
        if(this.RelatedObj){
      this.LogTicketDialog = true
        }
    },
    methods: {
      CheckRouting(){
        setTimeout(() => {
          if(this.$route.params.id){
            let item = this.ComputedSystemTickets.find(obj => obj.id === this.$route.params.id)
            this.PrepareScopeData(item)
          }  
        }, 2000);
        
      },
      NavigatetoRoadmap(item){
        this.$router.push({name: 'Roadmap',params: { Active: item.RoadMapTask }})
      },
      DateFormatter(date){
            if(date){
                //console.log(date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },
      ProcessUpgradetoRoadmap(){
        let Feedback = document.getElementById('FeedbackBox').innerHTML
       
        db.collection('systemtickets').doc(this.editedItem.id).update({
          IsRoadmapItem: this.editedItem.IsRoadmapItem
        })
        
        db.collection('systemtickets').doc(this.editedItem.id).update({
          Feedback: Feedback
        })
        this.CancelProcessUpgradetoRoadmap()

      },
      CancelProcessUpgradetoRoadmap(){
        this.editedItem = ''
        this.ViewTicket = false
      },
      PrepareScopeData(item){
        this.editedItem = item
        if(!this.editedItem.Feedback){
          this.editedItem.Feedback = ''
        }
        this.ViewTicket = true
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
      getTickets(){
        db.collection('systemtickets').where('Systemid','==','QSHCimvRtK2k5IgF1edf').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.SystemTickets.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
  
      })
      })
    
      
    },
      SubmitSystemTicket(){
        let NewTicket = {
          User: this.UserRecord,
          Description: this.Description,
          RelatedObj: this.RelatedObj,
          View: this.View,
          ViewURL: window.location.href,
          Created_On: new Date(),
          Priority: this.Priority
        }
        let vm = this
        db.collection('systemtickets').add(NewTicket).then(doc => {
          vm.TicketLoggedSnackbar = true
          setTimeout(() => {
            vm.CancelSubmitSystemTicket()
          }, 2000);
          
        })
      },
      CancelSubmitSystemTicket(){
        this.$emit('CancelSubmitSystemTicket')
      },
      
    },
}
</script>

<style>
</style>
    