<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
      <!-- ActiveSession {{ActiveSession}} -->
       <v-dialog max-width="800" persistent v-model="AutomationDialog">	
                           	
                                <v-snackbar v-model="AutomationCreatedSnackbar" :timeout="4000" top color="success">	
                                    <span v-html="AutomationSnackbarDetails"></span>	
                                    <v-btn color="white" @click="AutomationCreatedSnackbar= false">Close</v-btn>	
                                    </v-snackbar>	
                                    <!-- <template v-slot:activator="{ on }">	
                                        <v-btn :color="ThemeColor" @click="whatbtn(Autobutton)" small dark v-on="on">{{Autobutton.ButtonName}}</v-btn>	
                                    </template> -->	
                                    <v-card max-width="800" >	
                                        <v-stepper v-model="automationstep"  non-linear>	
                                            <v-stepper-header><span  v-for="n in AutoSteps" :key="n">	
                                                <v-stepper-step :step="n">	
                                                    {{AutoStepName(n)}}   <v-divider v-if="i !== 3"/>                                         	
                                                </v-stepper-step></span>	
                                            </v-stepper-header>	
                                             <v-stepper-content step="1">	
                                                <v-card flat class="transparent">	
                                                <v-card-title>	
                                                    We will do the following steps:	
                                                </v-card-title>	
                                                <v-card-text>	
                                                    <ul>	
                                                        <li v-for="n in AutoSteps" :key="n">	
                                                            {{AutoStepName(n)}}	
                                                        </li>	
                                                    </ul>	
                                                </v-card-text>	
                                                <v-card-text v-if="Autobutton.Steps && Autobutton.Steps.length>0">	
                                                  However, the record neds to here to these rules:
                                                </v-card-text>	
                                                </v-card>                                               	
                                                </v-stepper-content>	
                                            </v-stepper>	
                                       	
                                        <v-expand-transition>	
                                            <div	
                                                v-if="FormProcessing"	
                                                :class="'d-flex transition-fast-in-fast-out '+ThemeColor+' v-card--reveal mediumoverline white--text'"	
                                                style="height: 100%;"	
                                            >	
                                                <v-progress-circular size="130"	
                                                :width="5"	
                                                :color="ThemeHeaderColor"	
                                                indeterminate	
                                                >Processing</v-progress-circular>	
                                            </div>	
                                            </v-expand-transition>	
                                        <!-- <v-card-title class="title font-weight-regular justify-space-between">	
                                            	
                                                <span>{{ Autobutton.Windowtitle }}</span>	
                                                <v-avatar	
                                                    color="primary lighten-2"	
                                                    class="subheading white--text"	
                                                    size="24"	
                                                    v-text="automationstep"	
                                                ></v-avatar>	
                                                </v-card-title> -->	
                                        <!-- <v-card-title> -->                                            	
                                        <!-- </v-card-title> -->	
                                        <v-window v-model="automationstep">	
                                                <v-window-item :value="1">	
                                                    	
                                                    <v-list v-if="Autobutton && Autobutton.Steps && Autobutton.Steps.length>0">
                                                        <v-list-item-title class="mx-1 green--text" v-if="Autobutton.Pass">
                                                            This Record Complies with the Required Rules
                                                        </v-list-item-title>    
                                                        <v-list-item-title class="mx-1 red--text" v-if="!Autobutton.Pass">
                                                            This Record does NOT Comply with the Required Rules
                                                        </v-list-item-title>
                                                       <v-list-item v-for="condstep in Autobutton.Steps" :key="condstep.itemObjKey">   
                                                            <v-list-item-content>   
                                                                <v-list-item-title> 
                                                                    Check: <span class="green--text" v-if="condstep.Pass">{{condstep.CheckParams}}</span><span class="red--text" v-if="!condstep.Pass">{{condstep.CheckParams}}</span>  
                                                                </v-list-item-title>    
                                                                <v-list-item-subtitle class="blue--text">   
                                                                   Value: {{condstep.CheckValue}}   
                                                                </v-list-item-subtitle> 
                                                            </v-list-item-content>  
                                                            <v-list-item-avatar>    
                                                                <v-icon :color="condstep.Color">    
                                                                    {{condstep.icon}}   
                                                                </v-icon>   
                                                            </v-list-item-avatar>   
                                                        </v-list-item>
  
                                                    </v-list>   
	
                                                </v-window-item>	
                                               	
                                                <v-window-item  :value="i+2" v-for="(action,i) in Autobutton.ResultActions" :key="action.ItemObjKey">
                                                    	
                                                    <v-card-text v-if="action.ActionType === 'Create'">	
                                                        <v-list-item>	
                                                            <v-text-field v-model="action.FormEmailRecipient" :rules="[rules.email]" label="Send Email?"/>	
                                                            <v-select clearable v-model="action.EmailtoUser" return-object :items="UsersArray" @change="UpdateFormEmailRecipient(action)" item-text="Full_Name" label="Email to"/>	
                                                        </v-list-item>	
                                                       <WebFormTab v-if="action.ActionType === 'Create'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	
                                                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"	
                                                            @ConfirmSubmission="ConfirmSubmission" :PageName="action.PageName" @ProcessingData="ProcessingData"	
                                                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"	
                                                            :tab="action" :i="i"	
                                                            />	
                                                            	
                                                        </v-card-text>	
                                                        <v-card-title v-if="action.ActionType === 'Update' && action.Record.id === ActiveSession.buildid">	
                                                           	
                                                            <span v-if="action.ActionType === 'Update' && action.Record.id === ActiveSession.buildid">	
                                                                Update "{{ProcessRecord[action.Record.RecordPrimaryField.Name]}}"</span>	
                                                        </v-card-title>	
                                                        <v-card-text v-if="action.ActionType === 'Update'">	
                                                            <WebFormTab v-if="action.ActionType === 'Update'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	
                                                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"	
                                                            @ConfirmSubmission="ConfirmSubmission" :PageName="action.PageName" @ProcessingData="ProcessingData"	
                                                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"	
                                                            :tab="action" :i="i"	
                                                            />	
                                                             <!-- <v-list-item v-if="action.StatusReasonUpdate">	
                                                              	
                                                        </v-list-item>	
                                                        <v-list-item v-if="action.StatusReasonUpdate">	
                                                            {{action.StatusReasonUpdate}}	
                                                        </v-list-item>	
                                                        <v-list-item v-if="action.FieldMapping">	
                                                            {{action.FieldMapping}}	
                                                        </v-list-item> -->	
	
                                                        </v-card-text>	
                                                </v-window-item>	
	
                                                <!-- <v-window-item :value="3">	
                                                    <v-card-text>	
                                                    <v-select return-object v-model="ActiveProcess" item-text="DisplayName" :items="ActiveSession.EntityProcesses" label="Process"></v-select>	
                                                        <v-select v-if="ActiveProcess" return-object v-model="ActiveStage" item-text="Name" :items="ActiveProcess.ProcessStages" label="Stage"></v-select>	
                                                    </v-card-text>	
                                                </v-window-item>	
                                                <v-window-item :value="4">	
                                                    <v-card-text>	
                                                        This will now update the Record	 Click "{{UnAssignedButton}}" below to confirm
                                                    </v-card-text>	
                                                </v-window-item> -->	
                                                </v-window>	
	
                                                <v-divider></v-divider>	
	
                                                <v-card-actions>	
                                                    <v-btn dark :color="ThemeColor" @click="AutomationDialog = !AutomationDialog,Autobutton = '',ProcessRecord = ''"> Cancel
                                                         	
                                                    </v-btn>	
                                                <v-btn	
                                                    :disabled="automationstep === 1"	
                                                    text	
                                                    @click="automationstep--"	
                                                >	
                                                    Back	
                                                </v-btn>	
                                                <v-spacer></v-spacer>	
	
                                                  <!-- :disabled="automationstep > 1" -->	
                                                
                                                <v-btn v-if="Autobutton && Autobutton.ResultActions"	
                                                    :disabled="AutoAdvanceDisabled"	
                                                    dark	
                                                    :color="UnAssignedButtonColor"	
                                                    depressed	
                                                    @click="AdvanceAutomationButton()"	
                                                >	
                                                    {{UnAssignedButton}}	
                                                </v-btn>	
                                                </v-card-actions>	
                                    </v-card>	
                            </v-dialog>	
	

      <v-dialog max-width="500" v-model="TimelineDialog">
                           
        <v-card
            class="mx-auto"
            max-width="500"
            height="100%"
        >
        <v-card-title>
            Liaison <v-spacer></v-spacer>
            <v-btn color="blue" icon target="_blank" :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+TimelineRecord.id">
                <v-icon color="blue">mdi-database</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text v-if="!TimelineRecord.HasClientRecord && ActiveSession.ClientLookupField && !TimelineRecord[ActiveSession.ClientLookupField.Name]">
            There is no {{ActiveSession.ClientLookupField.Name}} linked to the record
            <v-btn :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+TimelineRecord.id">See Record</v-btn>
        </v-card-text>
        <v-card-text v-if="!TimelineRecord.HasClientRecord && ActiveSession.ClientLookupField && TimelineRecord[ActiveSession.ClientLookupField.Name]">
            <v-dialog max-width="500">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on">Start Liaison</v-btn>
                </template>
                <v-card max-width="500">
                    <v-card-title class="largeoverline">
                        Start Liaison
                    </v-card-title>
                    <v-card-text>
                        <p class="caption red--text">
                            This will allow client <span class="blue--text">{{TimelineRecord[ActiveSession.ClientLookupField.Name][ActiveSession.ClientLookupField.LookupFieldName]}}</span> to see everythign you put in here, and open up a channel for them to communicate back to
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn>
                            Cancel
                        </v-btn>
                        <v-spacer>
                        </v-spacer>
                        <v-btn @click="CreateClientRecord(TimelineRecord)">
                            Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
        <v-card-text v-if="TimelineRecord.HasClientRecord || !ActiveSession.ClientLookupField">
            <v-timeline dense clipped>
                <v-timeline-item v-if="TimelineRecord.CanEdit"
                    fill-dot
                    class="white--text mb-12"
                    color="orange"
                    large
                >
                    <template v-slot:icon>
                    <span>{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}</span>
                    </template>
                        
                    <v-text-field
                    v-model="input"
                    hide-details
                    flat
                    label="Leave a comment..."
                    solo
                    @keydown.enter="comment(TimelineRecord,null,LiasonUploadFile)"
                    >
                    <template v-slot:append>
                    <v-menu
                        
                        top
                        offset-y
                        :close-on-content-click="false"
                        >
                        <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-link</v-icon>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-text-field v-model="NewLink" label="link" :rules="[rules.url]"/>
                            </v-list-item>
                            <v-list-item-action>
                                <v-btn @click="link(NewLink,TimelineRecord)">Save</v-btn>
                            </v-list-item-action>
                        </v-list>
                        </v-menu>

                        <input v-if="!LiasonUploadFile"
                            style="display:none"
                            type="file"
                            @change="onFileselected($event)"
                            id="fileInputer">
                        <v-icon
                        class="mx-0"
                        depressed
                        @click="FileInput('fileInputer')"
                        >
                        mdi-paperclip
                        </v-icon>
                            <v-menu
                        
                        top
                        offset-y
                        v-if="ActiveSession.DigitizedForms.length >0"
                        >
                        <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        class="mx-0"
                        depressed
                        
                        >
                        mdi-dots-vertical-circle-outline
                        </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="comment(TimelineRecord,form)" v-for="form in ActiveSession.DigitizedForms" :key="form.itemObjKey">
                                {{form.Name}}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        class="mx-0"
                        small
                        @click="comment(TimelineRecord,null,LiasonUploadFile)"
                        >
                        Post
                        </v-btn>
                    </template>
                    </v-text-field>
                    <!-- ActiveSession {{ActiveSession}} -->
                    
                </v-timeline-item>
                <v-timeline-item
                    class="mb-4"
                    color="grey"
                    icon-color="grey lighten-2"
                    small
                    :hide-dot="comment.hidedot"
                    v-for="comment in ByLatestLiason(TimelineRecord[ActiveSession.LiaisonProp])" :key="comment.itemObjKey">
                
                    <v-row justify="space-between">
                    <v-col cols="7">
                        <v-chip
                        class="white--text ml-0"
                        :color="comment.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
                        label
                        small
                        >
                        {{comment.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{comment.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-chip>
                        <span v-html="comment.description"></span>
                    </v-col>
                    <v-col
                        class="text-right caption"
                        cols="5"
                    >
                        {{comment.Created_On.toDate()}}
                    </v-col>
                    <v-row justify="justify-center" v-if="comment.ThumbURL">        
                        <v-avatar tile size="150">
                        <v-img contain
                            :src="comment.ThumbURL"
                                />
                        </v-avatar>
                        </v-row>
                    </v-row>
                        <v-row justify="space-between my-6" v-if="comment.fileurl">
                        <!-- ActiveRecord.name+' - '+Uploadfilename -->
                        <v-btn small v-if="!comment.TokenURL"
                        download
                        @click="GetShortLivedURL(comment)"
                        class="blue--text mx-0"
                        >
                        Get View Token <v-icon small>mdi-security</v-icon>
                        </v-btn>
                        <v-btn small v-if="comment.TokenURL"
                         :href="comment.TokenURL" target="_blank"
                        class="orange--text mx-0"
                        >
                        View File <v-icon>mdi-download</v-icon>
                        </v-btn>
                            <v-btn small v-if="!comment.Filed && ActiveSession.DocumentRegisters && ActiveSession.DocumentRegisters.length>0 || !comment.Filed && ActiveSession.Galleries && ActiveSession.Galleries.length>0"
                        @click="OpenSaveFiletoRecord(comment)"
                        class="success--text mx-0"
                        >
                        Save to Record <v-icon color="success">mdi-content-save-move-outline</v-icon>
                        </v-btn>
                                
                        </v-row>
                    <v-row justify="space-between my-6" v-if="comment.FormObj">
                        <v-btn
                        @click="OpenForm(comment.FormObj,comment)"
                        class="blue--text mx-0"
                        >
                        Open Form
                        </v-btn>
                        </v-row>

                </v-timeline-item>
                    <v-timeline-item>
                    <v-btn @click="slice = slice+4">More</v-btn>
                    <v-btn v-if="slice>4" @click="slice = slice-4">Less</v-btn>
                </v-timeline-item>
            </v-timeline>
        </v-card-text>
        </v-card>
    </v-dialog>

      <v-dialog v-model="FiletoRecordDialog" max-width="500">
       
        <v-card max-width="500">
            <v-card-title class="largeoverline">
                Save to Record 
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-select v-model="DocumentTable" :items="DocumentsAndGalleries" item-text="DisplayName" return-object label="Document Table">
                        </v-select>
                    </v-list-item>
                    <v-list-item>
                        <v-select v-if="DocumentTable && !DocumentTable.IsGallery" return-object v-model="DocumentType" item-text="Name" :items="DocumentTable.DocumentPrimaryOptions" label="Document Type">
                        </v-select>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelSaveFiletoRecord()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveFiletoRecord(ActiveComment)">Save</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
      <v-dialog v-model="AssignRecordDialog" max-width="400">
          <v-card max-width="400">
              <v-card-title class="title font-weight-regular justify-space-between">
                    <span>{{ UnassignedWindowcurrentTitle }}</span>
                    <v-avatar
                        color="primary lighten-2"
                        class="subheading white--text"
                        size="24"
                        v-text="unassignedstep"
                    ></v-avatar>
                    </v-card-title>
              <v-window v-model="unassignedstep">
                    <v-window-item :value="1">
                         <v-card-text v-if="EntityHasWebForm" v-html="Step1Body">
                  
                            </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text>
                               
                                <v-select return-object v-model="AssigningRecord.Owner" item-text="Full_Name" :items="UsersArray" label="Owner"></v-select>
                        
                            </v-card-text>
                    </v-window-item>

                    <v-window-item :value="3">
                        <v-card-text>
                           <v-select return-object v-model="ActiveProcess" item-text="DisplayName" :items="ActiveSession.EntityProcesses" label="Process"></v-select>
                            <v-select v-if="ActiveProcess" return-object v-model="ActiveStage" item-text="Name" :items="ActiveProcess.ProcessStages" label="Stage"></v-select>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="4">
                        <v-card-text>
                            This will now update the Record, Click "{{UnAssignedButton}}" below to confirm
                        </v-card-text>
                    </v-window-item>
                    </v-window>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-btn
                        :disabled="unassignedstep === 1"
                        text
                        @click="unassignedstep--"
                    >
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        dark
                        :color="UnAssignedButtonColor"
                        depressed
                        @click="AdvanceUnAssignedRecord()"
                    >
                        {{UnAssignedButton}}
                    </v-btn>
                    </v-card-actions>
          </v-card>
      </v-dialog>

      <!-- <v-tabs centered>
          <v-tab>
              Unassigned
          </v-tab>
          <v-tab-item> -->
              <v-list
                v-if="!ActiveRecord"
                :dark="AppisDarkMode"
                dense                
                nav
                >                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">mdi-alert-rhombus-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">Unassigned</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
              <v-list  :dark="AppisDarkMode"  >
                <v-list-item v-for="record in UnassignedArray" :key="record.itemObjKey"  @click="AssignItem(record)">
                    <v-list-item-icon>
                    <v-icon small>{{ActiveSession.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="record.class">{{ record[ActiveSession.RecordPrimaryField.Name] }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="record.NotificationCount && record.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="record.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
              </v-list>
              <v-list
                v-if="!ActiveRecord"
                :dark="AppisDarkMode"
                dense                
                nav
                >                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">mdi-pipe</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">Processes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
              <v-list  :dark="AppisDarkMode"  >
                <v-list-item v-for="process in ProcessesArray" :key="process.itemObjKey" :class="process.class"  @click="PushItem(process)">
                    <v-list-item-avatar class="mx-2">
                    <v-icon small>{{ActiveSession.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="process.headerclass">{{ process.Name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                       <v-list-item-title > Records</v-list-item-title>
                        <v-list-item-subtitle >{{process.ActiveData.length}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- <v-list-item-action v-if="record.NotificationCount && record.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="record.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action> -->
                </v-list-item>
            </v-list>
              </v-list>
          <!-- </v-tab-item>
          <v-tab>
              Pipeline
          </v-tab>
          <v-tab-item> -->
            
              <v-card-title v-if="ActiveRecord">                  
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        </v-card-title>
        <v-card-text v-if="ActiveRecord && Tabs === 1">
            <v-btn id="ArchivesButton" @click="StatusFilter.ID === 1000001 ? StatusFilter.ID = 1000002 : StatusFilter.ID = 1000001">Toggle Historic</v-btn>
        </v-card-text>
        <v-tabs centered v-if="ActiveRecord" v-model="Tabs">
            <v-tab>Chart
            </v-tab>
            <v-tab-item>
                <DashboardBuilder :UsersArray="UsersArray" :PushedElmnt="ActiveRecord"/>
            </v-tab-item>
            <v-tab>Actions
            </v-tab>
           <v-tab-item v-if="StatusFilter.ID !== 1000001">
                <v-list>
                    <v-list-item v-for="record in InactiveDataArray" :key="record.itemObjKey">
                         <v-list-item-avatar class="mx-2">
                    <v-icon small>{{ActiveSession.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="record.headerclass">{{ record[ActiveSession.RecordPrimaryField.Name] }}</v-list-item-title>
                    </v-list-item-content>
                   <v-list-item-action>
                             <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            <v-btn color="blue" @click="ReadNotices(record),OpenLiasonDialog(record)" :id="record.id+'responses'"><v-icon color="white">mdi-comment-edit</v-icon></v-btn>
                            </v-badge>
                             <v-btn color="blue" @click="OpenLiasonDialog(record)" v-if="!record.NotificationCount" :id="record.id+'responses'"><v-icon color="white">mdi-comment-edit</v-icon></v-btn>                           
                    </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-tab-item>

            <v-tab-item v-if="StatusFilter.ID === 1000001">
                <v-dialog max-width="1000" v-model="OpenFormComponent">
    <DigitizedFormComponent v-if="OpenFormComponent && ActiveForm" @SubmitForm="SubmitForm" :ActiveForm="ActiveForm" :comment="ActiveComment" :userLoggedIn="userLoggedIn"/>
  </v-dialog>
                <v-list
                v-for="stage in ActiveRecord.ProcessStages" :key="stage.itemObjKey"
                :dark="AppisDarkMode"
                dense                
                nav
                >                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">mdi-pipe</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">{{stage.Name}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
              <v-list  :dark="AppisDarkMode"  >
                <v-list-item v-for="record in GetMatchingStatus(stage)" :key="record.itemObjKey" :class="record.class">
                    <v-list-item-avatar class="mx-2">
                    <v-icon small>{{ActiveSession.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="record.headerclass">{{ record[ActiveSession.RecordPrimaryField.Name] }}</v-list-item-title>
                    </v-list-item-content>
                   <v-list-item-action>
                             <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            <v-btn color="blue" @click="ReadNotices(record),OpenLiasonDialog(record)" :id="record.id+'responses'"><v-icon color="white">mdi-comment-edit</v-icon></v-btn>
                            </v-badge>
                             <v-btn color="blue" @click="OpenLiasonDialog(record)" v-if="!record.NotificationCount" :id="record.id+'responses'"><v-icon color="white">mdi-comment-edit</v-icon></v-btn>                           
                    </v-list-item-action>
                    <v-list-item-action v-if="record.CanEdit">
                       <!-- <v-list-item-title > Records</v-list-item-title>
                        <v-list-item-subtitle >{{record}}</v-list-item-subtitle> -->
                        <v-btn color="grey" v-on="on"><v-icon :color="ThemeHeaderColor">mdi-pipe</v-icon></v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="record.CanEdit">
                        <v-dialog 
                            max-width="400">
                            <template v-slot:activator="{ on }">
                                <v-btn color="warning" v-on="on" @click="Status = Object.assign({},record.Status),Status_Reason = Object.assign({},record.Status_Reason)"><v-icon :color="ThemeHeaderColor">mdi-power</v-icon></v-btn>
                            </template>
                            <v-card width="400">
                                <v-card-title>Change Status</v-card-title>
                                <v-card-text>
                                 <v-autocomplete class="mx-5"
                                :items="ActiveSession.StatusOptions"
                                v-model="Status"
                                item-text="Name"
                                label="Status"
                                autowidth
                                return-object
                                chips
                                flat
                                />
                                <v-autocomplete class="mx-5"
                                 v-if="Status"
                                :items="Status.Options"
                                v-model="Status_Reason"
                                item-text="Name"
                                label="Status_Reason"
                                autowidth
                                return-object
                                chips
                                flat
                                />

                                </v-card-text>
                                <v-card-actions>
                                <v-btn @click="ResetStatus(record)">Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn @click="UpdateStatus(record)">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-list-item-action>

                    <v-list-item-action v-if="record.CanEdit">
                    <v-menu 
                    v-if="record.AutomationButtons && record.AutomationButtons.length > 0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="200px">
                    <template v-slot:activator="{ on }">
                        <v-btn :color="ThemeColor" v-on="on"><v-icon :color="ThemeHeaderColor">mdi-auto-fix</v-icon></v-btn>
                    </template>
                    <v-list dense class="justify-center">
                        <v-list-item>
                            <v-list-item-title class="overline">
                                Options
                            </v-list-item-title>
                            
                        </v-list-item>
                        <v-list-item  class="justify-center" v-for="button in record.AutomationButtons" :key="button.itemObjKey">
                        <v-btn :color="ThemeColor" @click="ActivateAutoButton(button,record)" small dark v-on="on">{{button.ButtonName}}</v-btn>
                         <!-- <v-list-item-subtitle >{{record}}</v-list-item-subtitle> -->
                        
                    </v-list-item>
                    </v-list>
                    </v-menu>
                    </v-list-item-action>
                     
                    
                    <!-- <v-list-item-action v-if="record.NotificationCount && record.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="record.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action> -->
                </v-list-item>
            </v-list>
                </v-list>
            </v-tab-item>
        </v-tabs>
              
          <!-- </v-tab-item>
      </v-tabs> -->
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import DashboardBuilder from '@/components/Dashboards/DashboardBuilder'
import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTabReadOnly';
// import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTabReadOnly';
import DigitizedFormComponent from '@/components/Client/DigitizedFormComponent'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem','UsersArray','ThemeHeaderColor','SystemEntities','NotificationObj'],
    components: {
        DashboardBuilder,
        WebFormTab,
        DigitizedFormComponent
    },
    data(){
        return{
            ActiveAction: '',
            Autobutton: '',
            ProcessRecord: '',
            StatusFilter: {ID:1000001},
            Status: '',
            Status_Reason: '',
            Galleries: [],
            DocumentRegisters: [],
            TimelineRecord: '',
            TimelineDialog: false,
            Tabs: 1,
            NewLink: '',
            slice: 4,
            FiletoRecordDialog: false,
            DocumentTable: '',
            DocumentType: '',            
            UploadingFileProgress: 0,
            LiasonUploadFile: '',
            OpenFormComponent: false,
            ActiveForm: '',
            ActiveComment: '',
            AutomationDialog: false,
            FormProcessing: false,
            AutomationCreatedSnackbar: false,
            AutomationSnackbarDetails: '',
            rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
            AutomationButtons: [],
            ActiveRecord: '',
            unassignedstep: 1,
            automationstep: 1,
            ActiveProcess: '',
            ActiveStage: '',
            AssigningRecord: '',
            AssignRecordDialog: false,
            //DataArray: [],
            items: [
                {
                    icon: 'mdi-alert-rhombus-outline',
                    title: 'Unassigned'
                },
                {
                    icon: '{{ActiveSession.icon}}',
                    title: 'Assigned'
                },
            ],
        }
    },
    watch: {
        ActiveSession: {
            handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                   this.ActiveRecord = '' 
                }
            },
            deep: true
        },
        NotificationObj (value) {
            if (value) {
                this.CheckNotificationObj()
                // let Processes = this.ActiveSession.EntityProcesses.map(process => {
                //         console.log(process)
                //         let filteredrecords = process.Data.filter(record => {
                //             record.id === value.ActiveRecord
                //         })
                //         console.log(filteredrecords)
                //         if(filteredrecords.length > 0){
                //             process.Active = true
                //         }
                //         return process
                //     })
                //     console.log(Processes)
                //     let activeprocess = Processes.filter(process => {return process.Active})
                //     console.log(activeprocess)
                //     this.ActiveRecord = activeprocess[0]
            }
        },
        
    },
    computed:{
        AutoSteps(){
            if(this.Autobutton && this.Autobutton.ResultActions){
                return this.Autobutton.ResultActions.length-1+2
            }
        },
        
        InactiveDataArray(){
            return this.ActiveSession.DataArray.filter(record => {
                return record.Status && record.Status.ID !== 1000001
            })
        },
        DocumentsAndGalleries(){
            let arr1 = this.Galleries.map(gallery => {
                gallery.IsGallery = true
                return gallery
                })
            let arr2 = this.DocumentRegisters
            if(this.ActiveComment && this.ActiveComment.FileType.includes('image')){
                return arr1.concat(arr2)
            }
            else{
                return arr2
            }
            
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        ComputedRecordData(){
            return this.ActiveRecord.Data
            .map(record => {
                console.log(record)
                record.AutomationButtons = record.AutomationButtons.map(button => {
                    button.Steps = button.Steps.map(condstep => {
                        if(condstep.LookupParameter === 'Preset Options'){
                            let fieldcheck = condstep.FieldtoCheck
                            let valuecheck = ''
                            if(fieldcheck.Type === 'Lookup' && record[fieldcheck.Name]){
                                valuecheck = record[fieldcheck.Name][fieldcheck.LookupFieldName]
                            }
                            else if(fieldcheck.Type === 'Option Set' && record[fieldcheck.Name]){
                                valuecheck = record[fieldcheck.Name].Name
                            }
                            else{
                                valuecheck = record[fieldcheck.Name]
                            }
                            if(record[condstep.FieldtoCheck.Name] && condstep.Value === 'Contains Data'){
                                condstep.Pass = true
                                condstep.icon = 'mdi-check'
                                condstep.Color = 'green'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                            else if(!record[condstep.FieldtoCheck.Name] && condstep.Value === 'Does Not Contain Data'){
                                condstep.Pass = true
                                condstep.icon = 'mdi-check'
                                condstep.Color = 'green'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                            else {
                                condstep.Pass = false
                                condstep.icon = 'mdi-cancel'
                                condstep.Color = 'red'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                        }
                        return condstep
                    })
                    let unpassedlength = button.Steps.filter(step => {
                        return step.Pass === false
                    }).length
                    if(unpassedlength>0){
                        button.Pass = false
                    }
                    else{
                        button.Pass = true
                    }
                    
                    button.ResultActions = button.ResultActions.map(act => {
                        console.log(act,act.Record)
                        if(act.ActionType === 'Create' || act.ActionType === 'Update'){
                            if(act.ActionType === 'Update'){
                                if(act.Record){
                                    if(act.Record.Relationship === 'Build Self'){
                                        if(this.ProcessRecord){
                                            act.ExistingRecord = this.ProcessRecord
                                        }
                                    }
                                    else if(isNaN(act.Record.RelationshipField)){
                                        //good, this is a method output value
                                    }
                                    else{
                                        //nope, the reocrd is from the ProcessRecord
                                    }
                                }
                            }
                            if(!act.EmailtoUser && record.Created_Byid){
                             act.EmailtoUser = this.UsersArray.find(obj => obj.id === record.Created_Byid)
                             act.FormEmailRecipient = act.EmailtoUser.Email  
                            }
                            
                            act.Record.AllFields.map(field => {
                            let matchobj = act.FieldMapping.find(obj => obj.Name.Name === field.Name)
                            if(matchobj){
                                field.MappedForm = true
                                field.MapObj = matchobj
                            }
                            return field
                        })
                        act.Windowtitle = 'Select Record Fields'
                        act.FormFields = act.Record.AllFields.filter(field => {
                            return field.MappedForm || field.IsHeader
                        })
                        act.EntityForm = true
                        act.RelatedEntity = {
                            id: act.Record.DisplayName.split(' ').join('').toLowerCase()
                        }
                        act.PageName = this.$route.name
                        act.FromDatabase = true
                        // act.DefaultOwner = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                        act.Name = 'Web Form'
                        act.BGColor = {hexa: '#ffffff'}
                        act.HeaderColor = {hex: '#000000'}
                        act.FormName = act.MethodName
                        act.FormFields = act.FormFields.map(field => {
                            if(field.MappedForm){
                                
                                let finalinput = ''
                                if(field.MapObj.InputType === 'Combined Value'){                                    
                                   field.MapObj.Input.map(input => {
                                       if(input.MapType === 'Custom Text'){
                                           finalinput = finalinput+input.DisplayName
                                       }
                                       else{
                                           finalinput = record[input.ParentInput.Name]
                                           
                                       }
                                       
                                    })
                                    field.Response = finalinput
                                }
                                else{
                                   console.log('but when') 
                                if(field.MapObj.source && !isNaN(field.MapObj.source.RelationshipField)){
                                    console.log('automation workflow based right here',field.MapObj.source.RelationshipField,button.ResultActions)
                                    let autoobj = button.ResultActions.find(btnact => btnact.MethodID === field.MapObj.source.RelationshipField)
                                    console.log(autoobj)
                                    //outstanding is
                                    //1. Syncronize "next" and "submit" to respect automation sequence. Perhaps "finish" is needed if at resultacitons length end
                                    //2. update the record with "automation count integer", then afterwards we can consider "once off only" check ConfirmSubmission
                                    if(autoobj && autoobj.Output){
                                        console.log('computed for next step', autoobj.Output)
                                        let parinput = field.MapObj.Input[0]
                                        console.log('parinput', parinput)
                                        // console.log(parinput.ParentInput.Name,record) 
                                        let valueobj = autoobj.Output[parinput.ParentInput.Name]
                                        console.log('from parinput',valueobj)
                                        if(field.Type === 'Lookup'){
                                            valueobj = {
                                                [field.MapObj.Name.LookupFieldName]: autoobj.Output[parinput.ParentInput.Name],
                                                id: autoobj.Output.id
                                            }
                                            field.Array = []
                                            field.Array.push(valueobj)
                                        }
                                        field.Response = valueobj
                                    }
                                    
                                }
                                else{
                                    let parinput = field.MapObj.Input[0]
                                    console.log(parinput.ParentInput.Name,record) 
                                    let valueobj = record[parinput.ParentInput.Name]
                                    console.log(valueobj) 
                                    if(field.Type === 'Lookup'){    
                                        console.log(field)   
                                        if(field.RelatedBuildID === this.ActiveSession.id){
                                        valueobj = {
                                            [parinput.ParentInput.Name]: record[parinput.ParentInput.Name],
                                            id: record.id
                                        }
                                        }                          
                                        field.Array = []
                                        field.Array.push(valueobj)
                                    }
                                    field.Response = valueobj   
                                }
                                
                            }
                            }
                            
                            return field
                        })
                        .filter(field => {
                            if(act.ActionType === 'Update'){
                            return field.MappedForm || field.Name === 'Status' || field.Name === 'Status_Reason'
                            }
                            else if(act.ActionType === 'Update' && !act.StatusUpdate){
                            return field.MappedForm
                            }
                            else{
                             return field.Response || field.IsHeader   
                            }
                        })
                        .map(field => {
                            if(field.Type === 'Lookup' && !field.MappedForm){
                              console.log('wait a minut mate, we need to assign arrays fror lookup types',field)  
                            //   field.Array = this.$store.state[field.RelatedRecord+'Array'] 
                            }                            
                            return field
                        })
                        let actionrecord = act.Record
                        if(actionrecord){
                         let statusfield = actionrecord.AllFields.find(obj => obj.Name === 'Status')                            
                            if(statusfield){
                                let formstatus = act.FormFields.find(obj => obj.Name === 'Status')
                                if(act.StatusUpdate && formstatus){                                    
                                    formstatus.Response = act.StatusUpdate  
                                    act.StatusDefault = act.StatusUpdate
                                    act.Status_ReasonDefault = act.StatusReasonUpdate                              
                                }
                                else{
                                    act.StatusDefault = statusfield.DefaultOption
                                    act.Status_ReasonDefault = statusfield.DefaultLevel2Option
                                }
                                
                            }   
                        }
                        
                        
                        // .filter(field => {
                        //     return field.Name !== 'Status' && field.Name !== 'Status_Reason'
                        // })
                        //RecordHeaderFields
                        //FieldMapping
                        //pass to a prop called FormFields
                        //may need PageHeadersFontSize
                        
                        }
                        
                        return act
                    })
                    if(this.automationstep === 1){
                        button.Windowtitle = 'Validation'
                    }
                    
                    else{
                        // let stepcountindex = this.automationstep-2
                        // button.Windowtitle = button.ResultActions[stepcountindex].Windowtitle
                    }
                    
                    return button
                })
                
                return record
            })
        },
        ProcessesArray(){
            return this.ActiveSession.EntityProcesses
            .map(process => {
                process.PipelineProcess = process.id
                process.ValueType = 'Count',
                process.ThemeColor = this.ThemeColor
                process.Data = this.AssignedArray.filter(record => {
                    return record.ActiveProcess.id === process.id
                })
                process.ActiveData = process.Data.filter(record => {
                    return record.Status && record.Status.ID === 1000001
                })
                process.PrimaryEntity = this.ActiveSession.id
                process.class = this.ThemeColor+' lighten-4 tag-wrap'
                process.headerclass = 'overline'
                return process
            })
            
        },
        AutoAdvanceDisabled(){
            if(this.automationstep === 1 && this.Autobutton.Pass){
                return false
            }
            else if(this.automationstep > this.Autobutton.ResultActions.length-1+2){
                return true
            }
            else{
                return typeof this.Autobutton.ResultActions[this.automationstep-2] === 'undefined' || this.Autobutton.ResultActions[this.automationstep-2] && typeof this.Autobutton.ResultActions[this.automationstep-2].Output === 'undefined'
            }
        },
        UnAssignedButtonColor(){
           switch (this.unassignedstep) {
                case 4: return 'red'
                default: return 'green'
            }
        },
        UnAssignedButton(){
           switch (this.unassignedstep) {
                case 4: return 'Process'
                default: return 'Next'
            }
        },
        AutomationWindowtitle(){
           switch (this.automationstep) {
                case 1: return 'Validation'
                case 2: return 'Confirmation'
                case 3: return 'Process Actions'
                default: return 'Automation'
            }
        },
        UnassignedWindowcurrentTitle(){
           switch (this.unassignedstep) {
                case 1: return this.Step1Title
                case 2: return 'Assign Owner'
                case 3: return 'Assign Process'
                default: return 'Process Record'
            }
        },
        Step1Title(){
            if(this.EntityHasWebForm){
                return 'Enquiry'
            }
            else{
                return 'Data'
            }
        },
        Step1Body(){
            let Body = ``
            if(this.EntityHasWebForm){
                Body = `<html>
<body>
<head></head>
<h2>New Enquiry - Contact Us</h2>
<table>`
             let length = this.WebFormHeaders.length
            this.WebFormHeaders.map((field,i) => {
                Body = Body+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+this.AssigningRecord[field.Name]+`</td>
</tr>`
    if(i-1+2 === length){
        Body = Body+`
    </table>
</body>
</html>`
 } 
            })
            }
            return Body
        },
        ComputedHeaders(){
            return this.ActiveSession.AllFields
            .filter(field => {
                return field.IsHeader
            })
        },
         WebFormHeaders(){
            return this.ActiveSession.AllFields
            .filter(field => {
                return field.OnWebForm
            })
        },
        EntityHasWebForm(){
            return this.ActiveSession.HasWebForm
        },
        Computeditems(){
            return this.items.map(item => {
                //item.icon = this.ActiveSession.icon
                return item
            })
        },
        StoreQuery(){
            return this.$store.state[this.ActiveSession.storequery]
        },
        StoreArray(){
            return this.$store.state[this.ActiveSession.storedata]
        },
        UnassignedArray(){
            return this.ComputedDataArray
            .filter(record => {
                return record.PipelineProgress === 'Unassigned'
            })
            .filter(record => {
                return record.Status && record.Status.ID === 1000001
            })
        },
        AssignedArray(){
            return this.ComputedDataArray
            .filter(record => {
                return record.PipelineProgress === 'Assigned'
            })
        },
        ComputedDataArray(){
            return this.ActiveSession.DataArray
            .map(record => {
                if(record.ActiveProcess){
                    record.PipelineProgress = 'Assigned'
                }
                else{
                    record.PipelineProgress = 'Unassigned'
                }
                
                return record
            })
           
        },

        
    },
    created(){       
        if(this.ActiveSession.automations){
            this.PrepareAutomations()
        }
        if(this.NotificationObj){
           this.CheckNotificationObj()
        }
    },
    methods:{
        AutoStepName(n){
            if(n === 1){
                return 'Overview'
            }
            else{
            let autoobj = this.Autobutton.ResultActions[n-2]
                if(autoobj){
                    return autoobj.MethodName
                }
            }
        },
        ActivateAutoButton(button,record){
            console.log(button)
            let vm = this
            this.unassignedstep = 1
            this.Autobutton = Object.assign({},button)
            this.ProcessRecord = Object.assign({},record)
            this.AutomationDialog = true
            this.Autobutton.ResultActions.map(action => {
                if(action.FormFields){
                    action.FormFields.map(field => {
                        if(!field.MappedForm && field.Type === 'Lookup'){
                            if(this.$store.state[field.RelatedRecord+'Array'].length>0){
                               field.Array = this.$store.state[field.RelatedRecord+'Array'] 
                            }
                            else{
                                let payload = {                
                                query: this.$store.state[field.RelatedRecord+'Query'],
                                arraymutation: 'set'+field.RelatedRecord+'Array',
                                getter: 'get'+field.RelatedRecord+'Array',
                                arrayname: field.RelatedRecord+'Array'
                                }
                                this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                                    console.log(vm.$store.state[field.RelatedRecord+'Array'])
                                    field.Array = vm.$store.state[field.RelatedRecord+'Array']
                                    })
                            }
                        }
                    })
                }
            })
        },
        ResetStatus(record){
        this.Status = Object.assign({},record.Status)
        this.Status_Reason = Object.assign({},record.Status_Reason)
        },
        UpdateStatus(record){
        db.collection(this.ActiveSession.collection).doc(record.id).update({
            Status: this.Status,
            Status_Reason: this.Status_Reason
        })
        },
        GetShortLivedURL(item){
        console.log(item)
        const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          item.TokenURL = result.data[0]
          setTimeout(() => {
                item.TokenURL = ''
                let url = item.fileurl
                item.fileurl = ''
                item.fileurl = url
          }, 120000);
          let url = item.fileurl
          item.fileurl = ''
          item.fileurl = url
        })
        },
        OpenLiasonDialog(record){
            this.TimelineRecord = Object.assign({},record)
            this.TimelineDialog = true
        },
        CheckNotificationObj(){
            this.ActiveRecord = this.ProcessesArray.find(obj => obj.Number === this.NotificationObj.ActiveProcessNumber)
            console.log(this.ActiveRecord)
            this.Tabs = 1
            setTimeout(() => {
              let elmnt = document.getElementById(this.NotificationObj.ActiveRecord+'responses')
                console.log(this.NotificationObj.ActiveRecord+'responses')
                console.log(elmnt)
                if(elmnt){
                    elmnt.click()
                }  
            }, 100);
        },
        link(url,record) {
        this.input = `See this link <a href="`+url+`" target="_blank">here</a>`
        document.execCommand("createLink", false, url);
        record.TimelineDialog = false
        record.TimelineDialog = true
        this.NewLink = ''
        },
        ReadNotices(record){
            record.Notifications.map(not => {
              db.collection('notifications').doc(not.id).update({
                Read: true
              })
            })
        },
        FileInput(elid){
            let elmnt = document.getElementById(elid)
            elmnt.click()
        },
        OpenSaveFiletoRecord(comment){
            this.ActiveComment = Object.assign({},comment)
            this.FiletoRecordDialog = true
            this.DocumentTable = ''
            this.DocumentType = ''
            this.DocumentRegisters = JSON.parse(JSON.stringify(this.ActiveSession.DocumentRegisters))
            this.Galleries = JSON.parse(JSON.stringify(this.ActiveSession.Galleries))
        },
        SaveFiletoRecord(comment){
            let vm = this
            console.log(comment)
            // this.FiletoRecordDialog = true
            
            const RecordFilingdocument = { 
                
                IsLiasonFile: true,
                Liasonid: comment.id,
                FileType: comment.FileType,
                fileurl: comment.fileurl,
                Name: comment.Uploadfilename,
                Modified_By: {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
                Modified_Byid: this.userLoggedIn.id,
                Modified_On: new Date(),
                StorageRef: comment.StorageRef,
                Created_By: {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
                Created_Byid: this.userLoggedIn.id,
                Created_On: new Date(),
                ModifiedDate: comment.lastModifiedDate
                }
                if(!this.DocumentTable.IsGallery){
                    let optionname = this.DocumentTable.id+'PrimarySelectedOption'
                    RecordFilingdocument[optionname] = this.DocumentType
                    let collection = this.DocumentTable.id.toLowerCase()
                    this.FileRecord(comment,RecordFilingdocument,collection)
                }
                else{ 
                    let collection = this.DocumentTable.id.toLowerCase()+'imagesuploads'
                    RecordFilingdocument.url = RecordFilingdocument.fileurl
                    RecordFilingdocument.ThumbURL = comment.ThumbURL
                    this.FileRecord(comment,RecordFilingdocument,collection)
                }
            
                
        },
        
        FileRecord(comment,RecordFilingdocument,collection){
            db.collection(this.ActiveSession.collection).doc(comment.relatedid).collection(collection).doc(comment.newdocid).set(RecordFilingdocument).then(doc => {
                   db.collection('clientliason').doc(comment.id).update({
                       Filed: true
                   }).then(doc => {
                       this.CancelSaveFiletoRecord()
                   })
                })
        },
        CancelSaveFiletoRecord(){
            this.FiletoRecordDialog = false
            this.DocumentTable = ''
            this.DocumentType = ''

        },
        DateFormatterwithTime(date){
      if(date){
        console.log(date)
        console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        let hrnumber = Number(hr)
        let minr = Number(min)
        let secnr = Number(sec)
        var ampm = "am";
        if(isNaN(secnr)){
          secnr = 0
        }
        console.log(yearnumber,monthnumber,daynumber,hrnumber,minr,secnr)
        let newdate = new Date(yearnumber, monthnumber-1, daynumber,hrnumber,minr,secnr)
        console.log(newdate)
        return newdate
        }
      else{
        return null
      }
},
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        console.log(finaldate)
        return finaldate
      },
        ByLatestLiason(ClientLiason){
            if(ClientLiason){
             return ClientLiason.map(liase => {
                    liase.createdon = liase.Created_On.toDate()
                    liase.TimestampSort = liase.createdon.getTime()
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                  .slice(0,this.slice)   
            }
            
        },
        onFileselected(event){
        this.LiasonUploadFile = event.target.files[0]
        this.LiasonUploadFile.FileModifiedDate = new Date(this.LiasonUploadFile.lastModified)
        setTimeout(() => {
         this.input = 'Please see attached file - '+this.LiasonUploadFile.name  
         console.log(this.input) 
        }, 300);
        
        
        },
        OpenAttachment(record){
            console.log(record)
        },
        CreateClientRecord(record){
            console.log(this.ActiveSession.id)
            let clientrecordobj = {
                name: record[this.ActiveSession.RecordPrimaryField.Name],
                topicid: this.ActiveSession.topicid,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,                
                ActiveProcessNumber: this.ActiveRecord.Number
            }
             clientrecordobj.Clientid = record[this.ActiveSession.ClientLookupField.Name].id 
             db.collection('clientrecords').doc(record.id).set(clientrecordobj).then(doc => {
                 console.log('created client record')
                 let notifyowner = record[this.ActiveSession.ClientLookupField.Name]
                 const NewNotification = {
                    ActiveProcessNumber: clientrecordobj.ActiveProcessNumber,
                    itemidprop: 'clientrecords',
                    itemid: record.id,
                    Type: 'Feedback',
                    topicid: clientrecordobj.topicid,
                    Header: 'Liaison Started on '+clientrecordobj.name,
                    Content: 'Communication Channeled opened for '+clientrecordobj.name,
                    CreatorFullName: record.Owner.Full_Name,
                    CreatorID: record.Owner.id,
                    Owner: {id: notifyowner.id, Full_Name: notifyowner.Full_Name},
                    Ownerid: notifyowner.id,
                    Read: false,
                    Path: '/MyAccount',
                    IsLiason: true,
                    Liasonid: record.id,
                    Message: record.Owner.Full_Name+' has opened Communication on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    }
                this.$store.dispatch('createNotification',NewNotification)
                this.StartLiasonDialog = false
                this.TimelineDialog = false  
             })               

        },
        OpenForm(form,comment){
        this.ActiveForm = form
        this.ActiveComment = comment
        this.OpenFormComponent = true
      },
      SubmitForm(form,comment,fileonly){
        console.log(form,comment)
        this.ActiveForm = ''
        this.ActiveComment = ''
        this.OpenFormComponent = false
        
        if(!fileonly){
            this.input = 'Submitted'
        form.readonly = true
          this.comment(this.ActiveRecord,form)  
        }
        else{
            console.log(form)
        }
      },
        comment(record,form,LiasonUploadFile) {
        let NewComms = {
          description: this.input,
          ActiveProcessNumber: record.ActiveProcess.Number,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.ActiveSession.title,
          recordlocation: '/'+this.ActiveSession.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.ActiveSession.topicid,
        }
        if(form){
            NewComms.FormObj = form.Tabs[0].Sections[0].Content[0]
            if(form.readonly){
              NewComms.readonly = true
            }
        }
        if(!LiasonUploadFile){
         this.SaveNewcomms(NewComms,record)  
        }
        else{
            //first upload the file, return url and include url in NewComms
            this.UploadFileandAttahctoNewComms(NewComms,record)
        }
        
        // this.events.push({
        //   id: this.nonce++,
        //   text: this.input,
        //   time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
        //     return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
        //   }),
        // })

        this.input = null
      },
      SaveNewcomms(NewComms,record){
        let commscol = ''
        let owneruser = ''
        let owner = ''
          if(this.ActiveSession.LiaisonProp === 'InternalLiaison'){
              commscol = db.collection(this.ActiveSession.collection).doc(record.id).collection('InternalLiaison')
              owneruser = record.Owner
                owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
          }
          else{
              commscol = db.collection('clientliason')
              owneruser = record[this.ActiveSession.ClientLookupField.Name]
                owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
          }
        // console.log('this.ActiveRecord ',record)
        // console.log('this.ActiveRecord ClientLookupField',this.ActiveSession.ClientLookupField.Name)
        
          NewComms.recipientid = owner.id
          console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    ActiveProcessNumber: NewComms.ActiveProcessNumber,
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiason: true,
                    Liasonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                this.$store.dispatch('createNotification',NewNotification) 
               } 
            this.LiasonUploadFile = ''
            this.input = null
            this.TimelineDialog = false  
            this.TimelineDialog = true
            
            }) 
            
      },
      UploadFileandAttahctoNewComms(NewComms,record){
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        let filename = newdocid+'_'+this.LiasonUploadFile.name
        console.log(filename)
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = NewComms.relatedtype.split(' ').join('')+'/'+NewComms.relatedid+'/'+this.ActiveSession.LiaisonProp+'/'+ filename
        NewComms.Uploadfilename = this.LiasonUploadFile.name
        var storageRef = firebase.storage().ref(path)
        NewComms.StorageRef = path
        NewComms.newdocid = newdocid
        NewComms.FileType = this.LiasonUploadFile.type
        NewComms.lastModifiedDate = this.LiasonUploadFile.FileModifiedDate
          var uploadTask = storageRef.put(this.LiasonUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.LiasonUploadFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                NewComms.fileurl = downloadURL
                if(NewComms.FileType.includes('image')){
                  vm.$emit('ActivateProcessing',true)
                  vm.PrepareThumbnail(NewComms.Uploadfilename,vm.LiasonUploadFile,NewComms.StorageRef).then(thumbresult => {
                        NewComms.ThumbURL = thumbresult
                        vm.$emit('ActivateProcessing',false)
                        vm.SaveNewcomms(NewComms,record)
                    })
                }
                else{
                  vm.SaveNewcomms(NewComms,record)
                }
                
              });
            })
                
        }, 300);
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
        UpdateFormEmailRecipient(action){
            if(action.EmailtoUser){
              action.FormEmailRecipient = action.EmailtoUser.Email
            this.AutomationDialog = false
            this.AutomationDialog = true
            console.log(action.FormEmailRecipient)  
            }
            
        },
        ProcessingData(boolean){
            this.FormProcessing = boolean
        },
        RefreshDialog(){
            this.AutomationDialog = false
            this.automationstep = this.automationstep-1
            this.automationstep = this.automationstep-1+2
            this.AutomationDialog = true 
            
        },
        ConfirmSubmission(tab,obj,docid){
            let action = this.Autobutton.ResultActions[this.automationstep-2]
            action.Output = {...obj.data,id: docid}
            console.log('confirm assigned',action)
            if(action.ActionType === 'Create'){
                console.log(action,this.ProcessRecord,this.ActiveSession)
                let count = 0
                let actionprocessedprop = action.MethodID+'Processed'
                let actionprocesscountprop = action.MethodID+'Count'
                if(this.ProcessRecord[actionprocesscountprop]){
                    count = this.ProcessRecord[actionprocesscountprop]
                }
                count = count-1+2
                db.collection(this.ActiveSession.collection).doc(this.ProcessRecord.id).update({
                    [actionprocessedprop]: true,
                    [actionprocesscountprop]: count
                })
                this.AdvanceAutomationButton()
            }
            else if(action.ActionType === 'Update'){
                let obj = action.Output
                for(var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if(obj[prop] !== 'undefined' && obj[prop].Type === 'Date'){
                    obj[prop] = new Date(obj[prop].DateNumber)
                    }
                    db.collection(action.Record.collection).doc(action.ExistingRecord.id).update({
                    [prop]: obj[prop]
                    })
                    }
                }
                this.AdvanceAutomationButton()
            }
            else{
                this.AdvanceAutomationButton()
            }
            
            //okay so here we returned the right object as well which is great but in automation chainging, we need to return this object to the automation that requires it
            //to  automation we need to attach the "Output"
            //so firt we get the automation we need and then...well
            //Below a sample of how single code updates to reocrd the automation is done on
            // {action.MethodName.split(' ').join('')}}Processed: true,
            // Process{action.MethodName.split(' ').join('')}}Count: this.Process{action.MethodName.split(' ').join('')}}Count,
            // Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            // Modified_Byid: this.UserRecord.id,
            // Modified_On: new Date()
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record here`
        },


        // PrepareAutomations(){
        //     console.log(this.ActiveSession)
        //     let array = JSON.parse(JSON.stringify(this.ActiveSession.automations))
        //     let automationswithconditionalbuttons = array.map(auto => {
        //         auto.Conditions = auto.Conditions.filter(cond => {
        //                 return cond.CallfromButton
        //             })
        //         return auto
        //     })
        //     .filter(auto => {
        //         return auto.Conditions.length > 0
        //     })
        //     this.AutomationButtons = automationswithconditionalbuttons.map(auto => {
        //         let obj = {}
        //             auto.Conditions.map(cond => {
        //                 obj.ButtonName = cond.ApprovalButtonText,
        //                 obj.Steps = cond.Steps
        //                 obj.ResultActions = []
        //                 cond.ResultActions.map(act => {
        //                     let matchobj = auto.AutomationActions.find(obj => obj.MethodID === act.MethodID)
        //                     if(matchobj){
        //                         let entity = this.SystemEntities.find(obj => obj.buildid === matchobj.Record.id)
        //                         if(!entity){
        //                           matchobj.Record = this.SystemEntities.find(obj => obj.id === matchobj.Record.id)  
        //                         }
        //                         else{
        //                             matchobj.Record = entity
        //                         }
        //                         obj.ResultActions.push(matchobj)
        //                     }
        //                 })
        //             })
        //             //the key to minimal intervention is here. possibly specifically in resultactions. 
        //             //we need a 2 step window. step 1 = conditions met or unmet. Step 2 being, the dialog
        //             //but seeing as multiple actions could apply I think the actions you want is the key
        //             //aha!!! we render buttons on datarecord right? Well, we need to render the data, do the comparisons and validations and everything within there,
        //             //pusht he "buton" as a prop to the data. That's what we should do. 
        //         return obj
        //     })
        // },
        PrepareAutomations(){
            console.log(this.ActiveSession)
            let array = JSON.parse(JSON.stringify(this.ActiveSession.automations))
            let automationswithconditionalbuttons = array.map(auto => {
                auto.Conditions = auto.Conditions.filter(cond => {
                        return cond.CallfromButton
                    })
                return auto
            })
            .filter(auto => {
                return auto.Conditions.length > 0
            })
            this.AutomationButtons = automationswithconditionalbuttons.map(auto => {
                let obj = {}
                    auto.Conditions.map(cond => {
                        obj.ButtonName = cond.ApprovalButtonText,
                        obj.Steps = cond.Steps
                        obj.ResultActions = []
                        cond.ResultActions.map(act => {
                            let matchobj = auto.AutomationActions.find(obj => obj.MethodID === act.MethodID)
                            console.log(act,matchobj.Record.id,this.SystemEntities)
                            if(matchobj){
                                let temprecord = matchobj.Record
                                matchobj.Record = this.SystemEntities.find(obj => obj.buildid === matchobj.Record.id)
                                matchobj.Record.Relationship = temprecord.Relationship
                                if(temprecord.RelationshipField){
                                  matchobj.Record.RelationshipField = temprecord.RelationshipField  
                                }
                                obj.ResultActions.push(matchobj)
                            }
                        })
                    })
                    //the key to minimal intervention is here. possibly specifically in resultactions. 
                    //we need a 2 step window. step 1 = conditions met or unmet. Step 2 being, the dialog
                    //but seeing as multiple actions could apply I think the actions you want is the key
                    //aha!!! we render buttons on datarecord right? Well, we need to render the data, do the comparisons and validations and everything within there,
                    //pusht he "buton" as a prop to the data. That's what we should do. 
                return obj
            })
        },
        PushItem(item){
            this.ActiveRecord = item
            this.ActiveRecord.LookupArrays = []
            this.ActiveRecord.Data
                .map(record => {
                console.log(record)                
                record.AutomationButtons = JSON.parse(JSON.stringify(this.AutomationButtons))
            }) 
            console.log(this.ActiveRecord)
        },
        AdvanceAutomationButton(){
            if(this.Autobutton && this.Autobutton.ResultActions && this.Autobutton.Pass){                
                this.automationstep = this.automationstep-1+2 
                if(this.automationstep > this.Autobutton.ResultActions.length-1+2){ 
                   this.AutomationDialog = false
                   this.Autobutton = ''
                   this.ActiveRecord = ''
                }
                else{
                    this.RefreshDialog()
                }
            } 
            else if(this.Autobutton && this.Autobutton.ResultActions && !this.Autobutton.Pass){
                alert('This Record does not meet the required Criteria')
            }           
        },
        AdvanceUnAssignedRecord(){
            if(this.unassignedstep === 4){
                this.AssignRecord()
            }
            else{
                this.unassignedstep = this.unassignedstep-1+2
            }
        },
        CancelAssignRecord(){     
            let vm = this       
            this.AssignRecordDialog = false
            this.AssigningRecord = ''
            this.ActiveProcess = ''
            this.ActiveStage = ''
            //vm.DataArray = []
            //setTimeout(() => {
            //    vm.DataArray = vm.StoreArray
            //    console.log(vm.DataArray)
            //}, 1000);
            
        },
        AssignRecord(){
            let vm = this
            let Owner = {Full_Name: this.AssigningRecord.Owner.Full_Name,id: this.AssigningRecord.Owner.id}
            db.collection(this.ActiveSession.collection).doc(this.AssigningRecord.id).update({
                Owner: Owner,
                Ownerid: Owner.id
            }).then(doc => {
                vm.AssignProcessConfirm()
            })
        },
    AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
        console.log(this.ActiveStage)
        let SavingProcess = Object.assign({},this.ActiveProcess)
        delete SavingProcess.Data
      db.collection(this.ActiveSession.collection).doc(this.AssigningRecord.id).update({
        ActiveProcess: SavingProcess,
        stagename: this.ActiveStage,
        stagenameid: this.ActiveStage.Number
      })
      let processdoc = SavingProcess.Name.split(' ').join('')+SavingProcess.Number
      console.log(processdoc)
      console.log(typeof this.AssigningRecord[processdoc],this.AssigningRecord[processdoc])
      console.log(!this.AssigningRecord[processdoc])
      if(!this.AssigningRecord[processdoc]){
        // let ProcessDoc = 
        db.collection(this.ActiveSession.collection).doc(this.AssigningRecord.id).collection('processes').add(SavingProcess).then(doc => {
          db.collection(this.ActiveSession.collection).doc(this.AssigningRecord.id).update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
          this.CancelAssignRecord()
        })
        // this.StageID = 0
        // this.StageName = ''
      }
      else{
        db.collection(this.ActiveSession.collection).doc(this.$route.params.id).collection('processes').doc(this.AssigningRecord[processdoc]).update({
         DisplayName: SavingProcess.DisplayName,
         Name: SavingProcess.Name,
         ID: SavingProcess.Number,
          ProcessStages: SavingProcess.ProcessStages
        })
        db.collection(this.ActiveSession.collection).doc(this.$route.params.id).update({
            ActiveProcessDocid: this.AssigningRecord[processdoc]
          })
          this.ActiveProcessDocid = this.AssigningRecord[processdoc]
          db.collection(this.ActiveSession.collection).doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            // this.StageName = stagedata.StageName
            // if(this.StageName){
            // this.StageID = stagedata.StageName.Number
            // }
            })
      }
    },
        AssignItem(item){
            if(item.CanEdit){
               this.AssigningRecord = item
                this.AssignRecordDialog = true 
            }
        },
        GetMatchingStatus(item){
        //    if(this.MenuItem && this.MenuItem.Array){
             return this.ComputedRecordData
            .filter(record => {
                return record.stagenameid === item.Number && record.Status && record.Status.ID === 1000001
            })
            
        },
    }

}
</script>

<style>

</style>


    