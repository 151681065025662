
<template>
    
             <v-card class="transparent footermargin" flat>
            
              <div v-if="UserRecord">
              <div  v-if="SliderView">
                
                  <v-carousel v-model="model" height="400" cycle>
                      <v-carousel-item
                        v-for="article in RelatedBlogs.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          style="background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                             <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'">
                                 {{article.caption}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="'/Blog/' + article.id" color="links black--text"  :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
        
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedBlogs" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.description.substr(0,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="'/Blog/'+article.id">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedBlogs.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
               <div v-if="!UserRecord">
                  <div  v-if="SliderView">
                  <v-carousel v-model="model" height="400">
                      <v-carousel-item
                        v-for="article in RelatedBlogs.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                             <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'">
                                 {{article.caption}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="'/Blog/' + article.title.split(' ').join('-')" color="links black--text"  :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedBlogs" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate.toDate()}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.caption.substr(0,Excerpt)">
                </v-card-text>
                <v-card-actions>
                 <!-- <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip> -->
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="'/Blog/'+article.title.split(' ').join('-')">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedBlogs.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
           
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','Origin','clientDB','Filter','Excerpt','Slice','SliderView','UserView','UserID'],
    components: {
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
          CollectionRef: db.collection('siteblogs'),
            model: 0,
            groupblogs: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
        RelatedBlogs () {
            return this.groupblogs
            // .filter(blog => {
            //     let bloggroup = this.groups.find(obj => obj.id === blog.Groupid)
            //     blog.category = bloggroup.GroupCategory
            //     console.log(blog.grouptype)
            //     let blogmatch = this.Filter.find(obj => obj.Name === blog.grouptype)
            //     if(blogmatch){
            //         return blog
            //     }
            // })
        },

        BlogExcerpt () {
          return this.RelatedBlogs.filter(blog => {
            return blog.content === this.blog.content
          })
        }
    },

    created() {
      if(this.UserView){
        console.log('is user view',this.UserID)
        if(this.UserID === this.UserRecord.id){
          this.CollectionRef = this.CollectionRef.where('Creatorid','==',this.UserID)
          this.getGroupBlogs()
        }
        else{
          this.CollectionRef = this.CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)'])
          this.getGroupBlogs()
        }
        
        
      }
      else{
        this.CollectionRef = db.collection('siteblogswiki')
        if(this.UserRecord){
          this.CollectionRef = this.CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)'])
        }
        else{
          this.CollectionRef = this.CollectionRef.where('PublishStatus','==','Published (Public)')
        }
        
        this.getGroupBlogs()
      }
        console.log(this.userid)
},
    methods: {
      
         getGroupBlogs() {
         
           this.CollectionRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.groupblogs.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('siteblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>      
    
    
    
     
    