
<template>

    
      <!-- <v-dialog v-model="LogTicketDialog" max-width="600px"> -->
        <v-card witdh="100%">
          <v-snackbar v-model="TicketLoggedSnackbar" :timeout="4000" top color="success">
            <span>Awesome! The ticket has been logged.</span>
            <v-btn color="white" @click="TicketLoggedSnackbar = false">Close</v-btn>
          </v-snackbar>
          <v-card-title>Log a New Ticket - GP Combrink Vehicle Sales & Finance</v-card-title>
          <v-card-text class="mx-3">
            <v-layout row>
            <v-img src='@/assets/logo.png' max-height="200px" contain>
              </v-img>
          </v-layout>
          <v-layout row>
            <v-select  label="Logging User" :items="UsersArray" filled readonly v-model="UserRecord" item-text="Full_Name"></v-select>
          </v-layout>
          <v-layout row>
            <v-textarea label="Description/Symptomn/Issue" v-model="Description"></v-textarea>
          </v-layout>
          <v-layout row>
            <v-select  label="Priority" :items="['High','Medium','Low']" v-model="Priority" item-text="Full_Name"></v-select>
          </v-layout>
         
          </v-card-text>
          <v-card-actions>
            <v-layout class="justify-end">            
            <v-btn @click="CancelSubmitSystemTicket()" dark color="warning">Cancel</v-btn>
            <v-btn @click="SubmitSystemTicket()" dark color="success">Submit</v-btn>
            </v-layout>
          </v-card-actions>          
        </v-card>
      <!-- </v-dialog> -->
   
</template>

<script>
import firebase from 'firebase';
import format from 'date-fns/format'
import db from '@/main'

export default {
    props: ['UserRecord','UsersArray','View','RelatedObj','SystemRoadmapsBuilt','LastRoadmapTaskID','SystemObj'],
  components: {
  },
    data() {
        return {
          TicketLoggedSnackbar: false,
          LogTicketDialog: false,
          Priority: '',
          Description: '',
            
            rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            
        }
    },
    computed: {
           
    },
    mounted () {
        if(this.RelatedObj){
      this.LogTicketDialog = true
        }
    },
    methods: {
      SubmitSystemTicket(){
        let NewTicket = {
          User:  {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Description: this.Description,
          View: this.View,
          ViewURL: window.location.href,
          Created_On: new Date(),
          Priority: this.Priority,
          Systemid: process.env.VUE_APP_RA_SYSTEM_ID,
          Status: 'Logged'
        }
        if(this.RelatedObj){
          NewTicket.RelatedObj = this.RelatedObj
        }
        let vm = this
        db.collection('systemtickets').add(NewTicket).then(doc => {
          vm.TicketLoggedSnackbar = true
          setTimeout(() => {
            vm.CancelSubmitSystemTicket()
            vm.Description = ''
            vm.Priority = ''
          }, 2000);
          
        })
      },
      CancelSubmitSystemTicket(){
        this.$emit('CancelSubmitSystemTicket')
      },
      
    },
}
</script>

<style>
</style>

    