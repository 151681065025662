
  <template>
  <v-row
    class="fill-height ma-0 transparent stickytopconscious"
    align="center"
    justify="center">
    <div class="moving-clouds" 
        :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
        <div class="moving-clouds2" 
        style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>

        <v-card
            class="mx-auto"
            max-width="500"
            min-width="350"            
            >
            <v-img
            
            src="@/assets/RABaseBG-Soft.png"
            width="350">
            <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="pop lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="step">
                <v-window-item :value="1">
                  <v-card-text>                                
                    <span class="caption grey--text text--darken-1">
                        <span class="caption grey--text">Forgot Username or Password? Click <a @click="step = 2">here</a></span><br>
                    </span>
                    </v-card-text>
                    <v-card-text>
                    <v-text-field full-width dense type="text" v-model="email" placeholder="Email" size="12" :rules="[rules.email]"/>
                    <v-text-field full-width dense type="password" v-model="password" placeholder="Password" @keydown.enter="loginWithFirebase"/>
                    </v-card-text>
                     <v-card-actions>
                       <v-spacer></v-spacer><v-btn dark class="links" @click="loginWithFirebase">Sign In</v-btn> 
                     </v-card-actions>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                      <v-list class="transparent">
                        <v-list-item @click="step = 3">
                          I forgot my Login Email
                        </v-list-item>
                        <v-list-item @click="step = 4">
                          I forgot my Password
                        </v-list-item>
                      </v-list>                   
                                
                    <span class="caption grey--text text--darken-1">
                        This is the email you will use to login to your GP Combrink Vehicle Sales & Finance account
                    </span>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                    
                    <v-card-text>
                     
                     <v-img v-if="!Profile_Photo "
                     width="200"
                     height="200" 
                        src="@/assets/BlankProfilePic.png"              
                      />
                      <v-img v-if="Profile_Photo "
                     width="200" 
                     height="200" 
                        :src="Profile_Photo"              
                      />
                      <input type="file"  accept="image/*" @change="SelectProfilePic($event)"/>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                    <v-card-text>
                    <v-text-field
                        label="Email"
                         :rules="[rules.email]"
                        v-model="email"
                    ></v-text-field>
                    
                    <span class="caption grey--text text--darken-1">
                        Please enter the Login Email
                    </span>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="5">
                    <div class="pa-4 text-center">
                    <v-img
                        class="mb-4"
                        contain
                        height="128"
                        src="@/assets/logo.png"
                    ></v-img>
                    <h3 class="title font-weight-light mb-2">
                        Welcome to GP Combrink Vehicle Sales & Finance
                    </h3>
                    <span class="caption grey--text">Thanks for signing up!</span><br>
                    <span class="caption grey--text">An Email will be sent shortly, taking you through the rest of the process. You may close the page</span>
                    </div>
                </v-window-item>
                </v-window>

                <v-divider v-if="step !== 1"></v-divider>

                <v-card-actions v-if="step !== 1">
                         
                <v-btn
                
                    
                    text
                    @click="step = 1"
                >
                    Login
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    v-if="ProgressButtonDisabled"
                    color="pop"
                    depressed
                    @click="ProgressWindow()"
                >
                    {{StepProgressTitle}}
                </v-btn>
                </v-card-actions>
            </v-img>
            </v-card>      
    </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'


  export default {
    name: 'login',
    data() {
      return {
        rules: {
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
        step: 1,
        email: '',
        password: '',
        usertest: ''
      }
    },
     computed: {
       ProgressButtonDisabled(){
         if(this.step === 4){
           if(this.email && this.email.length>0){
             return true
           }
           else{
             return false
           }
         }
         else if(this.step === 3){
           return false
         }
         else {
           return true
         }
       },
      userLoggedIn () {
      return this.$store.getters.user
    },
    StepProgressTitle(){
            return this.step === 4 ||  this.step === 3? 'Action' : 'Next'
        },
    currentTitle () {
        switch (this.step) {
          case 1: return 'Sign In'
          case 2: return 'Choose an Option'
          case 3: return 'Reset Email'
          case 4: return 'Reset Password'
          default: return 'Request Processed'
        }
    }
    },

    watch: {
      userLoggedIn (value) {
        // if (value !== null && value !== undefined) {
         // this.$router.push('/MyProfile')
       // }
      }
    },
    methods: {
      ProgressWindow(){
        if(this.step === 1){

        }
        else if(this.step === 2){

        }
        else if(this.step === 3){
          this.ResetEmail()
          
        }
        else if(this.step === 4){
          this.ResetPassword()
           
        }
      },
      ResetPassword(){
        //we need to get userlist, call user matching the email, get id of that user, create passwordrequest with Created_On date, send email to user with "Created_On date"  
        let DataRequest = {
          Email: this.email
        }
        console.log('okay will do password funciton call somehow')
        console.log(DataRequest)
        const functions = firebase.functions();
        const UnauthorizedUserPasswordResetrequest = functions.httpsCallable('UnauthorizedUserPasswordResetrequest');
        UnauthorizedUserPasswordResetrequest(DataRequest).then(result => {
                console.log('created user supposedly')
                console.log(result);
                this.step = 5
                }).catch(error => {
                console.log("error",error);
                });
      },
      ResetEmail(){
        alert('something to happen here to reset email')
        this.step = 5
      },
      loginWithFirebase () {
      let vm = this
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(function(result) {
            console.log(result.user.uid)
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
            console.log('login user id',result.user.uid)
              //vm.$emit('AssignAuth',result.user)
          }).catch(function(error) {
            console.log(error)
            if(error.code === 'auth/wrong-password'){
              alert('This password does not seem correct')
            }
            else if(error.code === 'auth/user-not-found'){
              alert('We do not recognize this email')
            }
            // if(error.message.toLowerCase().includes('password is invalid'))
            // Handle error.
          });


    }

  }
    }



</script>

<style scoped>
.signinpad {
  padding: 5%
}
  .login {
    margin-top: 40px;
    color: black
  }
  .logincard {
    padding: 10%
  }
  input {
    margin: 10px 0;
    width: 100%;
    padding: 15px;
  }
  button {
    margin-top: 20px;
    width: 10%;
    cursor: pointer;
  }
  p {
    margin-top: 40px;
    font-size: 13px;
  }
  p a {
    text-decoration: underline;
    cursor: pointer;
  }
  .social-button {
    width: 75px;
    background: white;
    padding: 10px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    outline: 0;
    border: 0;
  }
  .social-button:active {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }
  .social-button img {
    width: 100%;
  }
.stickytopconscious{
  top: 100px
}
</style>


  

  