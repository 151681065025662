
<template>
  <v-main>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new Activity.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>
      <v-layout row wrap class="justify-space-between mx-2">

  <v-tabs  centered>
    <v-tab>
      Tasks
    </v-tab>
    <v-tab-item>
     <v-layout row class="justify-center mx-3">   
        <v-chip-group   
                    v-if="TasksFilterMode == 'Type'"    
                    v-model="selectedType"    
                    active-class="deep-purple--text text--accent-4"   
                    column    
                        
                >   
                <v-chip v-for="type in STDActionTypesbyBoard" :key="type.Name" :value="type">   
                {{ type.Name }}   
                </v-chip>
                </v-chip-group>
                <v-chip-group   
                    v-if="TasksFilterMode"    
                    v-model="selectedFilter"    
                    @change="ActivateProcessMapFilter()"    
                    active-class="deep-purple--text text--accent-4"   
                    column  
                >   
                <v-chip v-for="type in FilteredArray" :key="type.id" :value="type">   
                {{ type[type.identifier] }}   
                </v-chip> 
            </v-chip-group>   
        </v-layout> 
                <v-row v-if="RegardingTypefilter && typeof ActivitiesArray !== 'undefined'"
                class="fill-height"   
                align="start"   
                justify="center">        
                <v-flex lg3 md3 sm6 class="my-3" v-for="status in Labels" :key="status.itemObjKey">                       
                        <v-card width="100%" :class="StatusBGColor(status)" tile @dragover.prevent @drop="taskdragFinish(status, $event)">    
                        <v-card-title :class="StatusColor(status)" style="font-size:12px;">
                            <v-icon color="white">{{status.Icon}}</v-icon>
                            {{status.Name}}
                            <v-spacer></v-spacer>{{Match(status).length}}</v-card-title>  
                                                  
                        <v-list dense class="transparent mx-1"    
                                min-height="100px"> 
                                <!-- <v-hover v-slot="{ hover }"  >    -->
                                <v-card   :class="`chip ${notice.status} my-1`" width="100%" v-for="notice in Match(status)" :key="notice.itemObjKey" >
                                        
                                <v-list>
                                    <v-list-item
                                    :draggable="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" @dragstart="taskdragstart(notice, $event)"
                                ><v-icon small v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">
                                    mdi-drag
                                    </v-icon>
                                    <v-list-item-avatar class="blue" size="25">
                                    <span class="white--text caption mx-1">
                                    {{notice.Owner.Name.substr(0,1)}}{{notice.Owner.Surname.substr(0,1)}}
                                    </span>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                    <v-list-item-title>
                                         {{notice.name}}
                                        <!-- <span class="caption red--text" style="font-size:10px;" v-if="notice.overdue">Overdue</span> -->
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-icon x-small>mdi-calendar</v-icon> {{notice.daycreated}}{{notice.monthcreated}}
                                    </v-list-item-subtitle>
                                    <!-- <v-list-item-subtitle class="caption red--text" style="font-size:10px;" v-if="notice.overdue">
                                         <v-icon color="red" x-small>mdi-alert</v-icon>Overdue
                                    </v-list-item-subtitle> -->
                                    </v-list-item-content>
                                  
                                    <v-list-item-content>
                                        <v-menu offset-y :close-on-content-click="false" top width="300px">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            v-on="on"
                                             v-if="!notice.overdue"
                                             @click="dragging = notice"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                        <v-icon color="red" small v-if="notice.overdue" v-on="on"
                                        @click="dragging = notice">mdi-alert-circle-outline</v-icon>
                                        </template>
                                      
                                            <!-- <div
                                            
                                                class="d-flex transition-fast-in-fast-out blue v-card--reveal"
                                                style="height: 70px;"
                                            > -->
                                            <v-card  width="300px">
                                         <v-card-subtitle :class="StatusColor(status)">
                                             <b>{{notice.name}} - {{status.Name}}</b><br>
                                             <span class="caption">Due Date - {{notice.duedate.toDate()}}</span>
                                             
                                        
                                         </v-card-subtitle>
                                         <v-card-subtitle v-if="notice.overdue">
                                         <span class="overline red--text" style="font-size:10px;" v-if="notice.overdue">
                                             Overdue!!!
                                         </span>
                                         </v-card-subtitle>
                                         <v-card-text class="caption" style="font-size:8px;">
                                             <i>Created by {{notice.creatorfullname}} on {{notice.createdon.toDate()}}</i>
                                         </v-card-text>
                                         <v-card-text>
                                        <span v-html="notice.description" class="caption" style="font-size:10px;">
                                        </span>
                                         </v-card-text>
                                        <v-card-actions class="hidden-md-and-up">
                                           <v-select @change="taskdragFinish(notice.Status)" :items="Labels" item-text="Name" return-object v-model="notice.Status"/>
                                        </v-card-actions> 
                                        <v-card-subtitle v-if="notice.regardingrecord">                                         
                                         <span class="blue--text overline" style="font-size:10px;" v-if="notice.regardingrecord">
                                             Regarding: <router-link :to="notice.regardingrecord.Link">
                                             {{notice.regardingrecord.Name}}
                                             </router-link>
                                         </span>
                                         </v-card-subtitle>   
                                         <v-card-actions>
                                            <v-spacer></v-spacer>
                                             <v-menu offset-y :close-on-content-click="false" top left max-width="1000px">
                                        <template v-slot:activator="{ on }">
                                            <v-badge  overlap
                                            v-if="typeof notice.Comments !== 'undefined' && notice.Comments.length > 0"
                                                :dark="AppisDarkMode">
                                            <v-icon                                            
                                                v-on="on"
                                                color="links"
                                                >
                                                mdi-comment-text-outline
                                            </v-icon>
                                            <span slot="badge" class="white--text"> {{notice.Comments.length}} </span>
                                                </v-badge>
                                            <v-icon                                            
                                            v-on="on"
                                            color="links"
                                            v-if="typeof notice.Comments === 'undefined'"
                                            >
                                            mdi-comment-text-outline
                                        </v-icon>
                                            </template>
                                        <v-card min-width="300px" max-height="700px">
                                        <v-card outlined >

                                        <v-card-title>
                                            Activity Type: {{notice.activitytype}}<v-spacer></v-spacer>
                                            <v-layout row class="justify-end" v-if="notice.activitytype === 'Task' || notice.activitytype === 'Appointment'"><br><br>
                                            <span v-if="notice.status==='Open'"><v-btn fab><v-icon v-if="notice.status==='Open'" color="success" @click="ChangeActivityStatus(notice)">mdi-check-bold</v-icon></v-btn><v-btn fab><v-icon color="red" @click="ChangeActivityStatustoCancelled(notice)">mdi-cancel</v-icon></v-btn></span>
                                            <span  @click="ChangeActivityStatus(notice)" v-if="notice.status==='Completed' || notice.status==='Cancelled'"><v-btn fab><v-icon color="warning">mdi-progress-clock</v-icon>ReOpen</v-btn></span>
                                            </v-layout>
                                        </v-card-title>
                                        <v-card-text>
                                            Description: {{notice.description}}
                                        </v-card-text>
                                        <v-card-text>
                                            DueDate: {{notice.duedate.toDate()}}
                                        </v-card-text>
                                        <v-card-text>
                                            Created By: {{notice.creatorfullname}}
                                        </v-card-text>
                                        </v-card>
                                        <v-card-text>
                                            <v-list two-line subheader>
                                            <v-subheader><h4>Comments:</h4></v-subheader>

                                            <v-list-item v-for="(comment,itemObjKey) in notice.Comments" :key="comment.itemObjKey" class="commentbasicoutlined">
                                            <v-list-item-content class="commentbasicoutlined">
                                            <v-list-item-title>
                                                {{comment.CreatorFullName}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{comment.Date}}
                                                <v-menu offset-y :close-on-content-click="false" top left v-model="comment.ReplyMenu" min-width="400px" max-height="700px">
                                                    <template v-slot:activator="{ on }">
                                                    <v-btn fab small text color="red"><v-icon v-on="on" @click="ReplytoComment(notice,comment)">mdi-reply-circle</v-icon></v-btn>
                                                    </template>
                                                    <v-card outlined>
                                                        <v-card-title>
                                                        Reply to {{comment.CreatorFullName}}
                                                        </v-card-title>
                                                        <v-textarea v-model="comment.ReplyingContent" label="Add Reply here..."></v-textarea>
                                                        <v-card-actions>
                                                        <v-btn @click="PostReplytoComment(notice,comment,itemObjKey,comment.ReplyMenu)">Post</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-menu>
                                            </v-list-item-subtitle>

                                                {{comment.Content}}

                                                <span v-if="comment.Replies.length>0">

                                                <v-subheader><h4>Replies:</h4></v-subheader>
                                                <v-list subheader>
                                                <v-list-item v-for="reply in comment.Replies" :key="reply.itemObjKey"  class="replybasicoutlined">
                                                <v-list-item-content class="replybasicoutlined">
                                                    <v-list-item-title>
                                                    {{reply.CreatorFullName}}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                    {{reply.Date}}
                                                    <v-menu offset-y :close-on-content-click="false" top left v-model="reply.ReplytoReplyMenu" min-width="400px" max-height="700px">
                                                    <template v-slot:activator="{ on }">
                                                    <v-btn fab small text color="blue"><v-icon v-on="on" @click="ReplytoComment(notice,comment)">mdi-reply-circle</v-icon></v-btn>
                                                    </template>
                                                    <v-card outlined>
                                                        <v-card-title>
                                                        Reply to {{reply.CreatorFullName}}
                                                        </v-card-title>
                                                        <v-textarea v-model="comment.ReplyingContent" label="Add Reply here..."></v-textarea>
                                                        <v-card-actions>
                                                        <v-btn @click="PostReplytoComment(notice,comment,itemObjKey,comment.ReplyMenu)">Post</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-menu>
                                                    </v-list-item-subtitle>
                                                    {{reply.Content}}
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list></span>
                                            </v-list-item-content>
                                            </v-list-item>
                                            <v-card outlined >
                                            <v-textarea v-model="notice.NewComment" label="Add Comment here..."></v-textarea>
                                            <v-card-actions>
                                                <v-btn @click="PostComment(notice)">Post</v-btn>
                                            </v-card-actions>
                                             </v-card>
                                                </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-menu></v-card-actions>                               
                                            </v-card>
                                    <!-- </div> -->
                                            
                                        </v-menu>
                                        
                                    </v-list-item-content>
                                </v-list-item>
                                
                               
                              
                            </v-list>                                           
                        </v-card>
                        <!-- </v-hover> -->
                    </v-list> 
                              
                </v-card> 
                  
            </v-flex>
        </v-row>
    </v-tab-item>
    <v-tab>
      Appointments
    </v-tab>
    <v-tab-item>
        <v-dialog v-model="NewEventDialog" max-width="800px">
      <v-card>
        <!-- <v-card-title class="primary white--text">       -->
        <v-toolbar flat dark height="100px">           
          <v-toolbar-title>New Event</v-toolbar-title>
         
        </v-toolbar>
            <!-- <v-list>          
          
        </v-list> -->
        <!-- </v-card-title> -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
          <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" v-model="AppointmentOwner" return-object></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
            <div class="hovercontent mx-3"><v-icon @click="InvitingAttendees = !InvitingAttendees">mdi-account-multiple-plus<p class="tooltiptext">Invite Attendees</p></v-icon></div>
          
          <v-list-item v-if="InvitingAttendees">
            <v-list-item-avatar tile >               
                      <v-btn small @click="ToggleSelectDialog()" color="pop" dark>To:</v-btn>
                      </v-list-item-avatar>
            <v-list-item-content>               
                      <v-combobox v-model="Attendees" multiple  label="To" readonly :rules="[rules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content> 
          </v-list-item>
          <v-list-item> 
            <v-list-item-content>             
              <v-text-field v-model="EventName" label="Subject" :rules="[rules.required]" autocomplete="disabled"></v-text-field>
              </v-list-item-content>
              </v-list-item>
               <!-- <v-list-item>
              <v-list-item-content>             
              <v-text-field v-model="EventName" label="Name"
            ></v-text-field>
              </v-list-item-content>
              </v-list-item> -->
              <v-list-item>
              <v-list-item-content>
                <v-menu
          v-model="EventstartdateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
        </v-menu>             
              </v-list-item-content>
              <v-list-item-content> 
                <v-menu v-if="EventStartDate"
          v-model="EventStartTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartTimeMenu"
        
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartTime"
              label="Start Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventStartTime"
             @input="EventStartTimeMenu = false"
            type="month"
            width="290"
            class="ml-4"
          ></v-time-picker>
        </v-menu>            
              </v-list-item-content>
              <v-list-item-content>
                <v-checkbox v-model="AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>  
                <v-menu
        v-if="EventStartDate"
          v-model="EventenddateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndDate"
              label="End Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
        </v-menu>           
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventEndDate && EventStartDate && EventStartTime"
          v-model="EventEndTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndTimeMenu"
          
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndTime"
              label="End Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventEndTime"
             @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
            type="month"
            width="290"
            class="ml-4"
            
          :allowed-minutes="AllowedEndTimeMinutes"
          :allowed-hours="AllowedEndTimeHours"
          ></v-time-picker>
        </v-menu>           
                           
              </v-list-item-content>
          </v-list-item>
         
            <v-list-item>
              <v-list-item-content>  
                 <v-layout class="justify-center">
          <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <!-- <v-select @click="getDocumentSelection()" :items="[2,4,6,8,10,12,14,16,18,20]" label="Size" @change="ChangefontSize()" v-model="fontsize">                        
                          </v-select> -->
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>                      
                        <!-- </v-btn-toggle>
                        <v-btn-toggle> -->
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <!-- <v-btn>
                            <v-icon>mdi-format-align-center</v-icon>
                          </v-btn> -->
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
                 </v-layout>
              </v-list-item-content>
            </v-list-item>
                        <v-list-item>
              <v-list-item-content>  
                 <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          <v-btn onclick="document.execCommand('undo',false,'')">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('redo',false,'')">
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                          
                          <v-btn onclick="document.execCommand('bold', false, '');">
                            <v-icon>mdi-format-bold</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('italic', false, '');">
                            <v-icon>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('underline', false, '')">
                            <v-icon>mdi-format-underline</v-icon>
                          </v-btn>
                          <v-btn @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <v-btn  onclick="document.execCommand('selectAll',false,'')">
                            <v-icon>mdi-select-all</v-icon>
                          </v-btn>
                                                
                          <v-btn>
                            <!-- <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" style="display: none;" @change="getImage()">

      <label for="file" class="tool-items fa fa-file-image-o"></label> -->
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>                      
                          <v-btn>
                            <v-icon @click="link()">mdi-link</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                            <v-icon>mdi-format-strikethrough</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                 </v-layout>
              </v-list-item-content>
                        </v-list-item>  
         
               <v-list-item>
              <v-list-item-content>
                <v-card width="90%" min-height="300px" outlined flat v-html="EventDetails" contenteditable id="previewsendemail">             
                </v-card>
              </v-list-item-content>
              </v-list-item>
               
               <v-list-item>
                 <v-list-item-content>
                    </v-list-item-content>
                 </v-list-item>
          
        </v-list>
        
            
        
        
        
     
        
        <v-card-actions>
          <v-btn @click="AddNewEvent()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-sheet height="64" class="accent" dark>
        
        <v-toolbar flat dark>
            
          <v-btn outlined class="mr-4" color="secondary" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="secondary" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="secondary" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ datetitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                color="secondary"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet  height="100%" width="100%" v-if="typeof AllEvents !== 'undefined'">
          <v-btn color="green" dark fab icon @click="ActivateNewEvent()"><v-icon>mdi-plus-thick</v-icon></v-btn>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="AllEventsViewFormatted"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              color="pop"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="DeleteEvent()">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>              
            </v-toolbar>
            <v-toolbar height="100px">
              <v-list>
                <v-list-item>
              <v-combobox v-model="selectedEvent.Attendees" multiple  label="To" readonly :rules="[rules.required]" autocomplete="off"></v-combobox>
                </v-list-item>
              </v-list>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-tab-item>
  </v-tabs>
  
  </v-layout>
  </v-main>
</template>
<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ProcessMapComponent from '@/components/Processes/ProcessMapComponent'

export default {
  props: ['AppisDarkMode','ActivityType','UserRecord','AllEvents','ActivitiesArray'],
  components: {
    ProcessMapComponent,
  },
  data() {
    return {
        rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
        focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            TempAllEvents: [],
            NewEventDialog: false,
            EventstartdateMenu: false,
            AppointmentOwner: '',
            InvitingAttendees: false,
            AttendeeSelectDialog: false,
            EventRecordFilter: '',
            MailFieldFilter: '',
            UserHeaders:[
        { text: 'Selected', value: 'selected',sortable: false},
        { text: 'Title', value: 'Title'},
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Gender', value: 'Gender'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
            ArrayFilter: 'To',
            relatedsearches: [],
            search: '',
            Attendees: [],
            EventenddateMenu: false,
            EventEndDate: '',
            EventStartTime: '',
            EventStartTimeMenu: false,
            AllDayEvent: false,
            EventEndTime: '',
            EventEndTimeMenu: false,
            EventStartDate: '',
            EventName: '',
            EventSubject: '',
            EventDetails: '',
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            today: format(new Date(),'yyyy-MM-dd'),
      Labels: [
        {
          Name: 'Open',
          Icon: 'mdi-timeline-help-outline'
          },
          {
          Name: 'In Progress',
          Icon: 'mdi-timer'
          },
           {
          Name: 'On Hold',
          Icon: 'mdi-timeline-alert-outline'
          },
           {
          Name: 'Completed',
          Icon: 'mdi-marker-check'
          },

      ],
      EntityProcesses: [
  {
    "DisplayName": "Standard Task",
    "Number": 1000001,
    "ProcessStages": [
      {
        "Steps": [],
        "Number": 1000001,
        "IsHeader": true,
        "Automations": [],
        "Name": "Inbound",
        "Type": "Stage"
      },
      {
        "Automations": [],
        "Steps": [],
        "Type": "Stage",
        "Number": 1000002,
        "Name": "Stock",
        "IsHeader": true
      },
      {
        "Type": "Stage",
        "Steps": [],
        "Automations": [],
        "Name": "Outbound",
        "IsHeader": true,
        "Number": 1000003
      },
      {
        "Number": 1000004,
        "Automations": [],
        "Steps": [],
        "IsHeader": true,
        "Name": "Aftermarket",
        "Type": "Stage"
      }
    ],
    "Type": "Process",
    "Name": "Lifecycle",
    "IsHeader": true,
    "Automations": [],
    "ProcessStageInteger": 1000004
  }
],
      CreatedOnmenu: false,
      DueDatemenu: false,
      Month: {Name: '',Days: []},
      Years: ['2020','2021'],
      Year: '2020',
      Months: [{Name: 'January', Days: 31},{Name: 'February', Days: 28},{Name: 'March', Days: 31},{Name: 'April', Days: 30},{Name: 'May', Days: 31}],
      WeekDays: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
      ActivityTypeOptions: ['Email','Phone Call','Task','Appointment'],
      ActivityTypefilter: '',
      RegardingTypeOptions: [
  "Account",
  "SiteEvent",
  "User",
  "BusinessUnit"
],
      RegardingTypefilter: '',
      ActivityOwnerFilter: '',
      UsersArray:[],
      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      headers: [
      {
          text: 'id',
          align: 'left',
          sortable: false,
          value: 'id'
        },
        { text: 'Name', value: 'name'},
        { text: 'Description', value: 'description'},
        { text: 'Activity Type', value: 'activitytype'},
        { text: 'Regarding Type', value: 'regardingtype'},
        { text: 'Created On', value: 'createdon'},
        { text: 'Due Date', value: 'duedate'},
        { text: 'Owner', value: 'Owner.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      importdialog: false,
      importsnackbar: false,
      ImportHeaders: [],
      NewImportsArray: [],
      PartialFailuresArray: [],
      SuccessfulImportsArray: [],
      CSVResult: [],
      Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Activities', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
      ImportFile: '',
      EditedActivitysnackbar: false,
      ActivityEditdialog: false,
      newActivitydialog: false,
      editedIndex: -1,
      defaultItem: {
        id: '',
          name: '',
          subject: '',
          to: '',
          from: '',
          description: '',
          activitytype: '',
          regardingtype: '',
          createdon: '',
          Owner: {id: '',Name: '', Surname: '',Full_Name: ''},
          duedate: '',
      },
      editedItem: {
        id: '',
          name: '',
          subject: '',
          to: '',
          from: '',
          description: '',
          activitytype: '',
          regardingtype: '',
          createdon: '',
          Owner: {id: '',Name: '', Surname: '',Full_Name: ''},
          duedate: '',
      },
      
      UserBusinessUnitID: '',
      UserRoles: [],
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
        dragging: '',
        
    }
  },

  created(){
      console.log(this.ActivityType)
    this.RegardingTypefilter = this.ActivityType.countname
    console.log(this.RegardingTypefilter,this.UserRecord)
    this.ActivitiesArray = this.ActivityType.tasks
    this.AllEvents = this.ActivityType.appointments
    // this.GetActivities()
    // this.GetBusinessUnits()
    this.UsersArray = this.UsersStore

  },
  computed: {
      datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
      AllEventsViewFormatted(){
      return this.AllEvents.map(event => {
          let eventobj = Object.assign({},event)    
        if(typeof eventobj.start.seconds !== 'undefined'){

        eventobj.start = this.TimestampFormatterSTRING(eventobj.start.toDate())
        }
        else{

        }
  
        
        if(typeof eventobj.end.seconds !== 'undefined'){
  
        eventobj.end = this.TimestampFormatterSTRING(eventobj.end.toDate())
        }
        else{
      
        }
    
        return eventobj
        
      })
    },
      UsersStore(){
          return this.$store.getters.getUsersArray
      },
    ComputedStageNameOptions(){
  if(this.ProcessID){
    if(this.ProcessID === 1000001){
      return [
  {
    "Steps": [],
    "Number": 1000001,
    "IsHeader": true,
    "Automations": [],
    "Name": "Inbound",
    "Type": "Stage"
  },
  {
    "Automations": [],
    "Steps": [],
    "Type": "Stage",
    "Number": 1000002,
    "Name": "Stock",
    "IsHeader": true
  },
  {
    "Type": "Stage",
    "Steps": [],
    "Automations": [],
    "Name": "Outbound",
    "IsHeader": true,
    "Number": 1000003
  },
  {
    "Number": 1000004,
    "Automations": [],
    "Steps": [],
    "IsHeader": true,
    "Name": "Aftermarket",
    "Type": "Stage"
  }
]
    }
  }
},
    formTitle () {
        return this.editedIndex === -1 ? 'New Activity' : 'Edit Activity'
      },

      ActivitiesSearched() {
      return this.ActivitiesArray.filter(act => {
        if(act.name){
          return act.name.toLowerCase().includes(this.search.toLowerCase())
          }
          else if(act.Name){
          return act.Name.toLowerCase().includes(this.search.toLowerCase())
          }
          })
      },

      ActivitiesFilteredandSearched() {
          console.log(typeof this.ActivitiesSearched)
      return this.ActivitiesSearched.filter(act => {
            if(this.RegardingTypefilter){
              if(act.regardingtype){
                 return act.regardingtype === this.RegardingTypefilter
                }
                }
              else {
                return act
                }
                }).map(not => {
                    console.log(not)
                    if(not.duedate){
                        not.monthcreated = this.TimestampFormatterNoticeBoard(not.duedate.toDate(),'month')
                        not.daycreated = this.TimestampFormatterNoticeBoard(not.duedate.toDate(),'date')
                        let today = new Date()
                        if(not.duedate.toDate()<today){
                          not.overdue = true
                        }
                        
                    }
                        if(not.activitytype === 'Task'){
                          not.typeicon = 'mdi-check-box-outline'
                        }
                        return not
                      })
      },

      HeaderNames() {
        return this.headers.map(header => {
          return header.text
        }).filter(header => {
          return header !== 'id'
        })
      },
      HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
         let correctheader = header.split(' ').join('').toLowerCase()
          return correctheader
        })
      }
    },
  methods: {
      DeleteEvent(){
          confirm('Are you sure? This will completely remove the appointment') && this.ProcessDeleteEvent()
      },
      ProcessDeleteEvent(){
          let index = this.AllEvents.find(obj => obj.id === this.selectedEvent)
          db.collection('activities').doc(this.selectedEvent.id).delete().then(doc => {
              this.$emit('RemoveActivity',this.selectedEvent,'Event')
          })
      },
      CheckAllowedMinutes(){
       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
      AddNewEvent(){
        console.log('this.AllJavaFormatEvents right before we push a new one')
        console.log(this.AllJavaFormatEvents)
        console.log(this.EventStartTime)
        let regardingrecord = {}
        let NEWeditorContent = document.getElementById('previewsendemail')
        this.EventDetails = NEWeditorContent.innerHTML;
        console.log(this.EventDetails)
        let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
        let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
         let NewJavaStartDate = new Date(NewStartDateString)
        let NewJavaEndDate = new Date(NewEndDateString)
        if(typeof this.$route.params.id !== 'undefined'){
        regardingrecord = {
              Link:'/'+this.RelatedObj.Link+'/'+this.$route.params.id, Name:[this.RelatedObj.identifier], [this.RelatedObj.identifier]: this.RelatedObj[this.RelatedObj.identifier],id: this.$route.params.id
            }
            console.log(regardingrecord)
        }
        let NewEvent = {
            Owner: {Full_Name: this.AppointmentOwner.Full_Name,Name: this.AppointmentOwner.Name,Surname: this.AppointmentOwner.Surname,id: this.AppointmentOwner.id},
            Ownerid: this.AppointmentOwner.id,
            Attendees: this.Attendees,
          name: this.EventName,
          subject: this.EventSubject,
          details: this.EventDetails,
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          color: 'pop',
          activitytype: 'Appointment',
        creatorfullname: this.UserRecord.Full_Name,
        creatorid: this.UserRecord.id,
        status: 'Open',
        AllDayEvent: this.AllDayEvent
        
        }
        if(typeof this.$route.params.id !== 'undefined'){
        NewEvent.regardingrecord = regardingrecord
        NewEvent.regardingtype = this.RelatedObj.Link
        }
        if(this.UserRecord.id === this.AppointmentOwner.id){
            NewEvent.FollowingUsers = [{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        else{
            NewEvent.FollowingUsers = [{id: this.AppointmentOwner.id, Name: this.AppointmentOwner.Name, Surname: this.AppointmentOwner.Surname, Full_Name: this.AppointmentOwner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        if(this.Attendees.length > 0){
          NewEvent.AttendeeUserIDStrings = []
          this.Attendees.map(attendee => {
            let userobj = this.UsersArray.find(obj => obj.Email === attendee)
            if(userobj){
              let attendeeobj = {id: userobj.id, Name: userobj.Name, Surname: userobj.Surname, Full_Name: userobj.Full_Name}
              NewEvent.AttendeeUserIDStrings.push(attendeeobj.id)
              let followingobj = NewEvent.FollowingUsers.find(obj => obj.id === attendeeobj.id)
              if(!followingobj){
                NewEvent.FollowingUsers.push(attendeeobj)
              }
            }
          })
        }
         if(this.Business_Unit && typeof this.Business_Unit.id !== 'undefined'){
        NewEvent.Business_Unitid = this.Business_Unit.id
      }
      let FollowingUserIDStrings = []
       let FollowingUserIDKeys = {}
       NewEvent.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       NewEvent.FollowingUserIDKeys = FollowingUserIDKeys
       NewEvent.FollowingUserIDStrings = FollowingUserIDStrings

       
        console.log(NewJavaStartDate)
        console.log(NewJavaEndDate)
        let ref = db.collection('activities').doc()
        let newdocid = ref.id
        console.log(newdocid)
        db.collection('activities').doc(newdocid).set(NewEvent).then(res => {

        })
        this.AllDayEvent = false
        this.EventSubject = ''
        this.EventName = ''
        this.EventDetails = ''
        this.EventStartDate = ''
        this.EventStartTime = ''
        this.EventEndDate = ''
        this.EventEndTime = ''
        this.NewEventDialog = false        
      },
     
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          console.log(this.selectedEvent)
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      ActivateNewEvent(){
        this.Attendees = []
        this.Attendees.push(this.UserRecord.Email)
        this.NewEventDialog = true
      },
      AssignEventEndDate(){
        console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
    StatusBGColor(status){
      if(status.Name === 'Open'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status.Name === 'In Progress'){
        return 'blue-grey lighten-4 mx-3'
      }
      else if(status.Name === 'On Hold'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status.Name === 'Completed'){
        return 'blue-grey lighten-4 mx-3'
      }
      
    },
    StatusColor(status){
      if(status.Name === 'Open'){
        return 'orange white--text'
      }
      else if(status.Name === 'In Progress'){
        return 'blue white--text'
      }
      else if(status.Name === 'On Hold'){
        return 'red white--text'
      }
      else if(status.Name === 'Completed'){
        return 'green white--text'
      }
      
    },
    taskdragstart(task){
        this.dragging = task
        console.log(this.dragging)
    },
    taskdragFinish(status){
        
        if(status.Name === 'Completed'){
            confirm('This will update the item to Completed, and remove it form the outstanding tasks. Are you sure?')
            && this.UpdateTaskStatus(this.dragging,status)
        }
        else{
            this.UpdateTaskStatus(this.dragging,status)
          
        }
        
        console.log(this.dragging)
    },
    UpdateTaskStatus(task,status){
        task.Status = status.Name
        db.collection('activities').doc(task.id).update({
            Status: status.Name
            })
            this.dragging = ''
    },
    Match(status){    
          console.log(status)   
            return this.ActivitiesFilteredandSearched.filter(task => {    
                return task.Status === status.Name    
            })    
        },  
    DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
     GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },

    closeImport(){
      this.importdialog = false
      this.ImportFile = null
      this.Import.Name = ''
    },

    saveImport() {
      let importLocalURL = URL.createObjectURL(this.ImportFile)
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this
      reader.addEventListener('load', function(e) {
            var text = e.target.result;
            vm.CSVConvertToJSON(text)
      });
    },


    CSVConvertToJSON(text){
      alert('another thing')
      this.NewImportsArray = []
      this.PartialFailuresArray = []
      let lines = text.split("\n")
      // let dataonly = lines
      // dataonly.splice(0,1)
      // console.log(dataonly)
      this.CSVResult = []
      this.ImportHeaders=lines[0].split(",");
      for(var i=1;i-1+2<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(",");

      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Full_Name == obj.Full_Name)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)


    }
      this.CSVResult.map((res,i) => {
        // console.log(res)
        this.UpdateFiresStore(res,i)
      })
    },

    UpdateFiresStore(SingleResult, Index){
      console.log("Index")
      console.log(Index)
      let ResultLength = this.CSVResult.length
      console.log("ResultLength")
      console.log(ResultLength)
      console.log("Index-1+2")
      console.log(Index-1+2)
      let OriginalImportObject = Object.assign({},SingleResult)
      let ImportActivity = {
        id: '',
        name: SingleResult.name,
        description: SingleResult.description,
        createdon: SingleResult.createdon,
        duedate: SingleResult.duedate,
        }

        let FailureObject = Object.assign({},ImportActivity)
        FailureObject.Failures = []
        if(SingleResult.activitytype !== ''){
          let Options = [
              "Email",
              "Phone Call",
              "Task",
              "Appointment"
            ]
          let GetObj = Options.find(option => option == SingleResult.activitytype)
          if(typeof GetObj === 'undefined'){
            FailureObject.activitytype = 'Failed - No Matching Option Found'
            ImportActivity.hasFailures = true
            let FailureInstance = {Type: 'No Matching Option', Name: 'activitytype'}
            FailureObject.Failures.push(FailureInstance)
          }
          else{
          let ActivityTypeObject = {id: GetObj.id,companyname: GetObj.companyname}
          ImportActivity.activitytype = SingleResult.activitytype
          }

        }
        if(SingleResult.regardingtype !== ''){
          let Options = [
  "Account",
  "SiteEvent",
  "User",
  "BusinessUnit"
]
          let GetObj = Options.find(option => option == SingleResult.regardingtype)
          if(typeof GetObj === 'undefined'){
            FailureObject.regardingtype = 'Failed - No Matching Option Found'
            ImportActivity.hasFailures = true
            let FailureInstance = {Type: 'No Matching Option', Name: 'regardingtype'}
            FailureObject.Failures.push(FailureInstance)
          }
          else{
          let RegardingTypeObject = {id: GetObj.id,companyname: GetObj.companyname}
          ImportActivity.regardingtype = SingleResult.regardingtype
          }

        }

        if(ImportActivity.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }

        this.NewImportsArray.push(OriginalImportObject)
        // db.collection('activities').add(ImportActivity).then({
        // })
        db.collection('activities').add(ImportActivity).then(doc => {
          let AllImportObj = this.NewImportsArray.find(imp => imp.name == ImportActivity.name)
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = doc.id
          console.log(this.NewImportsArray[AllImportObjIndex])
          console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp.name == ImportActivity.name)

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
          console.log(this.PartialFailuresArray[PartialImportObjIndex])
          console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.name == ImportActivity.name)
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
          console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          console.log(this.SuccessfulImportsArray)
          }
          if (Index-1+2 === ResultLength){
        console.log("NewImportsArray")
        console.log(this.NewImportsArray)
        console.log("PartialFailuresArray")
        console.log(this.PartialFailuresArray)

        let CreatedOn = format(new Date(),'yyyy-MM-dd')
        let User = this.UserRecord
        let RefinedHeaders = this.ImportHeaders.map(header => {
          let obj = {text: header, value: header}
          return obj
        })
        console.log("this.NewImportsArray at final length")
        console.log(this.NewImportsArray)
        let DataImport = {name: this.Import.Name, dataset: 'Activities', datasetrecordpath: 'Activity', createdon: CreatedOn, createdby: {id: User.id, fullname: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
        db.collection('dataimports').add(DataImport).then(doc => {
        var storageRef = firebase.storage().ref('DataImport/CreatedOn/'+this.ImportFile.name);
        var uploadTask = storageRef.put(this.ImportFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('dataimports').doc(doc.id).update({
                    csvfile: url
            })
            this.ImportFile = ''
                }),
                  this.importsnackbar = true
        })

        }
        })


    },

    onImportfileSelected(event){
      this.ImportFile = event.target.files[0]
    },

    DownloadContactsImportTemplate(){
      let copyText = this.HeaderNamesSplit

      var csvfilename = 'Activities.csv'
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
      element.setAttribute('download', csvfilename)
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      // }
    },
    ResetFilters() {
      this.ActivityTypefilter = ''
      this.RegardingTypefilter = ''
      this.ActivityOwnerFilter = ''
    },
    ResetSearch() {
      this.search = ''
    },

    GetActivities() {
      db.collection('activities').where('Ownerid','==',this.UserRecord.id).get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
        console.log(this.ActivitiesArray)


      })
    })
  },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserRecord = userdetails
          vm.UserRecord.id = user.uid
          vm.ActivityOwnerFilter = vm.UserRecord
          vm.GetActivities()
          })
        }
      })
    },

    ChangeActivityStatus(item){
      if(item.status === 'Open'){
        item.status = 'Completed'
        this.SaveActivityStatus(item)
      }
      else if(item.status === 'Completed'){
        item.status = 'Open'
        this.SaveActivityStatus(item)
      }
      else if(item.status === 'Cancelled'){
        item.status = 'Open'
        this.SaveActivityStatus(item)
      }
    },

    ChangeActivityStatustoCancelled(item){
      if(item.status === 'Open'){
        item.status = 'Cancelled'
        this.SaveActivityStatus(item)
      }
    },

    SaveActivityStatus(item){
      db.collection('activities').doc(item.id).update({
        status: item.status
      })

    },



      PostComment(item) {
      let NewComment = {Content: item.NewComment, CreatorFullName: this.UserRecord.Full_Name, Date: format(new Date(),'yyyy-MM-dd'), CreatorId: this.UserRecord.id, Replies: [] }
      if(typeof item.Comments === 'undefined'){
        item.Comments = []
      }
      item.Comments.push(NewComment)
      item.NewComment = ''
      this.SaveCommentsandReplies(item)
    },

    ReplytoComment(item,comment){
      console.log('initiating reply')
      console.log('item')
      console.log(item)
      console.log('comment')
      console.log(comment)
      comment.Replying = true
    },

    PostReplytoComment(item,comment,commentindex,ReplyMenu,ReplytoReplyMenu){
      console.log('posting reply')
      console.log(comment)
      let NewReply = {Content: comment.ReplyingContent, CreatorFullName: this.UserRecord.Full_Name, Date: format(new Date(),'yyyy-MM-dd'), CreatorId: this.UserRecord.id}
      let itemindex = this.ActivitiesArray.indexOf(item)
      item.Comments[commentindex].Replies.push(NewReply)
      comment.ReplyingContent = ''
      item.Comments[commentindex].ReplyMenu = false
      comment.ReplyMenu = false
      ReplyMenu = false
      ReplytoReplyMenu = false


      // this.ActivitiesArray[itemindex] = item
      this.SaveCommentsandReplies(item)
    },

    SaveCommentsandReplies(item){
      db.collection('activities').doc(item.id).update({
        Comments: item.Comments
      })

    },


      deleteItem (item) {
        const index = this.ActivitiesArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('activities').doc(item.id).delete() && this.ActivitiesArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.ActivitiesArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },


      save () {
      const EditedActivity = {
    name: this.editedItem.name,
    description: this.editedItem.description,
    activitytype: this.editedItem.activitytype,
    regardingtype: this.editedItem.regardingtype,
    createdon: new Date(),
    duedate: this.DateFormatter(this.editedItem.duedate),
    Owner: {id: this.editedItem.Owner.id, Name: this.editedItem.Owner.Name, Surname: this.editedItem.Owner.Surname, fullname: this.editedItem.Owner.Full_Name},
    Ownerid: this.editedItem.Owner.id,
    creatorfullname: this.UserRecord.Full_Name,
    creatorid: this.UserRecord.id,
    Status: 'Open'

        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('activities').doc(this.mainrecordid).update({
        name: EditedActivity.name,
        description: EditedActivity.description,
        activitytype: EditedActivity.activitytype,
        regardingtype: EditedActivity.regardingtype,
        createdon: EditedActivity.createdon,
        duedate: EditedActivity.duedate,
        Owner: EditedActivity.Owner,
        Ownerid: EditedActivity.Ownerid,
        creatorfullname: EditedActivity.creatorfullname,
        creatorid: EditedActivity.creatorid

              });
              Object.assign(this.ActivitiesArray[this.editedIndex], this.editedItem)
              this.closeActivityeditDialog
              this.EditedActivitysnackbar = true
        }
         else {
          const NewActivity = {
            name: EditedActivity.name,
            description: EditedActivity.description,
            activitytype: EditedActivity.activitytype,
            regardingtype: EditedActivity.regardingtype,
            createdon: EditedActivity.createdon,
            duedate: EditedActivity.duedate,
            Owner: EditedActivity.Owner,
            Ownerid: EditedActivity.Ownerid,
            creatorfullname: EditedActivity.creatorfullname,
            creatorid: EditedActivity.creatorid,
            status: 'Open',
            FollowingUsers: [EditedActivity.Owner,{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
              }


          db.collection('activities').add(NewActivity).then(doc => {
            if(NewActivity.activitytype === 'Task'){
            const NewNotification = {
              relatedid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+NewActivity.name,
              Content: NewActivity.description,
              CreatorFullName: NewActivity.creatorfullname,
              CreatorID: NewActivity.creatorid,
              Owner: NewActivity.Owner,
              Ownerid: NewActivity.Ownerid,
              Read: false,
              Path: '/Activities/',
              Message: 'a New Task has been assigned to you by '+NewActivity.creatorfullname,
                    Date: new Date()
            }
            db.collection('notifications').add(NewNotification).then({

            })
          }
          })
        }

        this.close()
      },

  }
}

</script>
<style>
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;

}
.replybasicoutlined{

  border: 0.1px solid #dedee9;

}
.outlinefont{
  color: red
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.noticeoutlined{
   border-style: solid;
    border-color: rgb(207, 205, 205);
   border-width: thin;
}
.outlinedavatar{
  border:1px double red;

  background-color: #2196F3;
}
</style>

    