
<template>
<v-content class="align-content-center stickytopconscious">
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Blog.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-layout justify-center>
    <v-flex xs12 s12 m12 lg10>
    <v-dialog v-model="blogquil">
<template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on" v-if="UserCanEdit">Edit</v-btn>
      </template>
       <v-card light>
       <v-layout class="justify-center">
          <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                             <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <!-- <v-select @click="getDocumentSelection()" :items="[2,4,6,8,10,12,14,16,18,20]" label="Size" @change="ChangefontSize()" v-model="fontsize">                        
                          </v-select> -->
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>                      
                        <!-- </v-btn-toggle>
                        <v-btn-toggle> -->
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <!-- <v-btn>
                            <v-icon>mdi-format-align-center</v-icon>
                          </v-btn> -->
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
          </v-layout>
              <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          <v-btn onclick="document.execCommand('undo',false,'')">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('redo',false,'')">
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                          
                          <v-btn onclick="document.execCommand('bold', false, '');">
                            <v-icon>mdi-format-bold</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('italic', false, '');">
                            <v-icon>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('underline', false, '')">
                            <v-icon>mdi-format-underline</v-icon>
                          </v-btn>
                          <v-btn @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <v-btn  onclick="document.execCommand('selectAll',false,'')">
                            <v-icon>mdi-select-all</v-icon>
                          </v-btn>
                               <!-- <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">             
                          <v-btn v-on="on">
                            
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>    
                          
                                </template> -->
                                
                          <!-- <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" style="display: none;" @change="getImage()">

                                <label for="file" class="tool-items fa fa-file-image-o"></label> -->
                               <!-- </v-menu>                   -->
                          <v-btn>
                            <v-icon @click="link()">mdi-link</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="addphone()">mdi-phone</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="addemail()">mdi-email</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                            <v-icon>mdi-format-strikethrough</v-icon>
                          </v-btn>
                        </v-btn-toggle> 
                        
          </v-layout>
 <v-card width="90%" min-height="300px" outlined flat v-html="BlogContent" contenteditable id="blogcontentdiv">
                      </v-card>

  <v-btn @click="UpdateBlog()">save</v-btn>
 </v-card>
    </v-dialog>
    
    <v-card light color="#f8f8f8">
      <v-card>
            <v-select :items="BlogPrivacyTypes"
                  name="Privacy" v-model="editedItem.BlogPrivacy"
                  
                  :value="editedItem.BlogPrivacy"
                  >
                                
                                  <v-text-field :value="editedItem.BlogPrivacy" label="Blog Privacy"></v-text-field>
                                </v-select> 
                                  <v-select :items="PublishStatusTypes"
                  name="Status" v-model="editedItem.PublishStatus"
                  
                  :value="editedItem.PublishStatus"
                  >
                                
                                  <v-text-field :value="editedItem.PublishStatus" label="Publish Status"></v-text-field>
                                </v-select> <v-btn @click="UpdateBlogSettings()">save</v-btn></v-card>
    <h1 id="Grouptitle" class="Grouptitle">{{title}}</h1>


        
    <v-img class="Image" :src="image" width="50%"></v-img>
    <div  class="mx-3">
<span v-html="content"></span></div>
    <v-card-actions>
<v-chip :to="'/userprofile/' + authorid" medium color="secondary" class="white--text">
                  {{author}}
                </v-chip>
    </v-card-actions>
    </v-card>
    


    </v-flex>
    </v-layout>

</v-content>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: [],
      components: {
  },
    data() {
        return {
         
            blogquil: false,
            snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        blogdialog: false,
        blogcontent: 'Insert your texxt bra',
        author: null,
        authorid: null,
        //userid: this.$store.state.user.id,
        groupblogs: [],
        groups: [],       
        blogid: this.$route.params.id,
        content: null,
        image: '',
        UserCanEdit: false,
        BlogPrivacy: null,
        PublishStatus: null,
        BlogPrivacyTypes: [
        {index: 0, text: 'Private'},
        {index: 1, text: 'Members Only'},
        {index: 2, text: 'Public'},
          ],
        PublishStatusTypes: [
        {index: 0, text: 'Draft'},
        {index: 1, text: 'Published'},
          ],  
        title: null,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                BlogPrivacy: null,
                PublishStatus: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                BlogPrivacy: null,
                PublishStatus: null,                
            },
            UserRecord: {}    
        }
    },
    computed: {
        RelatedBlogs () {
            return this.groupblogs.filter(blog => {
                return blog.blogid === this.blogid
            })
        },
            
    },
    
    created() {
        this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.GetRequestingUser()
        this.getBlogInformation()
        
    },
    methods: {
        async GetRequestingUser(){
        let vm = this;
        console.log("I try assign this.UserRecord")
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            console.log('let us see now, yes the user is signed in')
            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              console.log(vm.UserRecord)
            })
        }
        else{
            console.log('nope the user is apprantely NOT signed in')
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
        addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },

    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'blogcontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
            UserBlogrelation(){
                if(this.authorid === this.UserRecord.id) {
            this.UserCanEdit = true
                }
                else {
            this.UserCanEdit = false
                }
            },
        UpdateBlog(){
          console.log(this.editor.scrollingContainer.innerHTML)

            const UpdatedBlog = {
                content: this.editor.scrollingContainer.innerHTML
            }

            db.collection('systemtraining').doc(this.blogid).update({
                content: UpdatedBlog.content
            });
              this.snackbar = true
            this.blogquil = false
        },
                UpdateBlogSettings(){


            const UpdatedBlog = {
                PublishStatus: this.editedItem.PublishStatus,
                BlogPrivacy: this.editedItem.BlogPrivacy
            }

            db.collection('systemtraining').doc(this.blogid).update({
                PublishStatus: UpdatedBlog.PublishStatus,
                BlogPrivacy: UpdatedBlog.BlogPrivacy
            });
              this.snackbar = true
            this.blogquil = false
        },
        onEditorChange() {
// alert('change')
        },
    async getBlogInformation() {
        let blogcollection = db.collection('systemtraining')
        let docref = blogcollection.doc(this.blogid)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var blogdata = snapshot.data()
        
      
        this.title = blogdata.title
        this.content = blogdata.content
        this.blogcontent = blogdata.content
        this.author = blogdata.author
        this.authorid = blogdata.authorid
        this.image = blogdata.image
        this.PublishStatus = blogdata.PublishStatus
        this.BlogPrivacy = blogdata.BlogPrivacy
        this.editedItem.BlogPrivacy = blogdata.BlogPrivacy
        this.editedItem.PublishStatus = blogdata.PublishStatus
      console.log('blog id is '+this.blogid)
        console.log('user id is '+this.UserRecord.id)
        console.log('author id is '+this.authorid)
this.UserBlogrelation()
        })
    
        },
      OpenBlogdialog() {
        this.blogdialog = true
    },
    Closedialog() {
        this.blogdialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
     save() {
           if(this.$refs.form.validate()) {
                    var storageRef = this.firestore.storage().ref('System_Training_Documents/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.BlogPrivacy = this.editedItem.BlogPrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('systemtraining').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      },
      
   
     saveBlogSettings() {
           if(this.$refs.form.validate()) {
                    var storageRef = this.firestore.storage().ref('System_Training_Documents/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.BlogPrivacy = this.editedItem.BlogPrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('systemtraining').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {
align-content: center;
margin-left: 0.5em
}
    
</style>
    