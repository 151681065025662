                <template>
      
<v-card tile :style="{marginTop:'126px'}" class="transparent" width="100%" height="100%"  flat>

    <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
            
            <v-flex lg12 md12 sm12 xs12>
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center style="padding:7px;">
            <div class="flex xl11 lg11 md11 sm12 xs12">
            
             
            
            <v-row        
                    class="mb-6 justify-start"
                    no-gutters
                    style="margin-top:px;margin-bottom:px;margin-left:px;margin-right:px;"                       
                    >
                    
                    
                                
                     <v-col
                            :xl="6" :lg="8" :md="10" :sm="12" :xs="12"
                            cols="12"    
                            class=" my-1"  
                            style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;align-self:undefined"                      
                        > 
                        <v-card :flat="true" :tile="true" height="100%"  class="transparent mx-1">
                        
                        
                          
                        
                            
     <v-hover v-slot="{ hover }">
    <v-card class=" pa-2" :dark="false"
                            height="100%" color="#ffffffff" :elevation="4"     style="padding:20px;margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;" >
                                 <v-expand-transition>
                                    <div
                                        v-if="hover && WebFormtab.FormSubmitted"
                                        class="d-flex transition-fast-in-fast-out darken-2 display-2 white--text"
                                        style="height: 100%;position: absolute;background-color: rgba(50, 59, 68, 0.75);align-items: center;justify-content: center;witdh:100%;z-index:2"
                                    >
                                    <p style="padding:20px;">
                                        Thank you!
                                        Your submission has processed...
                                    </p>
                                    </div>
                                    <div
                                        v-if="WebFormtab.FormProcessing && !WebFormtab.FormSubmitted && !WebFormtab.FromDatabase"
                                        :class="'d-flex transition-fast-in-fast-out v-card--reveal mediumoverline white--text'"
                                        :style="'height: 100%;background-color:rgba(50, 59, 68, 0.75);'"
                                    >
                                        <v-progress-circular size="130"
                                        :width="5"
                                        color="white"
                                        indeterminate
                                        >Processing</v-progress-circular>
                                    </div>

                                    </v-expand-transition>

                                 
                                    <v-card-title style="padding-bottom: 20px;color:#000000;font-family: Michroma, sans-serif;font-size:50px;">
                                    <strong>Web Form</strong></v-card-title>
                                    <v-card-text>
                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                    
                                        <span v-for="field in WebFormtab.FormFields" :key="field.itemObjKey"><span v-if="field.Type === 'Option Set'">
                                            <v-select :readonly="WebFormtab.FormProcessing" :rules="[rules.required]" v-model="field.Response" :items="field.Options" :label="field.Name" item-text="Name"></v-select></span><span v-if="field.Type === 'Single Line Text'">
                                            <v-text-field  :readonly="WebFormtab.FormProcessing"  :rules="[rules.required]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'">
                              <v-text-field  :readonly="WebFormtab.FormProcessing"  :rules="[rules.required,rules.email]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Telephone Number'">
                              <v-text-field  :readonly="WebFormtab.FormProcessing"  :rules="[rules.required,rules.telnr]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                                                <v-list dense class="outline">
                                                    <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title>
                                                    <v-list-item v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                                        <v-text-field :readonly="WebFormtab.FormProcessing" v-model="breakdownfield.Response" :label="breakdownfield.DisplayName"/>
                                                    </v-list-item>
                                                </v-list>
                                                </span><span v-if="field.Type === 'Number Field'">
                                            <v-text-field  :readonly="WebFormtab.FormProcessing"  :rules="[rules.required]" v-model="field.Response" type="number" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text'">
                                            <v-textarea  :readonly="WebFormtab.FormProcessing"  :rules="[rules.required]" v-model="field.Response"  :label='field.Name'></v-textarea></span><span v-if="field.Type === 'Lookup'">
                                            <v-autocomplete  :readonly="WebFormtab.FormProcessing"  :rules="[rules.required]" v-model="field.Response"  :label='field.Name'></v-autocomplete></span><span v-if="field.Type === 'Date'">
                                            <v-menu 
                                            v-model="field.DateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="200px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                :label="field.Name"
                                                prepend-icon="mdi-calendar-month"
                                                readonly  :rules="[rules.required]"
                                                v-on="on"
                                                v-model="field.Response"
                                                ></v-text-field>
                                                </template>
                                            <v-date-picker :readonly="WebFormtab.FormProcessing"  v-model="field.Response"
                                                    @input="field.DateMenu = false"></v-date-picker>
                                            </v-menu>
                                            <v-menu v-if="field.HasTimeInput"
                                                v-model="field.TimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px" 
                                                id="fieldTimeMenu"
                                                
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-model="field.TimeResponse"
                                                    :label="field.Name+' Time'"
                                                    prepend-icon="mdi-calendar-month"
                                                    readonly
                                                    v-on="on"
                                                    :id="field.Name+'Time'"
                                                    clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-model="field.TimeResponse"
                                                    @input="field.TimeMenu = false"
                                                    type="month" :readonly="WebFormtab.FormProcessing" 
                                                    width="290"
                                                    class="ml-4"
                                                ></v-time-picker>
                                                </v-menu></span><span v-if="field.Type === 'Single File Upload'">
                                            <input
                                    
                                        type="file">
                                    <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> --></span></span>
                                        
                                        <!-- <v-checkbox
                                        v-model="checkbox"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        label="Do you agree?"
                                        required
                                        ></v-checkbox> -->
                                        
                                    </v-form>
                                    </v-card-text>
                                <v-card-actions class="justify-end">
                                        <v-btn
                                        :disabled="WebFormtab.FormSubmitted || WebFormtab.FormProcessing"
                                        color="links"
                                        class="mr-4"
                                        @click="SubmitForm(WebFormtab)"
                                        >
                                        Submit
                                        </v-btn>
                                        </v-card-actions>
                                <!-- <v-card-text > -->
                                    <!-- <v-layout class="" row> -->
                                   <!-- </v-layout> -->
                                 <!-- </v-card-text> -->
                            
                                </v-card>
                                </v-hover>

                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                        </v-card>
                     </v-col>
            </v-row>
             </div>
             </v-layout>
           </v-card>
          
          </v-flex>
          
          
  
    </v-card>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'


export default {
    props: ['AppisDarkMode','IntranetView','AppisDarkModeCard'],
    components: {
        },
    data() {
    return {
      carddarkcolor: 'grey darken-4',
      cardlightcolor: 'white',
        mustreadnotices: [],
        noticestep: 0,
          
      WebFormtab: {
        FormSubmitted: false,
        FormFields: [
  {
    "Name": "Email",
    "DisplayName": "Email",
    "Type": "Common Field",
    "CommonFieldType": "Email"
  },
  {
    "Type": "Multiple Lines Text",
    "DisplayName": "Enquiry",
    "Name": "Enquiry"
  },
  {
    "DisplayName": "Name",
    "Name": "Name",
    "Type": "Single Line Text"
  },
  {
    "Type": "Single Line Text",
    "DisplayName": "Surname",
    "Name": "Surname"
  },
  {
    "Name": "Telephone_Number",
    "Type": "Common Field",
    "CommonFieldType": "Telephone Number",
    "DisplayName": "Telephone Number"
  }
],
        FormEmailRecipient: 'hennielouw@rapidapps.co.za',
        EntityForm: true,
        RelatedEntity: {
  "datasethasrecordowner": true,
  "Fields": [
    {
      "id": "Email",
      "Name": "Email",
      "Created": false,
      "CommonFieldType": "Email",
      "Type": "Common Field",
      "OnWebForm": true,
      "Primary": false,
      "DisplayName": "Email",
      "UsedonForm": true
    },
    {
      "Type": "Multiple Lines Text",
      "OnWebForm": true,
      "Created": false,
      "Name": "Enquiry",
      "Primary": false,
      "DisplayName": "Enquiry",
      "UsedonForm": true,
      "id": "Enquiry"
    },
    {
      "id": "Name",
      "UsedonForm": true,
      "Name": "Name",
      "IsHeader": true,
      "Type": "Single Line Text",
      "Created": true,
      "DisplayName": "Name",
      "OnWebForm": true,
      "IsSearch": true,
      "Primary": true,
      "IsMandatory": true
    },
    {
      "UsedonForm": true,
      "Created": false,
      "Type": "Single Line Text",
      "DisplayName": "Surname",
      "Name": "Surname",
      "Primary": false,
      "OnWebForm": true,
      "id": "Surname"
    },
    {
      "Type": "Common Field",
      "OnWebForm": true,
      "Created": false,
      "UsedonForm": true,
      "DisplayName": "Telephone Number",
      "Name": "Telephone_Number",
      "id": "Telephone_Number",
      "CommonFieldType": "Telephone Number",
      "Primary": false
    }
  ],
  "SingleName": "Enquiry",
  "id": "Enquiries",
  "Name": "Enquiries"
},
        DefaultOwner: {
  "Name": "Hennie",
  "Full_Name": "Hennie Louw",
  "Surname": "Louw",
  "Email": "hennielouw@rapidapps.co.za",
  "id": "6wbvUT4kJIPk5p6z4ygnjeURh5B3"
}
      },
      RelevantTopBannerAds: [],      
      UserRecord: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()

    },
    
    methods: {
      GetHeaderSize(int){
         if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
              return int/2
          }
          else if(this.$vuetify.breakpoint.md){
              return int/1.5
          } 
          else {
              return int
          }
      },
      ActivateNotificationSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateNotificationSnackbar',boolean,snackbarcontent)
      },
      ActivateStandbyDialog(boolean){
        this.$emit('ActivateStandbyDialog',boolean)
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      SubmitForm(tab){
            console.log(tab)
            tab.FormProcessing = true
            if(tab.FromDatabase){
                this.$emit('ProcessingData',true)
            }
            if(tab.FormEmailRecipient){
                let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry - Contact Us</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`
                 } 
            })
            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New Enquiry - Contact Us',
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
            }
            console.log('tab.EntityForm',tab.EntityForm)
            if(tab.EntityForm){
                let NewRecord = {
                    Created_On: new Date()
                }
                let length = tab.FormFields.length
                tab.FormFields.map((field,i) => {
                NewRecord[field.Name.split(' ').join('')] = field.Response
                if(field.Type === 'Date'){
                    let date = this.DateFormatter(field.Response)
                    NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: date.getTime()}    
                    }
                if(i-1+2 === length){
                  const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(tab.DefaultOwner){
                    NewRecord.Owner = tab.DefaultOwner
                    NewRecord.Ownerid = tab.DefaultOwner.id
                  }
                  if(this.userLoggedIn){
                      NewRecord.WebSubmission = false
                  }
                  else{
                      NewRecord.WebSubmission = true
                  }
                  if(!NewRecord.Created_By && this.userLoggedIn){
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Created_Byid = this.userLoggedIn.id
                      
                  }
                  let obj = {
                    collection: tab.RelatedEntity.id,
                    data: NewRecord
                  }    
                  console.log(obj)        
                  SubmitFormData(obj).then(result => {
                    console.log(result)
                    if(tab.FromDatabase){
                        this.$emit('ConfirmSubmission',tab,obj,result.data)
                        this.$emit('ProcessingData',false)
                    }
                    else{
                      tab.FormSubmitted = true  
                    }
                    
                }) 
            }
                })
            }
        },
      GetMustReadNotices() {
           db.collection('notices').where('MustRead','==',true).onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                  })
                  
                
              }  
              

            })
          })

          },
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
             let vm = this
            if(this.userLoggedIn){
              vm.UserRecord = this.userLoggedIn
              vm.GetMustReadNotices()
              let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)           
              vm.GetBannerAds(RouteBannerAdsRef)
              console.log(vm.UserRecord)
                }
                else{
                  let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
                 
                  vm.GetBannerAds(PublicRouteBannerAdsRef)
                }
        },
          SubmitWeb_Form(){
            let NewWeb_FormObject ={
              CreatedOn: new Date(),
              SubmissionType: 'Web Form',
              SubmissionForm: 'Web Form',
              SubmissionPage: 'Contact Us',
            }
            
            db.collection('webformsubmissions').add(NewWeb_FormObject).then(doc => {
              
            })
            let oldform = document.getElementById('Web_Formform')
            let formheight = oldform.style.height
            console.log('formheight',formheight)
            let newform = document.getElementById('PostWeb_Formform')
            oldform.style.display = "none"
            newform.style.display = "block"
            newform.style.height = formheight
            
          },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}
</style>
  
            
        