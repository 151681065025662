      <template>
<v-card flat class="transparent" :dark="AppisDarkMode">
  <v-dialog class="black" v-model="FullScreenCarouselIMGDialog" fullscreen persistent>
    <v-btn absolute fab style="top:25px;" top right dark class="secondary" @click="CancelFullScreenCarouselIMGDialog()"><v-icon>mdi-fullscreen-exit</v-icon></v-btn>
    <v-btn v-if="ActivePostImages[CarouselIMG-1]" absolute fab :style="'top:'+ImageMaxHeight+'px;'"  bottom left dark class="accent" @click="CarouselIMG = CarouselIMG-1">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn v-if="ActivePostImages[CarouselIMG+1]" absolute fab :style="'top:'+ImageMaxHeight+'px;'"  bottom right dark class="accent" @click="CarouselIMG = CarouselIMG+1">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-right</v-icon>
    </v-btn>
  
  <v-row
      class="fill-height black"
      align="center"
      justify="center"
    >
    <v-img contain v-if="ActivePostImages[CarouselIMG] && ActivePostImages[CarouselIMG].fileurl" height="100%"
                :src="ActivePostImages[CarouselIMG].fileurl"
                :alt="ActivePostImages[CarouselIMG].Name"
            />
   </v-row>
  </v-dialog>

  <v-dialog v-model="PostImagesCarousel"  fullscreen persistent>
    <v-card >
      <v-btn absolute fab style="top:25px;" top right dark class="accent" @click="PostImagesCarousel = !PostImagesCarousel"><v-icon>mdi-close</v-icon></v-btn>
      <v-card-title v-if="EditingCarousel">
        
        <v-list-item>
          <v-list-item-avatar>
              <img v-if="UserRecord.Profile_Pic"
                :src="UserRecord.Profile_Pic"
                :alt="UserRecord.Full_Name"
            >
            <img v-if="!UserRecord.Profile_Pic"
                src='@/assets/BlankProfilePic.png'
                :alt="UserRecord.Full_Name"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              <b><a :href="'/SiteMember/'+UserRecord.id">{{UserRecord.Full_Name}}</a></b>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
      </v-card-title>
      <v-card-title v-if="!EditingCarousel">
        <v-list-item>
          <v-list-item-avatar>
              <img v-if="ActivePost.creatorimg"
                :src="ActivePost.creatorimg"
                :alt="ActivePost.creatorname"
            >
            <img v-if="!ActivePost.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="ActivePost.creatorname"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              <b><a :href="'/SiteMember/'+ActivePost.Creatorid">{{ActivePost.creatorname}}</a></b>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
        <v-flex xl8 lg8 md8 sm12 xs12>
    <v-carousel v-model="CarouselIMG" :height="ImageMaxHeight+150">
     <v-carousel-item
        class="black"
        @click="AlertMe()"
        v-for="(item,i) in ActivePostImages"
        :key="i"
        style="align-self:center;"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      <v-img :src="$vuetify.breakpoint.xs ||$vuetify.breakpoint.sm ? item.ThumbURL : item.fileurl" contain height="100%"></v-img>
     
      </v-carousel-item>
    </v-carousel>
        </v-flex>
        <v-flex xl4 lg4 md4 sm12 xs12>
        <SocialCommentsandLikes :IsChild="true" :ChildProp="'Name'" :Child="ActivePostImages[CarouselIMG]" :HideSummary="true" :Flat="true" :ChildType="'Post Image'"
        :ChildArrayProp="'Images'" :CanSocial="UserCanSocialTopic"
        :PathName="'SitePosts'" :panel="0" :notificationitem="'Post'" :RelatedType="'Site Post Images'" :SocialItem="ActivePost" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
        </v-flex>
        </v-row>
      </v-card-text>
  
      <v-card-actions v-if="ActivePostImages[CarouselIMG]">
        <v-list dense class="transparent" width="100%">
         
          <v-list-item v-if="EditingCarousel">
            <v-textarea v-model="ActivePostImages[CarouselIMG].Description" label="Description"/>
       
          </v-list-item>
          <v-list-item v-if="!EditingCarousel && ActivePostImages[CarouselIMG].Description">
            {{ActivePostImages[CarouselIMG].Description}}
       
          </v-list-item>
        </v-list>
      </v-card-actions>
      <v-card-actions>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-card flat id="postwrapper" class="transparent">
      <v-layout row class="justify-center my-1">
          <v-btn-toggle dark id="PostInsertButtons" style="display:none">
                <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" multiple @change="getImage($event)" hidden ref="postimage">           
              <v-btn icon small @click="$refs.postimage.click()">
                <v-icon small>mdi-file-image</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="link()">mdi-link</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="addphone()">mdi-phone</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="addemail()">mdi-email</v-icon>
              </v-btn>
              <v-btn icon small onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon small>mdi-format-list-bulleted</v-icon>
              </v-btn>
        </v-btn-toggle>
    </v-layout>                        
      <v-layout row class="justify-center">  
        <v-card flat :width="inputwidth"  class="transparent">   
          <v-card-text v-if="selectedImagespreview.length>0">
            <v-layout row class="align-center mx-3">
              <v-img
                    :src="selectedImagespreview[0].fileurl"                   
                    aspect-ratio="1"
                    max-height="400"
                    contain
                    class="transparent"
                  />
              
            </v-layout>
            <v-layout row class="align-center mx-3">
                 <v-col
                v-for="(card,i) in selectedImagespreview.slice(0,ImagesSlice)"
                :key="card.itemObjKey"
                :cols="ImagesColumns"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-2"  @click="OpenCarousel(card,i,true)"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.fileurl"                   
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                     <div
                      v-if="hover && EditingMultipleFileUpload1"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      Edit
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
                </v-layout>

          </v-card-text>                   
          <div flat contenteditable="true" class="postinputbox"  id="postinput">
          </div> 
          <v-card-actions class="my-1">
            <v-spacer></v-spacer>
            <v-btn @click="CreatePost()" style="display:none" id="CreatePostButton" text>Post</v-btn><v-btn @click="CancelPost()"  style="display:none" id="CancelPostButton" icon ><v-icon color="red">mdi-cancel</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-card>
 <v-card flat class="transparent">
    <v-row  v-for="post in PostsTimeline.slice(0,Slice)" :key="post.itemObjkey"
      
      align="center"
      >
      <v-card outlined class="mx-1 my-1" width="100%">
        <v-card-title >
          <v-list dense class="transparent" width="100%">
          <v-list-item>
          <v-list-item-avatar>
              <img v-if="post.creatorimg"
                :src="post.creatorimg"
                :alt="post.creatorname"
            >
            <img v-if="!post.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="post.creatorname"
            >
            
          </v-list-item-avatar>
             <v-list-item-content style="font-size:10px" v-if="post.Creatorid !== post.WallID && UserorGroupID !== post.WallID && post.WallType === 'Group'">
              <span><b><a :href="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</a></b> on <v-chip small :to="'/'+post.WallType+'/'+post.WallID">{{post.ShareName}}</v-chip></span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.Creatorid !== post.WallID && UserorGroupID !== post.WallID && post.WallType === 'SiteMember'">
              <span><b><a :href="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</a></b> on <a :href="'/'+post.WallType+'/'+post.WallID">{{post.ShareName}}</a>'s Wall</span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.Creatorid === post.WallID || UserorGroupID === post.WallID">
              <span><b><a :href="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</a></b></span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.createdon">
              <span v-if="post.createdon.toDate">{{post.createdon}}</span>
              <span v-else>{{post.createdon}}</span>
            </v-list-item-content>
          </v-list-item>
          
        
           <v-list-item v-if="post.IsLink && post.InternalLink && post.ComponentName">
             <SocialPostSharedItem :LinkID="post.LinkID" :collectionname="post.collectionname" :UserRecord="UserRecord" :Location="post.InternalLinkType"
              />
            </v-list-item>
          <v-list-item >
          <v-list-item-content  v-html="post.post" style="font-size:12px">
              
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="post.Images">
            <!-- :aspect-ratio="post.Images[0].DisplayRatio.Ratio"
              :max-width="450/post.Images[0].DisplayRatio.Ratio" -->
            <v-img
              
              :width="150/post.Images[0].DisplayRatio.Ratio"
               @click="OpenCarousel(post,0)"
              :src="post.Images[0].ThumbURL"                   
              
                  />
                  <v-row class="mx-1">
                    <v-col
                      v-for="(card,i) in post.Images.slice(1,ImagesSlice)"
                      :key="card.itemObjKey"
                      class="d-flex child-flex"
                      :cols="GetImageColumns(post.Images.slice(1,ImagesSlice))"
                    >
                      <v-img
                        :src="card.ThumbURL"
                        :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                         @click="OpenCarousel(post,i)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
           <!-- <div class="layout justify-start row mx-0">
             
             <div :class="'col col-'+GetImageColumns(post.Images.slice(1,ImagesSlice))" v-for="(card,i) in post.Images.slice(1,ImagesSlice)"
                :key="card.itemObjKey" 
                :cols="ImagesColumns">
            
                  <v-img @click="OpenCarousel(post,i)"
                    :src="card.ThumbURL"                   
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                   
                  </v-img>
             </div>
           </div> -->
          </v-list-item>
      </v-list> 
        </v-card-title>
        <!-- HERE STARTS WHAT SHOULD BECOME LIKES AND COMMENTS COMP -->
              <!-- </v-card-actions> -->
             <SocialCommentsandLikes :FromSocialPosts="true" :PathName="'SitePosts'" :CanSocial="UserCanSocialTopic" :notificationitem="'Post'" :RelatedType="'Site Post'" :SocialItem="post" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
             
           
                  <!-- HERE ENDS WHAT SHOULD BECOME LIKES AND COMMENTS COMP -->
                     
                   
              
            </v-card>  
            </v-row>
          </v-card>  
  

</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SocialPostSharedItem from '@/components/System/SocialPostSharedItem';
import SocialCommentsandLikes from '@/components/System/SocialCommentsandLikes';

export default {
    props: ['UserRecord','Slice','UserorGroupView','AppisDarkMode','UserorGroupID','UserObj','WallType'],
    components: {
      SocialPostSharedItem,
      SocialCommentsandLikes
        },
    data() {
        return {
          rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
          PostImagestotallist: 0,
          PostImagesuploadTotal: 0,
          PostImagsrunningpercenteage: 0,
          PostImagesrunningProgress: 0,
          PostImagesuploadPercentage: 0,
          PostImageslistuploadcount: 0,
          CollectionRef: db.collection('socialsiteposts'),
          NewCommentInput: '',
          NewReplyInput: '',
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
            WallID: '',
            selectedImages: '',
            selectedImagespreview: [],
            NewPostImages: [],
            PostImagesCarousel: false,
            EditingCarousel: false,
            CarouselIMG: '',
            ActivePostImages: [],
            ActivePost: '',
            FullScreenCarouselIMGDialog: false,
        }
        
    },

    created(){
      setTimeout(() => {
        this.WatchforURLPasteEvent()
      }, 1000);
      if(this.UserorGroupView){
        
        this.WallID = this.UserorGroupID
        this.CollectionRef = this.CollectionRef.where('WallID','==',this.WallID)
        this.GetSitePosts()
      }
      else{
        this.CollectionRef = this.CollectionRef
        this.WallID = this.UserRecord.id
        this.GetSitePosts()
      }
      // this.GetSocialComments()
      // this.GetSocialLikes()
      // this.GetSocialReplies()
        window.onload = setTimeout(() => {
          document.addEventListener("focusin", function(e) {
            var x = document.activeElement.id
          let stylebuttons = document.getElementById('PostInsertButtons')
          let createpostbtn = document.getElementById('CreatePostButton')
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let postwrapper = document.getElementById('postwrapper')
          if(x === 'postinput'){
              stylebuttons.style.display = 'block'
              createpostbtn.style.display = 'block'
              cancelpostbtn.style.display = 'block'
              postwrapper.style.outlineStyle = 'solid'
              postwrapper.style.outlineColor = '#dee6ec'
              postwrapper.style.outlineWidth = "1px"
          }
          
        });
          
          this.postelmnt = document.getElementById("postinput")          
          document.getElementById("postinput").addEventListener("paste", function(e) {
          e.preventDefault();
          if (e.clipboardData && e.clipboardData.getData) {
            var text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
          } else if (window.clipboardData && window.clipboardData.getData) {
            var text = window.clipboardData.getData("Text");
            insertTextAtCursor(text);
          }
        });
        
      }, 300);

    },

    watch: {
    // WritingPost (value) {
    //   if (value !== null && value !== undefined && value === true) {
    //     // this.inputwidth = '90%'
    //     // let postelmnt = document.getElementById('postinput')
    //     setTimeout(() => {
    //         this.postelmnt.focus()
    //       }, 100); 

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
  },

    computed: {
      UserCanSocialTopic(){
        if(this.$route.name === 'GroupSingle'){
          let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
          return memberobj
        }
        else{
          return this.userLoggedIn
        }
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      ImagesColumns(){
        if(this.$vuetify.breakpoint.xl){
          return 3
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else {
          return 4
        }
      },
      ImageMaxHeight(){
        
        if(this.$vuetify.breakpoint.xl){
          return 450
        }
        else if(this.$vuetify.breakpoint.lg){
          return 350
        }
        else if(this.$vuetify.breakpoint.md){
          return 200
        }
        else {
          return 150
        }
      },
      ImagesSlice(){
        if(this.$vuetify.breakpoint.xl){
          return 24
        }
        else if(this.$vuetify.breakpoint.lg){
          return 12
        }
        else if(this.$vuetify.breakpoint.md){
          return 6
        }
        else {
          return 3
        }
      },
      ComputedSocialReplies(){
        return this.SocialReplies.map(mainreply => {
          this.SocialReplies.map(reply => {
                    if(reply.relatedid === mainreply.id){
                      let replyonmainreplycheck = mainreply.Replies.find(obj => obj.id == reply.id)
                      if(!replyonmainreplycheck){
                        mainreply.Replies.push(reply)
                      }
                    }
                  })
          return mainreply
        }).map(mainreply => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === mainreply.id){
                      let likeonmainreplycheck = mainreply.Likes.find(obj => obj.id == like.id)
                      if(!likeonmainreplycheck){
                        mainreply.Likes.push(like)
                      }
                    }
                  })
          return mainreply
        })
      },
      ComputedSocialComments(){
        return this.SocialComments.map(comment => {
          this.ComputedSocialReplies.map(reply => {
                    if(reply.relatedid === comment.id){
                      let replyoncommentcheck = comment.Replies.find(obj => obj.id == reply.id)
                      if(!replyoncommentcheck){
                        if(reply.Replies){
                          comment.Replies = comment.Replies.concat(reply.Replies)
                        }
                        comment.Replies.push(reply)
                      }
                    }
                  })
          return comment
        }).map(comment => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === comment.id){
                      let likeoncommentcheck = comment.Likes.find(obj => obj.id == like.id)
                      if(!likeoncommentcheck){
                        comment.Likes.push(like)
                      }
                    }
                  })
          return comment
        })
      },
      PostsTimeline () {
        return this.Posts.map(post => {
          // this.ComputedSocialComments.map(comment => {
          //   if(comment.relatedid === post.id && !comment.IsChild){
          //     let oncheck = post.Comments.find(obj => obj.id == comment.id)
          //       if(!oncheck){
          //         post.Comments.push(comment)
          //       }
          //     }   
          //     else if(comment.relatedid === post.id && comment.IsChild && post.Images){
          //       post.Images = post.Images.map(img => {
          //         if(!img.Comments){
          //           img.Comments = []
          //         }
          //         if(comment.Child === img.Name){
          //          let oncheck = img.Comments.find(obj => obj.id == comment.id)
          //           if(!oncheck){
          //             img.Comments.push(comment)
          //           } 
          //         }
                  
          //           return img
          //       })
              
          //     }
          // })
          return post
        }).map(post => {
          // this.SocialLikes.map(like => {
          //   if(like.relatedid === post.id){
          //     let oncheck = post.Likes.find(obj => obj.id == like.id)
          //       if(!oncheck){
          //         post.Likes.push(like)
          //       }
          //     }   
          // })
          return post
        }).map(post => {
          // this.ComputedSocialReplies.map(reply => {
          //   if(reply.relatedid === post.id){
          //     let oncheck = post.Likes.find(obj => obj.id == reply.id)
          //       if(!oncheck){
          //         post.Likes.push(reply)
          //       }
          //     }   
          // })
          return post
        }).map(post => {          
          if(post.createdon.toDate){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        }).sort((a, b) => {
            var key1 = b.createdon;
            var key2 = a.createdon;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },

    },

    methods: {
      GetURLPreview(url){
        console.log(url)
      },
      WatchforURLPasteEvent(){
      const target = document.getElementById('postinput');
      target.addEventListener('paste', (event) => {
      let paste = (event.clipboardData || window.clipboardData).getData('text');
        let urltest = this.rules.url(paste)
        //console.log('urltest',urltest)
        if(urltest){
          this.GetURLPreview(paste)
        }
        
      })
      },
      CancelFullScreenCarouselIMGDialog(){
        this.FullScreenCarouselIMGDialog = false
        document.removeEventListener('keydown')
      },
      AlertMe(){
        this.FullScreenCarouselIMGDialog = true
        let vm = this
        document.addEventListener('keydown', function(event) { 
          if (event.code === 'ArrowLeft' && vm.FullScreenCarouselIMGDialog && vm.ActivePostImages[vm.CarouselIMG-1]) {
            vm.CarouselIMG = vm.CarouselIMG-1
            
          }
          else if(event.code === 'ArrowRight' && vm.FullScreenCarouselIMGDialog && vm.ActivePostImages[vm.CarouselIMG+1]){
            vm.CarouselIMG = vm.CarouselIMG+1
            
          }
        });
      },
      GetImageColumns(array){
        let length = array.length
        let int = 0
        if(length<5){
          int = 6
          
        }
        else if(length<7){
          int = 4
        }
        else if(length<13){
          int = 3
        }
        else{
          int = 2
        }
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
            return int*2
          }
          else{
            return int
          }
        
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        console.log(finaldate)
        return finaldate
      },
      
      GetSocialLikes(){
          db.collection('socialsitelikes').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialLikes.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        GetSocialReplies(){
          db.collection('socialsitereplies').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialReplies.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
      GetSocialComments(){
          db.collection('socialsitecomments').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.SocialComments.find(obj => obj.id === commentobj.id)
                if(!oncheck){
                  this.SocialComments.push(commentobj)
                }
                
              }
            })
          })
        },
        GetSitePosts(){
          let vm = this
          this.CollectionRef.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let postobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                this.GetCommentsLikesandReplies(postobj).then(result => {
                  vm.Posts.push(result)
                })
                
              }
            })
          })
        },
        GetCommentsLikesandReplies(postobj){
          let vm = this
          return new Promise(function(resolve, reject) {
            vm.GetAllPostComments(postobj).then(postcommentresult => {
              vm.GetItemSocialLikes(postobj,'relatedid',postobj.id).then(postlikesresult => {
                    if(postlikesresult){
                      if(postobj.Images){               
                        postobj.Images.map(img => {
                            vm.GetAllPostImagesComments(postobj,img).then(postimagescommentresult => {
                            if(postcommentresult && postimagescommentresult){
                              console.log(postobj)
                              resolve(postobj)
                            }
                          }) 
                        })
                        
                      }
                      else{
                        resolve(postobj)
                      }
                    }
                  }) 
              
              
            })
          })
        },
        GetItemSocialLikes(item,propname,propvalue){
          let vm = this
          item.Likes = []
          return new Promise(function(resolve, reject) {
            db.collection('socialsitelikes').where(propname,'==',propvalue).onSnapshot(res => {
              let listlength = res.docs.length
                if(listlength === 0){
                      resolve(item)
                }
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  item.Likes.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
                if(listlength === item.Likes.length){
                  resolve(item)
                  }
              })
            })
          })
        },
        GetItemSocialReplies(item,propname,propvalue){
          let vm = this
          item.Replies = []
           return new Promise(function(resolve, reject) {
            db.collection('socialsitereplies').where(propname,'==',propvalue).onSnapshot(res => {
              let listlength = res.docs.length
                if(listlength === 0){
                      resolve(item)
                }
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  let commentobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = item.Replies.find(obj => obj.id === commentobj.id)
                    if(!oncheck){
                      item.Replies.push(commentobj)
                      vm.GetItemSocialLikes(commentobj,'relatedid',commentobj.id).then(postlikesresult => {
                            if(postlikesresult && listlength === item.Replies.length){
                              resolve(item)
                            }
                         })
                      
                    }
                }
              })
            })
           })
        },
        GetAllPostComments(postobj){
          let vm = this
          postobj.Comments = []
          return new Promise(function(resolve, reject) {
            db.collection('socialsitecomments').where('relatedid','==',postobj.id).where('relatedtype','==','Site Post').onSnapshot(res => {
                let listlength = res.docs.length
                if(listlength === 0){
                  // vm.GetItemSocialLikes(postobj,'relatedid',postobj.id).then(postlikesresult => {
                  //   if(postlikesresult){
                      resolve(postobj)
                  //   }
                  // })                  
                }
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let commentobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = postobj.Comments.find(obj => obj.id === commentobj.id)
                    if(!oncheck){
                      postobj.Comments.push(commentobj)
                       if(listlength === postobj.Comments.length){
                          vm.GetItemSocialLikes(commentobj,'relatedid',commentobj.id).then(postlikesresult => {
                            if(postlikesresult){
                              vm.GetItemSocialReplies(commentobj,'relatedid',commentobj.id).then(commentreplyresult => {
                                if(commentreplyresult){
                                  resolve(postobj)
                                }
                                
                              })
                            }
                          }) 
                        }
                    }
                    
                  }
                })
              })
          })
        },
        GetAllPostImagesComments(postobj,img){
          let vm = this
          img.Comments = []
          
          return new Promise(function(resolve, reject) {
            db.collection('socialsitecomments').where('relatedid','==',postobj.id).where('relatedtype','==','Site Post Images').where('Child','==',img.Name).onSnapshot(res => {
                let listlength = res.docs.length
                console.log('img.Name',img.Name,listlength)
                if(listlength === 0){
                  resolve(postobj)
                }
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let commentobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = img.Comments.find(obj => obj.id === commentobj.id)
                    if(!oncheck){
                      img.Comments.push(commentobj)
                       if(listlength === img.Comments.length){
                          vm.GetItemSocialLikes(commentobj,'relatedid',commentobj.id).then(postlikesresult => {
                            if(postlikesresult){
                              vm.GetItemSocialReplies(commentobj,'relatedid',commentobj.id).then(commentreplyresult => {
                                if(commentreplyresult){
                                  resolve(postobj)
                                }
                                
                              })
                            }
                          }) 
                        }
                    }
                    
                  }
                })
              })
          })
        },
        CancelPostActive(){
          var x = document.activeElement.id      
          let postwrapper = document.getElementById('postwrapper')  
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let createpostbtn = document.getElementById('CreatePostButton')
          let stylebuttons = document.getElementById('PostInsertButtons')     
              cancelpostbtn.style.display = 'none'
              createpostbtn.style.display = 'none'
              stylebuttons.style.display = 'none'
              postwrapper.style.outlineStyle = 'none'
              postwrapper.style.outlineColor = 'black'
              postwrapper.style.outlineWidth = "0px"
      },
      OpenCarousel(item,index,frompreview){
        // this.ActivePost = item
        if(frompreview){
          this.ActivePostImages = this.selectedImagespreview
          this.EditingCarousel = true
          this.CarouselIMG = 0
        }
        else{
          this.ActivePost = item
          this.ActivePostImages = item.Images
          console.log(item)
          if(index === 0){
            this.CarouselIMG = index-1+2
          }
          else{
            this.CarouselIMG = index-1+2
          }
          
        }
        this.PostImagesCarousel = true
      },
      getImage(event) {
      this.selectedImages = event.target.files
      
      for(var prop in this.selectedImages) {
        if(this.selectedImages[prop] && this.selectedImages[prop].lastModified){
          let file = this.selectedImages[prop]
          //file.FileModifiedDate = new Date(file.lastModified)
          let localurl = URL.createObjectURL(file)          
          let item = {
            fileurl: localurl,
            Name: file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
          }
          let img = new Image();
          img.onload = function () {
            item.width = this.width
            item.height = this.height
          };
          img.src = localurl;
          this.selectedImagespreview.push(item)
        }        
      }
      // this.selectedImagespreview = this.selectedImages.map(imagefile => {
      //   return URL.createObjectURL(imagefile)
      // })
      console.log(this.selectedImages)
      console.log(this.selectedImagespreview)
    },
    AddPostImages(LocalImages){
      this.$emit('ActivateMultipleFilesUploadNotification',true)   
      let fileList = this.selectedImages
      this.PostImagsrunningpercenteage = 0
      this.PostImagesrunningProgress = 0 
      //this.UploadingPostImages = true
      setTimeout(() => {
      this.PostImagestotallist = fileList.length
      this.PostImagesuploadTotal = fileList.length*100
      const topelmnt = document.getElementById('uploadfilesprogressbar')
      topelmnt.style.display = "block";
      const elmnt = document.getElementById('uploadfilesprogressgroupbar') 
      let oldtotalprogresselementptag = document.getElementById("uploadfilestotalprogressbarptag")
      let oldtotalprogresselement = document.getElementById("uploadfilestotalprogressbar")
      if(oldtotalprogresselementptag){
        oldtotalprogresselementptag.remove();
      }   

      if(oldtotalprogresselement){
        oldtotalprogresselement.remove();
      }
  
      if(elmnt.childNodes[0]){
        elmnt.innerHTML = '';
      }     
      const totalprogresselementptag = document.createElement('p')
      totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
      totalprogresselementptag.setAttribute('id','uploadfilestotalprogressbarptag')
      const totalprogresselement = document.createElement('progress')
      totalprogresselement.setAttribute('id','uploadfilestotalprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
      var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.PostImagestotallist+' files uploaded');
      totalprogresselementptag.appendChild(startingtotaltext);
      elmnt.append(totalprogresselement) 
      elmnt.append(totalprogresselementptag) 
      // this.totalelement  = document.createElement('p')
      Array.from(Array(fileList.length).keys()).map((x,index) => {
        this.PreparePostThumbnails(fileList[x],index,fileList.length)
      })
      }, 300);
      },
      PreparePostThumbnails(file,index,listlength){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.PostImagesforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            let storeagelocation = 'SocialPosts/PostImages/'+ new Date()+'/'+ filename
            var storageRef = firebase.storage().ref(storeagelocation+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      vm.AddPostImagesSingle(file,index,listlength,thumburl,storeagelocation)
                    })
              })
      }
      },
      AddPostImagesSingle(file,fileindex,listlength,thumburl,storeagelocation) {
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        const totalprogresselement = document.getElementById('uploadfilestotalprogressbar')
        const totalprogresselementptag = document.getElementById('uploadfilestotalprogressbarptag')
        const elmnt = document.getElementById('uploadfilesprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)    
        let vm = this
        //const ImageArray = this.PostImagesImagesUploads
        
        var storageRef = firebase.storage().ref(storeagelocation);        
        var uploadTask = storageRef.put(file);
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.PostImagesuploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.PostImagesuploadPercentage)
                var textnode = document.createTextNode(vm.PostImagesuploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.PostImagesuploadPercentage === 100){
                  vm.PostImagesrunningProgress = vm.PostImagesrunningProgress+vm.PostImagesuploadPercentage
                  vm.PostImagsrunningpercenteage = Number(((vm.PostImagesrunningProgress/vm.PostImagesuploadTotal)*100).toFixed(2))                  
                  vm.PostImageslistuploadcount = vm.PostImageslistuploadcount-1+2
                  var totaltext = document.createTextNode(vm.PostImagsrunningpercenteage+'% complete  - '+vm.PostImageslistuploadcount+' of '+vm.PostImagestotallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.PostImagsrunningpercenteage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                      let newimage = {
                              Name: filename,
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Created_Byid: vm.UserRecord.id,          
                              Created_On: new Date(), 
                              //ModifiedDate: file.FileModifiedDate,
                              Modified_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Modified_Byid: vm.UserRecord.id,
                              Modified_On: new Date(),                              
                              StorageRef: storeagelocation
                              }
                              if(file.lastModifiedDate){
                                newimage.ModifiedDate = file.lastModifiedDate
                              }
                              else{
                                newimage.ModifiedDate = new Date(file.lastModified)
                              }
                      vm.NewPostImages.push(newimage)
                    //ImageArray.push(newimage)
                    // db.collection('capitalequipmentjobs').doc(this.$route.params.id).collection('outboundphotosimagesuploads').add(newimage).then(imagedoc => {
                    //   console.log('created new doc '+imagedoc)
                    //   })
                      if(vm.PostImagsrunningpercenteage === 100){
                      //this.UploadingPostImages = false
                      vm.$emit('ActivateMultipleFilesUploadNotification',false)
                      if(vm.NewPostImages.length === listlength){
                        vm.CreatePost()
                      }
                      
                     
                      
                      vm.PostImagestotallist = 0
                      vm.PostImagesuploadTotal = 0
                      
                      vm.PostImagesrunningProgress = 0
                      vm.PostImagesuploadPercentage = 0
                      vm.PostImageslistuploadcount = 0
                      // this.selectedImages = []
                      //elmnt.style.display = "none";
                      }
                  })
      },
    ProcessImage(){
      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;
          const img = document.createElement("img");          
          img.src = dataURI;
          img.width = 400
          img.style.marginLeft = "auto"
          img.style.marginRight = "auto"    
          img.style.display = 'block'    
          let NEWeditorContent = document.getElementById(elementid)
          document.execCommand('insertHTML', false, '<br>');               
          NEWeditorContent.appendChild(img);
          document.execCommand('insertHTML', false, '<br>');
          
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },
    

      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
      
      
      CancelPost(){
        this.postelmnt.innerHTML = ''
        this.NewPostImages = []
        this.selectedImagespreview = []
        this.selectedImages = ''
        this.PostImagsrunningpercenteage = 0
        this.EditingCarousel = false
        this.CarouselIMG = 0
        this.CancelPostActive()
      },
      CreatePost(){
        // let postelmnt = document.getElementById('postinput')
        let postinput = this.postelmnt.innerHTML
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          creatorname: this.UserRecord.Full_Name,
          post: postinput,
          createdon: new Date(),
          Comments: [],
          WallID: this.WallID,
          WallType: this.WallType,
          ShareName: this.ShareName,
        }
        if(!NewPost.WallType){
          NewPost.WallType = 'Site Member'
          NewPost.ShareName = this.UserRecord.Full_Name
        }
        if(this.UserRecord.Profile_Photo){
          NewPost.creatorimg = this.UserRecord.Profile_Photo
        }
        if(this.selectedImages.length > 0 && this.PostImagsrunningpercenteage !== 100){
          this.AddPostImages(this.selectedImagespreview)
        }
        else{
          console.log('this.NewPostImages.length',this.NewPostImages.length)
          if(this.NewPostImages.length > 0){
            //alert('got image length')
            NewPost.Images = this.NewPostImages.map(file => {
              let localobj = this.selectedImagespreview.find(obj => obj.Name == file.Name)
              //alert(file.Name)
              if(localobj.Description){
                file.Description = localobj.Description
              }
              file.height = localobj.height
              file.width = localobj.width             
              
              if(file.width>file.height){
                let ratio = file.height/file.width
                file.DisplayRatio = this.GetImageRatio(ratio)
              }
              else if(file.width === file.height){
                 let ratio = file.height/file.width
                  file.DisplayRatio = this.GetImageRatio(ratio)
              }
              else{
                 let ratio = file.width/file.height
                file.DisplayRatio = this.GetImageRatio(ratio,true)
              }
              return file
            })
            console.log('NewPost.Images',NewPost.Images)
            this.ProcessPost(NewPost)
          }
          else{
            this.ProcessPost(NewPost)
          }
          
        console.log(NewPost.post)
        this.CancelPost()
        }
        
      },
      GetImageRatio(ratio,invert){
        let ComputerDisplay = {Name: 'Computer Display',Ratio: 0.8}
        let SDTV = {Name: 'Computer Display',Ratio: 0.75}
        let DSLR = {Name: 'Computer Display',Ratio: 0.67}
        let WideScreen = {Name: 'Computer Display',Ratio: 0.625}
        let HDTV = {Name: 'Computer Display',Ratio: 0.5625}
        let CinemaFilm = {Name: 'Computer Display',Ratio: 0.5405}
        let Panorama = {Name: 'Panorama',Ratio: 0.5}
        if(ratio<=Panorama.Ratio){
          if(invert){
            Panorama.Inverted = true
          }
            return Panorama
        }
        else if(ratio<=CinemaFilm.Ratio){
          if(invert){
            CinemaFilm.Inverted = true
          }
            return CinemaFilm
        }
        else if(ratio<=HDTV.Ratio){
          if(invert){
            HDTV.Inverted = true
          }
            return HDTV
        }
        else if(ratio<=WideScreen.Ratio){
          if(invert){
            WideScreen.Inverted = true
          }
            return WideScreen
        }
        else if(ratio<=DSLR.Ratio){
          if(invert){
            DSLR.Inverted = true
          }
            return DSLR
        }
        else if(ratio<=SDTV.Ratio){
          if(invert){
            SDTV.Inverted = true
          }
            return SDTV
        }
        else{
          if(invert){
            ComputerDisplay.Inverted = true
          }
            return ComputerDisplay
        }
      },
      ProcessPost(NewPost){
        //alert('adding post')
        db.collection('socialsiteposts').add(NewPost).then(doc => {
            // console.log('this.selectedImages',this.selectedImages)
            // this.ProcessPost(NewPost)
          if(NewPost.Creatorid !== NewPost.WallID){
          const NewNotification = {
                    postid: doc.id,
                    Type: 'Wall Post',
                    Header: this.UserRecord.Full_Name+' posted on your wall',
                    Content: NewPost.post,
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: this.UserObj.Full_Name,
                    Ownerid: NewPost.WallID,
                    Read: false,
                    Path: '/MyProfile',
                    Message: 'Click to view Post',
                    Date: new Date()
                    }
                    db.collection('notifications').add(NewNotification).then(notedoc => {

                    })
        }
        })
      }
    },
}

</script>

<style>
.postinputbox{
  outline: rgb(111, 204, 247);
  outline-width: 1px;
  outline-style: solid;
  background-color: white;
  min-height: 80px;
}
.postoutline {
  outline: 1px solid #dedee9;
  background-color: grey;
}
.postoutline:focus {
  outline: 1px solid #dedee9;
  background-color: white;
}
.Chipinput{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.commentblock {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #f0f2f5
}
.commentpost{
  background-color: #87B6E6  
}
.greyBG{
  background-color: #f0f2f5;
}
</style>

