
  <template>


  <div class="dataimports">
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new ImportRecord.</span>
      <v-btn color="white" flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <div class="hidden-sm-and-down">
        <h1 class="Header">Data Imports</h1>
    </div>

    <v-snackbar v-model="csvfilefilesnackbar" :timeout="4000" top color="success">
      <span>Awesome! Data saved successfully.</span>
      <v-btn color="white" flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>



<v-data-table
    :headers="headers"
    :items="DataImportsFilteredandSearched"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My Data Imports</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field light
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Data Import</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>







                      <v-combobox
                        v-model="editedItem.dataset"

                        :items="DataSetOptions"
                        hide-selected
                        label="DataSet Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>








                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

<v-toolbar v-if="DataImportsArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>
    </template>
<template v-slot:[`item.createdby`]="{ item }">
      <v-avatar size="35">
        <v-img :src="item.createdby.Profile_Photo" v-if="item.createdby && item.createdby.Profile_Photo"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.createdby || !item.createdby.Profile_Photo"/>
        </v-avatar>
        <span v-if="item.createdby">{{' '+item.createdby.Full_Name}}</span>
    </template>

<template v-slot:[`item.csvfile`]="{ item }">
      <v-btn dark color="green" :href="item.csvfile">Download CSV</v-btn>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-icon
        small
        @click="ViewItem(item)"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>


  </div>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'


export default {
  data() {
    return {
       UsersArray: [],
        csvfilefilesnackbar: false,
        selectedcsvfileFile: '',
        csvfilelocalURL: '',

      DataSetOptions: ['Contacts','Accounts','Designs','Opportunities','Quotes'],
      DataSetfilter: '',

      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      headers: [
        { text: 'Name', value: 'name'},


        { text: 'DataSet', value: 'dataset'},


        { text: 'CSV File', value: 'csvfile'},


        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedDataImportsnackbar: false,
      DataImportEditdialog: false,
      newDataImportdialog: false,
      DataImportsArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
          name: '',
          dataset: '',
          csvfile: '',
      },
      editedItem: {
        id: '',
          name: '',
          dataset: '',
          csvfile: '',
      },
    }
  },

  created(){
     this.GetUsers()
    this.GetDataImports()

  },
  computed: {

    formTitle () {
        return this.editedIndex === -1 ? 'New Data Import' : 'Edit Data Import'
      },
    DataImportsSearched() {
      return this.DataImportsArray.filter(DataImport => {
        if(DataImport.name){return DataImport.name.toLowerCase().includes(this.search.toLowerCase())}
      })
      },
      DataImportsFilteredandSearched() {
      return this.DataImportsSearched.filter(DataImport => {return DataImport})
      },

    },
  methods: {
    GetUsers(){
    db.collection('users').onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.UsersArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
  },
    ResetFilters() {},
    ResetSearch() {
      this.search = ''
    },

    GetDataImports() {
      db.collection('dataimports').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.DataImportsArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },

  oncsvfileUpload() {
        var storageRef = firebase.storage().ref('DataImport/' + 'csvfile/'+this.selectedcsvfileFile.name);
        var uploadTask = storageRef.put(this.selectedcsvfileFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('dataimports').doc(this.mainrecordid).update({
                    csvfile: url
            })
            this.selectedcsvfileFile = ''
                }),
                  this.csvfilefilesnackbar = true



      },
      oncsvfileFileselected(event) {
        this.selectedcsvfileFile = event.target.files[0]
        this.csvfilelocalURL = URL.createObjectURL(this.selectedcsvfileFile)
      },





      ViewItem (item) {
        this.$router.push('/DataImport/'+item.id)
      },

      deleteItem (item) {
        const index = this.DataImportsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('dataimports').doc(item.id).delete() && this.DataImportsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.DataImportsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },


      save () {
      const EditedDataImport = {
    name: this.editedItem.name,
    dataset: this.editedItem.dataset,

        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('dataimports').doc(this.mainrecordid).update({
        name: EditedDataImport.name,

        dataset: EditedDataImport.dataset,



              });
              Object.assign(this.DataImportsArray[this.editedIndex], this.editedItem)
              this.closeDataImporteditDialog
              this.EditedDataImportsnackbar = true
        }
         else {
          const NewDataImport = {
      name: EditedDataImport.name,

      dataset: EditedDataImport.dataset,



          }
          db.collection('dataimports').add(NewDataImport).then({

          })
        }

        this.close()
      },

  }
}

</script>

  