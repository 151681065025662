
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
    themes: {
      
      light: {
        primary: '#468FBE',
        secondary: '#FFCDD2',
        accent: '#89C8F0',
        background: '#EBF0F0',
        links: '#FFA200',
        fieldcard: '#707A81',
        pop: '#FF0000',
        morning: '#7cb9fa',
        night: '#091319',
        intramenulight: '#607D8B',
        intramenudark: '#607D8B',
        pagecardlight: '#607D8B',
        pagecarddark: '#607D8B',
        
      },
      dark: {
        primary: '#468FBE',
        secondary: '#FFCDD2',
        accent: '#89C8F0',
        background: '#EBF0F0',
        links: '#FFA200',
        fieldcard: '#707A81',
        pop: '#FF0000',
        morning: '#7cb9fa',
        night: '#091319',
        intramenulight: '#607D8B',
        intramenudark: '#607D8B',
        pagecardlight: '#607D8B',
        pagecarddark: '#607D8B',
        
      },
    },
  },
});

    