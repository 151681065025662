          <template>
    <v-card  tile class="dealerships" flat height="100%" width="100%"  v-if="typeof dashboardelementindex === 'undefined'"> 

  <v-dialog v-model="importresultsdialog" max-width="800px">
        <v-card dark height="100%" width ="100%" class="center secondary">
          <v-img src="@/assets/RABaseBG.jpeg">
          <v-card-title>
            Import Result<v-spacer></v-spacer><v-icon @click="CloseImportresultsdialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Successful Imports ({{SuccessfulImportsArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in SuccessfulImportsArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Partial Failures ({{PartialFailuresArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in PartialFailuresArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer><v-btn href="/DataImports" target="_blank" color="green" dark>View Import Record</v-btn>
          
          </v-card-actions>
          </v-img>
        </v-card>
      </v-dialog>

      <v-dialog v-model="ImportingProgressDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Importing Records</h2><br>
                Now Importing - 
                          <div class="red--text">
                          <p>{{Importcount}}</p>
                          </div>
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
                  <v-layout row class="justify-center mx-3 my-3">
                      <progress id="importprogressbar">
                      </progress>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
    
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome!Dealership.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
     
           <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>
    
         <v-parallax class="recordparallax2"
        height="150"
            src="@/assets/RASolidA.jpeg"
            v-if="!$route.params.id  && AppisDarkMode  && !PageView"
        >
        <v-row
              align="center"
              justify="center"
            >
        <v-col class="stdparallaxgradient text-center" cols="12">
        <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Dealerships ({{DealershipsFilteredandSearched.length}})</span>
        </v-col>
      </v-row>
        </v-parallax>
        <v-parallax class="recordparallax2"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
            v-if="!$route.params.id  && !AppisDarkMode || PageView"
        >
        <v-row
              align="center"
              justify="center"
            >
        <v-col class="stdparallaxgradient text-center" cols="12">
        <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Dealerships ({{DealershipsFilteredandSearched.length}})
</span>
        </v-col>
      </v-row>
        </v-parallax>
    
    <v-layout class="justify-center" style="padding-top: 70px"  id="dealershipsskeleton">
      <v-flex lg10 md10> 
    <v-sheet
        color="secondary"
        class="mx-6 my-3"
        style="margin-top:150px;"
      >
      <div class="hidden-sm-and-down">
            <h1 class="Header white--text">Dealerships</h1>
        </div>
        <v-skeleton-loader
          class="mx-auto"
        
          type="table-thead"
        ></v-skeleton-loader>
        <v-skeleton-loader v-for="n in 5" :key="n.itemObjKey"
          class="mx-auto"
          
          type="table-row"
        ></v-skeleton-loader>
      </v-sheet>
  </v-flex>
 </v-layout>

        
    <v-layout class="justify-center"  :style="CollectionPadding">
      <v-flex    lg10 md10>
     <div id="dealershipsdata" style="display:none">
    
    <v-snackbar v-model="Logofilesnackbar" :timeout="4000" top color="success">
      <span>Awesome! Data saved successfully.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    
      
          
              <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-img
            width="500"
            src="@/assets/RABaseBG-Soft.png"
            ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
              <v-card-title class="mediumoverline white--text">
                {{ formTitle }}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-car-multiple</v-icon>
              </v-card-title>
              <v-card-subtitle class="overline white--text">
              <v-icon  class="actionicon" small :color="formSubTitleIconColor">{{formSubTitleIcon}}</v-icon> {{formSubTitle}}
            </v-card-subtitle></div>
                <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field v-model="editedItem.Dealer_Name" color="red" :rules="[rules.required]" id="Dealer_Name" label="* Dealer_Name"></v-text-field>
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                          <v-combobox
                            class="FormFields"
                            v-model="editedItem.Status"
                            :items="StatusOptions"
                            hide-selected
                            item-text="Name"
                            label="Status Options"
                            small-chips
                            solo 
                      
                            flat
                          ></v-combobox>
                          <v-combobox
                            v-if="editedItem.Status && editedItem.Status.Options"
                            class="FormFields"
                            v-model="editedItem[editedItem.Status.Options[0].LevelFieldName]"
                            :items="editedItem.Status.Options"
                            hide-selected
                            item-text="Name"
                            :label="editedItem.Status.Options[0].LevelFieldName"
                            small-chips
                            solo 
                      
                            flat></v-combobox>
                        
                      </v-col>
                    </v-row>       
                </v-card-text>
                <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="close()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="save()">Save</v-btn>
              </v-card-actions>
                 </v-img>
              </v-card>
            </v-dialog>
  
        <v-dialog v-model="importdialog" max-width="500px">
               <v-card>
                 <v-img
                    width="500"
                    src="@/assets/RABaseBG-Soft.png"
                    ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                      <v-card-title class="mediumoverline white--text">
                        Import Dealerships<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-car-multiple</v-icon>
                      </v-card-title>
                      <v-card-subtitle class="overline white--text">
                      <v-icon  class="actionicon" small color="green">mdi-database-import</v-icon> Import Dealerships here
                    </v-card-subtitle>
                    <v-card-subtitle class="overline white--text">
                     <v-icon  class="actionicon" @click="DownloadDealershipsImportTemplate()" small color="white">mdi-download</v-icon> Download Dealerships Template
                    </v-card-subtitle></div>
                 <v-card-text>
                     <v-row>
                       <v-col cols="12" sm="12" md="12" lg="12">
                         <input v-if="!ImportFile"
                                   type="file"
                                   @change="onImportfileSelected($event)"
                                   ref="ImportFileinputter"
                                   id="fileUpload"
                                   >
                         <v-text-field class="FormFields" v-model="Import.Name" label="Import File Name"></v-text-field>
                         
                         
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions class="white documenttypeshadowinvert">                  
                  <v-btn color="orange" dark class="overline" @click="closeImport()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn color="green" dark  class="overline" @click="saveImport()">Save</v-btn>
                </v-card-actions>
                 </v-img>
               </v-card>
             </v-dialog>

    
          <v-dialog v-model="exportdialog" max-width="500">
      <v-card>
        <v-img
          width="500"
          src="@/assets/RABaseBG-Soft.png"
          ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
            <v-card-title class="mediumoverline white--text">
              Export Dealerships<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-car-multiple</v-icon>
            </v-card-title>
            <v-card-subtitle class="overline white--text">
            <v-icon  class="actionicon" small color="green">mdi-database-export</v-icon> Export Dealerships here
          </v-card-subtitle>
          </div>
        <v-card-text class="FormFields">
          This will export all data in your curent view, in .CSV Format. Choose below whether to Export just for viewing, or to Export for importing of Re-Importing the sheet
          to perform Bulk updates to the Dealerships Records:
          <v-list class="transparent">
            <v-list-item>
              <v-btn @click="ExportData()" class="overline blue--text" text>Export for View</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="ExportDataforReimport()" class="overline blue--text" text>Export for Reimport</v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions  class="white documenttypeshadowinvert">
          <v-btn color="orange" dark class="overline" @click="closeExport()">Cancel</v-btn>
          </v-card-actions>           
        </v-img>
      </v-card>
    </v-dialog>



        
  <v-toolbar v-if="DealershipsArray" elevation="6" dark dense rounded src="@/assets/RABaseBG.jpeg" style="margin-bottom:30px;">
          <v-layout col class="justify-start">
            <h3 class="my-8">Data Filters</h3><v-spacer></v-spacer>
          <div width="100px"><br>
            <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Statusfilter"
            @change="Status_Reasonfilter = ''"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            </div>
          <div width="100px" v-if="Statusfilter"><br>
            <v-autocomplete class="mx-5"
            :items="Statusfilter.Options"
            v-model="Status_Reasonfilter"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </div>
            <v-btn outlined fab small class="elevation-6"  style="margin-top:25px;" >
              <v-icon @click="ResetFilters()">mdi-close</v-icon>
            </v-btn>
            </v-layout>
          </v-toolbar>
              
    <v-layout row class="justify-center">
      <v-card class="transparent" flat width="90%">
        <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12" style="margin-bottom:-40px;" color="primary white--text">
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white">mdi-car-multiple</v-icon>Dealerships</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon @click="VCardMode = false,VTableMode = true" class="actionicon mx-1" color="white">mdi-view-sequential</v-icon>
          <v-icon @click="VCardMode = true,VTableMode = false" class="actionicon mx-1" color="white">mdi-view-grid</v-icon>
         
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" v-if="CanCreate || userIsAdmin || HasCharts || HasProcesses" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
              <v-list-item class="listoutline"  @click="dialog = !dialog"  v-if="CanCreate || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    New
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="importdialog = !importdialog" v-if="UserRecord.CanImport || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    Import
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="grey">mdi-database-import</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="ActivateExportDialog(DealershipsArray,headers)"  v-if="UserRecord && UserRecord.CanExport || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    Export
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="grey">mdi-database-export</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-card>
    </v-layout>
        <v-data-table
      v-if="VTableMode"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :dark="AppisDarkMode"
      :headers="ComputedHeaders"
      :items="DealershipsFilteredandSearched"
      class="elevation-1"
      id="dealershipsdatatable"
    >
      <template v-slot:top>
        <div style="padding-top:40px;"/>
      </template>
    <template v-slot:[`item.Dealer_Name`]="{ item }">
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text"><v-icon small v-if="item && CanGet">mdi-car-multiple</v-icon></v-btn> {{item.Dealer_Name}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
      <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item && CanEdit">
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item && CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
      <template v-slot:footer>
      <v-card outlined>
        <v-pagination
        :total-visible="10"
          v-model="page"
          circle
          :length="pageCount"
        ></v-pagination>
        <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
        </v-card>
      </template>

    </v-data-table>
  <v-layout row wrap :class="VCardDarkModeClass" v-if="VCardMode">
      <!-- <v-card flat class="transparent" width="100%" height="80px"/> -->
      <div style="padding-top:40px;"/>
      <v-row class="mx-3" style="padding-top:60px;">
        <v-col
          v-for="card in DealershipsPaginated" :key="card.id"
          class="d-flex child-flex"
          >
          <v-card 
          width="350"
              class="mx-1 my-1"
              :dark="AppisDarkMode"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-card-title class="overline">
                    {{card.Dealer_Name}}
                  </v-card-title>
                  <v-card-subtitle v-if="card.Description">
                    {{card.Description.substr(0,150)}}
                  </v-card-subtitle>
                  <v-card-subtitle v-if="!card.Description">
                   <i class="caption"> There is no information in the Description Field of this record as yet</i>
                  </v-card-subtitle>
                </v-list-item-content>
                <v-list-item-avatar
                v-if="card.LogoThumbURL"
                  tile
                  size="80"
                  color="grey"
                >
                <img 
                :src="card.LogoThumbURL">                
                </v-list-item-avatar>
                <v-list-item-avatar
                v-if="!card.LogoThumbURL && AppisDarkMode"
                  tile
                  size="80"
                  color="grey"
                >               
                <img 
                src="@/assets/ImageHolder_dark.png">
                </v-list-item-avatar>
                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                  v-if="!card.LogoThumbURL && !AppisDarkMode"
                >
                <img 
                src="@/assets/ImageHolder.png">
                </v-list-item-avatar>
            </v-list-item>
            <v-card-actions :class="VCardActionDarkMode">
              <v-btn
                  color="accent"
                  text :to="'/Dealership/'+card.id"
                >
                  View
                </v-btn>
              <v-btn
                  color="accent"
                  text @click="editItem(card)"
                >
                  Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-card outlined :dark="AppisDarkMode" width="100%">
        <v-pagination
        :total-visible="10"
          v-model="CardPage"
          circle
          :length="Math.round(DealershipsFilteredandSearched.length/cardsPerPage)"
        ></v-pagination>
        
        <v-text-field
          :value="cardsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="cardsPerPage = parseInt($event, 10)"
        ></v-text-field>
        </v-card>

  </v-layout>

    
    
    </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'


export default {
  props: ['UsersArray','dashboardelementindex','IntranetView','RelatedObj','PageFilter','PageView','RelatedParentObj','RelatedGroupFilter','DashboardView','DashboardArray','CompSize','AllowsHistoric','ReferenceView','DocTableCall','RenderComp','AppisDarkMode'],
  components: {
  },
  data() {
    return {
  UserRecord: '',
  cardsPerPage: 6,
  CardPage: 1,
  VCardMode: false,
  VTableMode: true,
  page: 1,
  pageCount: 0,
  itemsPerPage: 10,
  ParentFilterField: '',
  ExportDataArray: [],
  ExportHeaders: [],
  exportdialog: false,
  
    Logofilesnackbar: false,
    selectedLogoFile: '',
    LogolocalURL: '',
  
  StatusOptions: [{
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Level": {
        "name": "Status Reason",
        "children": [],
        "id": 2,
        "FieldName": "Status_Reason"
      },
      "ID": 1000003,
      "Name": "Open"
    }
  ],
  "Level": {
    "children": [
      {
        "id": 2,
        "name": "Status Reason",
        "FieldName": "Status_Reason",
        "children": []
      }
    ],
    "FieldName": "Status",
    "name": "Status",
    "id": 1
  },
  "ID": 1000001,
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "Name": "Active"
},{
  "Options": [
    {
      "Level": {
        "children": [],
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "id": 2
      },
      "ID": 1000004,
      "Name": "Closed",
      "LevelFieldName": "Status_Reason"
    }
  ],
  "HasDeepHierarchy": true,
  "Level": {
    "children": [
      {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    ],
    "FieldName": "Status",
    "name": "Status",
    "id": 1
  },
  "Name": "InActive",
  "ID": 1000002,
  "LevelFieldName": "Status"
}],
  Statusfilter: {
  "LevelFieldName": "Status",
  "Level": {
    "children": [
      {
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "children": [],
        "id": 2
      }
    ],
    "id": 1,
    "FieldName": "Status",
    "name": "Status"
  },
  "ID": 1000001,
  "Options": [
    {
      "Level": {
        "children": [],
        "FieldName": "Status_Reason",
        "id": 2,
        "name": "Status Reason"
      },
      "ID": 1000003,
      "LevelFieldName": "Status_Reason",
      "Name": "Open"
    }
  ],
  "HasDeepHierarchy": true,
  "Name": "Active"
},
  Status_Reasonfilter: '',
  Created_ByArray: [],
  Created_Byfilter: {Full_Name: ''},
  dialog: false,
  initialize: '',
  search: '',
  BusinessUnitsArray: [],
  headers: [
    { text: 'Dealer Name', value: 'Dealer_Name',class: "overline"},
    
    { text: 'Status', value: 'Status.Name',class: "overline"},
    { text: 'Status_Reason', value: 'Status_Reason.Name',class: "overline"},
    { text: 'Actions', value: 'actions', sortable: false ,class: "overline"},
  ],
  snackbar: false,
  Reimport: false,
  importdialog: false,
  importsnackbar: false,
  ImportHeaders: [],
  ImportingProgressDialog: false,
  Importcount: '',
  importresultsdialog: false,
  NewImportsArray: [],
  PartialFailuresArray: [],
  SuccessfulImportsArray: [],
  CSVResult: [],
  Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Dealerships', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
  ImportFile: '',
  EditedDealershipsnackbar: false,
  DealershipEditdialog: false,
  newDealershipdialog: false,
  DealershipsArray: [],
  rules: {
      telnr: value => {
        const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return pattern.test(value) || "Not a telephone number.";
      },
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
  editedIndex: -1,
  defaultItem: {
    id: '',
      Dealer_Name: '',
      Description: '',
      Status: {
  "LevelFieldName": "Status",
  "Level": {
    "children": [
      {
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "children": [],
        "id": 2
      }
    ],
    "id": 1,
    "FieldName": "Status",
    "name": "Status"
  },
  "ID": 1000001,
  "Options": [
    {
      "Level": {
        "children": [],
        "FieldName": "Status_Reason",
        "id": 2,
        "name": "Status Reason"
      },
      "ID": 1000003,
      "LevelFieldName": "Status_Reason",
      "Name": "Open"
    }
  ],
  "HasDeepHierarchy": true,
  "Name": "Active"
},
      Status_Reason: {
  "Level": {
    "children": [],
    "name": "Status Reason",
    "FieldName": "Status_Reason",
    "id": 2
  },
  "ID": 1000003,
  "LevelFieldName": "Status_Reason",
  "Name": "Open"
},
      Created_On: '',
      Created_By: '',
      Logo: '',
      Office_Number: '',
  Office_Number:  '',
      Website: '',
  },
  editedItem: {
    id: '',
      Dealer_Name: '',
      Description: '',
      Status: {
  "LevelFieldName": "Status",
  "Level": {
    "children": [
      {
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "children": [],
        "id": 2
      }
    ],
    "id": 1,
    "FieldName": "Status",
    "name": "Status"
  },
  "ID": 1000001,
  "Options": [
    {
      "Level": {
        "children": [],
        "FieldName": "Status_Reason",
        "id": 2,
        "name": "Status Reason"
      },
      "ID": 1000003,
      "LevelFieldName": "Status_Reason",
      "Name": "Open"
    }
  ],
  "HasDeepHierarchy": true,
  "Name": "Active"
},
      Status_Reason: {
  "Level": {
    "children": [],
    "name": "Status Reason",
    "FieldName": "Status_Reason",
    "id": 2
  },
  "ID": 1000003,
  "LevelFieldName": "Status_Reason",
  "Name": "Open"
},
      Created_On: '',
      Created_By: '',
      Logo: '',
      Office_Number: '',
      Office_Number:  '',
      Website: '',
  },
  UndefinedMandatoryFields: [],
  CollectionRef: db.collection('dealerships'),
  CustomCreateRoles: [
  "Office Administrator"
],
  CustomGetRoles: [
  "Office Administrator"
],
  CustomListRoles: [
  "Office Administrator"
],
  CustomUpdateRoles: [
  "Office Administrator"
],
    
    }
  },

  mounted() {
    if(this.CompSize){
    this.$vuetify.breakpoint.width = this.CompSize
    }
  },
  
  created(){
    if(this.PageView){
      this.itemsPerPage = 5
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.CompSize){
      this.$vuetify.breakpoint.width = this.CompSize
      console.log(this.$vuetify.breakpoint)
    }
    
  if(typeof this.dashboardelementindex === 'undefined'){
setTimeout(() => {
  document.getElementById('dealershipsskeleton').style.display = 'none'
  document.getElementById('dealershipsdata').style.display = 'block'      
  },1500)
  }


window.onload = setTimeout(() => {this.CheckRouting()},1500)



  },
  computed: {
        ComputedHeaders(){
    return this.headers.filter(header => {
      return !header.Identifier
    })
  },
    DealershipsQuery(){
    return this.$store.state.DealershipsQuery 
    },
  CanGet(){
    return this.$store.state.DealershipsCanView
  },
  CanList(){
    return this.$store.state.DealershipsCanList
  },
  CanEdit(){
    return this.$store.state.DealershipsCanEdit
  },
  CanDelete(){
    return this.$store.state.DealershipsCanDelete
  },
  CanCreate(){
    return this.$store.state.DealershipsCanCreate
  },
    DealershipsPaginated(){
      let displayrange = this.cardsPerPage*this.CardPage-this.cardsPerPage
      return this.DealershipsFilteredandSearched.slice(displayrange, displayrange+this.cardsPerPage)
    },
    VCardActionDarkMode(){
    if(this.AppisDarkMode){
    return 'black documenttypeshadowinvertcolor'
  }
  else{
    return 'white documenttypeshadowinvert'
  }
  },
    VCardDarkModeClass(){
    if(this.AppisDarkMode){
      return 'justify-center black elevation-1 mx-2'
    }
    else{
      return 'justify-center white elevation-1 mx-2'
    }
  },
    
    CollectionPadding(){
    if(!this.$route.params.id && !this.PageView){
      return 'padding-top: 70px'
    }
    else if(this.PageView){
    return 'padding: 20px'
    }
    else{
      return ''
    }
  },
    DealershipsStore() {
      return this.$store.getters.getDealershipsArray
    },
    
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  UsersStore(){
    return this.UsersArray
  },
  UsersStore(){
      return this.$store.state.UsersArray
    },
  FinalDealershipsCreated_ByArray(){
    return this.DealershipsArray.map(Dealership => {
      let lookupobject = this.Created_ByArray.find(Created_By => Created_By.id === Dealership.Created_Byid)
      if(lookupobject){
        Dealership.Created_By = lookupobject
      }        
      return Dealership
    })
    },
    FinalDashboardArray(){
    return this.DealershipsArray
        .map(Dealership => {
        let lookupobject = this.Created_ByArray.find(Created_By => Created_By.id === Dealership.Created_Byid)
        if(lookupobject){
          Dealership.Created_By = lookupobject
        }          
        return Dealership
      })
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Dealership' : 'Edit Dealership'
    },
    formSubTitle(){
      return this.editedIndex === -1 ? 'Fill the form below and add a new Dealership' : 'Edit the Dealership '+this.editedItem.Dealer_Name
    },
    formSubTitleIcon(){
      return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'
    },
    formSubTitleIconColor(){
      return this.editedIndex === -1 ? 'green' : 'orange'
    },
  DealershipsSearched() {
    return this.DealershipsArray.filter(Dealership => {
      if(this.search){return Dealership.Dealer_Name && Dealership.Dealer_Name.toLowerCase().includes(this.search.toLowerCase())}else{return Dealership}
    }).filter(Dealership => {
      if(this.$route.params.id && this.RelatedObj || this.$route.params.id && this.RelatedParentObj){
        if(this.ParentFilterField && Dealership[this.ParentFilterField]){
          return Dealership[this.ParentFilterField].DocumentID === this.$route.params.id
        }          
      }
      else{
        return Dealership
      }
    })
    },
    DealershipsFilteredandSearched() {
    return this.DealershipsSearched
        .filter(Dealership => {
        if(this.Statusfilter){
        return Dealership.Status && Dealership.Status.ID === this.Statusfilter.ID
        }
        else {
        return Dealership
        }
        })
        .filter(Dealership => {
          if(this.Status_Reasonfilter){
          return Dealership.Status_Reason && Dealership.Status_Reason.ID === this.Status_Reasonfilter.ID
          }
          else {
          return Dealership
          }
          }).map(dealership => {
        if(this.PageView && this.PageFilter){
          if(this.PageFilter.FieldType === 'Option Set'){
            if(dealership[this.PageFilter.FieldName]){
              this.PageFilter.Filter.map(filter => {
                if(filter.ID === dealership[this.PageFilter.FieldName].ID){
                  dealership.Render = true
                }
              })
            }
          }
        }
        else{
          dealership.Render = true
        }
        return dealership
      })
      .filter(dealership => {
        return dealership.Render === true
      })
    },
    
    HeaderNames() {
      return this.headers.map(header => {
        return header.value.split('.')[0]
      }).filter(header => {
        return header !== 'id'
      })
    },
    HeaderNamesSplit() {
      return this.HeaderNames.map(header => {
       let correctheader = header
        return correctheader
      })
    },
  
    },
  watch: {
      userLoggedIn (value) {
        //if (value !== null && value !== undefined) {
         // this.$router.push('/')
        //}
      },
      FinalDashboardArray (value) {
        if(value && value.length > 0){
          let vm = this
          if(typeof this.dashboardelementindex !== 'undefined'){
               
                let lookuparrays = [
                  {
                    Field: 'Created_By',
                    Array: this.Created_ByArray
                  },
                ]
                vm.$emit('updatearray',this.dashboardelementindex,this.FinalDashboardArray,lookuparrays)
              }
        }
      },
    },
  methods: {
    
    ActivateExportDialog(array,headers){
      this.ExportDataArray = array.map(item => {
        delete item.Children
        delete item.headers
        return item
      })
      this.ExportHeaders = Array.from(headers)
      this.exportdialog = true
    },
    closeExport(){
      this.exportdialog = false
      this.ExportData = []
      this.ExportHeaders = []
    },
    ExportData(){
     this.exportToCsv('download.csv',this.ExportDataArray)
    },
    ExportDataforReimport(){
    this.exportToCsv('download.csv',this.ExportDataArray,true)
  },
    exportToCsv(filename, exportdata,forreimport) {
  const headers = this.ExportHeaders.map(header => {
    return header.value.split('.')[0]
  })
  const headerrows = [headers]
  if(forreimport === true){
    headers.push('id')
    let idobj = { text: 'id', value: 'id'}
    this.ExportHeaders.push(idobj)
    headers.push('id')
    this.ExportHeaders.push(idobj)
  }
  console.log(this.ExportDataArray)
  console.log(this.ExportHeaders)
  const datarows = exportdata.map(row => {
    row.csvdata =  this.ExportHeaders.map(header => {
      
      let headervalue = ''
      let firstprop = header.value.split('.')[0]
      let secondprop = header.value.split('.')[1]
      
      if(secondprop){
        
        if(row[firstprop]){
          if(row[firstprop][secondprop]){   
            let thirdprop = row[firstprop][secondprop]
                         
          headervalue = row[firstprop][secondprop]
          }
        }
      }
      else{
        if(row[firstprop]){
          
        headervalue = row[firstprop]
        }
      }   
      if(header.text === 'Owner'){
        if(row.Owner){
          headervalue = row.Owner.Full_Name
        }
        
      }         
      return headervalue
    })
    return row
    }).map(row => {
      return row.csvdata
    })
   const rows = headerrows.concat(datarows)
  

  let csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
  },
      
  DateFormatter(date){
    if(date){
      let removeT = date.split('T').join(' ')
      let removeFWDslash = date.split('/').join('-')
    let yearstring = removeFWDslash.split('-')[0]
      let monthstring = removeFWDslash.split('-')[1]
      let daystring = removeFWDslash.split('-')[2]
      let yearnumber = Number(yearstring)
      let monthnumber = Number(monthstring)
      let daynumber = Number(daystring)
      return new Date(yearnumber, monthnumber-1, daynumber)
      }
    else{
      return null
    }
  },
  FromExcelDateFormatter(date){
  if(date){
  let yearstring = date.split('/')[0]
    let monthstring = date.split('/')[1]
    let daystring = date.split('/')[2]
    let yearnumber = Number(yearstring)
    let monthnumber = Number(monthstring)
    let daynumber = Number(daystring)
    return new Date(yearnumber, monthnumber-1, daynumber)
    }
  else{
    return null
  }
},
  CheckRouting(){
    
      if(typeof this.$route.params.id !== 'undefined' && typeof this.dashboardelementindex === 'undefined'){
        //this.defaultItem[this.RelatedObj.identifier] = this.RelatedObj
            //this.editedItem = Object.assign({}, this.defaultItem)
            let queryfield = this.RelatedObj.identifier+'.DocumentID'
            
            if(typeof this.RelatedParentObj !== 'undefined'){
              //this.defaultItem[this.RelatedParentObj.identifier] = this.RelatedParentObj
              let queryfield = this.RelatedParentObj.identifier+'.DocumentID'
              this.ParentFilterField = this.RelatedParentObj.identifier
              this.CollectionRef = this.DealershipsQuery.where(queryfield,'==',this.RelatedParentObj.id)
              this.GetRequestingUser(true)
            }
            
            else{
              let queryfield = this.RelatedObj.identifier+'.DocumentID'
              this.ParentFilterField = this.RelatedObj.identifier
              this.CollectionRef = this.DealershipsQuery.where(queryfield,'==',this.RelatedObj.id)
              this.GetRequestingUser(true)
            }
      }
      else if(this.DashboardView){
        this.DealershipsArray = this.DashboardArray
      }
      else{
        
        this.GetRequestingUser()
      }
  },
  
  

  GetBusinessUnits(){
    db.collection('businessunits').onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.BusinessUnitsArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
  },

  closeImport(){
    this.importdialog = false
    this.ImportFile = null
    this.Import.Name = ''
  },

  saveImport() {
    this.ImportingProgressDialog = true
    setTimeout(() => {
    const totalprogresselement = document.getElementById('importprogressbar')
    totalprogresselement.setAttribute('max','100')
    totalprogresselement.setAttribute('value',1)
  }, 200);
    this.Importcount = 'Preparing Import'
    let importLocalURL = URL.createObjectURL(this.ImportFile)
    var reader = new FileReader();
    let test = reader.readAsText(this.ImportFile);
    let vm = this
    reader.addEventListener('load', function(e) {
          var text = e.target.result;
          vm.CSVConvertToJSON(text)
    });
  },


  CheckDelimiter(lines){
    let headertest = lines[0].split(";");
    if(headertest.length ===1){
      this.ConversionCSVtoJJSON(lines,',')
    }
    else{
      this.ConversionCSVtoJJSON(lines, ';')
    }
    console.log('headertest')
    console.log(headertest)
  },
  ConversionCSVtoJJSON(lines,delimiter){
    let dataonly = Object.assign([],lines)
    dataonly.splice(0,1)
    console.log(dataonly)
    this.CSVResult = []
    this.ImportHeaders=lines[0].split(delimiter);
    console.log('this.ImportHeaders')
    console.log(this.ImportHeaders)
    let finalindex = this.ImportHeaders.length-1
  let finalheader = this.ImportHeaders[finalindex]
  if(finalheader.includes('id')){
    this.Reimport = true
    confirm('We picked up this is a Reimport. Shall you continue?') && this.ProceedReimport(lines,delimiter)
  }
  else{
    for(var i=1;i-1+2<lines.length;i++){

    var obj = {};
    var currentline=lines[i].split(delimiter);
    
    for(var j=0;j<this.ImportHeaders.length;j++){
      obj[this.ImportHeaders[j]] = currentline[j];
    }
    let resultcheck = this.CSVResult.find(res => res.Dealer_Name == obj.Dealer_Name)
    this.CSVResult.push(obj);
    console.log("this.CSVResult length is "+this.CSVResult.length)


  }
  this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
    this.CSVResult.map((res,i) => {
      
      this.UpdateFiresStore(res,i)
      
    })
  }
  },
  ProceedReimport(lines,delimiter){
  for(var i=1;i-1+2<lines.length;i++){
      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Dealer_Name == obj.Dealer_Name)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)
    }
    this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
      this.CSVResult.map((res,i) => {
          this.UpdateFiresStoreReimport(res,i)
      })
},
  CSVConvertToJSON(text){
   
    this.NewImportsArray = []
    this.PartialFailuresArray = []
    
    let lines = text.split("\n")
    this.CheckDelimiter(lines)
    
  },
  UpdateFiresStoreReimport(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let Dealer_NameQuery = []
                        let Dealer_NameStringArray = SingleResult.Dealer_Name.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(Dealer_NameStringArray.length,Dealer_NameStringArray)
                          for (p = 0; p < Dealer_NameStringArray.length; p++) {
                              buildup = buildup+ Dealer_NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          Dealer_NameQuery = lowertext.split(',')  
                          Dealer_NameQuery.length = Dealer_NameQuery.length-1
                  
    let ImportDealership = {
      Dealer_Name: SingleResult.Dealer_Name.trim(),
      Dealer_NameQuery: Dealer_NameQuery,
      Modified_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date(), 
      ImportedRecord: true
      }
      
        ImportDealership.id = SingleResult.id
      
      
      let FailureObject = Object.assign({},ImportDealership)
      FailureObject.Failures = []
      if(SingleResult.Status !== ''){
        let Options = [
  {
    "Options": [
      {
        "LevelFieldName": "Status_Reason",
        "Level": {
          "name": "Status Reason",
          "children": [],
          "id": 2,
          "FieldName": "Status_Reason"
        },
        "ID": 1000003,
        "Name": "Open"
      }
    ],
    "Level": {
      "children": [
        {
          "id": 2,
          "name": "Status Reason",
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "FieldName": "Status",
      "name": "Status",
      "id": 1
    },
    "ID": 1000001,
    "HasDeepHierarchy": true,
    "LevelFieldName": "Status",
    "Name": "Active"
  },
  {
    "Options": [
      {
        "Level": {
          "children": [],
          "FieldName": "Status_Reason",
          "name": "Status Reason",
          "id": 2
        },
        "ID": 1000004,
        "Name": "Closed",
        "LevelFieldName": "Status_Reason"
      }
    ],
    "HasDeepHierarchy": true,
    "Level": {
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "FieldName": "Status",
      "name": "Status",
      "id": 1
    },
    "Name": "InActive",
    "ID": 1000002,
    "LevelFieldName": "Status"
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status = 'Failed - No Matching Option Found'
          ImportDealership.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let StatusObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportDealership.Status = StatusObject
        }

      }
      
      if(ImportDealership.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
      // db.collection('dealerships').add(ImportDealership).then({
      // })
      db.collection('dealerships').doc(ImportDealership.id).update({
      Dealer_Name: ImportDealership.Dealer_Name,
      Dealer_NameQuery: ImportDealership.Dealer_NameQuery,
      Status: ImportDealership.Status,
      Modified_By: ImportDealership.Modified_By,
      Modified_Byid: ImportDealership.Modified_Byid,
      Modified_On: ImportDealership.Modified_On, 
      ImportedRecord: true
        
      })
      
     
        let AllImportObj = this.NewImportsArray.find(imp => imp.Dealer_Name == ImportDealership.Dealer_Name)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = ImportDealership.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Dealer_Name == ImportDealership.Dealer_Name)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = ImportDealership.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Dealer_Name == ImportDealership.Dealer_Name)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = ImportDealership.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.UserRecord
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'Dealerships', datasetrecordpath: 'Dealership', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      


  },
  UpdateFiresStore(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let Dealer_NameQuery = []
                        let Dealer_NameStringArray = SingleResult.Dealer_Name.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(Dealer_NameStringArray.length,Dealer_NameStringArray)
                          for (p = 0; p < Dealer_NameStringArray.length; p++) {
                              buildup = buildup+ Dealer_NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          Dealer_NameQuery = lowertext.split(',')  
                          Dealer_NameQuery.length = Dealer_NameQuery.length-1
                  
    let ImportDealership = {
      Dealer_Name: SingleResult.Dealer_Name.trim(),
      Dealer_NameQuery: Dealer_NameQuery,
      Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Created_Byid: this.UserRecord.id,
      Created_On: new Date(),        
      Status: {ID: 1000001, Name: 'Active'},
      Status_Reason: {ID: 1000001, Name: 'Active'},
      ImportedRecord: true
      }
      
   
      
      let FailureObject = Object.assign({},ImportDealership)
      FailureObject.Failures = []
      if(SingleResult.Status !== ''){
        let Options = [
  {
    "Options": [
      {
        "LevelFieldName": "Status_Reason",
        "Level": {
          "name": "Status Reason",
          "children": [],
          "id": 2,
          "FieldName": "Status_Reason"
        },
        "ID": 1000003,
        "Name": "Open"
      }
    ],
    "Level": {
      "children": [
        {
          "id": 2,
          "name": "Status Reason",
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "FieldName": "Status",
      "name": "Status",
      "id": 1
    },
    "ID": 1000001,
    "HasDeepHierarchy": true,
    "LevelFieldName": "Status",
    "Name": "Active"
  },
  {
    "Options": [
      {
        "Level": {
          "children": [],
          "FieldName": "Status_Reason",
          "name": "Status Reason",
          "id": 2
        },
        "ID": 1000004,
        "Name": "Closed",
        "LevelFieldName": "Status_Reason"
      }
    ],
    "HasDeepHierarchy": true,
    "Level": {
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "FieldName": "Status",
      "name": "Status",
      "id": 1
    },
    "Name": "InActive",
    "ID": 1000002,
    "LevelFieldName": "Status"
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status = 'Failed - No Matching Option Found'
          ImportDealership.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let StatusObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportDealership.Status = StatusObject
        }

      }
      

      if(ImportDealership.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
      // db.collection('dealerships').add(ImportDealership).then({
      // })
      db.collection('dealerships').add(ImportDealership).then(doc => {
        let AllImportObj = this.NewImportsArray.find(imp => imp.Dealer_Name == ImportDealership.Dealer_Name)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = doc.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Dealer_Name == ImportDealership.Dealer_Name)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Dealer_Name == ImportDealership.Dealer_Name)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.UserRecord
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'Dealerships', datasetrecordpath: 'Dealership', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      })


  },
  CloseImportresultsdialog(){
    this.importresultsdialog = false
  },
  onImportfileSelected(event){
    this.ImportFile = event.target.files[0]
  },

  DownloadDealershipsImportTemplate(){
    let copyText = this.HeaderNamesSplit

    var csvfilename = 'Dealerships.csv'
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
    element.setAttribute('download', csvfilename)
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // }
  },
  ResetFilters() {
    this.Statusfilter = ''
    this.Status_Reasonfilter = ''
  },
  ResetSearch() {
    this.search = ''
  },
    
  BURolesCheck(RoleObject){
        let vm = this
        let CreatePermissionLevel = 0
        let GetPermissionLevel = 0
        let ListPermissionLevel = 0
        let UpdatePermissionLevel = 0
        let DeletePermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          let alldeltest = RoleObject.AlldeleteRoles.find(obj => obj == role)
          let unitdowndeltest = RoleObject.UnitDowndeleteRoles.find(obj => obj == role)
          let userunitdeltest = RoleObject.UserUnitdeleteRoles.find(obj => obj == role)
          let ownerdeltest = RoleObject.OwnerdeleteRoles.find(obj => obj == role)
          let alledittest = RoleObject.AllupdateRoles.find(obj => obj == role)
          let unitdownedittest = RoleObject.UnitDownupdateRoles.find(obj => obj == role)
          let userunitedittest = RoleObject.UserUnitupdateRoles.find(obj => obj == role)
          let owneredittest = RoleObject.OwnerupdateRoles.find(obj => obj == role)
          let allviewtest = RoleObject.AllgetRoles.find(obj => obj == role)
          let unitdownviewtest = RoleObject.UnitDowngetRoles.find(obj => obj == role)
          let userunitviewtest = RoleObject.UserUnitgetRoles.find(obj => obj == role)
          let ownerviewtest = RoleObject.OwnergetRoles.find(obj => obj == role)
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
          let allcreatetest = RoleObject.AllcreateRoles.find(obj => obj == role)
          let peercreatetest = RoleObject.UnitDowncreateRoles.find(obj => obj == role)
          let parentcreatetest = RoleObject.UserUnitcreateRoles.find(obj => obj == role)
          let childcreatetest = RoleObject.OwnercreateRoles.find(obj => obj == role)
          if(typeof alldeltest !== 'undefined' || vm.IsAdmin){
            DeletePermissionLevel = 4
          }
          else if(typeof unitdowndeltest !== 'undefined'){
            if(DeletePermissionLevel<3){
              DeletePermissionLevel = 3
            }
          }
          else if(typeof userunitdeltest !== 'undefined'){
            if(DeletePermissionLevel<2){
              DeletePermissionLevel = 2
            }
          }
          else if(typeof ownerdeltest !== 'undefined'){
            if(DeletePermissionLevel<1){
              DeletePermissionLevel = 1
            }
          }
          if(typeof alledittest !== 'undefined' || vm.IsAdmin){
            UpdatePermissionLevel = 4
          }
          else if(typeof unitdownedittest !== 'undefined'){
            if(UpdatePermissionLevel<3){
              UpdatePermissionLevel = 3
            }
          }
          else if(typeof userunitedittest !== 'undefined'){
            if(UpdatePermissionLevel<2){
              UpdatePermissionLevel = 2
            }
          }
          else if(typeof owneredittest !== 'undefined'){
            if(UpdatePermissionLevel<1){
              UpdatePermissionLevel = 1
            }
          }
          if(typeof allviewtest !== 'undefined' || vm.IsAdmin){
            GetPermissionLevel = 4
          }
          else if(typeof unitdownviewtest !== 'undefined'){
             if(GetPermissionLevel<3){
              GetPermissionLevel = 3
            }
          }
          else if(typeof userunitviewtest !== 'undefined'){
            if(GetPermissionLevel<2){
              GetPermissionLevel = 2
            }
          }
          else if(typeof ownerviewtest !== 'undefined'){
            if(GetPermissionLevel<1){
              GetPermissionLevel = 1
            }
          }
          if(typeof alltest !== 'undefined' || vm.IsAdmin){
            ListPermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(ListPermissionLevel<3){
              ListPermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(ListPermissionLevel<2){
              ListPermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(ListPermissionLevel<1){
              ListPermissionLevel = 1
            }
          }
          if(typeof allcreatetest !== 'undefined' || vm.IsAdmin){
            CreatePermissionLevel = 4
          }
          else if(typeof peercreatetest !== 'undefined'){
            if(CreatePermissionLevel<3){
              CreatePermissionLevel = 3
            }
          }
          else if(typeof parentcreatetest !== 'undefined'){
            if(CreatePermissionLevel<2){
              CreatePermissionLevel = 2
            }
          }
          else if(typeof childcreatetest !== 'undefined'){
            if(CreatePermissionLevel<1){
              CreatePermissionLevel = 1
            }
          }
            if(i-1+2 === Roleslength){
              let PermissionLevel = {
                CreatePermissionLevel: CreatePermissionLevel,
                GetPermissionLevel: GetPermissionLevel,
                ListPermissionLevel: ListPermissionLevel,
                UpdatePermissionLevel: UpdatePermissionLevel,
                DeletePermissionLevel: DeletePermissionLevel

              }
              resolve(PermissionLevel)
            }
          })
        })
      },
      BUListRolesCheck(RoleObject){
        let vm = this
        let ListPermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
           
          if(typeof alltest !== 'undefined' || vm.IsAdmin){
            ListPermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(ListPermissionLevel<3){
              ListPermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(ListPermissionLevel<2){
              ListPermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(ListPermissionLevel<1){
              ListPermissionLevel = 1
            }          
          }
            if(i-1+2 === Roleslength){
              let PermissionLevel = {
                ListPermissionLevel: ListPermissionLevel,
              }
              resolve(PermissionLevel)
            }
          })
        })
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      
      GetDealerships() {
      let vm = this
      return new Promise(function(resolve, reject) {
        vm.CollectionRef.onSnapshot(res => {
          let listlength = res.docs.length
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.DealershipsArray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              if(vm.DealershipsArray.length === listlength){
                  resolve('done calling DealershipsArray')
              }
            })
         })
        })
      },
        GetRequestingUser(filteredcall){
      let vm = this
      if(this.userLoggedIn){
        this.UserRecord = this.userLoggedIn
        if(filteredcall === true){
          this.DealershipsCall(this.CollectionRef)
          }   
        else if(vm.DealershipsStore.length>0){
          
          
          vm.DealershipsArray = vm.DealershipsStore
          
              

                    if(vm.UsersStore.length > 0){
                      //alert('got from store')
                      vm.Created_ByArray = vm.UsersStore
                    }
                    else{
                    //alert('NOPE, MUST CALL from store')
                    let payload = {                
                      query: vm.$store.state.UsersQuery,
                      arraymutation: 'setUsersArray',
                      getter: 'getUsersArray',
                      arrayname: 'UsersArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.UsersStore)
                          vm.Created_ByArray = vm.UsersStore
                          })
                    }
    
                
          
        }
        else if (this.DealershipsQuery){
          let payload = {                
                      query: vm.$store.state.DealershipsQuery,
                      arraymutation: 'setDealershipsArray',
                      getter: 'getDealershipsArray',
                      arrayname: 'DealershipsArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.DealershipsStore)
                          vm.DealershipsArray = vm.DealershipsStore
                          })
          
            

                    if(vm.UsersStore.length > 0){
                      //alert('got from store')
                      vm.Created_ByArray = vm.UsersStore
                    }
                    else{
                    //alert('NOPE, MUST CALL from store')
                    let payload = {                
                      query: vm.$store.state.UsersQuery,
                      arraymutation: 'setUsersArray',
                      getter: 'getUsersArray',
                      arrayname: 'UsersArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.UsersStore)
                          vm.Created_ByArray = vm.UsersStore
                          })
                    }
    
              
          }
          else {
        vm.RoutetoLoginPage()
          }
       
      }
      else {
        vm.RoutetoLoginPage()
          }
          
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
    
  DealershipsCall(query){
    let vm = this
    query.onSnapshot(res => {
      let listlength = res.docs.length
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.DealershipsArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
        if(this.DealershipsArray.length === listlength && this.DocTableCall){
          vm.$emit('ReferencedDocsArrayPush',vm.DealershipsArray,'DealershipsArray',vm.headers)
        }          
      })
    })
  },
  
  onLogoUpload(doc,file,uploadfile) {
        let filename = uploadfile.name.split('.')[0]+'_'+uploadfile.size+'.'+uploadfile.name.split('.')[1]
        const totalprogresselement = document.getElementById('shareddocumentstotalprogressbar')
        const totalprogresselementptag = document.getElementById('shareddocumentstotalprogressbarptag')
        const elmnt = document.getElementById('shareddocumentsprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)
        var storageRef = firebase.storage().ref('Dealerships/' + 'Logo/'+filename);
        var uploadTask = storageRef.put(uploadfile);
        let vm = this
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.SharedDocumentsuploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.SharedDocumentsuploadPercentage)
                var textnode = document.createTextNode(vm.SharedDocumentsuploadPercentage+"% complete for "+file.Number);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.SharedDocumentsuploadPercentage === 100){
                  vm.SharedDocumentsrunningProgress = vm.SharedDocumentsrunningProgress+vm.SharedDocumentsuploadPercentage
                  vm.SharedDocumentsrunningpercentage = Number(((vm.SharedDocumentsrunningProgress/vm.SharedDocumentsuploadTotal)*100).toFixed(2))                  
                  vm.SharedDocumentslistuploadcount = vm.SharedDocumentslistuploadcount-1+2
                  var totaltext = document.createTextNode(vm.SharedDocumentsrunningpercentage+'% complete  - '+vm.SharedDocumentslistuploadcount+' of '+vm.SharedDocumentstotallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.SharedDocumentsrunningpercentage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('dealerships').doc(doc.id).update({
                    filename: filename,
                    Logo: url,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date(),
            })
            if(this.SharedDocumentsrunningpercentage === 100){
                      this.UploadingMultipleSharedDocuments = false
                      this.SharedDocumentstotallist = 0
                      this.SharedDocumentsuploadTotal = 0
                      this.SharedDocumentsrunningpercentage = 0
                      this.SharedDocumentsrunningProgress = 0
                      this.SharedDocumentsuploadPercentage = 0
                      this.SharedDocumentslistuploadcount = 0
                      elmnt.style.display = "none";
                      }
            this.selectedLogoFile = ''
                }),
                  this.Logofilesnackbar = true



      },
      onLogoFileselected(event) {
        this.selectedLogoFile = event.target.files[0]
        this.LogolocalURL = URL.createObjectURL(this.selectedLogoFile)
      },

      
  
    

    ViewItem (item) {
      if(this.DashboardView || this.IntranetView){
        let routeData = this.$router.resolve({name: 'dealership',params: {id: item.id}})
        window.open(routeData.href, '_blank')
      }
      
      else{
        this.$router.push('/Dealership/'+item.id)
      }
      
    },

    deleteItem (item) {
      const index = this.DealershipsArray.indexOf(item)
      confirm('Are you sure you want to delete this item?') && db.collection('dealerships').doc(item.id).delete() && this.DealershipsArray.splice(index, 1)
    },

    editItem (item) {
      this.editedIndex = this.DealershipsArray.indexOf(item)
      this.editedItem = Object.assign({}, item)
      
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.UndefinedMandatoryFields.map(prop => {
      
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
            
          })
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },  
    

    save(file) {
      this.UndefinedMandatoryFields = []
    const EditedDealership = {
  Dealer_Name: this.editedItem.Dealer_Name,
  Status: this.editedItem.Status,
      Status_Reason: this.editedItem.Status_Reason,
      }
      if (this.editedIndex > -1) {
       
    for(var prop in EditedDealership) {
        if (EditedDealership.hasOwnProperty(prop)) {
          if(typeof EditedDealership[prop] === 'undefined'){
            this.UndefinedMandatoryFields.push(prop)
          }
        else {
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
          }
          }
    
        else{
          console.log('something is amook')
        }
      }
      console.log(this.UndefinedMandatoryFields)
      if(this.UndefinedMandatoryFields.length === 0){
    db.collection('dealerships').doc(this.editedItem.id).update({
      Dealer_Name: EditedDealership.Dealer_Name,
      
      Status: EditedDealership.Status,
      Status_Reason: EditedDealership.Status_Reason,
      
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date()
            });
            Object.assign(this.DealershipsArray[this.editedIndex], this.editedItem)
            this.close()
            this.EditedDealershipsnackbar = true
      }
      //here we loop for the optional fields and add them as we go
          
      }
      
       else {
        const NewDealership = {
    Dealer_Name: EditedDealership.Dealer_Name,
      
    Status: EditedDealership.Status,
              
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date()
        }
        
        for(var prop in NewDealership) {
        if (NewDealership.hasOwnProperty(prop)) {
          if(typeof NewDealership[prop] === 'undefined' || NewDealership[prop] === ''){
            this.UndefinedMandatoryFields.push(prop)
            console.log(prop)
            console.log(NewDealership[prop])
          }
        else {
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
          }
          }
    
        else{
          console.log('something is amook')
        }
      }
      
        
          console.log(this.UndefinedMandatoryFields)
      if(this.UndefinedMandatoryFields.length === 0){
        NewDealership.Created_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}
        NewDealership.Created_Byid = this.UserRecord.id,          
        NewDealership.Status = this.editedItem.Status,
        NewDealership.Status_Reason = this.editedItem.Status_Reason,
        NewDealership.Created_On = new Date(),
        NewDealership.Modified_By = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        NewDealership.Modified_Byid = this.UserRecord.id,
        NewDealership.Modified_On = new Date()
        NewDealership.Dealer_Name = this.editedItem.Dealer_Name
             
                        let Dealer_NameQuery = []
                        let Dealer_NameStringArray = this.editedItem.Dealer_Name.split('')
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(Dealer_NameStringArray.length,Dealer_NameStringArray)
                          for (p = 0; p < Dealer_NameStringArray.length; p++) {
                              buildup = buildup+ Dealer_NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          Dealer_NameQuery = lowertext.split(',')  
                          Dealer_NameQuery.length = Dealer_NameQuery.length-1
                       // db.collection('dealerships').doc(doc.id).update({
                          NewDealership.Dealer_NameQuery = Dealer_NameQuery,

                         // })
                      
        db.collection('dealerships').add(NewDealership).then(doc => {
          let snackbarcontent = {
          snackbartimeout: 10000,
          snackbartext: 'Added New Dealership - '+NewDealership.Dealer_Name,
          snackbartop: true,
          snackbarpath: '/Dealership/'+doc.id
        }
        this.$emit('ActivateSnackbar',true,snackbarcontent)
                                
                    
                
                                
                  this.close()
        })
        
      }
        else{
      alert('you need to capture the values in '+this.UndefinedMandatoryFields)
      this.UndefinedMandatoryFields.map(field => {
        if(field !== 'Business_Unit' && !field.includes('id')){
        console.log('getting element by id '+field)
        let element = document.getElementById(field)
        
        element.setAttribute("class", "incompletedmandatory")
        console.log('here is element')
        console.log(element)
        }
      })
    }
        
      }
      
    },
  
  }
}

</script>

 <style>
  
    
</style>
<style scoped>
.v-data-table{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#757575;
}
</style>

            
        