          
<template>
  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat  :class="CheckView" style="padding-bottom:100px;">
  <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel> 

  <v-card flat width="100%">
       
        <v-card class="transparent" width="100%"  flat>
            <v-layout row wrap class="justify-center mx-3 my-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
                    <v-text-field v-if="EditingTitle" v-model="Title" label="Title"/>          
          <p class="display-2" v-if="!EditingTitle">{{Title}}</p>
              
                 <v-textarea v-if="EditingDescription" v-model="Description" label="Description"></v-textarea>           
                <p v-if="!EditingDescription">{{Description}}</p>
              
                    <v-text-field v-if="EditingClient" v-model="Client" label="Client"/>          
          <p v-if="!EditingClient&& Client">{{Client}}</p>
              
                    <v-text-field v-if="EditingContract" v-model="Contract" label="Contract"/>          
          <p v-if="!EditingContract&& Contract">{{Contract}}</p>
                
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%"  flat>
            <v-layout row wrap class="justify-center mx-3 my-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
              <v-layout row class="fill-height align-center mx-2">
                   <v-list two-line subheader light align="left" width="100%">                     
                      <v-list-item v-for="item in Multiple_File_Upload_1" :key="item.itemObjKey" class="basicoutlined">
                        <v-layout row class="justify-start">
                          <v-list-item-avatar>
                            <v-icon>mdi-file-document</v-icon>
                            </v-list-item-avatar>
                           <v-list-item-content>
                                <v-list-item-title>{{item.MultipleFileUpload1PrimarySelectedOption.Name}}</v-list-item-title>
                            </v-list-item-content>                             
                              <v-list-item-action>                             
                                    <v-btn icon @click="GetShortLivedURL(item)">
                                    <v-icon color="grey lighten-1">mdi-download</v-icon>
                                  </v-btn>                              
                              </v-list-item-action>                           
                          </v-layout>
                        </v-list-item>
                    </v-list>
                </v-layout>
                
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%"  flat>
            <v-layout row wrap class="justify-center mx-3 my-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-bottom:40px;">
                
              
            <v-row class="justify-center">            
              <v-col
                v-for="(card,i) in MultipleFileUpload2ImagesUploads.slice(0, 8)"
                :key="card.itemObjKey"
                class="d-flex child-flex"
                 cols="BreakPointCol(3)"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-auto" :href="card.url" target="_blank"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.ThumbURL"
                    :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
            </v-row>
                
          </v-flex>          
          </v-layout>      
      </v-card>
       <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
    <v-btn @click="NavigatetoWikiDataMode()" v-if="userLoggedIn && !UserisGuest" icon x-large><v-icon  color="blue" x-large>mdi-database</v-icon>
       </v-btn>

  </v-layout>
  </v-card>
  

  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SiteArticlesFeed from '@/components/Articles/SiteArticle/SiteArticleFeed';
import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTabReadOnly';

export default {
  props: ['AppisDarkMode','UserisGuest'],
  components: {
    SiteArticlesFeed,
    WebFormTab
  },
  data() {
    return {
      
      FormProcessing: false,
      AutomationCreatedSnackbar: false,
      AutomationSnackbarDetails: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },

      RelevantTopBannerAds: [],
      customerclaimdata: {},
      customerclaimswikiarray: [],
      Title: '',
      Description: '',
      Client: '',
      Contract: '',
      Multiple_File_Upload_1: '',
      MultipleFileUpload2ImagesUploads: [],
    }
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(!this.UserisGuest){
      let query = db.collection('customerclaimswiki')
      this.GetCustomerClaim(query)
    }
    else{
      let field = 'Clientid'
      let query = db.collection('customerclaimswiki').where(field,'==',this.userLoggedIn.id)
      this.GetCustomerClaim(query)
    }
    if(this.userLoggedIn){
      this.IntranetViewToggle(true)
    }
    else{
      this.IntranetViewToggle(false)
    }
  },

  computed: {
    
    CheckView(){
      if(!this.userLoggedIn) {
        return 'publicnav'
      }
      else if(this.UserisGuest){
        return ''
      }
      else {
        return 'stickytopconscious'
      }
      
    },
    userLoggedIn () {
        return this.$store.getters.user
      },
              
    },
  
  methods: {
    CurrencyFormatter(value,currency,shortcurrency){
      const currencyformatter = this.PrepareCurrency(currency)
      let formalformat = currencyformatter.format(value)
      return formalformat.split(currency).join(shortcurrency)
    },
    PrepareCurrency(currency){			
      currency
      return new Intl.NumberFormat('en-US', {			
            style: 'currency',			
            currency: currency,			
            minimumFractionDigits: 2			
        })
       
    },	
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          window.open(result.data[0])
        })
    },
    ProcessingData(boolean){
            this.FormProcessing = boolean
        },
    ConfirmSubmission(tab,obj,docid){
            console.log(tab,obj,docid)
            tab.FormSubmitted = true
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record <a :href="'/`+tab.RelatedEntity.SingleName+`/'+`+docid+`" target="_blank">here</a>`
        },

    SubmitEnquiry(){
            let NewEnquiryObject ={
              CreatedOn: new Date(),
              // SubmissionType: 'Web Form',
              // SubmissionForm: 'Enquiry',
              // SubmissionPage: 'Home',Name: this.Name,
              Surname: this.Surname,
              Email: this.Email,
              Telephone_Number: this.Telephone_Number,
              Message: this.Message,
            }
            let data = {
              EnquiryObject: NewEnquiryObject,
            }
            console.log(NewEnquiryObject)
            const functions = firebase.functions();
            const UnauthorizedEnquiry = functions.httpsCallable('UnauthorizedEnquiry');
            UnauthorizedEnquiry(data).then(result => {
                    console.log('sent enquiry')
                    console.log(result);
                    let oldform = document.getElementById('Enquiryform')
                    let formheight = oldform.style.height
                    console.log('formheight',formheight)
                    let newform = document.getElementById('PostEnquiryform')
                    oldform.style.display = "none"
                    newform.style.display = "block"
                    newform.style.height = formheight
                    }).catch(error => {
                    console.log("error",error);
                    });
            
            
          },
    NavigatetoWikiDataMode(){
      confirm('This will navigate you to the backend of this record. Do you want to continue?') && this.ProcessNavigateWikiDataMode()
    },
    ProcessNavigateWikiDataMode(){
      let newpath = '/'+this.$route.path.split('/')[1].split('-Wiki')[0]+'/'+this.customerclaimdata.id
      console.log(newpath)
      this.IntranetViewToggle(false)
      this.$router.push(newpath)
    },
    IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    BreakPointCol(int){
        if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
        return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return int
        }
        else{
          return int
        }
      },
    getImgUrl(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      GetCustomerClaim(query){
        let vm = this
      return new Promise(function(resolve, reject) {
        query.get().then(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.customerclaimswikiarray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
             resolve(vm.customerclaimswikiarray)
              })
          }).then(snapshot => {
          vm.customerclaimdata = snapshot.find(obj => obj.Title.split(' ').join('') === vm.$route.params.id.split(' ').join(''))
        var customerclaimdata = vm.customerclaimdata
        vm.$emit('PushRecordName',vm.customerclaimdata.Title)
        if(vm.userLoggedIn){
          let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
          let RecordSpecificBannerAds =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',true).where('RecordName','==',vm.customerclaimdata.Title)
          vm.GetBannerAds(RouteBannerAdsRef)
          vm.GetBannerAds(RecordSpecificBannerAds)
         
        }
        if(!vm.userLoggedIn){
          let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
          let PublicRecordSpecificBannerAds =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',true).where('RecordName','==',vm.customerclaimdata.Title)
          vm.GetBannerAds(PublicRouteBannerAdsRef)
          vm.GetBannerAds(PublicRecordSpecificBannerAds)
         
        }
        if(customerclaimdata.Title){
          this.Title = customerclaimdata.Title
            }
        
        if(customerclaimdata.Description){
          this.Description = customerclaimdata.Description
            }
        
        if(customerclaimdata.Client){
          this.Client = customerclaimdata.Client
            }
        
        if(customerclaimdata.Contract){
          this.Contract = customerclaimdata.Contract
            }
        
        if(customerclaimdata.Multiple_File_Upload_1){
          this.Multiple_File_Upload_1 = customerclaimdata.Multiple_File_Upload_1
            }
        
          if(customer_claimdata.MultipleFileUpload2ImagesUploads){
             this.MultipleFileUpload2ImagesUploads = customerclaimdata.MultipleFileUpload2ImagesUploads
          }
        
        
      })
      },
         
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
  }
}


</script>

<style>












#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.stickytopconscious{
  top: 100px
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}
</style>
          
            
        