
    <template>
    <div>
      <v-img :src="require('@/assets/RASectionBG.jpeg')" height="50px">
       <h1 align="center" class="mediumoverline white--text">My <span v-if="TeamView">Team </span>Activities by Type</h1>
      
    
       </v-img>
   
  <v-layout row wrap class="justify-center mx-2">
    

     <v-card width ="100%"  :dark="AppisDarkMode" tile flat 
     :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')'  ,backgroundSize: 'cover'}">
     <v-select :items="UserTeamMembers" @change="UpdateView()" v-model="ActiveUser" return-object v-if="TeamView" label="Team Member" item-text="Full_Name"/>
       <v-card-text>
         <p>Here you can view Activities assigned to you, by category of relating Record.</p>
         
       </v-card-text>
       <v-layout row wrap class="justify-center mx-2">
       <v-flex xs6 sm6 md4 lg4 class="my-1 mx-1" v-for="item in itemsCount" :key="item.itemObjKey">
         <v-list elevation="4" outlined
          :dark="AppisDarkMode"
         >
         <v-list-item-title class="blue grey--text overline"
         :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')'  ,backgroundSize: 'cover'}">
          
            <span class="white--text mx-1">{{item.title}} ({{item.count}})</span>
           </v-list-item-title>
         <v-list-item   @click="ActivateType(item)">
           
           <v-list-item-avatar size="40"  >
            <v-icon :class="item.count > 0 ? 'actionicon links--text justify-center' : 'actionicon grey--text justify-center'" dark>{{item.icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content >
         
            <v-list-item-title  :class="item.tasks.length > 0 ? 'caption blue--text' : 'caption grey--text'">
              Tasks: {{item.tasks.length}}</v-list-item-title> 
              <v-list-item-title :class="item.appointments.length > 0 ? 'caption blue--text' : 'caption grey--text'">
              Appointments: {{item.appointments.length}}
             
                </v-list-item-title> 
            </v-list-item-content>
                
          </v-list-item>
         </v-list>
    
       </v-flex>
       </v-layout>
     </v-card>

  </v-layout>

  
  <MyActivitiesbyType @RemoveActivity="RemoveActivity" v-if="ActivitiesbyType" :ActivityType="ActivityType" :UserRecord="ActiveUser" :AllEvents="SelectedEvents" :ActivitiesArray="SelectedTasks"
  />
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/BreadCrumbs'
import MyActivitiesbyType from '@/views/Activities/MyProfile/MyActivitiesbyType'


export default {
  props: ['AppisDarkMode','TeamView'],
  components: { BreadCrumbs,MyActivitiesbyType },
  data() {
    return {
    
    ActivityType: '',
    ActivitiesbyType: false,
      Icons: [],
      drawer: false,
      items: [],
    
      UserFullName: '',
      UserAssignedRoles: [],
      IsAdmin: false,
      MyActivitiesArray: [],
      UserRecord: {},
      ActiveUser: '',
    }
  },
  computed: {
      userLoggedIn () {
      return this.$store.getters.user
      },
      UserTeamMembers(){
          return this.UsersStore.filter(user => {
              let match = this.userLoggedIn.Subordinatearrayquery.find(obj => obj === user.id)
              if(match){
                  return user
              }
          })
      },
      UsersStore(){
          return this.$store.getters.getUsersArray
      },
    SelectedEvents(){
        if(this.ActivityType){
            return this.ActivityType.appointments
        }
    },
    SelectedTasks(){
        if(this.ActivityType){
            return this.ActivityType.tasks
        }
    },


    itemsCount(){
      return this.items.map(item => {
          if(item.countname === 'Other'){
              
              item.count = this.MyActivitiesArray.filter(activity => {
                return typeof activity.regardingtype === 'undefined'
            }).length
            item.tasks = []
            item.tasks = this.MyActivitiesArray.filter(activity => {
                return typeof activity.regardingtype === 'undefined' && activity.activitytype === 'Task' && activity.Ownerid === this.ActiveUser.id
            })
            item.appointments = this.MyActivitiesArray.filter(activity => {
                return typeof activity.regardingtype === 'undefined' && activity.activitytype === 'Appointment' && activity.Ownerid === this.ActiveUser.id
            }) 
            return item
          }
          else{
             item.count = this.MyActivitiesArray.filter(activity => {
                return activity.regardingtype === item.countname
            }).length
            item.tasks = []
            item.tasks = this.MyActivitiesArray.filter(activity => {
                return activity.regardingtype === item.countname && activity.activitytype === 'Task' && activity.Ownerid === this.ActiveUser.id
            })
            item.appointments = this.MyActivitiesArray.filter(activity => {
                return activity.regardingtype === item.countname && activity.activitytype === 'Appointment' && activity.Ownerid === this.ActiveUser.id
            }) 
            return item
          }
        
        
        // this.itemsCount.push(item)
        // this.itemsCount.push(item)
        // this.itemsCount.push(item)
        // this.itemsCount.push(item)
      })
    },
    },

    watch: {
      
    },

  created() {
      this.ActiveUser = this.userLoggedIn

    this.GetRequestingUser()
  },

  methods: {
      UpdateView(){
          
          this.ActivitiesbyType = false
        //   this.MyActivitiesArray = []
          this.ActivityType = ''
          setTimeout(() => {
          this.ActivitiesbyType = true  
          }, 300);
      },
      RemoveActivity(item,type){
          console.log(item)
          console.log(this.MyActivitiesArray)
          let indexitem = this.MyActivitiesArray.find(obj => obj.id === item.id)
          let index = this.MyActivitiesArray.indexOf(indexitem)
          console.log(index)
          this.MyActivitiesArray.splice(index,1)
          console.log(this.MyActivitiesArray)
          this.ActivitiesbyType = false
          setTimeout(() => {
          this.ActivitiesbyType = true  
          }, 300);

      },
      ActivateType(type){
          this.ActivitiesbyType = false
          setTimeout(() => {
            this.ActivityType = type
          this.ActivitiesbyType = true  
          }, 300);
          
      },
    DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserRecord = userdetails
          vm.UserRecord.id = user.uid
          
          if(vm.TeamView){
              vm.userLoggedIn.Subordinatearrayquery.map(teammemberid => {
                  console.log('getting for team member ',teammemberid)
                  vm.GetMemberActivities(teammemberid)
              })
          }
          else{
              vm.GetActivities()
          }
          vm.getUserActivityRelated()
          })
        }
      })
    },
    GetMemberActivities(teammemberid){
      db.collection('activities').where('Ownerid', '==', teammemberid).where('activitytype', 'in', ['Task','Appointment']).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
          if(this.ActivitiesbyType){
              this.ActivitiesbyType = false
              setTimeout(() => {
                this.ActivitiesbyType = true
              }, 300);
          }
        }


      })
    //   this.itemsCount = []
      
    })
    },
    GetActivities(){
      db.collection('activities').where('Ownerid', '==', this.UserRecord.id).where('activitytype', 'in', ['Task','Appointment']).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
          if(this.ActivitiesbyType){
              this.ActivitiesbyType = false
              setTimeout(() => {
                this.ActivitiesbyType = true
              }, 300);
          }
        }


      })
    //   this.itemsCount = []
      
    })
    },


    async getUserActivityRelated() {
        var vm = this;

      await firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
        //console.log('AuthchangeLoggedIn but not dispatching userstate')
        // console.log(user.uid)
        // vm.$store.dispatch('UserState',user)
      vm.items = []
      let Otheritemobject = {
            icon: 'mdi-help',
            title: 'Other',
            to: '/MyActivities/undefined',
            countname: 'Other'
          }
          vm.items.push(Otheritemobject)
        db.collection('users').doc(user.uid).onSnapshot(snapshot => {
           var userdetails = snapshot.data()

          vm.UserFullName = userdetails.Name+' '+userdetails.Surname
          if(typeof userdetails.rolesarrayQuery !== 'undefined'){
          vm.UserAssignedRoles = userdetails.rolesarrayQuery
          let AdminTest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
          
          let DefaultgetAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get All')
      if(typeof DefaultgetAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let Name21roletest = vm.UserAssignedRoles.find(obj => obj === 'Name 21')
      if(typeof Name21roletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list All')
      if(typeof DefaultlistAllroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultdeleteALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete ALL')
      if(typeof DefaultdeleteALLroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit')
      if(typeof DefaultUnitroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultupdateUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update Unit Down')
      if(typeof DefaultupdateUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultgetUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UserUnit')
      if(typeof DefaultgetUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UserUnit')
      if(typeof DefaultlistUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultCreateAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Create All')
      if(typeof DefaultCreateAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UnitDown')
      if(typeof DefaultlistUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let Defaultcreateunitdownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create unitdown')
      if(typeof Defaultcreateunitdownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update ALL')
      if(typeof DefaultupdateALLroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultOwnerroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Owner')
      if(typeof DefaultOwnerroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default All')
      if(typeof DefaultAllroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultcreateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create UserUnit')
      if(typeof DefaultcreateUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UserUnit')
      if(typeof DefaultdeleteUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UnitDown')
      if(typeof DefaultdeleteUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update UserUnit')
      if(typeof DefaultupdateUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit Down')
      if(typeof DefaultUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultgetUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UnitDown')
      if(typeof DefaultgetUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          }
        })



    }

   else {
        //console.log('AuthchangeLoggedOut')
        vm.pushLoggedOutItems

      vm.items = [

        {
          icon: 'mdi-login-variant',
          title: 'Login',
          to: '/Login'
        },
      ]

  }
    })
    }

  }

}
</script>

<style>
.shaped{
  border: 1px solid red;
  border-radius: 5px;
}
</style>
    

    