
    <template>
<v-card flat class="transparent" width="100%" height="100%">
    <h4>Tasks for {{RecordPrimaryField}}</h4>

                      <v-list dark dense max-height="75">
                        <v-list-item>
                          <v-list-item-content>
                        <!-- <v-btn outlined class="mr-4" color="secondary">
                          <v-text-field v-model="NewTask.Name" label="Name"></v-text-field>
                        </v-btn> -->
                        
                            <v-text-field  color="secondary" dense outlined v-model="search" label="Name"></v-text-field>
                          </v-list-item-content>
                          <v-list-item-content>
                      <v-menu
                      
                          v-model="TaskFilterDatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                            dense
                            outlined
                            color="secondary"
                              v-model="TaskFilterDate"
                              label="Due Date"
                              prepend-icon="mdi-calendar-month"
                              readonly
                              v-on="on"
                              clearable
                                                         
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="TaskFilterDate" @input="TaskFilterDatemenu = false"></v-date-picker>
                      </v-menu>
                      </v-list-item-content>
                        
                        </v-list-item>
                          </v-list>
                          <v-dialog v-model="TaskDialog" max-width="500px">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on"
                                  fab
                                  color="primary"
                                  right
                                  absolute
                                  small
                                ><v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                            </template>
                          <v-card width ="600px">
                          <v-card-title>
                            <span class="headline">Add New Task</span>
                          </v-card-title>

                           
                              <v-card-text>

                                    <v-text-field v-model="NewTask.Name" label="Name"></v-text-field>
                                  </v-card-text>
                                  <v-card-text>
                                    <v-textarea v-model="NewTask.details" label="Description"></v-textarea>
                                  </v-card-text>

                                  <v-card-text>
                                      <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" v-model="NewTask.Owner" return-object></v-autocomplete>
                                    </v-card-text>
                                  <v-card-text>

                                   </v-card-text>

                                  <v-card-text>
                                      <v-menu
                                        v-model="DueDatemenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            v-model="NewTask.duedate"
                                            label="Due Date"
                                            prepend-icon="mdi-calendar-month"
                                            readonly
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="NewTask.duedate" @input="DueDatemenu = false"></v-date-picker>
                                      </v-menu>

                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn dark color="blue darken-1" @click="CloseTaskDialog()">Cancel</v-btn>
                                  <v-btn dark color="blue darken-1" @click="SaveNewTask()">Save</v-btn>
                                </v-card-actions>

                            
                          </v-card>
                          </v-dialog>
                          <v-layout class="justify-center mx-4" style="font-size:12px">
                            <v-col>
                              <strong>Owner</strong>
                            </v-col>
                            <v-col>
                              <strong>Name</strong>
                            </v-col>
                            <v-col>
                              <strong>Date</strong>
                            </v-col>
                            
                            <v-col>
                              <v-menu
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-filter-menu-outline</v-icon>
                              </template>
                                <v-card>
                                <v-layout row class="mx-3">
                                    <v-select :items="['Open','Completed']" v-model="TaskStatusFilter" label="Status Filter"></v-select>
                                  </v-layout>
                                                  
                                  <v-layout row class="mx-3">
                                    <v-menu
                                    v-model="TaskFilterDatemenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="TaskFilterDate"
                                        label="Filter Date"
                                        prepend-icon="mdi-calendar-month"
                                        readonly
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="TaskFilterDate" @input="TaskFilterDatemenu = false"></v-date-picker>
                                </v-menu>
                                </v-layout>
                                </v-card>
                              </v-menu>                              
                            </v-col>
                          </v-layout>
                          <v-list-group class="mx-3 my-2 red lighten-5 basicoutlined"
                                  v-for="task in TasksFilteredandSearched" :key="task.id"
                              >
                                <template v-slot:activator>
                                  <v-layout class="justify-center" style="font-size:12px">
                                  <v-col cols="1">
                                    <v-avatar color="accent" size="25">
                                    <v-btn class="transparent" :to="'/User/'+task.Owner.id" target="_blank"><span class="white--text">{{task.Owner.Full_Name.split(' ')[0].substr(0,1)}}{{task.Owner.Full_Name.split(' ')[1].substr(0,1)}}</span></v-btn>
                                  </v-avatar>
                                  </v-col>
                                  <v-col cols="6" class=" mx-2">
                                   {{ task.name }}
                                  </v-col>
                                   <v-col cols="6">
                                  {{ task.duedate.toDate().getDate()}}-{{ task.duedate.toDate().getMonth()-1+2}}-{{ task.duedate.toDate().getFullYear()}}
                                  </v-col>
                                
                                  </v-layout>
                                </template>
                                <v-list dense class="white taskdetails">
                              <v-list-item>
                                View <v-spacer></v-spacer><v-icon @click="ViewActivityItem(task)" class="blue--text">mdi-eye</v-icon>
                              </v-list-item>
                              <v-list-item>
                                <b>Owner:</b><v-spacer></v-spacer>{{ task.Owner.Full_Name }}
                              </v-list-item>
                              <v-list-item>
                                <b>Name:</b><v-spacer></v-spacer>{{ task.name }}
                              </v-list-item>
                              
                              <v-list-item>
                                <b>Description:</b>
                              </v-list-item>
                              <v-list-item>
                                {{ task.details }}
                              </v-list-item>
                              <v-list-item>
                                <b>Status:</b><v-spacer></v-spacer>{{ task.status }}
                              </v-list-item>
                              <v-list-item>
                                <b>Mark Complete:</b><v-spacer></v-spacer><v-btn fab x-small color="success"><v-icon small color="white">mdi-check</v-icon></v-btn>
                              </v-list-item>
                     
                            </v-list>
                                </v-list-group>
                      <v-layout row class="justify-center">
                      Completed - {{TasksFilteredandSearched.length}}
                      Total - {{ActivitiesArray.length}}
                      </v-layout>                                
</v-card>    
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['Business_Unit','UserRecord','UsersArray','ActivitiesArray','RelatedObj',
    'RecordPrimaryField','RegardingTypefilter','ActivityTypefilter'],   
    data() {
        return {
            TaskFilterDate: '',
      TaskDialog: false,
      NewTask: {
            Name: '',
            details: '',
            activitytype: 'Task',
            regardingtype: '',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
      DefaultNewTask: {
            Name: '',
            details: '',
            activitytype: 'Task',
            regardingtype: '',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
            pickerDate: null,
            TaskStatusFilter: 'Open',
            CreatedOnmenu: false,
            DueDatemenu: false,
            TaskFilterDatemenu: false,
            taskheaders: [
            { text: 'Owner', value: 'Owner'},
            { text: 'Name', value: 'Name'},
            { text: 'Date', value: 'duedate'},
            { text: 'Delete', value: 'delete', sortable: false },
            { text: 'Check', value: 'check', sortable: false },
        ],
        search: '',
        }
    },
    computed: {
        ActivitiesSearched() {
      return this.ActivitiesArray.filter(Activity => {
        if(Activity.name){return Activity.name.toLowerCase().includes(this.search.toLowerCase())}})
      
      },
      ActivitiesFilteredandSearched() {
      return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
      },
      TasksFilteredandSearched(){
          return this.ActivitiesFilteredandSearched.filter(activity => {
              return activity.activitytype === 'Task'
          }).filter(activity => {
              if(this.TaskFilterDate){
                  return this.DateFilterString(activity.duedate) === this.FormattedTextDate
              }
              else{
                  return activity
              }
          }).filter(activity => {
              if(this.TaskStatusFilter){
                  return activity.status.includes(this.TaskStatusFilter)
              }
              else{
                  return activity
              }
          })
      },
      TasksFilteredandSearchedTable(){
          return this.TasksFilteredandSearched.filter(activity => {
              return activity.duedate === this.TaskFilterDate
          })
      },
      FormattedPickDate(){
        return this.DateFormatter(this.TaskFilterDate)
      },
      FormattedTextDate(){
          if(this.TaskFilterDate){
        return this.TaskFilterDate.split('-').join('')
          }
      },    
    },
    created(){

    },
    methods:{
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
    },
        SaveNewTask(){
      const TasktoAdd = {
            name: this.NewTask.Name,
            details: this.NewTask.details,
            activitytype: 'Task',
            regardingtype: this.RelatedObj.Link,
            createdon: new Date(),
            duedate: this.DateFormatter(this.NewTask.duedate),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            regardingrecord: {
              Link:'/'+this.RelatedObj.Link+'/'+this.$route.params.id, Name:this.RecordPrimaryField, id: this.$route.params.id
            },
             

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      TasktoAdd.regardingrecord[this.RelatedObj[this.RelatedObj.identifier]] = this.$route.params.id
      if(typeof this.Business_Unit !== 'undefined' && typeof this.Business_Unit.id !== 'undefined'){
        TasktoAdd.Business_Unitid = this.Business_Unit.id
      }
      db.collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.activitytype === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.name,
              Content: TasktoAdd.details,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname,
                    Date: new Date()
            }
            db.collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },
        DateFilterString(date){      
      let onedate = date.toDate()
      let dateyear = onedate.getFullYear().toString()
      let datemonth = onedate.getMonth()-1+2
      let datemonthfinal = datemonth.toString()
      let dateday = onedate.getDate()
      if(datemonth.toString().length===1){
        let stringdate = dateyear+'0'+datemonth+dateday
      return stringdate
      }
      else{
        let stringdate = dateyear+datemonth+dateday
      return stringdate
      }
    },
    }
}
</script>

     

     

     