
<template>
<v-main class="grey lighten-3" style="margin-top:0px;margin-bottom:-150px">

             <v-card :color="MatchColor(tab)" :class="tab.RowTransparency" tile  width="100%" flat  class="CodeRenders" id="PageCode"
                v-for="(tab,i) in PageTabs" :key="tab.itemObjKey" >
<!-- :class="'mb-6 '+row.Justify"
            no-gutters
            v-for="(row,rowindex) in tab.Rows" :key="row.itemObjKey"
            :style="'align-self:'+row.Alignment" -->
                        <!-- <v-container class="grey lighten-5"> -->
                            
                            <v-layout row justify-center v-if="tab.ShowComponents">
                             
                          <div :class="'flex xl'+tab.FlexXLRG+' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall">  
                              <!-- <v-flex :xl="tab.FlexXLRG" {{tab.FlexXLRG}} :lg="tab.FlexLarge" :md="tab.FlexMedium" :sm="tab.FlexSmall" :xs="tab.FlexXSmall"> -->
                     <div v-if="tab.Name === 'Card Row'">
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                <v-card-title :class="tab.Center" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                <strong>{{tab.Title}}</strong>
                                 <!-- <v-divider inset class="white" >
                                </v-divider> -->
                                <!-- <hr style="width:35%; color:blue;"> -->
                                </v-card-title>
                                <!-- <v-divider inset class="white" >
                                </v-divider> -->
                            <v-card-text v-html="tab.Description" :class="tab.FillHeight" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex">
                                </v-card-text>
                             
                             <v-card-text v-if="tab.HasImage">
                                 <v-layout :class="tab.Justify" row>
                                 <v-img  v-if="tab.HasImage && tab.IMG"
                                 :contain="!tab.Clipped"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        :style="CheckifClipped(tab)"
                                       
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                 </v-layout>
                             </v-card-text>
                        
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">
                            <v-btn :dark="tab.ActionBtnDark"  :style="'background-color:'+tab.ButonColor.hex">
                                {{tab.ActionButtonName}}
                            </v-btn>
                        </v-card-actions>
                            </div>
                     <v-row  v-if="tab.Name !== 'Parallax' && tab.Name !== 'Clipped Tab'"        
                        :class="'mb-6 '+tab.Justify"
                        no-gutters
                        :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                       
                        >        
                        
                         
                         
                                    
                        <v-col
                            v-for="(elmnt,index) in tab.Elements"
                            :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                            cols="12"    
                            :class="elmnt.Justify+' '+ColMY(elmnt)"  
                            :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
                        >    
                        
                         <TimelineTab 
                            v-if="elmnt.Name === 'Timeline' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                            />
                            
                            <ImageTab v-if="elmnt.Name === 'Image' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                            />
                        <TitleTab 
                            v-if="elmnt.Name === 'Title' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <IFRAMETab  v-if="elmnt.Name === 'IFRAME' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                                :PageHeadersFontSize="PageHeadersFontSize" :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                                    />
                        
                            <GoogleMyMapsTab 
                            v-if="elmnt.Name === 'Google My Maps' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <HTMLBox 
                            v-if="elmnt.Name === 'HTML Box' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            
                            <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" :i="i"
                            />
                            
                            <CardRowTab 
                            v-if="elmnt.Name === 'Card Row Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                           <CardBlocksTab 
                            v-if="elmnt.Name === 'Card Block Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <CounterTab 
                            v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <TestimonialTab 
                            v-if="elmnt.Name === 'Testimonials' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <StoreProductTab 
                            v-if="elmnt.Name === 'Store Product Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            
                        </v-col>
                        
                     </v-row>
                     <!-- <div v-if="tab.Name === 'Card Blocks'">

                                        <v-container class="pa-1">
                                            <v-row>
                                            <v-col
                                                v-for="(item, i) in tab.Elements"
                                                :key="i"
                                                cols="3"
                                                md="3"
                                            >
                                            </v-col>
                                            </v-row>
                                        </v-container>
                            </div> -->
                     
                    <GalleryTab  v-if="tab.Name === 'Gallery' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"
                                @DeleteTabAsset="DeleteTabAsset"
                                />
                        
                   <ParallaxTab v-if="tab.Name === 'Parallax' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"
                    />
                 
                  
                    <ClippedTab 
                    v-if="tab.Name === 'Clipped Tab' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize"
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"
                    />
                      </div>
                     <v-card flat width="100%" style="visibility:hidden;height:0px"  @dragleave="unallowDrop(event,i)" @dragover.prevent @drop.prevent="contentdropFinish(i, $event)" :id="i+'drop-area'">
                <v-layout class="justify-center">
                    <v-card-title class="grey--text">
                    <v-icon>mdi-plus-thick</v-icon> Add tab below
                    </v-card-title>
                </v-layout>
                </v-card>
                      
                            </v-layout>    
                </v-card>
                <v-layout column class="fab-container">
                <v-btn  icon x-large @click="ToggleMenu()"><v-icon  color="green" x-large>mdi-home</v-icon></v-btn>
            </v-layout>
          </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TimelineTab from '@/components/Campaigns/LandingPages/TimelineTabReadOnly';
import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTabReadOnly';
import TitleTab from '@/components/Campaigns/LandingPages/TitleTabReadOnly';
import CounterTab from '@/components/Campaigns/LandingPages/CounterTabReadOnly';
import StoreProductTab from '@/components/Campaigns/LandingPages/StoreProductTabReadOnly';
import TestimonialTab from '@/components/Campaigns/LandingPages/TestimonialTabReadOnly';
import CardBlocksTab from '@/components/Campaigns/LandingPages/CardBlocksTabReadOnly';
import CardRowTab from '@/components/Campaigns/LandingPages/CardRowTabReadOnly';
import HTMLBox from '@/components/Campaigns/LandingPages/HTMLBoxReadOnly';
import ImageTab from '@/components/Campaigns/LandingPages/ImageTabReadOnly';
import ParallaxTab from '@/components/Campaigns/LandingPages/ParallaxTabReadOnly';
import ClippedTab from '@/components/Campaigns/LandingPages/ClippedTabReadOnly';
import IFRAMETab from '@/components/Campaigns/LandingPages/IFRAMETab';
import GalleryTab from '@/components/Campaigns/LandingPages/GalleryTabReadOnly';
import GoogleMyMapsTab from '@/components/Campaigns/LandingPages/GoogleMyMapsTab';

export default {
    props: [],
    components: {
        TimelineTab,
        WebFormTab,
        TitleTab,
        HTMLBox,
        CardBlocksTab,
        CardRowTab,
        CounterTab,
        StoreProductTab,
        TestimonialTab,
        ImageTab,
        ParallaxTab,
        ClippedTab,        
        GalleryTab,
        IFRAMETab,
        GoogleMyMapsTab
        },
    data() {
    return {   
        PageName: '',
        PageContentFont: 'Montserrat',
        PageHeadersFontSize: 42,
        PageHeadersFont: 'Raleway',
      PageTabs: [],
        PageCode: '',
        UserRecord: '',
        rules: {
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            

            }
    }, 

    computed:{
        
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.$emit('LandingPageView',true)
      this.CheckUser()

    },
    
    methods: {
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },
        MatchColor(tab){
            if(tab.RowColor){
                return tab.RowColor.hexa
            }
            
        },
      CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
      ClassCheck(tab){
           var newclass = ''
           
            if(tab.Transparent){
                newclass = newclass+' red'
                tab.Color = '#ffffff'
            }
             if(tab.Clipped){
                newclass = newclass+' clipped'
            }
            return newclass
        },
      HeaderColor(tabHeaderColor){
            if(tabHeaderColor.hex){
                return tabHeaderColor.hex
            }
        },
        ToggleMenu(){
            this.$emit('LandingPageView',false)
        },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
            if(this.userLoggedIn){
               this.UserRecord = this.userLoggedIn
              console.log(this.UserRecord)
              
            }
            this.GetLandingPage()
        },
        
        GetLandingPage(){
            console.log('calling page')
            db.collection('landingpages').doc(this.$route.params.id).onSnapshot(snapshot => {
                let pagedata = snapshot.data()
                console.log(pagedata)
                if(pagedata.PageCode){
                    this.PageCode = pagedata.PageCode
                   
                }
                if(pagedata.PageTabs){
                  this.PageTabs = pagedata.PageTabs
                }
                this.PageContentFont = pagedata.PageContentFont
                this.PageHeadersFontSize = pagedata.PageHeadersFontSize
                this.PageHeadersFont = pagedata.PageHeadersFont
                this.PageName = pagedata.Name
                // alert(this.PageName)
            })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
@keyframes spotlightsearch {

    0% { clip-path: circle(50px at 0 100px); }
    15% { clip-path: circle(15% at 100% 50%); }
    50% { clip-path: circle(15% at 10% 80%); }
    60% { clip-path: circle(15% at 80% 70%); }
    75% { clip-path: circle(15% at 50% 50%); }
    100% { clip-path: circle(75%); }
}
</style>

        

    