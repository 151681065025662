<template>
  <v-dialog v-model="ShareItemDialog" persistent max-width="400">
    <v-card max-width="400">
      <v-card-title>
        Share Details
      </v-card-title>
      <v-card-actions>
        <v-autocomplete :items="Walls" item-text="Name" return-object v-model="SelectedWall" label="Wall"/>
      </v-card-actions>

      <v-card-text contenteditable id="sharepostinput">
      </v-card-text>
      <v-card-actions>
        <v-btn @click="CancelShareItemDialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="CreatePost()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['ShareItemDialog','UserRecord','InternalLinkType','LinkComponent','SiteModerators','collectionname','LinkID','FriendsList','SharingPost'],
    data() {
        return {
            SelectedWall: '',
            UserGroups: []
        }
    },
    computed: {
         Walls(){
        let mywallobj = {
          Name: 'My Wall',
          WallID: this.UserRecord.id,
          IsUser: true,
          WallType: 'SiteMember',
          Owner: {
            Full_Name: this.UserRecord.Full_Name,
            id: this.UserRecord.id
          },
            Path: '/SiteMember/'+this.UserRecord.id
        }
        let friendswalls = []
        if(this.FriendsList){
          friendswalls = this.FriendsList.map(friend => {
          let obj = {
            Name: friend.Full_Name,
            WallID: friend.id,
            IsFriend: true,
            WallType: 'SiteMember',
            Owner: {
              Full_Name: friend.Full_Name,
              id: friend.id
            },
            Path: '/SiteMember/'+friend.id
          }
          return obj
        })
          }
        friendswalls.push(mywallobj)
        let groups = []
        console.log(this.UserGroups)
        if(this.UserGroups){
          groups = this.UserGroups.map(group => {
          let obj = {
            Name: group.name,
            WallID: group.id,
            IsGroup: true,
            WallType: 'Group',
            Owner: group.Created_By,
            Path: '/Group/'+group.id
          }
          return obj
        })
          }
          
        return groups.concat(friendswalls)
      }
    },
    created(){
      console.log(this.UserRecord)
      this.UserRecord.groups.map(group => {
        this.GetGroupInformation(group.Groupid)
      })
      
    },
    methods: {
      GetGroupInformation(groupid){
        db.collection('groups').doc(groupid).onSnapshot(snapshot => {
          let groupdata = snapshot.data()
          groupdata.id = groupid
          this.UserGroups.push(groupdata)
        })
      },
        CreatePost(){
        let postelmnt = document.getElementById('sharepostinput')
        let postinput = postelmnt.innerHTML
        if(!postinput){
          postinput = null
        }
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          creatorname: this.UserRecord.Full_Name,
          post: postinput,
          createdon: new Date(),
          Comments: [],
          WallID: this.SelectedWall.WallID,
          WallType: this.SelectedWall.WallType,
          ShareName: this.SelectedWall.Name,
          IsLink: true,
          InternalLink: true,
          InternalLinkType: this.InternalLinkType,
          LinkID: this.LinkID,
          collectionname: this.collectionname,
          ComponentName: this.LinkComponent,
        }
         if(this.SharingPost){
          NewPost.InternalLinkType = this.SharingPost.InternalLinkType
          NewPost.collectionname = this.SharingPost.collectionname
          NewPost.ComponentName = this.SharingPost.ComponentName
          NewPost.LinkID = this.SharingPost.LinkID
          NewPost.PostID = this.LinkID
        }
        if(this.UserRecord.Profile_Photo){
          NewPost.creatorimg = this.UserRecord.Profile_Photo
        }
        db.collection('socialsiteposts').add(NewPost).then(doc => {
          if(NewPost.Creatorid !== NewPost.WallID){
          const NewNotification = {
                    postid: doc.id,
                    Type: 'Wall Post',
                    Header: this.UserRecord.Full_Name+' posted on your wall',
                    Content: NewPost.post,
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: this.SelectedWall.Owner,
                    Ownerid: this.SelectedWall.Owner.id,
                    Read: false,
                    Path: this.SelectedWall.Path,
                    Message: 'Click to view Post',
                    Date: new Date()
                    }
                    if(NewPost.WallType === 'Group'){
                      NewNotification.Header = this.UserRecord.Full_Name+' posted on '+NewPost.ShareName+' Group wall'
                    }
                    else if(NewPost.WallType === 'SiteMember'){
                      NewNotification.Header = this.UserRecord.Full_Name+' posted on your wall'
                    }
                    if(NewNotification.Content === null){
                      NewNotification.Content = NewNotification.Header
                    }
                    db.collection('notifications').add(NewNotification).then(notedoc => {

                    })
        }
        })
        console.log(NewPost.post)
        this.CancelShareItemDialog()
      },
        CancelShareItemDialog(){
            this.$emit('CancelShareItemDialog')
        }
    }
}
</script>

<style>

</style>

  