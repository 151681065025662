
<template>
    <v-card class="transparent" flat tile width="100%" >
        <v-dialog v-model="UploadingSingleFile" max-width="500px" persistent>
                <v-card min-height="300">
                <v-img
                width="500"
                src="@/assets/RABaseBG-Soft.png"
                ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                  <v-card-title class="mediumoverline white--text">
                    Uploading...
                  </v-card-title></div>
                    <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                      <v-layout row class="justify-center mx-3 my-3">
                          <ul>
                            <progress id="singlefileuploadprogressbar" max="100" value="0"></progress>
                            <p style="font-size:16px;color:blue;" id="singlefileuploadprogresstext">0% complete</p>
                          </ul>
                      </v-layout>
                    </v-row>
                    </v-img>
                 </v-card>
               </v-dialog>
        <v-dialog v-model="UploadingFiles" max-width="500px" persistent>
                   <v-card width="100%" min-height="300">
                          <v-img
                        width="500"
                        src="@/assets/RABaseBG-Soft.png"
                        ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                          <v-card-title class="mediumoverline white--text">
                            Uploading...
                          </v-card-title></div>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="uploadfilesprogressbar" style="display:none">
                            <ul id="uploadfilesprogressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                      </v-img>
                   </v-card>
                 </v-dialog>
                 
                 <v-list dense :dark="AppisDarkMode" class="windowcard transparent" max-width="400" style="padding:25px;">
            <!-- <v-snackbar :light="!AppisDarkMode" multi-line transition="slide-x-reverse-transition" bottom right absolute :style="{'margin-top':calcMargin(i)}" v-for="(s,i) in Computedsnackbars" :key="i" v-model="s.show" :timeout="s.timeout"> -->
                    <v-card class="mx-3 my-2" elevation="6" :dark="AppisDarkMode" v-for="(s,i) in Computedsnackbars" :key="i" v-model="s.show">
                        <v-card-subtitle ><v-btn color="red" x-small text @click="hide(s)"> Dismiss </v-btn>
                        <v-btn color="red"  v-if="s.IsNotification" x-small text @click="MarkasRead(s)"> Mark as Read </v-btn>
                        </v-card-subtitle>
                      <v-card-subtitle >{{s.title}}
                        </v-card-subtitle>
                        <!-- <v-card-text> -->
                 <v-list-item v-if="!s.IsNotification"  :to="'/NoticeSingle/'+s.id" >
                 <v-list-item-avatar size="70" tile class="justify-center" v-if="s.thumbnail" rounded>
                       <v-img :src="s.thumbnail"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="s.text" style="font-size:14px;">
                
                 </v-list-item-content>
                 </v-list-item>
                <v-list-item v-if="s.IsNotification && !s.isHref"  @click="OpenNotification(s)">
                 <v-list-item-avatar size="70" tile class="justify-center" v-if="s.thumbnail" rounded>
                       <v-img :src="s.thumbnail"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="s.text" style="font-size:14px;">
                
                 </v-list-item-content>
                 </v-list-item>
                 <v-list-item v-if="s.IsNotification && s.isHref"  :href="s.Route" :target="s.Target" >
                 <v-list-item-avatar size="70" tile class="justify-center" v-if="s.thumbnail" rounded>
                       <v-img :src="s.thumbnail"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="s.text" style="font-size:14px;">
                
                 </v-list-item-content>
                 </v-list-item>

                        <!-- </v-card-text> -->
                    </v-card>
            <!-- </v-snackbar> -->
                 </v-list>
                 <!-- <v-list v-if="windowSnackbars" :dark="AppisDarkMode" class="windowcard" width="400">
                   <v-snackbar 
                   :value="true"
                   
                   :timeout="200000"
                   v-for="(notice,i) in snackbars" :key="i" :style="{'margin-bottom':calcMargin(i)}">
                      {{notice.text}}
                   </v-snackbar> -->
                  <!-- <v-list-item :to="'/NoticeSingle/'+notice.id"  class="mx-1 my-1 windowcarditem" v-for="(notice,i) in snackbars" :key="notice.itemObjKey" >
                
                 <v-list-item-avatar size="90" tile class="justify-center" rounded>
                       <v-img src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="notice.text">
                
                 </v-list-item-content>
               <v-snackbar :style="{'margin-bottom':calcMargin(i)}" v-model="show" :timeout="2000">
                {{ notice.text }}
                <v-btn color="blue" text @click="hide(i)"> Close </v-btn>
            </v-snackbar> -->
                    <!-- <v-list-item-avatar size="40" class="noticeboarddate links white--text justify-center" rounded>
                         <v-icon class="white--text">
                           mdi-alert
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>     
                                                    
                            <v-list-item-subtitle style="font-size:10px">
                            <v-icon small color="red">mdi-alert</v-icon>
                                                
                            <span class="blue--text"> 12 November 2020</span><span class="purple--text"> Category</span>
                            </v-list-item-subtitle>
                            <v-list-item-content class="caption grey--text">
                        ist-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                            Unconfirmed 
                        </v-list-item-content>
                        </v-list-item-content>
                        <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
                        <v-icon class="white--text">
                           mdi-alert
                        </v-icon>
                    </v-list-item-avatar>
                    </v-list-item> -->
                 <!-- </v-list> -->
        <v-layout row justify-center v-if="Processing">
          <v-dialog v-model="Processing" persistent fullscreen content-class="loading-dialog">
            <v-container fill-height>
              <v-layout row justify-center align-center>
                <v-progress-circular indeterminate :size="250" :width="7" class="mediumoverline" color="purple">Processing</v-progress-circular>
              </v-layout>
            </v-container>
          </v-dialog>
        </v-layout>

       <v-dialog
       persistent
       v-model="StandbyDialog"
         max-width="1000"
        >
        <v-card
        style="padding-top:10px;"
        width="100%"
        tile
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
       </v-dialog>
       <v-snackbar
       color="links"
       :top="snackbartop"
      v-model="snackbar"
      :multi-line="snackbarmultiLine"
      :timeout="snackbartimeout"
    >
      {{ snackbartext }}
         <template v-slot:action="{ attrs }">
        <v-btn
        v-if="!snackbartimeout && !snackbarpath"
          color="blue"
          text
          v-bind="attrs"
          @click="CloseSnackbar()"
        >
          Close
        </v-btn>
        <v-btn
        v-if="snackbarpath"
          color="blue"
          text
          v-bind="attrs"
          :to="snackbarpath"
           @click="CloseSnackbar()"
        >
          View
        </v-btn>

      </template>
      
    </v-snackbar>
    </v-card>
   
</template>

<script>
import firebase from 'firebase';
import format from 'date-fns/format'
import db from '@/main'

export default {
    props: ['AppisDarkMode','StandbyDialog','snackbar','snackbarmultiLine','snackbartimeout','snackbartext','snackbartop','UploadingFiles','UploadingSingleFile','AppisDarkMode','snackbars','snackbarpath','Processing','Notifications','UserisGuest'],
  components: {
  },
    data() {
        return {
            windowSnackbars: true,
            show: true,
        //     snackbars: [
        // {title: 'Number 1',timeout:4000,show: true, text: `Hey I am the 1st.
        // <p>For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.</p>`},
        // // {title: 'Number 2', text: `Hey I am the 2nd.For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.`},
        // // {title: 'Number 3', text: `Hey I am the 3rd.For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.`},
        // // {title: 'Number 4', text: `Hey I am the 4th.For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.`}
        // ],
            StaticNotices: [

            ],
            
            rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            
        }
    },
    computed: {
        Computedsnackbars(){
            return this.snackbars
            .map(s => {
                if(s.timeout){
                     setTimeout(() => {
                   s.show = false 
                }, s.timeout);
                }
               
                return s
            })
            .filter(s => {
                return s.show
            })
        },
            FourSeconds(){
                let date = new Date()
                date.setSeconds(date.getSeconds() + 4)
                return date
            },
        
           
    },
    mounted () {
     
    },
    created(){
        
       
    },
    methods: {
      OpenNotification(s){
        console.log(s)
        let notificationobj = this.Notifications.find(obj => obj.id === s.notificationid)
        console.log(notificationobj)
        if(!notificationobj || notificationobj && !notificationobj.IsLiason){
          this.$router.push(notificationobj.Path)
        }
        else{
          let payload = {
            ActiveSessionid: notificationobj.topicid,
            ActiveRecord: notificationobj.clientrecords,
            IsLiason: true,
            ActiveProcessNumber: notificationobj.ActiveProcessNumber
          }
          console.log(payload)
          this.$emit('TriggerDashboardbyNotification',payload)
        }
      },
      MarkasRead(s){
        db.collection('notifications').doc(s.notificationid).update({
                Read: true
              })
              this.hide(s)
      },
        CheckSnack(s){
            console.log('check')
        },
        
        calcMargin(i) {
            return '-'+(i*170) + 'px'
        },
        hide(s){
            console.log('hiding')
            s.show = false
        },
        CloseSnackbar(){
         this.$emit('CloseSnackbar')   
        }
        
    }
}
</script>

<style>
.windowcard{
    position: fixed;
    bottom: 50px;
    right: 10px;
    z-index: 4;
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.windowcarditem{
   border-style: solid;
    border-color: rgb(204, 203, 203);
   border-width: thin;
   color: currentColor;
}
.loading-dialog{
 background-color: #c8e1f0b6 
}
</style>
    