
<template>
<v-card class="transparent stickytopconscious" width="100%" height="100%" style="padding-bottom:60px;">
<v-layout column class="createnotice-container" v-if="userisContentAdmin">
    <v-icon @click="AddSocialItemtoNotice()" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
  </v-layout>

<SocialSharing v-if="userLoggedIn" :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="userLoggedIn" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>

    <v-parallax
            height="150"
            :src="GroupData.logo"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Blog.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-layout class="justify-center" v-if="!EditingBlog">
              <v-card class="my-3 mx-3" hover width="90%" >
                  <v-card-actions style="font-size:10px">
                  <v-chip small color="secondary" class="white--text"  v-if="UserRecord.id">
                    {{author}}
                  </v-chip>
                  
                  <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator"
                                 v-if="UserRecord.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="pop" class="white--text" v-on="on" v-if="UserRecord.id">
                              {{PubStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <v-list-item class="green" @click="CheckStatus('Draft')">
                            Draft
                          </v-list-item>
                          <v-list-item class="warning" @click="CheckStatus('Published (Internal)')">
                            Published (Internal)
                          </v-list-item>
                          <v-list-item  class="error" @click="CheckStatus('Published (Public)')">
                            Published (Public)
                          </v-list-item>
                        </v-list>
                  </v-menu>
                 <v-spacer></v-spacer>
                  <span  v-if="createdon && createdon.toDate && UserRecord">{{createdon.toDate()}}</span>
                  <v-icon @click="EditItem()" v-if="UserIsModerator || userIsAdmin">mdi-pencil</v-icon>
                </v-card-actions>
                <v-img
                  height="350px"
                  v-bind:src="image"
                  v-if="image"
                ></v-img>
                        <v-card-title class="headline">{{ title }}</v-card-title>
                            
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{blogpubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="blogcontent">
                </v-card-text>
                <v-card-actions>
                  
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <!-- <v-spacer></v-spacer>
                  <v-btn @click="ShareBlog()">Share<v-icon>mdi-share-circle</v-icon></v-btn> -->
                </v-card-actions>
                <v-card-actions>
                  <TaggingComponent v-if="userLoggedIn" :AppisDarkModeCard="AppisDarkModeCard" :UsercanTag="UserIsModerator" :record="FullArticleData" />
                </v-card-actions>

                 <SocialCommentsandLikes v-if="FullArticleData && FullArticleData.Comments && FullArticleData.Likes && userLoggedIn"  :panel="0"
            :PathName="'Blog'"  :GroupID="$route.name === 'GroupBlog' ? $route.params.id : ''" :CanSocial="UserCanSocialTopic" :notificationitem="'Blog'" :RelatedType="'Site Blog'" :SocialItem="FullArticleData" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
              </v-card>
              </v-layout>
      <v-layout class="justify-center" v-if="EditingBlog">
        <EditBlog :title="title" :pubdatastring="pubdatastring" :CurrentModerators="Moderators"
        @StopEdit="StopEdit" :CurrentImage="image" :FullArticleData="FullArticleData"
        :SiteModerators="SiteModerators"  :caption="caption"
        :CurrentImageShy="ImageShy" :Currentblogcontent="blogcontent"/>
      </v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import EditBlog from '@/components/Blog/SiteBlog/Edit/Single'
import TaggingComponent from '@/components/System/Tagging/TaggingComponent';
import SocialSharing from '@/components/System/SocialSharing'
import SocialCommentsandLikes from '@/components/System/SocialCommentsandLikes';

export default {
    props: ['SiteModerators','FriendsList'],
      components: {
        EditBlog,
        SocialSharing,
        SocialCommentsandLikes,
        TaggingComponent
  },
    data() {
        return {
          ShareItemDialog: false,
          InternalLinkType: '',
          LinkComponent: '',
          LinkID: '',
          SharedCollection: '',
          NoticeCoverIMGProp: 'image',
          FullArticleData: '',
          PublicBlogs: [],
          SiteModerators: [],
         Moderators: [],
            blogquil: false,
            snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        blogdialog: false,
        blogcontent: '',
        PubStatus: '',
        UserIsNOTModerator: true,
        
        IsAuthor: false,
        author: null,
        authorid: null,
        //userid: this.$store.state.user.id,
        groupblogs: [],
        groups: [],       
        blogid: this.$route.params.id,
        content: null,
        image: '',
        ImageShy: false,
        createdon: '',
        blogpubDate: '',
        UserCanEdit: false,
        BlogPrivacy: null,
        PublishStatus: null,
        BlogPrivacyTypes: [
        {index: 0, text: 'Private'},
        {index: 1, text: 'Members Only'},
        {index: 2, text: 'Public'},
          ],
        PublishStatusTypes: [
        {index: 0, text: 'Draft'},
        {index: 1, text: 'Published'},
          ],  
        title: null,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                BlogPrivacy: null,
                PublishStatus: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                BlogPrivacy: null,
                PublishStatus: null,                
            },
            UserRecord: {},
            GroupData: {},
            EditingBlog: false,    
        }
    },
    computed: {
      userisContentAdmin(){
        return this.userIsAdmin
      },
      UserCanSocialTopic(){
        if(this.$route.name === 'GroupBlog'){
          let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
          return memberobj
        }
        else{
          return this.userLoggedIn
        }
      },
      UserIsModerator(){
          let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
          return this.authorid === this.UserRecord.id || UserModeratorobj
        },
      RouteName(){
          return this.$route.name === 'GroupBlog' ? this.GroupData.name+' Blog' : 'GP Combrink Vehicle Sales & Finance'
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        RelatedBlogs () {
            return this.groupblogs.filter(blog => {
                return blog.blogid === this.blogid
            })
        },
            
    },
    
    created() {
        document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
      if(this.$route.name === 'GroupBlog'){
          this.InternalLinkType = 'Group-Blog/'+this.$route.params.id+'/Blog'
          this.SharedCollection = 'groupblogs'
          this.LinkComponent = 'GroupBlogShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else{
          this.InternalLinkType = 'Blog'
          this.SharedCollection = 'siteblogs'
          this.LinkComponent = 'SiteBlogShared'
          this.LinkID = this.$route.params.id
        } 
      this.GetRequestingUser()
        
        
        
    },
    methods: {
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.LinkID,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this[this.NoticeCoverIMGProp]
        }      
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      StopEdit(){
        this.EditingBlog = false
      },
      EditItem(){
        this.EditingBlog = true
      },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        db.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      CheckStatus(status){
        if(status === 'Draft'){
          confirm('this will update the Blog for all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Internal)'){
          confirm('this will make the Blog avialable for viewing by all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Public)'){
          confirm('this will make the Blog available for viewing by the Public. Do you want to continue?') && this.UpdateStatus(status)
        }
      },
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
      UpdateStatus(status){
          let col = ''
          let wikicol = ''
          let docid = ''
          if(this.$route.name === 'GroupBlog'){
            col = 'groupblogs'
            wikicol = 'groupblogswiki'
            docid = this.$route.params.slug
          }
          else{
            col = 'siteblogs'
            wikicol = 'siteblogswiki'
            docid = this.$route.params.id
          }
        db.collection(col).doc(docid).update({
          PublishStatus: status
        })
        if(status === 'Published (Internal)'){
          const systemactivity = {
                            user: this.UserRecord,
                            contentvar: 'published an Internal Blog',
                            location: '/Blog/',
                            docname: this.title,
                            docid: docid,
                            type: 'New Blog',
                          }                          
                          this.$store.dispatch('createSystemActivity',systemactivity)
                           
        }
        else if(status === 'Published (Public)' && this.coverimage || status === 'Published (Public)' && this.image){
          db.collection(wikicol).doc(docid).set({
            tags: [],
            title: this.title,
            UpdatingUser: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            UpdatingUserid: this.UserRecord.id,
            author: this.author,
            authorid: this.authorid,
            content: this.blogcontent,
            caption: this.caption,
            updatedon: new Date(),
            pubDate: this.DateFormatter(this.pubdatastring),
            Comments: [],
            ImageShy: this.ImageShy,
            PublishStatus: status,
            coverimage: this.image,
            PublicURL: this.title.split(' ').join('-')
          })     
          const systemactivity = {
                            user: this.UserRecord,
                            contentvar: 'published a Public Blog',
                            location: '/Blog/',
                            docname: this.title,
                            docid: docid,
                            type: 'New Blog',
                          }                          
                          this.$store.dispatch('createSystemActivity',systemactivity)    
        }
        else if(status === 'Published (Public)' && !this.coverimage || status === 'Published (Public)' && !this.image){
          db.collection(wikicol).doc(docid).set({
            tags: [],
            title: this.title,
            UpdatingUser: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            UpdatingUserid: this.UserRecord.id,
            author: this.author,
            authorid: this.authorid,
            content: this.blogcontent,
            updatedon: new Date(),
            pubDate: this.DateFormatter(this.pubdatastring),
            Comments: [],
            ImageShy: this.ImageShy,
            PublishStatus: status,
            PublicURL: this.title.split(' ').join('-')
          })     
          const systemactivity = {
                            user: this.UserRecord,
                            contentvar: 'published a Public Blog',
                            location: '/Blog/',
                            docname: this.title,
                            docid: docid,
                            type: 'New Blog',
                          }                          
                          this.$store.dispatch('createSystemActivity',systemactivity)     
        }
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetGroup(){
            db.collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                this.GroupData = snapshot.data()
                // this.GetForumDiscussion()
            })
        },
        async GetRequestingUser(){
        let vm = this;
        console.log("I try assign this.UserRecord")
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          vm.FocusedViewToggle()
      vm.IntranetViewToggle()
            console.log('let us see now, yes the user is signed in')
            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              
              vm.getBlogInformation()
              console.log(vm.UserRecord)
            })
        }
        else{
          vm.getPublicBlogInformation()
            console.log('nope the user is apprantely NOT signed in')
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
        addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },

    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'blogcontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
            UserBlogrelation(){
              console.log('checking rleation to blogs')
              console.log(this.authorid)
              console.log(this.Moderators)
              console.log(this.UserRecord)
                if(this.authorid === this.UserRecord.id) {
            this.UserCanEdit = true
            this.IsAuthor = true
            
            this.UserIsNOTModerator = false
            this.GetSiteModerators()
                }
                else {
            this.UserCanEdit = false
            let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
            if(UserModeratorobj){
              let vm = this
              this.GetSiteModerators().then(function(result) {
                  if(result){
                    console.log('checking if moderators')
                    let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
                    if(UserModeratorobj){
                      if(UserModeratorobj.ModerationPermissions.includes('Public Blogs')){
                        vm.UserIsPublicModerator = true
                      }
                      
                    }
                  }
                })
              
              this.UserIsNOTModerator = false
            }
                }
            },
        UpdateBlog(){
          console.log(this.editor.scrollingContainer.innerHTML)

            const UpdatedBlog = {
                content: this.editor.scrollingContainer.innerHTML
            }

            db.collection('groupblogs').doc(this.blogid).update({
                content: UpdatedBlog.content
            });
              this.snackbar = true
            this.blogquil = false
        },
                UpdateBlogSettings(){


            const UpdatedBlog = {
                PublishStatus: this.editedItem.PublishStatus,
                BlogPrivacy: this.editedItem.BlogPrivacy
            }

            db.collection('groupblogs').doc(this.blogid).update({
                PublishStatus: UpdatedBlog.PublishStatus,
                BlogPrivacy: UpdatedBlog.BlogPrivacy
            });
              this.snackbar = true
            this.blogquil = false
        },
        onEditorChange() {
// alert('change')
        },
        getPublicBlogInformation(){
          let vm = this
          return new Promise(function(resolve, reject) {
        db.collection('siteblogswiki').where('PublishStatus','==','Published (Public)').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.PublicBlogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        }).then(function(result) {
          let blogcollection = db.collection('siteblogswiki').where('PublishStatus','==','Published (Public)')
          
          blogcollection.onSnapshot(snapshot => {
                  // this.UpdateRequestStatus()
                  var blogdata = vm.PublicBlogs.find(obj => obj.PublicURL == vm.$route.params.id)
                console.log(blogdata)
              
                vm.title = blogdata.title
                vm.content = blogdata.content
                vm.blogcontent = blogdata.content
                vm.author = blogdata.author
                vm.authorid = blogdata.authorid
                vm.PubStatus = blogdata.PublishStatus
                vm.ImageShy = blogdata.ImageShy
                vm.image = blogdata.coverimage
                vm.createdon = blogdata.createdon
                vm.pubdatastring = format(blogdata.pubDate.toDate(),'yyyy-MM-dd')
                vm.blogpubDate = vm.TimestampFormatterSTRINGDayText(blogdata.pubDate.toDate())
                vm.PublishStatus = blogdata.PublishStatus
                vm.BlogPrivacy = blogdata.BlogPrivacy
                vm.editedItem.BlogPrivacy = blogdata.BlogPrivacy
                vm.editedItem.PublishStatus = blogdata.PublishStatus
                })

        })
        },
    GetSocialComments(docid){
          db.collection('socialsitecomments').where('relatedid','==',docid).onSnapshot(res => {
            if(!this.FullArticleData.Comments){
             this.FullArticleData.Comments = [] 
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  Replies: [],
                  id: change.doc.id
                }
                this.GetSocialLike(commentobj.id,commentobj)
                let oncheck = this.FullArticleData.Comments.find(obj => obj.id === commentobj.id)
                if(!oncheck){
                    this.FullArticleData.Comments.push(commentobj)
                  }
                db.collection('socialsitereplies').where('relatedid','==',commentobj.id).onSnapshot(repliesres => {
                    const replychanges = repliesres.docChanges();
                    replychanges.forEach(repchange => {
                    if (repchange.type === 'added') {
                      let replyobj = {
                        ...repchange.doc.data(),
                          Replies: [],
                          id: repchange.doc.id
                        }
                        this.GetSocialLike(replyobj.id,replyobj)
                        let reponcheck = commentobj.Replies.find(obj => obj.id === replyobj.id)
                        if(!reponcheck){
                         commentobj.Replies.push(replyobj) 
                        }
                      }
                    })
                })
                
              }
            })
          })
        },
    GetSocialLike(docid,docobj){
      db.collection('socialsitelikes').where('relatedid','==',docid).onSnapshot(res => {
            if(!docobj.Likes){
              docobj.Likes = []
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                 let oncheck = docobj.Likes.find(obj => obj.id === commentobj.id)
                  if(!oncheck){
                    docobj.Likes.push(commentobj)
                  } 
               
              }
            })
          })
    },
    async getBlogInformation() {
        let blogcollection = db.collection('siteblogs')
        let docid = this.$route.params.id
        if(this.$route.name === 'GroupBlog'){
          blogcollection = db.collection('groupblogs')
          docid = this.$route.params.slug
        }
        let docref = blogcollection.doc(docid)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var blogdata = snapshot.data()
          this.FullArticleData = blogdata
          this.FullArticleData.id = docid
          this.GetSocialComments(docid)
          this.GetSocialLike(docid,this.FullArticleData)
        console.log(blogdata)
        if(blogdata.caption){
          this.caption = blogdata.caption
        }
        this.Moderators = blogdata.moderatorrolesarrayQuery
        this.title = blogdata.title
        this.content = blogdata.content
        this.blogcontent = blogdata.description
        this.author = blogdata.author
        this.authorid = blogdata.authorid
        this.PubStatus = blogdata.PublishStatus
        if(typeof blogdata.ImageShy !== 'undefined'){
          this.ImageShy = blogdata.ImageShy
        }
        if(typeof blogdata.coverimage !== 'undefined'){
          this.image = blogdata.coverimage
        }
        this.createdon = blogdata.createdon
        this.pubdatastring = format(blogdata.PublishDate.toDate(),'yyyy-MM-dd')
        this.blogpubDate = this.TimestampFormatterSTRINGDayText(blogdata.PublishDate.toDate())
        this.PublishStatus = blogdata.PublishStatus
        this.BlogPrivacy = blogdata.BlogPrivacy
        this.editedItem.BlogPrivacy = blogdata.BlogPrivacy
        this.editedItem.PublishStatus = blogdata.PublishStatus
      console.log('blog id is '+this.blogid)
        console.log('user id is '+this.UserRecord.id)
        console.log('author id is '+this.authorid)
        this.UserBlogrelation()
        })
    
        },
      OpenBlogdialog() {
        this.blogdialog = true
    },
    Closedialog() {
        this.blogdialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
     save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.BlogPrivacy = this.editedItem.BlogPrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('groupblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      },
      
   
     saveBlogSettings() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.BlogPrivacy = this.editedItem.BlogPrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('groupblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {
align-content: center;
margin-left: 0.5em
}
    
</style>
    

    