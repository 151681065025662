
<template>

<v-card :color="tab.RowColor" :class="tab.RowTransparency" tile  width="100%" flat v-if="tab.Name === 'Parallax'" id="parallax">
    <!-- <v-container class="grey lighten-5"> -->
   <v-parallax
        
        :height="tab.Height"
        :src="tab.IMG"
        :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'+CheckifClipped(tab)+';'"
      >   
      <v-card class="transparent" flat tile :style="tab.ParallaxGradient" height="100%" width="100%">
      <v-container class="fill-height" v-if="tab.Elements">
   
          
        <v-row
            
            >
           
            <v-col
                v-for="(elmnt,index) in tab.Elements"
                :key="elmnt.itemObjKey" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                cols="12"                            
            >   
            <v-card
                class="pa-2"
                tile
                outlined
                v-if="elmnt.Name === 'blank'"
                @click="OpenContentTypesDialog(elmnt,index,true,rowindex)"
                >
                Row {{rowindex-1+2}}-Element-{{ elmnt.Name }}
            </v-card>
            <TitleTab 
                v-if="elmnt.Name === 'Title' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
                <IFRAMETab  v-if="tab.Name === 'IFRAME' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"
                        />
            
                <GoogleMyMapsTab 
                v-if="elmnt.Name === 'Google My Maps' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
                <HTMLBox 
                v-if="elmnt.Name === 'HTML Box' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
                <v-icon v-if="elmnt.Name === 'Web Form'" @click="OpenContentTypesDialog(elmnt,index,true,rowindex)">mdi-cogs</v-icon>
                <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" :i="i"
                />
            </v-col>
            </v-row>

    
      </v-container>
      </v-card>
   </v-parallax>
   
                    </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTab';

import GoogleMyMapsTab from '@/components/Campaigns/LandingPages/GoogleMyMapsTab';
import IFRAMETab from '@/components/Campaigns/LandingPages/IFRAMETab';
import TitleTab from '@/components/Campaigns/LandingPages/TitleTab';
import HTMLBox from '@/components/Campaigns/LandingPages/HTMLBox';
import ImageTab from '@/components/Campaigns/LandingPages/ImageTab';
import ParallaxTab from '@/components/Campaigns/LandingPages/ParallaxTab';
import ClippedTab from '@/components/Campaigns/LandingPages/ClippedTab';
import GalleryTab from '@/components/Campaigns/LandingPages/GalleryTab';

export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex'],
    components: {
        
        WebFormTab,
        TitleTab,
        HTMLBox,
        ImageTab,
        ParallaxTab,
        ClippedTab,
        GalleryTab,
        IFRAMETab,
        GoogleMyMapsTab
        },
    data() {
    return {
        justify: [
        'start',
        'center',
        'end',
        'space-around',
        'space-between',
      ],      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      ParallaxWidth(){
          var element = document.getElementById('parallax');
          if(element){
             var positionInfo = element.getBoundingClientRect(); 
             console.log(element)
             console.log(positionInfo)
             if(positionInfo){
                 var height = positionInfo.height;
                var width = positionInfo.width;
                if(width){
                   return width  
                }
               

             }
          }
        
        
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        OpenContentTypesDialog(elmnt,elmntindex,isrow,rowindex){
           
            this.$emit('OpenContentTypesDialog',elmnt,elmntindex)
            // this.OpenDialog(elmnt,elmntindex)
            this.$emit('OpenDialog',elmnt,elmntindex,true,this.i,isrow,rowindex)
        },
        ClassCheck(tab){
           var newclass = ''
           
            if(tab.Transparent){
                newclass = newclass+' red'
                tab.Color = '#ffffff'
            }
             if(tab.Clipped){
                newclass = newclass+' clipped'
            }
            return newclass
        },
     OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex){
         if(isrow){
             this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex)
         }
         else{
            this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
        
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>
.parallaxheader{
  align-items: start;
  bottom: 0;
  justify-content: center;
    height: 100%;
  width: 100%;
  z-index:10
}

</style>

        


    