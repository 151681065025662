
<template>
<v-card class="transparent stickytopconscious" flat width="100%" height="100%" style="padding-bottom:50px;">
    <v-dialog v-model="UploadingMultipleSharedDocuments" max-width="500px">
                   <v-card width="100%" height="100%">
                     <v-card-title>Uploading Multiple Files</v-card-title>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="shareddocumentsprogressbar" style="display:none">
                            <ul id="shareddocumentsprogressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                   </v-card>
                 </v-dialog>
    <v-parallax
            height="150"
            src="@/assets/RABaseBG.jpeg"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">New Notice</h4>
              </v-col>
            </v-row>
            </v-parallax>     
               
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Notice.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
    <v-flex xs12 s12 m10 lg10>
    <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Notice</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="ArticleTitle">
                </v-text-field>                
                    
                    <v-select :items="Categories" :readonly="ItemLocked" item-text="Name" v-model="Category" return-object label="Category"/>
                   <v-card-text v-if="Category" style="padding-top:20px;" >
                        <SiteArticles v-if="Category.Name === 'Site Article' && !ItemLocked" @AddSocialItemtoNotice="AddSocialItemtoNotice" :FeedView="true" :NoticeSelect="true" />
                        <SiteBlogs v-if="Category.Name === 'Site Blog' && !ItemLocked" @AddSocialItemtoNotice="AddSocialItemtoNotice" :FeedView="true" :NoticeSelect="true" />
                    </v-card-text>
                    <v-card-text v-if="SocialShareItem && SocialShareItem.IsLink && SocialShareItem.InternalLink && SocialShareItem.ComponentName">
                      <SocialPostSharedItem :LinkID="SocialShareItem.LinkID" :collectionname="SocialShareItem.collectionname" :UserRecord="UserRecord" :Location="SocialShareItem.InternalLinkType"/>
                    </v-card-text> 
                <v-menu
                        v-model="PublishDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="PublishDate">
                        <template v-slot:activator="{ on }">
                          <v-text-field                            
                            v-model="PublishDate"
                            label="Publish Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="PublishDate"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="PublishDate" @input="PublishDatemenu = false"></v-date-picker>
                      </v-menu>
                      <v-checkbox label="Has Due Date" v-model="HasDueDate"/>
                      <v-menu
                      v-if="HasDueDate"
                        v-model="DueDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="DueDate">
                        <template v-slot:activator="{ on }">
                          <v-text-field                            
                            v-model="DueDate"
                            label="Due Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="DueDate"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="DueDate" @input="DueDatemenu = false"></v-date-picker>
                      </v-menu>
                    
                <v-checkbox label="Is Image Shy" v-model="ImageShy"/>
                 <v-checkbox label="Skip Writeup"    v-model="SkipWriteup"/>
                
                <input
                v-if="!ImageShy"
            type="file"
            @change="onarticlecoverimageselect($event)"
            ref="coverimageinputter">
           
                </v-card-text>
                <v-layout class="justify-center" v-if="!SkipWriteup">
                
          <v-img v-if="coverimagelocalurl" :src="coverimagelocalurl">
          </v-img>
                </v-layout>
          <v-card-text  v-if="!SkipWriteup">
              <v-layout class="justify-center">
          <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <!-- <v-select @click="getDocumentSelection()" :items="[2,4,6,8,10,12,14,16,18,20]" label="Size" @change="ChangefontSize()" v-model="fontsize">                        
                          </v-select> -->
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>                      
                        <!-- </v-btn-toggle>
                        <v-btn-toggle> -->
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <!-- <v-btn>
                            <v-icon>mdi-format-align-center</v-icon>
                          </v-btn> -->
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
          </v-layout>
              <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          <v-btn onclick="document.execCommand('undo',false,'')">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('redo',false,'')">
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                          
                          <v-btn onclick="document.execCommand('bold', false, '');">
                            <v-icon>mdi-format-bold</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('italic', false, '');">
                            <v-icon>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('underline', false, '')">
                            <v-icon>mdi-format-underline</v-icon>
                          </v-btn>
                          <v-btn @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <v-btn  onclick="document.execCommand('selectAll',false,'')">
                            <v-icon>mdi-select-all</v-icon>
                          </v-btn>
                               <!-- <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">             
                          <v-btn v-on="on">
                            
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>    
                          
                                </template> -->
                                
                          <!-- <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" style="display: none;" @change="getImage()">

                                <label for="file" class="tool-items fa fa-file-image-o"></label> -->
                               <!-- </v-menu>                   -->
                          <v-btn>
                            <v-icon @click="link()">mdi-link</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="linkyoutubevideo()">mdi-youtube</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="insertiframe()">mdi-iframe</v-icon>
                          </v-btn>
                          
                          <v-btn>
                            <v-icon @click="addphone()">mdi-phone</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="addemail()">mdi-email</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                            <v-icon>mdi-format-strikethrough</v-icon>
                          </v-btn>
                        </v-btn-toggle> 
                        
          </v-layout>
                <div contenteditable id="NewNoticeValue" class="postinputbox"/>
                
            </v-card-text>
            <v-layout class="justify-center" v-if="ContentisEmbeddedDocument && DocumentSource">
                    <embed width="80%" height="700" :src="DocumentSource"/>
                </v-layout>
            <v-card-actions>    
            <v-textarea label="Caption" v-model="Caption" :rules="[rules.max100Chars]" />
            </v-card-actions>
            <v-card-actions>    
               <v-layout row class="align-center mx-2"  v-if="AttachmentsArray.length>0">
                   <v-list two-line subheader light align="left" width="100%"><span v-for="(item,i) in AttachmentsArray" :key="item.itemObjKey">                     
                      <v-list-item class="basicoutlined">
                        <v-layout row class="justify-start">
                          <v-list-item-avatar>
                            <v-icon>mdi-file-document</v-icon>
                            </v-list-item-avatar>
                           <v-list-item-content>
                                <v-list-item-title>{{item.Name}}</v-list-item-title>
                            </v-list-item-content>                             
                              <v-list-item-action>                               
                                    <v-btn icon :href="item.Path" target="_New">
                                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                                  </v-btn>  
                                   <v-btn icon @click="DeleteAttachment(item,i)" target="_New">
                                    <v-icon color="red">mdi-delete-forever</v-icon>
                                  </v-btn>                             
                              </v-list-item-action>                           
                          </v-layout>
                        </v-list-item></span>
                    </v-list>
                </v-layout>
             <v-card v-if="AttachmentsArray.length === 0" flat width="60%" @dragover.prevent @drop.prevent="UploadFiles($event, FilterType)" id="drop-area">
                <v-layout class="justify-center">
                  <v-card-title class="grey--text">
                      Drop any attachment files in here
                  </v-card-title>
                </v-layout>
              </v-card>
            </v-card-actions>
            <v-card-actions>                
                <v-select multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>       
                        
            </v-card-actions>
            <v-card-actions>                
                <v-checkbox label="Must Read" v-model="MustRead"/> 
                <v-checkbox label="Must Confirm" v-model="MustConfirm"/>      
                        
            </v-card-actions>
            <v-card-actions>
               
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StoreNotice()">
                    Post
                </v-btn>
                
            </v-card-actions>
        </v-card>
        </v-layout>
   
    
   </v-flex>
</v-layout>
 <v-layout column class="fab-container" v-if="NoticeData">      
           <v-icon @click="DeleteNotice()"  color="red" x-large>mdi-delete-forever</v-icon>
  </v-layout>
</v-card>
</template>


<script>
import firebase, { storage } from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SocialPostSharedItem from '@/components/System/SocialPostSharedItem';

import SiteArticles from '@/components/Articles/SiteArticle/SiteArticles';
import SiteBlogs from '@/components/Blog/SiteBlog/SiteBlogs';

export default {
    props: ['RelatedLink','RelatedRecord','ContentisEmbeddedDocument','ContentDeterminesCategory','NoticeItem'],
        middleware: 'auth',
      components: {
        SiteArticles,
        SiteBlogs,
        SocialPostSharedItem
  },
  
    data() {
        return {
          ItemLocked: false,
          ChangingItem: true,
          SocialShareItem: '',
          AttachmentsArray: [],
          selectedSharedDocumentsFileArray: [],
          SharedDocumentstotallist: 0,
          UploadingMultipleSharedDocuments: false,
          SharedDocumentstotallist: 0,
          SharedDocumentslistuploadcount: 0,
          SharedDocumentsuploadTotal: 0,
          SharedDocumentsrunningpercentage: 0,
          SharedDocumentsrunningProgress: 0,
          SharedDocumentsuploadPercentage: 0,
          NoticeData: '',
            Categories: [
                {
                Name: 'General',
                ID: 1000000,
                Icon: 'mdi-eye'
                },
                {
                Name: 'Site Event',
                ID: 1000001,
                Icon: 'mdi-eye'
                },
                {
                Name: 'Site Poll',
                ID: 1000002,
                Icon: 'mdi-eye'
                },
                {
                Name: 'Site Article',
                ID: 1000003,
                Icon: 'mdi-eye'
                },
                {
                Name: 'Site Group',
                ID: 1000004,
                Icon: 'mdi-eye'
                },
                {
                Name: 'Site Forum',
                ID: 1000005,
                Icon: 'mdi-eye'
                },
                {
                Name: 'Site Blog',
                ID: 1000006,
                Icon: 'mdi-eye'
                },
                
                {
                Name: 'Company Document',
                ID: 1000007,
                Icon: 'mdi-eye'
                },
            ],
            Category: '',
            HasDueDate: false,
            DueDate: '',
            DueDatemenu: false,
            DocumentSource: '',
          Caption: '',
          UsersArray: [],
          SiteModerators: [],
          Moderators: [],
          PublishDate: '',
          PublishDatemenu: false,
          ArticleTitle: '',
          ImageShy: false,
          MustRead: false,
          MustConfirm: false,
          SkipWriteup: false,
            GroupData: '',
        selectedFile: '',
        ImagelocalURL: '',
        ArticleContent: '',
        articlequil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        articledialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      articletitle: null,
        articlecontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        grouparticles: [],
        groups: [],
        articleid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        coverimage: '',
        coverimageThumbURL: '',
        image: '',
        Youtubelink: '',
        
        rules: {
        max100Chars: value => value.length < 101 || "Max. 100 characters",
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            }    
        }
    },
    computed: {
      RouteName(){
          return this.$route.name === 'GroupNewNotice' ? this.GroupData.name+' Article' : 'GP Combrink Vehicle Sales & Finance'
        },
       userLoggedIn () {
      return this.$store.getters.user
    },
        ModeratorsIDStrings(){
          return this.Moderators.map(mod => {
            mod.id
          })
        },
        ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="560" height="315" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="560" height="315" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
        RelatedArticles () {
            return this.grouparticles.filter(article => {
                return article.articleid === this.articleid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    
    created() {
       document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.NoticeItem){
      this.ItemLocked = true
      if(this.NoticeItem.InternalLinkType.includes('Article')){
        this.Category = this.Categories.find(obj => obj.Name === 'Site Article')
      }
      else if(this.NoticeItem.InternalLinkType.includes('Blog')){
        this.Category = this.Categories.find(obj => obj.Name === 'Site Blog')
      }
      else if(this.NoticeItem.InternalLinkType.includes('Event')){
        this.Category = this.Categories.find(obj => obj.Name === 'Site Event')
      }
      else if(this.NoticeItem.InternalLinkType.includes('Forum')){
        this.Category = this.Categories.find(obj => obj.Name === 'Site Forum')
      }
      else if(this.NoticeItem.InternalLinkType.includes('Poll')){
        this.Category = this.Categories.find(obj => obj.Name === 'Site Poll')
      }
      else if(this.NoticeItem.InternalLinkType.includes('Group')){
        this.Category = this.Categories.find(obj => obj.Name === 'Site Group')
      }
      this.AddSocialItemtoNotice(this.NoticeItem)
    }
        // this.getArticleInformation()
        // this.getUserInformation()
        this.FocusedViewToggle()
      this.IntranetViewToggle(true)
      this.GetRequestingUser()
      this.GetSiteModerators()
      

    },
    
    methods: {
       AddSocialItemtoNotice(item){
        this.ChangingItem = true
        this.SocialShareItem = ''
        this.SocialShareItem = item
        if(item.coverimage){
          this.coverimage = item.coverimage
          if(item.coverimageThumbURL){
          this.coverimageThumbURL = item.coverimageThumbURL
          }
          this.coverimagelocalurl = item.coverimage
        }
        setTimeout(() => {
          this.ChangingItem = false
        }, 500);
        
      },
      DeleteNotice(){
        confirm('this will remove the Notice, are you sure?') && this.ProcessDeleteNotice()
     },
     ProcessDeleteNotice(){
       db.collection('notices').doc(this.$route.params.id).delete().then(doc => {
         this.$router.push('/')
       })
     },
     DeleteAttachment(item,index){
       confirm('this will remove the item from the Notice, are you sure?') && this.ProcessDeleteAttachment(item,index)
     },
     ProcessDeleteAttachment(item,index){
       let vm = this
       console.log(item.StorageRef)
       let itemref = firebase.storage().ref(item.StorageRef);
        itemref.delete().then(function() {                  
          // File deleted successfully    
         
          console.log(vm.AttachmentsArray)  
          vm.AttachmentsArray.splice(index,1)
          db.collection('notices').doc(vm.$route.params.id).update({
            AttachmentsArray: vm.AttachmentsArray
          })
        }).catch(function(error) {                  
          // Uh-oh, an error occurred!                  
        }); 
    },
      GetSiteModerators(){
        db.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          
        })
      },
   DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
      UploadFiles(event,Type){
          this.selectedSharedDocumentsFileArray = event.target.files || event.dataTransfer.files
          this.UploadingMultipleSharedDocuments = true
          setTimeout(() => {
          this.SharedDocumentstotallist = this.selectedSharedDocumentsFileArray.length
          this.SharedDocumentsuploadTotal = this.selectedSharedDocumentsFileArray.length*100
          const topelmnt = document.getElementById('shareddocumentsprogressbar')
          topelmnt.style.display = "block";
          const elmnt = document.getElementById('shareddocumentsprogressgroupbar')      
          const totalprogresselementptag = document.createElement('p')
          totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
          totalprogresselementptag.setAttribute('id','shareddocumentstotalprogressbarptag')
          const totalprogresselement = document.createElement('progress')
          totalprogresselement.setAttribute('id','shareddocumentstotalprogressbar')
          totalprogresselement.setAttribute('max','100')
          totalprogresselement.setAttribute('value',1)
          var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.SharedDocumentstotallist+' files uploaded');
          totalprogresselementptag.appendChild(startingtotaltext);
          elmnt.append(totalprogresselement) 
          elmnt.append(totalprogresselementptag)
          Array.from(this.selectedSharedDocumentsFileArray).map(file => {  
              this.saveDROP(file,Type)
          })
          },300)
        },
    saveDROP(file,Type){
          
          
          this.onDocument_URLUpload(file)
      },
    onDocument_URLUpload(uploadfile) {
        let filename = uploadfile.name.split('.')[0]+'_'+uploadfile.size+'.'+uploadfile.name.split('.')[1]
        let NewFile = {}
        const totalprogresselement = document.getElementById('shareddocumentstotalprogressbar')
        const totalprogresselementptag = document.getElementById('shareddocumentstotalprogressbarptag')
        const elmnt = document.getElementById('shareddocumentsprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)
        let tempStorageRef = 'Notices/'+this.$route.params.id+'/'+new Date()+'/'+filename
        var storageRef = firebase.storage().ref(tempStorageRef);
        var uploadTask = storageRef.put(uploadfile);
        let vm = this
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.SharedDocumentsuploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.SharedDocumentsuploadPercentage)
                var textnode = document.createTextNode(vm.SharedDocumentsuploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.SharedDocumentsuploadPercentage === 100){
                  vm.SharedDocumentsrunningProgress = vm.SharedDocumentsrunningProgress+vm.SharedDocumentsuploadPercentage
                  vm.SharedDocumentsrunningpercentage = Number(((vm.SharedDocumentsrunningProgress/vm.SharedDocumentsuploadTotal)*100).toFixed(2))                  
                  vm.SharedDocumentslistuploadcount = vm.SharedDocumentslistuploadcount-1+2
                  var totaltext = document.createTextNode(vm.SharedDocumentsrunningpercentage+'% complete  - '+vm.SharedDocumentslistuploadcount+' of '+vm.SharedDocumentstotallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.SharedDocumentsrunningpercentage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            // db.collection('companydocuments').doc(doc.id).update({
            //         filename: filename,
            //         Document_URL: url,
            //         Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            //         Modified_Byid: this.UserRecord.id,
            //         Modified_On: new Date(),
            //         DocumentID: doc.id,
            // })
            NewFile.Created_By = {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
            NewFile.Created_Byid = this.UserRecord.id,
            NewFile.Path = url
            NewFile.FileType = uploadfile.type
            NewFile.StorageRef = tempStorageRef
            NewFile.Size = uploadfile.size
            NewFile.Name = filename
            this.AttachmentsArray.push(NewFile)
            if(this.SharedDocumentsrunningpercentage === 100){
              console.log(this.AttachmentsArray)
              // db.collection('notices').doc(this.$route.params.id).
                      this.UploadingMultipleSharedDocuments = false
                      this.SharedDocumentstotallist = 0
                      this.SharedDocumentsuploadTotal = 0
                      this.SharedDocumentsrunningpercentage = 0
                      this.SharedDocumentsrunningProgress = 0
                      this.SharedDocumentsuploadPercentage = 0
                      this.SharedDocumentslistuploadcount = 0
                      elmnt.style.display = "none";
                      }
           
                }),
                  this.Document_URLfilesnackbar = true



      },
    onarticlecoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        GetArticles(){
        db.collection('notices').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        
        },
        StoreNotice(){
          let vm = this
        let postelmnt = document.getElementById('NewNoticeValue')
        let postinput = ''
        console.log(postelmnt)
        if(!this.SkipWriteup){
            postinput = postelmnt.innerHTML
        }
        
        if(this.selectedcoverimage && this.ArticleTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
          console.log(this.Moderators)
          console.log(ModeratorRolesObject)
          console.log(ModeratorRolesArray)
        let NewArticle = {
          AttachmentsArray: this.AttachmentsArray,
          Likes: [],
          Shares: [],
          tags: [],
          title: this.ArticleTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          Owner: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Ownerid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
          caption: this.Caption,
          ImageShy: false,
          MustRead: this.MustRead,
          MustConfirm: this.MustConfirm,
          SkipWriteup: this.SkipWriteup,
          Category: this.Category
          
        }
        if(this.SocialShareItem){
          NewArticle.SocialShareItem = this.SocialShareItem
        }
        if(this.RelatedRecord){
            NewArticle.RelatedRecord = this.RelatedRecord
        }
        if(this.RelatedLink){
            NewArticle.RelatedLink = this.RelatedLink
        }
        if(this.ContentisEmbeddedDocument && this.DocumentSource){
            NewArticle.ContentisEmbeddedDocument = this.ContentisEmbeddedDocument
            NewArticle.DocumentSource = this.DocumentSource
        }
        if(this.DueDate){
          NewArticle.HasDueDate = this.HasDueDate
            NewArticle.DueDate = this.DateFormatter(this.DueDate)
        }
        
        if(this.UserRecord.Profile_Photo){
          NewArticle.creatorimg = this.UserRecord.Profile_Photo
        }
       let ref = db.collection('notices').doc()
        let newdocid = ref.id
        console.log('notices')
        this.PrepareUpdateArticleCoverImageThumbnail(this.selectedcoverimage,newdocid,NewArticle,ModeratorRolesObject,ModeratorRolesArray,postinput)
        }
        else if(this.ImageShy && this.ArticleTitle && this.SkipWriteup || this.ImageShy && this.ArticleTitle && postinput || this.coverimage && this.ArticleTitle && postinput){
           let ref = db.collection('notices').doc()
          let newdocid = ref.id
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
          console.log(this.Moderators)
          console.log(ModeratorRolesObject)
          console.log(ModeratorRolesArray)
        let NewArticle = {
          AttachmentsArray: this.AttachmentsArray,
          Likes: [],
          Shares: [],
          tags: [],
          title: this.ArticleTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          Owner: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Ownerid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          ImageShy: this.ImageShy,
          MustRead: this.MustRead,
          MustConfirm: this.MustConfirm,
          SkipWriteup: this.SkipWriteup,
          PublishStatus: 'Draft',
          caption: this.Caption,
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
          Category: this.Category
        }
        if(this.coverimage){
          NewArticle.coverimage = this.coverimage
          if(this.coverimageThumbURL){
          NewArticle.coverimageThumbURL = this.coverimageThumbURL
          }
          else{
          NewArticle.coverimageThumbURL = this.coverimage
          }
        }
         if(this.SocialShareItem){
          NewArticle.SocialShareItem = this.SocialShareItem
        }
        if(this.RelatedRecord){
            NewArticle.RelatedRecord = this.RelatedRecord
        }
        if(this.RelatedLink){
            NewArticle.RelatedLink = this.RelatedLink
        }
        if(this.ContentisEmbeddedDocument && this.DocumentSource){
            NewArticle.ContentisEmbeddedDocument = this.ContentisEmbeddedDocument
            NewArticle.DocumentSource = this.DocumentSource
        }
        if(this.DueDate){
            NewArticle.DueDate = this.DateFormatter(this.DueDate)
        }
        
        if(this.UserRecord.Profile_Photo){
          NewArticle.creatorimg = this.UserRecord.Profile_Photo
        }     
          if(typeof this.NoticeData === 'undefined'){
            db.collection('notices').doc(newdocid).set(NewArticle).then(doc => {

                let ResponseDoc = {
                    Disabled: false
                }
                db.collection('noticeconfirmations').doc(newdocid).set(ResponseDoc).then(responsedoc => {
                  vm.$router.push('/NoticeSingle/'+newdocid)
                })
                
              
                })
          }
          else{
            console.log(this.Moderators)
          console.log(ModeratorRolesObject)
          console.log(ModeratorRolesArray)
            //DueDate: this.DateFormatter(this.DueDate)
              //PublishDate: this.DateFormatter(this.PublishDate) 
              this.UpdateNotice(ModeratorRolesObject,ModeratorRolesArray,postinput)
          } 
          
               console.log(NewArticle.post)          
        }
        else if(!this.selectedcoverimage && this.ArticleTitle && postinput){
            alert('You have to upload an image')
        }
        else if(!this.selectedcoverimage && !this.ArticleTitle && postinput){
            alert('You have to upload an image, and insert a title')
        }
        else if(!this.selectedcoverimage && !this.ArticleTitle && !postinput){
            alert('There is nothing to post!')
        }
        else if(!this.selectedcoverimage && this.ArticleTitle && !postinput){
            alert('There is no cover image, or post content!')
        }
        else if(this.selectedcoverimage && !this.ArticleTitle && postinput){
            alert('You have to insert a title')
        }
        else if(this.selectedcoverimage && !this.ArticleTitle && !postinput){
            alert('You have to insert a title, and content')
        }
        else if(this.selectedcoverimage && this.ArticleTitle && !postinput){
            alert('You have to insert content')
        }
        
      
        },
        PrepareUpdateArticleCoverImageThumbnail(file,newdocid,NewArticle,ModeratorRolesObject,ModeratorRolesArray,postinput){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 1000
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref('Notices/'+newdocid+'/ArticleCover/'+file.name+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      vm.UpdateArticleCoverImage(file,thumburl,newdocid,NewArticle,ModeratorRolesObject,ModeratorRolesArray,postinput)
                    })
              })
      }
      },
      
        UpdateArticleCoverImage(picturefile,thumburl,newdocid,NewArticle,ModeratorRolesObject,ModeratorRolesArray,postinput){
          
      let vm = this
      console.log('did thumbnail now major',picturefile,thumburl,newdocid)

      var storageRef = firebase.storage().ref('Notices/'+newdocid+'/ArticleCover/'+picturefile.name);
      var uploadTask = storageRef.put(picturefile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          console.log(url)
       
                NewArticle.coverimage = url
                NewArticle.coverimageThumbURL = thumburl
                 if(typeof vm.NoticeData === 'undefined'){
                    db.collection('notices').doc(newdocid).set(NewArticle).then(doc => {
                        vm.$router.push('/NoticeSingle/'+newdocid)
                    })
                 }
                 else{
                   vm.UpdateNotice(ModeratorRolesObject,ModeratorRolesArray,postinput,url,thumburl)
                 }
               console.log(NewArticle.post)
                vm.CancelNewArticleDialog()
            
                
      })
    },
    UpdateNotice(ModeratorRolesObject,ModeratorRolesArray,postinput,url,thumburl){
      console.log(this.Moderators)
          console.log(ModeratorRolesObject)
          console.log(ModeratorRolesArray)
      db.collection('notices').doc(this.$route.params.id).update({
                AttachmentsArray: this.AttachmentsArray,
                title: this.ArticleTitle,
                caption: this.Caption,
                ImageShy: this.ImageShy,
                MustRead: this.MustRead,
                MustConfirm: this.MustConfirm,
                SkipWriteup: this.SkipWriteup,
                caption: this.Caption,
                Moderators: this.Moderators,
                moderatorrolesarrayDBRules: ModeratorRolesObject,
                moderatorrolesarrayQuery: ModeratorRolesArray,
                description: postinput

               }).then(doc => {
                 console.log(doc)
                 if(this.DueDate){
                  db.collection('notices').doc(this.$route.params.id).update({
                    DueDate: this.DateFormatter(this.DueDate)
                  })
                 }
                 if(this.PublishDate){
                   db.collection('notices').doc(this.$route.params.id).update({
                    PublishDate: this.DateFormatter(this.PublishDate)
                  })
                 }
                 if(url){
                    db.collection('notices').doc(this.$route.params.id).update({
                    coverimage: url,
                    coverimageThumbURL: thumburl
                  })
                 }
               })
    },
     
        CancelNewArticleDialog(){
            this.ArticleTitle = ''
            this.selectedcoverimage = ''
        },
      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
    insertiframe(){
        let content = `<embed width="420" height="290" :src="`+this.RelatedRecord.data.Document_URL+`"/>`
        document.execCommand("insertHTML", false, content)
    },
    linkyoutubevideo(){
        this.Youtubelink = prompt("Enter the link")
        let validation = this.rules.youtubeurl(this.Youtubelink)

        if(validation === true){
        
        // let finalembed = `<iframe width="560" height="315" src="`+this.ComputedYoutubelinkEmbed+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        document.execCommand("insertHTML", false, this.ComputedYoutubelinkEmbed)
        this.Youtubelink = ''
        }
        else{
            confirm(validation) && this.linkyoutubevideo()
        }
    },


    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'articlecontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
      GetRequestingUser(){
          var vm = this;
          if(this.userLoggedIn){
              this.UserRecord = this.userLoggedIn
              this.authorid = this.userLoggedIn.id
              this.author = this.userLoggedIn.Name+' '+this.userLoggedIn.Surname
              this.GetNotice()
          }
        
      },
      GetNotice(){
          if(this.RelatedRecord){
              
              
              this.ArticleTitle = this.RelatedRecord.recordname
              if(this.ContentisEmbeddedDocument){
                  this.DocumentSource = this.RelatedRecord.data.Document_URL                  
              }
              if(this.ContentDeterminesCategory){
                  alert('must get cateogry')
                  console.log(this.RelatedLink)
                 this.Category = this.Categories.find(obj => obj.Name === this.RelatedRecord.meta.subcat)
                 this.Category.Icon = this.RelatedRecord.meta.viewicon
                  
              }
          }

          db.collection('notices').doc(this.$route.params.id).onSnapshot(snapshot => {
            if(snapshot){
              let noticedata = snapshot.data()
               this.NoticeData = snapshot.data()
              console.log(this.NoticeData)
              if(noticedata.AttachmentsArray){
                this.AttachmentsArray = noticedata.AttachmentsArray
              }
              if(noticedata.description){
                let postelmnt = document.getElementById('NewNoticeValue')
                if(postelmnt){
                  postelmnt.innerHTML = noticedata.description
                } 
              }
              if(noticedata.caption){
                this.Caption = noticedata.caption
              }
              if(noticedata.HasDueDate){
                this.HasDueDate = noticedata.HasDueDate
              }
              if(noticedata.title){
                this.ArticleTitle = noticedata.title
              }
              if(noticedata.Category){
                this.Category = noticedata.Category
              }
              if(noticedata.ContentisEmbeddedDocument){
                this.ContentisEmbeddedDocument = noticedata.ContentisEmbeddedDocument
              }
              if(noticedata.DocumentSource){
                this.DocumentSource = noticedata.DocumentSource
              }
              if(noticedata.ImageShy){
                this.ImageShy = noticedata.ImageShy
              }
              if(noticedata.DueDate){
                this.DueDate = format(noticedata.DueDate.toDate(),'yyyy-MM-dd')
              }
              if(noticedata.PublishDate){
                this.PublishDate = format(noticedata.PublishDate.toDate(),'yyyy-MM-dd')
              }
              if(noticedata.RelatedLink){
                this.RelatedLink = noticedata.RelatedLink
              }
              if(noticedata.RelatedRecord){
                this.RelatedRecord = noticedata.RelatedRecord
              }
              if(noticedata.Moderators){
                this.Moderators = noticedata.Moderators
              }
              if(noticedata.MustRead){
                this.MustRead = noticedata.MustRead
              }
              if(noticedata.MustConfirm){
                this.MustConfirm = noticedata.MustConfirm
              }
              if(noticedata.SkipWriteup){
                this.SkipWriteup = noticedata.SkipWriteup
              }
              if(noticedata.coverimage){
                this.coverimagelocalurl = noticedata.coverimage
              }

            }
            
          })
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
console.log('this is user name man '+this.author)
      

        })
            },
        UpdateArticle(){
          // console.log(this.editor.scrollingContainer.innerHTML)

          //   const UpdatedArticle = {
          //       content: this.editor.scrollingContainer.innerHTML
          //   }

            db.collection('grouparticles').doc(this.articleid).update({
                content: UpdatedArticle.content
            });
              this.snackbar = true
            this.articlequil = false
        },
        onEditorChange() {
// alert('change')
        },
    // async getArticleInformation() {
    //     let articlecollection = db.collection('grouparticles')
    //     let docref = articlecollection.doc(this.articleid)
    //     docref.onSnapshot(snapshot => {
    //       // this.UpdateRequestStatus()
    //       var articledata = snapshot.data()
        
      
    //     this.name = articledata.name
    //     this.content = articledata.content
    //     this.articlecontent = articledata.content
        
    //     this.image = articledata.image
      

    //     })
    
    //     },
      OpenArticledialog() {
        this.articledialog = true
    },
    Closedialog() {
        this.articledialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
        this.ImagelocalURL = URL.createObjectURL(this.selectedFile)
      },
     save() {
       if(this.$route.params.id){
        //    if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                    let vm = this
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          let contentelmnt = document.getElementById('articlecontentdiv')
                    this.title = this.articletitle
                    this.content = contentelmnt.innerHTML
                    this.author = this.author
                    this.authorid = this.UserRecord.id
                    this.image = url
                    
                        
                        const article = { 
                              title: this.title,
                                content: this.content,
                                author: this.author,
                                authorid: this.authorid,
                                image: this.image,
                                PublishStatus: 'Draft'
                                
                            }  
                            db.collection('notices').add(article).then(function(doc) {
                          const systemactivity = {
                            user: vm.UserRecord,
                            contentvar: 'created a new Notice',
                            location: '/NoticeSingle/',
                            docname: article.title,
                            docid: doc.id,
                            type: 'New Notice',
                          }                          
                          vm.$store.dispatch('createSystemActivity',systemactivity)
                          vm.$router.push('/NoticeSingle/'+doc.id)
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        })
                        }
                        else {
        //    if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Site_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                    let vm = this
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          let contentelmnt = document.getElementById('articlecontentdiv')
                    this.title = this.articletitle
                    this.content = contentelmnt.innerHTML
                    this.author = this.author
                    this.authorid = this.UserRecord.id
                    this.image = url
                    
                        
                        const article = { 
                              title: this.title,
                                content: this.content,
                                author: this.author,
                                authorid: this.authorid,
                                image: this.image,
                                PublishStatus: 'Draft'
                                
                            }  
                        db.collection('notices').add(article).then(function(doc) {
                          const systemactivity = {
                            user: vm.UserRecord,
                            contentvar: 'created a new Notice',
                            location: '/NoticeSingle/',
                            docname: article.title,
                            docid: doc.id,
                            type: 'New Notice',
                          }                          
                          vm.$store.dispatch('createSystemActivity',systemactivity)
                            vm.$router.push('/NoticeSingle/'+doc.id)
                            })
                    
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
                        
          }
          
      }
      
    }
// }
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>
    
    

    