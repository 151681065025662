                <template>
      
<v-card tile :style="{marginTop:'126px',backgroundImage:'linear-gradient(338deg, rgba(23,72,105,0.99) 0%, rgba(193,37,23,0.9) 50%, rgba(9,19,25,0.9) 100%),url(' + require('@/assets/ClientNavBG.jpeg') + ')' ,backgroundSize: 'cover'}" class="transparent" width="100%" height="100%"  flat>

    <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
            
            <v-flex lg12 md12 sm12 xs12>
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center style="padding:7px;">
            <div class="flex xl11 lg11 md11 sm12 xs11">
            
             
            
            <v-row        
                    class="mb-6 justify-center"
                    no-gutters
                    style="margin-top:50px;margin-bottom:px;margin-left:px;margin-right:px;"                       
                    >
                    
                    
                                
                     <v-col
                            :xl="11" :lg="11" :md="10" :sm="12" :xs="12"
                            cols="12"    
                            class=" my-1"  
                            style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;align-self:undefined"                      
                        > 
                        <v-card :flat="true" :tile="true" height="100%"  class="transparent mx-1">
                        
                        
                          
                        
                            
                            
                            
                            
                            
                            
                            

        <v-card class="mx-3" color="#ffffffff" elevation="8"  style="padding:20px;border-radius: 35px 0px 35px 0px;margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;">
                            <v-card-title class="justify-center" :style="'word-break: normal;line-height: 80%;text-align: center;padding-bottom: 20px;color:#000000;font-family: Michroma, sans-serif;font-size:'+GetHeaderSize(50)+'px;'">
                            <strong>GP Combrink Vehicle Sales & Finance</strong>
                             <!-- <v-divider inset class="white" >
                            </v-divider> -->
                            <!-- <hr style="width:35%; color:blue;"> -->
                            </v-card-title>
                            <!-- <v-divider inset class="white" >
                            </v-divider> -->
                        <v-card-text v-html="HTMLBox00Description" class="" style="font-family:Maven Pro, sans-serif;font-size:px;color:#000000">
                            </v-card-text>
                         
                         <v-card-text>
                             <v-layout class="" row>
                             <v-img  
                             :contain="true"
                               height="350"
                                    src="https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FEAeK2ov547ZbO55ZJ6ok%2FAppIcon?alt=media&token=34a30f13-f4b3-4c53-9b6f-4460aa3625bf"
                                    style=""
                                   
                                    />
                             </v-layout>
                         </v-card-text>
                    <v-card-actions  class="justify-center">
                        <v-btn dark  :to="'/Login'" color="#FF0000">
                            Login
                        </v-btn>
                    </v-card-actions>
                        </v-card>

                        
                            
                            
                            
                            
                            
                        </v-card>
                     </v-col>
            </v-row>
             </div>
             </v-layout>
           </v-card>
          
          </v-flex>
          
          
  
    </v-card>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'


export default {
    props: ['AppisDarkMode','IntranetView','AppisDarkModeCard'],
    components: {
        },
    data() {
    return {
      carddarkcolor: 'grey darken-4',
      cardlightcolor: 'white',
        mustreadnotices: [],
        noticestep: 0,
          
      HTMLBox00Description: `<div style="text-align: center;">This site is intended for clients of GP Combrink Finance &amp; Insurances.&nbsp;</div><div style="text-align: center;">At GP Combrink Finance &amp; Insurances, we aim to go the extra mile to bring a smile to every customer we serve.&nbsp;</div><div style="text-align: center;"><br></div><div style="text-align: center;">Just another benefit of being one of our clients.</div>`,
      RelevantTopBannerAds: [],      
      UserRecord: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()

    },
    
    methods: {
      ActivateMultipleFilesUploadNotification(boolean){
        this.$emit('ActivateMultipleFilesUploadNotification',boolean)
      },
      GetHeaderSize(int){
         if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
              return int/2
          }
          else if(this.$vuetify.breakpoint.md){
              return int/1.5
          } 
          else {
              return int
          }
      },
      ActivateNotificationSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateNotificationSnackbar',boolean,snackbarcontent)
      },
      ActivateStandbyDialog(boolean){
        this.$emit('ActivateStandbyDialog',boolean)
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      SubmitForm(tab){
            console.log(tab)
            tab.FormProcessing = true
            if(tab.FromDatabase){
                this.$emit('ProcessingData',true)
            }
            if(tab.FormEmailRecipient){
                let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry - Home</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`
                 } 
            })
            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New Enquiry - Home',
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
            }
            console.log('tab.EntityForm',tab.EntityForm)
            if(tab.EntityForm){
                let NewRecord = {
                    Created_On: new Date()
                }
                let length = tab.FormFields.length
                tab.FormFields.map((field,i) => {
                NewRecord[field.Name.split(' ').join('')] = field.Response
                if(field.Type === 'Date'){
                    let date = this.DateFormatter(field.Response)
                    NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: date.getTime()}    
                    }
                if(i-1+2 === length){
                  const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(tab.DefaultOwner){
                    NewRecord.Owner = tab.DefaultOwner
                    NewRecord.Ownerid = tab.DefaultOwner.id
                  }
                  if(this.userLoggedIn){
                      NewRecord.WebSubmission = false
                  }
                  else{
                      NewRecord.WebSubmission = true
                  }
                  if(!NewRecord.Created_By && this.userLoggedIn){
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Created_Byid = this.userLoggedIn.id
                      
                  }
                  let obj = {
                    collection: tab.RelatedEntity.id,
                    data: NewRecord
                  }    
                  console.log(obj)        
                  SubmitFormData(obj).then(result => {
                    console.log(result)
                    if(tab.FromDatabase){
                        this.$emit('ConfirmSubmission',tab,obj,result.data)
                        this.$emit('ProcessingData',false)
                    }
                    else{
                      tab.FormSubmitted = true  
                    }
                    
                }) 
            }
                })
            }
        },
      GetMustReadNotices() {
           db.collection('notices').where('MustRead','==',true).onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                  })
                  
                
              }  
              

            })
          })

          },
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
             let vm = this
            if(this.userLoggedIn){
              vm.UserRecord = this.userLoggedIn
              vm.GetMustReadNotices()
              let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)           
              vm.GetBannerAds(RouteBannerAdsRef)
              console.log(vm.UserRecord)
                }
                else{
                  let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
                 
                  vm.GetBannerAds(PublicRouteBannerAdsRef)
                }
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}
</style>
  
            
        