          
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  
  

  
    
  <v-dialog width="400" v-model="ChangeStatusDialog">
          <v-card width="400">
            <v-card-title>Change Status</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Status"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
             v-if="Status"
            :items="Status.Options"
            v-model="Status_Reason"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="ViewFileDialog" fullscreen>
        <v-card tile class="white" flat>
          <v-btn @click="CloseViewFileDialog()" style="z-index:2;">Close</v-btn>
          <embed v-if="ViewFileURL" :height="ImageHeight-25" :width="ImageWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="ImageHeight-25" :width="ImageWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>

      <v-toolbar tile dark :color="Status && Status.ID === 1000001 ? 'accent':'warning'" class="stickytopbanner2" width="100%" id="recordtoolbar" v-if="!WikiMode">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item @click="ChangeStatusDialog = !ChangeStatusDialog" class="listoutline">
            <v-list-item-title  class="subtleoverline">
              {{StatusToggleName}}
            </v-list-item-title>
          <v-list-item-action>
              <v-icon class="actionicon" color="white">mdi-power</v-icon>
            </v-list-item-action>
          </v-list-item>
        <v-list-item @click="UpdateDealership()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditDealership()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title>
   <v-list width="100%" class="transparent">
   <v-list-item>
  <v-list-item-content >
    <span class="headline"><span class=" hidden-sm-and-down">Dealership - </span><span class="headline font-weight-light">{{ Dealer_Name  }}</span></span>                   
    <v-list-item-subtitle v-if="Status">
       {{Status.Name}}
    </v-list-item-subtitle>
  </v-list-item-content>
  </v-list-item>
    </v-list>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
     <v-parallax class="recordparallax" v-if="AppisDarkMode && !WikiMode"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
        >               
        </v-parallax>
        <v-parallax class="recordparallax" v-if="!AppisDarkMode && !WikiMode"
        height="150"
            src="@/assets/RASolidA.jpeg"
        >               
        </v-parallax>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
  <v-icon style="background-color:green;" @click="UpdateDealership()" color="white" class="soloactionicon">mdi-content-save</v-icon>
  </v-layout>
  

  
  
    

  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Dealership help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingDealership" tile  width="100%" class="stickytopbanner3" id="recordownershipbanner1">    
</v-card>
<v-card v-if="EditingDealership" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">Dealership - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Dealer_Name" label="Dealer Name" />
  </v-card-text>
  </v-img>
</v-card> 
  
  
  

  

  


   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
          <v-card height="80px" flat class="transparent">
          </v-card>
                
      
          <v-layout row class="justify-start mx-3"><span class="secondary--text headline" id="Basic"></span></v-layout>
 
           <div class="flex row xl12 lg12 md12 sm12" v-if="!EditingDealership">
        <v-col style="padding-bottom:40px;" class="my-5 slightmargin" cols="12" sm="12">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                <v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection1 = !EditingSection1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Dealer Name</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Dealer_Name}}</h4>
                      
                      
                      
                    <span v-if="EditingSection1"><v-text-field id="Dealer_Name"  v-model="Dealer_Name"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Description</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Description}}</h4>
                      <v-textarea id="Description" v-if="EditingSection1" v-model="Description"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Website</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                       <v-text-field id="Website" v-if="EditingSection1" :rules="[rules.url]" v-model="Website" label="Website"></v-text-field>
                     <a v-if="!EditingSection1" :href="'//'+Website" target="blank">{{Website}}</a>
                        <span v-if="EditingSection1"><v-text-field id="Website" label="Website" :rules="[rules.telnr]" v-model="Website"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Office Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col> 
                       <a :href="'callto:'+Office_Number" target="_top">{{Office_Number}}</a>
                        <span v-if="EditingSection1"><v-text-field id="Office_Number" label="Office Number" :rules="[rules.telnr]" v-model="Office_Number"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Logo</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      
                    <v-avatar
                    v-if="EditingSection1"
                      color="grey"
                      size="150" 
                      tile>
                      <input
                      style="display: none"
                      type="file"
                      @change="onLogoFileselected($event)"
                      ref="LogofileInputer">
                        <v-btn v-if="EditingSection1 && LogolocalURL" @click="onLogoUpload()" class="green white--text" icon small absolute bottom right><v-icon>mdi-content-save</v-icon></v-btn>
                        <v-btn v-if="EditingSection1 && LogolocalURL" @click="closeLogo()" class="red white--text" icon small absolute bottom left><v-icon>mdi-close</v-icon></v-btn>
                        <v-btn v-if="EditingSection1" @click="$refs.LogofileInputer.click()" class="blue white--text" icon small absolute top right><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn v-if="!EditingSection1" @click="GetShortLivedURL(Logo)" class="blue white--text" icon small absolute top right><v-icon>mdi-eye</v-icon></v-btn>
                        <img v-if="LogolocalURL" :src="LogolocalURL" height="150px">
                        <img v-if="Logo && !LogolocalURL" :src="DealershipData.LogoThumbURL" height="150px">
                        <img v-if="!Logo && !LogolocalURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px">
                        <img v-if="!Logo && !LogolocalURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px">
                    </v-avatar>
                    <span v-if="!EditingSection1">
                  <v-hover v-if="DealershipData.LogoThumbURL"
                        v-slot:default="{ hover }"
                        open-delay="200"
                      >
                      <v-card class="mx-auto transparent"  @click="GetShortLivedURL(Logo)"
                      flat tile>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                            style="height: 100%;"
                          >
                            View
                          </div>
                        </v-expand-transition>
                        <v-img :src="DealershipData.LogoThumbURL" height="150px" contain/>
                      </v-card>
                  </v-hover>
                  <v-img v-if="!DealershipData.LogoThumbURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px" contain/>
                  <v-img v-if="!DealershipData.LogoThumbURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px" contain/>
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
            </v-card>
          </v-col>
          </div>
    
          
          
        
          
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
  </v-layout>
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
components: {
  RMAdministration,
  SystemTicketsLogging,
},
    

  data() {
    return {
      ViewFileDialog: false,
      ViewFileURL: '',
      ViewIMGURL: '',
      ChangeStatusDialog: false,
  
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      CustomCreateRoles: [
  "Office Administrator"
],
      CustomGetRoles: [
  "Office Administrator"
],
      CustomListRoles: [
  "Office Administrator"
],
      CustomUpdateRoles: [
  "Office Administrator"
],
      
      selectedEntity: {Name: 'Dealerships',
      PrimaryField: 'Dealer_Name',
      Tables: [
          ]
      },
      selectedRecord: {},
      BannerTabs: [
        ],
      EditingSection1: false,
      TicketLog: false,
      step: 1,
      rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      Dealer_Name: '',
      Description: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {
  "Level": {
    "children": [],
    "name": "Status Reason",
    "FieldName": "Status_Reason",
    "id": 2
  },
  "ID": 1000003,
  "LevelFieldName": "Status_Reason",
  "Name": "Open"
},
      Created_On: '',
      Created_Onmenu : false,
      Created_By:  {id: '', Full_Name: ''},
      UnpermittedCreated_By:  {id: '', Full_Name: ''},
      HasCreated_By: false,
      Created_ByRelatedParentObj: {},
      Logo: '',
      Office_Number:  '',
      Website:  '',
      Created_ByFull_Name: null,
      
        Logofilesnackbar: false,
        selectedLogoFile: '',
        LogolocalURL: '',
      Created_Onmenu: false,
      
      StatusOptions: [{
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Level": {
        "name": "Status Reason",
        "children": [],
        "id": 2,
        "FieldName": "Status_Reason"
      },
      "ID": 1000003,
      "Name": "Open"
    }
  ],
  "Level": {
    "children": [
      {
        "id": 2,
        "name": "Status Reason",
        "FieldName": "Status_Reason",
        "children": []
      }
    ],
    "FieldName": "Status",
    "name": "Status",
    "id": 1
  },
  "ID": 1000001,
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "Name": "Active"
},{
  "Options": [
    {
      "Level": {
        "children": [],
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "id": 2
      },
      "ID": 1000004,
      "Name": "Closed",
      "LevelFieldName": "Status_Reason"
    }
  ],
  "HasDeepHierarchy": true,
  "Level": {
    "children": [
      {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    ],
    "FieldName": "Status",
    "name": "Status",
    "id": 1
  },
  "Name": "InActive",
  "ID": 1000002,
  "LevelFieldName": "Status"
}],
      Statusfilter: '',
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      EditingDealership: false,
      dialog: false,
      initialize: '',
      search: '',
      DealershipData: {},
      headers: [
      
        
        { text: 'Dealer Name', value: 'Dealer_Name'},
        
        
        { text: 'Status', value: 'Status.Name'},
        
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedDealershipsnackbar: false,
      DealershipEditdialog: false,
      newDealershipdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
window.addEventListener('scroll', this.handleScroll)

  },
  
  computed: {
    
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
    ComputedStageID(){
    return this.ProcessStages[this.StageID-1].Number
  },
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
        return true
      }
    }
    else{
      return this.userIsAdmin
    }
  
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    },
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
                if(vm.UserRecord !== 'undefined'){
                vm.UserRoles = vm.UserRecord.rolesarrayQuery
                let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.GetDealership(AdminTest)
                        
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                }
                else{
                  let GetQuery = db.collection('dealerships')
                  vm.CustomRolesCheck(vm.CustomGetRoles).then(PermissionLevel => {
                    if(PermissionLevel === 1 || vm.IsAdmin){
                      vm.GetDealership(vm.UserRecord)
                        
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                    }
                    else{
                      vm.RoutetoErrorPage()
                    }
                  })
                }
                
                }
              
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetDealership() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
             vm.CustomRolesCheck(vm.CustomUpdateRoles).then(PermissionLevel => {
              if(PermissionLevel === 1){
                vm.CanEdit = true
              }
             })
             vm.CustomRolesCheck(vm.CustomUpdateRoles).then(PermissionLevel => {
              if(PermissionLevel === 1){
                vm.CanCreate = true
              }
             })
            db.collection('dealerships').doc(this.$route.params.id).onSnapshot(snapshot => {
            var dealership = snapshot.data()
            dealership.id = this.$route.params.id
            
            this.selectedRecord = {id: this.$route.params.id, Dealer_Name: dealership.Dealer_Name }
            this.RelatedObj = {id: this.$route.params.id, Dealer_Name: dealership.Dealer_Name,identifier: 'Dealer_Name',collection: 'dealerships',Link:'Dealership' }
            this.DealershipData = dealership
            this.Status_Reason = dealership.Status_Reason
            this.DealershipData.id = this.$route.params.id
            this.$emit('PushRecordName',this.DealershipData.Dealer_Name)
            if(typeof dealership.Owner !== 'undefined'){
              this.Business_Unit = dealership.Business_Unit
            }        

            this.Dealer_Name = dealership.Dealer_Name
            this.editedItem.Dealer_Name = dealership.Dealer_Name
            //I think API Trigger should be here, outside the first if loop on fields
            this.Description = dealership.Description
            this.editedItem.Description = dealership.Description
            //I think API Trigger should be here, outside the first if loop on fields
            this.Status = dealership.Status
            this.editedItem.Status = dealership.Status
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
              if(dealership.Created_On && typeof dealership.Created_On !== 'string'){
            this.Created_On = format(dealership.Created_On.toDate(),'yyyy-MM-dd')
            this.editedItem.Created_On = dealership.Created_On
              }
            if(dealership.Created_By){
            this.Created_ByRelatedParentObj = {id: dealership.Created_By.id, Dealer_Name: dealership.Dealer_Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Created_By = this.UsersStore.find(created_by => created_by.id === dealership.Created_Byid)
                
            if(typeof dealership.Created_Byid !== 'undefined' && dealership.Created_Byid !== ''){
              this.HasCreated_By = true
              if(typeof this.Created_By === 'undefined'){
                this.UnpermittedCreated_By = {id: dealership.Created_Byid,Full_Name: dealership.Created_By.Full_Name}
              }
            }
            this.editedItem.Created_By = dealership.Created_By
            this.Logo = dealership.Logo
            this.editedItem.Logo = dealership.Logo
            //I think API Trigger should be here, outside the first if loop on fields
            this.Office_Number = dealership.Office_Number
            this.editedItem.Office_Number = dealership.Office_Number
            //I think API Trigger should be here, outside the first if loop on fields
            this.Website = dealership.Website
            this.editedItem.Website = dealership.Website
            //I think API Trigger should be here, outside the first if loop on fields
    
            })
          
        },
      
      


    
      
      
            CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
      GetCols(int){
      if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg){
        return int
      }
      else if(this.$vuetify.breakpoint.md){
        return int*1.5
      }
      else if(this.$vuetify.breakpoint.sm){
        return int*2.5
      }
      else{
        return int*3
      }
    },
      ResetStatus(){
      this.Status = Object.assign({},this.DealershipData.Status)
      this.Status_Reason = Object.assign({},this.DealershipData.Status_Reason)
      this.ChangeStatusDialog = false
    },
    UpdateStatus(){
      db.collection('dealerships').doc(this.$route.params.id).update({
        Status: this.Status,
        Status_Reason: this.Status_Reason
      })
      this.ChangeStatusDialog = false
    },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
          }
          else{
            this.ViewFileURL = result.data[0]
          }
          console.log(window.innerHeight)
          this.ViewFileDialog = true
          //window.location.assign(result.data[0])
        })
    },
      ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode')
      },
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateDealership(){
        this.UndefinedMandatoryFields = []
      const EditedDealership = {
    Dealer_Name: this.Dealer_Name,
    
        }
      for(var prop in EditedDealership) {
          if (EditedDealership.hasOwnProperty(prop)) {
            if(typeof EditedDealership[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      db.collection('dealerships').doc(this.$route.params.id).update({
        Dealer_Name: EditedDealership.Dealer_Name,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedDealershipsnackbar = true
              this.CancelDealershipEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Dealer_Name !== 'undefined'){                  
                  let Dealer_NameQuery = []
                  let Dealer_NameStringArray = this.Dealer_Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(Dealer_NameStringArray.length,Dealer_NameStringArray)
                    for (p = 0; p < Dealer_NameStringArray.length; p++) {
                        buildup = buildup+ Dealer_NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    Dealer_NameQuery = lowertext.split(',')  
                    Dealer_NameQuery.length = Dealer_NameQuery.length-1
                   db.collection('dealerships').doc(this.$route.params.id).update({
                     Dealer_NameQuery: Dealer_NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                if(typeof this.Description !== 'undefined'){
                   db.collection('dealerships').doc(this.$route.params.id).update({
                     Description: this.Description,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Logo !== 'undefined'){
                   db.collection('dealerships').doc(this.$route.params.id).update({
                     Logo: this.Logo,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Office_Number !== 'undefined'){
                   db.collection('dealerships').doc(this.$route.params.id).update({
                     Office_Number: this.Office_Number,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Website !== 'undefined'){
                   db.collection('dealerships').doc(this.$route.params.id).update({
                     Website: this.Website,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.DealershipsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('dealerships').doc(item.id).delete() && this.DealershipsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.DealershipsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelDealershipEditing(){
      this.EditingDealership = false
    },
    EditDealership() {
      this.EditingDealership = !this.EditingDealership
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetUsersCreated_By() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Created_ByArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersCreated_ByArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.Created_ByArray = vm.UsersStore
      vm.GetCreated_By();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.Created_ByArray = vm.UsersStore
                        vm.GetCreated_By();
                        })
    }
  },

  GetCreated_By(){
    this.Created_By = this.Created_ByArray.find(created_by => created_by.id === this.DealershipData.Created_Byid)
  },

  //GetUsersCreated_By(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Created_ByArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersCreated_By finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingCreated_By(){
this.CancelAddParentDialog()
this.PrepareDataParentCreated_By()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Created By'
      this.LookupArray = this.Created_ByArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentCreated_By(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Created_By'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Created By', Prop: 'Full_Name', Propid: 'Created_Byid', Value: '',Array: 'Created_ByArray',LookupFieldName: 'Created_By'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Created By', Prop: 'Created_By', Value: '',LookupFieldName: 'Created_By'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "Options": [
      {
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        },
        "LevelFieldName": "Status_Reason",
        "ID": 1000003,
        "Name": "Open"
      }
    ],
    "HasDeepHierarchy": true,
    "ID": 1000001,
    "Name": "Active",
    "LevelFieldName": "Status",
    "Level": {
      "name": "Status",
      "id": 1,
      "children": [
        {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      ],
      "FieldName": "Status"
    }
  },
  {
    "ID": 1000002,
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004,
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      }
    ],
    "Level": {
      "FieldName": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "name": "Status",
      "id": 1
    },
    "LevelFieldName": "Status",
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewCreated_ByDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCreated_By()
},



      
    
      
      
    
      
    
      
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          
      
     
      
      

      
      
      
      ResetFilters() {
        this.Statusfilter = ''
      },
      
      
      
    ProcessDeleteItem(item,itemarrayname,collection){
      let vm = this
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(vm.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
      
      
  
        
        
        
        
        
      
    
    

    
  onLogoUpload() {
    //singleuploadfile.FeaturedPhoto true
    let vm = this
    let filename = vm.selectedLogoFile.name
        let storepath = 'Dealerships/'+vm.$route.params.id+'/Logo/'+filename
        var storageRef = firebase.storage().ref(storepath);
        var uploadTask = storageRef.put(vm.selectedLogoFile);
        vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,vm.selectedLogoFile,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let LogoObject = {
              fileurl: url,
              url: url,
              LogoThumbURL: thumbresult,
              FileType: vm.selectedLogoFile.type,
              Modified_By: {Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
              Modified_Byid: vm.UserRecord.id,
              Modified_On: new Date(),
              ModifiedDate: vm.selectedLogoFile.FileModifiedDate,
              Size: vm.selectedLogoFile.size,
              StorageRef: storepath,
              filename: filename
            }
            db.collection('dealerships').doc(vm.$route.params.id).update({
                    Logo: LogoObject,
                    LogoThumbURL: LogoObject.LogoThumbURL
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added Logo to - '+vm.Dealer_Name,
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            vm.selectedLogoFile = ''
                })
                  vm.Logofilesnackbar = true
         })
      },
      closeLogo(){
      this.LogolocalURL = ''
      this.selectedLogoFile = ''
    },
      onLogoFileselected(event) {
        this.selectedLogoFile = event.target.files[0]
        this.selectedLogoFile.FileModifiedDate = new Date(this.selectedLogoFile.lastModified)
         if(this.ReviseDealershipDialog){
          this.RevisedDealershipDealer_Name = this.selectedLogoFile.name
        }
        this.LogolocalURL = URL.createObjectURL(this.selectedLogoFile)
      },

      
      
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        