
<template>
  <v-footer
      :dark="true"
      class="primary justify-center"
      style="padding-bottom:50px;"
      v-if="!userLoggedIn && FooterColumns"
    >
      <v-flex lg9 md10 sm12 xs12>
        <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
          <v-col
            v-for="column in FooterColumns" :key="column.itemObjKey"
            :cols="12/FooterColumns.length"
            >
            <v-list-item-title class="mediumoverline" v-if="column.Title">
                {{column.Title}}
              </v-list-item-title>
            <div v-for="item in column.Items" :key="item.itemObjKey">
            <v-list-item  v-if="item.Type === 'Avatar'">
            <v-avatar size="200">
                <v-img contain :src="item.SRC"/>
            </v-avatar>
            </v-list-item>
            <v-list class="transparent" dense v-if="item.Type === 'Our Locations'">
              
              <v-list class="transparent caption" dense v-for="location in BusinessLocations" :key="location.itemObjKey">
                <v-list-item dense>
                  <v-btn icon :href="'tel:'+location.Telephone"><v-icon>mdi-phone</v-icon></v-btn>{{location.Telephone}}
                </v-list-item>
                <v-list-item dense> 
                  <v-btn icon @click="ViewMapItem(location.AddressLine1,location.AddressLine2,location.AddressLineCity,location.AddressLinePostalCode,location.AddressLineProvince,location.AddressLineCountry)"><v-icon>mdi-google-maps</v-icon></v-btn>{{location.Name}}
                </v-list-item>
              </v-list>
              <v-list-item dense> 
                  <v-list-item-avatar size="30">
                    <v-icon small style="border: 0.5px solid currentColor;">mdi-facebook</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-avatar size="30">
                    <v-icon small style="border: 0.5px solid white;">mdi-linkedin</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
            </v-list>
            <v-list class="transparent" dense v-if="item.Type === 'ClassifiedWiki Filter'">
               <v-list class="transparent caption" dense v-for="filter in item.Filters" :key="filter.itemObjKey">
                <v-list-item dense @click="LoadWithFilter(item,filter)">
                  {{filter.Name}}
                </v-list-item>
              </v-list>
             </v-list>
            </div>
          </v-col>
        </v-row>
      </v-flex>
    </v-footer>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
  components: {},
  data() {
    return {
       FooterColumns: [
        {
          SectionnName: '1',
          Items: [
            {
              Type: 'Avatar',
              SRC: ''
            },
          ],
        },
        
        {
          SectionnName: '2',
          Title: 'Body Type',
          Items: [
            {
              Type: 'ClassifiedWiki Filter',
              SRC: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FKemu5JN8fdDItmI34Ofz%2Flogo?alt=media&token=d4801232-effb-4ab2-8b5d-3e11987eadd6',
              Path: 'Used-Vehicles',
              FilterProp: 'Vehicle_Type',
              CompName: 'vehicleswikipublic',
              Filters: [
                {
              Name: 'Bakkie',
              ID: 1000001
              },
              {
              Name: 'Double cab',
              ID: 1000002
              },
              {
              Name: 'Hatch back',
              ID: 1000003
              },
              {
              Name: 'LWB',
              ID: 1000004
              },
              {
              Name: 'Sedan',
              ID: 1000005
              },
              {
              Name: 'SUV',
              ID: 1000006
              },
              {
              ID: 2000000,
              Name: 'Other'
              },
              
              ],

            },
            
          ],
        },
        {
          SectionnName: '3',
          Title: 'Our Locations',
          Items: [
            {
              Type: 'Our Locations',
              Title: 'Contact Us'
            },
          ],
        },

      ],
      BusinessLocations: [
      ],
    }
  },
  computed: {
     userLoggedIn (value) {
        if (value !== null && value !== undefined) {
         //this.CheckAuth()
         this.UserObj = Object.assign({},value)
        }
      },
    },

  created() {
    this.GetBusinessLocations()
  },

  methods: {
    LoadWithFilter(item,filter){
      this.$router.push({ name: item.CompName, params: {FilterProp: item.FilterProp, slug: filter.Name,ViewType: 'ClassifiedsView',LinksGroupsearch: ''}})
    },
    ViewMapItem(PhysicalAddressAddressLine1,PhysicalAddressAddressLine2,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressAddressLine1+'+'+PhysicalAddressAddressLine2+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
    GetBusinessLocations(){
            db.collection('businessunits').where('IsPublicLocation','==',true).onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                          let buobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        if(buobj.id !== 'Guest'){
                            this.BusinessLocations.push(buobj)
                            if(buobj.Top){
                                this.TopLocation = buobj                       
                            }
                        }
                          
                        }
                    }) 
            })
        },
  }

}
</script>

<style>
</style>
    