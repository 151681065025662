    <template>
  <v-content>
     <v-card width ="100%" flat>
       <v-img :src="require('@/assets/RABaseBG.jpeg')" height="150px">
       <h1 align="center" class="white--text">My Activities</h1>
      <v-layout class="justify-center">
       <v-badge color="pop" overlap  v-for="item in items" :key="item.itemObjKey" dark class="mx-3">
       <v-icon color="white" large>{{item.icon}}</v-icon>
       <span slot="badge"> {{item.count }} </span>
                            </v-badge>
       </v-layout>
       </v-img>
       <v-layout row wrap class="justify-start">
       <v-flex xs6 sm6 md3 lg3 class="my-1" v-for="item in itemsCount" :key="item.itemObjKey">
       <v-card class="mx-2" :href="item.to">

             <v-layout row class="justify-start">
               <v-card-title>
            <v-avatar tile color="blue">
              <v-icon dark>{{item.icon}}</v-icon>
            </v-avatar>

            <p v-if="item.count === 0">{{item.title}} ({{item.count}})</p>
            <p v-if="item.count > 0" class="red--text"><strong>{{item.title}} ({{item.count}})</strong></p>
                </v-card-title>
            </v-layout>

       </v-card>
       </v-flex>
       </v-layout>
     </v-card>
  </v-content>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/BreadCrumbs'

export default {
  components: { BreadCrumbs },
  data() {
    return {
      Icons: [],
      drawer: false,
      items: [],
      itemsCount: [],
      UserFullName: '',
      UserAssignedRoles: [],
      IsAdmin: false,
      MyActivitiesArray: [],
      UserRecord: {},
    }
  },
  computed: {
      userLoggedIn () {
      return this.$store.getters.user
    },


    // itemsCount(){
    //   return this.items.map(item => {
    //     item.count = this.MyActivitiesArray.filter(activity => {
    //       return activity.regardingtype === item.countname
    //     })
    //     return item
    //   })
    // },
    },

    watch: {
      userLoggedIn (value) {
        if (value === null || value === undefined) {
          this.$router.push('/login')
        }
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },

  created() {


    this.GetRequestingUser()
  },

  methods: {
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserRecord = userdetails
          vm.UserRecord.id = user.uid
          vm.GetActivities()
          vm.getUserActivityRelated()
          })
        }
      })
    },

    GetActivities(){
      db.collection('activities').where('Ownerid', '==', this.UserRecord.id).get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
      this.itemsCount = []
      this.items.map(item => {
        item.count = this.MyActivitiesArray.filter(activity => {
          return activity.regardingtype === item.countname
        }).length
        this.itemsCount.push(item)
      })
    })
    },


    async getUserActivityRelated() {
        var vm = this;

      await firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
        //console.log('AuthchangeLoggedIn but not dispatching userstate')
        // console.log(user.uid)
        // vm.$store.dispatch('UserState',user)
      vm.items = []
        db.collection('users').doc(user.uid).onSnapshot(snapshot => {
           var userdetails = snapshot.data()

          vm.UserFullName = userdetails.Name+' '+userdetails.Surname
          if(typeof userdetails.rolesarrayQuery !== 'undefined'){
          vm.UserAssignedRoles = userdetails.rolesarrayQuery
          let AdminTest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
          
          let DefaultlistAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list All')
      if(typeof DefaultlistAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultgetUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UserUnit')
      if(typeof DefaultgetUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UserUnit')
      if(typeof DefaultdeleteUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultCreateAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Create All')
      if(typeof DefaultCreateAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit')
      if(typeof DefaultUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultgetAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get All')
      if(typeof DefaultgetAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update UserUnit')
      if(typeof DefaultupdateUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update ALL')
      if(typeof DefaultupdateALLroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let Defaultcreateunitdownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create unitdown')
      if(typeof Defaultcreateunitdownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultgetUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UnitDown')
      if(typeof DefaultgetUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultcreateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create UserUnit')
      if(typeof DefaultcreateUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UnitDown')
      if(typeof DefaultdeleteUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update Unit Down')
      if(typeof DefaultupdateUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UnitDown')
      if(typeof DefaultlistUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete ALL')
      if(typeof DefaultdeleteALLroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UserUnit')
      if(typeof DefaultlistUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default All')
      if(typeof DefaultAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultOwnerroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Owner')
      if(typeof DefaultOwnerroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit Down')
      if(typeof DefaultUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          }
        })



    }

   else {
        //console.log('AuthchangeLoggedOut')
        vm.pushLoggedOutItems

      vm.items = [

        {
          icon: 'mdi-login-variant',
          title: 'Login',
          to: '/Login'
        },
      ]

  }
    })
    }

  }

}
</script>

<style>
</style>
    