
<template>

  
      <v-row 
        class="justify-center"
        align="center"
      >
      
      <v-spacer></v-spacer><v-icon v-if="userisNoticeModerator || userIsAdmin" class="actionicon" @click="NewNotice()">mdi-plus-thick</v-icon>
      <v-carousel v-model="model"  v-if="filterednotices.length>0" dark cycle>
         <v-carousel-item 
            v-for="notice in filterednotices.slice(0,Excerpt)"
          :key="notice.id"
                      >
                      <div>
                    <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
          <v-list-item :to="'/NoticeSingle/'+notice.id" class="mx-1 noticeoutlined" >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
                      </div>
         </v-carousel-item>
      </v-carousel> 
      </v-row>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import NoticeSingle from '@/components/System/Notices/NoticeSingle'


export default {
    props: ['clientDB','Description','Excerpt','Filter','HasFilter','AppisDarkModeCard','AppisDarkMode','UserView','UserID'],
    components: {
        NoticeSingle,
  },
    middleware: 'auth',
    data () {
        return {
          model: 0,
            Notice: '',
            NoticeDialog: false,
          PrivacyTypes: [
      {index: 0, text: 'Private'},
      {index: 1, text: 'Members Only'},
      {index: 2, text: 'Site Members Only'},
      {index: 3, text: 'Public'},
        ],
        CollectionRef: db.collection('notices'),
          tempid: 'new',
          //userid: this.$store.state.user.id,
        GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            notices: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                logo: null,
                                
            },
            UserRecord: {},           

        }
    },
    computed: {
        userLoggedIn () {
      return this.$store.getters.user
    },
    userisNoticeModerator(){
      if(this.UserRecord && this.UserRecord.ModerationPermissions){
            let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Notices')
            if(match){
              return true
            }
          }
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    filterednotices() {
      
      return this.notices.filter(notice => {
          if(this.HasFilter){
          return this.Filter.includes(notice.type)
        }
          else{
            return notice
          }
      }).map(not => {
        if(typeof not.createdon.toDate !== 'undefined'){
        not.monthcreated = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'month')
        not.daycreated = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'date')        
        not.createddate = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'full')
        if(not.UserReadDate){
            not.UserReadDate = this.TimestampFormatterNoticeBoard(not.UserReadDate.toDate(),'full')
        }
        not.createdon = this.TimestampFormatterSTRING(not.createdon.toDate())
        }
        if(not.MustRead && not.MustConfirm && !not.UserRead || not.MustConfirm && !not.UserConfirmed){
          not.IconColor = 'red'
          not.Icon = 'mdi-clipboard-alert'
        }
        else if(not.MustRead &&!not.MustConfirm && !not.UserRead){
          not.IconColor = 'orange'
          not.Icon = 'mdi-clipboard-alert-outline'
        }
        else if(!not.MustRead &&!not.MustConfirm && !not.UserRead){
          not.IconColor = 'blue'
          not.Icon = 'mdi-clipboard-outline'
        }
        else{
          not.IconColor = 'green'
          not.Icon = 'mdi-clipboard-check-outline'
        }
        return not
      }).sort((a, b) => {
            var key1 = b.createdon;
            var key2 = a.createdon;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    
    },
    // FilterComputed(){
    //     return this.Filter.map(filter => {
    //         return filter.Name
    //     })
    // }
    },

    created() {
      // this.$store.dispatch('GetUserStateChange')
      if(this.userLoggedIn){
          this.UserRecord = this.userLoggedIn
      }
      console.log(this.Filter)
      if(this.UserView){
        this.CollectionRef = this.CollectionRef.where('creatorid','==',this.UserID)
        this.GetNotices()
      }
      else{
        this.GetNotices()
      } 
    
    // this.FocusedViewToggle()
    //   this.IntranetViewToggle()
    // //   this.GetRequestingUser()
},
    methods: {
      NewNotice(){
        let ref = db.collection('notices').doc()
        let newdocid = ref.id
        this.$router.push({ name: 'noticenew', params: {id: newdocid }})
      },
        SubmitResponse(){
            alert('wow you responded')
        },
        OpenNotice(notice){
            this.$router.push({ name: 'noticesingle', params: {Notice: notice }})
        },
        ViewNotice(notice){
            this.Notice = notice
            this.NoticeDialog = true
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
            })
        }
      })
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
         GetNotices() {
           this.CollectionRef.onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
            let noticeobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                let responsedocdata = responddoc.data()
                if(responsedocdata && responsedocdata.Confirmed){
                    noticeobj.UserConfirmed = true
                }
                if(responsedocdata && responsedocdata.Read){
                    noticeobj.UserRead = true
                    noticeobj.UserReadDate = responsedocdata.ReadDate
                }
                if(responsedocdata && responsedocdata.ConfirmDate){
                    noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                }
                this.notices.push(noticeobj)
            })
            
          
        }  
      })
    })

    },
    Opendialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    // var storageRef = firebase.storage()('Group_Logos/' + this.selectedFile.name);
                    // var uploadTask = storageRef.put(this.selectedFile);
                    //   uploadTask
                    //   .then(snapshot => snapshot.ref.getDownloadURL())
                    //     .then((url) => {     
                    this.name = this.editedItem.Name
                    this.Description = this.editedItem.Description
                    this.user = this.$store.state.user.id
                    this.GroupCategory = this.editedItem.GroupCategory
                    // this.FileURL = url

                        
                        const notice = { 
                              name: this.name,
                                Description: this.Description,
                                Created_By: {Full_Name: this.UserRecord.Full_Name, id: this.UserRecord.id},
                                Created_Byid: this.UserRecord.id,
                                Created_On: new Date(),
                                GroupCategory: this.GroupCategory,
                                GroupPrivacy: 'Public',
                                GroupPrivacyIcon: 'mdi-lock',
                                GroupPublishStatus: 'Draft',
                                GroupPublishStatusIcon: 'mdi-eye-off',
                                EventsPrivacy: 'Public',
                                BlogPrivacy: 'Public',
                                MembersPrivacy: 'Public',
                                NewsPrivacy: 'Public',
                                ForumPrivacy: 'Public',
                                // logo: this.FileURL
                                
                            }
                            const groupmemberscollection =   db.collection('groupmembers')
                            let vm = this
                        db.collection('notices').add(notice).then(function(doc) {
                          console.log(doc.id)
                           const GroupMember = { 
                            Created_On: new Date(),
                            Userid: notice.Created_By.id,
                            Groupid: doc.id,
                            UserRole: 'Administrator'
                            
                            }
                            groupmemberscollection.add(GroupMember)
          vm.$router.push('/Group/'+GroupMember.Groupid)
                            })
                    this.Closedialog()
                    
                          // }),                         
                        
                   
                            this.dialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.noticeoutlined{
   border-style: solid;
    border-color: rgb(207, 205, 205);
   border-width: thin;
}
.outlinedavatar{
  border:1px double red;

  background-color: #2196F3;
}
</style>
    


    