
<template>
<v-card class="transparent stickytopconscious" flat width="100%" height="100%" style="padding-bottom:50px;">

    <v-parallax
            height="150"
            src="@/assets/RABaseBG.jpeg"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">New Article</h4>
              </v-col>
            </v-row>
            </v-parallax>             
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Article.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
    <v-flex xs12 s12 m10 lg10>
    <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Article</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="ArticleTitle">
                </v-text-field>                

                <v-menu
                        v-model="PublishDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="PublishDate">
                        <template v-slot:activator="{ on }">
                          <v-text-field                            
                            v-model="PublishDate"
                            label="Publish Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="PublishDate"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="PublishDate" @input="PublishDatemenu = false"></v-date-picker>
                      </v-menu>
                <v-checkbox label="Is Image Shy" v-model="ImageShy"/>
                <input
                v-if="!ImageShy"
            type="file"
            @change="onarticlecoverimageselect($event)"
            ref="coverimageinputter">
           
                </v-card-text>
                <v-layout class="justify-center">
                
          <v-img v-if="coverimagelocalurl" :src="coverimagelocalurl">
          </v-img>
                </v-layout>
          <v-card-text>
             <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text>
            <v-card-actions>    
            <v-textarea label="Caption" v-model="Caption" :rules="[rules.max150Chars]" />
            </v-card-actions>
            <v-card-actions>                
                <v-select multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-card-actions>
            <v-card-actions>
                
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StoreArticle()">
                    Post
                </v-btn>
                
            </v-card-actions>
        </v-card>
        </v-layout>
   
    
   </v-flex>
</v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import ContentEditable from '@/components/System/ContentEditable';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'

export default {
    props: [],
        middleware: 'auth',
      components: {
        ContentEditable
    // quillEditor

        
  },
  
    data() {
        return {
          TagLinkDialog: false,
          Caption: '',
          UsersArray: [],
          SiteModerators: [],
          Moderators: [],
          PublishDate: '',
          PublishDatemenu: false,
          ArticleTitle: '',
          ImageShy: false,
            GroupData: '',
        selectedFile: '',
        ImagelocalURL: '',
        ArticleContent: '',
        articlequil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        articledialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      articletitle: null,
        articlecontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        grouparticles: [],
        groups: [],
        articleid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        image: '',
        Youtubelink: '',
        rules: {
        max150Chars: value => value.length < 151 || "Max. 150 characters",
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            }    
        }
    },
    computed: {
      UserIsModerator(){
          if(this.$route.name === 'GroupNewArticle'){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.ArticlesModerator
          }
          else{
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Articles')
              if(moderator){
                return true
              }
              else{
                return false
              }
          }
        },
        NonModeratorRoute(){
          if(this.$route.name === 'GroupNewArticle'){
            return '/Group/'+this.$route.params.id
          }
          else{
            return '/Articles'
          }
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
       RouteName(){
          return this.$route.params.id ? this.GroupData.name+' Article' : 'GP Combrink Vehicle Sales & Finance'
        },
        ModeratorsIDStrings(){
          return this.Moderators.map(mod => {
            mod.id
          })
        },
        ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="560" height="315" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="560" height="315" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
        RelatedArticles () {
            return this.grouparticles.filter(article => {
                return article.articleid === this.articleid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    
    created() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if(this.UserIsModerator){
            this.FocusedViewToggle()
            this.IntranetViewToggle()
            this.GetRequestingUser()
            let moderatorsquery = db.collection('sitemoderators')
            if(!this.$route.params.id){
              this.GetSiteModerators(moderatorsquery)
            }
            else{
              this.GetGroup()
            }
        }
        else{
          this.$router.push(this.NonModeratorRoute)
        }

    },
    
    methods: {
      GetGroup(){
            db.collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                this.GroupData = snapshot.data()
                //let moderatorsquery = db.collection('groupmembers')
                let Moderator = {
                  Full_Name: this.userLoggedIn.Full_Name,
                  Name: this.userLoggedIn.Name,
                  Surname: this.userLoggedIn.Surname,
                  ModerationPermissions: this.userLoggedIn.ModerationPermissions,
                  id: this.userLoggedIn.id
                }
                this.Moderators.push(Moderator)
                this.SiteModerators.push(Moderator)
            })
        },
      ActivateTagLinkDialog(boolean){
        this.TagLinkDialog = boolean  
      },
      taglink(){
        this.TagLinkDialog = true
      },
      SaveSingleTag(tagitem){
        console.log(tagitem)
        this.TagLinkDialog = false  
        document.execCommand("createLink", false, tagitem.taglocation);
      },
      GetSiteModerators(query){
        let Moderator = {
          Full_Name: this.userLoggedIn.Full_Name,
          Name: this.userLoggedIn.Name,
          Surname: this.userLoggedIn.Surname,
          ModerationPermissions: this.userLoggedIn.ModerationPermissions,
          id: this.userLoggedIn.id
        }
        this.Moderators.push(Moderator)
        query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          
        })
      },
   DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
        onarticlecoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        GetArticles(){
        db.collection('sitearticles').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        
        },
        StoreArticle(){
          let vm = this
          let col = ''
          let routepath = ''
          let routeprop = ''
          let storagepath = ''
          if(this.$route.params.id){
            col = 'grouparticles'
            routepath = '/Group-Article/'+this.$route.params.id+'/Article/'
            routeprop = 'title'
          }
          else{
            col = 'sitearticles'
            routepath = '/Article/'
            routeprop = 'id'
          }
          let ref = db.collection(col).doc()
          let newdocid = ref.id
          vm.$emit('ActivateProcessing',true)
        let postelmnt = document.getElementById('NewBlogValue')
        console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        if(this.selectedcoverimage && this.ArticleTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewArticle = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.ArticleTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
          caption: this.Caption,
          ImageShy: false
        }
        if(this.$route.params.id){
          NewArticle.groupid = this.$route.params.id
        }
        let titleQuery = []
        let titleStringArray = NewArticle.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery.length = titleQuery.length-1
          console.log(titleQuery)
          NewArticle.titleQuery = titleQuery
          
        if(this.UserRecord.Profile_Photo){
          NewArticle.creatorimg = this.UserRecord.Profile_Photo
        }
        
        let filename = this.selectedcoverimage.name.split('.')[0]+'_'+this.selectedcoverimage.size+'.'+this.selectedcoverimage.name.split('.')[1]
        console.log(col)
        if(this.$route.params.id){
            storagepath = 'GroupArticles/'+this.$route.params.id+'/CoverImage/'+newdocid+'/'+new Date()+'/'+filename
          }
          else{
            storagepath = 'SiteBlogs/CoverImage/'+new Date()+'/'+filename
          }
        this.PrepareUpdateArticleCoverImageThumbnail(this.selectedcoverimage,newdocid,NewArticle,col,storagepath)
        }
        else if(this.ImageShy && this.ArticleTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewArticle = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.ArticleTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          ImageShy: this.ImageShy,
          caption: this.Caption,
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
        }
        if(this.$route.params.id){
          NewArticle.groupid = this.$route.params.id
        }
        let titleQuery = []
        let titleStringArray = NewArticle.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery.length = titleQuery.length-1
          console.log(titleQuery)
          NewArticle.titleQuery = titleQuery
        
        if(this.UserRecord.Profile_Photo){
          NewArticle.creatorimg = this.UserRecord.Profile_Photo
        }      
        

        
          vm.$emit('ActivateProcessing',false)
          db.collection(col).doc(newdocid).set(NewArticle).then(doc => {
              if(this.$route.params.id){
                vm.$router.push('/Group-Article/'+this.$route.params.id+'/Article/'+newdocid)                 
                }
                else{
                  vm.$router.push('/Article/'+newdocid)
                } 
               })
               console.log(NewArticle.post)          
        }
        else if(!this.selectedcoverimage && this.ArticleTitle && postinput){
            alert('You have to upload an image')
        }
        else if(!this.selectedcoverimage && !this.ArticleTitle && postinput){
            alert('You have to upload an image, and insert a title')
        }
        else if(!this.selectedcoverimage && !this.ArticleTitle && !postinput){
            alert('There is nothing to post!')
        }
        else if(!this.selectedcoverimage && this.ArticleTitle && !postinput){
            alert('There is no cover image, or post content!')
        }
        else if(this.selectedcoverimage && !this.ArticleTitle && postinput){
            alert('You have to insert a title')
        }
        else if(this.selectedcoverimage && !this.ArticleTitle && !postinput){
            alert('You have to insert a title, and content')
        }
        else if(this.selectedcoverimage && this.ArticleTitle && !postinput){
            alert('You have to insert content')
        }
        
      
        },

        PrepareUpdateArticleCoverImageThumbnail(file,newdocid,NewArticle,col,storagepath){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 1000
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storagepath+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      vm.UpdateArticleCoverImage(file,thumburl,newdocid,NewArticle,col,storagepath)
                    })
              })
      }
      },
      
        UpdateArticleCoverImage(picturefile,thumburl,newdocid,NewArticle,col,storagepath){
      let vm = this
      console.log('did thumbnail now major',picturefile,thumburl,newdocid)

      var storageRef = firebase.storage().ref(storagepath);
      var uploadTask = storageRef.put(picturefile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          console.log(url)
       
                NewArticle.coverimage = url
                NewArticle.coverimageThumbURL = thumburl
                NewArticle.CoverStorageRef = storagepath
                vm.$emit('ActivateProcessing',false)
               db.collection(col).doc(newdocid).set(NewArticle).then(doc => {
                   vm.$router.push('/Article/'+newdocid)
               })
               console.log(NewArticle.post)
                vm.CancelNewArticleDialog()
            
                
      })
    },
        CancelNewArticleDialog(){
            this.ArticleTitle = ''
            this.selectedcoverimage = ''
        },
      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
    linkyoutubevideo(){
        this.Youtubelink = prompt("Enter the link")
        let validation = this.rules.youtubeurl(this.Youtubelink)

        if(validation === true){
        
        // let finalembed = `<iframe width="560" height="315" src="`+this.ComputedYoutubelinkEmbed+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        document.execCommand("insertHTML", false, this.ComputedYoutubelinkEmbed)
        this.Youtubelink = ''
        }
        else{
            confirm(validation) && this.linkyoutubevideo()
        }
    },


    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'articlecontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.authorid = userdetails.id

              vm.author = userdetails.Name+' '+userdetails.Surname
              
              console.log('this is user name man '+vm.author)
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
console.log('this is user name man '+this.author)
      

        })
            },
        UpdateArticle(){
          // console.log(this.editor.scrollingContainer.innerHTML)

          //   const UpdatedArticle = {
          //       content: this.editor.scrollingContainer.innerHTML
          //   }

            db.collection('grouparticles').doc(this.articleid).update({
                content: UpdatedArticle.content
            });
              this.snackbar = true
            this.articlequil = false
        },
        onEditorChange() {
// alert('change')
        },
    // async getArticleInformation() {
    //     let articlecollection = db.collection('grouparticles')
    //     let docref = articlecollection.doc(this.articleid)
    //     docref.onSnapshot(snapshot => {
    //       // this.UpdateRequestStatus()
    //       var articledata = snapshot.data()
        
      
    //     this.name = articledata.name
    //     this.content = articledata.content
    //     this.articlecontent = articledata.content
        
    //     this.image = articledata.image
      

    //     })
    
    //     },
      OpenArticledialog() {
        this.articledialog = true
    },
    Closedialog() {
        this.articledialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
        this.ImagelocalURL = URL.createObjectURL(this.selectedFile)
      },
     save() {
       if(this.$route.params.id){
        //    if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                    let vm = this
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          let contentelmnt = document.getElementById('articlecontentdiv')
                    this.title = this.articletitle
                    this.content = contentelmnt.innerHTML
                    this.author = this.author
                    this.authorid = this.UserRecord.id
                    this.image = url
                    
                        
                        const article = { 
                              title: this.title,
                                content: this.content,
                                author: this.author,
                                authorid: this.authorid,
                                image: this.image,
                                PublishStatus: 'Draft'
                                
                            }  
                            vm.$emit('ActivateProcessing',false)
                            db.collection('grouparticles').add(article).then(function(doc) {
                          const systemactivity = {
                            user: vm.UserRecord,
                            contentvar: 'created a new Article',
                            location: '/GroupArticle/',
                            docname: article.title,
                            docid: doc.id,
                            type: 'New Article',
                          }                          
                          vm.$store.dispatch('createSystemActivity',systemactivity)
                          vm.$router.push('/GroupArticle/'+doc.id)
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        })
                        }
                        else {
        //    if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Site_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                    let vm = this
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          let contentelmnt = document.getElementById('articlecontentdiv')
                    this.title = this.articletitle
                    this.content = contentelmnt.innerHTML
                    this.author = this.author
                    this.authorid = this.UserRecord.id
                    this.image = url
                    
                        
                        const article = { 
                              title: this.title,
                                content: this.content,
                                author: this.author,
                                authorid: this.authorid,
                                image: this.image,
                                PublishStatus: 'Draft'
                                
                            }  
                            vm.$emit('ActivateProcessing',false)
                        db.collection('sitearticles').add(article).then(function(doc) {
                          const systemactivity = {
                            user: vm.UserRecord,
                            contentvar: 'created a new Article',
                            location: '/SiteArticle/',
                            docname: article.title,
                            docid: doc.id,
                            type: 'New Article',
                          }                          
                          vm.$store.dispatch('createSystemActivity',systemactivity)
                            vm.$router.push('/SiteArticle/'+doc.id)
                            })
                    
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
                        
          }
          
      }
      
    }
// }
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>
    
    

    