
  <template>
  <v-card>
  <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          dark
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>This is the Data Imports helpsheet. What would you like to know?</div>
      </v-sheet>
    </v-bottom-sheet>
  <v-banner two-line :sticky="true" class="topbasicoutlined" v-if="!EditingDataImport" height="80px">
  <v-container fluid>
     <v-card width ="100%" flat>
           <v-btn @click="ToggleHelp()" color="red" dark
                fab
                small
                absolute
                top
                right><v-icon>mdi-help</v-icon></v-btn>
                <v-btn @click="EditDataImport()" color="#012840" dark
                fab
                small
                absolute
                bottom
                right><v-icon>mdi-pencil</v-icon></v-btn>
          <v-layout row class="primary--text justify-center">
          <h2 class="white--text">DataImport - {{ Name  }}</h2>
           </v-layout>
           <v-layout row class="blue white--text justify-center">
          <h2 class="white--text">Status - {{ DataImportStatus }}</h2>
           </v-layout>
      </v-card>
    </v-container>
  </v-banner>

  <v-banner two-line :sticky="true" class="topbasicoutlined" v-if="EditingDataImport" height="80px">
   <v-container fluid>
   <v-card width ="100%" flat>
   <v-layout row class="primary white--text justify-center">
      <h2 class="white--text">DataImport- {{ Name }}</h2>
    </v-layout>
     <v-btn @click="CancelDataImportEditing()" color="red" dark
                fab
                small
                top
                left><v-icon>mdi-cancel</v-icon></v-btn>
                <v-btn @click="UpdateDataImport()" color="#012840" dark
                fab
                small
                absolute
                bottom
                right><v-icon>mdi-content-save</v-icon></v-btn>
      </v-card>
    </v-container>
  </v-banner>

   <v-layout class="justify-center">
     <v-flex lg8 md10>
      Imported on {{DataImportData.createdon.toDate()}} by 
      <v-avatar>
        <v-img :src="CreatedByProfilePic" v-if="CreatedByProfilePic"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!CreatedByProfilePic"/>
        </v-avatar>
        <a :href="'/SiteMember/'+DataImportData.createdby.id" target="_blank">{{DataImportData.createdby.Full_Name}}</a>

          <h3 class="primary--text">Tab 1</h3>
          <v-btn @click="DeleteImport()" v-if="DataImportStatus !== 'Reversed'">Delete Import</v-btn>
           <v-layout row v-if="!EditingDataImport" class="outline fieldcard">
        <v-col>
          <v-card outlined  height="100%">
              <v-card flat>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Name</h3><v-spacer></v-spacer><p>{{ Name }}</p>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">DataSet</h3><v-spacer></v-spacer><p>{{ DataSet }}</p>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">CSV File</h3><v-spacer></v-spacer> <input
                    style="display: none"
                    type="file"
                    @change="oncsvfileFileselected($event)"
                    ref="csvfilefileInputer">
                    <v-btn @click="oncsvfileUpload()" v-if="selectedcsvfileFile">Save</v-btn>
                     <v-btn @click="closecsvfile()" v-if="selectedcsvfileFile">Cancel</v-btn>
                     <v-btn :href="CSVFile" target="_new" color="green" dark>Download CSV</v-btn>
                  </v-layout>
                  </v-card-subtitle>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>


<v-layout row v-if="EditingDataImport" class="outline fieldcard">
          <h3 class="primary--text">Tab 1</h3><br><br>
          <v-row>
        <v-col><br>
          <v-card flat>
              <v-card flat>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>Name</h4><v-text-field v-model="Name"></v-text-field></div>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>DataSet</h4><v-combobox
                        v-model="DataSet"


                        :items="DataSetOptions"

                        item-text=""
                        hide-selected
                        label="DataSet"
                        small-chips
                        solo
                        flat
                      ></v-combobox></div>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>CSV File</h4><v-text-field v-model="CSVFile"></v-text-field></div>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                </v-card>
            </v-card>
          </v-col>
          </v-row>
          </v-layout>
      <v-tabs row>
        <v-tab>All Imports</v-tab>
            <v-tab-item>
              <v-data-table
                :headers="ImportHeaders"
                :items="AllImports"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <!-- <v-toolbar-title>All Data Imported</v-toolbar-title> -->
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field light
                      v-model="search"
                      append-icon="mdi-file-find-outline"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                <!-- <v-toolbar v-if="AllImports" flat dense dark color="primary">
                    <v-layout col class="justify-start">
                      <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
                      <v-icon @click="ResetFilters()">mdi-close</v-icon>
                      </v-layout>
                    </v-toolbar> -->
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    small
                    @click="ViewItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">Reset</v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
        <v-tab>Succesful Imports</v-tab>
            <v-tab-item>
              <v-data-table
                :headers="ImportHeaders"
                :items="SuccessfulImports"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <!-- <v-toolbar-title>All Data Imported</v-toolbar-title> -->
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field light
                      v-model="search"
                      append-icon="mdi-file-find-outline"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                <!-- <v-toolbar v-if="AllImports" flat dense dark color="primary">
                    <v-layout col class="justify-start">
                      <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
                      <v-icon @click="ResetFilters()">mdi-close</v-icon>
                      </v-layout>
                    </v-toolbar> -->
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    small
                    @click="ViewItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">Reset</v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
        <v-tab>Partially Failed</v-tab>
            <v-tab-item>
                <v-data-table
                :headers="ImportHeaders"
                :items="PartialFailures"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <!-- <v-toolbar-title>All Data Imported</v-toolbar-title> -->
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field light
                      v-model="search"
                      append-icon="mdi-file-find-outline"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                <!-- <v-toolbar v-if="AllImports" flat dense dark color="primary">
                    <v-layout col class="justify-start">
                      <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
                      <v-icon @click="ResetFilters()">mdi-close</v-icon>
                      </v-layout>
                    </v-toolbar> -->
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    small
                    @click="ViewItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">Reset</v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
      </v-tabs>
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="primary">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
components: {
},
  data() {
    return {
      DeletedItemList: 0,
      Name:  '',
      DataSet:  '',
      DataSetRecordPath: '',
      CSVFile:  '',
      DataImportStatus: '',
      csvfilefilesnackbar: false,
      selectedcsvfileFile: '',
      csvfilelocalURL: '',
      DataSetOptions: ['Contacts','Accounts','Designs','Opportunities','Quotes'],
      DataSetfilter: '',
      EditingDataImport: false,
      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      DataImportData: {},
      ImportHeaders: [],
      AllImports: [],
      SuccessfulImports: [],
      PartialFailures: [],
      snackbar: false,
      EditedDataImportsnackbar: false,
      DataImportEditdialog: false,
      newDataImportdialog: false,
      DataImportsArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      helpsheet: false,
      UsersArray: [],
    }
  },

  created(){
    this.GetDataImports()
    this.GetUsers()
    this.GetDataImport()
    window.addEventListener('scroll', this.handleScroll)

  },
  computed: {
  CreatedByProfilePic(){
      let userobj = this.UsersArray.find(obj => obj.id == this.DataImportData.createdby.id)
      if(userobj.Profile_Photo){
        return userobj.Profile_Photo
      }
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New DataImport' : 'Edit DataImport'
      },
    DataImportsSearched() {
      return this.DataImportsArray.filter(DataImport => {
        if(this.search !== ''){
        return DataImport.name.toLowerCase().includes(this.search.toLowerCase())
        }
        else {
          return DataImport
        }
      })
      },
      DataImportsFilteredandSearched() {
      return this.DataImportsSearched.filter(DataImport => {
        return DataImport
      })
      },
    },
  methods: {
  GetUsers(){
    db.collection('users').onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.UsersArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
  },
    ViewItem (item) {
      console.log("tableitem")
      console.log(item)
        this.$router.push('/'+this.DataSetRecordPath+'/'+item.id)
      },

    DeleteImport(){
      let listlength = this.AllImports.length
      confirm('Are you sure? This will delete ALL records imported with this Data Import, and records associated with imported '+this.DataSet+' will not longer have associated '+this.DataSet) && this.AllImports.map((dataimport,i) => {
        this.RemoveDataImport(listlength,dataimport.id)
      })
    },

    RemoveDataImport(listlength,id){
      let collectionname = this.DataSet.split(' ').join('').toLowerCase()
      
      db.collection(collectionname).doc(id).delete().then(doc => {
        this.DeletedItemList = this.DeletedItemList-1+2
        console.log(this.DeletedItemList)
        if(this.DeletedItemList === listlength){
          this.DataImportStatus = 'Reversed'
          this.UpdateDataImport()
        }
      })
      
    },

    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },

    ResetFilters() {},

    ResetSearch() {
      this.search = ''
    },
    CancelDataImportEditing(){
      this.EditingDataImport = false
    },
    EditDataImport() {
      this.EditingDataImport = true
    },


    GetDataImports() {
      db.collection('dataimports').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.DataImportsArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },


      GetDataImport() {
        this.mainrecordid = this.$route.params.id
        db.collection('dataimports').doc(this.$route.params.id).onSnapshot(snapshot => {
            var dataimport = snapshot.data()
            this.DataImportData = dataimport
            this.ImportHeaders = dataimport.importheaders
            let ActionsIndex = this.ImportHeaders.length-1
            this.ImportHeaders[ActionsIndex].text = 'Actions'
            this.ImportHeaders[ActionsIndex].value = 'action'
            this.AllImports = dataimport.allimports
            this.SuccessfulImports = dataimport.successfulimports
            this.PartialFailures = dataimport.partialfailures
            this.Name = dataimport.name
            this.editedItem.name = dataimport.name
            this.DataSet = dataimport.dataset
            this.DataSetRecordPath = dataimport.datasetrecordpath
            this.editedItem.dataset = dataimport.dataset
            this.CSVFile = dataimport.csvfile
            this.editedItem.csvfile = dataimport.csvfile
            this.DataImportStatus = dataimport.dataimportstatus
            })
        },

  oncsvfileUpload() {
        var storageRef = firebase.storage().ref('DataImport/' + 'csvfile/'+this.selectedcsvfileFile.name);
        var uploadTask = storageRef.put(this.selectedcsvfileFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('dataimports').doc(this.$route.params.id).update({
                    csvfile: url
            })
            this.selectedcsvfileFile = ''
                }),
                  this.csvfilefilesnackbar = true



      },
      oncsvfileFileselected(event) {
        this.selectedcsvfileFile = event.target.files[0]
        this.csvfilelocalURL = URL.createObjectURL(this.selectedcsvfileFile)
      },






      deleteItem (item) {
        const index = this.DataImportsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('dataimports').doc(item.id).delete() && this.DataImportsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.DataImportsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false

      },

      UpdateDataImport(){
      const EditedDataImport = {
    name: this.Name,
    dataset: this.DataSet,

        }

      const EditedUser = this.Owner

      db.collection('dataimports').doc(this.$route.params.id).update({
        name: EditedDataImport.name,
        dataset: EditedDataImport.dataset,
        dataimportstatus: this.DataImportStatus,
              });



              this.closeDataImporteditDialog
              this.EditedDataImportsnackbar = true




        this.CancelDataImportEditing()
      },

  }
}


</script>

<style>
.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}

.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
</style>
  