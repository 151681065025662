
<template>
<v-card
      flat
      class="transparent"
      style="margin-top: 150px;"
    >
<v-layout class="justify-center my-6">
<v-flex xs12 s12 md11 lg11>
        <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12" color="primary white--text">
          <v-toolbar-title  class="mediumoverline">
          <v-icon  class="mx-1" x-large color="white">mdi-face</v-icon>{{groupName}}</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
        <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You changed a User Role.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
            <v-snackbar v-model="contributorsnackbar" :timeout="8000" top color="success">
      <span>Awesome! You added a Contributor, view them all and finalize their Contributor Profiles in "Member Contributors".</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-text-field dark
        v-model="search"
        append-icon="mdi-file-find-outline"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
        <v-menu>
            <template v-slot:activator="{ on }">
              <v-icon  v-on="on" color="white">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item>
                Edit
              </v-list-item>
            </v-list>
        </v-menu>
          </v-toolbar>

          <v-layout  row class="justify-center">

          <v-flex class="my-3" xs12 sm6 md4 lg3  v-for="member in Computedgroupmembers" :key="member.id">
            <v-card flat class="transparent" max-width="300px" :href="'/SiteMember/'+member.id" target="_blank">
            <v-layout class="justify-center">
          
           <v-avatar
              color="grey"
              size="164" 
              class="elevatedsectionheader"                     
            >
            <v-img v-if="member.Profile_Photo" :src="member.Profile_Photo"></v-img>
            <v-img v-if="!member.Profile_Photo"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>
       
          </v-layout>
          
          <v-layout class="justify-center overlayed borderthicknobottom mx-3">
          <v-card tile flat height="100px" width="100%">
          </v-card>
          </v-layout>
          <v-layout class="justify-center borderthicknotop mx-3">
         
            <v-card tile flat  width="100%"  class="text-xs-center">              
              <h4 class="font-weight-thin black--text centertext my-1">{{member.Full_Name}}</h4>
              <h6 class="subheader centertext">{{member.Title}}</h6>
              <p class="centertext grey--text my-2" style="font-size:12px">
                Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
              </p>
            </v-card>
          </v-layout>
            </v-card>
          </v-flex>
          </v-layout>
    </v-flex>
 </v-layout>
</v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
props: ['UserRecord'],
    data() {
        return {
          Company: 'Nene Motors',
          hover: false,
          showerror: false,
          tags: [
                    'Auckland',
                    'Wellington',
                    'Very long string that would overflow'
                ],
          snackbar: false,
          contributorsnackbar: false,
              UserRolesType: [
      {index: 0, text: 'Follower'},
      {index: 1, text: 'Editor'},
      {index: 2, text: 'Administrator'},
        ],
        ListingContributors: false,
        EditingContributors: false,
        blogdialog: false,
        contributordialog: false,
        dialog: false,
        search: '',
        servicesearch: '',
        orgservicesearch: '',
        groupblogs: [],
        groupmembers: [],
        contributorgroups: [],
        contributingmembers: [],
        groupName: null,
        groups: [],
        users: [],
        // membersofgroup: [],
        groupid: this.$route.params.id,
        content: null,
        logo: '',
        image: '',
        description: null,
        Network: false,
        Editing: false,
        name: null,
        ServicestoOrganizations: [],
        DefaultServicestoOrganizations: [],
        ServicestoIndividuals: [],
        DefaultServicestoIndividuals: [],
        newservicetoindividual : {
          index: '', Name: '' ,checked: true
        },
        defaultservicetoindividual: {
          index: '', Name: '', check: true
        },
        editedContributor: {
              id: null,
              memberid: null,
              groupmemberid: null,
              ContributionCategory: null,
              ContributetoIndividuals: false,
              IndividualContributionDescription: null,
              OrganizationContributionDescription: null,
              ContributetoOrganizations: false,
              FreeConsultation: false,
              ServiceCategorytoIndividuals: null,
              //consulting,legal,financial,technical specialist,other
              ServiceCategorytoOrganizations: null,
              //consulting,legal,financial,technical specialist,other
              ServicestoIndividuals: [],
              ServicestoOrganizations: [],
              DefaultServicestoOrganizations: [],
              DefaultServicestoIndividuals: [],         


        },
            editedItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            defaultItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            blogtitle: null,
            blogcontent: null,
            image: null,
            //userid: this.$store.state.user.id,
            UserisMember: false,
            UserisAdmin: false,
            tetempusid: "'GNagVng6n1VgjtSXXrHDdEejjM93'"+","+"'Sk7cvSO6uXMJdN2qwJW5C9LSwzj1'", 
            Changes: false,
            GroupCategory: null,
            Contributorprofilefield: '',
            helpnewsheet: false,
            // MinistryContributionstoIndividialNames: '',
            MinistryContributionstoIndividualArr: [],
            BusinessContributionstoIndividualArr: [],
            FamilyContributionstoIndividualArr: [],
            MinistryContributionstoOrganizationsArr: [],
            BusinessContributionstoOrganizationsArr: [],
            FamilyContributionstoOrganizationsArr: [],
            HasOrgServices: false,
            HasIndServices: false,      
        
        }
    },
    computed: {
      Computedgroupmembers(){
        return this.users.map(member => {
          if(member.Created_On){
            member.MembershipDate = this.TimestampFormatterSTRING(member.Created_On.toDate())
          }
          
          return member
          
        }).filter(member => {
          return member.Full_Name.toLowerCase().includes(this.search.toLowerCase())
        })
                    
       },
    },
        created() {    
          setTimeout(() => {
            console.log(this.UserRecord)
          }, 1000); 
             this.IntranetViewToggle()
        this.getUsers()
    },

     methods: {
       IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
       TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
        ToggleHelp() {         
            this.helpnewsheet = true
          },

        editItem (item) {
        this.editedIndex = this.GroupMembersWithSearch.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        // this.Editing = true
      },

      

   
        
    GetUserBUInformation(userobj){
      let vm = this
      return new Promise(function(resolve, reject) {
      db.collection('businessunits').doc(userobj.Business_Unit.id).onSnapshot(snapshot => {
          let buobj = snapshot.data()
          userobj.Business_Unit = buobj
          userobj.Business_Unit.id = userobj.Business_Unit.id
          resolve(userobj)
        })
      })
    },
        
    getUsers() {
      let vm = this
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let userobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          if(userobj.Business_Unit){
            this.GetUserBUInformation(userobj).then(result => {
            vm.users.push(result)
          })
          }
          else{
            this.users.push(userobj)
          }
          
        }  
      })
    })    
        },
          
            
     }
}
</script>

<style>
.tags-input {
    border: 1px solid #333;
    display: inline-block;
}
.UserName {
    font-size: 10px;
    font-weight: bold;
    color: #474747
}
.Memo {
      font-size: 10px;
}
.userprofileBG {
  align-self: center
}
.v-avatar.outlined {
                  border: 8px solid #048abf;
                  border-radius:100%;

}
.searchguide {
  color: #e4003b;
  font-size: 14px
}

</style>

    

    