
import store from '@/store'
import Vue from 'vue'
import firebase from 'firebase';
import VueRouter from 'vue-router'
import Glossary from '@/components/System/Glossary';
import NoticeNew from '@/components/System/Notices/NoticeNew'
import NoticeSingle from '@/components/System/Notices/NoticeSingle'
import SiteMembers from '@/components/System/SiteMembers'
import SiteMemberSingle from '@/components/System/SiteMemberSingle';
import UserInvite from '@/components/System/UserInvite';
import PasswordReset from '@/components/System/PasswordReset'
import ExpiredLink from '@/components/System/ExpiredLink'
import RMAdmin from '@/components/System/RMAdministration.vue'
import SecurityOverview from '@/components/System/SecurityOverview.vue'
import LandingPages from '@/components/Campaigns/LandingPages'
import NewLandingPage from '@/components/Campaigns/NewLandingPage';
import LandingPageSingle from '@/components/Campaigns/LandingPageSingle';
import BannerAds from '@/components/Campaigns/BannerAds'
import Campaigns from '@/components/Campaigns/Campaigns';
import CampaignBuilder from '@/components/Campaigns/CampaignBuilder';
import NewBannerAd from '@/components/Campaigns/NewBannerAd';
import Dashboards from '@/components/Dashboards';
import FeaturedMember from '@/components/FeaturedMemberSingle';
import SiteBlogs from '@/components/Blog/SiteBlog/SiteBlogs';
import SiteBlogSingle from '@/components/Blog/SiteBlog/Single';
import SiteBlogNew from '@/components/Blog/SiteBlog/New/Single';
import SiteArticles from '@/components/Articles/SiteArticle/SiteArticles';
import SiteArticleSingle from '@/components/Articles/SiteArticle/Single';
import SiteArticleNew from '@/components/Articles/SiteArticle/New/Single';
import SystemTrainingDocument from '@/components/System/SystemTrainingDocument';
import Roadmap from '../components/Roadmap'
import SystemTicketsLogging from '../components/SystemTicketsLogging'
import SystemTickets from '../views/SystemTickets'
import Login from '../views/login'
import PermissionError from '@/views/PermissionError'
import BusinessUnits from '@/views/BusinessUnits'
import Users from '@/views/Users'
import MyProfile from '../views/MyProfile'
import ChatbotBuilder from '@/components/System/Chatbot/ChatbotBuilder'
import DataImports from '@/views/DataImports'
import DataImport from '@/views/DataImport/Single'
import Activities from '@/views/Activities';
import Activity from '@/views/Activity/Single';
import MyActivities from '@/views/MyActivities'
import MySchedule from '@/components/MySchedule'
import MyTeamSchedule from '@/components/MyTeamSchedule'
import MyActivitiesbyType from '@/views/Activities/MyActivitiesbyType';
import MyTeamActivities from '@/views/MyTeamActivities'
import MyTeamMemberActivities from '@/views/MyTeamMemberActivities'
import TeamMemberActivities from '@/views/TeamActivities/TeamMemberActivities'
import BusinessUnit from '@/views/BusinessUnit/Single';
import CurrentCustomers from '@/views/CurrentCustomers';
import CurrentCustomer from '@/views/CurrentCustomer/Single';
import CurrentCustomerWiki from '@/views/CurrentCustomer/Wiki';
import CustomerClaims from '@/views/CustomerClaims';
import CustomerClaim from '@/views/CustomerClaim/Single';
import CustomerClaimWiki from '@/views/CustomerClaim/Wiki';
import Dealerships from '@/views/Dealerships';
import Dealership from '@/views/Dealership/Single';
import SalesContracts from '@/views/SalesContracts';
import SalesContract from '@/views/SalesContract/Single';
import SalesContractWiki from '@/views/SalesContract/Wiki';
import User from '@/views/User/Single';
import ContactUs from '@/pages/ContactUs';
import Home from '@/pages/Home';
import Intranet from '@/pages/Intranet';
import Welcome from '@/pages/Welcome';
import DashboardBuilder from '@/components/Dashboards/DashboardBuilder'
import SocialMediaPlatforms from '@/components/SocialMedia/SocialMediaPlatforms'
import LinkedInAuth from '@/components/SocialMedia/LinkedInAuth'
import SocialDashboard from '@/components/Dashboards/SocialDashboard'
import MyDashboard from '@/components/Dashboards/MyDashboard'
import Goals from '@/components/Goals/Goals'
import MyAccount from '@/components/Client/MyAccount'
import Component404 from '@/components/SystemNavigation/Component404'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name:'404', 
    component: Component404
  },
  {
    path: '/',
    name: 'defaultpage',
    component: Home
  },
  {
    path: '/MyAccount',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/LinkedInAuth',
    name: 'LinkedInAuth',
    component: LinkedInAuth
  },
  {
    path: '/SocialDashboard',
    name: 'SocialDashboard',
    component: SocialDashboard
  },
  {
    path: '/GroupDashboard/:id',
    name: 'GroupDashboard',
    component: MyDashboard
  },
  {
    path: '/MyDashboard',
    name: 'MyDashboard',
    component: MyDashboard
  },
  {
    path: '/SocialMediaPlatforms',
    name: 'SocialMediaPlatforms',
    component: SocialMediaPlatforms
  },
  {
    path: '/Goals',
    name: 'Goals',
    component: Goals
  },
  {
    path: '/DashboardBuilder/:slug',
    name: 'DashboardBuilder',
    component: DashboardBuilder
  },
  {
    path: '/CustomDashboard/:slug',
    name: 'CustomDashboard',
    component: DashboardBuilder,
    meta: {
      RouteEventID: 1037,
    }
  },
  {
    path: '/Glossary',
    name: 'Glossary',
    component: Glossary,
    meta: {
      RouteEventID: 1034,
      IsTopic: true,
      TopicName: 'Glossary',
      TopicCategory: 'General'
    }
  },
  {
    path: '/BusinessMember/:id',
    name: 'BusinessMember',
    component: FeaturedMember
  },
  {
    path: '/Blogs',
    name: 'SiteBlogs',
    component: SiteBlogs,
    meta: {
      RouteEventID: 1001,
      IsTopic: true,
      TopicCategory: 'Social',
      TopicName: 'Blogs'
    }
  },
  {
    path: '/Blog/:id',
    name: 'SiteBlogSingle',
    component: SiteBlogSingle,
    meta: {
      RouteEventID: 1002,
      icon: 'mdi-blogger',
      type: 'Social',
      identifier: 'title',
      subcat: 'Site Blog',
      viewicon: 'mdi-blogger',
      collection:'siteblogs'
      }
  },
  {
    path: '/NewBlog',
    name: 'SiteBlogNew',
    component: SiteBlogNew
  },
    {
    path: '/Articles',
    name: 'SiteArticles',
    component: SiteArticles,
    meta: {
      RouteEventID: 1003,
      IsTopic: true,
      TopicCategory: 'Social',
      TopicName: 'Articles'
    }
  },
  {
    path: '/Article/:id',
    name: 'SiteArticleSingle',
    component: SiteArticleSingle,
    meta: {
      RouteEventID: 1004,
      icon: 'mdi-poll-box',
      type: 'Social',
      identifier: 'title',
      subcat: 'Site Article',
      viewicon: 'mdi-poll-box',
      collection:'sitearticles'
      }
  },
  {
    path: '/NewArticle',
    name: 'SiteArticleNew',
    component: SiteArticleNew
  },
  
  {
    path: '/Dashboards',
    name: 'Dashboards',
    component: Dashboards,
    meta: {
      IsTopic: true,
      TopicCategory: 'Social',
      TopicName: 'Dashboards'
    }
  },
  {
    path: '/UserInvite/:id',
    name: 'UserInvite',
    component: UserInvite
  },
  {
    path: '/Password-Reset/:id',
    name: 'PasswordReset',
    component: PasswordReset,
    props: true
  },
  {
    path: '/Expired-Link',
    name: 'ExpiredLink',
    component: ExpiredLink,
    props: true
  },
  {
    path: '/RMAdministration',
    name: 'RMAdmin',
    component: RMAdmin
  },
  {
    path: '/Campaigns',
    name: 'campaigns',
    component: Campaigns,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Campaigns'
    }
  },
  {
    path: '/CampaignBuilder',
    name: 'campaignbuilder',
    component: CampaignBuilder,
  },
  {
    path: '/BannerAds',
    name: 'bannerads',
    component: BannerAds,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Banner Ads'
    }
  },
  {
    path: '/LandingPages',
    name: 'landingpages',
    component: LandingPages,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Landing Pages'
    }
  },
  {
    path: '/BannerAd/Edit/:id',
    name: 'newbannerad',
    component: NewBannerAd,
    props:true
  },
  {
    path: '/LandingPage/Edit/:id',
    name: 'newlandingpage',
    component: NewLandingPage,
    props:true
  },
  {
    path: '/SecurityOverview',
    name: 'SecurityOverview',
    component: SecurityOverview
  },
  {
    path: '/Roadmap',
    name: 'Roadmap',
    component: Roadmap
  },
  {
    path: '/BusinessUnits',
    name: 'BusinessUnits',
    component: BusinessUnits,
    meta: {
      icon: 'mdi-auto-fix',
      RouteEventID: 1029,
      type: 'Data Tables',
      identifier: 'Name',
      subcat: 'Business Units',
      viewicon: 'mdi-security-network',
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Business Units' 
      },
    Security: {
      SecurityType: 'Signed In',
    }
  },
  {
    path: '/Users',
    name: 'Users',
    component: Users,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Users'
    }
  },
  {
    path: '/DataImports',
    name: 'DataImports',
    component: DataImports,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Imports'
    }
  },
  {
    path: '/DataImport/:id',
    name: 'DataImport',
    component: DataImport,
  },
  {
    path: '/ChatbotBuilder',
    name: 'ChatbotBuilder',
    component: ChatbotBuilder,
  },
  
  {
    path: '/LandingPage/:id',
    name: 'landingpagesingle',
    component: LandingPageSingle,
    props:true,
    meta: {
      RouteEventID: 1032,
    }
  },
  {
    path: '/NoticeSingle/:id',
    name: 'noticesingle',
    component: NoticeSingle,
    props:true,
    meta: {
      RouteEventID: 1031,
      IsTopic: true,
      TopicCategory: 'Moderators',
      TopicName: 'Notices'
    }
  },
  {
    path: '/NoticeNew/:id',
    name: 'noticenew',
    component: NoticeNew,
    props:true
  },
  {
    path: '/SiteMembers',
    name: 'SiteMembers',
    component: SiteMembers,
    meta: {
      RouteEventID: 1035,
    }
  },
   {
    path: '/SiteMember/:id',
    name: 'SiteMemberSingle',
    component: SiteMemberSingle,
    meta: {
      RouteEventID: 1036,
    }
  },
  {
    path: '/SystemTraining/:id',
    name: 'SystemTrainingDocument',
    component: SystemTrainingDocument
  },
  {
    path: '/SystemTickets/Logging',
    name: 'SystemTicketsLogging',
    component: SystemTicketsLogging,
    
  },  
  {
    path: '/SystemTickets/:id',
    name: 'SystemTicketsSingle',
    component: SystemTickets,
    
  },
  {
    path: '/SystemTickets',
    name: 'SystemTickets',
    component: SystemTickets,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'System Tickets'
    }
  }, 
  {
    path: '/PermissionError',
    name: 'PermissionError',
    component: PermissionError
  },
  {
    path: '/Activities',
    name: 'activities',
    component: Activities,
    meta: {
      IsTopic: true,
      TopicCategory: 'Teams',
      TopicName: 'Activities'
    }
  },
  {
    path: '/MyActivities/:id',
    name: 'MyActivitiesbyType',
    component: MyActivitiesbyType,
  },
  {
    path: '/MyTeamSchedule',
    name: 'MyTeamSchedule',
    component: MyTeamSchedule,
  },
  {
    path: '/MySchedule',
    name: 'MySchedule',
    component: MySchedule,
  },
  
  {
    path: '/MyTeamActivities',
    name: 'MyTeamActivities',
    component: MyTeamActivities,
  },
  {
    path: '/MyTeamActivities/:id',
    name: 'MyTeamMemberActivities',
    component: MyTeamMemberActivities,
  },
  {
    path: '/TeamMemberActivities/:id/:prop',
    name: 'TeamMemberActivities',
    component: TeamMemberActivities,
  },
  {
    path: '/Activity/:id',
    name: 'activity',
    component: Activity
  },
  {
    path: '/MyActivities',
    name: 'MyActivities',
    component: MyActivities,
  },
  {
    path: '/Login',
    name: 'login',
    component: Login
  },
  {
    path: '/MyProfile',
    name: 'myprofile',
    component: MyProfile,
    meta: {
      IsTopic: true,
      TopicCategory: 'Users',
      TopicName: 'My Profile'
    }
  },
  {
    path: '/BusinessUnit/:id',
    name: 'businessunit',
    component: BusinessUnit,
    props: true,
    meta: {
      icon: 'mdi-security-network',
      identifier: 'Name',
      RouteEventID: 1030,
      type: 'DB Records',
      subcat: 'Business Unit',
      viewicon: 'mdi-library',
      }
  },
  {
    path: '/CurrentCustomers',
    name: 'currentcustomers',
    component: CurrentCustomers,
    meta: {
      id: 'Current_Customers',
      icon: 'mdi-tag-faces',
      RouteEventID: 1029,
      type: 'Data Tables',
      identifier: 'Name',
      subcat: 'Current Customers',
      viewicon: 'mdi-file-table-box',
      IsTopic: true,
      TopicCategory: 'Database',
      TopicName: 'Current Customers'
      },
    Security: {
      SecurityType: 'Custom Roles',
        CreateisCustom: true,
        CreatePermission: 'Custom Role',
        CustomCreateRoles: [
  "Office Administrator"
],
        GetisCustom: true,
        GetPermission: 'Custom Role',
        CustomGetRoles: [
  "Office Administrator"
],
        ListisCustom: true,
        ListPermission: 'Custom Role',
        CustomListRoles: [
  "Office Administrator"
],
        EditisCustom: true,
        EditPermission: 'Custom Role',
        CustomUpdateRoles: [
  "Office Administrator"
],
        DeleteisCustom: true,
        DeletePermission: 'Custom Role',
        CustomDeleteRoles: [
  "Office Administrator"
],

    }
  },
  {
    path: '/CurrentCustomer/:id',
    name: 'currentcustomer',
    component: CurrentCustomer,
    props: true,
    meta: {
      icon: 'mdi-tag-faces',
      identifier: 'Name',
      RouteEventID: 1030,
      type: 'DB Records',
      subcat: 'Current Customer',
      viewicon: 'mdi-library',
      }
  },
    {
    path: '/CurrentCustomer-Wiki/:id',
    name: 'currentcustomer-wiki',
    component: CurrentCustomerWiki,
    meta: {
      icon: 'mdi-tag-faces',
      RouteEventID: 1033,
      type: 'Wiki Pages',
      subcat: 'Current Customer',
      viewicon: 'mdi-wikipedia',
      IsTopic: true,
      TopicCategory: 'Wiki',
      TopicName: 'Current Customers Wiki'
      }
  },
  {
    path: '/CustomerClaims',
    name: 'customerclaims',
    component: CustomerClaims,
    meta: {
      id: 'Customer_Claims',
      icon: 'mdi-shield-car',
      RouteEventID: 1029,
      type: 'Data Tables',
      identifier: 'Title',
      subcat: 'Customer Claims',
      viewicon: 'mdi-file-table-box',
      IsTopic: true,
      TopicCategory: 'Database',
      TopicName: 'Customer Claims'
      },
    Security: {
      SecurityType: 'Custom Roles',
        CreateisCustom: true,
        CreatePermission: 'Custom Role',
        CustomCreateRoles: [
  "Office Administrator"
],
        GetisCustom: true,
        GetPermission: 'Custom Role',
        CustomGetRoles: [
  "Office Administrator"
],
        ListisCustom: true,
        ListPermission: 'Custom Role',
        CustomListRoles: [
  "Office Administrator"
],
        EditisCustom: true,
        EditPermission: 'Custom Role',
        CustomUpdateRoles: [
  "Office Administrator"
],
        DeleteisCustom: false,
        DeletePermission: 'Admin',
        CustomDeleteRoles: [],

    }
  },
  {
    path: '/CustomerClaim/:id',
    name: 'customerclaim',
    component: CustomerClaim,
    props: true,
    meta: {
      icon: 'mdi-shield-car',
      identifier: 'Title',
      RouteEventID: 1030,
      type: 'DB Records',
      subcat: 'Customer Claim',
      viewicon: 'mdi-library',
      }
  },
    {
    path: '/CustomerClaim-Wiki/:id',
    name: 'customerclaim-wiki',
    component: CustomerClaimWiki,
    meta: {
      icon: 'mdi-shield-car',
      RouteEventID: 1033,
      type: 'Wiki Pages',
      subcat: 'Customer Claim',
      viewicon: 'mdi-wikipedia',
      IsTopic: true,
      TopicCategory: 'Wiki',
      TopicName: 'Customer Claims Wiki'
      }
  },
  {
    path: '/Dealerships',
    name: 'dealerships',
    component: Dealerships,
    meta: {
      id: 'Dealerships',
      icon: 'mdi-car-multiple',
      RouteEventID: 1029,
      type: 'Data Tables',
      identifier: 'Dealer_Name',
      subcat: 'Dealerships',
      viewicon: 'mdi-file-table-box',
      IsTopic: true,
      TopicCategory: 'Database',
      TopicName: 'Dealerships'
      },
    Security: {
      SecurityType: 'Custom Roles',
        CreateisCustom: true,
        CreatePermission: 'Custom Role',
        CustomCreateRoles: [
  "Office Administrator"
],
        GetisCustom: true,
        GetPermission: 'Custom Role',
        CustomGetRoles: [
  "Office Administrator"
],
        ListisCustom: true,
        ListPermission: 'Custom Role',
        CustomListRoles: [
  "Office Administrator"
],
        EditisCustom: true,
        EditPermission: 'Custom Role',
        CustomUpdateRoles: [
  "Office Administrator"
],
        DeleteisCustom: false,
        DeletePermission: 'Admin',
        CustomDeleteRoles: [],

    }
  },
  {
    path: '/Dealership/:id',
    name: 'dealership',
    component: Dealership,
    props: true,
    meta: {
      icon: 'mdi-car-multiple',
      identifier: 'Dealer_Name',
      RouteEventID: 1030,
      type: 'DB Records',
      subcat: 'Dealership',
      viewicon: 'mdi-library',
      }
  },
  {
    path: '/SalesContracts',
    name: 'salescontracts',
    component: SalesContracts,
    meta: {
      id: 'Sales_Contracts',
      icon: 'mdi-bank',
      RouteEventID: 1029,
      type: 'Data Tables',
      identifier: 'Name',
      subcat: 'Sales Contracts',
      viewicon: 'mdi-file-table-box',
      IsTopic: true,
      TopicCategory: 'Database',
      TopicName: 'Sales Contracts'
      },
    Security: {
      SecurityType: 'Custom Roles',
        CreateisCustom: true,
        CreatePermission: 'Custom Role',
        CustomCreateRoles: [
  "Office Administrator"
],
        GetisCustom: true,
        GetPermission: 'Custom Role',
        CustomGetRoles: [
  "Office Administrator"
],
        ListisCustom: true,
        ListPermission: 'Custom Role',
        CustomListRoles: [
  "Office Administrator"
],
        EditisCustom: true,
        EditPermission: 'Custom Role',
        CustomUpdateRoles: [
  "Office Administrator"
],
        DeleteisCustom: false,
        DeletePermission: 'Admin',
        CustomDeleteRoles: [],

    }
  },
  {
    path: '/SalesContract/:id',
    name: 'salescontract',
    component: SalesContract,
    props: true,
    meta: {
      icon: 'mdi-bank',
      identifier: 'Name',
      RouteEventID: 1030,
      type: 'DB Records',
      subcat: 'Sales Contract',
      viewicon: 'mdi-library',
      }
  },
    {
    path: '/SalesContract-Wiki/:id',
    name: 'salescontract-wiki',
    component: SalesContractWiki,
    meta: {
      icon: 'mdi-bank',
      RouteEventID: 1033,
      type: 'Wiki Pages',
      subcat: 'Sales Contract',
      viewicon: 'mdi-wikipedia',
      IsTopic: true,
      TopicCategory: 'Wiki',
      TopicName: 'Sales Contracts Wiki'
      }
  },
  {
    path: '/User/:id',
    name: 'user',
    component: User,
    props: true,
    meta: {
      icon: 'mdi-face',
      identifier: 'Full_Name',
      RouteEventID: 1030,
      type: 'DB Records',
      subcat: 'User',
      viewicon: 'mdi-library',
      }
  },
  {
    path: '/ContactUs',
    name: 'contactus',
    component: ContactUs,
    meta: {
      RouteEventID: 1027,
      id: '0PYHtiuOzzqo3KGwUjtp',
      icon: 'mdi-deskphone',
      type: 'Pages',
      subcat: 'Contact Us',
      viewicon: 'mdi-book-open-page-variant',
      IsTopic: true,
      TopicCategory: 'Pages',
      TopicName: 'Contact Us Page'
      }
  },
  {
    path: '/Home',
    name: 'home',
    component: Home,
    meta: {
      RouteEventID: 1028,
      id: 'Home',
      icon: 'mdi-apps',
      type: 'Pages',
      subcat: 'Home',
      viewicon: 'mdi-book-open-page-variant',
      IsTopic: true,
      TopicCategory: 'Pages',
      TopicName: 'Home Page'
      }
  },
  {
    path: '/Intranet',
    name: 'intranet',
    component: Intranet,
    meta: {
      RouteEventID: 1027,
      id: 'Intranet',
      icon: 'mdi-directions-fork',
      type: 'Pages',
      subcat: 'Intranet',
      viewicon: 'mdi-book-open-page-variant',
      IsTopic: true,
      TopicCategory: 'Pages',
      TopicName: 'Intranet Page'
      }
  },
  {
    path: '/Welcome',
    name: 'welcome',
    component: Welcome,
    meta: {
      RouteEventID: 1027,
      id: 'Welcome',
      icon: 'mdi-sign-direction',
      type: 'Pages',
      subcat: 'Welcome',
      viewicon: 'mdi-book-open-page-variant',
      IsTopic: true,
      TopicCategory: 'Pages',
      TopicName: 'Welcome Page'
      }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  const functions = firebase.functions();
  const RecordSiteAnalytics = functions.httpsCallable('RecordSiteAnalytics');
  
  if(to.meta && to.meta.type || to.meta && to.meta.RouteEventID){
    setTimeout(() => {
     console.log('logging view '+to.meta.type)
    let obj = { type: 'internal',RouteName: to.meta.type,page_title:to.name ,page_path: to.path}
    if(store.getters.user){
      obj.userid = store.getters.user.id
      obj.userfullname = store.getters.user.Full_Name
    }
    if(to.params.id){
      obj.recordid = to.params.id
    }
    if(to.meta.RouteEventID){
      obj.RouteEventID = to.meta.RouteEventID
    }
    console.log(obj)
      RecordSiteAnalytics(obj).then(result => {
        console.log(result)
      }) 
    }, 2000);
        
    //firebase.analytics().logEvent('page_view', obj);
  }
  return next();

});
export default router
    