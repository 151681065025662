

<template>
    <v-content>
        <!-- <v-card  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover'  ,position: 'fixed',top: '30px'}" width="100%" class="stickytop" flat tile> -->
               <v-toolbar height="50" class="primary stickytop3" dark style="z-index:100" flat>
             <!-- class="hidden-md-and-up" -->
          
          <!--<v-toolbar-title class="mediumoverline hidden-sm-and-down">
            Intranet Menu
          </v-toolbar-title> -->
          <v-layout v-if="UserRecord && UserRecord.id">
              <v-list dark class="transparent">
                <v-list-item>
                  <v-menu
                      bottom
                      left
                      offset-y
                      :close-on-content-click="false"
                      >
                      <template v-slot:activator="{ on }">
                      <v-avatar v-on="on" size="40">
                        <img v-if="UserRecord.Profile_Photo "
                          :src="UserRecord.Profile_Photo " 
                          style="object-fit: cover;"             
                        >
                        <img v-if="!UserRecord.Profile_Photo "
                          src="@/assets/BlankProfilePic.png"     
                          style="object-fit: cover;"         
                        >
                      </v-avatar>
                      </template>
                      <v-list max-width="300" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
                      <v-list-item>
                        <!-- <v-list-item-content > -->
                        <v-list-item-avatar class="blue" tile size="80">
                          <img v-if="UserRecord.Profile_Photo "
                          :src="UserRecord.Profile_Photo "    
                          style="object-fit: cover;"          
                          >
                          <img v-if="!UserRecord.Profile_Photo "
                            src="@/assets/BlankProfilePic.png"   
                            style="object-fit: cover;"           
                          >                      
                          </v-list-item-avatar>
                        <!-- </v-list-item-content> -->
                        <v-list-item-content >
                          <v-list-item-title>{{UserRecord.Full_Name}}</v-list-item-title>                    
                          <v-list-item-subtitle>
                            <router-link  :to="'/MyProfile'">View My Profile</router-link>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{UserRecord.Email}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Online: Online Now
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                        <v-list-item @click="ActivateTicketLog()">
                        <v-list-item-avatar>
                        <v-icon>mdi-ticket-confirmation</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            Log Ticket 
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="ConfirmlogoutFromFirebase">
                        <v-list-item-avatar>
                        <v-icon>mdi-exit-to-app</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            Sign Out 
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                </v-list-item>
        </v-list>
        <!-- <div id="notificationbar"> -->
          <v-list dark class="transparent">
                  <v-list-item>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                          <template v-slot:activator="{ on }">
                            <v-badge :color="NotificationsColor" overlap
                              dark>

                              <v-icon
                              dark
                              v-on="on">
                              mdi-bell-check
                            </v-icon>

                            <span slot="badge"> {{UnreadNotifications.length}} </span>
                            </v-badge>
                           
                          </template>

                          <v-tabs dark>
                                <v-tab>Unread</v-tab>
                                <v-tab-item>
                                <v-list light v-if="UnreadNotifications.length>0">
                                  <h3 class="viewingnotificationheader">New Notifications</h3><br>
                                  <v-list-item v-for="notification in UnreadNotifications" :key="notification.itemObjKey" :class="{newbasicoutlined: !notification.Read}" @click="NotificationRead(notification)">
                                      <v-list-item-content class="notificationheader">
                                      {{notification.Header}}
                                      <p class="notificationcontent">{{notification.Message}}<span class="tooltiptext"><h4>{{notification.CreatorFullName}}</h4>{{notification.Content.substr(0, 50)}}</span></p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-list class="viewingnotification" light v-if="UnreadNotifications.length===0">
                                  <h3 class="viewingnotificationheader">New Notifications</h3><br>
                                  <v-list-item>
                                    <v-list-item-content class="notificationheader">
                                      There are no new notifications<br>
                                      <p class="notificationcontent">Click on the "Read" tab to see Read notifications</p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                </v-tab-item>
                                <v-tab>Read</v-tab>
                                <v-tab-item>
                                <v-list class="viewingnotification" light v-if="ReadNotifications.length>0">
                                  <h3 class="viewingnotificationheader">Read Notifications</h3><br>
                                  <v-list-item v-for="notification in ReadNotifications" :key="notification.itemObjKey" :class="{newbasicoutlined: notification.Read}">
                                    <v-list-item-content class="notificationheader">
                                      {{notification.Header}}
                                      <p class="notificationcontent">{{notification.Message}}<span class="tooltiptext"><h4>{{notification.CreatorFullName}}</h4>{{notification.Content.substr(0, 50)}}</span></p>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                      <v-btn small @click="MarkasUnread(notification)">Mark Unread</v-btn><v-btn small @click="ViewItem(notification)">View</v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-list class="viewingnotification" light v-if="ReadNotifications.length===0">
                                  <h3 class="viewingnotificationheader">Read Notifications</h3><br>
                                  <v-list-item>
                                    <v-list-item-content class="notificationheader">
                                      There are no Read notifications<br>
                                      <p class="notificationcontent">Click on the "Unread" tab to see New notifications</p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                </v-tab-item>
                              </v-tabs>

                            </v-menu>
                      </v-list-item>                                            
                  </v-list>
                  <v-list dark class="transparent">
                  <v-list-item>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                          <template v-slot:activator="{ on }">
                            <v-badge :color="MessagesColor" overlap
                              dark>

                              <v-icon
                              dark
                              v-on="on">
                              mdi-message
                            </v-icon>

                            <span slot="badge"> {{UnreadMessages.length}} </span>
                            </v-badge>
                          </template>
                                <v-list flat width="100%">
                                  <h3 class="viewingnotificationheader">Conversations</h3><br>
                                  <v-card @click="ActivateMessage(msg)" outlined flat width="350px" class="messageboxheader" v-for="msg in ConversingUsers" :key="msg.itemObjKey">
                                  <v-list two-line :class="msg.Color">
                                    <v-list-item>
                                      
                                        <v-list-item-avatar  size="35">
                                      <!-- <v-avatar size="35"> -->
                                    <v-img :src="msg.Profile_Photo" v-if="msg.Profile_Photo"/>
                                        <v-img src="@/assets/BlankProfilePic.png" v-if="!msg.Profile_Photo"/>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-text="msg.Full_Name"></v-list-item-title>
                                          <v-list-item-subtitle v-if="msg.lastMessage" v-text="msg.lastMessage.message"></v-list-item-subtitle>
                                        </v-list-item-content>
                                          <v-spacer>
                                            </v-spacer>
                                            <span style="font-size:8px">{{msg.lastMessage.SendTime}}</span>
                                    </v-list-item>
                                    
                                  </v-list>
                                </v-card>
                                  
                                </v-list>                                
                            </v-menu>
                      </v-list-item>                                            
                  </v-list>
                  <v-spacer></v-spacer>
                   <v-toolbar-title class="mediumoverline hidden-sm-and-down">
                <v-text-field style="padding-top:20px;" label="Search" append-icon="mdi-file-find-outline"  light dense  background-color="white" v-model="search" @keydown.enter="ActivateSearch()"/>
              </v-toolbar-title>
          </v-layout>
           
                       
          </v-toolbar>
               <v-list id="logobar" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover'  ,position: 'fixed',top: '50px'}" width="100%" class="stickytop">
                  <v-list-item>
                    <!-- <v-list-item-content > -->
                    <v-list-item-avatar tile size="80">
                      <img src="@/assets/RAInvert.png"/>            
                    </v-list-item-avatar>
                    <!-- </v-list-item-content> -->
                    <v-list-item-content >
                      <v-list-item-title class="largeoverline white--text">GP Combrink Vehicle Sales & Finance</v-list-item-title>                    
                      <v-list-item-subtitle class="overline white--text">
                        a Brand new ERA
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
             </v-list>
               <!-- <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="left"
                      >
               <img contain height="40px" src="@/assets/RAInvert.png">

                <span class="largeoverline white--text">GP Combrink Vehicle Sales & Finance</span>
               </v-row>  -->
            <!-- </v-card> -->
            <!-- <v-card width="100%" flat class="transparent" style="margin-bottom:30px">
            <v-system-bar style="position:fixed;z-index:4;" window :dark="AppisDarkMode">
           <v-spacer></v-spacer>
           <input clearable class="searchinput" v-model="search" type="text" id="fname" name="fname" @keydown.enter="ActivateSearch()">
            <v-icon color="white">mdi-file-find-outline</v-icon>            
          </v-system-bar>
          
            </v-card> -->
            
          <v-toolbar :style="{ top: '160px'}" id="belowlogobar" :class="AppisDarkModeCard"  :dark="AppisDarkMode"  width="100%" >
          <v-icon class="actionicon mx-2" @click="ToggleAppDarkMode()">
                      mdi-invert-colors
                    </v-icon>
                    <v-icon class="green--text mx-2" @click="TogglesocialSiteQuickNav()">
                      mdi-compass
                    </v-icon>
                    <v-icon class="blue--text mx-2" @click="IntranetViewToggle">
                      mdi-database
                    </v-icon>
                    <v-icon v-if="!CurrentisFavorite" class="red--text mx-2" @click="AddPageAsFavorite()">
                      mdi-star-outline
                    </v-icon>
                    <v-icon v-if="CurrentisFavorite" class="red--text mx-2" @click="AddPageAsFavorite()">
                      mdi-star
                    </v-icon>

                    
                    <v-menu
                            bottom
                            right
                            offset-y
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on }">
                              <v-icon   :class="SocialMenuFontColor+'--text mx-2'" v-on="on">
                                mdi-arrow-down-drop-circle-outline
                              </v-icon>
                              </template>
                              <v-tabs dark>
                                  <v-tab>Random</v-tab>
                                  <v-tab-item>
                                    <v-list class="overline" dense max-width="400" min-width="350" 
                                    :style="{ backgroundImage: 'url(' + require('@/assets/RASolidA-Light.jpeg') + ')' ,backgroundSize: 'cover'}">
                                    <v-list-item-title>
                                      My Favorites
                                    </v-list-item-title>
                                    <v-divider></v-divider>
                                      <v-list-item v-for="fav in UserRecord.FavoriteLinks" :key="fav.itemObjKey" :href="fav.Path">
                                        {{fav.DisplayName}}
                                      </v-list-item>
                                    </v-list>
                                  </v-tab-item>
                                  <v-tab>Organized</v-tab>
                                  <v-tab-item>
                                    <v-list class="overline" dense max-width="400" min-width="350" 
                              :style="{ backgroundImage: 'url(' + require('@/assets/RASolidA-Light.jpeg') + ')' ,backgroundSize: 'cover'}"
                              >
                                <v-list-item-title>
                                  My Favorites
                                </v-list-item-title>
                                <v-divider></v-divider>
                               
                                <v-list-group
                                  :prepend-icon="item.Icon"
                                  v-for="item in UserFavoriteLinks" :key="item.itemObjKey"
                                >
                                  <template v-slot:activator>
                                    <v-list-item-title>{{item.Name}}</v-list-item-title>
                                  </template>
                                  <div v-if="item.Items">
                                    <!-- :prepend-icon="sub.icon" -->
                                  <v-list-group
                                    :value="true"
                                    no-action
                                    sub-group
                                    v-for="sub in item.Items" :key="sub.itemObjKey"
                                  >
                                    <template v-slot:activator>
                                      <v-list-item-content>
                                        <v-list-item-title>{{sub.Name}}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>

                                    <v-list-item
                                      v-for="fav in sub.Links"
                                      :key="fav.itemObjKey"
                                      :href="fav.Path"
                                    >
                                      <v-list-item-title>
                                        {{fav.DisplayName}}
                                      </v-list-item-title>

                                      <v-list-item-icon>
                                        <v-icon v-text="fav.icon"></v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </v-list-group>
                                  </div>
                                  <div v-if="item.Links">
                                    <v-list-item
                                      v-for="fav in item.Links"
                                      :key="fav.itemObjKey"
                                      :href="fav.Path"
                                    >
                                      <v-list-item-title>
                                        {{fav.DisplayName}}
                                      </v-list-item-title>

                                      <v-list-item-icon>
                                        <v-icon v-text="fav.icon"></v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </div>
                                </v-list-group>
                               
                              </v-list>
                                  </v-tab-item>
                              </v-tabs>
                              
                            
                    </v-menu>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer" />
         <v-list dense  :dark="AppisDarkMode"  class="transparent hidden-sm-and-down">
             <v-list-item>
                 <v-list-item-content v-for="item in FinalRender" :key="item.itemObjKey">
                 <router-link :class="'intranetnavlinks '+SocialMenuFontColor+'--text mx-6'" style="text-decoration: none" :to="item.Path" v-if="!item.SubItems || !item.SubItems.length">{{item.DisplayName}}</router-link>
                         <v-menu
                            v-if="item.SubItems && item.SubItems.length>0"
                            bottom
                            left
                            offset-y
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on }">
                            <span :class="'intranetnavlinks '+SocialMenuFontColor+'--text mx-6'">{{item.DisplayName}}<v-icon v-on="on"  :color="SocialMenuFontColor" right>mdi-menu-down</v-icon></span>
                            
                            </template>
                            <v-list dense class="grey lighten-3 submenuitem">
                            <v-list-item
                                v-for="subitem in item.SubItems"
                                :key="subitem.itemObjKey"
                            >       
                            <router-link :class="'intranetnavlinks '+SocialMenuFontColor+'--text mx-6'" style="text-decoration: none" :to="subitem.Path" v-if="!subitem.SubChildren || !subitem.SubChildren.length">{{subitem.DisplayName}}</router-link>                     
                                <v-list-group
                                 v-if="subitem.SubChildren && subitem.SubChildren.length"
                              >
                                <template v-slot:activator>
                                <span v-on="on" :class="'intranetnavlinks '+SocialMenuFontColor+'--text mx-6'">{{item.DisplayName}}<v-icon  :color="SocialMenuFontColor" right>mdi-menu-down</v-icon></span>
                                </template>
                               
                                    <v-list-item
                                        v-for="subsub in subitem.SubChildren"
                                        :key="subsub.itemObjKey"
                                        :href="subsub.Path"
                                    >                                    
                                        <span :class="'intranetnavlinks '+SocialMenuFontColor+'--text mx-6'">{{ subsub.DisplayName.toUpperCase() }}</span>
                                    </v-list-item>
                                </v-list-group>
                                
                            
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    
                 </v-list-item-content>
             </v-list-item>
         </v-list>
         </v-toolbar>
             
          <v-navigation-drawer temporary class="waytop"
          src="@/assets/RABaseBG.jpeg"
      fixed :dark="true" right  v-model="drawer" color="#0B63BD">
      <v-list>
        <div v-for="item in FinalRender" :key="item.title">
          <v-list-item v-if="!item.SubItems || item.SubItems.length===0" :to="item.Path">
          <v-list-item-content>
            <v-list-item-title :to="item.Path">{{ item.DisplayName}}
            </v-list-item-title>
            </v-list-item-content>
           
          </v-list-item>
            <v-list-group
              v-if="item.SubItems && item.SubItems.length"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{item.DisplayName}}</v-list-item-title>
                </template>
                <div v-for="subsub in item.SubItems"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3"
                        
                        :to="subsub.Path"
                    >                                   
                        <v-list-item-title>{{ subsub.DisplayName }}</v-list-item-title>
                    </v-list-item>
                    
                </div>
              </v-list-group>
          </div>
      </v-list>

    </v-navigation-drawer>
    <v-navigation-drawer v-model="socialquicknav" right class="fixedquicknav"  id="socialsitequicknav"
          :class="AppisDarkModeCard"
            fixed :dark="AppisDarkMode" >
            <v-icon color="warning" @click="socialquicknav = false">mdi-close</v-icon>
          <v-list dense width="240px" height="100%" :dark="AppisDarkMode" class="transparent">
          <v-list-item>
             <span :class="SocialMenuFontColor+'--text intranetnavlinks'">Invited Groups</span>
            </v-list-item>
            <v-list-item v-for="group in invitedgroups" :key="group.itemObjKey" @click="socialquicknav = false" :to="'/Group/'+group.id">
                 <v-list-item-avatar size="30">
                   <v-img :src="group.logo" v-if="group.logo"/>
                   <v-img src="@/assets/logo.png" v-if="!group.logo"/>
                 </v-list-item-avatar>
                 <v-list-item-title :class="SocialMenuFontColor+'--text'">
                   {{group.name}}
                 </v-list-item-title>
               </v-list-item>
            <v-divider></v-divider>
         

            <v-list-item>
             <span :class="SocialMenuFontColor+'--text intranetnavlinks'">Your Groups</span>
            </v-list-item>
            <v-list-item v-for="group in groups" :key="group.itemObjKey" @click="socialquicknav = false" :to="'/Group/'+group.id">
                 <v-list-item-avatar size="30">
                   <v-img :src="group.logo" v-if="group.logo"/>
                   <v-img src="@/assets/logo.png" v-if="!group.logo"/>
                 </v-list-item-avatar>
                 <v-list-item-title :class="SocialMenuFontColor+'--text'">
                   {{group.name}}
                 </v-list-item-title>
               </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <span :class="SocialMenuFontColor+'--text intranetnavlinks'">Your Friends</span>
            </v-list-item>
            <v-list-item v-for="user in FriendsList" :key="user.itemObjKey" @click="ActivateMessage(user)">
                 
                  <v-badge
                   v-if="user.onlinestatus === 'online'"
                    top
                    color="light-green accent-3"
                    dot
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-avatar size="35">
                      <v-img :src="user.Profile_Photo" v-if="user.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!user.Profile_Photo"/>
                    </v-avatar>
                  </v-badge>
                  <v-avatar size="35" v-if="user.onlinestatus !== 'online'">
                      <v-img :src="user.Profile_Photo" v-if="user.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!user.Profile_Photo"/>
                    </v-avatar>
                
                 <v-list-item-title :class="SocialMenuFontColor+'--text'">
                   {{user.Full_Name}}
                 </v-list-item-title>
               </v-list-item>
               
          </v-list>
          </v-navigation-drawer>
          <v-card id="messagebox" class="messagebox" :style="MessageBoxPadRight" width="300px" v-if="MessageDialog">
         
          <v-card class="messageboxheader" dark>
            <v-img height="80" src="@/assets/RABaseBG.jpeg">
            <v-list two-line class="transparent">
              <v-list-item>
                
                  <v-list-item-avatar  size="35">
              <v-img :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="MessagetoUser.Full_Name"></v-list-item-title>
                    <v-list-item-subtitle v-text="MessagetoUser.onlinestatus"></v-list-item-subtitle>
                  </v-list-item-content>
            
            <v-spacer>
                </v-spacer>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-phone</v-icon></v-btn>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-video</v-icon></v-btn>
                  <v-btn icon @click="DeactivateUserMessage"><v-icon color="red">mdi-close</v-icon></v-btn>
                  
        
              </v-list-item>
              
            </v-list>
            </v-img>
          </v-card>
            
             <v-list class="messagelist" id="messagelist" flat>
               <v-list-item v-for="message in ConversationAscending" :key="message.itemObjKey">
                 <v-list flat class="transparent" width="100%">
                   <v-list-item :dark="message.dark">
                 <v-list-item-avatar size="25"  v-if="message.ReadType === 'Received'">
                   <v-img :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                 </v-list-item-avatar>
                 <v-list-item-content :class="message.Color">
                   <span style="padding-left:10px;padding-right:1px;">{{message.message}}</span>
                   
                 </v-list-item-content>
               </v-list-item>
               <v-list-item>
                 <v-list-item-subtitle style="font-size:10px">
                   {{message.SendTime}}
                 </v-list-item-subtitle>
               </v-list-item>
                 </v-list>
               </v-list-item>            
             </v-list>
             <v-list>
                <v-list-item>
                  
                <input  class="messagepost mx-3" type="text" id="messageinputchip"  @keydown.enter="CreateMessage(MessagetoUser,UserRecord)"  placeholder="Type Message">
                <v-icon>mdi-share</v-icon>
               </v-list-item>
                 </v-list>
             
        </v-card>
    </v-content>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'

export default {
  props: ['AppisDarkMode','CurrentRecordName','FriendsList','Notifications'],
  components: {SystemTicketsLogging},
  data() {
    return {
      darkcolor: 'blue-grey',
      lightcolor: 'blue-grey',
       UserRecord: '',
      RecordName: '',
      SocialQuickNavTop: '225px',
      socialquicknav: false,
      MessageDialog: false,
      MessagetoUser: '',
      groups: [], 
      invitedgroups: [],
      UsersArray: [],
      ReceivedMessages: [],
      SentMessages: [],
      
      drawer:false,
      SearchView: false,
      search: '',
      pubitems: [
        {
          DisplayName:'Glossary',
          Path:'/Glossary',
        },
        {
          DisplayName:'Social',
          Path:'/Social',
          SubItems: [
            {
            DisplayName: 'Members',
            Path: '/SiteMembers'
            },
            {
            DisplayName: 'Articles',
            Path: '/Articles'
            },
            {
            DisplayName: 'Blogs',
            Path: '/Blogs'
            },
            
          ]
        },
        
        {
          DisplayName:'Intranet',
          Path:'/Intranet',
        },
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  computed: {
    SocialMenuFontColor(){
      if(this.AppisDarkMode){
        return 'secondary'
      }
      else{
        return 'pop'
      }
    },
    UserFavoriteLinks(){
    if(this.UserRecord.FavoriteLinks){
      let array = JSON.parse(JSON.stringify(this.UserRecord.FavoriteLinks))   
      array.Views = []   
      array.map(favlink => {
        console.log(favlink.Path.split('/')[1].toLowerCase())
        let routeitem = this.$router.options.routes.find(obj => obj.name === favlink.Path.split('/')[1].toLowerCase())
        console.log(routeitem)
        if(routeitem && routeitem.meta && routeitem.meta.icon){
          favlink.icon = routeitem.meta.icon
          favlink.viewicon = routeitem.meta.viewicon
          console.log(routeitem)
          if(routeitem.meta.type === 'Data Tables' && routeitem.meta.subcat){
            let DataTableObj = array.Views.find(obj => obj.Name === 'Data Tables')
            console.log("DataTableObj",DataTableObj)
            if(!DataTableObj){
              let DataTablePushObj = {
                Name: 'Data Tables',
                Links: [],
                Icon: 'mdi-file-table-box'
              }
              console.log(DataTablePushObj)

              DataTablePushObj.Links.push(favlink)
              array.Views.push(DataTablePushObj)
              console.log(array.Views)
            }
            else{
                DataTableObj.Links.push(favlink)
              }              
            }
            else if(routeitem.meta.type === 'Pages' && routeitem.meta.subcat){
            let PagesObj = array.Views.find(obj => obj.Name === 'Pages')
            console.log("PagesObj",PagesObj)
            if(!PagesObj){
              let PagesPushObj = {
                Name: 'Pages',
                Links: [],
                Icon: 'mdi-book-open-page-variant'
              }
              console.log(PagesPushObj)

              PagesPushObj.Links.push(favlink)
              array.Views.push(PagesPushObj)
              console.log(array.Views)
            }
            else{
                PagesObj.Links.push(favlink)
              }              
            }
            else if(routeitem.meta.type === 'DB Records' && routeitem.meta.subcat){
            let DBRecordObj = array.Views.find(obj => obj.Name === 'DB Records')
            console.log("DBRecordObj",DBRecordObj)
            if(!DBRecordObj){
              let DBRecordObjPush = {
                Name: 'DB Records',
                Items: [],
                Icon: 'mdi-database'
              }
              console.log(DBRecordObjPush)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              console.log(SubCatItem)
              DBRecordObjPush.Items.push(SubCatItem)
              console.log(DBRecordObjPush)
              array.Views.push(DBRecordObjPush)
              console.log(array.Views)
            }
            else{
              console.log(DBRecordObj)
              let subcatcheck = DBRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                DBRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Wiki Pages' && routeitem.meta.subcat){
            let WikiPageObj = array.Views.find(obj => obj.Name === 'Wiki Pages')
            console.log("WikiPageObj",WikiPageObj)
            if(!WikiPageObj){
              let WikiPageObjPush = {
                Name: 'Wiki Pages',
                Items: [],
                Icon: 'mdi-wikipedia'
              }
              console.log(WikiPageObjPush)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              console.log(SubCatItem)
              WikiPageObjPush.Items.push(SubCatItem)
              console.log(WikiPageObjPush)
              array.Views.push(WikiPageObjPush)
              console.log(array.Views)
            }
            else{
              let subcatcheck = WikiPageObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                WikiPageObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Library Records' && routeitem.meta.subcat){
            let LibraryRecordObj = array.Views.find(obj => obj.Name === 'Library Records')
            console.log("LibraryRecordObj",LibraryRecordObj)
            if(!LibraryRecordObj){
              let LibraryRecordPushObj = {
                Name: 'Library Records',
                Items: [],
                Icon: 'mdi-library'
              }
              console.log(LibraryRecordPushObj)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              console.log(SubCatItem)
              LibraryRecordPushObj.Items.push(SubCatItem)
              console.log(LibraryRecordPushObj)
              array.Views.push(LibraryRecordPushObj)
              console.log(array.Views)
            }
            else{
              let subcatcheck = LibraryRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                LibraryRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        
        else if(routeitem.meta.type === 'Libraries' && routeitem.meta.subcat){
            let LibraryObj = array.Views.find(obj => obj.Name === 'Libraries')
            console.log("LibraryObj",LibraryObj)
            if(!LibraryObj){
              let LibraryPushObj = {
                Name: 'Libraries',
                Links: [],
                Icon: 'mdi-library'
              }
              console.log(LibraryPushObj)

              LibraryPushObj.Links.push(favlink)
              array.Views.push(LibraryPushObj)
              console.log(array.Views)
            }
            else{
                LibraryObj.Links.push(favlink)
              }             
        }
        
            
          }
          else if(routeitem){
            let OtherObj = array.Views.find(obj => obj.Name === 'Other')
            console.log("OtherObj",OtherObj)
            if(!OtherObj){
              let OtherPushObj = {
                Name: 'Other',
                Links: [],
                Icon: 'mdi-help'
              }
              console.log(OtherPushObj)

              OtherPushObj.Links.push(favlink)
              array.Views.push(OtherPushObj)
              console.log(array.Views)
            }
            else{
                OtherObj.Links.push(favlink)
              }              
            }
          
        return favlink
      })
      return array.Views
    }
    },
    CurrentisFavorite(){
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          return true
        }
        else {
          return false
        }
      }
      else{
        return false
      }
    },

     FinalRender(){
         return this.pubitems.map(item => {
             return item
         })
     },
     NotificationsColor(){
      if(this.UnreadNotifications.length > 0){
        return 'pop'
      }
      else{
        return 'secondary'
      }
    },
    MessagesColor(){
      if(this.UnreadMessages.length > 0){
        return 'pop'
      }
      else{
        return 'secondary'
      }
    },
    MessageBoxPadRight(){
        if (this.socialquicknav === true) {
        return 'right: 260px;'
        }
        else{
          return 'right: 0px;'
        }
    },
    Conversation(){
      let arr1 = this.SentMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.recipientid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.senderid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'grey lighten-4 messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      console.log(arr1)
      return arr1.concat(arr2)
    },

    ConversationAscending(){
      return this.Conversation.map(msg => {
        console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    AllUserConversations(){
      let arr1 = this.SentMessages.map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'grey lighten-4 messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      console.log(arr1)
      return arr1.concat(arr2)
    },
    AllUserConversationsAscending(){
      return this.AllUserConversations.map(msg => {
        console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    ConversingUsers(){
      return this.UsersArray.filter(user => {
        let recipientcheck = this.AllUserConversationsAscending.find(msg => msg.recipientid == user.id)
        let sendercheck = this.AllUserConversationsAscending.find(msg => msg.senderid == user.id)
        if(sendercheck){
          user.lastMessage = Object.assign({},sendercheck)
          user.SendTime = this.TimestampFormatterSTRING(sendercheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(sendercheck.SendTime)
          return user
        }
        else if(recipientcheck){
          user.lastMessage = Object.assign({},recipientcheck)
          user.SendTime = this.TimestampFormatterSTRING(recipientcheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(recipientcheck.SendTime)
          return user
        }
        
      }).map(user => {
        let sendercheck = this.UnreadMessages.find(msg => msg.senderid == user.id)
       
        if(sendercheck){
          user.Color = ' grey lighten-2'          
        }
        else{
          user.Color = ' white'
        }
        console.log(user)
        return user
      }).filter(user => {
        return user.id !== this.UserRecord.id
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    UnreadMessages(){
      return this.AllUserConversationsAscending.filter(msg => {
        return !msg.Read === true && msg.ReadType === 'Received'
      })
    },
     UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
    ReadNotifications(){
      return this.Notifications.filter(notificaton => {
        return notificaton.Read === true
      })
    },
    AppisDarkModeCard(){
        if(this.AppisDarkMode){
          //darken-4
          return this.darkcolor+' stickytop2'
        }
        else{
          //lighten-4
          return this.lightcolor+' stickytop2'
        }
      },
    userLoggedIn () {
      return this.$store.getters.user
    },
    },

  created() {
    this.UserRecord = this.userLoggedIn
    window.addEventListener('scroll', this.handleScrollQuickNav)
    if (this.UserRecord && this.UserRecord.id) {
          console.log('this.UserRecord',this.UserRecord.id)
          let query = db.collection('notifications').where('Ownerid', '==', this.UserRecord.id)
            
          this.GetSentMessages()
          this.GetReceivedMessages()
          this.GetUsers()  
          this.GetUserGroups()
          this.GetUserInvitedGroups()
          //this.getGroups()
        }
  },
  watch:{
        'search' (){
            this.CheckActivateSearch()
        },
      
  },
  methods: {
    GetUserInvitedGroups(){
      
      db.collection('groups').where('GroupPrivacy','==','Invite Only').where('Invitees','array-contains',this.UserRecord.id).onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let groupdata = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('groups').doc(groupdata.id).collection('invites').doc(this.UserRecord.id).onSnapshot(snapshot => {
                  let invitedata = snapshot.data()
                  if(invitedata.Status.ID === 1000001){
                    this.invitedgroups.push(groupdata)
                  }
                })
                
              }
            })
      })
    },
    GetUserGroups(){
      this.UserRecord.groups.map(group => {
        db.collection('groups').doc(group.id).onSnapshot(snapshot => {
          let groupdata = snapshot.data()
          groupdata.id = group.id
          this.groups.push(groupdata)
        })
      })
    },
    RemoveFavorite(pathcheck){
      let FavoriteLinks = this.UserRecord.FavoriteLinks
      let FavoriteIndex = FavoriteLinks.indexOf(pathcheck)
      FavoriteLinks.splice(FavoriteIndex,1)
      db.collection('users').doc(this.UserRecord.id).update({
        FavoriteLinks: FavoriteLinks
      })
    },
    AddPageAsFavorite(){
      let routeitem = this.$router.options.routes.find(obj => obj.name === 'myprofile')
      console.log("routeitem",routeitem)
      console.log(this.$route.meta)
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          confirm('This item is already a favorite. Would you like to remove it from Favorites?') && this.RemoveFavorite(pathcheck)
        }
        else{
        let display = ''
        
        if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        
        }
      }
      else{
        let display = ''
      if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }      
      }
      
      // this.UserRecord.FavoriteLinks.find
    },
    StoreFavorite(display,path){
      let FavoriteLinks = []
        if(this.UserRecord.FavoriteLinks){
          FavoriteLinks = this.UserRecord.FavoriteLinks
        } 
        FavoriteLinks.push({
          DisplayName: display,
          Path: path
        })
        db.collection('users').doc(this.UserRecord.id).update({
          FavoriteLinks: FavoriteLinks
        })
    },
    ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
    
    CreateMessage(userto,userfrom){
        let inputelmnt = document.getElementById('messageinputchip')
        let NewMessage = {
          recipientid: userto.id,
          senderid: userfrom.id,
          message: inputelmnt.value,
          Created_On: new Date(),
          Read: false
        }
        db.collection('usermessages').add(NewMessage)
        inputelmnt.value = ''
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            
        }, 50);
      },
    handleScrollQuickNav(){
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        this.SocialQuickNavTop = '115px'
        }
        else{
          this.SocialQuickNavTop = '225px'
        }
    },
    TogglesocialSiteQuickNav(){
      if(this.socialquicknav){
        this.socialquicknav = false
      }
      else{
        this.socialquicknav = true
        setTimeout(() => {
        let socialsitequicknavelmnt = document.getElementById('socialsitequicknav')
        if(socialsitequicknavelmnt){
      socialsitequicknavelmnt.style.top = this.SocialQuickNavTop
    }
    }, 50);
      }
    },
    // ActivateMessageDialog(user){
    //   sdf
    // },
    DeactivateUserMessage(){
        this.MessageDialog = false
        this.MessagetoUser = ''
        this.socialquicknav = true
      },
      ActivateMessage(user){
        this.socialquicknav = false
        this.MessageDialog = true
        this.MessagetoUser = user
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            this.ReceivedMessages.map(msg => {
              if(msg.ReadType === 'Received' && msg.Read === false){
                this.UpdateMessageasRead(msg)
              }
            })
        }, 200);
      },
      UpdateMessageasRead(msg){
        db.collection('usermessages').doc(msg.id).update({
          Read: true
        })
      },
    getGroups() {
           db.collection('groups').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.groups.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
      })
    })

    },
    GetUsers(){
        db.collection('users').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
        if (change.type === 'modified') {
          console.log('here is change')
          console.log(change.doc.data())
          let userdata = change.doc.data()
          let userobj = this.UsersArray.find(obj => obj.id == change.doc.id)
          let userindex = this.UsersArray.indexOf(userobj)
          if(this.UsersArray[userindex].onlinestatus !== userdata.onlinestatus){
            this.UsersArray[userindex].onlinestatus = userdata.onlinestatus
          }
          
        }  
      })
    })
      },
    DateFormatterwithTime(date){
      if(date){
        console.log(date)
        console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},

TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        console.log(finaldate)
        return finaldate
      },
    GetSentMessages(){
        db.collection('usermessages').where('senderid','==',this.UserRecord.id).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.SentMessages.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }  
        })
      })      
      },
      GetReceivedMessages(){
        db.collection('usermessages').where('recipientid','==',this.UserRecord.id).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.ReceivedMessages.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }  
          if (change.type === 'modified') {
          console.log('here is change')
          console.log(change.doc.data())
          let msgdata = change.doc.data()
          msgdata.id = change.doc.id
          let msgobj = this.ReceivedMessages.find(obj => obj.id == change.doc.id)
          let msgindex = this.ReceivedMessages.indexOf(msgobj)
          if(this.ReceivedMessages[msgindex].Read !== msgdata.Read){
            this.ReceivedMessages[msgindex] = msgdata
          }
          
        }
        })
      })      
      },
    IntranetViewToggle(){
      confirm('This will take you to the data version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',false)
      },
      CancelSubmitSystemTicket(){
      this.TicketLog = false
    },
      ActivateTicketLog(){
        this.UsersArray = [this.UserRecord]
        this.TicketLog = true
      },
    

     ViewItem(notification){
      this.$router.push(notification.Path)
    },
    NotificationRead(notification){
      this.$emit('NotificationRead',notification)
    },
    MarkasUnread(notification){
      this.$emit('MarkasUnread',notification)
    },

    ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('users').doc(this.UserRecord.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.Notifications = []
      this.UserRecord = {}
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
    CheckActivateSearch(){
      console.log(this.search === '')
      console.log(this.SearchView)
      if(this.search === '' && this.SearchView){
        console.log('conditions met bro')
      this.$emit('DeactivateSearch')
      this.SearchView = false
      }
      
    },
    ActivateSearch(){    
      this.$emit('DeactivateSearch')
      console.log(this.search) 
      this.SearchView = true
      this.$emit('ActivateSearch',this.search)
    },
    
  }

}
</script>

<style>
.messagebox{
  position: fixed;
  bottom: 60px;
  transition: right 0.5s;
  z-index: 101;
}
.messageblock {
  border: none;
  cursor: pointer;
  border-radius: 25px;
}
.messagepost{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.messagelist {
  height:200px;/* or any height you want */
  overflow-y:auto
}
.messageboxheader{
  z-index: 105;
}
.systembar{
    background-repeat: repeat;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
.waytop{
  z-index: 100;
}
.searchinput{
  background-color: white;
  color: #474747;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  top: 30px;
  z-index: 4
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 4
}
.stickytop3{
  position: fixed;
  width: 100%;
  z-index: 4
}
</style>
    