<template>
  <v-card :dark="AppisDarkMode" :flat="Flat" >
      <SocialSharing :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
    :SharingPost="SharingPost"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="userLoggedIn" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>
       <v-divider></v-divider>
              <v-card tile flat
                v-if="!HideSummary"
                height="100%" width="100%"
                >  
              
                <v-card-text>
                <v-layout row class="justify-space-between">            
                  <v-btn icon v-if="CanSocial">
                      <v-badge color="links" dark overlap>
                        <v-icon  color="blue lighten-2" @click="LikePost(SocialItem)">mdi-thumb-up-outline</v-icon>
                        <span slot="badge"> {{SocialItem.Likes.length}} </span>
                      </v-badge>
                      
                    </v-btn>
                    <v-btn disabled icon v-if="!CanSocial">
                      <v-badge color="links" dark overlap>
                        <v-icon  color="blue lighten-2">mdi-thumb-up-outline</v-icon>
                        <span slot="badge"> {{SocialItem.Likes.length}} </span>
                      </v-badge>
                      
                    </v-btn>

                    <v-btn icon >
                    <v-icon  >mdi-tag-outline</v-icon>
                  </v-btn>
                  <v-btn icon >
                    <v-icon  color="red lighten-2">mdi-share-circle</v-icon>
                  </v-btn>
                  <v-btn icon >
                      <v-badge color="links" dark overlap>
                        <v-icon  color="orange lighten-2">mdi-comment-outline</v-icon>
                        <span slot="badge"> {{ComputedSocialItemComments.length}} </span>
                      </v-badge>
                      
                    </v-btn>
                </v-layout> 
                </v-card-text>
              </v-card>

              <v-divider></v-divider>
        <!-- <v-card-actions class="commentpost"> -->
                 <v-row v-if="CanSocial"
                class="fill-height justify-space-between mx-4 my-1"
    
                >
          <v-avatar size="40">
            <img :src="UserRecord.Profile_Photo"/>
          </v-avatar><input class="Chipinput mx-3" style="font-size:12px" type="text" @keydown.enter="CommenttoPost(SocialItem)" placeholder="Insert Comment" :id="computedcommentinputchip"/>
                 </v-row>
              <v-divider></v-divider>
            
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel >
                  <v-expansion-panel-header :id="SocialItem.id+'commentpanel'">
                    <v-layout row wrap class="justify-center mx-3">
                       
                      
                        <v-btn icon small>
                            <v-icon small color="orange">mdi-comment-outline</v-icon>
                        </v-btn>
                      <span class="caption"> {{ComputedSocialItemComments.length}} Comments</span> 
                      </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                     
                      <v-list dense  width="100%" v-for="comment in ComputedSocialItemComments" :key="comment.itemObjkey">
                      <!-- <v-list dense> -->
                      <v-list-item>
                      <v-list-item-avatar>
                          <img v-if="comment.creatorimg"
                            :src="comment.creatorimg"
                            :alt="comment.creatorname"
                        >
                        <img v-if="!comment.creatorimg"
                            src='@/assets/BlankProfilePic.png'
                            :alt="comment.creatorname"
                        >
                        
                      </v-list-item-avatar>
                        <v-list-item-content>
                          <!-- <v-chip
                            large
                          > -->
                          <div class="commentblock">
                          <v-list class="transparent" dense width="90%">
                            <v-list-item style="font-size:10px">
                            <b><a :href="'/SiteMember/'+comment.Creatorid">{{comment.creatorname}}</a></b>
                            </v-list-item>
                            <v-list-item style="font-size:12px">
                            {{comment.content}}
                            </v-list-item>
                          </v-list>
                          </div>
                          <!-- </v-chip> -->
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-spacer></v-spacer>
                        <v-btn icon small v-if="CanSocial">
                        <v-badge dark overlap>
                          <v-icon small color="blue" @click="LikeComment(comment)">mdi-thumb-up-outline</v-icon>
                          <span slot="badge"> {{comment.Likes.length}} </span>
                        </v-badge>
                        
                      </v-btn>
                      <v-btn icon small v-if="CanSocial">
                        <v-badge dark overlap>
                          <v-icon small color="blue" @click="ReplyBoxActivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')">mdi-reply</v-icon>
                          <span slot="badge"> {{comment.Replies.length}} </span>
                        </v-badge>
                        
                      </v-btn>
                      </v-list-item>
                      <v-list-item :id="comment.id+'replyboxbreak'"  style="display:none">
                      <!-- <v-list> -->
                        <!-- <v-list-item :id="i+'commentreply'" style="display:none"> -->
                          <v-avatar size="35" :id="comment.id+'replyinputavatar'">
                            <img :src="UserRecord.Profile_Photo"/>
                          </v-avatar><input @blur="ReplyBoxDeactivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')" class="Chipinput mx-3" type="text" @keydown.enter="ReplytoComment(comment)" placeholder="Reply Here" :id="comment.id+'replyinputchip'">
                          
                        <!-- </v-list-item>
                      </v-list>                       -->
                      </v-list-item>
                       <!-- </v-list> 
                       <v-list dense> -->
                         <v-list-item  v-for="reply in comment.Replies" :key="reply.itemObjKey">
                            <v-list class="transparent" dense width="90%">
                                  <v-list-item>
                                    
                            <v-list-item-avatar>
                                <img v-if="reply.creatorimg"
                                  :src="reply.creatorimg"
                                  :alt="reply.creatorname"
                              >
                              <img v-if="!reply.creatorimg"
                                  src='@/assets/BlankProfilePic.png'
                                  :alt="reply.creatorname"
                              >
                              
                            </v-list-item-avatar>
                              <v-list-item-content>
                                <!-- <v-chip
                                  large
                                > -->
                                <div class="commentblock">
                                <v-list class="transparent" dense width="90%">
                                  <v-list-item style="font-size:10px">
                                  <b><a :href="'/SiteMember/'+reply.Creatorid">{{reply.creatorname}}</a></b>
                                  </v-list-item>
                                  <v-list-item>
                                  {{reply.content}}
                                  </v-list-item>
                                </v-list>
                                </div>
                                <!-- </v-chip> -->
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-spacer></v-spacer>
                                <v-btn icon small v-if="CanSocial">
                                <v-badge dark overlap>
                                  <v-icon small color="blue" @click="LikeReply(reply)">mdi-thumb-up-outline</v-icon>
                                  <span slot="badge"> {{reply.Likes.length}} </span>
                                </v-badge>
                                
                              </v-btn>
                              <v-btn icon small v-if="CanSocial">
                                <v-badge dark overlap>
                                  <v-icon small color="blue" @click="ReplyBoxActivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')">mdi-reply</v-icon>
                                  <span slot="badge"> {{reply.Replies.length}} </span>
                                </v-badge>
                                
                              </v-btn>
                              </v-list-item>
                              <!-- <v-list>
                                <v-list-item> -->
                                  <v-list-item :id="reply.id+'replyboxbreak'" style="display:none">
                                  <v-avatar size="35">
                                    <img :src="UserRecord.Profile_Photo"/>
                                  </v-avatar><input @blur="ReplyBoxDeactivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')" class="Chipinput mx-3" type="text"  @keydown.enter="ReplytoReply(reply,comment)"  placeholder="Reply to reply" :id="reply.id+'replytoreplyinputchip'">
                                  
                               <!-- </v-list-item>
                              </v-list>                      -->
                              </v-list-item>
                                </v-list>
                            </v-list-item>
                      
                      
                  </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['FromSocialPosts','ItemType','SocialItem','UserRecord','AppisDarkMode','PathName','RelatedType','notificationitem','panel','Flat','HideSummary','IsChild','Child','ChildProp','ChildType','ChildArrayProp','CanSocial','GroupID'],
    components: {

    },
    data(){
        return{
            // panel: [0]
            ShareItemDialog: false,
            InternalLinkType: '',
            SharedCollection: '',
            LinkComponent: '',
            LinkID: '',
            SharingPost: '',
        }
    },
    computed:{
      userLoggedIn () {
      return this.$store.getters.user
    },
        ComputedSocialItemComments(){
            if(!this.IsChild){
                return this.SocialItem.Comments
            }
            else if(this.Child && this.Child.Comments){
                return this.Child.Comments
            }
            else{
                return []
            }
        },
        computedcommentinputchip(){
            if(this.IsChild){
                return this.Child[this.ChildProp]+'commentinputchip'
            }
            else{
                return this.SocialItem.id+'commentinputchip'
            }
        },
    },
    created(){

    },
    methods:{
      ShareBlog(item){
        this.ShareItemDialog = true
        if(this.FromSocialPosts){
         this.SharingPost = item 
        }
        else if(this.$route.name === 'GroupBlog'){
          this.InternalLinkType = 'Group-Blog/'+this.$route.params.id+'/Blog'
          this.SharedCollection = 'groupblogs'
          this.LinkComponent = 'GroupBlogShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else if(this.$route.name === 'SiteBlogSingle'){
          this.InternalLinkType = 'Blog'
          this.SharedCollection = 'siteblogs'
          this.LinkComponent = 'SiteBlogShared'
          this.LinkID = this.$route.params.id
        } 
        else if(this.$route.name === 'GroupArticle'){
          this.InternalLinkType = 'Group-Article/'+this.$route.params.id+'/Article'
          this.SharedCollection = 'grouparticles'
          this.LinkComponent = 'GroupArticleShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else if(this.$route.name === 'SiteArticleSingle'){
          this.InternalLinkType = 'Article'
          this.SharedCollection = 'sitearticles'
          this.LinkComponent = 'SiteArticleShared'
          this.LinkID = this.$route.params.id
        }
        
        this.LinkID = item.id
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
        LikePost(post){
          if(!this.CanSocial){
              this.NoAccess()
          }
          else{
            let UserhasLiked = post.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
            if(!UserhasLiked){
            let newlike = {
              Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
              Likedbyid: this.UserRecord.id,
              createdon: new Date(),
                relatedtype: this.RelatedType,
                relatedid: post.id,
                relatedowner: post.Creatorid
            }
            if(this.GroupID){
              newlike.GroupID = this.GroupID
            }
            if(this.IsChild){
                newlike.IsChild = true
                newlike.Child = this.Child[this.ChildProp]
                newlike.ChildType = this.ChildType
                newlike.ChildArrayProp = this.ChildArrayProp
            }
            // post.Likes.push(newlike)
            db.collection('socialsitelikes').add(newlike).then(doc => {
              if(newlike.Likedbyid !== post.Creatorid){
              const NewNotification = {
                        postid: post.id,
                        Type: 'New Like',
                        Header: this.UserRecord.Full_Name+' likes your '+this.notificationitem,
                        Content: 'Click to view Likes',
                        CreatorFullName: this.UserRecord.Full_Name,
                        CreatorID: this.UserRecord.id,
                        Owner: post.Creator.Full_Name,
                        Ownerid: post.Creatorid,
                        Read: false,
                        Path: '/'+this.PathName+'/'+post.id,
                        Message: 'Click to view Likes',
                        Date: new Date()
                        }
                        db.collection('notifications').add(NewNotification).then(notedoc => {

                        })
              }
            })
            }
            else{
              alert('you already liked this item')
            }
          }
        
      },
      CommentReplyActivate(i,comment){
        if(!this.CanSocial){
          this.NoAccess()
        }
        else{
          // comment.Replying = true
          setTimeout(() => {
            let elmnt = document.getElementById(i+'commentreply')
          elmnt.style.display = 'block'
          console.log(comment)
          console.log(elmnt)
          }, 300);
        }
      },
      
      ReplytoComment(comment,i){
          if(!this.CanSocial){
              this.NoAccess()
          }
          else{
            if(!comment.Replies){
                comment.Replies = []
              }
              let inputelmnt = document.getElementById(comment.id+'replyinputchip')
              let NewReply = 
              {
                content: inputelmnt.value,
                Likes: [],
                Shares: [],
                tags: [],
                Replies: [],
                Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
                Creatorid: this.UserRecord.id,
                creatorname: this.UserRecord.Full_Name,
                createdon: new Date(),
                Comments: [],
                relatedtype: 'Social Comment',
                relatedid: comment.id,
                relatedowner: comment.Creatorid
                
              }
              if(this.GroupID){
                NewReply.GroupID = this.GroupID
              }
              if(this.UserRecord.Profile_Photo){
                NewReply.creatorimg = this.UserRecord.Profile_Photo
                }
              db.collection('socialsitereplies').add(NewReply).then(doc => {
              if(NewReply.Creatorid !== comment.Creatorid){
              const NewNotification = {
                        commentid: comment.id,
                        Type: 'New Like',
                        Header: this.UserRecord.Full_Name+' replied to your comment',
                        Content: 'Click to view Likes',
                        CreatorFullName: this.UserRecord.Full_Name,
                        CreatorID: this.UserRecord.id,
                        Owner: comment.Creator.Full_Name,
                        Ownerid: comment.Creatorid,
                        Read: false,
                        Path: '/'+this.PathName+'/'+comment.id,
                        Message: 'Click to view Comments',
                        Date: new Date()
                        }
                        db.collection('notifications').add(NewNotification).then(notedoc => {

                        })
              }
            })
              comment.Replying = false
              this.NewReplyInput = ''
              inputelmnt.value = '' 
          }          
        },

        CommenttoPost(post){
            if(!this.CanSocial){
              this.NoAccess()
            }
            else{
            //         if(!post.Comments){
            //     post.Comments = []
            //   }
            //   setTimeout(() => {
                let elmntid = ''
                if(this.IsChild){
                    elmntid = this.Child[this.ChildProp]+'commentinputchip'
                }
                else{
                    elmntid = this.SocialItem.id+'commentinputchip'
                }
              let inputelmnt = document.getElementById(elmntid)
              console.log(inputelmnt)
              
              let expandelmnt = ''
              
              if(!this.IsChild){
              expandelmnt = document.getElementById(post.id+'commentpanel')
              }
              let NewComment = 
              {
                content: inputelmnt.value,
                Likes: [],
                Shares: [],
                tags: [],
                Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
                Creatorid: this.UserRecord.id,
                creatorname: this.UserRecord.Full_Name,
                createdon: new Date(),
                Replies: [],
                relatedtype: this.RelatedType,
                relatedid: post.id,
                relatedowner: post.Creatorid
                
              }
              if(this.GroupID){
                NewComment.GroupID = this.GroupID
              }
              if(this.IsChild){
                NewComment.IsChild = true
                NewComment.Child = this.Child[this.ChildProp]
                NewComment.ChildType = this.ChildType
                NewComment.ChildArrayProp = this.ChildArrayProp
            }
              //post.Comments.push(NewComment)
              if(this.UserRecord.Profile_Photo){
              NewComment.creatorimg = this.UserRecord.Profile_Photo
            }
              db.collection('socialsitecomments').add(NewComment).then(doc => {
              if(NewComment.Creatorid !== post.Creatorid){
              const NewNotification = {
                        postid: post.id,
                        Type: 'New Comment',
                        Header: this.UserRecord.Full_Name+' commented on your '+this.notificationitem,
                        Content: 'Click to view Likes',
                        CreatorFullName: this.UserRecord.Full_Name,
                        CreatorID: this.UserRecord.id,
                        Owner: post.Creator.Full_Name,
                        Ownerid: post.Creatorid,
                        Read: false,
                        Path: '/'+this.PathName+'/'+post.id,
                        Message: 'Click to view '+this.notificationitem,
                        Date: new Date()
                        }
                        db.collection('notifications').add(NewNotification).then(notedoc => {

                        })
              }
            })
              post.NewCommentInput = ''
              inputelmnt.value = ''
              if(!this.IsChild){
              expandelmnt.click()
              }
                // }, 500);
            }
       
          
        },
      NoAccess(){
        alert('You do not have access to perform this action')
      },
      LikeReply(reply){
        if(this.CanSocial){
        let UserhasLiked = reply.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
          relatedtype: 'Social Reply',
            relatedid: reply.id,
            relatedowner: reply.Creatorid
        }
        if(this.GroupID){
            newlike.GroupID = this.GroupID
          }
        // post.Likes.push(newlike)
        db.collection('socialsitelikes').add(newlike).then(doc => {
          if(newlike.Likedbyid !== reply.Creatorid){
          const NewNotification = {
                    replyid: reply.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' likes your comment',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: reply.Creator.Full_Name,
                    Ownerid: reply.Creatorid,
                    Read: false,
                    Path: '/'+this.PathName+'/'+reply.id,
                    Message: 'Click to view Likes',
                    Date: new Date()
                    }
                    db.collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
                
        })
        }
        else{
          alert('you already liked this item')
        }
        }
        else{
          this.NoAccess()
        }
      },
      ReplytoReply(reply,comment){
        if(!this.CanSocial){
          this.NoAccess()
        }
        else{
          if(!reply.Replies){
            reply.Replies = []
          }
          console.log(reply)
          let inputelmnt = document.getElementById(reply.id+'replytoreplyinputchip')
          let NewReply = 
          {
            content: inputelmnt.value,
            Replies: [],
            Likes: [],
            Shares: [],
            tags: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Comments: [],
            relatedtype: 'Social Reply',
            relatedid: comment.id,
            relatedowner: reply.Creatorid
            
          }
          if(this.GroupID){
            NewReply.GroupID = this.GroupID
          }
          if(this.UserRecord.Profile_Photo){
            NewReply.creatorimg = this.UserRecord.Profile_Photo
            }
           db.collection('socialsitereplies').add(NewReply).then(doc => {
          if(NewReply.Creatorid !== reply.Creatorid){
          const NewNotification = {
                    replyid: reply.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' replied to your comment',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: reply.Creator.Full_Name,
                    Ownerid: reply.Creatorid,
                    Read: false,
                    Path: '/'+this.PathName+'/'+reply.id,
                    Message: 'Click to view Comments',
                    Date: new Date()
                    }
                    db.collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
        })
          reply.Replying = false
          this.NewReplyInput = ''
          inputelmnt.value = ''
        }        
      },
      LikeComment(comment){
        if(!this.CanSocial){
          this.NoAccess()
        }
        else{
          let UserhasLiked = comment.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
          if(!UserhasLiked){
          let newlike = {
            Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            Likedbyid: this.UserRecord.id,
            createdon: new Date(),
            relatedtype: 'Social Comment',
              relatedid: comment.id,
              relatedowner: comment.Creatorid
          }
           if(this.GroupID){
            newlike.GroupID = this.GroupID
          }
          // post.Likes.push(newlike)
          db.collection('socialsitelikes').add(newlike).then(doc => {
            if(newlike.Likedbyid !== comment.Creatorid){
            const NewNotification = {
                      commentid: comment.id,
                      Type: 'New Like',
                      Header: this.UserRecord.Full_Name+' likes your comment',
                      Content: 'Click to view Likes',
                      CreatorFullName: this.UserRecord.Full_Name,
                      CreatorID: this.UserRecord.id,
                      Owner: comment.Creator.Full_Name,
                      Ownerid: comment.Creatorid,
                      Read: false,
                      Path: '/'+this.PathName+'/'+comment.id,
                      Message: 'Click to view Likes',
                      Date: new Date()
                      }
                      db.collection('notifications').add(NewNotification).then(notedoc => {

                      })
            }
          })
          }
          else{
            alert('you already liked this item')
          }
        }
        
      },
        ReplyBoxDeactivate(replyboxbreak,input){
        let replyboxbreakelmnt = document.getElementById(replyboxbreak)
        let inputelmnt = document.getElementById(input)
        inputelmnt.value = ''
        replyboxbreakelmnt.style.display = 'none'
        },
        ReplyBoxActivate(replyboxbreak,input){
            let replyboxbreakelmnt = document.getElementById(replyboxbreak)
            let inputelmnt = document.getElementById(input)
            replyboxbreakelmnt.style.display = ''
            inputelmnt.focus()
        },
    }
}
</script>

<style>

</style>


