                <template>
      
<v-card tile  class="transparent stickytopconscious bottomspace" width="100%" height="100%" :dark="AppisDarkMode" flat>
<v-dialog v-model="MustReadNoticeDialog" max-width="500">
       <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between">
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>

    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
        <v-list-item :to="'/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
     </v-dialog>

    <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
  <v-row>
      <v-flex lg2 md3 sm4 xs12  style="padding:7px;">
               <v-card  :class="AppisDarkModeCard"  :dark="AppisDarkMode" height="100%">
              <SystemActivitiesFeed style="padding-top:15px;" class="mx-3" :AppisDarkModeCard="AppisDarkModeCard" :AppisDarkMode="AppisDarkMode" :UsersRecord="UserRecord" :Origin="'Page'" :Excerpt="20" :HasFilter="false" />
              </v-card>
            </v-flex>
            
            <v-flex lg10 md9 sm8 xs12>
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center style="padding:7px;">
            <div class="flex xl12 lg12 md12 sm12 xs11">
            
             
            
<v-card color="#ffffff" class="transparent" tile  width="100%" flat>
    <!-- <v-container class="grey lighten-5"> -->
   <v-parallax
        
        height="300"
        src="https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FQSHCimvRtK2k5IgF1edf%2FIntranet_Page?alt=media&token=a4c7eaae-4f78-493c-9b19-5d1594ac631a"
        style="margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;;"
      >   
      <v-card class="transparent" flat tile style="background: linear-gradient(180deg, rgba(1,0,15,0.835171568627451) 0%, rgba(9,46,54,0.47942927170868344) 100%);" height="100%" width="100%">
      <v-container class="fill-height" >
   
          
        <v-row
            
            >
           
            <v-col
                 :xl="12" :lg="12" :md="12" :sm="12" :xs="12"
                cols="12"                            
            >

            <v-card class="transparent"  color="#00ffff" flat height="100%" style="margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;">
    <v-card-title class="justify-center" style="padding-bottom: 20px;color:#FCF5F5;align-self:center;font-family: Montserrat, sans-serif;font-size:60px;">
                      
        Intranet
    </v-card-title>
    
</v-card>


                
            
                
                
               
                
            </v-col>
            </v-row>

    
      </v-container>
      </v-card>
   </v-parallax>
   
</v-card>


             </div>
             </v-layout>
           </v-card>
          
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center style="padding:7px;">
            <div class="flex xl11 lg11 md11 sm12 xs11">
            
             
            
            <v-row        
                    class="mb-6 justify-center"
                    no-gutters
                    style="margin-top:px;margin-bottom:px;margin-left:px;margin-right:px;"                       
                    >
                    
                    
                                
                     <v-col
                            :xl="12" :lg="12" :md="12" :sm="12" :xs="12"
                            cols="12"    
                            class="justify-start my-1"  
                            style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;align-self:center"                      
                        > 
                        <v-card :flat="false" :tile="false" height="100%"  class="mx-1">
                        <v-card-title class="IntranetHeader mediumoverline">
                        Site Blogs
                        </v-card-title>
                        
                        
                          
                        
                          <SiteBlogsFeed  :SliderView="true" :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="250" :Slice="6" :Filter="SiteBlogsFilter" />
                            
                            
                            
                            
                            
                            
                            
                            
                            
                        </v-card>
                     </v-col>
            </v-row>
             </div>
             </v-layout>
           </v-card>
          
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center style="padding:7px;">
            <div class="flex xl11 lg11 md11 sm12 xs11">
            
             
            
            <v-row        
                    class="mb-6 justify-center"
                    no-gutters
                    style="margin-top:px;margin-bottom:px;margin-left:px;margin-right:px;"                       
                    >
                    
                    
                                
                     <v-col
                            :xl="4" :lg="4" :md="12" :sm="12" :xs="12"
                            cols="12"    
                            class="justify-start my-1"  
                            style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;"                      
                        > 
                        <v-card :flat="false" :tile="false" height="100%"  class="mx-1">
                        <v-card-title class="IntranetHeader mediumoverline">
                        Notice Board
                        </v-card-title>
                        
                        
                          
                        
                          <NoticeboardFeed style="padding:15px;" :FeedView="true" />
                            
                            
                            
                            
                            
                            
                            
                            
                            
                        </v-card>
                     </v-col>
                     <v-col
                            :xl="8" :lg="8" :md="12" :sm="12" :xs="12"
                            cols="12"    
                            class="justify-center my-1"  
                            style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;align-self:center"                      
                        > 
                        <v-card :flat="false" :tile="false" height="100%"  class="mx-1">
                        <v-card-title class="IntranetHeader mediumoverline">
                        Site Articles
                        </v-card-title>
                        
                        
                          
                        
                          <SiteArticlesFeed :SliderView="true" :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="250" :Slice="6" :Filter="SiteArticlesFilter" />
                            
                            
                            
                            
                            
                            
                            
                            
                            
                        </v-card>
                     </v-col>
            </v-row>
             </div>
             </v-layout>
           </v-card>
          
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center style="padding:7px;">
            <div class="flex xl10 lg11 md11 sm11 xs10">
            
             
            
            <v-row        
                    class="mb-6 justify-center"
                    no-gutters
                    style="margin-top:px;margin-bottom:px;margin-left:px;margin-right:px;"                       
                    >
                    
                    
                                
                     <v-col
                            :xl="11" :lg="12" :md="12" :sm="11" :xs="10"
                            cols="12"    
                            class="justify-center my-1"  
                            style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;align-self:center"                      
                        > 
                        <v-card :flat="false" :tile="false" height="100%"  class="mx-1">
                        <v-card-title class="IntranetHeader mediumoverline">
                        Social Posts
                        </v-card-title>
                        
                        
                          
                        
                          <SocialSitePosts  :UserObj="UserRecord" :dark="AppisDarkMode" class="mx-3" :UserRecord="UserRecord" :UserID="UserRecord.id" :UserView="false" :Slice="10" />
                            
                            
                            
                            
                            
                            
                            
                            
                            
                        </v-card>
                     </v-col>
            </v-row>
             </div>
             </v-layout>
           </v-card>
          
          </v-flex>
          
          
  </v-row>
  
    </v-card>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NoticeboardFeed from '@/components/System/Notices/NoticeboardFeed';
import SystemTrainingDocumentsFeed from '@/components/System/SystemTrainingDocumentsFeed';
import SiteBlogsFeed from '@/components/Blog/SiteBlogsFeed';

import SiteArticlesFeed from '@/components/Articles/SiteArticle/SiteArticleFeed';
import SystemActivitiesFeed from '@/components/System/SystemActivitiesFeed';
import SocialSitePosts from '@/components/System/SocialSitePosts';


export default {
    props: ['AppisDarkMode','IntranetView','AppisDarkModeCard'],
    components: {
        NoticeboardFeed,
        SystemTrainingDocumentsFeed,
        SiteBlogsFeed,
        SiteArticlesFeed,
        SystemActivitiesFeed,
        SocialSitePosts,
        },
    data() {
    return {
      carddarkcolor: 'grey darken-4',
      cardlightcolor: 'white',
        mustreadnotices: [],
        noticestep: 0,
          
          
          
          
      RelevantTopBannerAds: [],      
      UserRecord: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    watch: {
    mustreadnotices (value) {
      if (value.length>0) {
        this.MustReadNoticeDialog = true
      }
    },
    },
    computed:{
      currentNoticeTitle () {
        if(this.mustreadnotices.length > 0 && this.noticestep){
          console.log(this.mustreadnotices)
          return this.mustreadnotices[this.noticestep].title
        }
        
      },
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      SiteBlogsFilter(){
        return        
      },
      
      
      SiteArticlesFilter(){
        return        
      },
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()

    },
    
    methods: {
      ActivateMultipleFilesUploadNotification(boolean){
        this.$emit('ActivateMultipleFilesUploadNotification',boolean)
      },
      GetHeaderSize(int){
         if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
              return int/2
          }
          else if(this.$vuetify.breakpoint.md){
              return int/1.5
          } 
          else {
              return int
          }
      },
      ActivateNotificationSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateNotificationSnackbar',boolean,snackbarcontent)
      },
      ActivateStandbyDialog(boolean){
        this.$emit('ActivateStandbyDialog',boolean)
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      SubmitForm(tab){
            console.log(tab)
            tab.FormProcessing = true
            if(tab.FromDatabase){
                this.$emit('ProcessingData',true)
            }
            if(tab.FormEmailRecipient){
                let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry - Intranet</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`
                 } 
            })
            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New Enquiry - Intranet',
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
            }
            console.log('tab.EntityForm',tab.EntityForm)
            if(tab.EntityForm){
                let NewRecord = {
                    Created_On: new Date()
                }
                let length = tab.FormFields.length
                tab.FormFields.map((field,i) => {
                NewRecord[field.Name.split(' ').join('')] = field.Response
                if(field.Type === 'Date'){
                    let date = this.DateFormatter(field.Response)
                    NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: date.getTime()}    
                    }
                if(i-1+2 === length){
                  const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(tab.DefaultOwner){
                    NewRecord.Owner = tab.DefaultOwner
                    NewRecord.Ownerid = tab.DefaultOwner.id
                  }
                  if(this.userLoggedIn){
                      NewRecord.WebSubmission = false
                  }
                  else{
                      NewRecord.WebSubmission = true
                  }
                  if(!NewRecord.Created_By && this.userLoggedIn){
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Created_Byid = this.userLoggedIn.id
                      
                  }
                  let obj = {
                    collection: tab.RelatedEntity.id,
                    data: NewRecord
                  }    
                  console.log(obj)        
                  SubmitFormData(obj).then(result => {
                    console.log(result)
                    if(tab.FromDatabase){
                        this.$emit('ConfirmSubmission',tab,obj,result.data)
                        this.$emit('ProcessingData',false)
                    }
                    else{
                      tab.FormSubmitted = true  
                    }
                    
                }) 
            }
                })
            }
        },
      GetMustReadNotices() {
           db.collection('notices').where('MustRead','==',true).onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                  })
                  
                
              }  
              

            })
          })

          },
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
             let vm = this
            if(this.userLoggedIn){
              vm.UserRecord = this.userLoggedIn
              vm.GetMustReadNotices()
              let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)           
              vm.GetBannerAds(RouteBannerAdsRef)
              console.log(vm.UserRecord)
                }
                else{
                  vm.$router.push('/Login')
                  let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
                 
                  vm.GetBannerAds(PublicRouteBannerAdsRef)
                }
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}
</style>
  
            
        