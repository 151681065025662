          
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  
  

  
    
  <v-dialog width="400" v-model="ChangeStatusDialog">
          <v-card width="400">
            <v-card-title>Change Status</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Status"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
             v-if="Status"
            :items="Status.Options"
            v-model="Status_Reason"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="ViewFileDialog" fullscreen>
        <v-card tile class="white" flat>
          <v-btn @click="CloseViewFileDialog()" style="z-index:2;">Close</v-btn>
          <embed v-if="ViewFileURL" :height="ImageHeight-25" :width="ImageWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="ImageHeight-25" :width="ImageWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>

      <v-toolbar tile dark :color="Status && Status.ID === 1000001 ? 'accent':'warning'" class="stickytopbanner2" width="100%" id="recordtoolbar" v-if="!WikiMode">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item @click="ChangeStatusDialog = !ChangeStatusDialog" class="listoutline">
            <v-list-item-title  class="subtleoverline">
              {{StatusToggleName}}
            </v-list-item-title>
          <v-list-item-action>
              <v-icon class="actionicon" color="white">mdi-power</v-icon>
            </v-list-item-action>
          </v-list-item>
        <v-list-item @click="UpdateBusinessUnit()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditBusinessUnit()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title>
   <v-list width="100%" class="transparent">
   <v-list-item>
  <v-list-item-content >
    <span class="headline"><span class=" hidden-sm-and-down">Business Unit - </span><span class="headline font-weight-light">{{ Name  }}</span></span>                   
    <v-list-item-subtitle v-if="Status">
       {{Status.Name}}
    </v-list-item-subtitle>
  </v-list-item-content>
  </v-list-item>
    </v-list>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
  <v-icon style="background-color:green;" @click="UpdateBusinessUnit()" color="white" class="soloactionicon">mdi-content-save</v-icon>
  </v-layout>
  

  
  
    

  
    <!--<EmailComponent v-if="previewsendemaildialog" :PresetEmailBody="EmailBody" :PresetEmailFrom="EmailFrom" :PresetEmailTo="EmailTo" :PresetEmailCC="EmailCC" :PresetEmailBCC="EmailBCC" :PresetEmailSubject="EmailSubject" :previewsendemaildialog="previewsendemaildialog"/> -->
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Business Unit help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingBusinessUnit" tile  width="100%" class="stickytopbanner3" id="recordownershipbanner1">    
</v-card>
<v-card v-if="EditingBusinessUnit" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">BusinessUnit - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Name" label="Name" />
  </v-card-text>
  </v-img>
</v-card> 
  
  
  
       <v-layout class="justify-center" style="margin-top:150px;">
     <v-flex lg8 md10>


          <h3 class="primary--text">Tab 1</h3>

           <v-layout row v-if="!EditingBusinessUnit" class="outline">
        <v-col>
          <v-card outlined  height="100%">
              <v-card flat>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Name</h3><v-spacer></v-spacer><h4>{{ Name }}</h4>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Description</h3><v-spacer></v-spacer><p>{{ Description }}</p>
                  </v-layout>
                  </v-card-subtitle>
                 <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Slogan</h3>
                  </v-layout>
                  <v-layout row>
                    <span v-html="Slogan"></span>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <v-spacer></v-spacer><v-switch :disabled="!userisBusinessProfileAdmin" @change="updateIsPublicLocation()" label="Public Location" v-model="IsPublicLocation"></v-switch>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Parent</h3><v-spacer></v-spacer><p v-if="Parent">{{ Parent.Name }}</p>
                  </v-layout>
                  </v-card-subtitle>
                   <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <a :href="'callto:'+Telephone" target="_top">{{Telephone}}</a>
                  </v-layout>
                  </v-card-subtitle>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
<v-layout row v-if="!EditingBusinessUnit" class="outline">
        <v-col>
          <v-card outlined  height="100%">
              <v-card flat>
                <v-btn :href="FinalBUAddress" target="_blank">View</v-btn>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Street Address</h3><v-spacer></v-spacer>{{ BusinessUnitData.AddressLine1 }}
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Suburb</h3><v-spacer></v-spacer>{{ BusinessUnitData.AddressLine2 }}
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">City</h3><v-spacer></v-spacer>{{ BusinessUnitData.AddressLineCity }}
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Postal Code</h3><v-spacer></v-spacer>{{ BusinessUnitData.AddressLinePostalCode }}
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Province</h3><v-spacer></v-spacer>{{ BusinessUnitData.AddressLineProvince }}
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Country</h3><v-spacer></v-spacer>{{ BusinessUnitData.AddressLineCountry }}
                  </v-layout>
                  </v-card-subtitle>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
          

<v-layout row v-if="EditingBusinessUnit" class="outline">
          <h3 class="primary--text">Tab 1</h3><br><br>
          <v-row>
        <v-col><br>
          <v-card flat>
              <v-card flat>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>Name</h4><v-text-field v-model="Name"></v-text-field></div>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>Description</h4><v-textarea v-model="Description"></v-textarea></div>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                   <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>Slogan</h4></div>                  
                        </v-row>
                         <v-row>
                            <v-card outlined class="postinput" v-html="Slogan" contenteditable id="SloganInput">
                              </v-card>
                         </v-row>
                    </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <div><h4>Parent</h4><v-combobox
                        v-model="Parent"
                        :items="PotentialParentsOnly"
                        @change="AssignBusinessUnitHierarchy()"
                        item-text="Name"
                        hide-selected
                        label="Parent"
                        small-chips
                        solo
                        flat
                        ></v-combobox></div>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Telephone</h4>
                  <v-text-field label="Telephone" :rules="[rules.telnr]" v-model="Telephone"></v-text-field>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>

                </v-card>
            </v-card>
          </v-col>
          </v-row>
          </v-layout>
<v-layout row v-if="EditingBusinessUnit" class="outline">
        <v-col>
          <v-card outlined  height="100%">
              <v-card flat>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Street Address</h3><v-spacer></v-spacer><v-text-field v-model="BusinessUnitData.AddressLine1"></v-text-field>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Suburb</h3><v-spacer></v-spacer><v-text-field v-model="BusinessUnitData.AddressLine2"></v-text-field>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">City</h3><v-spacer></v-spacer><v-text-field v-model="BusinessUnitData.AddressLineCity"></v-text-field>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Postal Code</h3><v-spacer></v-spacer><v-text-field v-model="BusinessUnitData.AddressLinePostalCode"></v-text-field>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Province</h3><v-spacer></v-spacer><v-text-field v-model="BusinessUnitData.AddressLineProvince"></v-text-field>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Country</h3><v-spacer></v-spacer><v-text-field v-model="BusinessUnitData.AddressLineCountry"></v-text-field>
                  </v-layout>
                  </v-card-subtitle>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
<v-layout row v-if="EditingBusinessUnit" class="outline">
          <h3 class="primary--text">Tab 2</h3><br><br>
          <v-row>
        <v-col><br>
          <v-card flat>
              <v-card flat>
                </v-card>
            </v-card>
          </v-col>
        <v-col><br>
          <v-card flat>
              <v-card flat>
                </v-card>
            </v-card>
          </v-col>
          </v-row>
          </v-layout>
      <v-tabs row centered background-color="accent">
        <v-tab>Users</v-tab>
        <v-tab-item>
            <RelatedUsers/>
        </v-tab-item>
        <v-tab>Business Units</v-tab>
        <v-tab-item>
            <RelatedBusinessUnits/>
        </v-tab-item>
      </v-tabs>
      </v-flex>
  </v-layout>


    

  

  


   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
  </v-layout>
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import RelatedUsers from '@/components/RelatedUsers'
import RelatedBusinessUnits from '@/components/RelatedBusinessUnits'
import Appointments from '@/components/Appointments'
import RecordTasks from '@/components/RecordTasks'
import RecordEmails from '@/components/RecordEmails'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
  components: {
    SystemTicketsLogging,
  RelatedUsers,
  RelatedBusinessUnits,
},
    

  data() {
    return {
      ViewFileDialog: false,
      ViewFileURL: '',
      ViewIMGURL: '',
      ChangeStatusDialog: false,
  
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      
      selectedEntity: {Name: 'Business Units',
      PrimaryField: 'Name',
      Tables: [
          ]
      },
      selectedRecord: {},
      BannerTabs: [
        ],
      TicketLog: false,
      step: 1,
      rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      previewsendemaildialog: false,
      EmailBody: '',
      EmailFrom: '',
      EmailTo: '',
      EmailCC: '',
      EmailBCC: '',
      EmailSubject: '',
      STDAppointmentsQuery: db.collection('activities').where('activitytype','==','Appointment').where('regardingtype', '==', 'Business Unit').where('regardingrecord.id', '==', this.$route.params.id),
      AppointmentsQuery: '',
      ActivitiesArray: [],
      NotesArray: [],
      Name: '',
      Description: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {
  "LevelFieldName": "Status_Reason",
  "ID": 1000003,
  "Name": "Open",
  "Level": {
    "children": [],
    "id": 2,
    "FieldName": "Status_Reason",
    "name": "Status Reason"
  }
},
      Parent:  {id: '', name: ''},
      UnpermittedParent:  {id: '', name: ''},
      HasParent: false,
      ParentRelatedParentObj: {},
      Parentname: null,
      
      
      StatusOptions: [{
  "HasDeepHierarchy": true,
  "Level": {
    "id": 1,
    "FieldName": "Status",
    "children": [
      {
        "name": "Status Reason",
        "id": 2,
        "children": [],
        "FieldName": "Status_Reason"
      }
    ],
    "name": "Status"
  },
  "Options": [
    {
      "ID": 1000003,
      "LevelFieldName": "Status_Reason",
      "Name": "Open",
      "Level": {
        "children": [],
        "id": 2,
        "FieldName": "Status_Reason",
        "name": "Status Reason"
      }
    }
  ],
  "Name": "Active",
  "ID": 1000001,
  "LevelFieldName": "Status"
},{
  "ID": 1000002,
  "HasDeepHierarchy": true,
  "Name": "InActive",
  "Level": {
    "id": 1,
    "children": [
      {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    ],
    "name": "Status",
    "FieldName": "Status"
  },
  "LevelFieldName": "Status",
  "Options": [
    {
      "Name": "Closed",
      "Level": {
        "children": [],
        "id": 2,
        "FieldName": "Status_Reason",
        "name": "Status Reason"
      },
      "LevelFieldName": "Status_Reason",
      "ID": 1000004
    }
  ]
}],
      Statusfilter: '',
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      ParentArray: [],
      Parentfilter: {name: ''},
      
      EditingBusinessUnit: false,
      dialog: false,
      initialize: '',
      search: '',
      BusinessUnitData: {},
      headers: [
      
        
        { text: 'Name', value: 'Name'},
        
        
        { text: 'Description', value: 'Description'},
        
        
        { text: 'Status', value: 'Status.Name'},
        
        
        
        { text: 'Parent', value: 'Parent.name'},
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedBusinessUnitsnackbar: false,
      BusinessUnitEditdialog: false,
      newBusinessUnitdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      Name:  '',
      Description:  '',
      Slogan: '',
      IsPublicLocation: false,
      Telephone: '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      UnitDownsToUpdate: [],
      ChildArrayQuery: [],
      UnitDownsandChildrenArrayPushed: [],
      UnitDownsandChildrenDBObject: {},
      
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      IsAdmin: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
window.addEventListener('scroll', this.handleScroll)

  },
  
  computed: {
    
    userisBusinessProfileAdmin(){
        return this.$store.state.BusinessProfileAdmin || this.userIsAdmin
      },
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
    ComputedStageID(){
    return this.ProcessStages[this.StageID-1].Number
  },
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
        return true
      }
    }
    else{
      return this.userIsAdmin
    }
  
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  ActivitiesSearched() {
    return this.ActivitiesArray.filter(Activity => {
      if(Activity.Name){return Activity.Name.toLowerCase().includes(this.search.toLowerCase())}}).filter(Activity => {if(Activity.description){return Activity.description.toLowerCase().includes(this.search.toLowerCase())}
    })
    },
    ActivitiesFilteredandSearched() {
    return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
    },
    TasksFilteredandSearched(){
        return this.ActivitiesFilteredandSearched.filter(activity => {
            return activity.activitytype === 'Task' && this.DateFilterString(activity.duedate) === this.FormattedTextDate && activity.status.includes(this.TaskStatusFilter)
        })
    },
    TasksFilteredandSearchedTable(){
        return this.TasksFilteredandSearched.filter(activity => {
            return activity.duedate === this.TaskFilterDate
        })
    },
    FormattedPickDate(){
      return this.DateFormatter(this.TaskFilterDate)
    },
    FormattedTextDate(){
      return this.TaskFilterDate.split('-').join('')
    },
  FinalBUAddress(){
    let combinedlink = this.BusinessUnitData.AddressLine1+'+'+this.BusinessUnitData.AddressLine2+'+'+this.BusinessUnitData.AddressLineCity+'+'+this.BusinessUnitData.AddressLinePostalCode+'+'+this.BusinessUnitData.AddressLineProvince+'+'+this.BusinessUnitData.AddressLineCountry
    let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')

  return FinalLink
  },
  PotentialParentsOnly() {
    return this.ParentArray.filter(unit => {
      return !this.ChildArrayQuery.includes(unit.id)
    })
  },
    
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    },
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
              if(vm.UserRecord !== 'undefined'){
            vm.AppointmentsQuery = vm.STDAppointmentsQuery.where('Ownerid', '==', vm.UserRecord.id)
                vm.UserRoles = vm.UserRecord.rolesarrayQuery
                let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.GetBusinessUnit(AdminTest)
                        
              let BusinessUnitsParentQuery = db.collection('businessunits')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetBusinessUnitsParentArrayandLookupValue(BusinessUnitsParentQuery)
                }
              }
              

                }
                else{
                  vm.RoutetoErrorPage()
                }
              }
              
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetBusinessUnit() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
            this.GetBusinessUnitsParent()
        }
            db.collection('businessunits').doc(this.$route.params.id).onSnapshot(snapshot => {
            var businessunit = snapshot.data()
            businessunit.id = this.$route.params.id
              if(businessunit.Telephone){
                this.Telephone = businessunit.Telephone
              }
              if(businessunit.Slogan){
                this.Slogan = businessunit.Slogan
              }
            
            this.selectedRecord = {id: this.$route.params.id, Name: businessunit.Name }
            this.RelatedObj = {id: this.$route.params.id, Name: businessunit.Name,identifier: 'Name',collection: 'businessunits',Link:'BusinessUnit' }
                  let ActivitiesCollectionReference = db.collection('activities')
                  let ActivitiesstandardQuery = ActivitiesCollectionReference.where('regardingtype', '==', 'BusinessUnit').where('regardingrecord.id', '==', vm.$route.params.id)
                  let ActivityRegardingQuery =  ActivitiesstandardQuery;
                  vm.GetActivities(ActivityRegardingQuery)
            this.BusinessUnitData = businessunit
            this.Status_Reason = businessunit.Status_Reason
            this.BusinessUnitData.id = this.$route.params.id
            this.$emit('PushRecordName',this.BusinessUnitData.Name)
            if(typeof businessunit.notesarray !== 'undefined'){
            this.NotesArray = businessunit.notesarray
              }
            if(typeof businessunit.Owner !== 'undefined'){
              this.Business_Unit = businessunit.Business_Unit
            }        

            this.Name = businessunit.Name
            this.editedItem.Name = businessunit.Name
            //I think API Trigger should be here, outside the first if loop on fields
            this.Description = businessunit.Description
            this.editedItem.Description = businessunit.Description
            //I think API Trigger should be here, outside the first if loop on fields
            this.Status = businessunit.Status
            this.editedItem.Status = businessunit.Status
            //I think API Trigger should be here, outside the first if loop on fields
            if(businessunit.Parent){
            this.ParentRelatedParentObj = {id: businessunit.Parent.id, Name: businessunit.Name,identifier: 'name',collection: 'businessunits',Link: 'BusinessUnit' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                  this.Parent = this.ParentArray.find(parent => parent.id === businessunit.Parentid)
                
            if(typeof businessunit.Parentid !== 'undefined' && businessunit.Parentid !== ''){
              this.HasParent = true
              if(typeof this.Parent === 'undefined'){
                this.UnpermittedParent = {id: businessunit.Parentid,name: businessunit.Parent.name}
              }
            }
            this.editedItem.Parent = businessunit.Parent
    
            })
          
        },
      
      


    
      
      
            CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
      GetCols(int){
      if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg){
        return int
      }
      else if(this.$vuetify.breakpoint.md){
        return int*1.5
      }
      else if(this.$vuetify.breakpoint.sm){
        return int*2.5
      }
      else{
        return int*3
      }
    },
      ResetStatus(){
      this.Status = Object.assign({},this.BusinessUnitData.Status)
      this.Status_Reason = Object.assign({},this.BusinessUnitData.Status_Reason)
      this.ChangeStatusDialog = false
    },
    UpdateStatus(){
      db.collection('businessunits').doc(this.$route.params.id).update({
        Status: this.Status,
        Status_Reason: this.Status_Reason
      })
      this.ChangeStatusDialog = false
    },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
          }
          else{
            this.ViewFileURL = result.data[0]
          }
          console.log(window.innerHeight)
          this.ViewFileDialog = true
          //window.location.assign(result.data[0])
        })
    },
      ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode')
      },
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        updateIsPublicLocation(){
          db.collection('businessunits').doc(this.$route.params.id).update({
            IsPublicLocation: this.IsPublicLocation,
            Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            Modified_Byid: this.UserRecord.id,
            Modified_On: new Date()
          })
        },
      
        UpdateBusinessUnit(){
        this.UndefinedMandatoryFields = []
      const EditedBusinessUnit = {
    Name: this.Name,
    Description: this.Description,
    
      Name: this.Name,
        description: this.Description,
        }
        if(this.Parent){
          EditedBusinessUnit.Parent = {id: this.Parent.id, Name: this.Parent.Name}
        EditedBusinessUnit.Parentid = this.Parent.id
        }
      for(var prop in EditedBusinessUnit) {
          if (EditedBusinessUnit.hasOwnProperty(prop)) {
            if(typeof EditedBusinessUnit[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      db.collection('businessunits').doc(this.$route.params.id).update({
        Name: EditedBusinessUnit.Name,
        Description: EditedBusinessUnit.Description,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
                  Name: EditedBusinessUnit.Name,
                  description: EditedBusinessUnit.description
        
              });
                
              if(EditedBusinessUnit && EditedBusinessUnit.Parent && typeof EditedBusinessUnit.Parent.id !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 Parent: {id: EditedBusinessUnit.Parent.id, Name: EditedBusinessUnit.Parent.Name},
                Parentid: EditedBusinessUnit.Parentid,
               })
             }
          if(typeof this.BusinessUnitData.AddressLine1 !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 AddressLine1: this.BusinessUnitData.AddressLine1
               })
             }
             if(typeof this.BusinessUnitData.AddressLine2 !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 AddressLine2: this.BusinessUnitData.AddressLine2
               })
             }
             if(typeof this.BusinessUnitData.AddressLineCity !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 AddressLineCity: this.BusinessUnitData.AddressLineCity
               })
             }
             if(typeof this.BusinessUnitData.AddressLineCountry !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 AddressLineCountry: this.BusinessUnitData.AddressLineCountry
               })
             }
             if(typeof this.BusinessUnitData.AddressLineProvince !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 AddressLineProvince: this.BusinessUnitData.AddressLineProvince
               })
             }
             if(typeof this.BusinessUnitData.AddressLinePostalCode !== 'undefined'){
               db.collection('businessunits').doc(this.$route.params.id).update({
                 AddressLinePostalCode: this.BusinessUnitData.AddressLinePostalCode
               })
             }
               if(this.Telephone){
                  db.collection('businessunits').doc(this.$route.params.id).update({
                    Telephone: this.Telephone,
                  })
                }
              let elmnt = document.getElementById('SloganInput')
                if(elmnt && elmnt.innerHTML){
                  this.Slogan = elmnt.innerHTML
                  db.collection('businessunits').doc(this.$route.params.id).update({
                    Slogan: this.Slogan,
                  })
                }
                
              this.close()
              this.EditedBusinessUnitsnackbar = true
              this.CancelBusinessUnitEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Name !== 'undefined'){                  
                  let NameQuery = []
                  let NameStringArray = this.Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(NameStringArray.length,NameStringArray)
                    for (p = 0; p < NameStringArray.length; p++) {
                        buildup = buildup+ NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    NameQuery = lowertext.split(',')  
                    NameQuery.length = NameQuery.length-1
                   db.collection('businessunits').doc(this.$route.params.id).update({
                     NameQuery: NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.BusinessUnitsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('businessunits').doc(item.id).delete() && this.BusinessUnitsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.BusinessUnitsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelBusinessUnitEditing(){
      this.EditingBusinessUnit = false
    },
    EditBusinessUnit() {
      this.EditingBusinessUnit = !this.EditingBusinessUnit
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetBusinessUnitsParent() {
  db.collection('businessunits').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.ParentArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetBusinessUnitsParentArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.BusinessUnitsStore.length > 0){
      //alert('got from store')
      vm.ParentArray = vm.BusinessUnitsStore
      vm.GetParent();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setBusinessUnitsArray',
                    getter: 'getBusinessUnitsArray',
                    arrayname: 'BusinessUnitsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.BusinessUnitsStore)
                        vm.ParentArray = vm.BusinessUnitsStore
                        vm.GetParent();
                        })
    }
  },

  GetParent(){
    this.Parent = this.ParentArray.find(parent => parent.id === this.BusinessUnitData.Parentid)
  },

  //GetBusinessUnitsParent(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.ParentArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetBusinessUnitsParent finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingParent(){
this.CancelAddParentDialog()
this.PrepareDataParentParent()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Parent'
      this.LookupArray = this.ParentArray
      this.LookupArrayHeaders = [
        { text: 'Description', value: 'Description',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Parent', value: 'Parent.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
      ]
},

PrepareDataParentParent(){
  this.ParentTitle = 'Parent'
  this.ParentCollection = 'businessunits'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Parent', Prop: 'name', Propid: 'Parentid', Value: '',Array: 'ParentArray',LookupFieldName: 'Parent'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Parent', Prop: 'Parent', Value: '',LookupFieldName: 'Parent'}
        let DescriptionObj = {Type: 'Multiple Lines Text', Name: 'Description',Prop: 'Description'}
  this.NewParentAdditional.push(DescriptionObj )
        let ParentObj = {Type: 'Lookup', Name: 'Parent',Prop: 'name',Array: this.ParentArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(ParentObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "HasDeepHierarchy": true,
    "Level": {
      "id": 1,
      "FieldName": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "children": [],
          "FieldName": "Status_Reason"
        }
      ],
      "name": "Status"
    },
    "Options": [
      {
        "ID": 1000003,
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "Level": {
          "children": [],
          "id": 2,
          "FieldName": "Status_Reason",
          "name": "Status Reason"
        }
      }
    ],
    "Name": "Active",
    "ID": 1000001,
    "LevelFieldName": "Status"
  },
  {
    "ID": 1000002,
    "HasDeepHierarchy": true,
    "Name": "InActive",
    "Level": {
      "id": 1,
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "name": "Status",
      "FieldName": "Status"
    },
    "LevelFieldName": "Status",
    "Options": [
      {
        "Name": "Closed",
        "Level": {
          "children": [],
          "id": 2,
          "FieldName": "Status_Reason",
          "name": "Status Reason"
        },
        "LevelFieldName": "Status_Reason",
        "ID": 1000004
      }
    ]
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )     
  
},

ActivateAddNewParentDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentParent()
},



      
    
      
      
    
      
    
      
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          DateFilterString(date){      
      let onedate = date.toDate()
      let dateyear = onedate.getFullYear().toString()
      let datemonth = onedate.getMonth()-1+2
      let datemonthfinal = datemonth.toString()
      let dateday = onedate.getDate()
      if(datemonth.toString().length===1){
        let stringdate = dateyear+'0'+datemonth+dateday
      return stringdate
      }
      else{
        let stringdate = dateyear+datemonth+dateday
      return stringdate
      }
    },
    AddNote(){
      let NewNote = {
        Title: 'New Note',
        Owner: {Full_Name: this.UserRecord.Full_Name, id:this.UserRecord.id},
        Description: 'Add Description to the Note',
        Color: '#ECEC93FF',
        TitleColor: '#EBEB6C',
        Dark: false
      }
      this.NotesArray.push(NewNote)
      this.UpdateNotesArray()
    },
    LightenColor(note) {
    let color = note.Color
  	let num = parseInt(color.replace("#",""), 16)
		let amt = Math.round(2.55 * 25)
		let R = (num >> 16) - amt
		let B = (num >> 8 & 0x00FF) - amt
		let G = (num & 0x0000FF) - amt;

		note.TitleColor = (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    },
    ToggleNoteDark(note){
      if(note.Dark === true){
        note.Dark = false
        this.UpdateNotesArray()
      }
      else{
        note.Dark = true
        this.UpdateNotesArray()
      }
    },
    UpdateNotesArray(){
      db.collection('businessunits').doc(this.$route.params.id).update({
        notesarray: this.NotesArray,
        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        Modified_Byid: this.UserRecord.id,
        Modified_On: new Date()
      })
    },
    
    SaveNewTask(){
      const TasktoAdd = {
            Name: this.NewTask.Name,
            description: this.NewTask.description,
            activitytype: 'Task',
            regardingtype: 'BusinessUnit',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: this.DateFormatter(this.NewTask.duedate),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            regardingrecord: {
              Link:'/BusinessUnit/'+this.$route.params.id, Name:this.Name, Name:this.Name,id: this.$route.params.id
            },

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      if(typeof this.Business_Unit.id !== 'undefined'){
        TasktoAdd.Business_Unitid = this.Business_Unit.id
      }
      db.collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.activitytype === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Name,
              Content: TasktoAdd.description,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname
            }
            db.collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },

    GetActivities( regardingquery) {

      regardingquery.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },
      
      
     
      
      

      
      
GetBusinessUnitsParent() {
  db.collection('businessunits').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.ParentArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

AssignBusinessUnitHierarchy(){
  console.log('the first call')
  console.log(this.Parent)
  let CurrentUnitObj = this.ParentArray.find(obj => obj.id == this.$route.params.id)
  let CurrentUnitIndex = this.ParentArray.indexOf(CurrentUnitObj)
  this.ParentArray[CurrentUnitIndex].Parent = {Name: this.Parent.Name, id: this.Parent.id}
  this.ParentArray[CurrentUnitIndex].Parentid = this.Parent.id
  // this.UpdateBusinessUnit()
  this.fixunithierarchy()
},

async fixunithierarchy(){
  console.log('I await')

  await this.DestroyOldHierarchy()
  console.log('Done Waiting')

  this.getParentofParent(this.Parent)
},


async DestroyOldHierarchy(){

  console.log('watch this')
  console.log(this.PotentialParentsOnly)
  let FilteredParentArray = this.PotentialParentsOnly.filter(unit => {
    return unit.id !== this.$route.params.id
  })
  let CurrentBU = this.ParentArray.find(obj => obj.id === this.$route.params.id)
      console.log('current bu')
      console.log(CurrentBU)

  Array.from(Array(CurrentBU.childarrayQuery.length).keys()).map(x => {
    this.RemoveBUfromParentQueryandDBArray(CurrentBU.childarrayQuery[x])
  })
},

RemoveBUfromParentQueryandDBArray(BUid){
  console.log("removing "+BUid)
  // let CurrentBU = this.ParentArray.find(obj => obj.id === this.$route.params.id)
  // let BUidCheck = CurrentBU.childarrayQuery.indexOf(BUid)
  // if(BUidCheck === -1){
  let BUtoRemove = this.ParentArray.find(obj => obj.id == BUid)
  let FilteredParentArray = this.PotentialParentsOnly.filter(unit => {return unit.id !== BUid && unit.id !== this.$route.params.id && unit.id !== BUtoRemove.Parentid && unit.Parentid !== this.$route.params.id})
  console.log('filterarray')
  console.log(FilteredParentArray)
  FilteredParentArray.map(unit => {
    if(BUtoRemove.Parentid !== unit.id){
      let dbchildindex = unit.childarrayQuery.indexOf(BUid)
      let querychildobjectprop = unit.childarrayDBRules[BUid]

      if(dbchildindex !== -1){
        console.log('BUtoRemove '+BUtoRemove.id+' is index '+dbchildindex+' on '+unit.id)
        unit.childarrayQuery.splice(dbchildindex,1)

        }
        console.log('BUtoRemove '+BUtoRemove.id+' has no index on '+unit.id)
        if(typeof querychildobjectprop !== 'undefined'){
          console.log('from '+unit.id)
        delete unit.childarrayDBRules[BUid]
        db.collection('businessunits').doc(unit.id).update({
        childarrayDBRules: unit.childarrayDBRules,
        childarrayQuery: unit.childarrayQuery
          })
        }
    }
    else {
      console.log('BU '+BUid+' has '+ unit.id+' as a parent')
    }
    })


    if(this.Parent.Name !== ''){
        this.ParentsToUpdate = []
        console.log('initial call for')
        console.log(this.Parent)
        console.log('Finished')
        console.log('parentarrayvs filtered')
        console.log(this.ParentArray)
        console.log(FilteredParentArray)
      }
      // }
},

UpdateParent(parent){
  console.log('now calling UpdateParent')
    let FilteredParentArray = this.PotentialParentsOnly.filter(unit => {return unit.id !== this.$route.params.id})
    let unitdata = FilteredParentArray.find(obj => obj.id == parent.id)

      if(typeof unitdata.Parent !== 'undefined'){
      console.log('this parent has a parent')
      console.log(unitdata)
      this.getParentofParent(unitdata.Parent)
      }
      else {
      console.log('second okay save it')
      console.log(this.ParentsToUpdate)
      this.ParentArrayUpdate()
      }


},

ParentArrayUpdate() {
  console.log('now calling ParentArrayUpdate')
  Array.from(Array(this.ParentsToUpdate.length).keys()).map(x => {
    this.PrepareChildrenList(this.ParentsToUpdate[x])
  })
},

PrepareChildrenList(parent){
  console.log('now calling PrepareChildrenList')
  let EditedUnit = this.ParentArray.find(obj => obj.id == this.$route.params.id)
  // this.AddQueryandDBChildtoParent(EditedUnit.id)
  console.log("parent")
  console.log(parent)
  let unitdata = this.ParentArray.find(obj => obj.id == parent)
  console.log("unitdata")
  console.log(unitdata)
    Array.from(Array(unitdata.childarrayQuery.length).keys()).map(x => {
    this.AddQueryandDBChildtoParent(EditedUnit.childarrayQuery[x],parent)
  })
},

AddQueryandDBChildtoParent(length, parent){
  console.log('now calling AddQueryandDBChildtoParent')
  console.log(length)
  let CurrentBU = this.ParentArray.find(obj => obj.id === this.$route.params.id)
  console.log('each individually')
  console.log('parents first')
  console.log(parent)
    let FilteredParentArray = this.PotentialParentsOnly.filter(unit => {return unit.id !== this.$route.params.id})
    let unitdata = this.ParentArray.find(obj => obj.id == parent)
  console.log(unitdata)
  Array.from(Array(CurrentBU.childarrayQuery.length).keys()).map(x => {
    this.NarrowChildofChildQueryandDBtoParent(CurrentBU.childarrayQuery[x], unitdata)
  })
  // this.NarrowChildofChildQueryandDBtoParent(unitdata, CurrentBU)
},

NarrowChildofChildQueryandDBtoParent(unitid,unitdata){
  console.log('now calling NarrowChildofChildQueryandDBtoParent')
  let checkon = unitdata.childarrayQuery.indexOf(unitid)
  if(checkon === -1){
    console.log('not on array')
    console.log(unitid)
  unitdata.childarrayQuery.push(unitid)
  console.log('new array')
  console.log(unitdata.childarrayQuery)
  }
  else {
    console.log()
    console.log('is on array')
  }
  let tempchildarrayQuery = unitdata.childarrayQuery
  console.log(unitdata)
  unitdata.childarrayDBRules[unitid] = true
  let tempchildarrayDBRules = unitdata.childarrayDBRules
  db.collection('businessunits').doc(unitdata.id).update({
    childarrayQuery: tempchildarrayQuery,
    childarrayDBRules: tempchildarrayDBRules,
  })
  // this.UpdateBusinessUnit()
},


getParentofParent(parent){
  console.log('now calling getParentofParent')
  console.log(parent)
  this.ParentsToUpdate.push(parent.id)
  let unitdata = this.ParentArray.find(obj => obj.id == parent.id)

    if(typeof unitdata.Parent === 'undefined'){
      console.log('has no parent')
    this.UpdateParent(unitdata)
    }
    else{
      console.log('yip has parent')
      this.ParentsToUpdate.push(unitdata.Parent.id)
      this.UpdateParent(unitdata.Parent)
    }

},

  
      
      ResetFilters() {
        this.Statusfilter = ''
      },
      
      
      
    ProcessDeleteItem(item,itemarrayname,collection){
      let vm = this
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(vm.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
      
      
  
        
        
        
        
        
      
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    
    

    
      
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        