
<template>
    <v-card  tile :dark="AppisDarkMode" flat height="100%"  class="transparent">
      <div class="moving-clouds" 
    :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
     <div class="moving-clouds2" 
    :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>

    <v-dialog v-model="LoginforUpdatePasswordDialog" max-width="800" persistent>
    <v-card  >
      <v-card-title>
        Please Re-Authenticate
      </v-card-title>
     
      <v-card-text>
        You have to re-authenticate in order to update your email
        <v-form>
        <v-text-field v-model="currentEmail" label="Email"></v-text-field>
        <v-text-field type="password" v-model="currentPassword" label="Confirm Password" :rules="[rules.min8Chars,passwordConfirmationRule]"></v-text-field>
        </v-form>
      </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelUpdateUserPasswordorEmail()" color="pop" dark>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="UpdateUserPassword()" v-if="currentEmail && currentPassword " color="green" dark>Save New Password</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

    <v-dialog v-model="LoginforUpdateDialog" max-width="800" persistent>
    <v-card  >
      <v-card-title>
        Please Re-Authenticate
      </v-card-title>
     
      <v-card-text>
        You have to re-authenticate in order to update your email
        <v-form>
        <v-text-field v-model="currentEmail" label="Email"></v-text-field>
        <v-text-field type="password" v-model="currentPassword" label="Confirm Password" :rules="[rules.min8Chars,passwordConfirmationRule]"></v-text-field>
        </v-form>
      </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelUpdateUserPasswordorEmail()" color="pop" dark>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="UpdateUserEmail()" v-if="currentEmail && currentPassword " color="green" dark>Save Email</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
    <v-dialog v-model="UpdateEmailandPassworddialog" max-width="800" persistent>
    <v-card  >
      <v-card-title>
        My Email and Password
      </v-card-title>
      <v-card-text>
        You will be able to change the password and email here. You cannot do it often
        <v-text-field  :rules="[rules.email]" v-model="NewEmail" label="Email"></v-text-field>
        <v-switch v-if="!FBEmailProvider" label="Update Password?" v-model="UpdatingPassword"></v-switch>
      </v-card-text>
      <v-card-text v-if="UpdatingPassword || FBEmailProvider">
        <v-form>
        <v-text-field type="password" v-model="password" label="Password" :rules="[rules.min8Chars]"></v-text-field>
        <v-text-field type="password" v-model="rePassword" label="Confirm Password" :rules="[rules.min8Chars,passwordConfirmationRule]"></v-text-field>
        </v-form>
      </v-card-text>
        <v-card-actions v-if="FBEmailProvider">
          <v-btn @click="CancelUpdateUserPasswordorEmail()" color="pop" dark>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="LoginUpdateUserPassword()" v-if="password && rePassword && password === rePassword" color="green" dark>Save Password</v-btn>
          <v-btn @click="LoginforUpdateUserEmail()" v-if="Email && NewEmail && Email !== NewEmail" color="green" dark>Save Email</v-btn>
        </v-card-actions>
        <v-card-actions v-if="!FBEmailProvider">
          <v-btn @click="CancelUpdateUserPasswordorEmail()" color="pop" dark>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="CreateEmailandPasswordAuth()" id="sign-in-button" v-if="password && rePassword && password === rePassword && NewEmail" color="green" dark>Create Auth</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

    <v-dialog v-model="ProfileVisibilityDialog" max-width="800" persistent>
    <v-card  >
      <v-card-title>
        My Profile Visibilty
      </v-card-title>
      <v-card-text>
        The configuration options available to you for these settings to change these settings are based on an Organizational level. If you are usnure, please speak to your System Administrator(s)
        <v-select :items="['Anybody','Friends of My Friends','My Friends Only']" v-model="MyProfileViewPermissions" label="Who can View my Profile?"/>
        <v-select :items="['Anybody','Friends of My Friends','My Friends Only']" v-model="MyProfileMessagingOptions" label="Who can Message Me?"/>
        <v-select :items="['Anybody','Friends of My Friends']" v-model="MyProfileFriendRequests" label="Who can Friend Me?"/>
      </v-card-text>
        <v-card-actions>
          <v-btn @click="ProfileVisibilityDialog = !ProfileVisibilityDialog" color="pop" dark>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="UpdateProfileVisibility()" color="green" dark>Save</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
  <v-dialog v-model="NotificationSettingsDialog" max-width="800" persistent>
    <v-card >
      <v-card-title>
        My Notification Settings
      </v-card-title>
      <v-card-text>
        This setting determined whether all notification get emailed, and if not, they remain only as Notificaiton on the App. 
        <v-switch label="All Notifications get Emailed" v-model="UsersWantsEmailsOnAllNotifications"></v-switch>
      </v-card-text>
        <v-card-actions>
          <v-btn @click="NotificationSettingsDialog = !NotificationSettingsDialog" color="pop" dark>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="UpdateNotificationEmailer()" color="green" dark>Save</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Technical Manual help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>

<v-parallax class="recordparallax2" v-if="!UserisGuest"
    height="150"
        src="@/assets/RASolidA.jpeg"
    >
        <v-row
              align="center"
              justify="center"
            >
        <v-col class="stdparallaxgradient text-center" cols="12">
          <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large>mdi-face</v-icon>My Profile</span>
          
        </v-col>
      </v-row>
    </v-parallax>
<v-dialog v-model="UpdateUserParallaxDialog" max-width="800">
  <v-card>
    <v-card-title>
      Parallax Image Update
    </v-card-title>
    <v-card-text>
      <input type="file" accept="image/*"
      @change="AddUserParallax($event)"/>
      <v-img v-if="ParallaxlocalURL" :src="ParallaxlocalURL"/>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="DeactivateUserParallaxDialog()">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="UpdateUserParallaxDialog = false">View</v-btn>      
      <v-btn @click="SaveUserParallax()">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
    <v-layout class="justify-center" style="margin-top:60px;">
      
      <v-flex lg10 md12 class="grey lighten-5"> 
      <v-btn v-if="!UserData.Parallax && !ParallaxlocalURL" class="mx-1 my-1" style="position:absolute;z-index:1;" @click="UpdateUserParallax()">Change</v-btn>
      <v-btn v-if="UserData.Parallax && !ParallaxlocalURL" class="mx-1 my-1" style="position:absolute;z-index:1;" @click="UpdateUserParallax()">Change/Approve</v-btn>
      <v-btn v-if="ParallaxlocalURL" class="mx-1 my-1" style="position:absolute;z-index:1;" @click="UpdateUserParallax()">Change</v-btn>
        <v-parallax v-if="!UserData.Parallax && !ParallaxlocalURL"
            height="250"
            src="@/assets/defaultparallax.jpeg"
            >
            </v-parallax> 
            <v-parallax v-if="ParallaxlocalURL"
            height="250"
            :src="ParallaxlocalURL"
            >
            </v-parallax>  
            <v-parallax  v-if="UserData.Parallax && !ParallaxlocalURL"
            height="250"
            :src="UserData.Parallax"
            >
            </v-parallax>

      <div class="flex row xl12 lg12 md12 sm12">                     
        <v-col cols="12" lg="4" md="4" sm="4" style="padding-bottom:40px;">
          <v-avatar
              color="grey"
              size="140" 
                style="margin-top:-50px;"
                class="mx-4"                   
            >
            <input type="file" ref="profilephotofileInputer" @change="onprofilephotoFileselected($event)" style="display: none"/>
            <v-img src="@/assets/BlankProfilePic.png" v-if="!profilephotolocalURL && !Profile_Photo"></v-img>
            <v-img :src="profilephotolocalURL" v-if="profilephotolocalURL"></v-img>
            <v-img v-if="!profilephotolocalURL && Profile_Photo" :src="Profile_Photo"></v-img>
              <v-btn v-if="profilephotolocalURL" @click="onprofilephotoUpload()" class="green white--text" icon small absolute bottom right><v-icon>mdi-content-save</v-icon></v-btn>
              <v-btn v-if="profilephotolocalURL" @click="closephotoUpload()" class="red white--text" icon small absolute bottom left><v-icon>mdi-close</v-icon></v-btn>
              <v-btn v-if="!selectedprofilephotoFile" @click="$refs.profilephotofileInputer.click()" class="blue white--text" icon small absolute top right><v-icon>mdi-pencil</v-icon></v-btn>
          </v-avatar>

          
         <v-card flat width="100%"  class="transparent text-xs-center">
           
              <v-card-subtitle>
              <h4 class="headline font-weight-thin black--text my-1">Favorite Quote</h4>
              </v-card-subtitle>
              <v-card-text>
              <p class="grey--text my-2" v-if="!EditingUser">
                {{ FavoriteQuote }}
              </p>
              <v-textarea v-if="EditingUser" label="Favorite Quote" v-model="FavoriteQuote" />
              <v-list class="transparent">
                <v-list-item>
                  <v-icon>mdi-map-marker</v-icon>{{Business_Unit.Name}}
                </v-list-item>
                <v-list-item>
                  <v-icon>mdi-calendar</v-icon>{{Created_On}}
                </v-list-item>
              </v-list>
              
              
              </v-card-text>
         
              </v-card>
      
        </v-col>

        
        <v-col cols="12" lg="8" md="8" sm="8" style="padding-bottom:40px;">
          
          <v-card  width="100%" shaped >
             <v-card-title class="blue white--text"  :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')' }">
            Basic<v-spacer></v-spacer>
            <v-btn v-if="!FBEmailProvider" @click="LinkEmailAccount()">Link Email<v-icon color="blue">mdi-lastpass</v-icon></v-btn>
            <v-btn v-if="FacebookProvider && !FacebookToken" @click="SignIntoFacebook()" >Sign In<v-icon color="blue">mdi-facebook</v-icon></v-btn>
            <v-btn v-if="FacebookProvider && FacebookToken"  color="blue">Signed In<v-icon color="white">mdi-facebook</v-icon></v-btn>
            <v-btn v-if="!FacebookProvider" @click="LinkFacebookAccount()">Link Facebook<v-icon color="blue">mdi-facebook</v-icon></v-btn>
            <v-btn class="pop hidden-sm-and-down" @click="EditingUser = !EditingUser" dark>Edit Profile</v-btn><v-btn class="pop hidden-md-and-up" icon @click="EditingUser = !EditingUser" dark><v-icon>mdi-pencil</v-icon></v-btn><v-btn class="green" v-if="EditingUser || profilephotolocalURL" @click="saveMyProfile()" dark>Save</v-btn> 
          </v-card-title>

          <div class="mx-3 flex row xl12 lg12 md12 sm12">
                  <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Title</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Title }}</h4>
                          <v-select
                           v-if="EditingUser"
                              label="Title"
                              v-model="Title"
                              :rules="[rules.required]"
                              :items="['Mr','Mrs','Ms']"
                          ></v-select> 
                    </v-col>
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Name</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Name }}</h4>
                          <v-text-field v-if="EditingUser" label="Name" v-model="Name" />
                    </v-col>
                  <v-col row>
                    <h4 :class="HeaderDarkLightStyle">Surname</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Surname }}</h4>
                          <v-text-field v-if="EditingUser" label="Surname" v-model="Surname" />
                  </v-col>
                  </div>
                  <div class="mx-3 flex row xl12 lg12 md12 sm12">

                  
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Email</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Email }}</h4>
                          <v-text-field v-if="EditingUser" label="Email" v-model="Email" />
                    </v-col>
                  <v-col row>
                    <h4 :class="HeaderDarkLightStyle">Mobile Number</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Mobile_Number }}</h4>
                          <!-- <input type="tel" name="number"  v-if="EditingUser" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$"> -->
                          <v-text-field v-if="EditingUser" label="Mobile Number" v-model="Mobile_Number" />
                  </v-col>
                  <v-col row>
                    <h4 :class="HeaderDarkLightStyle">Work Number</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Work_Number }}</h4>
                          <!-- <input type="tel" name="number"  v-if="EditingUser" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$"> -->
                          <v-text-field v-if="EditingUser" label="Work Number" v-model="Work_Number" />
                  </v-col>
                  </div>
                  <div class="mx-3 flex row xl12 lg12 md12 sm12">

                    <v-col row>
                    <h4 :class="HeaderDarkLightStyle">Position</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Position }}</h4>
                          <!-- <input type="tel" name="number"  v-if="EditingUser" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$"> -->
                          <v-text-field v-if="EditingUser" label="Position" v-model="Position" />
                  </v-col>
                    
                  <v-col row>
                    <h4 :class="HeaderDarkLightStyle">Birthday</h4>
                          <v-spacer></v-spacer>

                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Birthday }}</h4>
                          <!-- <input type="tel" name="number"  v-if="EditingUser" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$"> -->
                         <v-menu
                         v-if="EditingUser"
                         v-model="BirthDayMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px" 
                          id="EventStartDatemenu"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="Birthday"
                              label="Birth Day"
                              prepend-icon="mdi-calendar-month"
                              readonly
                              v-on="on"
                              clearable
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="Birthday" @input="BirthDayMenu = false"></v-date-picker>
                        </v-menu>
                   
                  </v-col>
                  <v-col row>
                    <h4 :class="HeaderDarkLightStyle">Fax Number</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="!EditingUser">{{ Fax_Number }}</h4>
                          <!-- <input type="tel" name="number"  v-if="EditingUser" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$"> -->
                          <v-text-field v-if="EditingUser" label="Fax Number" v-model="Fax_Number" />
                  </v-col>
                  </div>


          </v-card>
        
        </v-col>
      </div>
     <div class="mx-3 flex row xl12 lg12 md12 sm12" v-if="!UserisGuest" style="padding-bottom:20px;"> 

         <v-card width="100%" flat style="padding-bottom:20px;">

          <div class="flex row xl12 lg12 md12 sm12" style="padding-top:20px;">
            
            <v-col cols="12" lg="3" md="3" sm="12" class="grey lighten-4">
                    <h3 class="display-1">Navigation</h3>
                    <v-list  :dark="RecordisDarkMode" class="mx-1 hidden-sm-and-down">
                      <v-list-item  class="newbasicoutlined"
                          v-for="item in DocMenuItemsRender"
                          :key="item.itemObjKey"
                          @click="ActivateItem(item)">
                          <v-list-item-icon>
                          <v-icon  class="spin2">{{ item.Icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                          <v-list-item-title>{{ item.Name }}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    
                      </v-list>
                      <v-select @change="ActivityToggle()" :items="DocMenuItemsRender" v-model="activeItem" label="View" item-text="Name" class="hidden-md-and-up" />
                  </v-col>
                  <v-col cols="12" lg="9" md="9" sm="12" v-if="activeItem === 'My Team Activities'">
                    <!-- <h3 class="display-1">My Activities</h3> -->
                    <MyActivitiesMyProfile :TeamView="true"  v-if="ActivitiesReady"/>
                  </v-col>
                  <v-col cols="12" lg="9" md="9" sm="12" v-if="activeItem === 'My Activities'">
                    <!-- <h3 class="display-1">My Activities</h3> -->
                    <MyActivitiesMyProfile  v-if="ActivitiesReady"/>
                  </v-col>
                  
                  
                  <v-col cols="12" lg="9" md="9" sm="12" v-if="activeItem === 'Social Activities'">
                    <h3 class="display-1">Social Activities</h3>
                    <v-tabs centered background-color="blue" dark>
                    <v-tab v-for="tab in UserActivityTabs" :key="tab.itemObjKey">{{tab.Name}}</v-tab>
                    <v-tab-item v-for="tab in UserActivityTabs" :key="tab.itemObjKey">
                      <v-card  :class="AppisDarkModeCard"  :dark="AppisDarkMode" height="100%"  v-if="tab.Name === 'Activities' && UserRecord && UserRecord.id">
                      <SystemActivitiesFeed  class="mx-3" :AppisDarkModeCard="AppisDarkModeCard" :AppisDarkMode="AppisDarkMode" :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="20" :HasFilter="false" :UserView="true" :UserID="UserRecord.id" />
                      </v-card>
                      <v-card  :class="AppisDarkModeCard"  :dark="AppisDarkMode" height="100%" v-if="tab.Name === 'Articles' && UserRecord && UserRecord.id">
                      
                      <SiteArticlesFeed  :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="250" :Slice="20" :Filter="InthePublicFilter" :UserView="true" :UserID="UserRecord.id" />
                      </v-card>
                      <v-card :dark="AppisDarkMode" height="100%" v-if="tab.Name === 'Wall' && UserRecord && UserRecord.id">
                      <SocialSitePosts :dark="AppisDarkMode" class="mx-3" :UserRecord="UserRecord" :WallType="'SiteMember'" :ShareName="UserRecord.Full_Name" :UserorGroupID="UserRecord.id" :UserorGroupView="true" :Slice="10" />
                      </v-card>
                       <v-card :dark="AppisDarkMode" height="100%" v-if="tab.Name === 'Blogs' && UserRecord && UserRecord.id">
                      <SiteBlogsFeed  :SliderView="false" :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="250" :Slice="4" :Filter="null" :UserView="true" :UserID="UserRecord.id"/>
                      </v-card>

                    </v-tab-item>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12" lg="9" md="9" sm="12" v-if="activeItem === 'My Network'">
                    <h3 class="display-1">My Network</h3>
                    <v-tabs centered background-color="blue" dark>
                    <v-tab v-for="tab in UserNetworkTabs" :key="tab.itemObjKey">{{tab.Name}}</v-tab>
                    <v-tab-item v-for="tab in UserNetworkTabs" :key="tab.itemObjKey">
                      component :is="{{tab.Component}}" />
                    </v-tab-item>
                    </v-tabs>
                  </v-col>
                  
                  <v-col cols="12" lg="9" md="9" sm="12" v-if="activeItem === 'Settings'">
                    <h3 class="display-1">My Settings</h3>
                    <v-card>
                      <v-card-title class="blue white--text">
                        My Profile Settings
                      </v-card-title>
                      <v-card-text>
                        <v-list  :dark="RecordisDarkMode" class="mx-1 transparent">
                      <v-list-item  class="newbasicoutlined"
                          v-for="item in ComputedSecurityItems"
                          :key="item.itemObjKey"
                          @click="ActivateProfileSecurityListItem(item)">
                          <v-list-item-icon>
                          <v-icon  class="spin2">{{ item.Icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                          <v-list-item-title>{{ item.Name }}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    
                      </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="9" md="9" sm="12" v-if="activeItem === 'Friends'">
                    <h3 class="display-1">My Friends</h3>
                    <v-card>
                      <v-card-title class="blue white--text">
                        Members that are My Friends
                      </v-card-title>
                      <v-card-text>
                      <v-card :dark="AppisDarkMode" height="100%" v-if="UserRecord && UserRecord.id">
                      <FriendsList :UserView="true" :UserID="UserRecord.id" :AppisDarkMode="AppisDarkMode"/>
                      </v-card>

                      </v-card-text>
                    </v-card>
                  </v-col>
          </div>
         </v-card>
        <!-- </v-card> -->
      </div>
      
      </v-flex>
      
    </v-layout>
    <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
    <!-- </v-img> -->
         </v-card>
    <!-- </v-card> -->
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SystemActivitiesFeed from '@/components/System/SystemActivitiesFeed';
import SiteArticlesFeed from '@/components/Articles/SiteArticle/SiteArticleFeed';
import SocialSitePosts from '@/components/System/SocialSitePosts';
import SiteBlogsFeed from '@/components/Blog/SiteBlogsFeed';
import FriendsList from '@/components/System/FriendsList';
import MyActivitiesMyProfile from '@/views/Activities/MyProfile/MyActivitiesMyProfile';
import FB from 'fb'; 

export default {
  props: ['AppisDarkMode','UserisGuest'],
  middleware: 'auth',
components: {
  SystemActivitiesFeed,
  SiteArticlesFeed,
  SocialSitePosts,
  SiteBlogsFeed,
  FriendsList,
  MyActivitiesMyProfile,
  FB
},
  data() {
    return {
      ActivitiesReady: true,
      UpdateUserParallaxDialog: false,
      selectedParallax: '',
      ParallaxlocalURL: '',
      GroupCategoryTypes: [],
      currentEmail: '',
      currentPassword: '',
      LoginforUpdatePasswordDialog: false,
      LoginforUpdateDialog: false,
      UpdatingPassword: false,
      rePassword: '',
      password: '',
      MyProfileViewPermissions: 'Anybody',
      MyProfileMessagingOptions: 'Anybody',
      MyProfileFriendRequests: 'Anybody',
      UpdateEmailandPassworddialog: false,
      ProfileVisibilityDialog: false,
      NotificationSettingsDialog: false,
      GuestscanSocial: false,
      SecurityItems: [
        {Name: 'Notifications',
        Icon: 'mdi-bell-check-outline',
        Method: 'ActivateNotificationSettingsDialog'},
        {Name: 'Profile Visibility',
        Icon: 'mdi-eye-outline',IsSocial: true,
        Method: 'ActivateAccountVisiblityDialog'},
        {Name: 'Update Email and/or Password',
        Icon: 'mdi-account-key',
        Method: 'ActivateUpdateEmailorPasswordDialog'},
      ],
      activeItem: 'My Activities',
      DocMenuItems: [
        {Name: 'My Activities',
        Icon: 'mdi-history'},         
        // {Name: 'My Network',
        // Icon: 'mdi-database'},
        {Name: 'Friends',
        Icon: 'mdi-tag-faces'},
        {Name: 'Settings',
        Icon: 'mdi-cogs'},
      ],
      MyActivityUserTabs: [
        {
          Name: 'Wall',
          Component: 'SocialSitePosts'
        },
        {
          Name: 'Activities',
          Component: 'SystemActivitiesFeed'
        },
        
        {
          Name: 'Blogs',
          Component: 'SiteBlogsFeed'
        },
        {
          Name: 'Articles',
          Component: 'SiteArticlesFeed'
        },
        
       
      ],
      MyNetworkUserTabs: [

        {
          Name: 'Friends',
          Component: 'FriendsList'
        },
        
      ],
      rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
      ActivitiesArray: [],
      NotesArray: [],
      TaskFilterDate: '',
      TaskDialog: false,
      NewTask: {
            Name: '',
            description: '',
            activitytype: 'Task',
            regardingtype: 'User',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
      FavoriteQuote: '',
      Title: '',
      NewEmail: '',
      Email: '',
      Mobile_Number: '',
      Work_Number: '',
      Company: '',
      Position: '',
      CompanyPositions: [],
      Birthday: '',
      BirthDayMenu: false,
      Fax_Number: '',
      DefaultNewTask: {
            Name: '',
            description: '',
            activitytype: 'Task',
            regardingtype: 'User',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
      pickerDate: null,
      TaskStatusFilter: 'Open',
      CreatedOnmenu: false,
      DueDatemenu: false,
      FullName:  '',
      
      
      
      
      EditingUser: false,
      dialog: false,
      initialize: '',
      search: '',
      UserData: {},
      headers: [
      
        { text: 'Actions', value: 'action', sortable: false },
      ],
      FullName:  '',
      Name:  '',
      Surname:  '',
      Created_On: '',
      Business_Unit:  {id: '', Name: ''},
      Manager:  {id: '', Full_Name: ''},
      Profile_Photo:  '',
      Managerfullname: null,
      profilephotofilesnackbar: false,
      selectedprofilephotoFile: '',
      ProfilePhotoUploadProgress: 0,
      profilephotolocalURL: '',
      BusinessUnitArray: [],
      BusinessUnitfilter: {Name: ''},ManagerArray: [],
      Managerfilter: {Full_Name: ''},
      EditingUser: false,
      dialog: false,
      initialize: '',
      search: '',
      helpsheet: false,
      
      SecurityRoleIndex: -1,
      SecurityRoledialog: false,
      editedRole: {Name: '', DisplayName: '', Description: '', Builds: []},
      defaultRole: {Name: '', DisplayName: '',Description: '', Builds: []},
      RolesArray: [],
      snackbar: false,
      EditedUsersnackbar: false,
      UserEditdialog: false,
      newUserdialog: false,
      UsersArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      IsTeamActivityManager: false,
      BusinessUnitsArray: [],
      Owner: {Name: '', Surname: '', id: '', Full_Name: ''},
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      Business_Unit: {},
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ManagersToUpdate: [],
      SubordindatesArray: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [
  "System Admin"
],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      OTPDialog: false,
    }
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.GetUsersManager()
    this.GetRequestingUser()
    this.GetUsers()
    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000);
    

  },
  computed: { 
    ComputedSecurityItems(){
      return this.UserisGuest && !this.GuestscanSocial? this.SecurityItems.filter(item => {return !item.IsSocial}) : this.SecurityItems
    },
     FBEmailProvider(){
        let fbprovider = this.$store.state.user.providerData.find(obj => obj.providerId === 'password')
        return fbprovider
      },
    FacebookProvider(){
        let fbprovider = this.$store.state.user.providerData.find(obj => obj.providerId === 'facebook.com')
        return fbprovider
      },
      FacebookToken(){
        let stateuser = this.$store.state.user
        return stateuser.FacebookAccessToken
      },
    userLoggedIn () {
      return this.$store.getters.user
      },
    UserisTeamManager(){
      if(this.UserRecord.IsTeamActivityManager){
        return true
      }
    },
    DocMenuItemsRender(){
      return this.DocMenuItems.filter(item => {
        if(!this.UserisTeamManager){
          return item.Name !== 'My Team Activities'
        }
        else{
          return item
        }
      })
    },
    BirthdayObject(){
      console.log(this.Birthday)
      if(this.Birthday){
        return this.DateFormatter(this.Birthday)
      }
    
  },
    passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Password must match'
            },
    SystemFeedNewFilter(){
        return [
  "New User",
  "New Group",
  "New Blog",
  "New Articles",
  "New Friendship",
  "New Forum"
]       
      },
    UserActivityTabs(){
      return this.MyActivityUserTabs
    },
    UserNetworkTabs(){
      return this.MyNetworkUserTabs
    },
    HeaderDarkLightStyle(){
      if(this.RecordisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    ActivitiesSearched() {
      return this.ActivitiesArray.filter(Activity => {
        if(Activity.Name){return Activity.Name.toLowerCase().includes(this.search.toLowerCase())}}).filter(Activity => {if(Activity.description){return Activity.description.toLowerCase().includes(this.search.toLowerCase())}
      })
      },
      ActivitiesFilteredandSearched() {
      return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
      },
      TasksFilteredandSearched(){
          return this.ActivitiesFilteredandSearched.filter(activity => {
              return activity.activitytype === 'Task' && activity.duedate.includes(this.pickerDate) && activity.status.includes(this.TaskStatusFilter)
          })
      },
      TasksFilteredandSearchedTable(){
          return this.TasksFilteredandSearched.filter(activity => {
              return activity.duedate === this.TaskFilterDate
          })
      },
        PotentialManagers() {
      return this.UsersArray.filter(unit => {
        return !this.SubordindatesArray.includes(unit.id)
      })
    },
   
    },
  methods: {
    LinkEmailAccount(){
      this.UpdatingPassword = true
      this.UpdateEmailandPassworddialog = true
    },
    LinkEmailandPassword(){
      let email = this.NewEmail
      let password = this.password
      var credential = firebase.auth.EmailAuthProvider.credential(email, password);
      firebase
        .auth().currentUser.linkWithCredential(credential)
      .then((usercred) => {
        var user = usercred.user;
        console.log("Account linking success", user);
        db.collection('users').doc(user.uid).update({
          Email: email
        })
        vm.CancelUpdateUserPasswordorEmail()
      }).catch((error) => {
        console.log("Account linking error", error);
      });
    },
    ReAuthwithPhone (mobileprovider) {
      let vm = this
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {
            console.log(response)
        }
        });
        // E.164 format 
        const phoneNumber = mobileprovider.phoneNumber;
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                console.log(confirmationResult)
                return confirmationResult.confirm(prompt('Enter your SMS code')) && vm.LinkEmailandPassword()
                
            }).catch((error) => {
                console.log(error)
            });
    },
    CreateEmailandPasswordAuth(){
      let vm = this
      let stateuser = this.$store.state.user
      let authuser = firebase.auth().currentUser
      console.log(stateuser)
      let fbprovider = stateuser.providerData.find(obj => obj.providerId === 'facebook.com')
      console.log(fbprovider)
      let mobileprovider = stateuser.providerData.find(obj => obj.providerId === 'phone')
      console.log(mobileprovider)
      if(fbprovider){
        let provider = new firebase.auth.FacebookAuthProvider()
        authuser.reauthenticateWithPopup(provider).then(function(result) {
          // The firebase.User instance:         
          vm.LinkEmailandPassword()
          // The Facebook firebase.auth.AuthCredential containing the Facebook
          // access token:
        }, function(error) {
          // An error happened.
        });
      }
      else if(mobileprovider){
        this.ReAuthwithPhone(mobileprovider)
      }
    },
    SignIntoFacebook(){
      let FBprovider = new firebase.auth.FacebookAuthProvider();
      let vm = this
        firebase
        .auth().currentUser.reauthenticateWithPopup(FBprovider).then(function(result) {
          // The firebase.User instance:
          var user = result.user;
          // The Facebook firebase.auth.AuthCredential containing the Facebook
          // access token:
          var credential = result.credential;
          let stateuser = vm.$store.state.user
            stateuser.FacebookAccessToken = credential.accessToken
            vm.$store.commit('SetUserStateObj', stateuser)
        }, function(error) {
          // An error happened.
        });
    },
    LinkFacebookAccount(){
        let vm = this
        let FBprovider = new firebase.auth.FacebookAuthProvider();
        firebase
        .auth().currentUser.linkWithPopup(FBprovider).then((result) => {
            // Accounts successfully linked.
            var credential = result.credential;
            var user = result.user;
            // ...
            let stateuser = vm.$store.state.user
            stateuser.FacebookAccessToken = credential.accessToken
            vm.$store.commit('SetUserStateObj', stateuser) 
            console.log(user,credential)
            console.log(vm.$store.state.user)
            //vm.fooApp.setAccessToken(credential.accessToken)            
            }, function(error) {
                console.log(error)
                });
        
    },
    VerifyPhoneNumber(){
      let vm = this
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
           const functions = firebase.functions();
            const UpdateUserMobile = functions.httpsCallable('UpdateUserMobile');
            let data = {
              Mobile_Number: vm.Mobile_Number,
              uid: user.uid
            }
            UpdateUserMobile(data).then(result => {
            console.log('updated notifications settings supposedly')
            console.log(result);
            db.collection('users').doc(vm.UserRecord.id).update({
            Mobile_Number: vm.Mobile_Number
            })
            }).catch(error => {
            console.log("error",error);
            });
          // this.UpdateUser
          // firebase.auth().updateUser({
          //   phoneNumber: vm.Mobile_Number
          // })
          // .then((userRecord) => {
          //   // See the UserRecord reference doc for the contents of userRecord.
          //   console.log('Successfully updated user', userRecord.toJSON());
          //   user.reload()
          // })
        }
      });
    },
    UpdateNotificationEmailer(){
      db.collection('users').doc(this.UserRecord.id).update({
        UsersWantsEmailsOnAllNotifications: this.UsersWantsEmailsOnAllNotifications
      })
      const functions = firebase.functions();
      const UserDefinedMailedNotifications = functions.httpsCallable('UserDefinedMailedNotifications');
      let data = {
        id: this.UserRecord.id,
        boolean: this.UsersWantsEmailsOnAllNotifications
      }
      UserDefinedMailedNotifications(data).then(result => {
          console.log('updated notifications settings supposedly')
          console.log(result);
          }).catch(error => {
          console.log("error",error);
          });
      this.NotificationSettingsDialog = false
    },
    DeactivateUserParallaxDialog(){
      this.ParallaxlocalURL = ''
      this.selectedParallax = ''
      this.UpdateUserParallaxDialog = false
    },
    SaveUserParallax(){
      let vm = this
      vm.$emit('ActivateProcessing',true)
    let filename = vm.selectedParallax.name
        var storageRef = firebase.storage().ref('User/Parallax/'+vm.UserRecord.id);
        var uploadTask = storageRef.put(vm.selectedParallax);
         
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('users').doc(vm.UserRecord.id).update({
                    Parallax: url,
            })
            vm.$emit('ActivateProcessing',false)
            vm.selectedParallax = ''
            
                  vm.Logofilesnackbar = true
                  vm.UpdateUserParallaxDialog = false
         })
      },
    AddUserParallax(event) {
        this.selectedParallax = event.target.files[0]
        this.ParallaxlocalURL = URL.createObjectURL(this.selectedParallax)
      },
    UpdateUserParallax(){
      this.UpdateUserParallaxDialog = true
    },
    CancelUpdateUserPasswordorEmail(){
      this.NewEmail = this.Email
      this.password = ''
      this.rePassword = ''
      this.UpdatingPassword = false
      this.UpdateEmailandPassworddialog = false
      this.currentEmail = ''
      this.currentPassword = ''
      this.LoginforUpdateDialog = false
      this.LoginforUpdatePasswordDialog = false
    },
    LoginforUpdateUserEmail(){
      this.LoginforUpdateDialog = true
    },
    UpdateUserEmail(){
      let vm = this
      firebase.auth().signInWithEmailAndPassword(this.currentEmail,this.currentPassword).then(function(userCredential) {
        userCredential.user.updateEmail(vm.NewEmail).then(function()  {
          db.collection('users').doc(vm.UserRecord.id).update({
            Email: vm.NewEmail
          })
          db.collection('userroles').doc(vm.UserRecord.id).update({
            Email: vm.NewEmail
          })
        vm.CancelUpdateUserPasswordorEmail()
        alert('Update successful')
          }).catch(function(error) {
            console.log('error',error)
            alert('Something went wrong. We strongly recommend you sign out, then sign back in and try again')
            vm.CancelUpdateUserPasswordorEmail()
          })
       })
    },
    LoginUpdateUserPassword(){
      this.LoginforUpdatePasswordDialog = true
    },
    UpdateUserPassword(){
      // await firebase.auth().currentUser(function(user) {
      let user = firebase.auth().currentUser
      console.log(user)
      if(this.UpdatingPassword && this.password){
        let newPass = this.password
      user.updatePassword(newPass).then(function()  {
        alert('Update successful')
        this.UpdateEmailandPassworddialog = false
        this.password = ''
        this.rePassword = ''
        this.UpdatingPassword = false
      }).catch(function(error) {
        console.log('error',error)
        alert('Something went wrong. We strongly recommend you sign out, then sign back in and try again')
      })
      }
      
      // })
    },
    UpdateProfileVisibility(){
      db.collection('users').doc(this.UserRecord.id).update({
        MyProfileViewPermissions: this.MyProfileViewPermissions,
        MyProfileMessagingOptions: this.MyProfileMessagingOptions,
        MyProfileFriendRequests: this.MyProfileFriendRequests
      })
      this.ProfileVisibilityDialog = false
    },
    ActivateProfileSecurityListItem(item){
      this[item.Method]()
    },
    ActivateUpdateEmailorPasswordDialog(){
      this.UpdateEmailandPassworddialog = true
    },
    ActivateAccountVisiblityDialog(){
      this.ProfileVisibilityDialog = true
    },
    ActivateNotificationSettingsDialog(){
      this.NotificationSettingsDialog = true
    },
    saveMyProfile(){
      console.log(this.Birthday)
      console.log(this.BirthdayObject)
      if(this.Mobile_Number){
        this.VerifyPhoneNumber()
        // db.collection('users').doc(this.UserRecord.id).update({
        // Mobile_Number: this.Mobile_Number
        // })
      }
      if(this.BirthdayObject){
        db.collection('users').doc(this.UserRecord.id).update({
        Birthday: this.BirthdayObject
        })
      }
      if(this.Position){
        db.collection('users').doc(this.UserRecord.id).update({
        Position: this.Position
        })
      }
      if(this.Work_Number){
        db.collection('users').doc(this.UserRecord.id).update({
        Work_Number: this.Work_Number
        })
      }
      if(this.Fax_Number){
        db.collection('users').doc(this.UserRecord.id).update({
        Fax_Number: this.Fax_Number
        })
      }
      if(this.Name){
        db.collection('users').doc(this.UserRecord.id).update({
        Name: this.Name
        })
      }
      if(this.Surname){
        db.collection('users').doc(this.UserRecord.id).update({
        Surname: this.Surname
        })
      }
      if(this.Name && this.Surname){
        db.collection('users').doc(this.UserRecord.id).update({
        Full_Name: this.Name+' '+this.Surname
        })
      }
      if(this.Title){
        db.collection('users').doc(this.UserRecord.id).update({
        Title: this.Title
        })
      }
      if(this.FavoriteQuote){
        db.collection('users').doc(this.UserRecord.id).update({
        FavoriteQuote: this.FavoriteQuote
        })
      }
      
      this.EditingUser = false
    },
    ActivateItem(item){
  this.activeItem = item.Name
  console.log(this.activeItem)
},
    ActivityToggle(){
      this.ActivitiesReady = false
      setTimeout(() => {
        this.ActivitiesReady = true
      }, 100);
    },
    ActivateItem(item){
  this.activeItem = item.Name
  console.log(this.activeItem)
},
    DateFormatter(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
    },
 

    GetActivities( regardingquery) {

      regardingquery.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },
    
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
      }
      
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
  
       onprofilephotoUpload() {
         let vm = this
        var storageRef = firebase.storage().ref('User/' + 'profilephoto/'+vm.UserRecord.id);
        var uploadTask = storageRef.put(vm.selectedprofilephotoFile);
        vm.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        uploadTask.on('state_changed', function(snapshot){
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        vm.ProfilePhotoUploadProgress = Number(progress.toFixed(2))
        barelmnt.setAttribute('value',vm.ProfilePhotoUploadProgress)
        var textnode = document.createTextNode(vm.ProfilePhotoUploadProgress+"% complete for "+vm.selectedprofilephotoFile.name);
        if(bartextelmnt.childNodes[0]){
        bartextelmnt.removeChild(bartextelmnt.childNodes[0])
        }         // Create a text node
        bartextelmnt.appendChild(textnode);
        if(vm.ProfilePhotoUploadProgress === 100){
            setTimeout(() => {
              vm.$emit('ActivateUploadingSingleFileNotification',false)
              //vm.UploadingMultipleFileUpload1File = false
              vm.ProfilePhotoUploadProgress = 0
              barelmnt.setAttribute('value',0)
              bartextelmnt.removeChild(bartextelmnt.childNodes[0])
              var finalnode = document.createTextNode('0% complete')
              bartextelmnt.appendChild(finalnode)
              }, 300);
            }
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          }, function(error) {
            // Handle unsuccessful uploads
          }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              console.log('File available at', downloadURL);
              db.collection('users').doc(vm.UserRecord.id).update({
                    Profile_Photo: downloadURL
            })
            vm.closephotoUpload()
            });
          });
        }, 300);


      },
      onprofilephotoFileselected(event) {
        this.selectedprofilephotoFile = event.target.files[0]
        this.profilephotolocalURL = URL.createObjectURL(this.selectedprofilephotoFile)
      },

      closephotoUpload(){
        this.selectedprofilephotoFile = ''
        this.profilephotolocalURL = ''
      },




      GetBusinessUnitsBusinessUnit() {
      db.collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
      },

      GetUsersManager() {
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ManagerArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
      },
    

      
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              if(vm.UserRecord !== 'undefined'){
                vm.GetUser(vm.UserRecord)
                
              }
              
              
              
              
              
            })
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      
      
      
        GetUser(user) {
          let vm = this
          if(typeof user !== 'undefined'){
            db.collection('users').doc(user.id).onSnapshot(snapshot => {
            var user = snapshot.data()
                  let ActivitiesCollectionReference = db.collection('activities')
                  let ActivitiesstandardQuery = ActivitiesCollectionReference.where('regardingtype', '==', 'User').where('regardingrecord.id', '==', vm.UserRecord.id)
                  let ActivityRegardingQuery =  ActivitiesstandardQuery;
                  vm.GetActivities(ActivityRegardingQuery)
            this.UserData = user
              if(typeof user.Subordinatearrayquery === 'undefined' || user.Subordinatearrayquery.length === 0){
                        let tempSubordindatesArray = []
                        tempSubordindatesArray.push(this.UserRecord.id)
                        let tempSubordinateArrayDBrules = {}
                        tempSubordinateArrayDBrules[this.UserRecord.id] = true
                        db.collection('users').doc(this.UserRecord.id).update({
                        SubordinateArrayDBrules: tempSubordinateArrayDBrules,
                        Subordinatearrayquery: tempSubordindatesArray
                          })
                      }
              else if(typeof user.SubordindatesArray !== 'undefined'){
                this.SubordindatesArray = user.SubordindatesArray
              }
              if(typeof user.UsersWantsEmailsOnAllNotifications){
                this.UsersWantsEmailsOnAllNotifications = user.UsersWantsEmailsOnAllNotifications
              }
              if(typeof user.MyProfileViewPermissions !== 'undefined'){
                this.MyProfileViewPermissions = user.MyProfileViewPermissions
              }
              if(typeof user.MyProfileMessagingOptions !== 'undefined'){
                this.MyProfileMessagingOptions = user.MyProfileMessagingOptions
              }
              if(typeof user.MyProfileFriendRequests !== 'undefined'){
                this.MyProfileFriendRequests = user.MyProfileFriendRequests
              }
              if(typeof user.IsTeamActivityManager !== 'undefined'){
                this.IsTeamActivityManager = user.IsTeamActivityManager
              }
              if(typeof user.assignedroles !== 'undefined'){
                this.AssignedRoles = user.assignedroles
              }
              if(typeof user.Full_Name !== 'undefined'){
                this.FullName = user.Full_Name
              }
              if(typeof user.Name !== 'undefined'){
                this.Name = user.Name
              }
              if(typeof user.Title !== 'undefined'){
                this.Title = user.Title
              }
              if(typeof user.FavoriteQuote !== 'undefined'){
                this.FavoriteQuote = user.FavoriteQuote
              }
              if(typeof user.Surname !== 'undefined'){
                this.Surname = user.Surname
              }
              if(typeof user.Email !== 'undefined'){
                this.Email = user.Email
                this.NewEmail = user.Email
              }
              if(typeof user.Mobile_Number !== 'undefined'){
                this.Mobile_Number = user.Mobile_Number
              }
              if(typeof user.Work_Number !== 'undefined'){
                 this.Work_Number = user.Work_Number
              }
              if(typeof user.Fax_Number !== 'undefined'){
                 this.Fax_Number = user.Fax_Number
              }
              if(typeof user.Birthday !== 'undefined'){
                 this.Birthday = format(user.Birthday.toDate(),'yyyy-MM-dd')
                 console.log(this.Birthday)
              }
              if(typeof user.Position !== 'undefined'){
                 this.Position = user.Position
              }
              
              if(typeof user.Manager !== 'undefined'){
                this.Manager = this.ManagerArray.find(Manager => Manager.id === user.Managerid)
              }
              if(typeof user.Profile_Photo !== 'undefined'){
                this.Profile_Photo = user.Profile_Photo
              }
            if(typeof user.notesarray !== 'undefined'){
            this.NotesArray = user.notesarray
              }
            if(typeof user.Business_Unit !== 'undefined'){
              this.Business_Unit = user.Business_Unit
            }
            if(typeof user.Created_On){
              this.Created_On = this.TimestampFormatterFULLSTRING(user.Created_On.toDate())
            }
            this.FullName = user.Full_Name
            this.editedItem.Full_Name = user.Full_Name
            })
          }
        },
        
      
      
    TimestampFormatterFULLSTRING(d){
       console.log(typeof d,d)
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var textmonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var day = days[d.getDay()]
        console.log(day)
        var date = d.getDate();
        var month = textmonths[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = day+' '+date+' '+month+' '+year;
        console.log(finaldate)
        return finaldate
      },

      
       

  }
}


</script>

<style>
/* .borderthicknobottom{
  border-top: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
}
.borderthicknotop{
  border-bottom: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
} */
.moving-clouds {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.moving-clouds2 {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c2 80s linear infinite;


}
@keyframes c2 {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
</style>
          
            
        


    