
<template>
 <v-hover v-slot="{ hover }">
    <v-card :class="tab.BoxTransparency+' pa-2'" :dark="tab.DarkForm"
                            v-if="tab.Name === 'Web Form'" :height="!tab.HeightRestrictions? '100%' : ''" :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding-right:20px;padding-left:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'" >
                                 <v-expand-transition>
                                    <div
                                        v-if="hover && tab.FormSubmitted"
                                        class="d-flex transition-fast-in-fast-out darken-2 display-2 red--text"
                                        style="height: 100%;position: absolute;background-color: rgba(50, 59, 68, 0.75);align-items: center;justify-content: center;witdh:100%;z-index:2"
                                    >
                                    <p style="padding:20px;">
                                        Thank you!
                                        Your submission has processed...
                                    </p>
                                    </div>
                                    <div
                                        v-if="tab.FormProcessing && !tab.FormSubmitted && !tab.FromDatabase"
                                        :class="'d-flex transition-fast-in-fast-out v-card--reveal mediumoverline white--text'"
                                        :style="'height: 100%;background-color:'+tab.BGColor.hexa+';'"
                                    >
                                        <v-progress-circular size="130"
                                        :width="5"
                                        :color="tab.BGColor.hexa"
                                        indeterminate
                                        >Processing</v-progress-circular>
                                    </div>
                                    </v-expand-transition><v-expand-transition>
                                    </v-expand-transition>
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                    <v-card-title v-if="!tab.HideFormName" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                    <strong>{{tab.FormName}}</strong></v-card-title>
                                    <v-card-text>
                                        <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <span v-for="field in tab.FormFields" :key="field.itemObjKey"><span v-if="field.Type === 'Option Set'">
                                            <v-select :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue" :rules="[rules.required]" v-model="field.Response" :items="field.Options" :label="field.Name" item-text="Name"></v-select></span><span v-if="field.Type === 'Single Line Text'">
                                            <v-text-field  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"  :rules="[rules.required]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'">
                              <v-text-field  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"  :rules="[rules.required,rules.email]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Telephone Number'">
                              <v-text-field  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"  :rules="[rules.required,rules.telnr]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                                                <v-list dense class="outline">
                                                    <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title>
                                                    <v-list-item v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                                        <v-text-field :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue" v-model="breakdownfield.Response" :label="breakdownfield.DisplayName"/>
                                                    </v-list-item>
                                                </v-list>
                                                </span><span v-if="field.Type === 'Number Field'">
                                            <v-text-field  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"  :rules="[rules.required]" v-model="field.Response" type="number" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text'">
                                            <v-textarea  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"  :rules="[rules.required]" v-model="field.Response"  :label='field.Name'></v-textarea></span><span v-if="field.Type === 'Lookup'">
                                            <v-autocomplete  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue" :items="field.Array" :item-text="field.LookupFieldName" :rules="[rules.required]" v-model="field.Response" return-object :label='field.Name'></v-autocomplete></span><span v-if="field.Type === 'Date'">
                                            <v-menu 
                                            v-model="field.DateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="200px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                :label="field.Name"
                                                prepend-icon="mdi-calendar-month"
                                                readonly  :rules="[rules.required]"
                                                v-on="on"
                                                v-model="field.Response"
                                                ></v-text-field>
                                                </template>
                                            <v-date-picker :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"  v-model="field.Response"
                                                    @input="field.DateMenu = false"></v-date-picker>
                                            </v-menu>
                                            <v-menu v-if="field.HasTimeInput"
                                                v-model="field.TimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px" 
                                                id="fieldTimeMenu"
                                                
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-model="field.TimeResponse"
                                                    :label="field.Name+' Time'"
                                                    prepend-icon="mdi-calendar-month"
                                                    readonly
                                                    v-on="on"
                                                    :id="field.Name+'Time'"
                                                    clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-model="field.TimeResponse"
                                                    @input="field.TimeMenu = false"
                                                    type="month" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue" 
                                                    width="290"
                                                    class="ml-4"
                                                ></v-time-picker>
                                                </v-menu></span><span v-if="field.Type === 'Single File Upload'">
                                            <input
                                    
                                        type="file">
                                    <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> --></span></span>
                                        
                                        <!-- <v-checkbox
                                        v-model="checkbox"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        label="Do you agree?"
                                        required
                                        ></v-checkbox> -->
                                        
                                    </v-form>
                                    </v-card-text>
                                    <v-card-actions v-if="!userLoggedIn" id="recaptcha">
                                 </v-card-actions>
                                <v-card-actions :class="tab.ButtonAlignment">
                                        <v-btn
                                        :disabled="tab.FormProcessing || tab.FormSubmitted"
                                        color="links"
                                        class="mr-4"
                                        @click="ValidateForm(tab)"
                                        >
                                        Submit
                                        </v-btn>
                                        </v-card-actions>
                                <!-- <v-card-text v-if="tab.HasImage"> -->
                                    <!-- <v-layout :class="tab.Justify" row> -->
                                        <v-card-text>
                                            <v-layout :class="tab.Justify" row>
                                        
                                    <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && tab.IMG"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                            </v-layout>
                                        </v-card-text>
                                   <!-- </v-layout> -->
                                 <!-- </v-card-text> -->
                            
                            <v-card-actions v-if="tab.HasActionButton" >
                                    <v-btn :dark="tab.ActionBtnDark" :style="'background-color:'+tab.ButonColor.hex">
                                        {{tab.ActionButtonName}}
                                    </v-btn>
                                </v-card-actions>
                                </v-card>
    </v-hover>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','PageName','rowindex'],
    components: {

        },
    data() {
    return {      
      UserRecord: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },

    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        DateFormatter(date){
    if(date){
      let removeT = date.split('T').join(' ')
      let removeFWDslash = date.split('/').join('-')
    let yearstring = removeFWDslash.split('-')[0]
      let monthstring = removeFWDslash.split('-')[1]
      let daystring = removeFWDslash.split('-')[2]
      let yearnumber = Number(yearstring)
      let monthnumber = Number(monthstring)
      let daynumber = Number(daystring)
      return new Date(yearnumber, monthnumber-1, daynumber)
      }
    else{
      return null
    }
  },
        ActivateOptionSetDialog(field,ContentBlockObject){
            this.$emit('ActivateOptionSetDialog',field,ContentBlockObject)
        },
     
       OptionNameSelect(option){
           this.$emit('ActivateOptionSetDialog',option)
       },
       VerifyCaptcha(tab){
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.SubmitForm(tab)
                // ...
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
            })
            window.recaptchaVerifier.render()
        
       },
       ValidateForm(tab){
           if(this.$refs.form.validate()){
               if(this.userLoggedIn){
                this.SubmitForm(tab)   
               }
               else{
                   this.VerifyCaptcha(tab)
               }
               
           }
           else{
               alert('Please complete all Form Fields')
           }
       },
        SubmitForm(tab){
            console.log(tab)
            tab.FormProcessing = true
            if(tab.FromDatabase){
                this.$emit('ProcessingData',true)
            }
            if(tab.FormEmailRecipient){
                let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry - `+this.PageName+`</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`
                 } 
            })
            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'notifications@gpcombrinkvehiclesandfinance.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New Enquiry - '+this.PageName,
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
            }
            console.log('tab.EntityForm',tab.EntityForm)
            if(tab.EntityForm){
                let NewRecord = {
                    Created_On: new Date()
                }
                let length = tab.FormFields.length
                tab.FormFields.map((field,i) => {
                NewRecord[field.Name.split(' ').join('')] = field.Response
                if(field.Type === 'Lookup'){
                    NewRecord[field.Name.split(' ').join('')+'id'] = field.Response.id
                }
                NewRecord.Status = tab.StatusDefault
                NewRecord.Status_Reason = tab.Status_ReasonDefault
                if(field.Type === 'Date'){
                    let date = this.DateFormatter(field.Response)
                    NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: date.getTime()}    
                    }
                if(i-1+2 === length){
                  const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(!NewRecord.Created_By && this.userLoggedIn){
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Created_Byid = this.userLoggedIn.id
                      
                  }
                  if(tab.DefaultOwner){
                    NewRecord.Owner = tab.DefaultOwner
                    NewRecord.Ownerid = tab.DefaultOwner.id
                    NewRecord.NewNotification = {
                    itemidprop: 'recordid',
                    Type: 'Web Form Submission',
                    Header: 'Form Submission for '+tab.FormName,
                    Content: 'New Submission for a '+tab.SingleName,
                    Owner: NewRecord.Owner,
                    Ownerid: NewRecord.Ownerid,
                    Read: false,
                    Path: '/'+tab.SingleName.split(' ').join(''),
                    Message: 'New Submission for a '+tab.SingleName,
                    }
                    if(this.userLoggedIn){
                        NewRecord.NewNotification.CreatorFullName = this.userLoggedIn.Full_Name
                        NewRecord.NewNotification.CreatorID = this.userLoggedIn.id
                        NewRecord.NewNotification.Content = NewRecord.NewNotification.Content+' by '+NewRecord.NewNotification.CreatorFullName
                        NewRecord.NewNotification.Message = NewRecord.NewNotification.Message+' by '+NewRecord.NewNotification.CreatorFullName
                    }
                  }
                  if(this.userLoggedIn){
                      NewRecord.WebSubmission = false
                  }
                  else{
                      NewRecord.WebSubmission = true
                  }
                  
                  let obj = {
                    collection: tab.RelatedEntity.id,
                    data: NewRecord
                  }    
                  console.log(obj)        
                  if(!tab.ExistingRecord){
                      SubmitFormData(obj).then(result => {
                        console.log(result)
                        if(tab.FromDatabase){
                            this.$emit('ConfirmSubmission',tab,obj,result.data)
                            this.$emit('ProcessingData',false)
                        }
                        else{
                        tab.FormSubmitted = true  
                        }
                        
                    }) 
                  }  
                  else{
                    if(tab.FromDatabase){
                        this.$emit('ConfirmSubmission',tab,obj,tab.ExistingRecord.id)
                        this.$emit('ProcessingData',false)
                    }
                    else{
                    tab.FormSubmitted = true  
                    }
                  }
            }
                })
            }
        },

     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>
    