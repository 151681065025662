
<template>
<v-card :flat="NoticeSelect" :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent stickytopconscious' : 'transparent'" width="100%" height="100%">
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            src="@/assets/RABaseBG.jpeg"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">GP Combrink Vehicle Sales & Finance</h1>
                <h4 class="headline">Blogs</h4>
              </v-col>
            </v-row>
            </v-parallax> 
    <v-layout class="justify-end" v-if="!NoticeSelect">
    <v-btn outlined color="accent"  :to="$route.name !== 'GroupSingle' ? '/NewBlog' : '/Group/NewBlog/'+$route.params.id" target="_blank" v-if="UserModerator || CanCreate"><v-icon>mdi-plus</v-icon>New Blog</v-btn>
    </v-layout>
    
<v-list v-if="!NoticeSelect">
     <v-list-item  v-for="article in groupblogs" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>

                
                <v-card-text v-html="article.description">
                </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="$route.name !== 'GroupSingle' ? '/Blog/'+article.id : '/Group-Blog/'+$route.params.id+'/Blog/'+article.id">Read More</v-btn>
                </v-card-actions>
                <v-card-actions style="font-size:10px">
                  {{article.createdon.toDate()}}
                </v-card-actions>
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
        <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="groupblogs" item-text="title" v-model="selectedSocialItem" return-object label="Blog"/>
    </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','CanCreate','NoticeSelect'],
    components: {
        //FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
            InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
            groupblogs: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserModerator(){
        if(this.userLoggedIn.ModerationPermissions){
          let match = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Blogs')
          if(match){
            return true
          }
          else{
            return this.userIsAdmin
          }
        }
        else{
          return this.userIsAdmin
        }
      },
        RelatedBlogs () {
            return this.groupblogs.filter(blog => {
              if(this.search){
                return blog.title.toLowerCase().includes(this.search.toLowerCase()) && blog.Groupid === this.groupid && blog.PublishStatus === 'Published' || blog.content.toLowerCase().includes(this.search.toLowerCase()) && blog.Groupid === this.groupid && blog.PublishStatus === 'Published' || blog.content.toLowerCase().includes(this.search.toLowerCase()) && blog.authorid === this.userid  && blog.Groupid === this.Groupid && blog.PublishStatus === 'Draft'
              }
              else{
                return blog
                }
            })
        },

        BlogExcerpt () {
          return this.RelatedBlogs.filter(blog => {
            return blog.content === this.blog.content
          })
        }
    },

    created() {
      if(this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew'){
        this.InternalLinkType = 'Blog'
        this.SharedCollection = 'siteblogs'
        this.LinkComponent = 'SiteBlogShared'
        this.getGroupBlogs(db.collection('siteblogs'))
      }
      else{
        this.InternalLinkType = 'Group-Blog/'+this.$route.params.id+'/Blog'
        this.SharedCollection = 'groupblogs'
        this.LinkComponent = 'GroupBlogShared'
        this.getGroupBlogs(db.collection('groupblogs').where('groupid','==',this.$route.params.id))
      }
   
        console.log(this.userid)
},
    methods: {
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
         getGroupBlogs(col) {
           col.where('PublishStatus','==','Published (Internal)').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupblogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }  
            })
          })
          col.where('PublishStatus','==','Published (Public)').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupblogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }  
            })
          })
          col.where('PublishStatus','==','Draft').where('moderatorrolesarrayQuery','array-contains',this.userLoggedIn.id).onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupblogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('groupblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

    


    