
<template>
    
             <v-card class="transparent footermargin" flat>
              
               <div v-if="UserRecord">
              <div  v-if="SliderView">
                <v-card flat v-if="RelatedArticles.length === 0">
                  <v-card-subtitle>
                    There are no Articles for this item at this stage
                  </v-card-subtitle>
                </v-card>
                  <v-carousel cycle v-model="model" height="400"  v-if="RelatedArticles.length !== 0">
                      <v-carousel-item
                        v-for="article in RelatedArticles.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          style="background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="'/Article/' + article.id" color="links black--text" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedArticles" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text style="font-size: 10px" class="white--text overline mx-3" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="'/Article/'+article.id">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedArticles.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
              
               <div v-if="!UserRecord">
                  <div  v-if="SliderView">
                    <v-card flat v-if="RelatedArticles.length === 0">
                  <v-card-subtitle>
                    There are no Articles for this item at this stage
                  </v-card-subtitle>
                </v-card>
                  <v-carousel v-model="model" height="400" cycle  v-if="RelatedArticles.length > 0">
                      <v-carousel-item
                        v-for="article in RelatedArticles.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="'/Article/' + article.id" color="links black--text" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedArticles" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.content.substr(0,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="'/Article/'+article.title.split(' ').join('-')">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedArticles.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','Origin','clientDB','Filter','Excerpt','Slice','UserView','UserID','SliderView','TagFilter','TagsID'],
    components: {
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
            CollectionRef: db.collection('sitearticles'),
            grouparticles: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
        RelatedArticles () {
            return this.grouparticles.map(article => {
              if(article.PublishDate){
               article.pubdate = this.TimestampFormatterSTRING(article.PublishDate.toDate()) 
              }
              else if(article.pubDate){
                article.pubdate = this.TimestampFormatterSTRING(article.pubDate.toDate()) 
              }
            return article
          }).sort((a, b) => {
            // console.log('sorting again')
            // console.log(a.pubdate)
            var key1 = this.DateFormatter(b.pubdate);
            var key2 = this.DateFormatter(a.pubdate);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
            // .filter(article => {
            //     let articlegroup = this.groups.find(obj => obj.id === article.Groupid)
            //     article.category = articlegroup.GroupCategory
            //     console.log(article.grouptype)
            //     let articlematch = this.Filter.find(obj => obj.Name === article.grouptype)
            //     if(articlematch){
            //         return article
            //     }
            // })
        },

        ArticleExcerpt () {
          return this.RelatedArticles.filter(article => {
            return article.content === this.article.content
          })
        }
    },

    created() {
      console.log(this.UserRecord)
      if(this.UserView){
        console.log('is user view',this.UserID)
        if(this.UserID === this.UserRecord.id){
          this.CollectionRef = this.CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)'])
          this.getGroupArticles()
        }
       
        else{
          this.CollectionRef = this.CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)'])
          this.getGroupArticles()
        }
        
        
      }
      else{
        if(this.UserRecord){
          if(this.TagFilter){
          
          this.CollectionRef = this.CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('DataTagsid','array-contains',this.TagsID)
          this.getGroupArticles()
        }
        else{
          this.CollectionRef = this.CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)'])
          this.getGroupArticles()
        }
          
        }
        else{
          if(this.TagFilter){
          
          this.CollectionRef = db.collection('sitearticleswiki').where('PublishStatus','==','Published (Public)').where('DataTagsid','array-contains',this.TagsID)
          this.getGroupArticles()
        }
        else{
          console.log('calling public')
          this.CollectionRef = db.collection('sitearticleswiki').where('PublishStatus','==','Published (Public)')
        this.getGroupArticles()
        }
          
        }
        
      }
        console.log(this.UserID)
},
    methods: {
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        // console.log(finaldate)
        return finaldate
      },
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        // console.log(new Date(yearnumber, monthnumber-1, daynumber))
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
         getGroupArticles() {         
           this.CollectionRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.grouparticles.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const article = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('sitearticles').add(article).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>
    
    

    