    <template>
  <nav>    
  <v-dialog v-model="InviteUserDialog" max-width="500">
      <v-card>
        <v-card-title>
          Invite User
        </v-card-title>
        <v-card-text>
          <!-- <v-switch v-model="IsGuestUser" readonly label="Guest User" /> -->
          <v-text-field v-model="InvitingUserEmail" label="Email" :rules="[rules.required,rules.email]" />
          <!-- <v-select v-model="InviteUserBU" :items="GuestBusinessUnitArray" readonly label="Business Unit" :rules="[rules.required]" item-text="Name" return-object/> -->
          <!-- <v-select v-model="ModulePath" readonly :items="Modules" label="Module" :rules="[rules.required]" item-text="Name" return-object/> -->
          <!-- <div contenteditable id="previewsendemail" class="postinputbox" v-html="PresetEmailBody"/> -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelInvitingUserEmail()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="SendInvitetoUser()">Invite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-app-bar :dark="true" app :style="{backgroundImage:'linear-gradient(338deg, rgba(23,72,105,0.8) 0%, rgba(0,0,0,0.9) 100%),url(' + require('@/assets/ClientNavBG.jpeg') + ')' ,backgroundSize: 'cover',backgroundPosition: 'center center'}" color="#468FBE">
       <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>

      <v-toolbar-title class="mediumoverline white--text hidden-sm-and-down">
        <span>GP Combrink Vehicle Sales & Finance</span><span class="font-weight-light"> App</span>
      </v-toolbar-title>
      <v-toolbar-title v-if="!$vuetify.breakpoint.xs" class="mediumoverline white--text hidden-md-and-up">
        <span>GP Combrink V&F</span><span class="font-weight-light"> App</span>
      </v-toolbar-title>
      <v-layout v-if="UserRecord && UserRecord.id">
      
        <!-- <div id="notificationbar"> -->
          <v-list dark class="transparent">
                  <v-list-item>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                          <template v-slot:activator="{ on }">
                            <v-badge color="pop" overlap
                              dark>

                              <v-icon
                              dark
                              v-on="on">
                              mdi-bell-check
                            </v-icon>

                            <span slot="badge"> {{UnreadNotifications.length}} </span>
                            </v-badge>
                          </template>

                          <v-tabs dark>
                                <v-tab>Unread</v-tab>
                                <v-tab-item>
                                <v-list light v-if="UnreadNotifications.length>0">
                                  <h3 class="viewingnotificationheader">New Notifications</h3><br>
                                  <v-list-item v-for="notification in UnreadNotifications" :key="notification.itemObjKey" :class="{newbasicoutlined: !notification.Read}" @click="NotificationRead(notification)">
                                      <v-list-item-content class="notificationheader">
                                      {{notification.Header}}
                                      <p class="notificationcontent">{{notification.Message}}<span class="tooltiptext"><h4>{{notification.CreatorFullName}}</h4>{{notification.Content.substr(0, 50)}}</span></p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-list class="viewingnotification" light v-if="UnreadNotifications.length===0">
                                  <h3 class="viewingnotificationheader">New Notifications</h3><br>
                                  <v-list-item>
                                    <v-list-item-content class="notificationheader">
                                      There are no new notifications<br>
                                      <p class="notificationcontent">Click on the "Read" tab to see Read notifications</p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                </v-tab-item>
                                <v-tab>Read</v-tab>
                                <v-tab-item>
                                <v-list class="viewingnotification" light v-if="ReadNotifications.length>0">
                                  <h3 class="viewingnotificationheader">Read Notifications</h3><br>
                                  <v-list-item v-for="notification in ReadNotifications" :key="notification.itemObjKey" :class="{newbasicoutlined: notification.Read}">
                                    <v-list-item-content class="notificationheader">
                                      {{notification.Header}}
                                      <p class="notificationcontent">{{notification.Message}}<span class="tooltiptext"><h4>{{notification.CreatorFullName}}</h4>{{notification.Content.substr(0, 50)}}</span></p>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                      <v-btn small @click="MarkasUnread(notification)">Mark Unread</v-btn><v-btn small @click="ViewItem(notification)">View</v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-list class="viewingnotification" light v-if="ReadNotifications.length===0">
                                  <h3 class="viewingnotificationheader">Read Notifications</h3><br>
                                  <v-list-item>
                                    <v-list-item-content class="notificationheader">
                                      There are no Read notifications<br>
                                      <p class="notificationcontent">Click on the "Unread" tab to see New notifications</p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                </v-tab-item>
                              </v-tabs>

                            </v-menu>
                       
                     <v-icon v-if="!CurrentisFavorite" class="red--text mx-2" @click="AddPageAsFavorite()">
                      mdi-star-outline
                    </v-icon>
                    <v-icon v-if="CurrentisFavorite" class="red--text mx-2" @click="AddPageAsFavorite()">
                      mdi-star
                    </v-icon>

                    
                    <v-menu
                            bottom
                            right
                            offset-y
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on }">
                              <v-icon   class="orange--text mx-2" v-on="on">
                                mdi-arrow-down-drop-circle-outline
                              </v-icon>
                              </template>
                              <v-tabs dark>
                                  <v-tab>Random</v-tab>
                                  <v-tab-item>
                                    <v-list class="overline" dense max-width="400" min-width="350" 
                                    :style="{ backgroundImage: 'url(' + require('@/assets/RASolidA-Light.jpeg') + ')' ,backgroundSize: 'cover'}">
                                    <v-list-item-title>
                                      My Favorites
                                    </v-list-item-title>
                                    <v-divider></v-divider>
                                      <v-list-item v-for="fav in UserRecord.FavoriteLinks" :key="fav.itemObjKey" :href="fav.Path">
                                        {{fav.DisplayName}}
                                      </v-list-item>
                                    </v-list>
                                  </v-tab-item>
                                  <v-tab>Organized</v-tab>
                                  <v-tab-item>
                                    <v-list class="overline" dense max-width="400" min-width="350" 
                              :style="{ backgroundImage: 'url(' + require('@/assets/RASolidA-Light.jpeg') + ')' ,backgroundSize: 'cover'}"
                              >
                                <v-list-item-title>
                                  My Favorites
                                </v-list-item-title>
                                <v-divider></v-divider>
                               
                                <v-list-group
                                  :prepend-icon="item.Icon"
                                  v-for="item in UserFavoriteLinks" :key="item.itemObjKey"
                                >
                                  <template v-slot:activator>
                                    <v-list-item-title>{{item.Name}}</v-list-item-title>
                                  </template>
                                  <div v-if="item.Items">
                                    <!-- :prepend-icon="sub.icon" -->
                                  <v-list-group
                                    :value="true"
                                    no-action
                                    sub-group
                                    v-for="sub in item.Items" :key="sub.itemObjKey"
                                  >
                                    <template v-slot:activator>
                                      <v-list-item-content>
                                        <v-list-item-title>{{sub.Name}}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>

                                    <v-list-item
                                      v-for="fav in sub.Links"
                                      :key="fav.itemObjKey"
                                      :href="fav.Path"
                                    >
                                      <v-list-item-title>
                                        {{fav.DisplayName}}
                                      </v-list-item-title>

                                      <v-list-item-icon>
                                        <v-icon v-text="fav.icon"></v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </v-list-group>
                                  </div>
                                  <div v-if="item.Links">
                                    <v-list-item
                                      v-for="fav in item.Links"
                                      :key="fav.itemObjKey"
                                      :href="fav.Path"
                                    >
                                      <v-list-item-title>
                                        {{fav.DisplayName}}
                                      </v-list-item-title>

                                      <v-list-item-icon>
                                        <v-icon v-text="fav.icon"></v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </div>
                                </v-list-group>
                               
                              </v-list>
                                  </v-tab-item>
                              </v-tabs>
                              
                            
                    </v-menu>
</v-list-item>



                  </v-list>          
          <!-- </div> -->
      <v-spacer></v-spacer>

      <v-layout class="justify-end">
        <v-icon @click="ToggleAppDarkMode()">mdi-invert-colors</v-icon>
        <v-list dark class="transparent">
          <v-list-item>
            <v-menu
                bottom
                left
                offset-y
                :close-on-content-click="false"
                >
                <template v-slot:activator="{ on }">
                <v-avatar v-on="on">
                  <img v-if="UserPicture "
                    :src="UserPicture "   
                    style="object-fit: cover;"           
                  >
                  <img v-if="!UserPicture "
                    src="@/assets/BlankProfilePic.png"   
                    style="object-fit: cover;"           
                  >
                </v-avatar>
                </template>
                <v-list max-width="300" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
                  <v-list-item>
                    <!-- <v-list-item-content > -->
                    <v-list-item-avatar class="blue" tile size="80">
                      <img v-if="UserPicture "
                      :src="UserPicture"
                      style="object-fit: cover;"              
                      >
                      <img v-if="!UserPicture "
                        src="@/assets/BlankProfilePic.png"  
                        style="object-fit: cover;"            
                      >                      
                      </v-list-item-avatar>
                    <!-- </v-list-item-content> -->
                    <v-list-item-content >
                      <v-list-item-title>{{UserRecord.Full_Name}}</v-list-item-title>                    
                      <v-list-item-subtitle>
                        <router-link  :to="'/MyProfile'">View My Profile</router-link>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{UserRecord.Email}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Online: Online Now
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
         
                  <v-list-item @click="ActivateTicketLog()">
                  <v-list-item-avatar>
                  <v-icon>mdi-ticket-confirmation</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                      Log Ticket 
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="AcceptInviteUserDialog()" v-if="userLoggedIn.CanInviteUsers || userIsAdmin">
                  <v-list-item-avatar>
                  <v-icon>mdi-share-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                      Invite User 
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="ConfirmlogoutFromFirebase">
                  <v-list-item-avatar>
                  <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                      Sign Out 
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-list-item-avatar tile size="40">
                      <img 
                        src="@/assets/RAFullLogo_tiny.png"              
                      >                      
                      </v-list-item-avatar>
          </v-list-item>
        </v-list>

      </v-layout>
      </v-layout>
    </v-app-bar >

    <v-navigation-drawer class="dbnavlist" :dark="true" :style="{backgroundImage:'linear-gradient(338deg, rgba(23,72,105,0.99) 0%, rgba(193,37,23,0.9) 50%, rgba(9,19,25,0.9) 100%),url(' + require('@/assets/ClientNavBG.jpeg') + ')' ,backgroundPosition: 'center center'}" temporary app v-model="drawer" color="#468FBE">
      <v-layout column align-center>
      <div class="cloudbg" :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"/>
      <!-- <v-flex class="mt-5 hidden-sm-and-down"> -->
        <v-flex>
          <v-avatar
            class="profile"
            size="220"
            tile
          >
            <v-img contain src="@/assets/LogoWH.png"></v-img>
          </v-avatar>
          <!-- <p class="white--text subheading mt-1">GP Combrink Vehicle Sales & Finance</p> -->
        </v-flex>
      </v-layout>
      <v-list>        
        <v-divider></v-divider>
        <div v-for="link in items" :key="link.title">
          <v-list-item v-if="!link.Children || link.Children.length<0" :to="link.to" :target="link.target">
          <v-list-item-action>
          <v-icon large class="actionicon" color="accent">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="navoverline" :to="link.to" :target="link.target" >{{ link.title}}
            </v-list-item-title>
            </v-list-item-content>
             <v-list-item-action v-if="link.Page">
              <v-btn small fab tile class="primary"><v-icon class="secondary--text">mdi-sitemap
           </v-icon></v-btn>
           </v-list-item-action>
          </v-list-item>
            <v-list-group
              v-if="link.Children && link.Children.length"
              >
                <template v-slot:activator>
                  <v-list-item-action>
                  <v-icon large class="actionicon" color="accent">{{ link.icon }}</v-icon>
                </v-list-item-action>
                  <v-list-item-title class="navoverline">{{link.title}}</v-list-item-title>
                </template>
                <div v-for="subsub in link.Children"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title !== 'Dashboard Builder'"
                        
                        :to="subsub.to" :target="subsub.target"
                    >          
                    <v-list-item-action>
                     <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title === 'Dashboard Builder' "
                        
                        @click="OpenDashboardBuilder()"
                    >          
                    <v-list-item-action>
                    <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-group
                    sub-group
                      v-if="subsub.Children && subsub.Children.length"
                      >
                        <template v-slot:activator>
                          <!-- <v-list-item-action>
                         <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                        </v-list-item-action> -->
                          <v-list-item-title class="navoverline">{{subsub.title}}</v-list-item-title>
                        </template>
                        <v-list-item class="mx-3" v-for="subchild in subsub.Children" :key="subchild.itemObjKey"
                          :to="subchild.to" :target="subchild.target"
                      >          
                      <v-list-item-action>
                      <v-icon class="actionicon" color="accent">{{ subchild.icon }}</v-icon>
                          
                        </v-list-item-action>                          
                          <v-list-item-title class="navoverline">{{ subchild.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                </div>
              </v-list-group>
          </div>
      </v-list>

    </v-navigation-drawer>
    <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
        <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>

  </nav>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/BreadCrumbs'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'

export default {
  props: ['AppisDarkMode','CurrentRecordName','Notifications'],
  components: {
  BreadCrumbs,
  SystemTicketsLogging
  },
  data() {
    return {
      rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },

      InvitedUsersArray: [],
      IsGuestUser: true,
      Modules : [
      {
        Name: 'GP Combrink Vehicle Sales & Finance',
        URL: 'https://nene-motors.firebaseapp.com'
      },
      {
        Name: 'GP Combrink Vehicle Sales & Finance',
        URL: 'https://nene-motors.firebaseapp.com'
      },
      ],
      ModulePath: {
        Name: 'GP Combrink Vehicle Sales & Finance',
        URL: 'https://nene-motors.firebaseapp.com'
      },
      InviteUserBU: '',
      GuestBusinessUnitArray : [],
      InviteUserDialog: false,
      InvitingUserEmail: '',
      UserDashboards: [],
      RecordName: '',
      TicketLog: false,
      UsersArray: [],
      drawer: false,
      items: [],
      UserFullName: '',
      UserPicture: '',
      UserAssignedRoles: {},
      hover: false,
      
      ViewingNotificaitons: false,
      UserRecord: {},
    }
  },
  computed: {
  UserFavoriteLinks(){
    if(this.UserRecord.FavoriteLinks){
      let array = JSON.parse(JSON.stringify(this.UserRecord.FavoriteLinks))   
      array.Views = []   
      array.map(favlink => {
        console.log(favlink.Path.split('/')[1].toLowerCase())
        let routeitem = this.$router.options.routes.find(obj => obj.name === favlink.Path.split('/')[1].toLowerCase())
        console.log(routeitem)
        if(routeitem && routeitem.meta && routeitem.meta.icon){
          favlink.icon = routeitem.meta.icon
          favlink.viewicon = routeitem.meta.viewicon
          console.log(routeitem)
          if(routeitem.meta.type === 'Data Tables' && routeitem.meta.subcat){
            let DataTableObj = array.Views.find(obj => obj.Name === 'Data Tables')
            console.log("DataTableObj",DataTableObj)
            if(!DataTableObj){
              let DataTablePushObj = {
                Name: 'Data Tables',
                Links: [],
                Icon: 'mdi-file-table-box'
              }
              console.log(DataTablePushObj)

              DataTablePushObj.Links.push(favlink)
              array.Views.push(DataTablePushObj)
              console.log(array.Views)
            }
            else{
                DataTableObj.Links.push(favlink)
              }              
            }
            else if(routeitem.meta.type === 'Pages' && routeitem.meta.subcat){
            let PagesObj = array.Views.find(obj => obj.Name === 'Pages')
            console.log("PagesObj",PagesObj)
            if(!PagesObj){
              let PagesPushObj = {
                Name: 'Pages',
                Links: [],
                Icon: 'mdi-book-open-page-variant'
              }
              console.log(PagesPushObj)

              PagesPushObj.Links.push(favlink)
              array.Views.push(PagesPushObj)
              console.log(array.Views)
            }
            else{
                PagesObj.Links.push(favlink)
              }              
            }
            else if(routeitem.meta.type === 'DB Records' && routeitem.meta.subcat){
            let DBRecordObj = array.Views.find(obj => obj.Name === 'DB Records')
            console.log("DBRecordObj",DBRecordObj)
            if(!DBRecordObj){
              let DBRecordObjPush = {
                Name: 'DB Records',
                Items: [],
                Icon: 'mdi-database'
              }
              console.log(DBRecordObjPush)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              console.log(SubCatItem)
              DBRecordObjPush.Items.push(SubCatItem)
              console.log(DBRecordObjPush)
              array.Views.push(DBRecordObjPush)
              console.log(array.Views)
            }
            else{
              console.log(DBRecordObj)
              let subcatcheck = DBRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                DBRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Wiki Pages' && routeitem.meta.subcat){
            let WikiPageObj = array.Views.find(obj => obj.Name === 'Wiki Pages')
            console.log("WikiPageObj",WikiPageObj)
            if(!WikiPageObj){
              let WikiPageObjPush = {
                Name: 'Wiki Pages',
                Items: [],
                Icon: 'mdi-wikipedia'
              }
              console.log(WikiPageObjPush)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              console.log(SubCatItem)
              WikiPageObjPush.Items.push(SubCatItem)
              console.log(WikiPageObjPush)
              array.Views.push(WikiPageObjPush)
              console.log(array.Views)
            }
            else{
              let subcatcheck = WikiPageObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                WikiPageObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Library Records' && routeitem.meta.subcat){
            let LibraryRecordObj = array.Views.find(obj => obj.Name === 'Library Records')
            console.log("LibraryRecordObj",LibraryRecordObj)
            if(!LibraryRecordObj){
              let LibraryRecordPushObj = {
                Name: 'Library Records',
                Items: [],
                Icon: 'mdi-library'
              }
              console.log(LibraryRecordPushObj)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              console.log(SubCatItem)
              LibraryRecordPushObj.Items.push(SubCatItem)
              console.log(LibraryRecordPushObj)
              array.Views.push(LibraryRecordPushObj)
              console.log(array.Views)
            }
            else{
              let subcatcheck = LibraryRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                LibraryRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        
        else if(routeitem.meta.type === 'Libraries' && routeitem.meta.subcat){
            let LibraryObj = array.Views.find(obj => obj.Name === 'Libraries')
            console.log("LibraryObj",LibraryObj)
            if(!LibraryObj){
              let LibraryPushObj = {
                Name: 'Libraries',
                Links: [],
                Icon: 'mdi-library'
              }
              console.log(LibraryPushObj)

              LibraryPushObj.Links.push(favlink)
              array.Views.push(LibraryPushObj)
              console.log(array.Views)
            }
            else{
                LibraryObj.Links.push(favlink)
              }             
        }
        
            
          }
          else if(routeitem){
            let OtherObj = array.Views.find(obj => obj.Name === 'Other')
            console.log("OtherObj",OtherObj)
            if(!OtherObj){
              let OtherPushObj = {
                Name: 'Other',
                Links: [],
                Icon: 'mdi-help'
              }
              console.log(OtherPushObj)

              OtherPushObj.Links.push(favlink)
              array.Views.push(OtherPushObj)
              console.log(array.Views)
            }
            else{
                OtherObj.Links.push(favlink)
              }              
            }
          
        return favlink
      })
      return array.Views
    }
    },
    CurrentisFavorite(){
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          return true
        }
        else {
          return false
        }
      }
      else{
        return false
      }
    },
      userLoggedIn () {
      return this.$store.getters.user
    },

    UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
    ReadNotifications(){
      return this.Notifications.filter(notificaton => {
        return notificaton.Read === true
      })
    }
    },

    watch: {
      userLoggedIn (value) {
        if (value === null || value === undefined) {
          if(this.$route.path.includes('UserInvite')){    
          }
          else{
          this.$router.push('/login')
          }
        }
        //if (value !== null && value !== undefined) {
        //  this.$router.push('/')
        //}
      }
    },

  created() {
    this.getUserAuthStateChange()
    this.GetGuestBU()
  },

  methods: {
    GetGuestBU(){
      db.collection('businessunits').doc('Guest').onSnapshot(snapshot => {
        let budata = snapshot.data()
        this.InviteUserBU = budata
        this.InviteUserBU.id = 'Guest'
        this.GuestBusinessUnitArray.push(budata)
      })
    },
    SendInvitetoUser(){
      if(this.InviteUserisAdmin){
        confirm('Are you sure you want to create the User with Administrative permissions? It would give the user access to everything, including adding and removing administrators. Click okay to continue') && this.ProcessInvitetoUser()
      }
      else{
        this.ProcessInvitetoUser()
      }
    },
    ProcessInvitetoUser(){
      console.log('will invite user on '+this.InvitingUserEmail)
      let ref = db.collection('users').doc()
      let newdocid = ref.id
      let NewUserObj = {
        Email: this.InvitingUserEmail,
        Status: {
          ID: 1000001,
          Name: 'Sent'
        },
        Business_Unit: {
          id: this.InviteUserBU.id,
          Name: this.InviteUserBU.Name
        },
        Business_Unitid: this.InviteUserBU.id,
        Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
        Created_Byid: this.UserRecord.id,
        Created_On: new Date(),
        IsGuestUser: this.IsGuestUser
      }
      if(this.InviteUserisAdmin){
        NewUserObj.rolesarrayQuery = ['System Admin']
        NewUserObj.rolesarrayDBRules = {SystemAdmin: true}
      }
      db.collection('userinvites').doc(newdocid).set(NewUserObj).then(doc => {
        console.log('created user invite record')
      })

      let path = this.ModulePath.URL+'/UserInvite/'+newdocid
      this.EmailBody = `Good Day<div><br></div><div>You have been invited to join GP Combrink Vehicle Sales & Finance. </div><div>Please click on the below link to accept and process your invite.</div><div><br></div>
      <a href="`+path+`">Open your Invite</a><br>
      <div>Please note that this link is PRIVATE and you can NOT share the link with anybody else, lest you leave your pending profile at risk for attacks</div>
      <div>Thank you in advance</div>`
      console.log(this.EmailBody)
        let EmailObject = {          
          EmailFrom: 'notifications@gpcombrinkvehiclesandfinance.co.za',
          EmailTo: this.InvitingUserEmail,
          EmailSubject: 'Invite to join - GP Combrink Vehicle Sales & Finance',
          EmailBody: this.EmailBody,

        }
            //alert('email sending')
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              let snackbarcontent = {
                  snackbartimeout: 4000,
                  snackbartext: 'Invite Sent to  - '+this.InvitingUserEmail,
                  snackbartop: true,
                }
                this.$emit('ActivateSnackbar',true,snackbarcontent)
              this.CancelInvitingUserEmail()
            })
    },
    CancelInvitingUserEmail(){
      this.InviteUserDialog = false
      this.InvitingUserEmail = ''
      // this.InviteUserisAdmin = false
      // this.InviteUserBU = ''
      // this.ModulePath = ''
    },
    AcceptInviteUserDialog(){
      this.InviteUserDialog = true
    },
    OpenDashboardBuilder(){
      let ref = db.collection('customdashboards').doc()
      let newdocid = ref.id
      this.$router.push('/DashboardBuilder/'+newdocid)
    },
  RemoveFavorite(pathcheck){
      let FavoriteLinks = this.UserRecord.FavoriteLinks
      let FavoriteIndex = FavoriteLinks.indexOf(pathcheck)
      FavoriteLinks.splice(FavoriteIndex,1)
      db.collection('users').doc(this.UserRecord.id).update({
        FavoriteLinks: FavoriteLinks
      })
    },
    AddPageAsFavorite(){
      let routeitem = this.$router.options.routes.find(obj => obj.name === 'myprofile')
      console.log("routeitem",routeitem)
      console.log(this.$route.meta)
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          confirm('This item is already a favorite. Would you like to remove it from Favorites?') && this.RemoveFavorite(pathcheck)
        }
        else{
        let display = ''
        
        if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        
        }
      }
      else{
        let display = ''
      if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }      
      }
      
      // this.UserRecord.FavoriteLinks.find
    },
    StoreFavorite(display,path){
      let FavoriteLinks = []
        if(this.UserRecord.FavoriteLinks){
          FavoriteLinks = this.UserRecord.FavoriteLinks
        } 
        FavoriteLinks.push({
          DisplayName: display,
          Path: path
        })
        db.collection('users').doc(this.UserRecord.id).update({
          FavoriteLinks: FavoriteLinks
        })
    },
  ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
  IntranetViewToggle(){
      confirm('This will take you to the Intranet version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',true,true)
      },
  CancelSubmitSystemTicket(){
      this.TicketLog = false
    },
      ActivateTicketLog(){
        this.UsersArray = [this.UserRecord]
        this.TicketLog = true
      },
    GetUserNotifications(query){
      query.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.Notifications.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
    },


     NotificationRead(notification){
      if(notification.Read === false){
       db.collection('notifications').doc(notification.id).update({
        Read: true
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = true
      this.$router.push(notification.Path)

      }

    },
    ViewItem(notification){
      this.$router.push(notification.Path)
    },
    NotificationRead(notification){
      this.$emit('NotificationRead',notification)
    },
    MarkasUnread(notification){
      this.$emit('MarkasUnread',notification)
    },


    ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('users').doc(this.UserRecord.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.$emit('SignoutNotifications')
      this.UserRecord = {}
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
    GetAllUserDashboards(){
      this.GetUserDashboards(db.collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
      this.GetUserDashboards(db.collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
    },
    GetUserDashboards(query){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.UserDashboards.push({
              ...change.doc.data(),
              id: change.doc.id
            })
            let dashboardsitem = this.items.find(obj => obj.title === 'Dashboards')
        this.UserDashboards.map(dashboard => {
          let dashboarditem = {
            icon: 'mdi-desktop-mac-dashboard',
            title: dashboard.DashboardName,
            to: '/CustomDashboard/'+dashboard.id,
            target: 'self'
          }
          dashboardsitem.Children.push(dashboarditem)
        })
          }
        })
      })
    },
    async getUserAuthStateChange() {
        var vm = this;
         if(this.userLoggedIn){
        this.UserRecord = this.userLoggedIn
        vm.UserFullName = this.UserRecord.Name+' '+this.UserRecord.Surname
          vm.UserPicture = this.UserRecord.Profile_Photo
          
          
           vm.items = [
          
          {
            icon: 'mdi-desktop-mac-dashboard',
            title: 'Dashboards',
            to: '/Dashboards',
            target: 'self',
            Children: [
              {
                icon: 'mdi-desktop-mac-dashboard',
                title: 'My Dashboard',
                to: '/MyDashboard',
                target: 'self',
              },
              ]
          },
          
          {
            icon: 'mdi-sitemap',
            title: 'Pages',
            to: '/Pages',
            target: 'self',
            Children: [
            ]
          },
          {
            icon: 'mdi-library',
            title: 'Libraries',
            to: '/Libraries',
            target: 'self',
            Children: [
              
              ]
            },
          {
            icon: 'mdi-database',
            title: 'Records',
            to: '/Records',
            target: 'self',
            Children: [
              ]
            },
            {
            icon: 'mdi-information-outline',
            title: 'System',
            to: '/System',
            target: 'self',
            Children: [
            
              {
              icon: 'mdi-security-network',
              title: 'Security',
              to: '/Security',
              target: 'self',
              Children: [
                
                  {
                  icon: 'mdi-road-variant',
                  title: 'Users',
                  to: '/Users',
                  target: 'self'
                },
                {
                  icon: 'mdi-road-variant',
                  title: 'BusinessUnits',
                  to: '/BusinessUnits',
                  target: 'self'
                },
                 {
                    icon: 'mdi-security-network',
                    title: 'Security Overview',
                    to: '/SecurityOverview',
                    target: 'self'

                      },
                  ]
            },
              ]
            },
        ]
      let LibrariesItem = vm.items.find(obj => obj.title === 'Libraries')
      
        
      let RecordsItem = vm.items.find(obj => obj.title === 'Records')
          let RecordItemIndex = vm.items.indexOf(RecordsItem)
          let Admingroupobject = {
                    icon: 'mdi-eye',
                    title: 'Admin',
                    Children: [
                      ]
                  }
          vm.items[RecordItemIndex].Children.push(Admingroupobject)
          let AfterSalesgroupobject = {
                    icon: 'mdi-eye',
                    title: 'After Sales',
                    Children: [
                      ]
                  }
          vm.items[RecordItemIndex].Children.push(AfterSalesgroupobject)
          //let query = db.collection('notifications').where('Ownerid', '==', vm.UserRecord.id)
          //vm.GetUserNotifications(query)
         
            console.log('this.UserRecord.rolesarrayQuery',this.UserRecord.rolesarrayQuery)
          if(typeof this.UserRecord.rolesarrayQuery !== 'undefined'){
          vm.UserAssignedRoles = this.UserRecord.rolesarrayQuery
          
          let Adminitemsobject = vm.items[RecordItemIndex].Children.find(obj => obj.title === 'Admin')
          let Adminitemsobjectindex = vm.items[RecordItemIndex].Children.indexOf(Adminitemsobject)
          let AfterSalesitemsobject = vm.items[RecordItemIndex].Children.find(obj => obj.title === 'After Sales')
          let AfterSalesitemsobjectindex = vm.items[RecordItemIndex].Children.indexOf(AfterSalesitemsobject)
          let AdminTest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
          let EagleTest = vm.UserAssignedRoles.find(obj => obj === 'Eagle View')
                if(typeof AdminTest !== 'undefined'){
                  let SystemItem = vm.items.find(obj => obj.title === 'System')
                  let SystemItemIndex = vm.items.indexOf(SystemItem)
                  let SystemChildrenArray = [
                    {
                    icon: 'mdi-robot',
                    title: 'Data Admin',
                    to: '/DataAdministration',
                    target: 'self',
                    Children: [
                
                     {
                    icon: 'mdi-application-import',
                    title: 'RM Admin',
                    to: '/RMAdministration',
                    target: 'self'

                      },
                  {
                    icon: 'mdi-application-import',
                    title: 'Data Imports',
                    to: '/DataImports',
                    target: 'self'

                      },
                      ]

                      },
                    {
                    icon: 'mdi-help',
                    title: 'Support',
                    to: '/Support',
                    target: 'self',
                    Children: [
                
                      {
                    icon: 'mdi-robot',
                    title: 'ChatbotBuilder',
                    to: '/ChatbotBuilder',
                    target: 'self'

                      },
                   {
                    icon: 'mdi-ticket-confirmation',
                    title: 'System Tickets',
                    to: '/SystemTickets',
                    target: 'self'

                      },
                      {
                    icon: 'mdi-road-variant',
                    title: 'Roadmap',
                    to: '/Roadmap',
                    target: 'self'
                  },
                      ]

                      },
                    {
                    icon: 'mdi-whistle',
                    title: 'CampaignBuilder',
                    to: '/CampaignBuilder',
                    target: 'self'

                      },
                      {
                    icon: 'mdi-satellite-uplink',
                    title: 'Campaigns',
                    to: '/Campaigns',
                    target: 'self'

                      },
                    
                    
                  ]
                  vm.items[SystemItemIndex].Children = vm.items[SystemItemIndex].Children.concat(SystemChildrenArray)
                }
                
                  let CurrentCustomersitemobject = {
                    icon: 'mdi-tag-faces',
                    title: 'Current Customers',
                    to: '/CurrentCustomers',
                    target: 'self'
                  }
                  
                  let CurrentCustomersitemscheck = vm.items[RecordItemIndex].Children[Adminitemsobjectindex].Children.find(obj => obj.title === 'Current Customers')
                  if(typeof CurrentCustomersitemscheck === 'undefined'){
                    console.log('vm.$store.state.CurrentCustomersQuery',vm.$store.state.CurrentCustomersQuery)
                    if(vm.$store.state.CurrentCustomersQuery){
                    vm.items[RecordItemIndex].Children[Adminitemsobjectindex].Children.push(CurrentCustomersitemobject)
                    }
                  }
                  let CustomerClaimsitemobject = {
                    icon: 'mdi-shield-car',
                    title: 'Customer Claims',
                    to: '/CustomerClaims',
                    target: 'self'
                  }
                  
                  let CustomerClaimsitemscheck = vm.items[RecordItemIndex].Children[AfterSalesitemsobjectindex].Children.find(obj => obj.title === 'Customer Claims')
                  if(typeof CustomerClaimsitemscheck === 'undefined'){
                    if(vm.$store.state.CustomerClaimsQuery){
                    vm.items[RecordItemIndex].Children[AfterSalesitemsobjectindex].Children.push(CustomerClaimsitemobject)
                    }
                  }
                  let Dealershipsitemobject = {
                    icon: 'mdi-car-multiple',
                    title: 'Dealerships',
                    to: '/Dealerships',
                    target: 'self'
                  }
                  
                  let Dealershipsitemscheck = vm.items[RecordItemIndex].Children[Adminitemsobjectindex].Children.find(obj => obj.title === 'Dealerships')
                  if(typeof Dealershipsitemscheck === 'undefined'){
                    if(vm.$store.state.DealershipsQuery){
                    vm.items[RecordItemIndex].Children[Adminitemsobjectindex].Children.push(Dealershipsitemobject)
                    }
                  }
         }
         }
else {
        //console.log('AuthchangeLoggedOut')
        vm.pushLoggedOutItems

      vm.items = [

        {
          icon: 'mdi-login-variant',
          title: 'Login',
          to: '/Login',
          target: 'self'
        },
      ]

     }
    }

  }

}
</script>

<style>
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */

</style>
    