
<template>
  <v-flex xl12 lg12 md12 sm12 xs12 class="justify-center">
    <!-- <v-layout row class="justify-center"> -->
              <v-card tile flat height="100%" width="100%" :dark="AppisDarkMode">
                <!-- <v-layout row wrap align-center>
                  <v-flex> -->
                    <h4>Configure the Role</h4><br>
                    <v-select :items="Buildsbysystem" item-text="tempname" label="Select Table" return-object
                    v-model="selectedBuildforUserRoles"
                    />
                    <v-list dense>
                      <v-list-item >
                        <v-list-item-content>
                          <b>User</b>
                        </v-list-item-content>
                        <v-list-item-content>
                          <b>Create</b>
                        </v-list-item-content>
                        <v-list-item-content>
                          <b>Get</b>
                          </v-list-item-content>
                        <v-list-item-content>
                          <b>List</b>
                        </v-list-item-content>
                        <v-list-item-content>
                          <b>Update</b>
                        </v-list-item-content>
                        <v-list-item-content>
                          <b>Delete</b>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="user in ClientAppUsersbyselectedBuild" :key="user.itemObjKey">
                        <v-list-item-content>
                          {{user.Full_Name}}
                        </v-list-item-content>
                        <v-list-item-content :class="user.CanCreate ? `green--text hastooltip` : `grey--text`">
                          {{user.CanCreate}}
                          <span class="tooltiptext">{{ReturnAccessLevel(user.CanCreate,user)}}
                          </span>
                        </v-list-item-content>
                        <v-list-item-content :class="user.CanGet ? `green--text hastooltip` : `grey--text`">
                          {{user.CanGet}}
                          <span class="tooltiptext">{{ReturnAccessLevel(user.CanGet,user)}}
                          </span>
                        </v-list-item-content>
                        <v-list-item-content :class="user.CanList ? `green--text hastooltip` : `grey--text`">
                          {{user.CanList}}
                          <span class="tooltiptext">{{ReturnAccessLevel(user.CanList,user)}}
                          </span>
                        </v-list-item-content>
                        <v-list-item-content :class="user.CanUpdate ? `green--text hastooltip` : `grey--text`">
                          {{user.CanUpdate}}
                          <span class="tooltiptext">{{ReturnAccessLevel(user.CanUpdate,user)}}
                          </span>
                        </v-list-item-content>
                        <v-list-item-content :class="user.CanDelete ? `green--text hastooltip` : `grey--text`">
                          {{user.CanDelete}}
                          <span class="tooltiptext">{{ReturnAccessLevel(user.CanDelete,user)}}
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  <!-- </v-flex>
                </v-layout> -->
              </v-card>
</v-flex>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
  props: ['AppisDarkMode','UserisGuest'],
    data() {
        return {
            selectedBuildforUserRoles: '',
            clientDB: '',
            ClientAppBU: [],
            ClientAppUsers: [],
            SecurityRoles: [
  {
    "ID": 1000007,
    "Builds": [],
    "DefaultAssignment": {
      "updateUnitConfig": "",
      "createUnitConfig": "",
      "getBoolean": false,
      "updateBoolean": false,
      "createBoolean": false,
      "deleteBoolean": false,
      "getUnitConfig": "",
      "updateUnitconfig": "",
      "deleteUnitConfig": "",
      "listBoolean": true,
      "listUnitConfig": "All"
    },
    "SystemDefault": true,
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Name": "Default list All",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission tolist records in ALL Business Units",
    "DisplayName": "Default list All",
    "id": "2F6hZONhxuv5NrcWeM16"
  },
  {
    "ID": 1000015,
    "Builds": [],
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Name": "Default get UserUnit",
    "SystemDefault": true,
    "DisplayName": "Default get UserUnit",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to get all records, within their Unit ONLY",
    "DefaultAssignment": {
      "updateUnitconfig": "",
      "createUnitConfig": "",
      "deleteUnitConfig": "",
      "updateUnitConfig": "",
      "deleteBoolean": false,
      "listUnitConfig": "",
      "createBoolean": false,
      "listBoolean": false,
      "getUnitConfig": "UserUnit",
      "updateBoolean": false,
      "getBoolean": true
    },
    "id": "3UoWdIwkoQMAWk9JWwYz"
  },
  {
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Name": "Default delete UserUnit",
    "DisplayName": "Default delete UserUnit",
    "ID": 1000019,
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to delete any records within their Business Unit ONLY",
    "DefaultAssignment": {
      "listUnitConfig": "",
      "createBoolean": false,
      "updateBoolean": false,
      "updateUnitConfig": "",
      "getUnitConfig": "",
      "deleteBoolean": true,
      "deleteUnitConfig": "UserUnit",
      "listBoolean": false,
      "getBoolean": false,
      "createUnitConfig": "",
      "updateUnitconfig": ""
    },
    "SystemDefault": true,
    "Builds": [],
    "id": "5gAh4UFyb6I9WSrQ82fA"
  },
  {
    "DisplayName": "Default Create All",
    "Name": "Default Create All",
    "SystemDefault": true,
    "ID": 1000005,
    "Builds": [],
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create records in ALL Business Units",
    "DefaultAssignment": {
      "createBoolean": true,
      "deleteUnitConfig": "",
      "createUnitConfig": "All",
      "getBoolean": false,
      "listBoolean": false,
      "updateBoolean": false,
      "deleteBoolean": false,
      "listUnitConfig": "",
      "getUnitConfig": "",
      "updateUnitConfig": "",
      "updateUnitconfig": ""
    },
    "id": "6Cl5d7UXQeroHz6awuaK"
  },
  {
    "Name": "Default Unit",
    "SystemDefault": true,
    "Builds": [],
    "ID": 1000003,
    "DisplayName": "Default Unit",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create, get, list, and update ONLY records in their Business Unit",
    "DefaultAssignment": {
      "listBoolean": true,
      "deleteUnitConfig": "UserUnit",
      "listUnitConfig": "UserUnit",
      "updateBoolean": true,
      "createUnitConfig": "UserUnit",
      "getUnitConfig": "UserUnit",
      "deleteBoolean": true,
      "updateUnitConfig": "UserUnit",
      "createBoolean": true,
      "updateUnitconfig": "UserUnit",
      "getBoolean": true
    },
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "id": "DitOi4WrLo3HdpHp8voL"
  },
  {
    "ID": 1000006,
    "DefaultAssignment": {
      "getUnitConfig": "All",
      "getBoolean": true,
      "updateBoolean": false,
      "deleteBoolean": false,
      "updateUnitconfig": "",
      "createBoolean": false,
      "updateUnitConfig": "",
      "listBoolean": false,
      "listUnitConfig": "",
      "createUnitConfig": "",
      "deleteUnitConfig": ""
    },
    "SystemDefault": true,
    "Name": "Default get All",
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "DisplayName": "Default get All",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to records in ALL Business Units",
    "Builds": [],
    "id": "M7LKb0Cxfy3JQftCRsAH"
  },
  {
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to update any records, within their Business Unit ONLY",
    "DefaultAssignment": {
      "updateBoolean": true,
      "updateUnitConfig": "UserUnit",
      "getBoolean": false,
      "listUnitConfig": "",
      "listBoolean": false,
      "updateUnitconfig": "UnitDown",
      "deleteUnitConfig": "",
      "createUnitConfig": "",
      "getUnitConfig": "",
      "createBoolean": false,
      "deleteBoolean": false
    },
    "Builds": [],
    "ID": 1000018,
    "Name": "Default update UserUnit",
    "DisplayName": "Default update UserUnit",
    "SystemDefault": true,
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "id": "OfkxuUR9UqHeJmmvwyZi"
  },
  {
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Name": "Default update ALL",
    "DisplayName": "Default update ALL",
    "Builds": [],
    "ID": 1000009,
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to update records in ALL Business Unit",
    "DefaultAssignment": {
      "listBoolean": false,
      "listUnitConfig": "",
      "updateBoolean": true,
      "updateUnitconfig": "All",
      "createBoolean": false,
      "getUnitConfig": "",
      "getBoolean": false,
      "updateUnitConfig": "All",
      "createUnitConfig": "",
      "deleteBoolean": false,
      "deleteUnitConfig": ""
    },
    "SystemDefault": true,
    "id": "PA9P7P2LpYUdhnCQZ1wK"
  },
  {
    "Builds": [],
    "DisplayName": "Default create unitdown",
    "DefaultAssignment": {
      "getUnitConfig": "",
      "getBoolean": false,
      "deleteUnitConfig": "",
      "createUnitConfig": "UnitDown",
      "listBoolean": false,
      "deleteBoolean": false,
      "updateUnitconfig": "",
      "updateBoolean": false,
      "updateUnitConfig": "",
      "listUnitConfig": "",
      "createBoolean": true
    },
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "ID": 1000010,
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create records witin their Unit, and below",
    "Name": "Default create unitdown",
    "SystemDefault": true,
    "id": "TvwxsGEX0eK0jqiCPE17"
  },
  {
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to get all records in their Unit, and down",
    "Name": "Default get UnitDown",
    "DisplayName": "Default get UnitDown",
    "ID": 1000011,
    "SystemDefault": true,
    "Builds": [],
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "DefaultAssignment": {
      "listUnitConfig": "",
      "createUnitConfig": "",
      "deleteBoolean": false,
      "updateBoolean": false,
      "listBoolean": false,
      "getBoolean": true,
      "updateUnitConfig": "",
      "createBoolean": false,
      "getUnitConfig": "UnitDown",
      "updateUnitconfig": "",
      "deleteUnitConfig": ""
    },
    "id": "dfw9zhvuEzwzMj7dxctG"
  },
  {
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "SystemDefault": true,
    "DefaultAssignment": {
      "getUnitConfig": "",
      "deleteBoolean": false,
      "deleteUnitConfig": "",
      "createBoolean": true,
      "listBoolean": false,
      "listUnitConfig": "",
      "updateUnitconfig": "",
      "updateUnitConfig": "",
      "getBoolean": false,
      "updateBoolean": false,
      "createUnitConfig": "UserUnit"
    },
    "Builds": [],
    "Name": "Default create UserUnit",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create any records within their Unit ONLY",
    "DisplayName": "Default create UserUnit",
    "ID": 1000017,
    "id": "hh0YUL1fKCrdeZzqgegi"
  },
  {
    "DisplayName": "Default delete UnitDown",
    "Name": "Default delete UnitDown",
    "SystemDefault": true,
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to delete all records in their Unit, and down",
    "Builds": [],
    "DefaultAssignment": {
      "updateUnitconfig": "",
      "createBoolean": false,
      "createUnitConfig": "",
      "deleteUnitConfig": "UnitDown",
      "listUnitConfig": "",
      "getUnitConfig": "",
      "listBoolean": false,
      "updateBoolean": false,
      "deleteBoolean": true,
      "updateUnitConfig": "",
      "getBoolean": false
    },
    "ID": 1000013,
    "id": "i95Sq8eOsrnBu8rzkHzx"
  },
  {
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "DisplayName": "Default update Unit Down",
    "DefaultAssignment": {
      "listBoolean": false,
      "updateUnitconfig": "UnitDown",
      "createBoolean": false,
      "updateUnitConfig": "UnitDown",
      "listUnitConfig": "",
      "updateBoolean": true,
      "deleteUnitConfig": "",
      "getUnitConfig": "",
      "createUnitConfig": "",
      "getBoolean": false,
      "deleteBoolean": false
    },
    "SystemDefault": true,
    "Builds": [],
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to update all records, within their Unit and Down",
    "Name": "Default update Unit Down",
    "ID": 1000014,
    "id": "ma1sxmEUTjestzJsh3kj"
  },
  {
    "Name": "Default list UnitDown",
    "Builds": [],
    "ID": 1000012,
    "DefaultAssignment": {
      "createUnitConfig": "",
      "deleteBoolean": false,
      "getUnitConfig": "",
      "deleteUnitConfig": "",
      "getBoolean": false,
      "updateUnitconfig": "",
      "createBoolean": false,
      "listBoolean": true,
      "updateBoolean": false,
      "listUnitConfig": "UnitDown",
      "updateUnitConfig": ""
    },
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to list all records in their Unit of down",
    "DisplayName": "Default list UnitDown",
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "SystemDefault": true,
    "id": "oADXh0HulSlV8T8UlJq8"
  },
  {
    "Builds": [],
    "Name": "Default delete ALL",
    "ID": 1000008,
    "DisplayName": "Default delete ALL",
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "SystemDefault": true,
    "DefaultAssignment": {
      "getBoolean": false,
      "deleteUnitConfig": "All",
      "updateUnitconfig": "",
      "updateBoolean": false,
      "createBoolean": false,
      "listUnitConfig": "",
      "updateUnitConfig": "",
      "deleteBoolean": true,
      "listBoolean": false,
      "createUnitConfig": "",
      "getUnitConfig": ""
    },
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to delete records in ALL Business Units. (Not advised!!!!!)",
    "id": "v4gZygoE9Ipx4UMpS8ND"
  },
  {
    "ID": 1000016,
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to list all records within their Unit only",
    "DefaultAssignment": {
      "createBoolean": false,
      "createUnitConfig": "",
      "deleteUnitConfig": "",
      "updateUnitconfig": "",
      "deleteBoolean": false,
      "getBoolean": false,
      "getUnitConfig": "",
      "updateUnitConfig": "",
      "updateBoolean": false,
      "listBoolean": true,
      "listUnitConfig": "UserUnit"
    },
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Name": "Default list UserUnit",
    "Builds": [],
    "SystemDefault": true,
    "DisplayName": "Default list UserUnit",
    "id": "y31SCgpbTtPJlMtItjwY"
  },
  {
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create, get, list, and update ALL records regardless of the Business Unit the record lies in",
    "Name": "Default All",
    "DefaultAssignment": {
      "listUnitConfig": "All",
      "getUnitConfig": "All",
      "deleteUnitConfig": "All",
      "createBoolean": true,
      "getBoolean": true,
      "updateUnitconfig": "All",
      "listBoolean": true,
      "createUnitConfig": "All",
      "updateBoolean": true,
      "updateUnitConfig": "All",
      "deleteBoolean": true
    },
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "ID": 1000001,
    "DisplayName": "Default All",
    "Builds": [],
    "SystemDefault": true,
    "id": "yDoWX0lkusg4NLgdq5Su"
  },
  {
    "ID": 1000004,
    "DisplayName": "Default Owner",
    "SystemDefault": true,
    "DefaultAssignment": {
      "createUnitConfig": "Owner",
      "getBoolean": true,
      "listUnitConfig": "Owner",
      "createBoolean": true,
      "updateBoolean": true,
      "listBoolean": true,
      "deleteBoolean": true,
      "deleteUnitConfig": "Owner",
      "updateUnitconfig": "Owner",
      "getUnitConfig": "Owner",
      "updateUnitConfig": "Owner"
    },
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create, get, list, and update ONLY records that the User is an Owner of",
    "Builds": [],
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "Name": "Default Owner",
    "id": "zvVjZa2zxQJyK1bffzL3"
  },
  {
    "DefaultAssignment": {
      "getBoolean": true,
      "getUnitConfig": "UnitDown",
      "updateUnitconfig": "UnitDown",
      "updateBoolean": true,
      "listBoolean": true,
      "updateUnitConfig": "UnitDown",
      "listUnitConfig": "UnitDown",
      "deleteUnitConfig": "UnitDown",
      "createBoolean": true,
      "createUnitConfig": "UnitDown",
      "deleteBoolean": true
    },
    "Builds": [],
    "SystemDefault": true,
    "systemid": "EAeK2ov547ZbO55ZJ6ok",
    "ID": 1000002,
    "Name": "Default Unit Down",
    "DisplayName": "Default Unit Down",
    "Description": "This is a default role created by the system, that only applies to datasets marked to receive default security roles. This Role allows the assigned user permission to create, get, list, and update records in their Business Unit OR BELOW their Business Unit(Child Business Unit)",
    "id": "zzKnf3PQKQVFq2UGEq5g"
  }
],
            CustomSecurityRoles: [
  {
    "Name": "Office Administrator",
    "Description": "The one Role the organization actually needs to capture records",
    "id": "myMj2JpB3Lpbxe8l0oy7",
    "Builds": [
      {
        "CreateisCustom": true,
        "temppluralname": "Customer Claims",
        "id": "Customer_Claims",
        "updateBoolean": false,
        "ListisCustom": true,
        "CustomcreateBoolean": true,
        "GetisCustom": true,
        "deleteBoolean": false,
        "tempname": "Customer Claim",
        "CustomgetBoolean": true,
        "dataseticon": "mdi-shield-car",
        "CustomupdateBoolean": true,
        "listBoolean": false,
        "CustomlistBoolean": true,
        "RecordGroup": {
          "Icon": "mdi-eye",
          "Name": "After Sales",
          "ID": 1000003
        },
        "getBoolean": false,
        "type": "DataSet",
        "HasActivities": false,
        "DeleteisCustom": false,
        "EditisCustom": true,
        "IsMenuItem": true,
        "createBoolean": false
      },
      {
        "CustomgetBoolean": true,
        "dataseticon": "mdi-car-multiple",
        "RecordGroup": {
          "Icon": "mdi-eye",
          "Name": "Admin",
          "ID": 1000002
        },
        "temppluralname": "Dealerships",
        "ListisCustom": true,
        "updateBoolean": false,
        "deleteBoolean": false,
        "getBoolean": false,
        "CustomcreateBoolean": true,
        "listBoolean": false,
        "createBoolean": false,
        "tempname": "Dealership",
        "HasActivities": false,
        "EditisCustom": true,
        "IsMenuItem": true,
        "DeleteisCustom": false,
        "id": "Dealerships",
        "CreateisCustom": true,
        "GetisCustom": true,
        "type": "DataSet",
        "CustomupdateBoolean": true,
        "CustomlistBoolean": true
      },
      {
        "ListisCustom": true,
        "HasActivities": false,
        "CustomgetBoolean": true,
        "listBoolean": false,
        "type": "DataSet",
        "getBoolean": false,
        "DeleteisCustom": false,
        "CustomlistBoolean": true,
        "tempname": "Sales Contract",
        "updateBoolean": false,
        "dataseticon": "mdi-bank",
        "temppluralname": "Sales Contracts",
        "EditisCustom": true,
        "createBoolean": false,
        "deleteBoolean": false,
        "GetisCustom": true,
        "CustomcreateBoolean": true,
        "id": "Sales_Contracts",
        "RecordGroup": "",
        "CreateisCustom": true,
        "IsMenuItem": false,
        "CustomupdateBoolean": true
      },
      {
        "IsMenuItem": true,
        "listBoolean": false,
        "CustomdeleteBoolean": true,
        "deleteBoolean": false,
        "CustomcreateBoolean": true,
        "type": "DataSet",
        "EditisCustom": true,
        "updateBoolean": false,
        "ListisCustom": true,
        "RecordGroup": {
          "Name": "Admin",
          "ID": 1000002,
          "Icon": "mdi-eye"
        },
        "temppluralname": "Current Customers",
        "HasActivities": false,
        "CreateisCustom": true,
        "CustomupdateBoolean": true,
        "DeleteisCustom": true,
        "getBoolean": false,
        "tempname": "Current Customer",
        "createBoolean": false,
        "id": "Current_Customers",
        "CustomgetBoolean": true,
        "GetisCustom": true,
        "dataseticon": "mdi-tag-faces",
        "CustomlistBoolean": true
      }
    ],
    "DisplayName": "Office Administrator"
  }
],
            Buildsbysystem: [
                {
                    id: 'Business_Units',
                    tempname: 'Business Unit',
                    datasetsecuritystructure: 'Custom Roles',
                    CreateisCustom: false,
                    CreatePermission: 'Admin',
                    GetisCustom: false,
                    GetPermission: 'Admin',
                    ListisCustom: false,
                    ListPermission: 'Admin',
                    EditisCustom: false,
                    UpdatePermission: 'Admin',
                    DeleteisCustom: false,
                    DeletePermission: 'Admin'
                },
                {
                    id: 'Current_Customers',
                    tempname: 'Current Customer',
                    datasetsecuritystructure: 'Custom Roles',
                    CreateisCustom: true,
                    CreatePermission: 'Custom Role',
                    GetisCustom: true,
                    GetPermission: 'Custom Role',
                    ListisCustom: true,
                    ListPermission: 'Custom Role',
                    EditisCustom: true,
                    UpdatePermission: 'Custom Role',
                    DeleteisCustom: true,
                    DeletePermission: 'Custom Role'
                },
                {
                    id: 'Customer_Claims',
                    tempname: 'Customer Claim',
                    datasetsecuritystructure: 'Custom Roles',
                    CreateisCustom: true,
                    CreatePermission: 'Custom Role',
                    GetisCustom: true,
                    GetPermission: 'Custom Role',
                    ListisCustom: true,
                    ListPermission: 'Custom Role',
                    EditisCustom: true,
                    UpdatePermission: 'Custom Role',
                    DeleteisCustom: false,
                    DeletePermission: 'Admin'
                },
                {
                    id: 'Dealerships',
                    tempname: 'Dealership',
                    datasetsecuritystructure: 'Custom Roles',
                    CreateisCustom: true,
                    CreatePermission: 'Custom Role',
                    GetisCustom: true,
                    GetPermission: 'Custom Role',
                    ListisCustom: true,
                    ListPermission: 'Custom Role',
                    EditisCustom: true,
                    UpdatePermission: 'Custom Role',
                    DeleteisCustom: false,
                    DeletePermission: 'Admin'
                },
                {
                    id: 'Sales_Contracts',
                    tempname: 'Sales Contract',
                    datasetsecuritystructure: 'Custom Roles',
                    CreateisCustom: true,
                    CreatePermission: 'Custom Role',
                    GetisCustom: true,
                    GetPermission: 'Custom Role',
                    ListisCustom: true,
                    ListPermission: 'Custom Role',
                    EditisCustom: true,
                    UpdatePermission: 'Custom Role',
                    DeleteisCustom: false,
                    DeletePermission: 'Admin'
                },
                {
                    id: 'Service_Jobs',
                    tempname: 'Service Job',
                    datasetsecuritystructure: 'System Admin',
                    CreateisCustom: false,
                    CreatePermission: '',
                    GetisCustom: false,
                    GetPermission: '',
                    ListisCustom: false,
                    ListPermission: '',
                    EditisCustom: false,
                    UpdatePermission: '',
                    DeleteisCustom: false,
                    DeletePermission: ''
                },
                {
                    id: 'Users',
                    tempname: 'User',
                    datasetsecuritystructure: 'Custom Roles',
                    CreateisCustom: false,
                    CreatePermission: 'Admin',
                    GetisCustom: false,
                    GetPermission: 'Admin',
                    ListisCustom: false,
                    ListPermission: 'Admin',
                    EditisCustom: false,
                    UpdatePermission: 'Admin',
                    DeleteisCustom: false,
                    DeletePermission: 'Admin'
                },
            ],

        }
    },
    computed: {
        ClientAppUsersbyselectedBuild(){
      let build = this.selectedBuildforUserRoles
      
      if(build){
        return this.ClientAppUsers.map(user => {
          let userroles = []
          user.CanCreate = false
          user.CanGet = false
          user.CanList = false
          user.CanUpdate = false
          user.CanDelete = false
          
          if(user.rolesarrayQuery){
            userroles = user.rolesarrayQuery
          }
          let useradmintest = userroles.find(obj => obj == 'System Admin')
          if(useradmintest){
            user.CanCreate = true
            user.CanGet = true
            user.CanList = true
            user.CanUpdate = true
            user.CanDelete = true
          }
          
          if(build.datasetsecuritystructure === 'Custom Roles'){
            let customroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
            let createroles = JSON.parse(JSON.stringify(customroles))
            let getroles = JSON.parse(JSON.stringify(customroles))
            let listroles = JSON.parse(JSON.stringify(customroles))
            let updateroles = JSON.parse(JSON.stringify(customroles))
            let deleteroles = JSON.parse(JSON.stringify(customroles))
            if(build.CreateisCustom){
              this.GetCustomRoleOutput(user,userroles,'CanCreate',createroles,build,'CustomcreateBoolean')
            }
            else if(build.CreatePermission === 'Admin'){
              
            }
            else{
              user.CanCreate = true
            }
            if(build.GetisCustom){
              this.GetCustomRoleOutput(user,userroles,'CanGet',getroles,build,'CustomgetBoolean')
            }
            else if(build.GetPermission === 'Admin'){
              
            }
            else{
              user.CanGet = true
            }
            if(build.ListisCustom){
              this.GetCustomRoleOutput(user,userroles,'CanList',listroles,build,'CustomlistBoolean')
            }
            else if(build.ListPermission === 'Admin'){
              
            }
            else{
              user.CanList = true
            }
            if(build.EditisCustom){
              this.GetCustomRoleOutput(user,userroles,'CanUpdate',updateroles,build,'CustomupdateBoolean')
            }
            else if(build.UpdatePermission === 'Admin'){
              
            }
            else{
              user.CanUpdate = true
            }
            if(build.DeleteisCustom){
              this.GetCustomRoleOutput(user,userroles,'CanDelete',deleteroles,build,'CustomdeleteBoolean')
            }
            else if(build.DeletePermission === 'Admin'){
              
            }
            else{
              user.CanDelete = true
            }
          }
          else if(build.datasetsecuritystructure === 'Business Unit'){
            let createroles = JSON.parse(JSON.stringify(this.SecurityRoles))
            let getroles = JSON.parse(JSON.stringify(this.SecurityRoles))
            let listroles = JSON.parse(JSON.stringify(this.SecurityRoles))
            let updateroles = JSON.parse(JSON.stringify(this.SecurityRoles))
            let deleteroles = JSON.parse(JSON.stringify(this.SecurityRoles))
            this.GetBURoleOutput(user,userroles,'CanCreate',createroles,build,'createBoolean','createUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanGet',getroles,build,'getBoolean','getUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanList',listroles,build,'listBoolean','listUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanUpdate',updateroles,build,'updateBoolean','updateUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanDelete',deleteroles,build,'deleteBoolean','deleteUnitConfig')
          }
          
          return user
        })
      }
      else{
        return this.ClientAppUsers
      }
    },
    },
    created(){
        this.GetClientAppBU()
        this.GetClientAppUsers()
    },

    methods: {
        ReturnAccessLevel(value,user){
      if(value === 1 || value === 2 || value === 3){
        return this.GetClientDBBU(value,user)
      } 
      if(value === 4){
        return 'Only where Owner'
      }
      else if (value){
        return 'All'
      }     
      
    },
    GetClientDBBU(value,user){
      if(value === 1){
        let temparray = []
        this.ClientAppBU.map(bu => {
          temparray.push(bu.Name)
        })
        return temparray
      }
      if(value === 2){
       let userbuobj = this.ClientAppBU.find(obj => obj.id === user.Business_Unitid)
      let test = userbuobj.childarrayQuery
      let returnarray = test.map(buid => {
        let returnbuobj = this.ClientAppBU.find(obj => obj.id === buid)
        return returnbuobj.Name
      })
      return returnarray 
      }
      if(value === 3){
      let userbuobj = this.ClientAppBU.find(obj => obj.id === user.Business_Unitid)
      
      return userbuobj.Name
      }

    },
    GetBURoleOutput(user,userroles,permissionname,rolesarray,build,rolebuildpermissionname,rolebuildpermissionconfig){
      console.log(user)
      let roles = JSON.parse(JSON.stringify(rolesarray))
      let newroles = roles.filter(role => {
          role.Builds = role.Builds.filter(rolebuild => {
            return rolebuild.id === build.id && rolebuild[rolebuildpermissionname] === true
          })   
          return role
        }).filter(role => {
          return role.Builds.length > 0
        })
        
      newroles.map(role => {
                let userrolestest = userroles.find(obj => obj === role.Name)
                if(userrolestest){
                  console.log(user)
                  let permissiontype = role.Builds[0][rolebuildpermissionconfig]
                  if(permissiontype === 'All'){
                    user[permissionname] = 1
                  }                 
                  else if(permissiontype === 'UnitDown' && !user[permissionname] || permissiontype === 'UnitDown' && user[permissionname] > 2){
                    user[permissionname] = 2
                  }
                  else if(permissiontype === 'UserUnit' && !user[permissionname] || permissiontype === 'UserUnit' && user[permissionname] > 3){
                    user[permissionname] = 3
                  }
                  else if(permissiontype === 'Owner' && !user[permissionname] || permissiontype === 'Owner' && user[permissionname] > 3){
                    user[permissionname] = 4
                  }
                }
              })
    },
    GetCustomRoleOutput(user,userroles,permissionname,rolesarray,build,rolebuildpermissionname){
      let newroles = rolesarray.filter(role => {
          role.Builds = role.Builds.filter(rolebuild => {
            return rolebuild.id === build.id && rolebuild[rolebuildpermissionname] === true
          })   
          return role
        }).filter(role => {
          return role.Builds.length > 0
        })
      newroles.map(role => {
                let userrolestest = userroles.find(obj => obj === role.Name)
                if(userrolestest){
                  user[permissionname] = true
                }
              })
    },
      GetClientAppBU(){
        let vm = this
        db.collection('businessunits').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.ClientAppBU.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
        })
      },
      GetClientAppUsers(){
        let vm = this

        db.collection('userroles').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
                let userobj = {
                ...change.doc.data(),
                id: change.doc.id
                }
                vm.GetClientAppUsersBasic(userobj.id,userobj).then(result => {
                userobj.Full_Name = result
                vm.ClientAppUsers.push(userobj)
                })
                
            }
            })                 
        })
      },
      GetClientAppUsersBasic(userid,userobj){
        let vm = this
        return new Promise(function(resolve, reject) {
          db.collection('users').doc(userid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            resolve(userdata.Full_Name)
          })
        })
      },
    } 
}
</script>

<style>
.hastooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  /* background-color: rgb(0, 153, 255); */
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
 background-size: 400% 400%;
 animation: gradientBG 15s ease infinite;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.hastooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 100;
}
</style>

    