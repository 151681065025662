
<template>
<v-card :color="tab.RowColor" :class="tab.RowTransparency+' '+tab.Justify" tile  width="100%" flat v-if="tab.Name === 'Gallery'" :style="'margin-top:'+tab.RowMarginTop+';margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'">
  <v-sheet
    class="mx-auto my-3"
    :elevation="tab.Elevation"
  >
  <v-card-title v-if="tab.Title" :class="tab.Center" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                <strong>{{tab.Title}}</strong>
     </v-card-title>
    <v-slide-group
      v-model="model"
      class="pa-4"
      
      show-arrows
    >
      <v-slide-item
       v-for="card in tab.Assets"
                                :key="card.itemObjKey"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-4"
          height="200"
          width="200"
          @click="toggle"
        >
        <v-row    
            v-if="!active"   
            class="fill-height"                    
            align="center"
            justify="center"
            >
            <v-avatar
            v-if="!active" 
            tile size="100%">
        <v-img 
                :src="card.ThumbURL"
            
            />
            </v-avatar>
        </v-row>
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-scale-transition>
                <v-btn
                 :href="card.URL" target="_blank"
                 v-if="active"
                >
              View
                </v-btn>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <v-expand-transition  v-if="!tab.HideCaptions">
      <v-sheet
      v-if="model != null && tab.Assets[model].Caption"
       class="mx-3"
        height="100"
        tile
      >
        <!-- <v-row
          class="fill-height"
          align="center"
          justify="center"
        > -->
         <v-card-text align="center"
          justify="center">
             {{tab.Assets[model].Caption}}
         </v-card-text>      
        
        <!-- </v-row> -->
      </v-sheet>
    </v-expand-transition>
     <v-sheet
       v-if="tab.Caption && !tab.HideCaptions || tab.HasImage && !tab.HideCaptions"
        height="200"
        tile
      >
   
        <v-card-text
            v-if="tab.HasImage"
          align="center"
          justify="center"
        >
    
                    <v-avatar
                    tile
                    size="100"
                >
               
                   <v-img  v-if="tab.HasImage && tab.IMG"
                :contain="!tab.Clipped"
                :height="tab.Height"
                    :src="tab.IMG"
                    :style="CheckifClipped(tab)"
                    
                    />
                    <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                :height="tab.Height"
                    src="@/assets/ImageHolder.png"
                    />
                </v-avatar> 
       </v-card-text>
       <v-card-text
            v-if="tab.Caption"
          align="center"
          justify="center"
        >
        <div class="caption">
            {{tab.Caption}}
        </div>
        </v-card-text>
      </v-sheet>
  </v-sheet>       
        </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex'],
    components: {

        },
    data() {
    return {     
        model: null, 
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
     OpenDialog(tab,tabindex){
         this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    