
<template>

    <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat :height="tab.RowHeight" tile v-if="tab.Name === 'Image'">
                        <v-layout :class="tab.Justify" row :style="'margin-top:'+tab.RowMarginTop+'px;margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'">
                   
                        <v-img v-if="tab.IMG" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'+CheckifClipped(tab)"
                         :contain="!tab.Clipped"
                                   :height="tab.Height"
                           
                        
                         :src="tab.IMG"
                        
                        >
                        <v-layout  row :class="tab.LayoutValue+' mx-3'" v-if="tab.HasTextBox">
                        <v-flex :class="' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall+' mx-2 '"  :style="tab.FlexValue" v-if="tab.HasTextBox">
                            <v-card :class="tab.BoxTransparency"  :color="tab.BGColor.hex" :elevation="tab.Elevation" style="margin:10px;padding:10px" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" >
                                <v-card-title :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                <strong>{{tab.Title}}</strong></v-card-title>
                            <v-card-text v-html="tab.Description" :style="DefaultPageFont+'color:'+tab.FontColor.hex">
                            
                        </v-card-text>
                        <v-card-actions v-if="tab.HasActionButton">
                            <v-btn :dark="tab.ActionBtnDark" :style="'background-color:'+tab.ButonColor.hex">
                                {{tab.ActionButtonName}}
                            </v-btn>
                        </v-card-actions>
                            </v-card>
                            </v-flex>
                        </v-layout>
                        <v-layout v-if="tab.HasLogo && tab.LogoIsBottom" row :class="'justify-'+tab.LogoJustify">
                        <v-avatar size="120" tile >
                        <v-img contain v-if="!tab.LogoSRC"
                         src="@/assets/ImageHolder.png"
                        />
                        <v-img contain v-if="tab.LogoSRC"
                         :src="tab.LogoSRC"
                        />
                        </v-avatar>
                        
                        </v-layout>
                        </v-img>
                        <v-img v-if="!tab.IMG" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'+CheckifClipped(tab)"
                           
                        :contain="!tab.Clipped"
                        :height="tab.Height"
                         src="@/assets/ImageHolder.png"
                        
                        />
                        </v-layout>   
                        </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i'],
    components: {

        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        ClassCheck(tab){
           var newclass = ''
           
            if(tab.Transparent){
                newclass = newclass+' red'
                tab.Color = '#ffffff'
            }
             if(tab.Clipped){
                newclass = newclass+' clipped'
            }
            return newclass
        },
     OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex){
         this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex)
     },
     CheckifClipped(tab){
            if(tab.Clipped){
                if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                            if(tab.CircleAnimation === 'Spotlight Search'){
                                return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                            }
                            else{
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);`   
                            }
                            
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    