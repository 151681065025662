
    <template>
<v-card flat class="transparent" width="100%" height="100%">
    <h4>Emails for {{RecordPrimaryField}}</h4>
    <v-dialog v-model="ViewEmailDialog" v-if="ViewingEmail" max-width="1000">
        <v-card max-width="1000">
            <v-card-title>
                {{ViewingEmail.createdon.toDate()}}
            </v-card-title>
            <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item>
                      <v-list-item-content>               
                      <strong>From:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      {{ViewingEmail.from.displayName}}   
                      </v-list-item-content>               
                   </v-list-item>               
                   <v-list-item>
                     <!-- <v-list-item-avatar tile>                -->
                      <v-btn small color="pop" dark>To:</v-btn>
                      <!-- </v-list-item-avatar> -->
                      <v-list-item-content class="mx-3" v-for="to in ViewingEmail.to" :key="to.itemObjKey">               
                      {{to.emailAddress}},
                      </v-list-item-content>              
                   </v-list-item>
                   <v-list-item>
                      <!-- <v-list-item-avatar tile>                -->
                      <v-btn small color="pop" dark>CC:</v-btn>
                      <!-- </v-list-item-avatar> -->
                      <v-list-item-content class="mx-3" v-for="cc in ViewingEmail.cc" :key="cc.itemObjKey">               
                      {{cc.emailAddress}},
                      </v-list-item-content>                                   
                   </v-list-item>
                   <v-list-item v-if="UserRecord.Email === ViewingEmail.from.emailAddress">
                     <!-- <v-list-item-avatar tile>                -->
                      <v-btn small color="pop" dark>BCC:</v-btn>
                      <!-- </v-list-item-avatar> -->
                      <v-list-item-content class="mx-3" v-for="bcc in ViewingEmail.bcc" :key="bcc.itemObjKey">               
                      {{bcc.emailAddress}},
                      </v-list-item-content>   
                      </v-list-item>
                   <v-list-item>
                       <v-card-title>
                        Subject
                    </v-card-title>
                      <v-list-item-content>             
                      {{ViewingEmail.subject}}
                      </v-list-item-content>
                  </v-list-item>
                  </v-list>
                   </v-layout>
            <v-card-text v-html="ViewingEmail.details">
            </v-card-text>
        </v-card>
    </v-dialog>
                    <v-list>
                        <v-list-item @click="ActivateViewEmailDialog(email)" class="mx-1 noticeoutlined" v-for="email in ActivitiesArray" :key="email.itemObjKey">
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            <v-icon small>mdi-email</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small>mdi-email</v-icon>
                                    
                  <span class="blue--text"> {{email.subject}}</span>
                  <!-- <span class="purple--text"> {{notice.Category.Name}}</span> -->
                </v-list-item-subtitle>
                
                <v-list-item-content class="caption grey--text">
              {{email.body}}
            </v-list-item-content>
                <!-- <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle> -->
            </v-list-item-content>
            <!-- <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar> -->
          </v-list-item>
                      
                    </v-list>
                      
                      <v-layout row class="justify-center">
                      Completed - {{TasksFilteredandSearched.length}}
                      Total - {{ActivitiesArray.length}}
                      
                      </v-layout>                                
</v-card>    
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['Business_Unit','UserRecord','UsersArray','ActivitiesArray','RelatedObj',
    'RecordPrimaryField','RegardingTypefilter','ActivityTypefilter'],   
    data() {
        return {
            ViewingEmail: '',
            ViewEmailDialog: false,
            TaskFilterDate: '',
      TaskDialog: false,
      NewTask: {
            Name: '',
            details: '',
            activitytype: 'Task',
            regardingtype: 'Account',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
      DefaultNewTask: {
            Name: '',
            details: '',
            activitytype: 'Task',
            regardingtype: 'Account',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
            pickerDate: null,
            TaskStatusFilter: 'Open',
            CreatedOnmenu: false,
            DueDatemenu: false,
            TaskFilterDatemenu: false,
            taskheaders: [
            { text: 'Owner', value: 'Owner'},
            { text: 'Name', value: 'Name'},
            { text: 'Date', value: 'duedate'},
            { text: 'Delete', value: 'delete', sortable: false },
            { text: 'Check', value: 'check', sortable: false },
        ],
        search: '',
        }
    },
    computed: {
        ActivitiesSearched() {
      return this.ActivitiesArray.filter(Activity => {
        if(Activity.name){return Activity.name.toLowerCase().includes(this.search.toLowerCase())}})
      
      },
      ActivitiesFilteredandSearched() {
      return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
      },
      TasksFilteredandSearched(){
          return this.ActivitiesFilteredandSearched.filter(activity => {
              return activity.activitytype === 'Task'
          }).filter(activity => {
              if(this.TaskFilterDate){
                  return this.DateFilterString(activity.duedate) === this.FormattedTextDate
              }
              else{
                  return activity
              }
          }).filter(activity => {
              if(this.TaskStatusFilter){
                  return activity.status.includes(this.TaskStatusFilter)
              }
              else{
                  return activity
              }
          })
      },
      TasksFilteredandSearchedTable(){
          return this.TasksFilteredandSearched.filter(activity => {
              return activity.duedate === this.TaskFilterDate
          })
      },
      FormattedPickDate(){
        return this.DateFormatter(this.TaskFilterDate)
      },
      FormattedTextDate(){
          if(this.TaskFilterDate){
        return this.TaskFilterDate.split('-').join('')
          }
      },    
    },
    created(){
        console.log('this.RelatedObj',this.RelatedObj)
        //this.GetEmails()
    },
    methods:{
        ActivateViewEmailDialog(email){
            this.ViewingEmail = email
            this.ViewEmailDialog = true
        },
        GetEmails(){
            db.collection('activities').where('regardingtype','==',this.RelatedObj.Link).where('regardingrecord.id','==',this.$route.params.id).onSnapshot(res => {
                let listlength = res.docs.length
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.Emails.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }
                        if(this.Emails.length === listlength){
                        // console.log(Recordslist)
                        // document.getElementById('selectrecordbox').style.display = "block";
                        // document.getElementById('selectedrecord').oninput = recordselect;
                        // Recordslist.forEach(myrecordFunction);
                        }          
                    })
            })
        },
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
    },
        SaveNewTask(){
      const TasktoAdd = {
            name: this.NewTask.Name,
            details: this.NewTask.details,
            activitytype: 'Task',
            regardingtype: 'Account',
            createdon: new Date(),
            duedate: this.DateFormatter(this.NewTask.duedate),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            regardingrecord: {
              Link:'/Account/'+this.$route.params.id, Name:this.RecordPrimaryField, id: this.$route.params.id
            },
             

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      TasktoAdd.regardingrecord[this.RelatedObj[this.RelatedObj.identifier]] = this.$route.params.id
      if(typeof this.Business_Unit.id !== 'undefined'){
        TasktoAdd.Business_Unitid = this.Business_Unit.id
      }
      db.collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.activitytype === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.name,
              Content: TasktoAdd.details,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname,
                    Date: new Date()
            }
            db.collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },
        DateFilterString(date){      
      let onedate = date.toDate()
      let dateyear = onedate.getFullYear().toString()
      let datemonth = onedate.getMonth()-1+2
      let datemonthfinal = datemonth.toString()
      let dateday = onedate.getDate()
      if(datemonth.toString().length===1){
        let stringdate = dateyear+'0'+datemonth+dateday
      return stringdate
      }
      else{
        let stringdate = dateyear+datemonth+dateday
      return stringdate
      }
    },
    }
}
</script>
     