
     <template>
    <v-content>
        <v-card color="#ffffff" style="position:fixed;z-index:100;" width="100%" flat tile>
                <div style="text-align: center;"><a href="/Login"><img href="/Login" contain height="100px" src="@/assets/logo.png"></a>
              </div>
          </v-card>
        
           <v-toolbar  class="stickytop2" :dark="true" color="#468FBE" width="100%"  :flat="false">
                  <v-toolbar-title>
                    Menu
                    </v-toolbar-title>
                      <v-spacer></v-spacer>
                        <v-layout class="justify-end">
                            <v-list width="100%" dense :dark="true" class="transparent" >
                              <v-list-item>
                                  <v-list-item-content v-for="item in FinalRender" :key="item.itemObjKey">
                                            <v-btn small plain :dark="true" :to="item.Path" v-if="!item.SubItems || !item.SubItems.length" text>{{item.DisplayName}}</v-btn>
                                            <v-menu
                                                v-if="item.SubItems && item.SubItems.length"
                                                bottom
                                                left
                                                offset-y
                                                :close-on-content-click="false"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-btn
                                                    text
                                                    small plain
                                                    class="align-self-center mr-4"
                                                    v-on="on"
                                                    :dark="true"
                                                >
                                                {{item.DisplayName}}
                                                    <v-icon right>mdi-menu-down</v-icon>
                                                </v-btn>
                                                </template>
                                                <v-list dense :dark="false" color="#EEEEEE">
                                                <v-list-item
                                                    v-for="subitem in item.SubItems"
                                                    :key="subitem.itemObjKey"
                                                >                            
                                                    <v-btn small plain width="100%" :dark="false"  :to="subitem.Path" v-if="!subitem.SubChildren || !subitem.SubChildren.length" text>{{subitem.DisplayName}}</v-btn>
                                                    <v-list-group
                                                    v-if="subitem.SubChildren && subitem.SubChildren.length"
                                                  >
                                                    <template v-slot:activator>
                                                      <v-btn small plain width="100%" :dark="false" text>{{subitem.DisplayName.toUpperCase()}}<v-icon>mdi-menu-down</v-icon></v-btn>
                                                    </template>
                                                  
                                                        <v-list-item
                                                            v-for="subsub in subitem.SubChildren"
                                                            :key="subsub.itemObjKey"
                                                            :href="subsub.Path"
                                                            :dark="false" color="#EEEEEE"
                                                        >                                    
                                                            <v-btn small plain width="100%" :dark="false" text>{{subsub.DisplayName.toUpperCase()}}</v-btn>
                                                        </v-list-item>
                                                    </v-list-group>
                                                </v-list-item>
                                                </v-list>
                                            </v-menu>                                        
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-layout>
                      </v-toolbar>

    </v-content>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
  components: {},
  data() {
    return {
      TopLocation: '',
      BusinessLocations: [],
      pubitems: [
        {
          DisplayName:'Home',
          Path:'/',
        },
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  computed: {
     FinalRender(){
         return this.pubitems.map(item => {
             return item
         })
     }
    },

  created() {
    if(this.$route.path.includes('UserInvite')){
              
    }
    else{
      if(this.FinalRender.length === 0){
        this.$router.push('/Login')
      }
      this.GetBusinessLocations()
    }
    
  },

  methods: {
     GetBusinessLocations(){
            db.collection('businessunits').where('IsPublicLocation','==',true).onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                          let buobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        if(buobj.id !== 'Guest'){
                            this.BusinessLocations.push(buobj)
                            if(buobj.Top){
                                this.TopLocation = buobj                       
                            }
                        }
                          
                        }
                    }) 
            })
        },
    
    
  }

}
</script>

<style>
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  position: fixed;
  z-index: 100;
  top: 0px
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 100
}
</style>
    