
<template>
<v-img alt="Access" src="@/assets/accessdeniedBG.png">
<v-container class="container">
        <v-layout class="justify-center">
         <img alt="Access" src="@/assets/access_denied.png">
        </v-layout>
    <v-layout class="justify-center">
        <v-card flat color="rgb(255, 255, 255, 0.05)">
        <h1><v-icon x-large color="red">mdi-account-alert</v-icon>You do not have the permissions to view this page</h1>
        <v-layout class="justify-center">
        <h1><span>ELB</span><span class="font-weight-light"> Equipment</span></h1>
        </v-layout>
        <v-layout class="justify-center">
        <p>To gain access, speak to your System Adminisrator</p>
        </v-layout><br><br>
        <v-layout class="justify-center">
        <p>Powered by</p><h4><span> Rapid</span><span class="font-weight-light">Apps©</span></h4>
        </v-layout>

        </v-card>
    </v-layout>
</v-container>
</v-img>
</template>


<script>


export default {
    data() {
        return {

        }
    }
}
</script>

<style>
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
</style>

    