
// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import firebase from 'firebase';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

let app = '';
// const sql = require('mssql')
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}
const firebaseConfig = {
  apiKey: "AIzaSyCb6vny25sEZv03H56qXwAJR-gjFpJ9e7s",
  authDomain: "nene-motors.firebaseapp.com",
  databaseURL: "https://nene-motors.firebaseapp.com ",
  projectId: "nene-motors",
  storageBucket: "nene-motors.appspot.com",
  messagingSenderId: "771937702576",
  appId: "1:771937702576:web:30a3915e5492c98189a175",
  measurementId: "G-5VQ15W1C39"
};

firebase.initializeApp(firebaseConfig);
//firebase.analytics();
const db = firebase.firestore();
// db.settings({ timestampsInSnapshots: true });



firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
});

export default db;
