
<template>

    <v-card :class="tab.BoxTransparency+' pa-2'" :dark="tab.DarkForm"
                            v-if="tab.Name === 'Web Form'" height="100%" :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding-right:20px;padding-left:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'" >
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                    <v-card-title :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                    <strong>{{tab.FormName}}</strong></v-card-title>
                                    <v-card-text>
                                        <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                    
                                        <span v-for="field in tab.FormFields" :key="field.itemObjKey"><span v-if="field.Type === 'Option Set'">
                                            <v-select  :rules="[rules.required]" v-model="field.Response" :items="field.Options" :label="field.Name" item-text="Name"></v-select>
                                            <v-icon v-if="field.Type === 'Option Set'" @click="ActivateOptionSetDialog(field,tab)">mdi-cogs</v-icon></span><span v-if="field.Type === 'Single Line Text'">
                                            <v-text-field  :rules="[rules.required]" v-model="field.Response" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Number Field'">
                                            <v-text-field  :rules="[rules.required]" v-model="field.Response" type="number" :label="field.Name"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text'">
                                            <v-textarea  :rules="[rules.required]" v-model="field.Response"  :label='field.Name'></v-textarea></span><span v-if="field.Type === 'Lookup'">
                                            <v-autocomplete  :rules="[rules.required]" v-model="field.Response"  :label='field.Name'></v-autocomplete></span><span v-if="field.Type === 'Date'">
                                            <v-menu 
                                        
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="200px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                :label="field.Name"
                                                prepend-icon="mdi-calendar-month"
                                                readonly  :rules="[rules.required]"
                                                v-on="on"
                                                v-model="field.Response"
                                                ></v-text-field>
                                                </template>
                                            <v-date-picker></v-date-picker>
                                            </v-menu></span><span v-if="field.Type === 'Single File Upload'">
                                            <input
                                    
                                        type="file">
                                    <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> --></span></span>
                                        
                                        <v-checkbox
                                        v-model="checkbox"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        label="Do you agree?"
                                        required
                                        ></v-checkbox>
                                        
                                    </v-form>
                                    </v-card-text>
                                <v-card-actions :class="tab.ButtonAlignment">
                                        <v-btn
                                       
                                        color="links"
                                        class="mr-4"
                                        @click="SubmitForm(tab)"
                                        >
                                        Submit
                                        </v-btn>
                                        </v-card-actions>
                                <!-- <v-card-text v-if="tab.HasImage"> -->
                                    <!-- <v-layout :class="tab.Justify" row> -->
                                        <v-card-text>
                                            <v-layout :class="tab.Justify" row>
                                        
                                    <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && tab.IMG"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                            </v-layout>
                                        </v-card-text>
                                   <!-- </v-layout> -->
                                 <!-- </v-card-text> -->
                            
                            <v-card-actions v-if="tab.HasActionButton" >
                                    <v-btn :dark="tab.ActionBtnDark" :style="'background-color:'+tab.ButonColor.hex">
                                        {{tab.ActionButtonName}}
                                    </v-btn>
                                </v-card-actions>
                                </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','PageName','rowindex'],
    components: {

        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        ActivateOptionSetDialog(field,ContentBlockObject){
            this.$emit('ActivateOptionSetDialog',field,ContentBlockObject)
        },
     
       OptionNameSelect(option){
           this.$emit('ActivateOptionSetDialog',option)
       },
        SubmitForm(tab){
            console.log(tab)
            let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry for Landing Page - `+this.PageName+`</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`
                 } 
            })
            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New Enquiry for Landing Page - '+this.PageName,
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    