
<template>
<v-card class="transparent stickytopconscious" flat width="100%" height="100%">

    <v-parallax
            height="150"
            src="@/assets/RABaseBG.jpeg"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">New Blog</h4>
              </v-col>
            </v-row>
            </v-parallax>             
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Blog.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
    <v-flex xs12 s12 m10 lg10>
    <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Blog</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center" style="padding-bottom:50px;">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="BlogTitle">
                </v-text-field>                

                <v-menu
                        v-model="PublishDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="PublishDate">
                        <template v-slot:activator="{ on }">
                          <v-text-field                            
                            v-model="PublishDate"
                            label="Publish Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="PublishDate"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="PublishDate" @input="PublishDatemenu = false"></v-date-picker>
                      </v-menu>
                <v-checkbox label="Is Image Shy" v-model="ImageShy"/>
                <input
                v-if="!ImageShy"
            type="file"
            @change="onblogcoverimageselect($event)"
            ref="coverimageinputter">
           
                </v-card-text>
                <v-layout class="justify-center">
                
          <v-img v-if="coverimagelocalurl" :src="coverimagelocalurl">
          </v-img>
                </v-layout>
          <v-card-text>
              <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text>
            
            <v-card-actions>                
                <v-select multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-card-actions>
            <v-card-actions>
                
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StoreBlog()">
                    Post
                </v-btn>
                
            </v-card-actions>
        </v-card>
        </v-layout>
   
    
   </v-flex>
</v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import ContentEditable from '@/components/System/ContentEditable';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'

export default {
  props: ['userLoggedIn'],
        middleware: 'auth',
      components: {
        ContentEditable
    // quillEditor

        
  },
  
    data() {
        return {
          TagLinkDialog: false,
          UsersArray: [],
          SiteModerators: [],
          Moderators: [],
          PublishDate: '',
          PublishDatemenu: false,
          BlogTitle: '',
          ImageShy: false,
            GroupData: '',
        selectedFile: '',
        ImagelocalURL: '',
        BlogContent: '',
        blogquil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        blogdialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      blogtitle: null,
        blogcontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        groupblogs: [],
        groups: [],
        blogid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        image: '',
        Youtubelink: '',
        rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            }    
        }
    },
    computed: {
      UserIsModerator(){
          if(this.$route.name === 'GroupNewBlog'){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.BlogsModerator
          }
          else{
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Blogs')
              if(moderator){
                return true
              }
              else{
                return false
              }
          }
        },
        NonModeratorRoute(){
          if(this.$route.name === 'GroupNewBlog'){
            return '/Group/'+this.$route.params.id
          }
          else{
            return '/Blogs'
          }
        },
        RouteName(){
          return this.$route.params.id ? this.GroupData.name+' Blog' : 'GP Combrink Vehicle Sales & Finance'
        },
        ModeratorsIDStrings(){
          return this.Moderators.map(mod => {
            mod.id
          })
        },
        ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="560" height="315" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="560" height="315" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
        RelatedBlogs () {
            return this.groupblogs.filter(blog => {
                return blog.blogid === this.blogid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    
    created() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if(this.UserIsModerator){
            this.FocusedViewToggle()
            this.IntranetViewToggle()
            this.GetRequestingUser()
            let moderatorsquery = db.collection('sitemoderators')
            if(!this.$route.params.id){
              this.GetSiteModerators(moderatorsquery)
            }
            else{
              this.GetGroup()
            }
        }
        else{
          this.$router.push(this.NonModeratorRoute)
        }

    },
    
    methods: {
       GetGroup(){
            db.collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                this.GroupData = snapshot.data()
                //let moderatorsquery = db.collection('groupmembers')
                let Moderator = {
                  Full_Name: this.userLoggedIn.Full_Name,
                  Name: this.userLoggedIn.Name,
                  Surname: this.userLoggedIn.Surname,
                  ModerationPermissions: this.userLoggedIn.ModerationPermissions,
                  id: this.userLoggedIn.id
                }
                this.Moderators.push(Moderator)
                this.SiteModerators.push(Moderator)
            })
        },
      ActivateTagLinkDialog(boolean){
        this.TagLinkDialog = boolean  
      },
      taglink(){
        this.TagLinkDialog = true
      },
      SaveSingleTag(tagitem){
        console.log(tagitem)
        this.TagLinkDialog = false  
        document.execCommand("createLink", false, tagitem.taglocation);
      },
      GetSiteModerators(query){
        let Moderator = {
          Full_Name: this.userLoggedIn.Full_Name,
          Name: this.userLoggedIn.Name,
          Surname: this.userLoggedIn.Surname,
          ModerationPermissions: this.userLoggedIn.ModerationPermissions,
          id: this.userLoggedIn.id
        }
        this.Moderators.push(Moderator)
        query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          
        })
      },
   DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
        onblogcoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        
        StoreBlog(){
          let vm = this
          let col = ''
          let routepath = ''
          let routeprop = ''
          let storagepath = ''
          if(this.$route.params.id){
            col = 'groupblogs'
            routepath = '/Group-Blog/'+this.$route.params.id+'/Blog/'
            routeprop = 'title'
          }
          else{
            col = 'siteblogs'
            routepath = '/Blog/'
            routeprop = 'id'
          }
          let ref = db.collection(col).doc()
          let newdocid = ref.id
          vm.$emit('ActivateProcessing',true)
        let postelmnt = document.getElementById('NewBlogValue')
        console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        if(this.selectedcoverimage && this.BlogTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewBlog = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.BlogTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
        }
        if(this.$route.params.id){
          NewBlog.groupid = this.$route.params.id
        }
        let titleQuery = []
        let titleStringArray = NewBlog.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery.length = titleQuery.length-1
          console.log(titleQuery)
          NewBlog.titleQuery = titleQuery
        
        if(this.UserRecord.Profile_Photo){
          NewBlog.creatorimg = this.UserRecord.Profile_Photo
        }
        let filename = this.selectedcoverimage.name.split('.')[0]+'_'+this.selectedcoverimage.size+'.'+this.selectedcoverimage.name.split('.')[1]
        if(this.$route.params.id){
            storagepath = 'GroupBlogs/'+this.$route.params.id+'/CoverImage/'+new Date()+'/'+filename
          }
          else{
            storagepath = 'SiteBlogs/CoverImage/'+new Date()+'/'+filename
          }
        
        this.UpdateBlogCoverImage(this.selectedcoverimage,storagepath).then(function(result) {
            if(result){
                NewBlog.coverimage = result
                NewBlog.CoverStorageRef = storagepath
                 
                vm.$emit('ActivateProcessing',false)
               db.collection(col).add(NewBlog).then(doc => {
                   NewBlog.id = doc.id
                    vm.$router.push(routepath+NewBlog[routeprop])
               })
               console.log(NewBlog.post)
                vm.CancelNewBlogDialog()
            }          
          })  
        }
        else if(this.ImageShy && this.BlogTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewBlog = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.BlogTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          ImageShy: this.ImageShy,
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
        }
        if(this.$route.params.id){
          NewBlog.groupid = this.$route.params.id
        }
        let titleQuery = []
        let titleStringArray = NewBlog.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery.length = titleQuery.length-1
          console.log(titleQuery)
          NewBlog.titleQuery = titleQuery
        
        if(this.UserRecord.Profile_Photo){
          NewBlog.creatorimg = this.UserRecord.Profile_Photo
        }      
          db.collection(col).add(NewBlog).then(doc => {
            NewBlog.id = doc.id
            vm.$router.push(routepath+NewBlog[routeprop])
               })
               console.log(NewBlog.post)          
        }
        else if(!this.selectedcoverimage && this.BlogTitle && postinput){
            alert('You have to upload an image')
        }
        else if(!this.selectedcoverimage && !this.BlogTitle && postinput){
            alert('You have to upload an image, and insert a title')
        }
        else if(!this.selectedcoverimage && !this.BlogTitle && !postinput){
            alert('There is nothing to post!')
        }
        else if(!this.selectedcoverimage && this.BlogTitle && !postinput){
            alert('There is no cover image, or post content!')
        }
        else if(this.selectedcoverimage && !this.BlogTitle && postinput){
            alert('You have to insert a title')
        }
        else if(this.selectedcoverimage && !this.BlogTitle && !postinput){
            alert('You have to insert a title, and content')
        }
        else if(this.selectedcoverimage && this.BlogTitle && !postinput){
            alert('You have to insert content')
        }
        
      
        },


        UpdateBlogCoverImage(picturefile,storagepath){
          let vm = this
          return new Promise(function(resolve, reject) {
          var storageRef = firebase.storage().ref(storagepath);
          var uploadTask = storageRef.put(picturefile);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              
              
              resolve(url)
                  })
                    
          })
        },
        CancelNewBlogDialog(){
            this.BlogTitle = ''
            this.selectedcoverimage = ''
        },
     
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.authorid = userdetails.id

              vm.author = userdetails.Name+' '+userdetails.Surname
              
              console.log('this is user name man '+vm.author)
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
console.log('this is user name man '+this.author)
      

        })
            },
        
      
          
      }
      
    }
// }
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>
  
    

    