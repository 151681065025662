
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  
  

  
<v-dialog v-model="UploadingMultipleFileUpload1File" max-width="500px">
                <v-card min-height="300">
                <v-img
                width="500"
                src="@/assets/RABaseBG-Soft.png"
                ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                  <v-card-title class="mediumoverline white--text">
                    Uploading...
                  </v-card-title></div>
                    <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                      <v-layout row class="justify-center mx-3 my-3">
                          <ul>
                            <progress id="multiplefileupload1progressbar" max="100" value="0"></progress>
                            <p style="font-size:16px;color:blue;" id="multiplefileupload1progresstext">0% complete</p>
                          </ul>
                      </v-layout>
                    </v-row>
                    </v-img>
                 </v-card>
               </v-dialog>
<v-dialog v-model="EditingMultipleFileUpload2Dialog" max-width="500px">
  <v-card>
      <v-img
      width="500"
      src="@/assets/RABaseBG-Soft.png"
      ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
        <v-card-title class="mediumoverline white--text">
          Editing MultipleFileUpload2
        </v-card-title></div>
        <v-card-text>
        <v-select v-model="MultipleFileUpload2EditOption" :items="['Move out of Record','Destroy']">
        </v-select>
      </v-card-text>
      <v-card-actions class="white documenttypeshadowinvert">
        <v-btn  color="orange" dark class="overline" @click="CancelEditingMultipleFileUpload2Dialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
            <v-btn  color="green" dark class="overline" @click="ProcessEditingMultipleFileUpload2Dialog()">Process</v-btn>
      </v-card-actions>
      </v-img>
      </v-card>
      </v-dialog>
    
  <v-dialog width="400" v-model="ChangeStatusDialog">
          <v-card width="400">
            <v-card-title>Change Status</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Status"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
             v-if="Status"
            :items="Status.Options"
            v-model="Status_Reason"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="ViewFileDialog" fullscreen>
        <v-card tile class="white" flat>
          <v-btn @click="CloseViewFileDialog()" style="z-index:2;">Close</v-btn>
          <embed v-if="ViewFileURL" :height="ImageHeight-25" :width="ImageWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="ImageHeight-25" :width="ImageWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>

      <v-toolbar tile dark :color="Status && Status.ID === 1000001 ? 'accent':'warning'" class="stickytopbanner2" width="100%" id="recordtoolbar" v-if="!WikiMode">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item @click="ChangeStatusDialog = !ChangeStatusDialog" class="listoutline">
            <v-list-item-title  class="subtleoverline">
              {{StatusToggleName}}
            </v-list-item-title>
          <v-list-item-action>
              <v-icon class="actionicon" color="white">mdi-power</v-icon>
            </v-list-item-action>
          </v-list-item>
        <v-list-item @click="UpdateCurrentCustomer()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditCurrentCustomer()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="AssignProcess()"  class="listoutline">
      <v-list-item-title  class="subtleoverline">
        Assign Process
      </v-list-item-title>
    <v-list-item-action>
        <v-icon class="actionicon" color="warning">mdi-swap-vertical-variant</v-icon>
      </v-list-item-action>
    </v-list-item>
          <v-list-item @click="WikiMode = !WikiMode" v-if="!WikiMode && CanEdit && userIsWikiModerator"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Switch to Wiki
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-wikipedia</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="WikiMode = !WikiMode" v-if="WikiMode"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Switch to Data
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="accent">mdi-database</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="WikiMode" @click="PublishWikiPage()"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Publish Wiki
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-publish</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title>
   <v-list width="100%" class="transparent">
   <v-list-item>
  <v-list-item-content >
    <span class="headline"><span class=" hidden-sm-and-down">Current Customer - </span><span class="headline font-weight-light">{{ Name  }}</span></span>                   
    <v-list-item-subtitle v-if="Status">
       {{Status.Name}}
    </v-list-item-subtitle>
  </v-list-item-content>
  </v-list-item>
    </v-list>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
     <v-parallax class="recordparallax" v-if="AppisDarkMode && !WikiMode"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
        >               
        </v-parallax>
        <v-parallax class="recordparallax" v-if="!AppisDarkMode && !WikiMode"
        height="150"
            src="@/assets/RASolidA.jpeg"
        >               
        </v-parallax>
  <LookupComponent :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
    <div v-if="!WikiMode">
    
    
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
  <v-icon style="background-color:green;" @click="UpdateCurrentCustomer()" color="white" class="soloactionicon">mdi-content-save</v-icon>
  </v-layout>
  

  
  
    

  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Current Customer help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingCurrentCustomer" tile  width="100%" class="stickytopbanner3" id="recordownershipbanner1">
  <v-layout row class="justify-start" id="recordbanner">
      <v-col v-if="Owner">
        <strong>Owner: </strong>{{ Owner.Name+' '+Owner.Surname}}
      </v-col>
    </v-layout>    
</v-card>
<v-card v-if="EditingCurrentCustomer" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">CurrentCustomer - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Name" label="Name" />
  </v-card-text>
  </v-img>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <strong class="accent--text">Owner:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" @change="AssignBU()" v-model="Owner" return-object></v-autocomplete>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card> 
  
  
  

  

  

    <v-dialog v-model="SelectProcessDialog" max-width="400">
  <v-card>
    <v-card-title>
      Assign Process
    </v-card-title>
    <v-select return-object @change="AssignProcessConfirm()" v-model="ActiveProcess" item-text="DisplayName" :items="EntityProcesses" label="Process"></v-select>
  </v-card>
</v-dialog>
    
    <v-stepper v-model="StageID"  :dark="AppisDarkMode">

      <v-stepper-header>
        <span v-for="(stage,index) in ProcessStages" :key="stage.Number">
          <v-stepper-step  editable :step="index-1+2" :complete="IsComplete(stage)" @click="updateStatus(stage)">{{stage.Name}}
          </v-stepper-step>
          <v-divider></v-divider></span>          
      </v-stepper-header>
     <v-row class="justify-space-between mx-3" v-if="StageID && ProcessStages && ProcessStages[StageID-1] && ProcessStages[StageID].Steps-1">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(0,3)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(3,6)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(6,9)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          sm="6 "
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(9,12)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
    </v-row>

  </v-stepper>
  
   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
          <v-card height="80px" flat class="transparent">
          </v-card>
                
      
          <v-layout row class="justify-start mx-3"><span class="secondary--text headline" id="Basic"></span></v-layout>
 
           <div class="flex row xl12 lg12 md12 sm12" v-if="!EditingCurrentCustomer">
        <v-col style="padding-bottom:40px;" class="my-5 slightmargin" cols="12" sm="12">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                <v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection1 = !EditingSection1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Name</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Name}}</h4>
                      
                      
                      
                    <span v-if="EditingSection1"><v-text-field id="Name"  v-model="Name"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">VIN Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{VIN_Number}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="VIN_Number"  v-model="VIN_Number"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Engine Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Engine_Number}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Engine_Number"  v-model="Engine_Number"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Registration Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Registration_Number}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Registration_Number"  v-model="Registration_Number"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Mileage</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Mileage}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Mileage" type="number" v-model="Mileage"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Brand</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Brand}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Brand"  v-model="Brand"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Model</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Model}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Model"  v-model="Model"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Price</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Price}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Price" type="number" v-model="Price"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Client</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <router-link :to="'/User/'+Client.id" v-if="Client"><h4 v-if="!EditingSection1" class="light-blue--text">{{Client.Full_Name}}</h4></router-link><a v-if="!Client && HasClient" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedClient.Full_Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedClient.Full_Name }}"</p></a>
                    <span v-if="EditingSection1"><v-combobox
                         id="Client"
                        v-if="Client || !Client && !HasClient"
                        v-model="Client"
                        :items="UsersArray"
                        item-text="Full_Name"
                        @change="HasClient = false"
                         label="Client"
                        small-chips
                        solo
                        flat
                        >
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingClient()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewClientDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template></v-combobox>
                        <div  class="unpermitted" v-if="!Client && HasClient">
                        <v-combobox
                         id="Client"
                        v-model="UnpermittedClient"
                        readonly
                        :items="ClientArray"
                        item-text="Full_Name"
                        hide-selected
                         label="Client"
                        small-chips
                        solo
                        flat
                        ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedClient.Full_Name }}", and therefore cannot change the linked record</p></div>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3" id="Delivery_Photo">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Delivery Photo</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      
                    <v-avatar
                    v-if="EditingSection1"
                      color="grey"
                      size="150" 
                      tile>
                      <input
                      style="display: none"
                      type="file"
                      @change="onDelivery_PhotoFileselected($event)"
                      ref="Delivery_PhotofileInputer">
                        <v-btn v-if="EditingSection1 && Delivery_PhotolocalURL" @click="onDelivery_PhotoUpload()" class="green white--text" icon small absolute bottom right><v-icon>mdi-content-save</v-icon></v-btn>
                        <v-btn v-if="EditingSection1 && Delivery_PhotolocalURL" @click="closeDelivery_Photo()" class="red white--text" icon small absolute bottom left><v-icon>mdi-close</v-icon></v-btn>
                        <v-btn v-if="EditingSection1" @click="$refs.Delivery_PhotofileInputer.click()" class="blue white--text" icon small absolute top right><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn v-if="!EditingSection1" @click="GetShortLivedURL(Delivery_Photo)" class="blue white--text" icon small absolute top right><v-icon>mdi-eye</v-icon></v-btn>
                        <img v-if="Delivery_PhotolocalURL" :src="Delivery_PhotolocalURL" height="150px">
                        <img v-if="Delivery_Photo && !Delivery_PhotolocalURL" :src="CurrentCustomerData.Delivery_PhotoThumbURL" height="150px">
                        <img v-if="!Delivery_Photo && !Delivery_PhotolocalURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px">
                        <img v-if="!Delivery_Photo && !Delivery_PhotolocalURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px">
                    </v-avatar>
                    <span v-if="!EditingSection1">
                  <v-hover v-if="CurrentCustomerData.Delivery_PhotoThumbURL"
                        v-slot:default="{ hover }"
                        open-delay="200"
                      >
                      <v-card class="mx-auto transparent"  @click="GetShortLivedURL(Delivery_Photo)"
                      flat tile>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                            style="height: 100%;"
                          >
                            View
                          </div>
                        </v-expand-transition>
                        <v-img :src="CurrentCustomerData.Delivery_PhotoThumbURL" height="150px" contain/>
                      </v-card>
                  </v-hover>
                  <v-img v-if="!CurrentCustomerData.Delivery_PhotoThumbURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px" contain/>
                  <v-img v-if="!CurrentCustomerData.Delivery_PhotoThumbURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px" contain/>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">ID Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{ID_Number}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="ID_Number"  v-model="ID_Number"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Bank</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Bank}}</h4>                      
                    <span v-if="EditingSection1">
                      <v-combobox
                         id="Bank"
                        v-model="Bank"
                        :items="BankOptions"
                        item-text="Name"
                        hide-selected
                        color="red" :rules="[rules.required]" label="Bank"
                        small-chips
                        solo
                        flat
                        ></v-combobox>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Dealer</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <router-link :to="'/Dealership/'+Dealer.id" v-if="Dealer"><h4 v-if="!EditingSection1" class="light-blue--text">{{Dealer.Dealer_Name}}</h4></router-link><a v-if="!Dealer && HasDealer" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedDealer.Dealer_Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedDealer.Dealer_Name }}"</p></a>
                    <span v-if="EditingSection1"><v-combobox
                         id="Dealer"
                        v-if="Dealer || !Dealer && !HasDealer"
                        v-model="Dealer"
                        :items="DealerArray"
                        item-text="Dealer_Name"
                        @change="HasDealer = false"
                        color="red" :rules="[rules.required]" label="* Dealer"
                        small-chips
                        solo
                        flat
                        >
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingDealer()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewDealerDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template></v-combobox>
                        <div  class="unpermitted" v-if="!Dealer && HasDealer">
                        <v-combobox
                         id="Dealer"
                        v-model="UnpermittedDealer"
                        readonly
                        :items="DealerArray"
                        item-text="Dealer_Name"
                        hide-selected
                        color="red" :rules="[rules.required]" label="* Dealer"
                        small-chips
                        solo
                        flat
                        ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedDealer.Dealer_Name }}", and therefore cannot change the linked record</p></div>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Client Mobile</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col> 
                       <a :href="'callto:'+Client_Mobile" target="_top">{{Client_Mobile}}</a>
                        <span v-if="EditingSection1"><v-text-field id="Client_Mobile" label="Client Mobile" :rules="[rules.telnr]" v-model="Client_Mobile"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Vehicle Color</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Vehicle_Color}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Vehicle_Color"  v-model="Vehicle_Color"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Client Email</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                     <a :href="'mailto:'+Client_Email" target="_top">{{Client_Email}}</a>
                        <span v-if="EditingSection1"><v-text-field id="Client_Email" label="Client Email" :rules="[rules.email]" v-model="Client_Email"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Date of Purchase</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Date_of_Purchase}}</h4>
                      
                    <span v-if="EditingSection1">
                      <v-menu
                        v-model="Date_of_Purchasemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field      
                            id="Date_of_Purchase"                      
                            v-model="Date_of_Purchase"
                            label="Date of Purchase"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="Date_of_Purchase" @input="Date_of_Purchasemenu = false"></v-date-picker>
                      </v-menu>
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
            </v-card>
          </v-col>
          </div>
    
          
          
        
          
          <v-card height="80px" flat class="transparent">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Files">    
              <v-card-title class="align-center accent white--text">
                <span class="headline">Files</span>
              </v-card-title>
            </v-card>
             <v-card color="rgba(255,255,255,0.6)" outlined>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
            
              <v-dialog v-model="MultipleFileUpload1dialog" max-width="500px">
               <v-card>
                  <v-img
                  width="500"
                  src="@/assets/RABaseBG-Soft.png"
                  ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                    <v-card-title class="mediumoverline white--text">
                      Register Application Documents
                    </v-card-title></div>
                    <v-form ref="Enquiriesform" id="Enquiriesform">
                      <v-card-text>
                            <v-select  :rules="[rules.required]" :items="MultipleFileUpload1PrimaryOptions"
                            label="Select Primary Type" v-model="editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption" item-text="Name" return-object>
                            </v-select>
                            <input  v-if="!editedMultipleFileUpload1Item.fileurl"
                            type="file"
                            :multiple="true"
                            @change="onMultipleFileUpload1Fileselected($event)"
                            ref="MultipleFileUpload1fileInputer"
                            id="MultipleFileUpload1InputElement"
                            >
                            <v-btn icon @click="GetShortLivedURL(editedMultipleFileUpload1Item)" v-if="editedMultipleFileUpload1Item.fileurl">View
                                </v-btn>
                        </v-card-text>
                      <v-card-actions class="white documenttypeshadowinvert">                  
                      <v-btn color="orange" dark class="overline" @click="closeMultipleFileUpload1dialog()">Cancel</v-btn>
                        <v-spacer></v-spacer>
                      <v-btn color="green" dark  class="overline" @click="saveMultipleFileUpload1Doc()" v-if="selectedMultipleFileUpload1File || editedMultipleFileUpload1Item.fileurl">Save</v-btn>
                    </v-card-actions>
                    </v-form>
                    </v-img>
                </v-card>
            </v-dialog>
         
            <v-tabs background-color="transparent"  color="white" style="padding-bottom:40px;">
              <v-tab class="foldertab"><span class="hidden-sm-and-down">Application Documents</span><span class="hidden-md-and-up">Multiple File U</span></v-tab>
           <v-tab-item class="paddedtab">
            <v-card flat min-height="200px"  :dark="AppisDarkMode">
                <v-layout row class="justify-center mx-1">
                  <v-card flat width="100%">
                      <v-data-table 
                      :dark="AppisDarkMode"          
                      :headers="MultipleFileUpload1Headers"           
                      :items="MultipleFileUpload1withIcon"            
                      class="elevation-1" 
                      :show-select="SelectingMultipleFileUpload1"   
                      v-model="MultipleFileUpload1Selected"   
                      item-key="Name" 
                      single-expand
                      show-expand  
                      v-if="!UpdatingMultipleFileUpload1Table"
                      >           
                        <template v-slot:top>           
                          <v-toolbar flat color="blue darken-4 white--text">            
                              <v-toolbar-title>Upload</v-toolbar-title>           
                              <v-divider            
                              class="mx-4"            
                              inset           
                              vertical            
                              ></v-divider>           
                                      
                              <v-spacer></v-spacer>                                           
                              <v-menu> 
                              <template v-slot:activator="{ on }">            
                                  <v-icon  v-on="on" dark  id="NewMultipleFileUpload1Btn">mdi-dots-vertical</v-icon>            
                              </template>
                              <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
                                <v-list-item class="listoutline"  @click="MultipleFileUpload1dialog = !MultipleFileUpload1dialog"  v-if="CanCreateMultipleFileUpload1">
                                    <v-list-item-title class="subtleoverline">
                                      New Application Documents
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item  class="listoutline" v-if="!SelectingMultipleFileUpload1" @click="SelectingMultipleFileUpload1 = !SelectingMultipleFileUpload1">
                                    <v-list-item-title class="subtleoverline">
                                      Select to Remove
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="green">mdi-check</v-icon>
                                    </v-list-item-action>
                                  </v-list-item> 
                                  <v-list-item v-if="SelectingMultipleFileUpload1" @click="SelectingMultipleFileUpload1 = !SelectingMultipleFileUpload1,MultipleFileUpload1Selected = []">
                                  <v-list-item-title class="subtleoverline">
                                      Cancel Select
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="red">mdi-cancel</v-icon>
                                    </v-list-item-action>
                                    </v-list-item>
                                  <v-list-item  class="listoutline" v-if="SelectingMultipleFileUpload1 && MultipleFileUpload1Selected.length>0"  @click="ConfirmMultipleFileUpload1TableTransfer()">
                                    <v-list-item-title class="subtleoverline">
                                      Remove from Table
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="green">mdi-check</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>  
                              </v-list>
                             </v-menu>           
                          </v-toolbar>            
                          </template> 
                         <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding-top:10px;padding-bottom:10px;">
                            <b>File Name and Type:</b> <v-spacer></v-spacer>{{item.Name}}<br>
                             <b>File Type: </b>  <v-spacer> </v-spacer>{{item.FileType}} <br>
                            <b>Primary Classification:</b> <v-spacer></v-spacer> {{item.MultipleFileUpload1PrimarySelectedOption}}<br>
                            <b>Original Date:</b> <span v-if="item.ModifiedDate && item.ModifiedDate.toDate()">{{item.ModifiedDate.toDate()}}</span><span v-if="item.ModifiedDate && !item.ModifiedDate.toDate()">{{item.ModifiedDate}}</span> prior to upload.<br>
                            <b>Upload Details: </b> <span v-if="item.Created_By">{{item.Created_By.Full_Name}}</span> on <span v-if="item.Created_On && item.Created_On.toDate">{{item.Created_On.toDate()}}</span><span v-if="item.Created_On && !item.Created_On.toDate">{{item.Created_On}}</span><br>
                            <b>Modification Details: </b><span v-if="item.Modified_By">{{item.Modified_By.Full_Name}}</span> on <span v-if="item.Modified_On && item.Modified_On.toDate">{{item.Modified_On.toDate()}}</span><span v-if="item.Modified_On && !item.Modified_On.toDate">{{item.Modified_On}}</span>
                          </td>
                        </template>
                          <template v-slot:[`item.MultipleFileUpload1PrimarySelectedOption`]="{ item }">
                            <v-chip color="warning" v-if="item.MultipleFileUpload1PrimarySelectedOption">{{ Documentoptions(item.MultipleFileUpload1PrimarySelectedOption,MultipleFileUpload1PrimaryOptions) }}</v-chip>
                          </template>
                          <template v-slot:[`item.FileType`]="{ item }">
                            <v-chip color="secondary" v-if="item.FileType"><v-icon :color="item.FileTypeIconColor">{{item.FileTypeIcon}}</v-icon>{{ item.FileType.split('/')[1].substr(0,20) }}</v-chip>
                          </template>
                          <template v-slot:[`item.action`]="{ item }">            
                            <v-icon           
                            small           
                            class="mr-2"  
                             v-if="CanEditMultipleFileUpload1"          
                            @click="editMultipleFileUpload1Item(item)"            
                            >           
                                mdi-pencil            
                            </v-icon>           
                            <v-icon           
                                small 
                                 v-if="CanDeleteMultipleFileUpload1"          
                                @click="deleteMultipleFileUpload1Item(item)"           
                            >           
                                mdi-delete            
                            </v-icon>           
                            <v-btn x-small icon  @click="GetShortLivedURL(item)" v-if="CanGetMultipleFileUpload1">
                              <v-icon color="grey lighten-1">mdi-eye</v-icon>
                            </v-btn>            
                          </template>         
                          </v-data-table>
                    </v-card>

                </v-layout>

             </v-card>
            </v-tab-item>
              <v-tab class="foldertab"><span class="hidden-sm-and-down">Vehicle Photos</span><span class="hidden-md-and-up">Multiple File U</span></v-tab>
           <v-tab-item class="paddedtab">
            <v-card flat min-height="200px"  :dark="AppisDarkMode">
            <v-btn v-if="CanEditMultipleFileUpload2 && !EditingMultipleFileUpload2" @click="EditingMultipleFileUpload2 = !EditingMultipleFileUpload2"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-pencil</v-icon></v-btn><v-btn v-if="EditingMultipleFileUpload2" @click="EditingMultipleFileUpload2 = !EditingMultipleFileUpload2,CancelEditingMultipleFileUpload2Dialog()"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-cancel</v-icon></v-btn>
                        
                      <v-dialog v-model="UploadingMultipleFileUpload2" max-width="500px">
                   <v-card width="100%" min-height="300">
                          <v-img
                        width="500"
                        src="@/assets/RABaseBG-Soft.png"
                        ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                          <v-card-title class="mediumoverline white--text">
                            Uploading...
                          </v-card-title></div>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="multiplefileupload2progressbar" style="display:none">
                            <ul id="multiplefileupload2progressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                      </v-img>
                   </v-card>
                 </v-dialog>
              <v-pagination
                v-model="MultipleFileUpload2Page"
                :length="MultipleFileUpload2ImagesUploads.length/12"
                :total-visible="7"
              ></v-pagination>
              <v-dialog v-model="MultipleFileUpload2SlideShowDialog" max-width="800">
              <v-carousel v-model="MultipleFileUpload2SlideShow">
                  <v-carousel-item
                    v-for="(card,i) in MultipleFileUpload2ImagesUploadsPagination"
                    :key="card.itemObjKey"
                  >
                    <v-sheet
                      :color="color"
                      height="100%"
                      tile
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="display-3">
                          Image {{ i + 1 }}
                          <v-img :src="card.url" contain max-height="500"/>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
            </v-dialog>
            <v-layout class="justify-center">
            <input
                style="display: none"
                type="file"
                @change="AddMultipleFileUpload2($event)"
                ref="MultipleFileUpload2fileInputer"
                :multiple="true"
                accept="image/*"
                >
                <v-btn @click="$refs.MultipleFileUpload2fileInputer.click()" outlined  small class="elevation-6">
                  <v-icon>mdi-plus-thick</v-icon>Add Images</v-btn>

              <v-btn @click="MultipleFileUpload2SlideShowDialog = true" outlined  small class="elevation-6"><v-icon>mdi-file-presentation-box</v-icon>SlideShow</v-btn>
            </v-layout>
              <v-layout row class="align-center mx-3">
                 <v-col
                v-for="(card,i) in MultipleFileUpload2ImagesUploadsPagination"
                :key="card.itemObjKey"
                class="d-flex child-flex"
                :cols="GetCols(2)"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-auto"  @click="OpenorEditingMultipleFileUpload2(card,i)"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.ThumbURL"
                    :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover && !EditingMultipleFileUpload2"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                     <div
                      v-if="hover && EditingMultipleFileUpload2"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      Edit
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
                </v-layout>

             </v-card>
            </v-tab-item>
           </v-tabs>
          </v-card>       
    
          
        
          
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
  </v-layout>
  
  </div>
          <v-layout v-if="WikiMode" style="margin-top:-50px;">
  <v-card flat width="100%">
  <v-btn @click="WikiMode = !WikiMode" class="my-1">
            <v-icon >mdi-keyboard-return</v-icon>
            Return to Data
            <v-icon color="accent">mdi-database</v-icon>
          </v-btn>
          <v-btn  @click="PublishWikiPage()" class="my-1">
            Publish Wiki
            <v-icon color="green">mdi-publish</v-icon>
          </v-btn>
       
        <v-card class="transparent" width="100%" flat>
            <v-parallax
            height="250"
            :src="Delivery_Photo"
            >
               <div class="delivery_photocontentgrad">
              <v-row
              align="center"
              justify="center"
            >
              <v-col class="text-center" cols="12">
                <h1 class="display-1 font-weight-thin mb-4">My Vehicle</h1>
                <h4 class="subheading"></h4>
              </v-col>
            </v-row>
            </div>
            </v-parallax>      
      </v-card>
       
        <v-card class="transparent" width="100%" flat>
            <v-layout row wrap class="justify-center mx-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-top:40px;">
              
              <v-dialog width="800">
                  <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  Submit Claim
                </v-btn>
                  </template>
                <v-card max-width="800" >
                <v-expand-transition>
                    <div
                        v-if="FormProcessing"
                        :class="'d-flex transition-fast-in-fast-out '+ThemeColor+' v-card--reveal mediumoverline white--text'"
                        style="height: 100%;"
                    >
                        <v-progress-circular size="130"
                        :width="5"
                        :color="ThemeHeaderColor"
                        indeterminate
                        >Processing</v-progress-circular>
                    </div>
                    </v-expand-transition>
                <!--<WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :RelatedEntity="'claims'"
                      :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"
                      @ConfirmSubmission="ConfirmSubmission" :PageName="'Claim Form'" @ProcessingData="ProcessingData"
                      :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="0"
                      :tab="MappedClaim_FormTab" :i="0"
                      />-->
                </v-card>
                </v-dialog>
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%" flat>
            <v-layout row wrap class="justify-center mx-3">
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-top:40px;">
              
              <v-img
              class="mx-3 my-3" 
              contain
              :src="CurrentCustomerData.Delivery_PhotoThumbURL"
              lazy-src="https://picsum.photos/350/165?random"
              max-height="150"
            ><template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="pop"></v-progress-circular>
                </v-row>
              </template>
              </v-img>
          </v-flex>
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-top:40px;">
              
                <v-chip class="my-1" large color="links white--text" v-if="!EditingClient && Client">{{Client.Full_Name}}</v-chip><br> 
                   
              
                <v-chip class="my-1" large color="links white--text" v-if="!EditingBrand">{{Brand}}</v-chip><br><v-icon @click="EditingBrand = !EditingBrand">mdi-pencil</v-icon> 
                   <v-text-field v-model="Brand" label="Brand"></v-text-field>
              
                <v-chip class="my-1" large color="links white--text" v-if="!EditingModel">{{Model}}</v-chip><br><v-icon @click="EditingModel = !EditingModel">mdi-pencil</v-icon> 
                   <v-text-field v-model="Model" label="Model"></v-text-field>
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%" flat>
            <v-layout row wrap class="justify-center mx-3">
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-top:40px;">
               <v-layout row  v-if="!EditingVIN_Number">
                <p class="mediumoverline mx-1 my-3">VIN Number</p><v-icon @click="EditingVIN_Number = !EditingVIN_Number">mdi-pencil</v-icon>
                </v-layout>  
                <v-layout row v-if="EditingVIN_Number">
                <v-icon @click="EditingVIN_Number = !EditingVIN_Number" color="red" dark>mdi-cancel</v-icon><v-icon color="green" dark @click="saveVIN_Number(),  EditingVIN_Number = !EditingVIN_Number">mdi-content-save</v-icon>
                </v-layout> 
                   <v-text-field v-model="VIN_Number" label="VIN Number"></v-text-field>    
          <p v-if="!EditingVIN_Number">{{VIN_Number}}</p>
               <v-layout row  v-if="!EditingEngine_Number">
                <p class="mediumoverline mx-1 my-3">Engine Number</p><v-icon @click="EditingEngine_Number = !EditingEngine_Number">mdi-pencil</v-icon>
                </v-layout>  
                <v-layout row v-if="EditingEngine_Number">
                <v-icon @click="EditingEngine_Number = !EditingEngine_Number" color="red" dark>mdi-cancel</v-icon><v-icon color="green" dark @click="saveEngine_Number(),  EditingEngine_Number = !EditingEngine_Number">mdi-content-save</v-icon>
                </v-layout> 
                   <v-text-field v-model="Engine_Number" label="Engine Number"></v-text-field>    
          <p v-if="!EditingEngine_Number">{{Engine_Number}}</p>
              <p class="mediumoverline mx-1 my-3">Dealer</p> 
                       
          <p v-if="Dealer" >{{Dealer.Dealer_Name}}</p>
          </v-flex>
                <v-flex lg4 md5 sm8 xs12 class="mx-2" style="padding-top:40px;">
              
              <v-layout row class="fill-height align-center mx-2">
                   <v-list two-line subheader light align="left" width="100%">                     
                      <v-list-item v-for="item in Multiple_File_Upload_1" :key="item.itemObjKey" class="basicoutlined">
                        <v-layout row class="justify-start">
                          <v-list-item-avatar>
                            <v-icon>mdi-file-document</v-icon>
                            </v-list-item-avatar>
                           <v-list-item-content>
                                <v-list-item-title>{{item.MultipleFileUpload1PrimarySelectedOption.Name}}</v-list-item-title>
                            </v-list-item-content>                             
                              <v-list-item-action>                             
                                    <v-btn icon :href="item.fileurl" target="_New">
                                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                                  </v-btn>                              
                              </v-list-item-action>                           
                          </v-layout>
                        </v-list-item>
                    </v-list>
                </v-layout>
          </v-flex>          
          </v-layout>      
      </v-card>
       <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  </v-card>
     </v-layout>
    
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import LookupComponent from '@/components/LookupComponent'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
components: {
  RMAdministration,
  SystemTicketsLogging,
  LookupComponent,
},
    

  data() {
    return {
      ViewFileDialog: false,
      ViewFileURL: '',
      ViewIMGURL: '',
      ChangeStatusDialog: false,
  
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      CustomCreateRoles: [
  "Office Administrator"
],
      CustomGetRoles: [
  "Office Administrator"
],
      CustomListRoles: [
  "Office Administrator"
],
      CustomUpdateRoles: [
  "Office Administrator"
],
      CustomdDeleteRoles: [
  "Office Administrator"
],
      
      selectedEntity: {Name: 'Current Customers',
      PrimaryField: 'Name',
      Tables: [						
          {						
          Name: 'Multiple File Upload 1',
          PrimaryOptions: 
            [
  {
    "ID": 1000001,
    "Name": "Dekra Document"
  },
  {
    "Name": "Signed Contract",
    "ID": 1000002
  },
  {
    "Name": "Roadworthy",
    "ID": 1000003
  },
  {
    "Name": "Delivery Inspection",
    "ID": 1000004
  },
  {
    "Name": "Delivery Photo",
    "ID": 1000005
  },
  {
    "Name": "NATIS Document",
    "ID": 1000006
  },
  {
    "Name": "ID Document",
    "ID": 1000007
  }
]
          ,	
          Type: 'Document Register'					
          },						
          {						
          Name: 'Multiple File Upload 2',	
          Type: 'Images'					
          },
          ]
      },
      selectedRecord: {},						
      CanCreateMultipleFileUpload1: false,
      CanGetMultipleFileUpload1: false,
      CanListMultipleFileUpload1: false,
      CanEditMultipleFileUpload1: false,
      CanDeleteMultipleFileUpload1: false,						
      CanCreateMultipleFileUpload2: false,
      CanGetMultipleFileUpload2: false,
      CanListMultipleFileUpload2: false,
      CanEditMultipleFileUpload2: false,
      CanDeleteMultipleFileUpload2: false,
      MultipleFileUpload2Page: 1,
      MultipleFileUpload2SlideShow: 1,
      MultipleFileUpload2SlideShowDialog: false,
      MultipleFileUpload2ImagesUploads: [],
      Application1000001: '',
      ActiveProcess: '',
      ActiveProcessDocid: '',
      EntityProcesses: [
  {
    "Type": "Process",
    "id": "1000001",
    "IsHeader": true,
    "DisplayName": "Application",
    "Number": 1000001,
    "ProcessStageInteger": 1000001,
    "Automations": [],
    "Name": "Application",
    "ProcessStages": [
      {
        "Automations": [],
        "Type": "Stage",
        "IsHeader": true,
        "Steps": [],
        "Number": 1000001,
        "Name": "Executing"
      }
    ]
  }
],
      SelectProcessDialog: false,
      
      BannerTabs: [
        ],
      EditingSection1: false,
      EditingMultipleFileUpload1: false,
      EditingMultipleFileUpload2: false,
      TicketLog: false,
        WikiData: '',
      WikiMode: false,
      
      EditingBrand: false,
      EditingModel: false,
      EditingVIN_Number: false,
      EditingEngine_Number: false,
      step: 1,
      rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      StageID: 1,
      Brand: '',
      Engine_Number: '',
      ID_Number: '',
      Model: '',
      Name: '',
      Registration_Number: '',
      VIN_Number: '',
      Vehicle_Color: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {
  "ID": 1000003,
  "Name": "Open",
  "LevelFieldName": "Status_Reason",
  "Level": {
    "children": [],
    "id": 2,
    "name": "Status Reason",
    "FieldName": "Status_Reason"
  }
},
      
      Created_On: '',
      Created_Onmenu : false,
      Date_of_Purchase: '',
      Date_of_Purchasemenu : false,
      Client:  {id: '', Full_Name: ''},
      UnpermittedClient:  {id: '', Full_Name: ''},
      HasClient: false,
      ClientRelatedParentObj: {},
      Created_By:  {id: '', Full_Name: ''},
      UnpermittedCreated_By:  {id: '', Full_Name: ''},
      HasCreated_By: false,
      Created_ByRelatedParentObj: {},
      Dealer:  {id: '', Dealer_Name: ''},
      UnpermittedDealer:  {id: '', Dealer_Name: ''},
      HasDealer: false,
      DealerRelatedParentObj: {},
      Owner:  {id: '', Full_Name: ''},
      UnpermittedOwner:  {id: '', Full_Name: ''},
      HasOwner: false,
      OwnerRelatedParentObj: {},
      Bank: '',
      BankOptions: [
        {ID: 1000001, Name: 'Absa'},
        {ID: 1000002, Name: 'MFC'},
        {ID: 1000003, Name: 'Standard Bank'},
        {ID: 1000004, Name: 'Wesbank'},
      ],
      Delivery_Photo: '',
      Client_Email:  '',
      Client_Mobile:  '',
      Mileage: '',
      Price: '',
      ClientFull_Name: null,
      Created_ByFull_Name: null,
      DealerDealer_Name: null,
      OwnerFull_Name: null,
      
        Delivery_Photofilesnackbar: false,
        selectedDelivery_PhotoFile: '',
        Delivery_PhotolocalURL: '',
      Created_Onmenu: false,
      Date_of_Purchasemenu: false,
      
      StatusOptions: [{
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "Options": [
    {
      "ID": 1000003,
      "Name": "Open",
      "LevelFieldName": "Status_Reason",
      "Level": {
        "FieldName": "Status_Reason",
        "id": 2,
        "children": [],
        "name": "Status Reason"
      }
    }
  ],
  "ID": 1000001,
  "Name": "Active",
  "Level": {
    "name": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "children": [],
        "id": 2
      }
    ],
    "FieldName": "Status",
    "id": 1
  }
},{
  "Level": {
    "id": 1,
    "children": [
      {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    ],
    "name": "Status",
    "FieldName": "Status"
  },
  "Name": "InActive",
  "LevelFieldName": "Status",
  "ID": 1000002,
  "Options": [
    {
      "Name": "Closed",
      "ID": 1000004,
      "LevelFieldName": "Status_Reason",
      "Level": {
        "id": 2,
        "children": [],
        "name": "Status Reason",
        "FieldName": "Status_Reason"
      }
    }
  ],
  "HasDeepHierarchy": true
}],
      Statusfilter: '',
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      ClientArray: [],
      Clientfilter: {Full_Name: ''},
      
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      DealerArray: [],
      Dealerfilter: {Dealer_Name: ''},
      
      OwnerArray: [],
      Ownerfilter: {Full_Name: ''},
      
      EditingCurrentCustomer: false,
      EditingMultipleFileUpload2Dialog: false,
      editedMultipleFileUpload2Item: '',
      editedMultipleFileUpload2ItemIndex: -1,
      MultipleFileUpload2EditOption: '',
      
      UploadingMultipleFileUpload2: false,
      MultipleFileUpload2totallist: 0,
      MultipleFileUpload2listuploadcount: 0,
      MultipleFileUpload2uploadTotal: 0,
      MultipleFileUpload2runningpercentage: 0,
      MultipleFileUpload2runningProgress: 0,
      MultipleFileUpload2uploadPercentage: 0,
      MultipleFileUpload1Headers: [
        { text: 'Name', value: 'Name', sortable: true},						
            { text: 'Class', value: 'MultipleFileUpload1PrimarySelectedOption', sortable: true},						
            { text: 'File Type', value: 'FileType', sortable: true },						
            { text: 'Action', value: 'action', sortable: true },
      ],
      SelectingMultipleFileUpload1: false,
      MultipleFileUpload1Selected: [],
      MultipleFileUpload1: [],
      Multiple_File_Upload_1: [],
      UpdatingMultipleFileUpload1Table: false,
      DefaultMultipleFileUpload1path: 'CurrentCustomers/'+this.$route.params.id+'/MultipleFileUpload1/',
      MultipleFileUpload1dialog: false,

      MultipleFileUpload1PrimaryOptions: [
  {
    "ID": 1000001,
    "Name": "Dekra Document"
  },
  {
    "Name": "Signed Contract",
    "ID": 1000002
  },
  {
    "Name": "Roadworthy",
    "ID": 1000003
  },
  {
    "Name": "Delivery Inspection",
    "ID": 1000004
  },
  {
    "Name": "Delivery Photo",
    "ID": 1000005
  },
  {
    "Name": "NATIS Document",
    "ID": 1000006
  },
  {
    "Name": "ID Document",
    "ID": 1000007
  }
],
      selectedMultipleFileUpload1File: '',
      UploadingMultipleFileUpload1File: false,
      MultipleFileUpload1totallist: 0,
      MultipleFileUpload1listuploadcount: 0,
      MultipleFileUpload1uploadTotal: 0,
      MultipleFileUpload1runningpercentage: 0,
      MultipleFileUpload1runningProgress: 0,
      MultipleFileUpload1uploadPercentage: 0,
      editedMultipleFileUpload1Item: {
      MultipleFileUpload1PrimarySelectedOption: '',
      },
      DefaultMultipleFileUpload1Item: {
      MultipleFileUpload1PrimarySelectedOption: '',
      },
      editedMultipleFileUpload1Index: -1,
      dialog: false,
      initialize: '',
      search: '',
      CurrentCustomerData: {},
      headers: [
      
        
        { text: 'Brand', value: 'Brand'},
        
        
        { text: 'Engine Number', value: 'Engine_Number'},
        
        
        { text: 'ID Number', value: 'ID_Number'},
        
        
        { text: 'Model', value: 'Model'},
        
        
        { text: 'Name', value: 'Name'},
        
        
        { text: 'Registration Number', value: 'Registration_Number'},
        
        
        { text: 'VIN Number', value: 'VIN_Number'},
        
        
        { text: 'Vehicle Color', value: 'Vehicle_Color'},
        
        
        { text: 'Status', value: 'Status.Name'},
        
        
        { text: 'Date of Purchase', value: 'Date_of_Purchase'},
        
        
        
        { text: 'Dealer', value: 'Dealer.Dealer_Name'},
        
        
        { text: 'Owner', value: 'Owner.Full_Name'},
        
        { text: 'Client Email', value: 'Client_Email'},
        
        
        { text: 'Client Mobile', value: 'Client_Mobile'},
        
        
        { text: 'Mileage', value: 'Mileage'},
        
        
        { text: 'Price', value: 'Price'},
        
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedCurrentCustomersnackbar: false,
      CurrentCustomerEditdialog: false,
      newCurrentCustomerdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
this.GetUsers()
window.addEventListener('scroll', this.handleScroll)

  },
  
  computed: {
    
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
    ComputedStageID(){
    return this.ProcessStages[this.StageID-1].Number
  },
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
          return true
        }
      else{
      return this.userIsAdmin
      }
    }
    else{
      return this.userIsAdmin
    }

  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    DealershipsStore(){
      return this.$store.state.DealershipsArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
    MultipleFileUpload1withIcon(){
      return this.MultipleFileUpload1.map(doc => {
        console.log(doc)
        if(doc.FileType.includes('pdf')){
          doc.FileTypeIcon = 'mdi-file-pdf'
          doc.FileTypeIconColor = 'red'
          //doc.FileType = doc.FileType.split('/')[1]
        }
        else{
          doc.FileTypeIcon = 'mdi-file-question-outline'
          doc.FileTypeIconColor = 'blue'
          //doc.FileType = doc.FileType.split('/')[1]
        }
        return doc
      })
    },
    MultipleFileUpload2ImagesUploadsPagination(){
      let displaylimit = 12
      let displayrange = displaylimit*this.MultipleFileUpload2Page-displaylimit
      this.MultipleFileUpload2SlideShow = displayrange
      return this.MultipleFileUpload2ImagesUploads.slice(displayrange, displayrange+displaylimit)
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },
  Date_of_PurchaseObject(){
    return this.DateFormatter(this.Date_of_Purchase)
  },  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    },
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
                if(vm.UserRecord !== 'undefined'){
                vm.UserRoles = vm.UserRecord.rolesarrayQuery
                let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.GetCurrentCustomer(AdminTest)
                        
              let UsersClientQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersClientArrayandLookupValue(UsersClientQuery)
                }
              }
              

                        
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                        
                  let DealershipsDealerQuery = db.collection('dealerships')
                  let DealershipsListCustomSecurityRoles = [
  "Office Administrator"
]
                  if(DealershipsListCustomSecurityRoles.length>0 || vm.IsAdmin){
                    vm.CustomRolesCheck(DealershipsListCustomSecurityRoles).then(PermissionLevel => {
                       if(PermissionLevel === 1 || vm.IsAdmin){
                         vm.GetDealershipsDealerArrayandLookupValue(DealershipsDealerQuery)
                       }
                       else{
                         //vm.RoutetoErrorPage()
                       }
                  })
                  }
              

                        
              let UsersOwnerQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
                }
              }
              

                }
                else{
                  let GetQuery = db.collection('currentcustomers')
                  vm.CustomRolesCheck(vm.CustomGetRoles).then(PermissionLevel => {
                    if(PermissionLevel === 1 || vm.IsAdmin){
                      vm.GetCurrentCustomer(vm.UserRecord)
                        
              let UsersClientQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersClientArrayandLookupValue(UsersClientQuery)
                }
              }
              

                        
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                        
                  let DealershipsDealerQuery = db.collection('dealerships')
                  let DealershipsListCustomSecurityRoles = [
  "Office Administrator"
]
                  if(DealershipsListCustomSecurityRoles.length>0 || vm.IsAdmin){
                    vm.CustomRolesCheck(DealershipsListCustomSecurityRoles).then(PermissionLevel => {
                       if(PermissionLevel === 1 || vm.IsAdmin){
                         vm.GetDealershipsDealerArrayandLookupValue(DealershipsDealerQuery)
                       }
                       else{
                         //vm.RoutetoErrorPage()
                       }
                  })
                  }
              

                        
              let UsersOwnerQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
                }
              }
              

                    }
                    else{
                      vm.RoutetoErrorPage()
                    }
                  })
                }
                
                }
              
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetCurrentCustomer() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
          db.collection('currentcustomerswiki').doc(this.$route.params.id).onSnapshot(snapshot => {
            let wikidata = snapshot.data()
            this.WikiData = wikidata
                //if(wikidata && wikidata.MultipleFileUpload1){
                //this.MultipleFileUpload1 = wikidata.MultipleFileUpload1
              //}
          })
             vm.CustomRolesCheck(vm.CustomUpdateRoles).then(PermissionLevel => {
              if(PermissionLevel === 1){
                vm.CanEdit = true
              }
             })
             vm.CustomRolesCheck(vm.CustomUpdateRoles).then(PermissionLevel => {
              if(PermissionLevel === 1){
                vm.CanCreate = true
              }
             })
            db.collection('currentcustomers').doc(this.$route.params.id).onSnapshot(snapshot => {
            var currentcustomer = snapshot.data()
            currentcustomer.id = this.$route.params.id
            if(currentcustomer.Bank){
              this.Bank = currentcustomer.Bank
              this.editedItem.Bank = currentcustomer.Bank
            }
            this.selectedRecord = {id: this.$route.params.id, Name: currentcustomer.Name }
            this.RelatedObj = {id: this.$route.params.id, Name: currentcustomer.Name,identifier: 'Name',collection: 'currentcustomers',Link:'CurrentCustomer' }
            this.CurrentCustomerData = currentcustomer
            this.Status_Reason = currentcustomer.Status_Reason
            this.GetProcessData()
            this.CurrentCustomerData.id = this.$route.params.id
            this.$emit('PushRecordName',this.CurrentCustomerData.Name)
            if(typeof currentcustomer.Owner !== 'undefined'){
              let tempOwner = currentcustomer.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof currentcustomer.Owner !== 'undefined'){
              this.Business_Unit = currentcustomer.Business_Unit
            }        

            this.Brand = currentcustomer.Brand
            this.editedItem.Brand = currentcustomer.Brand
            //I think API Trigger should be here, outside the first if loop on fields
            this.Engine_Number = currentcustomer.Engine_Number
            this.editedItem.Engine_Number = currentcustomer.Engine_Number
            //I think API Trigger should be here, outside the first if loop on fields
            this.ID_Number = currentcustomer.ID_Number
            this.editedItem.ID_Number = currentcustomer.ID_Number
            //I think API Trigger should be here, outside the first if loop on fields
            this.Model = currentcustomer.Model
            this.editedItem.Model = currentcustomer.Model
            //I think API Trigger should be here, outside the first if loop on fields
            this.Name = currentcustomer.Name
            this.editedItem.Name = currentcustomer.Name
            //I think API Trigger should be here, outside the first if loop on fields
            this.Registration_Number = currentcustomer.Registration_Number
            this.editedItem.Registration_Number = currentcustomer.Registration_Number
            //I think API Trigger should be here, outside the first if loop on fields
            this.VIN_Number = currentcustomer.VIN_Number
            this.editedItem.VIN_Number = currentcustomer.VIN_Number
            //I think API Trigger should be here, outside the first if loop on fields
            this.Vehicle_Color = currentcustomer.Vehicle_Color
            this.editedItem.Vehicle_Color = currentcustomer.Vehicle_Color
            //I think API Trigger should be here, outside the first if loop on fields
            this.Status = currentcustomer.Status
            this.editedItem.Status = currentcustomer.Status
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
              if(currentcustomer.Created_On && typeof currentcustomer.Created_On !== 'string'){
            this.Created_On = format(currentcustomer.Created_On.toDate(),'yyyy-MM-dd')
            this.editedItem.Created_On = currentcustomer.Created_On
              }
            //I think API Trigger should be here, outside the first if loop on fields
              if(currentcustomer.Date_of_Purchase && typeof currentcustomer.Date_of_Purchase !== 'string'){
            this.Date_of_Purchase = format(currentcustomer.Date_of_Purchase.toDate(),'yyyy-MM-dd')
            this.editedItem.Date_of_Purchase = currentcustomer.Date_of_Purchase
              }
            if(currentcustomer.Client){
            this.ClientRelatedParentObj = {id: currentcustomer.Client.id, Name: currentcustomer.Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Client = this.UsersStore.find(client => client.id === currentcustomer.Clientid)
                
            if(typeof currentcustomer.Clientid !== 'undefined' && currentcustomer.Clientid !== ''){
              this.HasClient = true
              if(typeof this.Client === 'undefined'){
                this.UnpermittedClient = {id: currentcustomer.Clientid,Full_Name: currentcustomer.Client.Full_Name}
              }
            }
            this.editedItem.Client = currentcustomer.Client
            if(currentcustomer.Created_By){
            this.Created_ByRelatedParentObj = {id: currentcustomer.Created_By.id, Name: currentcustomer.Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Created_By = this.UsersStore.find(created_by => created_by.id === currentcustomer.Created_Byid)
                
            if(typeof currentcustomer.Created_Byid !== 'undefined' && currentcustomer.Created_Byid !== ''){
              this.HasCreated_By = true
              if(typeof this.Created_By === 'undefined'){
                this.UnpermittedCreated_By = {id: currentcustomer.Created_Byid,Full_Name: currentcustomer.Created_By.Full_Name}
              }
            }
            this.editedItem.Created_By = currentcustomer.Created_By
            if(currentcustomer.Dealer){
            this.DealerRelatedParentObj = {id: currentcustomer.Dealer.id, Name: currentcustomer.Name,identifier: 'Dealer_Name',collection: 'dealerships',Link: 'Dealership' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                  this.Dealer = this.DealerArray.find(dealer => dealer.id === currentcustomer.Dealerid)
                
            if(typeof currentcustomer.Dealerid !== 'undefined' && currentcustomer.Dealerid !== ''){
              this.HasDealer = true
              if(typeof this.Dealer === 'undefined'){
                this.UnpermittedDealer = {id: currentcustomer.Dealerid,Dealer_Name: currentcustomer.Dealer.Dealer_Name}
              }
            }
            this.editedItem.Dealer = currentcustomer.Dealer
            if(currentcustomer.Owner){
            this.OwnerRelatedParentObj = {id: currentcustomer.Owner.id, Name: currentcustomer.Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Owner = this.UsersStore.find(owner => owner.id === currentcustomer.Ownerid)
                
            if(typeof currentcustomer.Ownerid !== 'undefined' && currentcustomer.Ownerid !== ''){
              this.HasOwner = true
              if(typeof this.Owner === 'undefined'){
                this.UnpermittedOwner = {id: currentcustomer.Ownerid,Full_Name: currentcustomer.Owner.Full_Name}
              }
            }
            this.editedItem.Owner = currentcustomer.Owner
            this.Delivery_Photo = currentcustomer.Delivery_Photo
            this.editedItem.Delivery_Photo = currentcustomer.Delivery_Photo
            //I think API Trigger should be here, outside the first if loop on fields
            this.Client_Email = currentcustomer.Client_Email
            this.editedItem.Client_Email = currentcustomer.Client_Email
            //I think API Trigger should be here, outside the first if loop on fields
            this.Client_Mobile = currentcustomer.Client_Mobile
            this.editedItem.Client_Mobile = currentcustomer.Client_Mobile
            //I think API Trigger should be here, outside the first if loop on fields
            this.Mileage = currentcustomer.Mileage
            this.editedItem.Mileage = currentcustomer.Mileage
            //I think API Trigger should be here, outside the first if loop on fields
            this.Price = currentcustomer.Price
            this.editedItem.Price = currentcustomer.Price
            //I think API Trigger should be here, outside the first if loop on fields
    
                if(vm.IsAdmin === true){
                vm.CanCreateMultipleFileUpload2 = true
                vm.CanGetMultipleFileUpload2 = true
                vm.CanListMultipleFileUpload2 = true
                vm.CanEditMultipleFileUpload2 = true                
                vm.CanDeleteMultipleFileUpload2 = true
              }
                if(vm.CanEdit === true){
                  vm.CanDeleteMultipleFileUpload2 = true
                }
              
                if(vm.CanCreate === true){
                  vm.CanCreateMultipleFileUpload2 = true
                }
              
                if(vm.CanEdit === true){
                  vm.CanEditMultipleFileUpload2 = true
                }
              
              vm.CanGetMultipleFileUpload2 = true
              db.collection('currentcustomers').doc(this.$route.params.id).collection('multiplefileupload2imagesuploads').onSnapshot(res => {
                      const changes = res.docChanges();
                      changes.forEach(change => {
                        let existing = this.MultipleFileUpload2ImagesUploads.find(obj => obj.id === change.doc.id)
                        if(!existing){
                          if (change.type === 'added') {
                            this.MultipleFileUpload2ImagesUploads.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        }
                      })
                    })
                if(vm.IsAdmin === true){
                vm.CanCreateMultipleFileUpload1 = true
                vm.CanGetMultipleFileUpload1 = true
                vm.CanListMultipleFileUpload1 = true
                vm.CanEditMultipleFileUpload1 = true                
                vm.CanDeleteMultipleFileUpload1 = true
              }
                if(vm.CanEdit === true){
                  vm.CanDeleteMultipleFileUpload1 = true
                }
              
              
                if(vm.CanCreate === true){
                  vm.CanCreateMultipleFileUpload1 = true
                }
              
                if(vm.CanEdit === true){
                  vm.CanEditMultipleFileUpload1 = true
                }
              
              vm.CanGetMultipleFileUpload1 = true
              db.collection('currentcustomers').doc(this.$route.params.id).collection('multiplefileupload1').onSnapshot(res => {
                      const changes = res.docChanges();
                      changes.forEach(change => {
                        if (change.type === 'added') {
                          let match = this.MultipleFileUpload1.find(obj => obj.id === change.doc.id)
                          if(!match){
                          this.MultipleFileUpload1.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                          this.Multiple_File_Upload_1.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                          }
                        }
                        if(change.type === 'modified'){
                          let item = this.MultipleFileUpload1.find(obj => obj.id === change.doc.id)
                          let index = this.MultipleFileUpload1.indexOf(item)
                          this.MultipleFileUpload1.splice(index,1)
                          this.MultipleFileUpload1.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                        }
                      })
                    })
            })
          
        },
      
      


    
      
      
            toggleMultipleRadio(fieldname,_id) {
            console.log(_id,this[fieldname],this[fieldname].includes(_id))
            this[fieldname].map(opt => {
              if(opt.ID === _id.ID){
                console.log('well we see it this way')
              }
            })
            if (this[fieldname].includes(_id)) {
            this[fieldname] = this[fieldname].filter((i) => {
            return i !== _id;
            });
        } else {
            this[fieldname] = this[fieldname].concat(_id);
        }
            
        },
      CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
      GetCols(int){
      if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg){
        return int
      }
      else if(this.$vuetify.breakpoint.md){
        return int*1.5
      }
      else if(this.$vuetify.breakpoint.sm){
        return int*2.5
      }
      else{
        return int*3
      }
    },
      ResetStatus(){
      this.Status = Object.assign({},this.CurrentCustomerData.Status)
      this.Status_Reason = Object.assign({},this.CurrentCustomerData.Status_Reason)
      this.ChangeStatusDialog = false
    },
    UpdateStatus(){
      db.collection('currentcustomers').doc(this.$route.params.id).update({
        Status: this.Status,
        Status_Reason: this.Status_Reason
      })
      this.ChangeStatusDialog = false
    },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
            console.log(window.innerHeight)
            this.ViewFileDialog = true
          }
          else{
            this.ViewFileURL = result.data[0]
            var ua = navigator.userAgent.toLowerCase();
            console.log(ua)
            var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
            if(isAndroid) {
              window.open(this.ViewFileURL)
            }
            else{
              console.log(window.innerHeight)
              this.ViewFileDialog = true
            }
          }
          
          //window.location.assign(result.data[0])
        })
    },
      ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode')
      },
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateCurrentCustomer(){
        this.UndefinedMandatoryFields = []
      const EditedCurrentCustomer = {
    Brand: this.Brand,
    Engine_Number: this.Engine_Number,
    ID_Number: this.ID_Number,
    Model: this.Model,
    Name: this.Name,
    Registration_Number: this.Registration_Number,
    VIN_Number: this.VIN_Number,
    Vehicle_Color: this.Vehicle_Color,
    Date_of_Purchase: this.Date_of_PurchaseObject,
    
    Client_Email: this.Client_Email,
    Client_Mobile: this.Client_Mobile,
    Mileage: this.Mileage,
    Price: this.Price,
    
        }
        console.log(EditedCurrentCustomer)
        
          //here we should now make room for mandatory lookupfields
        if(this.Dealer){
          EditedCurrentCustomer.Dealer = { Dealer_Name: this.Dealer.Dealer_Name,id: this.Dealer.id }
          EditedCurrentCustomer.Dealerid = this.Dealer.id
        }
        else{
          EditedCurrentCustomer.Dealer = ''
        }
        if(this.Bank){
          EditedCurrentCustomer.Bank = this.Bank
        }
      for(var prop in EditedCurrentCustomer) {
          if (EditedCurrentCustomer.hasOwnProperty(prop)) {
            if(typeof EditedCurrentCustomer[prop] === 'undefined' || EditedCurrentCustomer[prop] === '' || EditedCurrentCustomer[prop] === null){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      db.collection('currentcustomers').doc(this.$route.params.id).update({
        Brand: EditedCurrentCustomer.Brand,
        Bank: EditedCurrentCustomer.Bank,
        Engine_Number: EditedCurrentCustomer.Engine_Number,
        ID_Number: EditedCurrentCustomer.ID_Number,
        Model: EditedCurrentCustomer.Model,
        Name: EditedCurrentCustomer.Name,
        Registration_Number: EditedCurrentCustomer.Registration_Number,
        VIN_Number: EditedCurrentCustomer.VIN_Number,
        Vehicle_Color: EditedCurrentCustomer.Vehicle_Color,
        Date_of_Purchase: EditedCurrentCustomer.Date_of_Purchase,
        
    //here we should now make room for mandatory lookupfields
        Dealer: EditedCurrentCustomer.Dealer,
        Dealerid: EditedCurrentCustomer.Dealerid,
                  
        Client_Email: EditedCurrentCustomer.Client_Email,
        Client_Mobile: EditedCurrentCustomer.Client_Mobile,
        Mileage: Number(EditedCurrentCustomer.Mileage),
        Price: Number(EditedCurrentCustomer.Price),
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedCurrentCustomersnackbar = true
              this.CancelCurrentCustomerEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Name !== 'undefined'){                  
                  let NameQuery = []
                  let NameStringArray = this.Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(NameStringArray.length,NameStringArray)
                    for (p = 0; p < NameStringArray.length; p++) {
                        buildup = buildup+ NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    NameQuery = lowertext.split(',')  
                    NameQuery.length = NameQuery.length-1
                   db.collection('currentcustomers').doc(this.$route.params.id).update({
                     NameQuery: NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
              if(typeof this.Client !== 'undefined'){
                db.collection('currentcustomers').doc(this.$route.params.id).update({
                  Client: {Full_Name: this.Client.Full_Name,id: this.Client.id},
                  Clientid: this.Client.id,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                      })
              }
                if(typeof this.Delivery_Photo !== 'undefined'){
                   db.collection('currentcustomers').doc(this.$route.params.id).update({
                     Delivery_Photo: this.Delivery_Photo,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.CurrentCustomersArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('currentcustomers').doc(item.id).delete() && this.CurrentCustomersArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.CurrentCustomersArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
              this.EditingMultipleFileUpload1 = false
              this.EditingMultipleFileUpload2 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelCurrentCustomerEditing(){
      this.EditingCurrentCustomer = false
    },
    EditCurrentCustomer() {
      this.EditingCurrentCustomer = !this.EditingCurrentCustomer
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetUsersClient() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.ClientArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersClientArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.ClientArray = vm.UsersStore
      vm.GetClient();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.ClientArray = vm.UsersStore
                        vm.GetClient();
                        })
    }
  },

  GetClient(){
    this.Client = this.ClientArray.find(client => client.id === this.CurrentCustomerData.Clientid)
  },

  //GetUsersClient(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.ClientArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersClient finished')
 //   })
 // })
//    })
//  },

  GetUsersCreated_By() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Created_ByArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersCreated_ByArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.Created_ByArray = vm.UsersStore
      vm.GetCreated_By();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.Created_ByArray = vm.UsersStore
                        vm.GetCreated_By();
                        })
    }
  },

  GetCreated_By(){
    this.Created_By = this.Created_ByArray.find(created_by => created_by.id === this.CurrentCustomerData.Created_Byid)
  },

  //GetUsersCreated_By(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Created_ByArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersCreated_By finished')
 //   })
 // })
//    })
//  },

  GetDealershipsDealer() {
  db.collection('dealerships').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.DealerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetDealershipsDealerArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.DealershipsStore.length > 0){
      //alert('got from store')
      vm.DealerArray = vm.DealershipsStore
      vm.GetDealer();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setDealershipsArray',
                    getter: 'getDealershipsArray',
                    arrayname: 'DealershipsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.DealershipsStore)
                        vm.DealerArray = vm.DealershipsStore
                        vm.GetDealer();
                        })
    }
  },

  GetDealer(){
    this.Dealer = this.DealerArray.find(dealer => dealer.id === this.CurrentCustomerData.Dealerid)
  },

  //GetDealershipsDealer(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.DealerArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetDealershipsDealer finished')
 //   })
 // })
//    })
//  },

  GetUsersOwner() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.OwnerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersOwnerArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.OwnerArray = vm.UsersStore
      vm.GetOwner();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.OwnerArray = vm.UsersStore
                        vm.GetOwner();
                        })
    }
  },

  GetOwner(){
    this.Owner = this.OwnerArray.find(owner => owner.id === this.CurrentCustomerData.Ownerid)
  },

  //GetUsersOwner(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.OwnerArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersOwner finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingClient(){
this.CancelAddParentDialog()
this.PrepareDataParentClient()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Client'
      this.LookupArray = this.ClientArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentClient(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Client'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Client', Prop: 'Full_Name', Propid: 'Clientid', Value: '',Array: 'ClientArray',LookupFieldName: 'Client'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Client', Prop: 'Client', Value: '',LookupFieldName: 'Client'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Level": {
          "id": 2,
          "children": [],
          "name": "Status Reason",
          "FieldName": "Status_Reason"
        },
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "ID": 1000003
      }
    ],
    "Name": "Active",
    "Level": {
      "id": 1,
      "FieldName": "Status",
      "children": [
        {
          "id": 2,
          "children": [],
          "FieldName": "Status_Reason",
          "name": "Status Reason"
        }
      ],
      "name": "Status"
    },
    "ID": 1000001
  },
  {
    "LevelFieldName": "Status",
    "ID": 1000002,
    "Level": {
      "children": [
        {
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason",
          "id": 2
        }
      ],
      "FieldName": "Status",
      "id": 1,
      "name": "Status"
    },
    "Options": [
      {
        "Level": {
          "name": "Status Reason",
          "FieldName": "Status_Reason",
          "id": 2,
          "children": []
        },
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004
      }
    ],
    "HasDeepHierarchy": true,
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewClientDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentClient()
},


PrepareLinkExistingCreated_By(){
this.CancelAddParentDialog()
this.PrepareDataParentCreated_By()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Created By'
      this.LookupArray = this.Created_ByArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentCreated_By(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Created_By'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Created By', Prop: 'Full_Name', Propid: 'Created_Byid', Value: '',Array: 'Created_ByArray',LookupFieldName: 'Created_By'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Created By', Prop: 'Created_By', Value: '',LookupFieldName: 'Created_By'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Level": {
          "id": 2,
          "children": [],
          "name": "Status Reason",
          "FieldName": "Status_Reason"
        },
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "ID": 1000003
      }
    ],
    "Name": "Active",
    "Level": {
      "id": 1,
      "FieldName": "Status",
      "children": [
        {
          "id": 2,
          "children": [],
          "FieldName": "Status_Reason",
          "name": "Status Reason"
        }
      ],
      "name": "Status"
    },
    "ID": 1000001
  },
  {
    "LevelFieldName": "Status",
    "ID": 1000002,
    "Level": {
      "children": [
        {
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason",
          "id": 2
        }
      ],
      "FieldName": "Status",
      "id": 1,
      "name": "Status"
    },
    "Options": [
      {
        "Level": {
          "name": "Status Reason",
          "FieldName": "Status_Reason",
          "id": 2,
          "children": []
        },
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004
      }
    ],
    "HasDeepHierarchy": true,
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewCreated_ByDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCreated_By()
},


PrepareLinkExistingDealer(){
this.CancelAddParentDialog()
this.PrepareDataParentDealer()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Dealer'
      this.LookupArray = this.DealerArray
      this.LookupArrayHeaders = [
        { text: 'Dealer Name', value: 'Dealer_Name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
      ]
},

PrepareDataParentDealer(){
  this.ParentTitle = 'Dealer'
  this.ParentCollection = 'dealerships'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Dealer', Prop: 'Dealer_Name', Propid: 'Dealerid', Value: '',Array: 'DealerArray',LookupFieldName: 'Dealer'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Dealer', Prop: 'Dealer', Value: '',LookupFieldName: 'Dealer'}
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "Name": "Active",
    "Options": [
      {
        "ID": 1000003,
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "Level": {
          "children": [],
          "FieldName": "Status_Reason",
          "id": 2,
          "name": "Status Reason"
        }
      }
    ],
    "ID": 1000001,
    "HasDeepHierarchy": true,
    "LevelFieldName": "Status",
    "Level": {
      "name": "Status",
      "id": 1,
      "FieldName": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "children": [],
          "FieldName": "Status_Reason"
        }
      ]
    }
  },
  {
    "ID": 1000002,
    "Level": {
      "name": "Status",
      "FieldName": "Status",
      "children": [
        {
          "children": [],
          "name": "Status Reason",
          "FieldName": "Status_Reason",
          "id": 2
        }
      ],
      "id": 1
    },
    "Options": [
      {
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004,
        "Level": {
          "id": 2,
          "children": [],
          "name": "Status Reason",
          "FieldName": "Status_Reason"
        }
      }
    ],
    "LevelFieldName": "Status",
    "Name": "InActive",
    "HasDeepHierarchy": true
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )     
  
},

ActivateAddNewDealerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentDealer()
},


PrepareLinkExistingOwner(){
this.CancelAddParentDialog()
this.PrepareDataParentOwner()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Owner'
      this.LookupArray = this.OwnerArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentOwner(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Owner'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Owner', Prop: 'Full_Name', Propid: 'Ownerid', Value: '',Array: 'OwnerArray',LookupFieldName: 'Owner'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Owner', Prop: 'Owner', Value: '',LookupFieldName: 'Owner'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Level": {
          "id": 2,
          "children": [],
          "name": "Status Reason",
          "FieldName": "Status_Reason"
        },
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "ID": 1000003
      }
    ],
    "Name": "Active",
    "Level": {
      "id": 1,
      "FieldName": "Status",
      "children": [
        {
          "id": 2,
          "children": [],
          "FieldName": "Status_Reason",
          "name": "Status Reason"
        }
      ],
      "name": "Status"
    },
    "ID": 1000001
  },
  {
    "LevelFieldName": "Status",
    "ID": 1000002,
    "Level": {
      "children": [
        {
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason",
          "id": 2
        }
      ],
      "FieldName": "Status",
      "id": 1,
      "name": "Status"
    },
    "Options": [
      {
        "Level": {
          "name": "Status Reason",
          "FieldName": "Status_Reason",
          "id": 2,
          "children": []
        },
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004
      }
    ],
    "HasDeepHierarchy": true,
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewOwnerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentOwner()
},



      
      BreakPointCol(int){
        if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
        return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return int
        }
        else{
          return int
        }
      },
    PublishWikiPage(){
      let WikiObj = {
        Name: this.Name,
        Delivery_PhotoThumbURL: this.CurrentCustomerData.Delivery_PhotoThumbURL,
        Delivery_Photo: this.CurrentCustomerData.Delivery_Photo,
        Delivery_PhotoThumbURL: this.CurrentCustomerData.Delivery_PhotoThumbURL,
        Delivery_Photo: this.CurrentCustomerData.Delivery_Photo,
        Brand: this.Brand,
        Model: this.Model,
        VIN_Number: this.VIN_Number,
        Engine_Number: this.Engine_Number,
        Multiple_File_Upload_1: this.Multiple_File_Upload_1,
      }
      if(this.Business_Unit){
        WikiObj.Location = this.Business_Unit
        WikiObj.Locationid = this.Business_Unit.id
      }
        if(this.Client){
          WikiObj.Client = { Full_Name: this.Client.Full_Name,id: this.Client.id }
          WikiObj.Clientid = this.Client.id
        }
        else{
          WikiObj.Client = ''
        }
        if(this.Dealer){
          WikiObj.Dealer = { Dealer_Name: this.Dealer.Dealer_Name,id: this.Dealer.id }
          WikiObj.Dealerid = this.Dealer.id
        }
        else{
          WikiObj.Dealer = ''
        }      
      this.UndefinedMandatoryFields = []
      let VerifyObj = Object.assign({},WikiObj)
      for(var prop in VerifyObj) {
          if (VerifyObj.hasOwnProperty(prop)) {
            if(typeof VerifyObj[prop] === 'undefined' || VerifyObj[prop] === '' || VerifyObj[prop] === null){
              if(!prop.includes('ThumbURL')){
                this.UndefinedMandatoryFields.push(prop)
              }
            }
            else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
          }
        }
      if(this.UndefinedMandatoryFields.length === 0){
        db.collection('currentcustomerswiki').doc(this.$route.params.id).set(WikiObj)
          db.collection('currentcustomers').doc(this.$route.params.id).update({
          Multiple_File_Upload_1: this.Multiple_File_Upload_1,
        })        
        let snackbarcontent = {
            snackbartimeout: 4000,
            snackbartext: 'Published Wiki for  - '+this.Name,
            snackbartop: true,
            snackbarpath: '/CurrentCustomer-Wiki/'+this.Name
          }
          this.$emit('ActivateSnackbar',true,snackbarcontent)
      }
      else{
        this.WikiMode = false
        this.EditingSection1 = true
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        setTimeout(() => {
         this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        }) 
        }, 500);
      }      
    },
      saveBrand(){
        db.collection('currentcustomers').doc(this.$route.params.id).update({
          Brand: this.Brand,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
      },
      saveModel(){
        db.collection('currentcustomers').doc(this.$route.params.id).update({
          Model: this.Model,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
      },
      saveVIN_Number(){
        db.collection('currentcustomers').doc(this.$route.params.id).update({
          VIN_Number: this.VIN_Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
      },
      saveEngine_Number(){
        db.collection('currentcustomers').doc(this.$route.params.id).update({
          Engine_Number: this.Engine_Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
      },
    
      
      
    
      
    
      
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          
      
     
      
      

      
      
      
      ResetFilters() {
        this.Statusfilter = ''
      },
      
      
      
      OpenorEditingMultipleFileUpload2(item,index){
      if(this.EditingMultipleFileUpload2){
        this.EditingMultipleFileUpload2Dialog = true
        this.editedMultipleFileUpload2Item = item
        this.editedMultipleFileUpload2ItemIndex = index
      }
      else{
        this.GetShortLivedURL(item)
      }
      },
      CancelEditingMultipleFileUpload2Dialog(){
      this.EditingMultipleFileUpload2Dialog = false
      this.editedMultipleFileUpload2Item = ''
      this.editedMultipleFileUpload2ItemIndex = -1
      this.MultipleFileUpload2EditOption = ''
    },
    CancelEditingMultipleFileUpload2Dialog(){
      this.EditingMultipleFileUpload2Dialog = false
      this.editedMultipleFileUpload2Item = ''
      this.editedMultipleFileUpload2ItemIndex = -1
      this.MultipleFileUpload2EditOption = ''
    },
    ProcessEditingMultipleFileUpload2Dialog(){
      console.log(this.MultipleFileUpload2EditOption)
      if(this.MultipleFileUpload2EditOption === 'Move out of Record'){
        confirm('This will move the Image out of this table, and move it to another. Are you sure you want to do this?') &&
        this.TransferMultipleFileUpload2toRMAdminTable()
      }
      else if(this.MultipleFileUpload2EditOption === 'Destroy'){
        //alert('We Will Delete')
        confirm('This will destroy all traces of the Image,are you Sure you want to do this?') && this.DeleteMultipleFileUpload2Item()
        
      }
    },
    DeleteMultipleFileUpload2Item(){
      let vm = this
      let thumstorageRef = firebase.storage().ref(vm.editedMultipleFileUpload2Item.StorageRef+'_thumb');
     
        let storageRef = firebase.storage().ref(vm.editedMultipleFileUpload2Item.StorageRef);	
              storageRef.delete().then(function() {									
                // File deleted successfully
                		console.log('actual file successfully deleted')
                    thumstorageRef.delete().then(function() {									
                      // File deleted successfully		
                      console.log('thumb file successfully deleted')
                      db.collection('currentcustomers').doc(vm.$route.params.id).collection('multiplefileupload2imagesuploads').doc(vm.editedMultipleFileUpload2Item.id).delete().then(function() {
                        vm.MultipleFileUpload2ImagesUploads.splice(vm.editedMultipleFileUpload2ItemIndex,1)
                      }).catch(function(error) {									
                      // Uh-oh, an error occurred!									
                    });
                    }).catch(function(error) {									
                      // Uh-oh, an error occurred!									
                    });							
              }).catch(function(error) {									
                // Uh-oh, an error occurred!									
              });
    },
    TransferMultipleFileUpload2toRMAdminTable(){
      if(this.userIsAdmin){
    let doc = Object.assign({},this.editedMultipleFileUpload2Item)
    doc.Name = doc.title
    doc.FileType = doc.type
    doc.url = doc.path
    doc.StorageRef = 'CurrentCustomers/'+this.$route.params.id+'/MultipleFileUpload2/'+ this.editedMultipleFileUpload2Item.title
    this.RemoveSingleItemfromTable(doc,'MultipleFileUpload2ImagesUploads','currentcustomers')	
      }
      else{
        alert('Apologies but only Administrators can do that')
      }
  },

      AddMultipleFileUpload2(){
      this.$emit('ActivateMultipleFilesUploadNotification',true)   
      let fileList = event.target.files || e.dataTransfer.files
      this.MultipleFileUpload2runningpercentage = 0
      this.MultipleFileUpload2runningProgress = 0 
      //this.UploadingMultipleFileUpload2 = true
      setTimeout(() => {
      this.MultipleFileUpload2totallist = fileList.length
      this.MultipleFileUpload2uploadTotal = fileList.length*100
      const topelmnt = document.getElementById('uploadfilesprogressbar')
      topelmnt.style.display = "block";
      const elmnt = document.getElementById('uploadfilesprogressgroupbar') 
      let oldtotalprogresselementptag = document.getElementById("uploadfilestotalprogressbarptag")
      let oldtotalprogresselement = document.getElementById("uploadfilestotalprogressbar")
      if(oldtotalprogresselementptag){
        oldtotalprogresselementptag.remove();
      }   

      if(oldtotalprogresselement){
        oldtotalprogresselement.remove();
      }
  
      if(elmnt.childNodes[0]){
        elmnt.innerHTML = '';
      }     
      const totalprogresselementptag = document.createElement('p')
      totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
      totalprogresselementptag.setAttribute('id','uploadfilestotalprogressbarptag')
      const totalprogresselement = document.createElement('progress')
      totalprogresselement.setAttribute('id','uploadfilestotalprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
      var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.MultipleFileUpload2totallist+' files uploaded');
      totalprogresselementptag.appendChild(startingtotaltext);
      elmnt.append(totalprogresselement) 
      elmnt.append(totalprogresselementptag) 
      // this.totalelement  = document.createElement('p')
      Array.from(Array(fileList.length).keys()).map((x,index) => {
        this.PrepareMultipleFileUpload2Thumbnails(fileList[x],index,fileList.length)
      })
      }, 300);
      },
      PrepareMultipleFileUpload2Thumbnails(file,index,listlength){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.MultipleFileUpload2forUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref('CurrentCustomers/'+vm.$route.params.id+'/MultipleFileUpload2/'+ filename+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      file.FileModifiedDate = new Date(file.lastModified)
                      vm.AddMultipleFileUpload2Single(file,index,listlength,thumburl)
                    })
              })
      }
      },
      AddMultipleFileUpload2Single(file,fileindex,listlength,thumburl) {
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        const totalprogresselement = document.getElementById('uploadfilestotalprogressbar')
        const totalprogresselementptag = document.getElementById('uploadfilestotalprogressbarptag')
        const elmnt = document.getElementById('uploadfilesprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)    
        let vm = this
        //const ImageArray = this.MultipleFileUpload2ImagesUploads
        var storageRef = firebase.storage().ref('CurrentCustomers/'+this.$route.params.id+'/MultipleFileUpload2/'+ filename);        
        var uploadTask = storageRef.put(file);
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.MultipleFileUpload2uploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.MultipleFileUpload2uploadPercentage)
                var textnode = document.createTextNode(vm.MultipleFileUpload2uploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.MultipleFileUpload2uploadPercentage === 100){
                  vm.MultipleFileUpload2runningProgress = vm.MultipleFileUpload2runningProgress+vm.MultipleFileUpload2uploadPercentage
                  vm.MultipleFileUpload2runningpercentage = Number(((vm.MultipleFileUpload2runningProgress/vm.MultipleFileUpload2uploadTotal)*100).toFixed(2))                  
                  vm.MultipleFileUpload2listuploadcount = vm.MultipleFileUpload2listuploadcount-1+2
                  var totaltext = document.createTextNode(vm.MultipleFileUpload2runningpercentage+'% complete  - '+vm.MultipleFileUpload2listuploadcount+' of '+vm.MultipleFileUpload2totallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.MultipleFileUpload2runningpercentage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                      const newimage = {
                              Name: filename,
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Created_Byid: this.UserRecord.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.FileModifiedDate,
                              Modified_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Modified_Byid: this.UserRecord.id,
                              Modified_On: new Date(),
                              
                              StorageRef: 'CurrentCustomers/'+this.$route.params.id+'/MultipleFileUpload2/'+ filename
                              }
                    //ImageArray.push(newimage)
                    db.collection('currentcustomers').doc(this.$route.params.id).collection('multiplefileupload2imagesuploads').add(newimage).then(imagedoc => {
                      console.log('created new doc '+imagedoc)
                      })
                      if(this.MultipleFileUpload2runningpercentage === 100){
                      //this.UploadingMultipleFileUpload2 = false
                      this.$emit('ActivateMultipleFilesUploadNotification',false)
                      this.MultipleFileUpload2totallist = 0
                      this.MultipleFileUpload2uploadTotal = 0
                      this.MultipleFileUpload2runningpercentage = 0
                      this.MultipleFileUpload2runningProgress = 0
                      this.MultipleFileUpload2uploadPercentage = 0
                      this.MultipleFileUpload2listuploadcount = 0
                      //elmnt.style.display = "none";
                      }
                  })
      },
    ProcessDeleteItem(item,itemarrayname,collection){
      let vm = this
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(vm.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
    ConfirmMultipleFileUpload1TableTransfer(){
    confirm('This will take all the selected items out of the table. Are you sure you want to continue?') && this.ProcessTransferMultipleFileUpload1RMAdminTable()
  },
  ProcessTransferMultipleFileUpload1RMAdminTable(){
    this.MultipleFileUpload1Selected.map(doc => {
      this.TransferMultipleFileUpload1RMAdminTable(doc)
    })
  },
  TransferMultipleFileUpload1RMAdminTable(){
    let doc = Object.assign({},this.editedMultipleFileUpload1Item)

    doc.url = doc.path
    this.RemoveSingleItemfromTable(doc,'MultipleFileUpload1','currentcustomers')	
  },
    ProcessRemovefromMultipleFileUpload1Table(){
    this.MultipleFileUpload1Selected.map(doc => {
      
      this.RemoveSingleItemfromTable(doc,'MultipleFileUpload1','currentcustomers')
    })
  },
  RemovefromMultipleFileUpload1Table(){
    confirm('This will not remove the items, but rather move them out of the table to unsorted. Are you sure you want to continue?') && this.ProcessRemovefromMultipleFileUpload1Table()
  }, 
    onMultipleFileUpload1Fileselected(event){
      this.selectedMultipleFileUpload1File = event.target.files || e.dataTransfer.files      
    },

    closeMultipleFileUpload1dialog (){
      this.MultipleFileUpload1dialog = false
      this.selectedMultipleFileUpload1File = ''
      this.MultipleFileUpload1PrimarySelectedOption = ''
      this.editedMultipleFileUpload1Item = this.DefaultMultipleFileUpload1Item
      this.editedMultipleFileUpload1Index = -1
      let inputelement = document.getElementById('MultipleFileUpload1InputElement')
      if(inputelement){
        inputelement.value = ''
      }
    },
    deleteMultipleFileUpload1Item (item) {
      confirm('Are You sure you want to delte this item? There is no going back') && this.ProcessDeleteItem(item,'MultipleFileUpload1','currentcustomers')
    },

    editMultipleFileUpload1Item (item) {
        this.editedMultipleFileUpload1Index = this.MultipleFileUpload1.indexOf(item)
        this.editedMultipleFileUpload1Item = Object.assign({}, item)
        this.MultipleFileUpload1recordid = item.id
        let btn = document.getElementById('NewMultipleFileUpload1Btn')
        btn.click()
        this.MultipleFileUpload1dialog = true
      },

    saveMultipleFileUpload1Doc(){
      
      if (this.editedMultipleFileUpload1Index > -1) {
        let filename = this.editedMultipleFileUpload1Item.id+'_'+this.editedMultipleFileUpload1Item.Name
        let vm = this
        const path = this.DefaultMultipleFileUpload1path 
        this.editedMultipleFileUpload1Item.Modified_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}
        this.editedMultipleFileUpload1Item.Modified_Byid = this.UserRecord.id
        this.editedMultipleFileUpload1Item.Modified_On = new Date()
        let existingstorageref = this.editedMultipleFileUpload1Item.StorageRef
        this.MoveFiletoNewLocation(this.editedMultipleFileUpload1Item.fileurl, path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename).then(url => {
        let OldPath = ''
        OldPath = this.editedMultipleFileUpload1Item.StorageRef
        console.log(OldPath)
        if(existingstorageref){
            let storageRef = firebase.storage().ref(existingstorageref);																
            storageRef.delete()	
          }	
        this.editedMultipleFileUpload1Item.StorageRef = path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename
        this.editedMultipleFileUpload1Item.fileurl = url
        console.log(this.editMultipleFileUpload1Item)
        console.log(OldPath)
        this.MultipleFileUpload1[this.editedMultipleFileUpload1Index] = this.editedMultipleFileUpload1Item
        db.collection('currentcustomers').doc(this.$route.params.id).collection('multiplefileupload1').doc(this.editedMultipleFileUpload1Item.id).set(this.editedMultipleFileUpload1Item).then(function() {
              console.log("Document successfully written!");
              
              vm.UpdatingMultipleFileUpload1Table = true
                setTimeout(() => {
                vm.UpdatingMultipleFileUpload1Table = false
                vm.closeMultipleFileUpload1dialog()
              }, 50);
          })
          .catch(function(error) {
              console.error("Error writing document: ", error);
          });
       //   if(this.Parent.Name !== ''){
      // db.collection('businessunits').doc(this.$route.params.id).update({
      //   Parent: {Name: this.Parent.Name,id: this.Parent.id},
      //   Parentid: this.Parent.id,
      //   Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      //   Modified_Byid: this.UserRecord.id,
      //   Modified_On: new Date()
      //         });
      // } 
                            
                            })
        
      }
      
    
      else{
        //console.log(this.selectedMultipleFileUpload1File)
        
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateMultipleFilesUploadNotification',true)
        setTimeout(() => {
          this.MultipleFileUpload1totallist = this.selectedMultipleFileUpload1File.length
          this.MultipleFileUpload1uploadTotal = this.selectedMultipleFileUpload1File.length*100
          //console.log(this.selectedMultipleFileUpload1File,this.MultipleFileUpload1totallist,this.MultipleFileUpload1uploadTotal)
          const topelmnt = document.getElementById('uploadfilesprogressbar')
          topelmnt.style.display = "block";
          const elmnt = document.getElementById('uploadfilesprogressgroupbar') 
          let oldtotalprogresselementptag = document.getElementById("uploadfilestotalprogressbarptag")
          let oldtotalprogresselement = document.getElementById("uploadfilestotalprogressbar")
          if(oldtotalprogresselementptag){
            oldtotalprogresselementptag.remove();
          }   

          if(oldtotalprogresselement){
            oldtotalprogresselement.remove();
          }
      
          if(elmnt.childNodes[0]){
            elmnt.innerHTML = '';
          }     
          const totalprogresselementptag = document.createElement('p')
          totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
          totalprogresselementptag.setAttribute('id','uploadfilestotalprogressbarptag')
          const totalprogresselement = document.createElement('progress')
          totalprogresselement.setAttribute('id','uploadfilestotalprogressbar')
          totalprogresselement.setAttribute('max','100')
          totalprogresselement.setAttribute('value',1)
          var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.MultipleFileUpload1totallist+' files uploaded');
          totalprogresselementptag.appendChild(startingtotaltext);
          elmnt.append(totalprogresselement) 
          elmnt.append(totalprogresselementptag)
          
        const path = this.DefaultMultipleFileUpload1path
        Array.from(Array(this.selectedMultipleFileUpload1File.length).keys()).map(x => {
        let fileobj = this.selectedMultipleFileUpload1File[x]
        let ref = db.collection('RMDocuments').doc()  
        let newdocid = ref.id
        let filename = newdocid+'_'+fileobj.name
        
                //console.log(filename)
                const MultipleFileUpload1document = { 
                  MultipleFileUpload1PrimarySelectedOption: this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption,
                  FileType: fileobj.type,
                  Name: fileobj.name,
                  Modified_By: {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
                  StorageRef: 'CurrentCustomers/'+this.$route.params.id+'/MultipleFileUpload1/'+ filename,
                  Created_By: {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Created_Byid: this.UserRecord.id,
                  Created_On: new Date(),
                  ModifiedDate: new Date(fileobj.lastModified)
                }
                const singleuploadptag = document.createElement('p')
                singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
                const singleuploaddiv = document.createElement('progress')
                singleuploaddiv.setAttribute('max','100')
                singleuploaddiv.setAttribute('value',1)
                var textnode = document.createTextNode("0% complete");
                singleuploaddiv.appendChild(textnode); 
                elmnt.append(singleuploaddiv) 
                elmnt.append(singleuploadptag)
                var storageRef = firebase.storage().ref(path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename);
                MultipleFileUpload1document.StorageRef = path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename
                  var uploadTask = storageRef.put(fileobj);
                  let vm = this
                  uploadTask.on('state_changed', function(snapshot){
                    console.log(snapshot.bytesTransferred,snapshot.totalBytes,snapshot)
                  let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  vm.MultipleFileUpload1uploadPercentage = Number(progress.toFixed(2))
                  console.log(progress,vm.MultipleFileUpload1uploadPercentage)
                  singleuploaddiv.setAttribute('value',vm.MultipleFileUpload1uploadPercentage)
                  var textnode = document.createTextNode(vm.MultipleFileUpload1uploadPercentage+"% complete for "+filename);
                  if(singleuploadptag.childNodes[0]){
                  singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                  }         // Create a text node
                  singleuploadptag.appendChild(textnode);
                  if(vm.MultipleFileUpload1uploadPercentage === 100){
                    vm.MultipleFileUpload1runningProgress = vm.MultipleFileUpload1runningProgress+vm.MultipleFileUpload1uploadPercentage
                    vm.MultipleFileUpload1runningpercentage = Number(((vm.MultipleFileUpload1runningProgress/vm.MultipleFileUpload1uploadTotal)*100).toFixed(2))      
                    console.log(vm.MultipleFileUpload1runningProgress,vm.MultipleFileUpload1runningpercentage,vm.MultipleFileUpload1uploadPercentage,vm.MultipleFileUpload1uploadTotal)            
                    vm.MultipleFileUpload1listuploadcount = vm.MultipleFileUpload1listuploadcount-1+2
                    var totaltext = document.createTextNode(vm.MultipleFileUpload1runningpercentage+'% complete  - '+vm.MultipleFileUpload1listuploadcount+' of '+vm.MultipleFileUpload1totallist+' files uploaded');
                    totalprogresselement.setAttribute('value',vm.MultipleFileUpload1runningpercentage)
                    if(totalprogresselementptag.childNodes[0]){
                    totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                    }         // Create a text node
                    totalprogresselementptag.appendChild(totaltext);
                  }
                  
                      switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                          console.log('Upload is paused');
                          break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                          console.log('Upload is running');
                          break;
                      }
                    }, function(error) {
                      // Handle unsuccessful uploads
                    }, function() {
                      // Handle successful uploads on complete
                      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        console.log('File available at', downloadURL);
                      });
                    });
                    uploadTask
                    .then(snapshot => snapshot.ref.getDownloadURL())
                      .then((url) => {
                        MultipleFileUpload1document.fileurl = url
                        //MultipleFileUpload1TEMPARRAY.push(MultipleFileUpload1document)
                          
                        db.collection('currentcustomers').doc(this.$route.params.id).collection('multiplefileupload1').doc(newdocid).set(MultipleFileUpload1document).then(doc => {
                          if(this.MultipleFileUpload1runningpercentage === 100){
                          //this.UploadingMultipleFileUpload2 = false
                          this.$emit('ActivateMultipleFilesUploadNotification',false)
                          this.MultipleFileUpload1totallist = 0
                          this.MultipleFileUpload1uploadTotal = 0
                          this.MultipleFileUpload1runningpercentage = 0
                          this.MultipleFileUpload1runningProgress = 0
                          this.MultipleFileUpload1uploadPercentage = 0
                          this.MultipleFileUpload1listuploadcount = 0
                          //elmnt.style.display = "none";
                          this.closeMultipleFileUpload1dialog()
                          }
                          
                        })
                      })
                        
                }, 300);
                })
        
      }
      
    },
      
            GetProcessData(){
        
        if(this.CurrentCustomerData.ActiveProcess){
            this.ActiveProcess = this.CurrentCustomerData.ActiveProcess
            this.Application1000001 = this.CurrentCustomerData.Application1000001
            this.ActiveProcessDocid = this.CurrentCustomerData.ActiveProcessDocid
            let ProcessObj = this.EntityProcesses.find(obj => obj.Number == this.ActiveProcess.Number)
            this.ProcessStages = ProcessObj.ProcessStages
             if (typeof this.CurrentCustomerData.stagenameid !== 'undefined'){
                let stageobj = this.ProcessStages.find(obj => obj.Number === this.CurrentCustomerData.stagenameid)
                this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
              }
            db.collection('currentcustomers').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            this.StageName = stagedata.StageName
            if(this.StageName){
              let stageobj = this.ProcessStages.find(obj => obj.Number === this.CurrentCustomerData.stagenameid)
            this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
            this.ProcessStages = this.ProcessStages
            .map(stage => {
              if(stage.Steps && stage.Steps.length>0){
                stage.Steps = stage.Steps.map(step => {
                  if(typeof stagedata[step.Name] !== 'undefined'){
                    step.Prop = stagedata[step.Name]
                  }
                  return step
                })
              }
              return stage
            })
            }
            })
            }
      },
      AssignProcess(){
      this.SelectProcessDialog = true
    },
    AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
      db.collection('currentcustomers').doc(this.$route.params.id).update({
        ActiveProcess: this.ActiveProcess
      })
      let processdoc = this.ActiveProcess.Name.split(' ').join('')+this.ActiveProcess.Number
      console.log(processdoc)
      console.log(typeof this[processdoc],this[processdoc])
      console.log(!this[processdoc])
      if(!this[processdoc]){
        // let ProcessDoc = 
        db.collection('currentcustomers').doc(this.$route.params.id).collection('processes').add(this.ActiveProcess).then(doc => {
          db.collection('currentcustomers').doc(this.$route.params.id).update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
            let Stage = this.ActiveProcess.ProcessStages[0]
            this.updateStatus(Stage)
        })
       
        this.SelectProcessDialog = false
      }
      else{
        db.collection('currentcustomers').doc(this.$route.params.id).collection('processes').doc(this[processdoc]).update({
         DisplayName: this.ActiveProcess.DisplayName,
         Name: this.ActiveProcess.Name,
         ID: this.ActiveProcess.Number,
          ProcessStages: this.ActiveProcess.ProcessStages
        })
        db.collection('currentcustomers').doc(this.$route.params.id).update({
            ActiveProcessDocid: this[processdoc]
          })
          this.ActiveProcessDocid = this[processdoc]
          db.collection('currentcustomers').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            if(stagedata){
            this.StageName = stagedata.StageName
            if(this.StageName){
            this.StageID = stagedata.StageName.Number            
            }
            }
            this.SelectProcessDialog = false
            })
          }
    },
    IsComplete(Stage){
      if(this.StageID){
      return this.ProcessStages[this.StageID-1].Number >= Stage.Number  
      }
      
    },
    UpdateStages(Prop,value){
      db.collection('currentcustomers').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).update({
        [Prop]: value
      })
    },
    updateStatus(Stage){
          setTimeout(() => {
          db.collection('currentcustomers').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).update({
            StageName: Stage,
            StageID: Stage.Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
          })
          db.collection('currentcustomers').doc(this.$route.params.id).update({
            stagename: Stage,
            stagenameid: Stage.Number,
          })
          
        }, 300)
        },



    
      
      
  
        
        
        
        
        
      
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    
    

    
  onDelivery_PhotoUpload() {
    //singleuploadfile.FeaturedPhoto true
    let vm = this
    let filename = vm.selectedDelivery_PhotoFile.name
        let storepath = 'CurrentCustomers/'+vm.$route.params.id+'/Delivery_Photo/'+filename
        var storageRef = firebase.storage().ref(storepath);
        var uploadTask = storageRef.put(vm.selectedDelivery_PhotoFile);
        vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,vm.selectedDelivery_PhotoFile,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let Delivery_PhotoObject = {
              fileurl: url,
              url: url,
              Delivery_PhotoThumbURL: thumbresult,
              FileType: vm.selectedDelivery_PhotoFile.type,
              Modified_By: {Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
              Modified_Byid: vm.UserRecord.id,
              Modified_On: new Date(),
              ModifiedDate: vm.selectedDelivery_PhotoFile.FileModifiedDate,
              Size: vm.selectedDelivery_PhotoFile.size,
              StorageRef: storepath,
              filename: filename
            }
            db.collection('currentcustomers').doc(vm.$route.params.id).update({
                    Delivery_Photo: Delivery_PhotoObject,
                    Delivery_PhotoThumbURL: Delivery_PhotoObject.Delivery_PhotoThumbURL
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added Delivery Photo to - '+vm.Name,
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            vm.selectedDelivery_PhotoFile = ''
                })
                  vm.Delivery_Photofilesnackbar = true
         })
      },
      closeDelivery_Photo(){
      this.Delivery_PhotolocalURL = ''
      this.selectedDelivery_PhotoFile = ''
    },
      onDelivery_PhotoFileselected(event) {
        this.selectedDelivery_PhotoFile = event.target.files[0]
        this.selectedDelivery_PhotoFile.FileModifiedDate = new Date(this.selectedDelivery_PhotoFile.lastModified)
         if(this.ReviseCurrentCustomerDialog){
          this.RevisedCurrentCustomerName = this.selectedDelivery_PhotoFile.name
        }
        this.Delivery_PhotolocalURL = URL.createObjectURL(this.selectedDelivery_PhotoFile)
      },

      
      
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
  }
}


</script>

<style>

.delivery_photocontentgrad {
  background-image: linear-gradient(to right, rgba(255,0,0,0.5), rgb(255,0,0, 1));
}
#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  