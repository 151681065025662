<template>
  <span>
     
      <v-row class="justify-center" v-if="CompProperties.Style === 'Cards' && elmnt.ShowSummary">
         <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" >
           <v-card class="mx-4" :color="elmnt.ContextTitleColor" round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                <v-card-title class="justify-center" style="align:center;">
            <v-avatar tile size="70" class="transparent mx-3" >
                <v-img contain src="@/assets/RAInvert.png"/>
            </v-avatar>
                </v-card-title>
            </v-card>
            <v-card rounded >
                <v-card-title class="justify-end overline">
                    {{elmnt.Title}}
                </v-card-title>
                 <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                   {{elmnt.ContextValue}}
                </v-card-title>
                <v-divider>
                </v-divider>
                <v-card-actions>
                    {{elmnt.Description}}
                    <v-spacer>
                    </v-spacer>
                    <v-btn class="green--text" x-small>
                         <v-icon x-small color="green">mdi-filter</v-icon>Filter
                         </v-btn>
                         <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                           <v-icon x-small color="blue">mdi-database</v-icon>View Data
                         </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
      </v-row>
      <v-row class="justify-center" v-if="CompProperties.Style === 'Cards' && !elmnt.ShowSummaryOnly">
          <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" v-for="arritem in elmnt.Context" :key="arritem.itemObjKey">
           <v-card class="mx-4" :color="arritem.ContextTitleColor" round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                <v-card-title class="justify-center" style="align:center;">
            <v-avatar tile size="70" class="transparent mx-3" >
                <v-img contain src="@/assets/RAInvert.png"/>
            </v-avatar>
                </v-card-title>
            </v-card>
            <v-card rounded >
                <v-card-title class="justify-end overline">
                    {{arritem.ContextTitle}}
                </v-card-title>
                 <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                   {{arritem.ContextValue}}
                </v-card-title>
                <v-divider>
                </v-divider>
                <v-card-actions>
                    {{arritem.Description}}
                    <v-spacer>
                    </v-spacer>
                    <v-btn class="green--text" x-small>
                         <v-icon x-small color="green">mdi-filter</v-icon>Filter
                         </v-btn>
                         <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                           <v-icon x-small color="blue">mdi-database</v-icon>View Data
                         </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
      </v-row>
      
      <v-row class="justify-center" v-if="CompProperties.Style === 'List' && elmnt.ShowSummary">
         
          <v-flex xl12 lg12 md12 sm12 xs12 >
            <v-card height="100%" class="mx-3" tile  :dark="AppisDarkMode">
                <v-card-title class="mediumoverline">
                         {{elmnt.Title}}<v-spacer></v-spacer>{{elmnt.ContextValue}}
                        </v-card-title>
                         <v-card-text v-if="elmnt.GoalMeasurements">
                            <v-list dense class="transparent" width="100%">
                                <!-- <v-list-item-title class="mediumoverline">
                                     <span :class="elmnt.AchievementClass">
                                    {{elmnt.Achievement}}</span>
                                </v-list-item-title>
                                <v-list-item class="overline">
                                    Target <v-spacer></v-spacer>{{elmnt.ContextTarget}}
                                </v-list-item>
                                <v-list-item class="overline">
                                    Achieved <v-spacer></v-spacer>{{elmnt.ContextValue}}
                                </v-list-item> -->
                                <v-list-item v-for="breakdown in elmnt.Timelines" :key="breakdown.itemObjKey" >
                                    <v-list-item-avatar>
                                            <v-icon small>mdi-poll-box</v-icon>
                                        </v-list-item-avatar>
                                    <v-list-item-content>
                                    <v-list-item-title>
                                        {{breakdown.Name}}
                                        </v-list-item-title>                    
                                        <v-list-item-subtitle>
                                        <v-progress-linear :color="breakdown.Color" :value="breakdown.Value"></v-progress-linear>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                        {{breakdown.AchievedText}}  ({{breakdown.Achieved}} out of {{breakdown.Target}})
                                        </v-list-item-subtitle>  
                                        <!-- <v-list-item-subtitle>
                                        {{breakdown.Achieved}} out of {{breakdown.Target}}
                                        </v-list-item-subtitle>                         -->
                                    </v-list-item-content>
                                    <v-list-item-content>
                                    <v-list-item-title class="justify-end" v-if="breakdown.PercentageText">
                                        <h2 :class="breakdown.IncreaseColor+'--text justify-end caption'"><v-icon :color="breakdown.IncreaseColor">mdi-menu-up</v-icon>
                                                {{breakdown.PercentageText}}</h2>
                                        </v-list-item-title>                    
                                        <v-list-item-subtitle class="caption justify-end">
                                            {{breakdown.Period}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="breakdown.NotificationCount && breakdown.NotificationCount>0">
                                            <v-menu
                                                open-on-hover
                                                top
                                                offset-y
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                                                mdi-bell-check
                                                </v-icon>
                                                <v-badge color="warning" class="mx-1" v-if="breakdown.NotificationCount && breakdown.NotificationCount>0" overlap>
                                                    <span slot="badge"> {{breakdown.NotificationCount}}</span>
                                                    </v-badge>
                                                </template>
                                                <v-card max-width="400">
                                                <v-card-title>
                                                    Notifications
                                                </v-card-title>
                                                <v-card-text class="caption"  v-html="breakdown.Notice">

                                                </v-card-text>
                                                </v-card>
                                                
                                            </v-menu>
                                            </v-list-item-action>
                                    <v-list-item-action>
                                        <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(breakdown)">
                                            <v-icon x-small color="blue">mdi-database</v-icon>View Data
                                            </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            
                        </v-card-text>
                    <v-list dense>
                        
                        <v-list-item v-if="!elmnt.GoalMeasurements">
                           <v-btn class="green--text" x-small>
                                <v-icon x-small color="green">mdi-filter</v-icon>Filter
                                </v-btn>
                                <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(elmnt)">
                                <v-icon x-small color="blue">mdi-database</v-icon>View Data
                                </v-btn>
                        </v-list-item>
                        <v-divider></v-divider>
                       
                    </v-list>
                    
                    </v-card>
          </v-flex>
      </v-row>
      <v-row class="justify-center" v-if="CompProperties.Style === 'List' && !elmnt.ShowSummaryOnly">
         
          <v-flex xl12 lg12 md12 sm12 xs12 >
            <v-card height="100%" class="mx-3" tile  :dark="AppisDarkMode">
                <v-card-title class="mediumoverline">
                        <v-spacer></v-spacer>{{elmnt.ContextValue}}
                        </v-card-title>
                    <v-list dense v-if="!elmnt.IsActivities">
                        <div v-for="arritem in elmnt.Context" :key="arritem.itemObjKey">
                        <v-list-item>
                            <v-list-item-content class="overline">
                                {{arritem.ContextTitle}}
                            </v-list-item-content>
                            <v-list-item-content class="mediumoverline">
                                {{arritem.ContextValue}}
                            </v-list-item-content>
                        <v-list-item-content class="justify-end overline">
                                <v-btn class="green--text" x-small>
                                <v-icon x-small color="green">mdi-filter</v-icon>Filter
                                </v-btn>
                                <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                                <v-icon x-small color="blue">mdi-database</v-icon>View Data
                                </v-btn>
                            </v-list-item-content>
                    
                        </v-list-item>
                        <v-divider></v-divider>
                        </div>
                    </v-list>
                    <v-list :dark="AppisDarkMode" v-if="elmnt.IsActivities">
                         <v-list-item-title >
                                <v-select @change="CheckforCharts(elmnt,i,index)" class="mx-2" dense  label="Owner" return-object :items="UsersArray" item-text="Full_Name" clearable v-model="elmnt.ActivityOwnerFilter"/>
                                </v-list-item-title>
                        <v-menu  v-for="item in elmnt.RawData" :key="item.itemObjKey"
                                transition="scale-transition"
                                right
                                open-on-hover
                                top
                                max-width="400"
                            >
                        <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" >
                            {{item.name}}
                        </v-list-item>
                        </template>
                        <v-card dark>
                        <v-card-title class="mediumoverline" style="text-transform:uppercase;">{{item.name}}
                            </v-card-title>
                            <v-card-subtitle>
                            <v-list :dark="AppisDarkMode" class="transparent">
                            <v-list-item>
                                <v-list-item-avatar class="blue" tile size="50">
                                <img v-if="item.Owner.Profile_Photo "
                                :src="item.Owner.Profile_Photo "    
                                style="object-fit: cover;"          
                                >
                                <img v-if="!item.Owner.Profile_Photo "
                                    src="@/assets/BlankProfilePic.png"   
                                    style="object-fit: cover;"           
                                >                      
                                </v-list-item-avatar>
                                <v-list-item-content >
                                <v-list-item-title>Owner - {{item.Owner.Full_Name}}</v-list-item-title>
                                <v-list-item-subtitle>
                                    Due by {{item.duedate.toDate()}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    Created By {{item.creatorfullname}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.createdon.toDate">
                                    Created On {{item.createdon.toDate()}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    Created On {{item.createdon}}
                                </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>                   
                            </v-card-subtitle>
                            <v-divider>
                            </v-divider>
                            <v-card-text>
                            {{item.details}}
                            </v-card-text>
                            <v-divider>
                            </v-divider>
                            <v-card-actions v-if="item.regardingrecord">
                            <span class="blue--text overline">
                            {{item.regardingrecord.Name}}
                            </span>
                            <v-spacer>
                            </v-spacer>
                            <v-btn target="_blank" :to="item.regardingrecord.Link">Regarding</v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-menu>
                        </v-list>
                    </v-card>
          </v-flex>
      </v-row>
  </span>
</template>

<script>
import DashboardElmtDataContext from '@/components/Dashboards/DashboardElmtDataContext'
import DashboardElmtChart from '@/components/Dashboards/DashboardElmtChart'

export default {
    name: 'DashboardElmtDataContext',
    props: ['elmnt','CompProperties','HasData','i','index','UsersArray','AppisDarkMode'],
    components: {
        DashboardElmtDataContext,
        DashboardElmtChart,
        },
    data(){
        return{

        }
    },
    computed: {

    },
    created(){

    },
    methods:{
         ActivateActiveDashboardTable(arritem){
            this.$emit('ActivateActiveDashboardTable',arritem)
        },
    }
}
</script>

<style>

</style>


    