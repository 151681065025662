
<template>									
<v-main class="grey lighten-3" style="margin-top:-60px;padding-bottom:350px;margin-bottom:-150px;">									
    <!-- <v-card class="grey lighten-4 stickytopconscious" width="100%" height="100%" flat> -->									
    <!-- <v-card class="transparent"> -->									
      <!-- <div  class="my-10"> -->									
          <v-card class="transparent" width="100%" flat tile									
        style="margin-bottom:-50px;z-index:0">									
        									
          <v-img src="@/assets/RASolidA-Light.jpeg"  									
        height="150">									
            <v-img									
        height="150"									
        src="@/assets/RAInvert-BG.png"									
            contain									
        >									
            									
              									
             									
            </v-img>  									
          </v-img>									
        </v-card>									
      <v-expansion-panels>									
            <v-expansion-panel>									
                <v-expansion-panel-header>Page Properties									
                </v-expansion-panel-header>									
                <v-expansion-panel-content>									
                     <v-text-field v-model="PageName" label="Name" />									
          <v-textarea v-model="PageOverview" label="verview" />									
          <v-select v-model="PageHeadersFont" label="Headers Font"                                  
                            :items="FontOptions">
                            <template v-slot:item="{ item }">
                                <span :style="'font-family:'+item">{{item}}
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                    </p>
                                    </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span :style="'font-family:'+item">{{item}}</span>
                            </template>
                            </v-select>                                 
                            <v-select v-model="PublishType" label="Visbility"                                   
                            :items="PublishTypes"/>                                 
            <v-select v-model="PageContentFont" label="Body Font"                                   
            :items="FontOptions">
                            <template v-slot:item="{ item }">
                                <span :style="'font-family:'+item">{{item}}
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                    </p>
                                    </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span :style="'font-family:'+item">{{item}}</span>
                            </template>
                            </v-select>									
            <v-select v-model="PageHeadersFontSize" :items="[24,36,42,50]"									
            />									
                </v-expansion-panel-content>									
            </v-expansion-panel>									
        </v-expansion-panels>    									
        									
        									
        									
        									
            <v-flex lg12 md12 sm12 xs12>									
                <v-dialog v-model="OptionSetDialog" width="800px">									
  <v-card class="slightlypadded">									
    <v-img src='@/assets/RABaseBG.jpeg'>									
      <v-card-title class="headline blue-grey darken-2 white--text">									
                {{EditOptionSet.Name}} - Configuration <v-layout class="justify-end">									
      <v-btn fab @click="SaveOptionSets()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelOptionSetDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn>									
      </v-layout>									
                </v-card-title>									
      <v-list>                                                									
        <v-list-item>                                      									
        <v-list-item-content>									
          <v-switch									
              v-model="EditOptionSet.Secondary"									
              class="mx-2"									
              label="Secondary Field"									
            ></v-switch>									
            </v-list-item-content>									
          <v-list-item-content>									
            <v-switch									
              v-model="EditOptionSet.IsFilter"									
              class="mx-2"									
              label="Filter Field"									
            ></v-switch>									
          </v-list-item-content>									
          <v-list-item-content>									
          <v-switch									
            v-model="EditOptionSet.IsMandatory"									
            class="mx-2"									
            label="Mandatory Field"									
          ></v-switch>									
          </v-list-item-content>                                    									
        </v-list-item>									
        </v-list>									
        <v-select item-text="Name" :items="EditOptionSet.Options" return-object v-model="EditOptionSet.DefaultOption" label="Default Option">									
        </v-select>									
  <v-layout row  color="rgb(236, 60, 126, 0.7)">									
      									
    <v-col cols="4">									
      <v-list>									
        <v-list-item>									
          <v-list-item-content class="listtitle">									
          Options									
          </v-list-item-content>									
          <v-list-item-action>									
            <v-menu 									
          :close-on-content-click="false"                            									
          transition="scale-transition"                            									
          >									
          <template v-slot:activator="{ on }">									
              <v-btn fab icon v-on="on"><v-icon>mdi-plus-thick</v-icon></v-btn>									
            </template>									
            <v-card width="400px">									
              <v-card-title>									
                New Option for {{EditOptionSet.Name}}									
                </v-card-title>									
                <v-text-field v-model="OptionName" label="Name"></v-text-field>                                                        									
                  <v-card-actions>									
                      <v-btn v-if="OptionName !== ''" @click="AddOptionSetOption()">Save</v-btn><v-btn @click="CancelGlobalOptionSetOption()">Cancel</v-btn>									
                  </v-card-actions>									
              </v-card>									
          </v-menu>									
            </v-list-item-action>									
          </v-list-item>									
        </v-list>									
        									
        <v-list>									
        <v-list-item v-for="optionsetoption in EditOptionSet.Options" :key="optionsetoption.itemObjKey" class="listoutline" @click="OptionNameSelect(optionsetoption)">									
          <v-list-item-content>									
            {{optionsetoption.Name}}									
            </v-list-item-content>									
          </v-list-item>									
        </v-list>									
      </v-col>									
      <v-col>									
        <!-- <v-scroll-y-transition mode="in-out" v-if="typeof EditOptionSet.Name !== 'undefined'"> -->									
									
            <v-card outlined  height="100%" width="100%" color="rgb(255, 255, 255, 0.9)" v-if="typeof EditOptionSet.Name !== 'undefined'">                                									
                <v-card-title class="headline blue-grey darken-2 white--text" v-if="typeof Option.Name !== 'undefined'">									
                Option - {{Option.Name}}									
                  <!-- <v-spacer></v-spacer>									
                  <v-switch dark									
                    v-model="Option.IsDefault"									
                    class="mx-2"									
                    label="Is Default"									
                  ></v-switch> -->									
                </v-card-title>									
                <v-card-text >									
                  <p v-if="typeof Option.ID !== 'undefined'">Option ID: {{Option.ID}}</p>									
                  <v-text-field label="Option Name" v-model="Option.Name" v-if="typeof Option.Name !== 'undefined'"></v-text-field>									
                  <v-layout class="justify-end" v-if="typeof Option.Name !== 'undefined'"><v-icon @click="DeleteOption(Option)" color="red">mdi-delete-forever</v-icon></v-layout>                                                       									
                </v-card-text>									
                <v-card-text v-if="typeof Option.Name === 'undefined'">									
                  Add an option to the left<span v-if="EditOptionSet.Options.length>0">, or select an existing option to change its name</span>...                                                    									
                </v-card-text>									
                                        									
            </v-card>									
                                									
            <!-- </v-scroll-y-transition> -->									
        </v-col>									
    </v-layout>									
    									
    <!-- <v-layout class="justify-end">									
      <v-btn fab @click="SaveGlobalOptionSets()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn>									
      </v-layout> -->									
      <v-layout class="justify-end">									
      <v-btn fab @click="DeleteFieldOffTabSectionANDRecordType(EditTab,EditSection,EditOptionSet)" icon><v-icon color="red" x-large>mdi-delete-forever</v-icon></v-btn>									
      </v-layout>									
      </v-img>									
      									
    </v-card>									
  </v-dialog>									
  									
  <v-dialog v-model="EditTestimonyDialog" max-width="400" dark v-if="EditedTestimony" persistent>									
                    <v-card width="400">									
                        <v-card-text>									
                            <input type="file"  @change="NewTestimonialImageFileselected($event)"/>									
                             <v-avatar									
                            size="150"									
                            									
                            >									
                            <v-img contain v-if="!SampleTestinomialFile && EditedTestimony.Avatar"									
                                :src="EditedTestimony.Avatar"									
                                />									
                                <v-img contain v-if="SampleTestinomialFile"									
                                :src="SampleTestinomialFile"									
                                />									
                                <v-img contain v-if="!EditedTestimony.Avatar && !SampleTestinomialFile"									
                                src="@/assets/ImageHolder.png"									
                                />									
                            </v-avatar>									
                            <v-btn v-if="SampleTestinomialFile" @click="SaveTestimonialFile()">									
                                Save									
                            </v-btn>									
                        </v-card-text>									
                        <v-card-text>									
                           <v-list									
                      class="transparent"									
                      dark									
                      height="100%"									
                      dense									
                      >									
                          <v-list-item >									
                          <v-text-field label="Name" v-model="EditedTestimony.Name" />									
                      </v-list-item>									
                         									
                      <v-list-item>									
                         <v-btn-toggle dark>									
                                                									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<h1>')">									
                                H1									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<h2>')">									
                                H2									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<h3>')">									
                                H3									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<p>')">									
                               <v-icon>mdi-format-paragraph </v-icon>									
                            </v-btn>									
                            									
                            									
                            </v-btn-toggle>									
                                 									
                      </v-list-item>									
                      <v-list-item>									
                         <v-btn-toggle dark>									
                            									
                            <v-btn x-small onclick="document.execCommand('justifyLeft',false,'')">									
                                <v-icon x-small>mdi-format-align-left</v-icon>									
                            </v-btn>									
                            <!-- <v-btn>									
                                <v-icon>mdi-format-align-center</v-icon>									
                            </v-btn> -->									
                            <v-btn x-small onclick="document.execCommand('justifyCenter',false,'')">									
                                <v-icon x-small>mdi-format-align-justify</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('justifyRight',false,'')">									
                                <v-icon x-small>mdi-format-align-right</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('insertUnorderedList',false,'')">									
                                <v-icon x-small>mdi-format-list-bulleted</v-icon>									
                            </v-btn>									
                            									
                            									
                            </v-btn-toggle>									
                                 									
                      </v-list-item>									
                      <v-list-item>									
                          <v-btn-toggle dark>									
                            <v-btn x-small onclick="document.execCommand('fontSize', 0, '12px');">									
                                <v-icon x-small>mdi-format-title</v-icon>									
                            </v-btn>									
                    									
                            									
                            <v-btn x-small onclick="document.execCommand('bold', false, '');">									
                                <v-icon x-small>mdi-format-bold</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('italic', false, '');">									
                                <v-icon x-small>mdi-format-italic</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('underline', false, '')">									
                                <v-icon x-small>mdi-format-underline</v-icon>									
                            </v-btn>									
                           									
                            </v-btn-toggle>									
                      </v-list-item>									
                  									
                       <v-list-item>									
                          <v-btn-toggle dark>									
                        									
                                <v-btn x-small>									
                                    <v-icon @click="link()">mdi-link</v-icon>									
                                </v-btn>									
                                <v-btn x-small>									
                                    <v-icon x-small @click="addphone()">mdi-phone</v-icon>									
                                </v-btn>									
                                <v-btn x-small>									
                                    <v-icon x-small @click="addemail()">mdi-email</v-icon>									
                                </v-btn>									
                                <v-btn x-small onclick="document.execCommand('strikeThrough',false,'')">									
                                    <v-icon x-small>mdi-format-strikethrough</v-icon>									
                                </v-btn>									
                                </v-btn-toggle>									
                      </v-list-item>									
                      <v-list-item>									
                          <v-card width="90%" min-height="300px" outlined flat v-html="EditedTestimony.Description" contenteditable 									
                          :id="EditedTestimony.Name.split(' ').join('_')+'editor'">									
                                 </v-card>									
                      </v-list-item>									
                      <v-list-item>									
                          <v-btn @click="UpdateTestimonyHTMLBox()">Update</v-btn>									
                      </v-list-item>									
                           </v-list>									
                        </v-card-text>									
                        									
                        <v-card-actions>                            									
                            <v-spacer></v-spacer>									
                            <v-btn @click="CancelEditTestimony()">Close</v-btn>									
                        </v-card-actions>									
                    </v-card>									
                </v-dialog>									
                <v-dialog v-model="FieldsDialogActivate" max-width="400" dark>									
                    <v-card width="400">									
                        <v-card-title>									
                            {{EditedTab.FormName}}									
                        </v-card-title>									
                        <v-list dense >									
                            <v-btn @click="AddFieldtoWebForm()">Add Field</v-btn>									
                            									
                            <v-list-item v-for="field in EditedTab.FormFields" :key="field.itemObjKey">									
                                <v-list-item-content>									
                                <v-text-field label="Field Name" v-model="field.Name"></v-text-field>									
                                </v-list-item-content>									
                                <v-list-item-content>									
                                <v-select label="Field Type" :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']" v-model="field.Type"></v-select>									
                                </v-list-item-content>									
                                </v-list-item>            									
                            </v-list>									
                        <v-card-actions>									
                            <v-btn>Cancel</v-btn>									
                            <v-spacer></v-spacer>									
                            <v-btn>Save</v-btn>									
                        </v-card-actions>									
                    </v-card>									
                </v-dialog>									
                <v-navigation-drawer									
                right									
                app									
                 width="300"									
                  v-model="ContentTypesDialog"									
                  class="blue-grey lighten-5"									
                  >									
                  <v-tabs  background-color="blue-grey lighten-5">									
                       <v-tab>									
                            Layout									
                        </v-tab>									
                        <v-tab-item class="blue-grey lighten-5">									
                            <!-- <v-treeview									
                                activatable									
                                :items="items"									
                            ></v-treeview> -->									
                            <v-list dense>									
                                <v-list-item>									
                                 <v-list-item-title>Body</v-list-item-title>									
                                    <v-list-item-avatar size="20" @click="InsertTab()">									
                                            <v-icon small class="links--text actionicon">mdi-plus-thick</v-icon>									
                                        </v-list-item-avatar>									
                                </v-list-item>									
                                <!-- <v-treeview									
                                        activatable									
                                        color="links"									
                                        :items="PageTabs"									
                                        :key="i"									
                                    >									
                                    <template v-slot:prepend="{ item }"> 									
                                        <v-list>									
                                            <v-list-item>									
                                                <v-list-item-content @click="OpenDialog(item,i)">									
                                                    {{item.Name}}									
                                                </v-list-item-content>									
                                                 <v-list-item-avatar size="20"  @click.prevent="AdjustRowAmount(item,i)">									
                                             <v-icon  v-if="item.SectionType"  small class="links--text actionicon">mdi-plus-thick</v-icon>									
                                        </v-list-item-avatar>									
                                            </v-list-item>									
                                        </v-list>									
                                      									
                                    </template>									
                                    </v-treeview> -->									
                                    									
                                    <v-list v-for="(tab,i) in PageTabs" :key="tab.itemObjKey">									
                                    <v-list-item     @click="ShowTabElements(i)"									
                                    @dragover.prevent @drop.prevent="Tabdragfinish(i, $event)">									
                                        <v-list-item-avatar size="20">									
                                                    <v-icon v-if="tab.Elements.length>0" small style="display:block;" :id="i+'ShowElementsConfig'" @click="ShowTabElements(i)">mdi-chevron-down</v-icon>									
                                                    <v-icon small style="display:none;" :id="i+'HideElementsConfig'" @click="HideTabElements(i)">mdi-chevron-up</v-icon>									
                                                </v-list-item-avatar>									
                                        <v-list-item-title  :draggable="true" @dragstart="Tabdragstart(tab,i, $event)" @click="OpenDialog(tab,i)">Section {{i-1+2}}</v-list-item-title>									
                                            <v-list-item-avatar size="20" @click.prevent="AdjustRowAmount(tab,i)">									
                                                    <v-icon small class="links--text actionicon">mdi-plus-thick</v-icon>									
                                                </v-list-item-avatar>									
                                                									
                                                									
                                    </v-list-item>									
                                    <v-list :id="i+'ElementsConfig'" style="display:none;padding-left:40px;">									
                                                <v-list-item									
                                                    @click="OpenDialog(elmnt,elmntindex,true,i),ShowTabElements(i)"									
                                                    v-for="(elmnt, elmntindex) in tab.Elements"									
                                                    :key="elmnt.itemObjKey"									
                                                    link									
                                                    @dragover.prevent @drop.prevent="Elmntdragfinish(tab,i,elmnt,elmntindex, $event),ShowTabElements(i)"									
                                                >									
                                                    <v-list-item-title v-text="elmnt.Name"									
                                                    :draggable="true" @dragstart="Elmntdragstart(tab,i,elmnt,elmntindex, $event)"></v-list-item-title>									
									
                                                    <v-list-item-icon>									
                                                    <v-icon class="blue--text" v-text="elmnt.Icon"></v-icon>									
                                                    </v-list-item-icon>									
                                                </v-list-item>									
                                    </v-list>									
                                    </v-list>									
                                   									
                                    <!-- <v-list-group									
                                    v-for="(tab,i) in PageTabs" :key="tab.itemObjKey"									
                                    :open-on-click="false"									
                                    no-action									
                                    sub-group									
                                    >									
                                    <template v-slot:activator>									
                                        <v-list-item-content @click="OpenDialog(tab,i)"									
                                        @dragover.prevent @drop.prevent="Tabdragfinish(i, $event)" :draggable="true" @dragstart="Tabdragstart(tab,i, $event)">									
                                        <v-chip  label style="width:100%;">									
                                            <v-icon>									
                                                {{ tab.Icon }}									
                                            </v-icon>									
                                            Section {{i-1+2}}									
                                            </v-chip>									
                                        </v-list-item-content>									
                                        <v-list-item-avatar size="20" @click.prevent="AdjustRowAmount(tab,i)">									
                                            <v-icon small class="links--text actionicon">mdi-plus-thick</v-icon>									
                                        </v-list-item-avatar>									
                                    </template>									
									
                                    <v-list-item									
                                        @click="OpenDialog(elmnt,elmntindex,true,i)"									
                                        v-for="(elmnt, elmntindex) in tab.Elements"									
                                        :key="elmnt.itemObjKey"									
                                        link									
                                    >									
                                        <v-list-item-title v-text="elmnt.Name"></v-list-item-title>									
									
                                        <v-list-item-icon>									
                                        <v-icon class="blue--text" v-text="elmnt.Icon"></v-icon>									
                                        </v-list-item-icon>									
                                    </v-list-item>									
                                    </v-list-group> -->									
									
                                </v-list>									
                          									
                        </v-tab-item>									
                        <v-tab>									
                            Blocks									
                        </v-tab>									
                        <v-tab-item class="blue-grey lighten-5">									
                            <v-row dense>									
                                <v-col cols="6" md="4"									
                                 v-for="block in RenderedContentBlocks" :key="block.itemObjKey">									
                                 <v-card outlined height="100%" class="mx-2" width="100" @dragend="CancelDrag(block)"									
                            :draggable="true" @dragstart="blockdragstart(block, $event)"> 									
                        <!-- <v-flex lg6 md6 sm6 xs6 > -->									
                            <!-- <v-row									
                            class="fill-height"									
                            align="center"									
                            justify="center"									
                          > -->									
                            									
                               <v-img									
                               contain									
                               class="my-1"									
                                height="80"									
                               src="@/assets/ImageHolder.png"									
                               />									
                               <v-card-title class="caption justify-center">									
                               {{block.Name}}									
                               </v-card-title>									
                             									
                           									
                              <!-- </v-row> -->									
                        <!-- </v-flex> -->									
                         </v-card>									
                                </v-col>									
                            </v-row>									
                            									
                        </v-tab-item>									
                    </v-tabs>									
                </v-navigation-drawer>									
                <v-navigation-drawer									
                app									
                 width="300"									
                  v-model="TabDialog"									
                  dark									
                  >									
                  <v-list-item-title class="overline links white--text" style="padding-left:5px;">									
                         {{EditedTab.Name}}                        									
                         <v-icon class="justify-end" @click="TabDialog = false,EditedTab = '',EditedTabindex = -1">mdi-close</v-icon> 									
                         <v-icon class="justify-end" color="red" @click="DeleteTab()">mdi-delete-forever</v-icon>									
                      </v-list-item-title>									
                      <v-list-item-subtitle class="overline indigo lighten-4" style="padding-left:5px;">									
                          {{ItemName}}									
                      </v-list-item-subtitle>									
                  <v-expansion-panels v-if="EditedTab">									
                      <v-expansion-panel v-if="EditedTab.Name === 'blank' && !EditedTab.SectionType">									
                      <v-expansion-panel-header>									
                          Row Type									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                          <v-select :items="RenderedContentBlocks" v-model="TypeChange" item-text="Name" label="Type" @change="ChangeContentType(EditedTab)" return-object />									
                      </v-expansion-panel-content>									
                      </v-expansion-panel>									
                      <v-expansion-panel v-if="EditedTab.Name === 'Store Products' || EditedTab.Name === 'Counter Single'">									
                      <v-expansion-panel-header>									
                          Data Calls									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content  v-if="EditedTab.Name === 'Store Products'">									
                          <v-select :items="StoreProducts" multiple v-model="SelectedStoreProducts" item-text="Title" label="Type" @change="UpdateStoreProducts(EditedTab)" return-object />									
                      </v-expansion-panel-content>									
                      <v-expansion-panel-content  v-if="EditedTab.Name === 'Counter Single'">									
                          <v-list>									
                            <v-list-item>                           									
                            <v-switch dense label="Count from Data" v-model="EditedTab.CountfromData" />									
                            </v-list-item>									
                        <v-list-item v-if="EditedTab.CountfromData">									
                              <v-select :items="['Amount']" v-model="EditedTab.CountOperation" 									
                                label="Count Operation"/>									
                            </v-list-item>									
                            <v-list-item v-if="EditedTab.CountfromData">									
                              <v-switch dense label="Count is Live" v-model="EditedTab.CountisLive" />									
                            </v-list-item>									
                            <v-list-item v-if="EditedTab.CountfromData">									
                             <v-select :items="DataCountCollections" v-model="EditedTab.DataCountCollection" item-text="Name" label="Collection" return-object  @change="UpdateTabCount(EditedTab)"/>									
                            </v-list-item>									
                            <v-list-item v-if="EditedTab.CountfromData && EditedTab.DataCountCollection">									
                              <v-switch dense label="Count is Filtered" v-model="EditedTab.CountisFiltered"  @change="UpdateTabCount(EditedTab)"/>									
                            </v-list-item>									
                            <v-list-item v-if="EditedTab.CountfromData && EditedTab.DataCountCollection && EditedTab.CountisFiltered">									
                             <v-select :items="EditedTab.DataCountCollection.CountbyOptions" v-model="EditedTab.Countby" item-text="Name" label="Count Filter" return-object />									
                            </v-list-item>									
                            <v-list-item v-if="EditedTab.CountfromData && EditedTab.DataCountCollection && EditedTab.Countby && EditedTab.CountisFiltered">									
                             <v-select :items="DataCollectionCategories" v-model="EditedTab.CountCategory" item-text="Name"									
                                label="Category Field" return-object/>									
                            </v-list-item>									
                            <v-list-item v-if="EditedTab.CountfromData && EditedTab.DataCountCollection && EditedTab.Countby && EditedTab.CountCategory && EditedTab.CountisFiltered">									
                             <v-select :items="DataCollectionCategoryOptions" v-model="EditedTab.CategoryValue"  item-text="Name" @change="UpdateTabCount(EditedTab)"									
                                label="Category Value" return-object/>									
                            </v-list-item>									
                        </v-list>									
                      </v-expansion-panel-content>									
                      </v-expansion-panel>									
                       <v-expansion-panel  >									
                      <v-expansion-panel-header>									
                          <span v-if="EditedTab.SectionType">Row</span><span v-else>Col</span> Styling									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                            <v-list class="transparent" v-if="EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'HTML Box'">									
                                <v-list-item>									
                                    									
                            <v-switch dense label="Transparent" @change="SetRowTransparency(EditedTab)" v-model="EditedTab.Transparent" />									
                            <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker @change="ChangeRowColor(EditedTab.RowColor)" v-model="EditedTab.RowColor"></v-color-picker>									
                            </v-menu>									
                                </v-list-item>									
                            </v-list>									
                              									
                            <v-card flat width="100%" height="20%">									
                                 									
                                  <v-card-text>									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Top									
                                      <v-btn x-small @click="RowTopMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="RowTopMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      <span class="caption">{{EditedTab.MarginTop}}</span>									
                                  </v-card-text>									
                                 									
                                  <v-card-text>									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Btm									
                                      <v-btn x-small @click="RowBottomMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="RowBottomMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      <span class="caption">{{EditedTab.MarginBottom}}</span>									
                                  </v-card-text>									
                               									
                                  <v-card-text>									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Lft									
                                      <v-btn x-small @click="RowLeftMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="RowLeftMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      <span class="caption">{{EditedTab.MarginLeft}}</span>									
                                  </v-card-text>									
                                  									
                                  <v-card-text>									
                                     									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Rgt									
                                      <v-btn x-small @click="RowRightMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="RowRightMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                       <span class="caption">{{EditedTab.MarginRight}}</span>									
                                  </v-card-text>									
                              </v-card>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                        									
                        <v-expansion-panel v-if="EditedTab.Name !== 'Parallax'">									
                      <v-expansion-panel-header>									
                          <span v-if="EditedTab.SectionType">Row</span><span v-else>Col</span> Layout									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>                        									
                                <v-list  v-if="EditedTab.Name !== 'HTML Box' && EditedTab.Name !== 'Web Form'">									
                                    <v-list-item>									
                                    <v-select :items="['justify-start','justify-center','justify-end']" v-model="EditedTab.Justify" 									
                                        label="Horizontal Align"/>									
                                    </v-list-item>									
                                <v-list-item>									
                                        <v-select :items="['flex-start','center','flex-end']" v-model="EditedTab.Alignment" 									
                                        label="Vertical Align"/>									
                                    </v-list-item>									
                                </v-list>									
                                <span class="caption">{{EditedTab.FlexXLRG}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexXLRG"									
                            min="1"									
                            max="12"									
                            label="X Large"									
                                ></v-slider>									
                               									
                          <span class="caption">{{EditedTab.FlexLarge}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexLarge"									
                            min="1"									
                            max="12"									
                            label="Large"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexMedium}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexMedium"									
                            min="1"									
                            max="12"									
                            label="Medium"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexSmall}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexSmall"									
                            min="1"									
                            max="12"									
                            label="Small"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexXSmall}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexXSmall"									
                            min="1"									
                            max="12"									
                            label="X Small"									
                                ></v-slider>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <v-expansion-panel  v-if="EditedTab.Name === 'Testimonials'">									
                           									
                       <v-expansion-panel-header>									
                          Testimonies									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content >									
                          <v-list>									
                              <v-btn @click="AddTestimony(EditedTab)">Add Testimony</v-btn>									
                              <v-list-item v-for="(tes,index) in EditedTab.Elements" :key="tes.itemObjKey">									
                                  <v-list-item-content>									
                                      {{tes.Name}}									
                                  </v-list-item-content>									
                                  <v-list-item-content>									
                                      <v-icon @click="EditTestimony(tes,index)">mdi-cogs</v-icon>									
                                  </v-list-item-content>									
                              </v-list-item>									
                          </v-list>									
                      </v-expansion-panel-content>									
                      </v-expansion-panel>									
                       <!-- <v-expansion-panel>									
                           									
                       <v-expansion-panel-header v-if="EditedTab.Name === 'Card Block Single'">									
                          Card Block Single									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'Card Block Single'">									
                          <v-list>									
                              <v-list-item>									
                                  <v-text-field v-model="EditedTab.CardsCount" label="CardsCount"									
                                  />									
                              </v-list-item>									
                               <v-list-item>									
                                   <v-textarea v-model="EditedTab.Description" label="Description"									
                                  />									
                              </v-list-item>									
                               <v-list-item>									
                                  <v-text-field v-model="EditedTab.icon" label="Icon"									
                                  />									
                                <a href="https://cdn.materialdesignicons.com/4.5.95/" target="_blank">Get Icons</a>									
                              </v-list-item>									
                          </v-list>									
                      </v-expansion-panel-content>									
                      </v-expansion-panel> -->									
                       <v-expansion-panel>									
                           									
                       <v-expansion-panel-header v-if="EditedTab.Name === 'Timeline Item'">									
                          Timeline Item									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'Timeline Item'">									
                          <v-list>									
                              <v-list-item>									
                                  <v-text-field v-model="EditedTab.Title" label="Title"									
                                  />									
                              </v-list-item>									
                               <v-list-item>									
                                   <v-textarea v-model="EditedTab.Description" label="Description"									
                                  />									
                              </v-list-item>									
                               <v-list-item>									
                                  <v-text-field v-model="EditedTab.icon" label="Icon"									
                                  />									
                                <a href="https://cdn.materialdesignicons.com/4.5.95/" target="_blank">Get Icons</a>									
                              </v-list-item>									
                              <!-- <v-list-item>									
                                   <v-switch v-model="EditedTab.Dark" label="Dark"/>									
                              </v-list-item> -->									
                              <v-list-item>									
                                  <v-menu    									
                                    :close-on-content-click="false"									
                                    :nudge-right="40"									
                                    transition="scale-transition"									
                                    offset-y									
                                    min-width="200px"									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                    </template>									
                                <v-color-picker v-model="EditedTab.color"></v-color-picker>									
                                </v-menu>									
                              </v-list-item>									
                              									
                        									
                          </v-list>									
                      </v-expansion-panel-content>									
                      </v-expansion-panel>									
                       <v-expansion-panel>									
                          <v-expansion-panel-header v-if="EditedTab.Name === 'Google My Maps' || EditedTab.Name === 'IFRAME'">									
                              URL									
                          </v-expansion-panel-header>									
                          <v-expansion-panel-content v-if="EditedTab.Name === 'Google My Maps' || EditedTab.Name === 'IFRAME'">									
                            <v-text-field v-if="EditedTab.Name === 'Google My Maps'" v-model="EditedTab.FrameURL" label="URL"/> 									
                            <v-textarea v-if="EditedTab.Name === 'IFRAME'" v-model="EditedTab.FrameURL" label="URL"/>   									
                             <v-list-item v-if="EditedTab.Name === 'IFRAME'">									
                                        <v-select :items="['start','center','end']" v-model="EditedTab.ContentAlign" 									
                                        label="Vertical Align"/>									
                                    </v-list-item>  									
                          </v-expansion-panel-content>									
                      </v-expansion-panel>									
                      <v-expansion-panel v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image' || EditedTab.Name === 'Clipped Tab' || EditedTab.HasImage && EditedTab.Name !== 'Card Block Single' && EditedTab.Name !== 'Card Row Single'">									
                          <v-expansion-panel-header>									
                             <span v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">Image</span> Height									
                          </v-expansion-panel-header>									
                          <v-expansion-panel-content>									
                             <v-card flat width="100%" height="100%">									
                                  <v-card-title> Height Box</v-card-title>									
                                  <v-card-text>									
                                      {{EditedTab.Height}}									
                                      <v-btn  :disabled="EditedTab.Height === '10%'" @click="ImageHeight(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      {{EditedTab.Height}}									
                                      <v-btn :disabled="EditedTab.Height === '100%'" @click="ImageHeight(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      									
                                  </v-card-text>									
                              </v-card>                      									
                          </v-expansion-panel-content>									
                      </v-expansion-panel>									
                       									
                        <v-expansion-panel>									
                          <v-expansion-panel-header v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image' || EditedTab.HasImage || EditedTab.Name === 'Clipped Tab'">									
                              Clipping									
                          </v-expansion-panel-header>									
                          <v-expansion-panel-content v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image' || EditedTab.HasImage || EditedTab.Name === 'Clipped Tab'">									
                              									
                              <v-switch label="Clipped" v-model="EditedTab.Clipped" />									
                              <v-switch label="Clip by Path" v-model="EditedTab.ClippedbyPath" v-if="EditedTab.Clipped"/>									
                              <v-switch label="Clipped Animation" v-model="EditedTab.ClipAnimated" />									
                               <v-select  @change="AssignClipDefaults(EditedTab)" v-if="EditedTab.Clipped && EditedTab.ClippedbyPath" v-model="EditedTab.ClippedPathOption" label="Style" :items="['elipse','circle','beizer','polygon']"/>									
                                <div v-if="EditedTab.ClippedPathOption === 'polygon'">									
                                									
                                    <span class="caption">{{EditedTab.TopLeftCrnrXValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.TopLeftCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Left X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.TopLeftCrnrYValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.TopLeftCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Left Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.TopRightCrnrXValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.TopRightCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Right X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.TopRightCrnrYValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.TopRightCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Right Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomLeftCrnrXValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.BottomLeftCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Left X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomLeftCrnrYValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.BottomLeftCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Left Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomRightCrnrXValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.BottomRightCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Right X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomRightCrnrYValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.BottomRightCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Right Y Value"									
                                ></v-slider>									
                                									
                                </div>									
                                <div v-if="EditedTab.ClippedPathOption === 'elipse'">									
                                <span class="caption">{{EditedTab.ElipseXValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.ElipseXValue"									
                                    min="0"									
                                    max="100"									
                                    label="X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.ElipseYValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.ElipseYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.ElipseXPositionValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.ElipseXPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="X Position"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.ElipseYPositionValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.ElipseYPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="Y Position"									
                                ></v-slider>									
                                </div>									
                                <div v-if="EditedTab.ClippedPathOption === 'circle'">									
                                <span class="caption">{{EditedTab.CircleRadiusValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.CircleRadiusValue"									
                                    min="0"									
                                    max="100"									
                                    label="Radius"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.CircleXPositionValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.CircleXPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="X Position"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.CircleYPositionValue}}</span>									
                                <v-slider @change="AssignClippingValue(EditedTab)"									
                                    v-model="EditedTab.CircleYPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="Y Position"									
                                ></v-slider>        									
                                </div>									
                               <v-menu      									
                                        :close-on-content-click="false"									
                                        :nudge-right="40"									
                                        transition="scale-transition"									
                                        offset-y									
                                        min-width="400px"									
                                        >									
                                        <template v-slot:activator="{ on }">									
                                    <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>									
                                       <v-layout row class="justify-center">									
                                    <v-card width="100%" max-width="1200px" flat style="padding-bottom:50px" dark>									
                                    <v-pagination									
                                        :total-visible="9"									
                                        v-model="SystemAssetsPage"									
                                        circle									
                                        :length="Math.round(SystemAssets.length/10)"									
                                        ></v-pagination>									
									
                                    <v-layout row wrap class="justify-center">									
                                        <v-card flat tile class="mx-3 my-3 transparent" 									
                                        v-for="photo in SystemAssetsPaginated"									
                                        :key="photo.itemObjKey"									
                                        max-width="200px"									
                                        									
                                        >    									
                                            <v-img									
                                            :src="photo.Path"									
                                            aspect-ratio="1"									
                                            width="100"									
                                            >									
                                            <template v-slot:placeholder>									
                                                <v-row									
                                                class="fill-height ma-0"									
                                                align="center"									
                                                justify="center"									
                                                >									
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>									
                                                </v-row>									
                                            </template>									
                                            </v-img>									
                                            <v-btn dark color="green" icon									
                                            @click="AssignImage(EditedTab,'ClippingIMG',photo)">									
                                            <v-icon>									
                                            mdi-check									
                                            </v-icon>									
                                        </v-btn>									
                   									
                                            </v-card>									
                                    </v-layout>									
                                    </v-card>									
                                       </v-layout>									
                                  									
                                    </v-menu>									
                          </v-expansion-panel-content>									
                           </v-expansion-panel>									
                           <v-expansion-panel>									
                          <v-expansion-panel-header v-if="EditedTab.ClipAnimated">									
                              Clipping Animation									
                          </v-expansion-panel-header>									
                          <v-expansion-panel-content v-if="EditedTab.ClipAnimated">									
                                <div v-if="EditedTab.ClipAnimated">									
                                    <v-select label="Style" @change="AssignClippingAnimationValue(EditedTab)" v-model="EditedTab.ClipAnimation" :items="['Spotlight Search']"/>									
                                    <v-select label="Style" @change="AssignClippingAnimationValue(EditedTab)" v-model="EditedTab.CircleAnimationRepeat" :items="['1','2','3','infinite']"/>									
                                									
                                </div>									
                          </v-expansion-panel-content>									
                           </v-expansion-panel>									
                      <v-expansion-panel>									
                          <v-expansion-panel-header v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image' || EditedTab.HasImage">									
                              Image									
                          </v-expansion-panel-header>									
                          <v-expansion-panel-content v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image' || EditedTab.HasImage">									
                            <input type="file"  @change="NewAssetFileselected($event)"/>									
                            <v-icon @click="SaveNewsystemAsset()">mdi-content-save</v-icon>									
                            <v-menu      									
                                        :close-on-content-click="false"									
                                        :nudge-right="40"									
                                        transition="scale-transition"									
                                        offset-y									
                                        min-width="400px"									
                                        >									
                                        <template v-slot:activator="{ on }">									
                                    <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>									
                                      									
                                        <v-tabs dark>									
                                            <v-tab>									
                                                Assets									
                                            </v-tab>									
                                            <v-tab-item>									
                                                <v-layout row class="justify-center">									
                                    <v-card width="100%" max-width="1200px" flat style="padding-bottom:50px" dark>									
                                    <v-pagination									
                                        :total-visible="9"									
                                        v-model="SystemAssetsPage"									
                                        circle									
                                        :length="Math.round(SystemAssets.length/10)"									
                                        ></v-pagination>									
									
                                    <v-layout row wrap class="justify-center">									
                                        <v-card flat tile class="mx-3 my-3 transparent" 									
                                        v-for="photo in SystemAssetsPaginated"									
                                        :key="photo.itemObjKey"									
                                        max-width="200px"									
                                        									
                                        >    									
                                            <v-img									
                                            :src="photo.Path"									
                                            aspect-ratio="1"									
                                            width="100"									
                                            >									
                                            <template v-slot:placeholder>									
                                                <v-row									
                                                class="fill-height ma-0"									
                                                align="center"									
                                                justify="center"									
                                                >									
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>									
                                                </v-row>									
                                            </template>									
                                            </v-img>									
                                            <v-btn dark color="green" icon									
                                            @click="AssignImage(EditedTab,'IMG',photo)">									
                                            <v-icon>									
                                            mdi-check									
                                            </v-icon>									
                                        </v-btn>									
                   									
                                            </v-card>									
                                    </v-layout>									
                                    </v-card>									
                                     </v-layout>									
                                            </v-tab-item>									
                                            <v-tab >									
                                                Libraries {{typeof GalleryLibraries}}									
                                            </v-tab>									
                                            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">                                       									
                                                <v-tabs>									
                                                    <v-tab>									
                                                        {{lib.name}}									
                                                    </v-tab>									
                                                    <v-tab-item>									
                                                        <v-card width="800">									
                                                        <component :is="lib.compname" :FullGallery="true"									
                                                        @AssignImage="AssignImage" :IMGProp="'IMG'"  :AppisDarkMode="true"									
                                                        :tab="EditedTab" />									
                                                        </v-card>									
                                                    </v-tab-item>									
                                                </v-tabs>									
                                            </v-tab-item>									
                                        </v-tabs>									
                                     									
                                    </v-menu>									
                                   									
                                     <v-switch label="Has Parallax Gradient" v-model="EditedTab.HasParallaxGradient" />									
                                    <v-text-field label="Gradient" v-model="EditedTab.ParallaxGradient" 									
                                    v-if="EditedTab.HasParallaxGradient"/>									
                                    <a href="https://cssgradient.io/" target="_blank">Get Code</a>									
                                    <v-switch label="Transparent" v-model="EditedTab.Transparent" />									
                                    <v-switch label="Has Text Box" v-model="EditedTab.HasTextBox" />									
                                    <v-switch label="Has Logo" v-model="EditedTab.HasLogo" />									
                                    <v-switch label="Logo  at Bottom" v-model="EditedTab.LogoIsBottom" v-if="EditedTab.HasLogo"/>									
                                    <v-menu      									
                                        :close-on-content-click="false"									
                                        :nudge-right="40"									
                                        transition="scale-transition"									
                                        offset-y									
                                        min-width="400px"									
                                        >									
                                        <template v-slot:activator="{ on }">									
                                    <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>									
                                            <v-layout row class="justify-center">									
                                    <v-card width="100%" max-width="1200px" flat style="padding-bottom:50px" dark>									
                                    <v-pagination									
                                        :total-visible="9"									
                                        v-model="SystemAssetsPage"									
                                        circle									
                                        :length="Math.round(SystemAssets.length/10)"									
                                        ></v-pagination>									
									
                                    <v-layout row wrap class="justify-center">									
                                        <v-card flat tile class="mx-3 my-3 transparent" 									
                                        v-for="photo in SystemAssetsPaginated"									
                                        :key="photo.itemObjKey"									
                                        max-width="200px"									
                                        									
                                        >    									
                                            <v-img									
                                            :src="photo.Path"									
                                            aspect-ratio="1"									
                                            width="100"									
                                            >									
                                            <template v-slot:placeholder>									
                                                <v-row									
                                                class="fill-height ma-0"									
                                                align="center"									
                                                justify="center"									
                                                >									
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>									
                                                </v-row>									
                                            </template>									
                                            </v-img>									
                                            <v-btn dark color="green" icon									
                                            @click="AssignImage(EditedTab,'LogoSRC',photo)">									
                                            <v-icon>									
                                            mdi-check									
                                            </v-icon>									
                                        </v-btn>									
                   									
                                            </v-card>									
                                    </v-layout>									
                                    </v-card>									
                                     </v-layout>									
                                        <!-- <v-card>									
                                        <v-row>									
                                            <v-col									
                                             v-for="asset in SystemAssets" :key="asset.itemObjKey"									
                                            class="d-flex child-flex"									
                                            cols="4"									
                                            >									
                                            <v-img @click="AssignImage(EditedTab,'LogoSRC',asset)" height="100" contain :src="asset.Path"/>									
                                            </v-col>									
                                        </v-row>									
                                        </v-card> -->									
                                  									
                                    </v-menu>									
                                    <v-select v-model="EditedTab.LogoJustify" label="Logo Justify" :items="['start','end','center']"/>									
                                    									
                          </v-expansion-panel-content>									
                      </v-expansion-panel>									
                        <!-- <v-expansion-panel  v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">									
                            <v-expansion-panel-header v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">									
                          Image Size									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">									
                           <v-slider @change="AssignImageWidth(EditedTab)"									
                                    v-model="EditedTab.IMGWidthValue"									
                                    min="10"									
                                    max="100"									
                                    label="Image Width"									
                                ></v-slider>									
                      </v-expansion-panel-content>									
                      <v-expansion-panel-header v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">									
                          Image Alignment									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">									
                         									
                                <v-card flat width="100%" height="100%">									
                                  <v-card-title> Wrapping Box</v-card-title>									
                                  <v-card-text>									
                                      									
                                      <v-btn-toggle v-model="toggletop">									
                                        <v-btn x-small @click="ImageAlignment(EditedTab,'justify-start')">									
                                          <v-icon>mdi-format-align-left</v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="ImageAlignment(EditedTab,'justify-center')">									
                                         <v-icon>mdi-format-align-center</v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="ImageAlignment(EditedTab,'justify-end')">									
                                         <v-icon>mdi-format-align-center</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>									
                                   									
                                  </v-card-text>									
                              </v-card>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                   									
                          									
                      <v-expansion-panel>									
                          <v-expansion-panel-header v-if="EditedTab.Name === 'Image'">									
                              Width									
                          </v-expansion-panel-header>									
                          <v-expansion-panel-content v-if="EditedTab.Name === 'Image'">									
                              <v-card flat width="100%" height="100%">									
                                  <v-card-title> Width Box: {{EditedTab.Width}}</v-card-title>									
                                  <v-card-text>									
                                      <v-btn  :disabled="EditedTab.Width === '10%'" @click="ImageWidth(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn :disabled="EditedTab.Width === '100%'" @click="ImageWidth(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      									
                                  </v-card-text>									
                              </v-card>									
                          </v-expansion-panel-content>									
                       </v-expansion-panel> -->									
                       									
                       <v-expansion-panel  v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Web Form' || EditedTab.Name === 'Clipped Tab' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single' || EditedTab.Name === 'Timeline Item' || EditedTab.Name === 'Counter Single' || EditedTab.Name === 'Store Product Single'">									
                      <v-expansion-panel-header>									
                          Box Styling									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                          <div v-if="EditedTab.Name !== 'Clipped Tab' && EditedTab.Name !== 'Card Block Single'">									
                          <v-switch v-model="EditedTab.Shaped" label="Shaped"/>									
                          <v-switch v-model="EditedTab.Outlined" label="Outlined"/>									
                          <v-switch v-model="EditedTab.Flat" label="Flat"/>									
                          <v-switch v-model="EditedTab.Tile" label="Tile"/>									
                          									
                          									
                        									
                          <v-slider									
                                    v-model="EditedTab.Elevation"									
                                    min="0"									
                                    max="24"									
                                    label="Elevation"									
                                ></v-slider>									
                          </div>									
                           <v-switch @change="UpdateBoxTransparency(EditedTab)" v-model="EditedTab.BoxTransparent" label="Transparent" v-if="EditedTab.Name !== 'Timeline Item'" />    									
                          <v-menu v-if="EditedTab.Name !== 'Timeline Item'"  									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker v-model="EditedTab.BGColor"></v-color-picker>									
                            </v-menu>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <v-expansion-panel  v-if="EditedTab.Name === 'Gallery'">									
                      <v-expansion-panel-header v-if="EditedTab.Name === 'Gallery'">									
                          Gallery									
                      </v-expansion-panel-header>									
                      									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'Gallery'">									
                          <v-slider v-model="EditedTab.GalleryCols" label="Column Size"									
                      min="1"									
                    max="3"									
                      />									
                      <v-slider v-model="EditedTab.Elevation" label="Elevation"									
                      min="0"									
                    max="10"									
                      />									
                          <v-switch v-model="EditedTab.HideCaptions" label="Hide Captions"/>									
                          <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="400px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                </template>									
                            <v-card>									
                                <v-tabs>									
                                    <v-tab>									
                                        Assets									
                                    </v-tab>									
                                    <v-tab-item>									
                                        <v-col									
                                             v-for="asset in SystemAssets" :key="asset.itemObjKey"									
                                            class="d-flex child-flex"									
                                            cols="2"									
                                            >									
                                            <v-img v-if="asset.Path" @click="AssignGalleryAssets(EditedTab,asset)" height="100" contain :src="asset.Path"/>									
                                            <v-img v-if="!asset.Path && asset.path" @click="AssignGalleryAssets(EditedTab,asset)" height="100" contain :src="asset.path"/>									
                                            </v-col>									
                                    </v-tab-item>									
                                    <v-tab >									
                                        Libraries {{typeof GalleryLibraries}}									
                                    </v-tab>									
                                    <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">                                       									
                                        <v-tabs>									
                                             <v-tab>									
                                                {{lib.name}}									
                                            </v-tab>									
                                            <v-tab-item>									
                                                <v-card width="800">									
                                                <component :is="lib.compname" :FullGallery="true"									
                                                 @AssignGalleryAssets="AssignGalleryAssets" 									
                                                :tab="EditedTab" />									
                                                </v-card>									
                                            </v-tab-item>									
                                        </v-tabs>									
                                    </v-tab-item>									
                                </v-tabs>									
                                        <!-- <v-row>									
                                            <v-col									
                                             v-for="asset in FinalGalleryObjects" :key="asset.itemObjKey"									
                                            class="d-flex child-flex"									
                                            cols="4"									
                                            >									
                                            <v-img v-if="asset.Path" @click="AssignGalleryAssets(EditedTab,asset)" height="100" contain :src="asset.Path"/>									
                                            <v-img v-if="!asset.Path && asset.path" @click="AssignGalleryAssets(EditedTab,asset)" height="100" contain :src="asset.path"/>									
                                            </v-col>									
                                        </v-row> -->									
                                        </v-card>									
                            </v-menu>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <v-expansion-panel  v-if="EditedTab.HasTextBox">									
                      <v-expansion-panel-header>									
                          Box Wrapping									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                         									
                                <!-- <v-switch v-model="EditedTab.isFillHeight" @change="FillHeight(EditedTab)" label="FillHeight"/>									
                                <v-select v-model="EditedTab.TextBoxFlexAlign" label="Align Flex" :items="['center','flex-start','flex-end']"/> -->									
                                <v-card flat width="100%" height="100%">									
                                  <v-card-title> Wrapping Box</v-card-title>									
                                  <v-card-text>									
                                      <p>Top</p>									
                                      <v-btn-toggle v-model="toggletop">									
                                        <v-btn x-small v-for="wrapopt in WrappingOptions.slice(0,3)" :key="wrapopt.itemObjKey" @click="BoxWrapping(EditedTab,wrapopt)">									
                                          <v-icon>{{wrapopt.JustifyIcon}}</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>									
                                    <p>Center</p>									
                                      <v-btn-toggle v-model="togglecenter">									
                                        <v-btn x-small v-for="wrapopt in WrappingOptions.slice(3,6)" :key="wrapopt.itemObjKey" @click="BoxWrapping(EditedTab,wrapopt)">									
                                         <v-icon>{{wrapopt.JustifyIcon}}</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>									
                                    <p>Bottom</p>									
                                    <v-btn-toggle v-model="togglebottom">									
                                        <v-btn x-small v-for="wrapopt in WrappingOptions.slice(6,9)" :key="wrapopt.itemObjKey" @click="BoxWrapping(EditedTab,wrapopt)">									
                                         <v-icon>{{wrapopt.JustifyIcon}}</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>									
                                  </v-card-text>									
                              </v-card>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                      <v-expansion-panel  v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Title' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Gallery'">									
                      <v-expansion-panel-header>									
                          Content									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content >									
                          <v-switch v-model="EditedTab.HasImage" label="HasImage"/>									
                          <v-list									
                      class="transparent"									
                      dark									
                      height="100%"									
                      dense									
                      >									
                          <v-list-item >									
                          <v-text-field label="Title" v-model="EditedTab.Title" />									
                      </v-list-item>									
                        <v-list-item v-if="EditedTab.Name === 'Gallery'">									
                            <v-textarea v-model="EditedTab.Caption" label="Caption"/>									
                        </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                         <v-btn-toggle dark>									
                                                									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<h1>')">									
                                H1									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<h2>')">									
                                H2									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<h3>')">									
                                H3									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('formatBlock', false, '<p>')">									
                                 <v-icon>mdi-format-paragraph </v-icon>									
                            </v-btn>									
                            									
                            									
                            </v-btn-toggle>									
                                 									
                      </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                         <v-btn-toggle dark>									
                            									
                            <v-btn x-small onclick="document.execCommand('justifyLeft',false,'')">									
                                <v-icon x-small>mdi-format-align-left</v-icon>									
                            </v-btn>									
                            <!-- <v-btn>									
                                <v-icon>mdi-format-align-center</v-icon>									
                            </v-btn> -->									
                            <v-btn x-small onclick="document.execCommand('justifyCenter',false,'')">									
                                <v-icon x-small>mdi-format-align-justify</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('justifyRight',false,'')">									
                                <v-icon x-small>mdi-format-align-right</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('insertUnorderedList',false,'')">									
                                <v-icon x-small>mdi-format-list-bulleted</v-icon>									
                            </v-btn>									
                            									
                            									
                            </v-btn-toggle>									
                                 									
                      </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                          <v-btn-toggle dark>									
                            <v-btn x-small onclick="document.execCommand('fontSize', 0, '12px');">									
                                <v-icon x-small>mdi-format-title</v-icon>									
                            </v-btn>									
                    									
                            									
                            <v-btn x-small onclick="document.execCommand('bold', false, '');">									
                                <v-icon x-small>mdi-format-bold</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('italic', false, '');">									
                                <v-icon x-small>mdi-format-italic</v-icon>									
                            </v-btn>									
                            <v-btn x-small onclick="document.execCommand('underline', false, '')">									
                                <v-icon x-small>mdi-format-underline</v-icon>									
                            </v-btn>									
                           									
                            </v-btn-toggle>									
                      </v-list-item>									
                  									
                       <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                          <v-btn-toggle dark>									
                        									
                                <v-btn x-small>									
                                    <v-icon @click="link()">mdi-link</v-icon>									
                                </v-btn>									
                                <v-btn x-small>									
                                    <v-icon x-small @click="addphone()">mdi-phone</v-icon>									
                                </v-btn>									
                                <v-btn x-small>									
                                    <v-icon x-small @click="addemail()">mdi-email</v-icon>									
                                </v-btn>									
                                <v-btn x-small onclick="document.execCommand('strikeThrough',false,'')">									
                                    <v-icon x-small>mdi-format-strikethrough</v-icon>									
                                </v-btn>									
                                </v-btn-toggle>									
                      </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                          <v-card width="90%" min-height="300px" outlined flat v-html="EditedTab.Description" contenteditable 									
                          :id="EditedTab.Name.split(' ').join('_')+EditedTabindex+'editor'">									
                                 </v-card>									
                      </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                          <v-btn @click="UpdateHTMLBox()">Update</v-btn>									
                      </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'Image' || EditedTab.HasImage">									
                          <v-text-field type="number" label="Width" v-model="EditedTab.Width" />									
                      </v-list-item>									
                      									
                      <v-list-item v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">									
                          <v-switch label="Clipped" v-model="EditedTab.Clipped" />									
                      </v-list-item>									
                      <v-list-item v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">									
                          <v-switch label="Transparent" v-model="EditedTab.Transparent" />									
                      </v-list-item>									
                        </v-list>									
                      </v-expansion-panel-content>									
                      </v-expansion-panel>									
                      <!--  <v-expansion-panel v-if="EditedTab.Name === 'Web Form'">									
                      <v-expansion-panel-header >									
                         Margins									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content >									
                          <v-card flat width="100%">									
                                  <v-card-title>Margin Top: {{EditedTab.MarginTop}}</v-card-title>									
                                  <v-card-text>									
                                      <v-btn @click="TopMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn @click="TopMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      									
                                  </v-card-text>									
                              </v-card>									
                              <v-card flat width="100%">									
                                  <v-card-title>Margin Btm: {{EditedTab.MarginBottom}}</v-card-title>									
                                  <v-card-text>									
                                      <v-btn @click="BottomMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn @click="BottomMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      									
                                  </v-card-text>									
                              </v-card>									
                               <v-card flat width="100%">									
                                  <v-card-title>Margin Lft: {{EditedTab.MarginLeft}}</v-card-title>									
                                  <v-card-text>									
                                      <v-btn @click="LeftMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn @click="LeftMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      									
                                  </v-card-text>									
                              </v-card>									
                               <v-card flat width="100%">									
                                  <v-card-title>Margin Rgt: {{EditedTab.MarginRight}}</v-card-title>									
                                  <v-card-text>									
                                      <v-btn @click="RightMargin(EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn @click="RightMargin(EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      									
                                  </v-card-text>									
                              </v-card>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <v-expansion-panel>									
                      <v-expansion-panel-header v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox">									
                        Background Image									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox">									
                         									
                         <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="400px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                </template>									
                            <v-card>									
                                        <v-row>									
                                            <v-col									
                                             v-for="asset in SystemAssets" :key="asset.itemObjKey"									
                                            class="d-flex child-flex"									
                                            cols="4"									
                                            >									
                                            <v-img @click="AssignImage(EditedTab,'BGImage',asset)" height="100" contain :src="asset.Path"/>									
                                            </v-col>									
                                        </v-row>									
                                        </v-card>									
                            </v-menu>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel> -->									
                       <!-- <v-expansion-panel>									
                      <v-expansion-panel-header v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Web Form'">									
                        Background Color									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Web Form'">									
                         <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker v-model="EditedTab.BGColor"></v-color-picker>									
                            </v-menu>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel> -->									
                      <v-expansion-panel v-if="EditedTab.Name === 'Store Product Single'">									
                      <v-expansion-panel-header>									
                        Store Product									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                             <v-switch label="Show Price" v-model="EditedTab.ShowsPrice" />									
                            <v-text-field v-model="EditedTab.Price" 									
                            />									
                             <v-switch label="Has BG IMG" v-model="EditedTab.HasBGIMG" />									
                            <v-switch label="BG IMG is IMG" v-model="EditedTab.BGIMGisIMG" />									
                            <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker v-model="EditedTab.SubHeaderColor"></v-color-picker>									
                            </v-menu>									
                            									
                            									
                            <v-select v-model="EditedTab.SubHeaderFontFamily" label="Font" 									
                            :items="FontOptions"/>									
                            <v-select v-model="EditedTab.SubHeaderFontSize" :items="[12,14,18,24,36,42,50,60,75,100]"									
                            />									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                        <v-expansion-panel v-if="EditedTab.Name === 'Counter Single'">									
                      <v-expansion-panel-header>									
                        Counter									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                             <v-text-field label="Title" v-model="EditedTab.Title" />									
                            <v-text-field v-model="EditedTab.CounterInteger" type="number"									
                            />									
                            <v-list-item>									
                                  <v-text-field v-model="EditedTab.CounterIcon" label="Icon"									
                                  />									
                                <a href="https://cdn.materialdesignicons.com/4.5.95/" target="_blank">Get Icons</a>									
                              </v-list-item>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <v-expansion-panel>									
                      <v-expansion-panel-header v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Web Form' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Counter Single'">									
                        Font Color									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Web Form' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Counter Single'">									
                         <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker v-model="EditedTab.FontColor"></v-color-picker>									
                            </v-menu>									
                            <v-select v-model="EditedTab.FontSize" :items="[10,12,14,16,20,24]"									
                            />									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <v-expansion-panel v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Web Form' || EditedTab.Name === 'Title' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Counter Single' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Gallery' && EditedTab.Title">									
                      <v-expansion-panel-header >									
                        Header Styling									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content>									
                          <v-switch label="Center Header" v-model="EditedTab.CenteredHeader" @change="HeaderCenterClass(EditedTab)" />									
                          <v-switch label="Has Header Image" v-model="EditedTab.HasHeaderImage" v-if="EditedTab.Name !== 'Card Row' && EditedTab.Name !== 'Counter Single' && EditedTab.Name !== 'Store Product Single'"/>									
                          <v-menu      									
                           v-if="EditedTab.Name !== 'Card Row' && EditedTab.Name !== 'Counter Single' && EditedTab.Name !== 'Store Product Single'"									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="400px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                </template>									
                               <v-layout row class="justify-center">									
                                    <v-card width="100%" max-width="1200px" flat style="padding-bottom:50px" dark>									
                                    <v-pagination									
                                        :total-visible="9"									
                                        v-model="SystemAssetsPage"									
                                        circle									
                                        :length="Math.round(SystemAssets.length/10)"									
                                        ></v-pagination>									
									
                                    <v-layout row wrap class="justify-center">									
                                        <v-card flat tile class="mx-3 my-3 transparent" 									
                                        v-for="photo in SystemAssetsPaginated"									
                                        :key="photo.itemObjKey"									
                                        max-width="200px"									
                                        									
                                        >    									
                                            <v-img									
                                            :src="photo.Path"									
                                            aspect-ratio="1"									
                                            width="100"									
                                            >									
                                            <template v-slot:placeholder>									
                                                <v-row									
                                                class="fill-height ma-0"									
                                                align="center"									
                                                justify="center"									
                                                >									
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>									
                                                </v-row>									
                                            </template>									
                                            </v-img>									
                                            <v-btn dark color="green" icon									
                                            @click="AssignImage(EditedTab,'HeaderImage',photo)">									
                                            <v-icon>									
                                            mdi-check									
                                            </v-icon>									
                                        </v-btn>									
                   									
                                            </v-card>									
                                    </v-layout>									
                                    </v-card>									
                                       </v-layout>									
                                  									
                                    </v-menu>									
                          <!-- <v-switch label="Center Header" v-model="EditedTab.CenteredHeader" @change="HeaderCenterClass(EditedTab)" /> -->									
                         <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker v-model="EditedTab.HeaderColor"></v-color-picker>									
                            </v-menu>									
                            									
                            									
                            <v-select v-model="EditedTab.HeaderFontFamily" label="Font" 									
                            :items="FontOptions"/>									
                            <v-select v-model="EditedTab.HeaderFontSize" :items="[12,14,18,24,36,42,50,60,75,100]"									
                            />									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                       <!-- <v-expansion-panel>									
                      <v-expansion-panel-header v-if="!EditedTab.SectionType">									
                        Flex									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="!EditedTab.SectionType">									
                          									
                          <span class="caption">{{EditedTab.FlexXLRG}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexXLRG"									
                            min="1"									
                            max="12"									
                            label="X Large"									
                                ></v-slider>									
                               									
                          <span class="caption">{{EditedTab.FlexLarge}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexLarge"									
                            min="1"									
                            max="12"									
                            label="Large"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexMedium}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexMedium"									
                            min="1"									
                            max="12"									
                            label="Medium"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexSmall}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexSmall"									
                            min="1"									
                            max="12"									
                            label="Small"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexXSmall}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexXSmall"									
                            min="1"									
                            max="12"									
                            label="X Small"									
                                ></v-slider>									
                     									
                      </v-expansion-panel-content>									
                       </v-expansion-panel> -->									
                       <v-expansion-panel>									
                       <v-expansion-panel-header v-if="EditedTab.Name === 'Web Form'">									
                        Form									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content v-if="EditedTab.Name === 'Web Form'">									
                          <v-text-field v-model="EditedTab.FormName" :rules="[rules.required]" label="Form Name"/>									
                        <v-switch v-model="EditedTab.DarkForm" label="Dark Form"/>									
                        <v-text-field v-model="EditedTab.FormEmailRecipient" :rules="[rules.email]" label="Recipient Email"/>									
                        <v-btn @click="FormFieldsDialogActivate()">									
                            Fields									
                        </v-btn>									
                         <v-select label="Button Alignment" v-model="EditedTab.ButtonAlignment" :items="['justify-start','justify-center','justify-end']"/>									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                        									
                        <v-expansion-panel  v-if="EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Timeline Item' || EditedTab.Name === 'Store Product Single'">									
                      <v-expansion-panel-header>									
                        Action Button									
                      </v-expansion-panel-header>									
                      <v-expansion-panel-content >									
                          <v-select label="Button Alignment" v-model="EditedTab.ButtonAlignment" :items="['justify-start','justify-center','justify-end']"/>									
                         <v-switch v-model="EditedTab.HasActionButton" label="Has Action Button"/>									
                        <v-text-field v-model="EditedTab.ActionButtonName" label="Display"/>									
                        <v-select v-model="EditedTab.ActionButtonType" label="Type" :items="['Route','Submission','Add Cart Item']"/>									
                        <v-switch v-model="EditedTab.ActionBtnDark" label="Dark Button"/>									
                        <v-menu v-if="EditedTab.Name !== 'Timeline Item'"    									
                                    :close-on-content-click="false"									
                                    :nudge-right="40"									
                                    transition="scale-transition"									
                                    offset-y									
                                    min-width="200px"									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                    </template>									
                                <v-color-picker v-model="EditedTab.ButonColor"></v-color-picker>									
                                </v-menu>									
                        									
                      </v-expansion-panel-content>									
                       </v-expansion-panel>									
                  </v-expansion-panels>									
                    									
                      									
                      									
                  									
          </v-navigation-drawer>									
          									
         									
            <v-card flat width="100%" @dragover.prevent @drop.prevent="newcontentdropFinish(0, $event)" id="drop-area" v-if="PageTabs.length === 0 || dragging && !EditedTab">									
                <v-layout class="justify-center">									
                    <v-card-title class="grey--text">									
                    <v-icon>mdi-plus-thick</v-icon> Add a Tab at the Top									
                    </v-card-title>									
                </v-layout>									
                </v-card>									
                									
                <!-- <iframe src="https://www.google.com/maps/d/u/0/embed?mid=12v-uQifQVbD3nR0ZtZ172lLmvzzGqvSc" width="100%" height="480"></iframe> -->									
                <v-card :color="MatchColor(tab)" :class="tab.RowTransparency" tile  width="100%" flat  class="CodeRenders" id="PageCode"									
                v-for="(tab,i) in PageTabs" :key="tab.itemObjKey" @dragover.prevent="allowDrop(event,i)">									
<!-- :class="'mb-6 '+row.Justify"									
            no-gutters									
            v-for="(row,rowindex) in tab.Rows" :key="row.itemObjKey"									
            :style="'align-self:'+row.Alignment" -->									
                        <!-- <v-container class="grey lighten-5"> -->									
                            									
                            <v-layout row justify-center v-if="tab.ShowComponents">									
                                <v-card v-if="tab.Name === 'Card Row' && tab.Elements.length === 0 || tab.Name === 'Card Blocks' && tab.Elements.length === 0 || tab.Name === 'Counter' && tab.Elements.length === 0 || tab.Name === 'Store Products' && tab.Elements.length === 0" id="drop-area"  @click.prevent="AdjustRowAmount(tab,i)">									
                             <v-layout class="justify-center">									
                                <v-card-text class="grey--text">									
                                Click on this card to start adding Elements									
                                </v-card-text>									
                            </v-layout>									
                            </v-card>									
                            <v-list-item-avatar  v-if="tab.Name === 'Card Row' && tab.Elements.length !== 0 || tab.Name === 'Card Blocks' && tab.Elements.length !== 0 || tab.Name === 'Counter' && tab.Elements.length !== 0 || tab.Name === 'Store Products' && tab.Elements.length !== 0" size="20" @click.prevent="AdjustRowAmount(tab,i)">									
                            <v-icon small class="links--text actionicon">mdi-plus-thick</v-icon>									
                        </v-list-item-avatar>									
                          <div :class="'flex xl'+tab.FlexXLRG+' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall">  									
                              <!-- <v-flex :xl="tab.FlexXLRG" {{tab.FlexXLRG}} :lg="tab.FlexLarge" :md="tab.FlexMedium" :sm="tab.FlexSmall" :xs="tab.FlexXSmall"> -->									
                     <div v-if="tab.Name === 'Card Row'">									
                                 <v-card-title v-if="tab.HasHeaderImage">									
                                  <v-img v-if="!tab.HeaderImage "									
                               contain									
                               class="my-1"									
                                height="80"									
                               src="@/assets/ImageHolder.png"									
                               /><v-img v-if="tab.HeaderImage "									
                               contain									
                               class="my-1"									
                                height="80"									
                               :src="tab.HeaderImage"									
                               /></v-card-title>									
                                <v-card-title :class="tab.Center" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">									
                                <strong>{{tab.Title}}</strong>									
                                 <!-- <v-divider inset class="white" >									
                                </v-divider> -->									
                                <!-- <hr style="width:35%; color:blue;"> -->									
                                </v-card-title>									
                                <!-- <v-divider inset class="white" >									
                                </v-divider> -->									
                            <v-card-text v-html="tab.Description" :class="tab.FillHeight" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex">									
                                </v-card-text>									
                             									
                             <v-card-text v-if="tab.HasImage">									
                                 <v-layout :class="tab.Justify" row>									
                                 <v-img  v-if="tab.HasImage && tab.IMG"									
                                 :contain="!tab.Clipped"									
                                   :height="tab.Height"									
                                        :src="tab.IMG"									
                                        :style="CheckifClipped(tab)"									
                                       									
                                        />									
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"									
                                   :height="tab.Height"									
                                        src="@/assets/ImageHolder.png"									
                                        />									
                                 </v-layout>									
                             </v-card-text>									
                        									
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">									
                            <v-btn :dark="tab.ActionBtnDark"  :style="'background-color:'+tab.ButonColor.hex">									
                                {{tab.ActionButtonName}}									
                            </v-btn>									
                        </v-card-actions>									
                            </div>									
                     <v-row  v-if="tab.Name !== 'Parallax' && tab.Name !== 'Clipped Tab'"        									
                        :class="'mb-6 '+tab.Justify"									
                        no-gutters									
                        :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"									
                       									
                        >        									
                        									
                         									
                        <v-card flat width="100%" height="100" :id="i+'drop-area'" @click="OpenDialog(tab,i)" v-if="tab.Name === 'blank' && tab.Elements.length === 0 || tab.Name === 'blank' && dragging && !dragging.SectionType"									
                             @dragover.prevent @drop.prevent="elementdropFinish(i, $event)">									
                            <v-layout class="justify-center">									
                                <v-card-title class="grey--text">									
                                <v-icon>mdi-plus-thick</v-icon> Add Content									
                                </v-card-title>									
                            </v-layout>									
                            </v-card>    									
                                    									
                        <v-col									
                            v-for="(elmnt,index) in tab.Elements"									
                            :key="elmnt.itemObjKey" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"									
                            cols="12"    									
                            :class="elmnt.Justify+' '+ColMY(elmnt)"  									
                            :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      									
                        >    									
                        <v-icon class="links--text" @click="OpenDialog(elmnt,index,true,i)">mdi-cogs</v-icon>									
                         <TimelineTab 									
                            v-if="elmnt.Name === 'Timeline' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"									
                            />									
                            									
                            <ImageTab v-if="elmnt.Name === 'Image' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"									
                            />									
                        <TitleTab 									
                            v-if="elmnt.Name === 'Title' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                       									
                            <IFRAMETab  v-if="elmnt.Name === 'IFRAME' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :tab="elmnt" @OpenDialog="OpenDialog" :i="i"									
                                    />									
                        									
                            <GoogleMyMapsTab 									
                            v-if="elmnt.Name === 'Google My Maps' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                            <HTMLBox 									
                            v-if="elmnt.Name === 'HTML Box' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                            									
                            <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" :i="i"									
                            />									
                            									
                            <CardRowTab 									
                            v-if="elmnt.Name === 'Card Row Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                           <CardBlocksTab 									
                            v-if="elmnt.Name === 'Card Block Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                            <CounterTab 									
                            v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                            <TestimonialTab 									
                            v-if="elmnt.Name === 'Testimonials' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                            <StoreProductTab 									
                            v-if="elmnt.Name === 'Store Product Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />									
                            									
                        </v-col>									
                        									
                     </v-row>									
                     <!-- <div v-if="tab.Name === 'Card Blocks'">									
									
                                        <v-container class="pa-1">									
                                            <v-row>									
                                            <v-col									
                                                v-for="(item, i) in tab.Elements"									
                                                :key="i"									
                                                cols="3"									
                                                md="3"									
                                            >									
                                            </v-col>									
                                            </v-row>									
                                        </v-container>									
                            </div> -->									
                     <v-icon v-if="tab.Name === 'Gallery'" @click="OpenDialog(tab,i)">mdi-cogs</v-icon>									
                    <GalleryTab  v-if="tab.Name === 'Gallery' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                                @DeleteTabAsset="DeleteTabAsset"									
                                />									
                         <v-icon v-if="tab.Name === 'Parallax'" @click="OpenDialog(tab,i)">mdi-cogs</v-icon>									
                   <ParallaxTab v-if="tab.Name === 'Parallax' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i" @click="OpenDialog(tab,i)"									
                    />									
                    <v-icon v-if="tab.Name === 'Clipped Tab'" @click="OpenDialog(tab,i)">mdi-cogs</v-icon>									
                  									
                    <ClippedTab 									
                    v-if="tab.Name === 'Clipped Tab' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
                      </div>									
                     <v-card flat width="100%" style="visibility:hidden;height:0px"  @dragleave="unallowDrop(event,i)" @dragover.prevent @drop.prevent="contentdropFinish(i, $event)" :id="i+'drop-area'">									
                <v-layout class="justify-center">									
                    <v-card-title class="grey--text">									
                    <v-icon>mdi-plus-thick</v-icon> Add tab below									
                    </v-card-title>									
                </v-layout>									
                </v-card>									
                      									
                            </v-layout>    									
                </v-card>									
                									
                   <!-- <div  v-for="(tab,i) in PageTabs" :key="tab.itemObjKey" @dragover="allowDrop(event,i)" class="CodeRenders" id="PageCode"									
                >  									
                    									
									
                        <GoogleMyMapsTab 									
                            v-if="tab.Name === 'Google My Maps' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="i"									
                            :tab="tab" @OpenDialog="OpenDialog"									
                            />									
                        <GalleryTab  v-if="tab.Name === 'Gallery' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                        @DeleteTabAsset="DeleteTabAsset"									
                        />									
                        <IFRAMETab  v-if="tab.Name === 'IFRAME' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                        />									
                    									
                    									
                     									
                    <ClippedTab 									
                    v-if="tab.Name === 'Clipped Tab' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
                    <CustomTab  v-if="tab.Name === 'Custom Tab' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" @unallowDrop="unallowDrop" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                    :OptionNameSelect="OptionNameSelect"									
                    :tab="tab" @OpenDialog="OpenDialog" :ShowComponents="ShowComponents"									
                    @elementdropFinish="elementdropFinish" :i="i"									
                    />									
               									
                    									
                   <ParallaxTab v-if="tab.Name === 'Parallax' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
                  									
        									
									
       									
       									
                     <v-icon @click="OpenDialog(tab,i)">mdi-cogs</v-icon>									
                    <WebFormTab v-if="tab.Name === 'Web Form' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                    :OptionNameSelect="OptionNameSelect"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
    <v-card :class="tab.RowTransparency+' mx-3'" style="padding:20px;" :color="tab.RowColor" flat height="100%" tile v-if="tab.Name === 'HTML Box'">									
     									
        <v-layout  row :class="tab.LayoutValue+' mx-3'" :style="'margin-top:'+tab.RowMarginTop+';margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'">									
        <v-flex :class="' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall+' mx-2 '"  :style="tab.FlexValue">									
                   <HTMLBox 									
                    v-if="tab.Name === 'HTML Box' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
        </v-flex>									
        </v-layout>									
    </v-card>									
    <v-layout  row :class="tab.LayoutValue+' mx-3'" :style="'margin-top:'+tab.RowMarginTop+';margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'">									
        <v-flex :class="' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall+' mx-2 '"  :style="tab.FlexValue">									
                <TimelineTab 									
                    v-if="tab.Name === 'Timeline' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
                    <ImageTab v-if="tab.Name === 'Image' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize"									
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"									
                    />									
        </v-flex>									
    </v-layout>									
              									
                          									
                    <v-card flat width="100%" style="visibility:hidden;height:0px"  @dragleave="unallowDrop(event,i)" @dragover.prevent @drop.prevent="contentdropFinish(i, $event)" :id="i+'drop-area'">									
                <v-layout class="justify-center">									
                    <v-card-title class="grey--text">									
                    <v-icon>mdi-plus-thick</v-icon> Add tab below									
                    </v-card-title>									
                </v-layout>									
                </v-card>      									
                </div> -->									
         									
                </v-flex>									
            <v-layout column class="fab-container">									
                <v-btn  icon x-large @click="SavePage()"><v-icon  color="green" x-large>mdi-content-save</v-icon></v-btn>									
                    <v-btn  icon x-large @click="ContentTypesDialog = !ContentTypesDialog"><v-icon  color="purple" x-large>mdi-monitor-dashboard</v-icon></v-btn>									
                    <v-btn  icon x-large :to="'/LandingPage/'+this.$route.params.id"><v-icon  color="blue" x-large>mdi-eye</v-icon></v-btn>									
            </v-layout>									
          </v-main>									
</template>									
									
<script>									
import firebase from 'firebase';									
import db from '@/main'									
import axios from 'axios'									
import format from 'date-fns/format'									
									
									
import TimelineTab from '@/components/Campaigns/LandingPages/TimelineTab';									
import WebFormTab from '@/components/Campaigns/LandingPages/WebFormTab';									
import TitleTab from '@/components/Campaigns/LandingPages/TitleTab';									
import CounterTab from '@/components/Campaigns/LandingPages/CounterTab';									
import StoreProductTab from '@/components/Campaigns/LandingPages/StoreProductTab';									
import TestimonialTab from '@/components/Campaigns/LandingPages/TestimonialTab';									
import CardBlocksTab from '@/components/Campaigns/LandingPages/CardBlocksTab';									
import CardRowTab from '@/components/Campaigns/LandingPages/CardRowTab';									
import HTMLBox from '@/components/Campaigns/LandingPages/HTMLBox';									
import ImageTab from '@/components/Campaigns/LandingPages/ImageTab';									
import ParallaxTab from '@/components/Campaigns/LandingPages/ParallaxTab';									
import ClippedTab from '@/components/Campaigns/LandingPages/ClippedTab';									
import IFRAMETab from '@/components/Campaigns/LandingPages/IFRAMETab';									
import GalleryTab from '@/components/Campaigns/LandingPages/GalleryTab';									
import GoogleMyMapsTab from '@/components/Campaigns/LandingPages/GoogleMyMapsTab';									
									
export default {									
    props: [],									
    components: {									
									
        TimelineTab,									
        WebFormTab,									
        TitleTab,									
        HTMLBox,									
        CardBlocksTab,									
        CardRowTab,									
        CounterTab,									
        StoreProductTab,									
        TestimonialTab,									
        ImageTab,									
        ParallaxTab,									
        									
        ClippedTab,        									
        GalleryTab,									
        IFRAMETab,									
        GoogleMyMapsTab									
        									
        },									
    data() {									
    return {  									
        NewTestimonialFile: '',									
        SampleTestinomialFile: '',									
        EditTestimonyDialog: false,									
        EditedTestimony: '',									
        EditedTestimonyIndex: -1,									
        model: true,									
        colors: [									
        'primary',									
        'secondary',									
        'yellow darken-2',									
        'red',									
        'orange',									
      ],									
        DataCountCollections: [									
            {									
            Name: 'Current Customers',									
            ArrayName: 'CurrentCustomersWikiArray',									
            CollectionName: 'currentcustomerswiki',									
            CountbyOptions: [									
                'Option Set',									
                'Lookup Field'									
            ],									
            Categories: [									
                {							
                Name: 'Status',									
                Type: 'Option Set',									
                Options: [
                {									
                    "Name": "Active",									
                    "ID": 1000001									
                   								
                },
                {									
                    "Name": "InActive",									
                    "ID": 1000002									
                   								
                },
                ]									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'Dealership',									
                    Type: 'Lookup Field',									
                    Options: 'DealershipsWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
            ]									
            },									
            {									
            Name: 'Customer Claims',									
            ArrayName: 'CustomerClaimsWikiArray',									
            CollectionName: 'customerclaimswiki',									
            CountbyOptions: [									
                'Option Set',									
                'Lookup Field'									
            ],									
            Categories: [									
                {							
                Name: 'Status',									
                Type: 'Option Set',									
                Options: [
                {									
                    "Name": "Active",									
                    "ID": 1000001									
                   								
                },
                {									
                    "Name": "InActive",									
                    "ID": 1000002									
                   								
                },
                ]									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'Current Customer',									
                    Type: 'Lookup Field',									
                    Options: 'CurrentCustomersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
            ]									
            },									
            {									
            Name: 'Sales Contracts',									
            ArrayName: 'SalesContractsWikiArray',									
            CollectionName: 'salescontractswiki',									
            CountbyOptions: [									
                'Option Set',									
                'Lookup Field'									
            ],									
            Categories: [									
                {							
                Name: 'Status',									
                Type: 'Option Set',									
                Options: [
                {									
                    "Name": "Active",									
                    "ID": 1000001									
                   								
                },
                {									
                    "Name": "InActive",									
                    "ID": 1000002									
                   								
                },
                ]									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'Dealership',									
                    Type: 'Lookup Field',									
                    Options: 'DealershipsWikiArray',									
                    Identifier: 'Name'									
                },									
                {									
             									
                    Name: 'User',									
                    Type: 'Lookup Field',									
                    Options: 'UsersWikiArray',									
                    Identifier: 'Name'									
                },									
            ]									
            },									
									
        ],									
        CurrentCustomersWikiArray: [],									
        CustomerClaimsWikiArray: [],									
        SalesContractsWikiArray: [],									
        SelectedStoreProducts: [],									
        DefaultProductsArray: [],									
        FontOptions: ['Architects Daughter','Bahianita','Bangers','Bebas Neue','Berkshire Swash','Caveat','Courgette','Dancing Script','Gudea','Hind','Impact','Indie Flower','Lato','Lobster','Macondo Swash Caps','Maven Pro','Metrophobic','Michroma','Montserrat','Mulish','Mukta Vaani',
                  'News Cycle','Niramit','NTR','Nunito Sans','Oswald','Padauk','Palanquin','Parisienne','Playfair Display','Poiret One','Poppins','Quicksand','Raleway','Redressed','Tangerine','Tulpen One','Verdana','Vibes','Yellowtail'],									
        TypeChange: '',									
        GalleryLibraries: [],									
        GalleryObjects: [],									
        PageContentFont: 'Montserrat',									
        PageHeadersFontSize: 42,									
        PageHeadersFont: 'Raleway',									
        customboxindex: -1,									
        elementIndex: -1,									
        rowindex: -1,									
        EditedRow: '',									
        WrappingOptions: [									
            {									
                DisplayName: 'Top Left',									
                FlexValue: 'align-self:flex-start',									
                LayoutValue: 'fill-height justify-start',									
                JustifyIcon: 'mdi-format-align-left'									
            },									
            {									
                DisplayName: 'Top Center',									
                FlexValue: 'align-self:flex-start',									
                LayoutValue: 'fill-height justify-center',									
                JustifyIcon: 'mdi-format-align-center'									
            },									
            {									
                DisplayName: 'Top Right',									
                FlexValue: 'align-self:flex-start',									
                LayoutValue: 'fill-height justify-end',									
                JustifyIcon: 'mdi-format-align-right'									
            },									
            {									
                DisplayName: 'Center Left',									
                FlexValue: 'align-self:center',									
                LayoutValue: 'fill-height justify-start',									
                JustifyIcon: 'mdi-format-align-left'									
            },									
            {									
                DisplayName: 'Absolute Center',									
                FlexValue: 'align-self:center',									
                LayoutValue: 'fill-height justify-center',									
                JustifyIcon: 'mdi-format-align-center'									
            },									
            {									
                DisplayName: 'Center Right',									
                FlexValue: 'align-self:center',									
                LayoutValue: 'fill-height justify-end',									
                JustifyIcon: 'mdi-format-align-right'									
            },									
            {									
                DisplayName: 'Bottom Left',									
                FlexValue: 'align-self:flex-end',									
                LayoutValue: 'fill-height justify-start',									
                JustifyIcon: 'mdi-format-align-left'									
            },									
            {									
                DisplayName: 'Bottom Center',									
                FlexValue: 'align-self:flex-end',									
                LayoutValue: 'fill-height justify-center',									
                JustifyIcon: 'mdi-format-align-center'									
            },									
            {									
                DisplayName: 'Bottom Right',									
                FlexValue: 'align-self:flex-end',									
                LayoutValue: 'fill-height justify-end',									
                JustifyIcon: 'mdi-format-align-right'									
            },									
            									
        ],									
        SystemAssets: [],									
        EditedTabindex: -1,									
        EditedTab: '',									
        ContentTypesDialog: false,									
        TabDialog: false,									
        ShowComponents: true,									
        draggingtab: '',									
        draggingtabindex: -1,									
        draggingelement: '',									
        draggingelementindex: -1,									
        draggingrow: '',									
        draggingrowindex: -1,									
        dragging: '', 									
        items: [									
        {									
          src: 'backgrounds/bg.jpg',									
        },									
        {									
          src: 'backgrounds/md.jpg',									
        },									
        {									
          src: 'backgrounds/bg-2.jpg',									
        },									
        {									
          src: 'backgrounds/md2.jpg',									
        },									
      ],									
      selected: [],									
        ContentBlocks: [									
            {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'blank',									
            Elements: [],									
            ShowComponents: true,									
            FlexXLRG:11,									
            FlexLarge: 11,									
            FlexMedium: 11,									
            FlexSmall: 12,									
            FlexXSmall: 12,									
            },									
            									
            {									
            Open: true,									
            SectionType: false,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Testimonials',									
            Elements: [],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-language-html5',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },									
            									
            {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Store Products',									
            Elements: [],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-language-html5',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },									
            {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Counter',									
            Elements: [],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-language-html5',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },									
            {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Card Row',									
            Elements: [],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-language-html5',									
            Title: 'Who will benefit',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:10,									
            FlexLarge: 10,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },									
            {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Card Blocks',									
            Elements: [],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-language-html5',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },									
            {									
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Open: true,									
                Height: 300,									
            									
                Name: 'Clipped Tab',									
                BGColor: {									
                    hex:'#009BFFFF'									
                },									
                ShowComponents: true,									
                ClippedbyPath: true,									
                ClippedPathOption: 'polygon',									
                TopLeftCrnrXValue: 25,									
                TopLeftCrnrYValue: 0,									
                TopLeftCrnrX: '25%',									
                TopLeftCrnrY: '0%',									
                TopRightCrnrXValue: 100,									
                TopRightCrnrYValue: 0,									
                TopRightCrnrX: '100%',									
                TopRightCrnrY: '0%',									
                BottomRightCrnrXValue: 75,									
                BottomRightCrnrYValue: 100,									
                BottomRightCrnrX: '75%',									
                BottomRightCrnrY: '100%',									
                BottomLeftCrnrXValue: 0,									
                BottomLeftCrnrYValue: 100,									
                BottomLeftCrnrX: '0%',									
                BottomLeftCrnrY: '100%',									
                Clipped: true,									
                Icon: 'mdi-drawing',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
                Height: 400,									
                Elements: []									
            },									
           									
            {									
                RowColor: '#ffffff',									
                Name: 'Parallax',									
                SectionType: true,									
                Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-image-area',									
                Height: 300,									
                Elements: [],									
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            									
            {									
                RowColor: '#ffffff',									
                Name: 'Image',									
                ShowComponents: true,									
                Height: 200,									
                Icon: 'mdi-image',									
                Width: '60%',
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            {									
                RowColor: '#00ffff',									
                Name: 'Timeline',									
                Items: [									
                    {									
                    HasIcon: true,									
                    Name: 'Timeline Item',									
                    Title: 'Lorem Ipsum Dolor',									
                    Description: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.',									
                    HasActionButton: true,									
                    ActionButtonName: 'Button',									
                    TitleColor: 'white',									
                    color: 'red lighten-2',									
                    icon: 'mdi-star',									
                    },									
                    {									
                    HasIcon: true,									
                    Name: 'Timeline Item',									
                    Title: 'Lorem Ipsum Dolor',									
                    Description: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.',									
                    HasActionButton: true,									
                    ActionButtonName: 'Button',									
                    TitleColor: 'white',									
                    color: 'purple darken-1',									
                    icon: 'mdi-book-variant',									
                    },									
                    {									
                    HasIcon: true,									
                    Name: 'Timeline Item',									
                    Title: 'Lorem Ipsum Dolor',									
                    Description: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.',									
                    HasActionButton: true,									
                    ActionButtonName: 'Button',									
                    TitleColor: 'white',									
                    color: 'green lighten-1',									
                    icon: 'mdi-airballoon',									
                    },									
                    {									
                    HasIcon: true,									
                    Name: 'Timeline Item',									
                    Title: 'Lorem Ipsum Dolor',									
                    Description: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.',									
                    HasActionButton: true,									
                    ActionButtonName: 'Button',									
                    TitleColor: 'white',									
                    color: 'indigo',									
                    icon: 'mdi-buffer',									
                    },									
                ],									
                ShowComponents: true,									
                Icon: 'mdi-timeline-text-outline',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            {									
                RowColor: '#00ffff',									
                Name: 'Title',									
                ShowComponents: true,									
                Icon: 'mdi-format-title',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            {									
                RowColor: '#00ffff',									
                Name: 'HTML Box',									
                ShowComponents: true,									
                Icon: 'mdi-language-html5',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            {									
                RowColor: '#00ffff',									
                Name: 'Web Form',									
                ButtonAlignment: 'justify-end',									
                ShowComponents: true,									
                Icon: 'mdi-email-outline',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
           									
            {									
                RowColor: '#00ffff',									
                Transparent: true,									
            Justify: 'justify-center',									
                Name: 'Google My Maps',									
                ShowComponents: true,									
                Elements: [],									
                Icon: 'mdi-google-my-business',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            {									
                RowColor: '#00ffff',									
                RowTransparency: 'transparent',									
                Name: 'IFRAME',									
                ContentAlign: 'center',									
                ShowComponents: true,									
                Elements: [],									
                Icon: 'mdi-iframe',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
            									
            {									
                SectionType: true,									
                Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
                Open: true,									
                RowColor: '#00ffff',									
                Name: 'Gallery',									
                Assets: [],									
                HideCaptions: false,									
                ShowComponents: true,									
                Elements: [],									
                Icon: 'mdi-folder-image',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },									
									
            									
            									
        ],									
        PageOverview: '',									
        PageName: '',									
        initiallyOpen: ['Body'],									
      									
        PageTabs: [									
            									
        ],									
        RecordSpecificTypes: [									
            {									
            ID: 1000001,									
            Name: 'Wiki'									
            }									
        ],									
        PublishType: '',									
        PublishTypes: [									
            'Public',									
            'Members Only'									
        ],									
        RecordName: '',									
        FeatureType: '',									
        RecordSpecific: false,									
        SiteRouteComponents: [],									
        BannerComponents: [],   									
        BannerImage: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRASolidA?alt=media&token=917741bf-7928-4b75-8baa-5564ef93fafd',									
        ActionButtonName: 'Find out More',									
        BannerLink: '/Groups/0erBwGHT5CzGEFSucUKf',									
        BannerPreview: true,									
        BannerHeader: '',									
        LinkType: '',									
        BannerName: '',									
        BannerDescription: '',									
        NewBannerTypes: [									
            {									
                ID: 1000001,									
                Name: 'Sidebar'									
            },									
            {									
                ID: 1000002,									
                Name: 'Topbar (GIF)'									
            },									
            {									
                ID: 1000003,									
                Name: 'TopBar (HTML)'									
            },									
        ],									
        SiteGroups: [],									
        LinkTypes: [									
            {									
                ID: 1000001,									
                Name: 'Page'									
            },									
            {									
                ID: 1000002,									
                Name: 'Group'									
            },									
            {									
                ID: 1000003,									
                Name: 'Article'									
            },									
            {									
                ID: 1000004,									
                Name: 'Wiki'									
            },									
            {									
                ID: 1000005,									
                Name: 'External'									
            },									
        ],									
        OptionSetDialog: false,									
        EditOptionSet: '',									
        OptionName: '',									
        Option: {},									
        FieldsDialogActivate: false,									
        UserRecord: '',									
        NewAssetFile: '',									
        SystemAssetsPage: 1,									
        rules: {									
            youtubeurl: value => {									
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;									
                return pattern.test(value) || "Not a Youtube Video Link.";									
            },									
            min8Chars: value => value.length >= 8 || "Min. 8 characters",									
            required: value => !!value || "Required.",									
            email: value => {									
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;									
                return pattern.test(value) || "Invalid e-mail.";									
            },									
            url: value => {									
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol									
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name									
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address									
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path									
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string									
            '(\\#[-a-z\\d_]*)?$','i'); 									
                return urlpattern.test(value) || "Invalid Link.";									
            }									
            },									
            									
									
            }									
    }, 									
									
    computed:{									
        DataCollectionCategoryOptions(){									
             if(this.EditedTab && this.EditedTab.DataCountCollection){									
                if(this.EditedTab.Countby && this.EditedTab.Countby === 'Option Set'){									
                    return this.EditedTab.CountCategory.Options									
                }									
                 else if(this.EditedTab.Countby && this.EditedTab.Countby === 'Lookup Field'){									
                    return this[this.EditedTab.CountCategory.Options].map(record => {									
                        record.Name = record[this.EditedTab.CountCategory.Identifier]									
                        return record									
                    })									
                }									
             }									
        },									
        									
        DataCollectionCategories(){									
            if(this.EditedTab && this.EditedTab.DataCountCollection){									
                if(this.EditedTab.Countby && this.EditedTab.Countby === 'Option Set'){									
                    return this.EditedTab.DataCountCollection.Categories.filter(cat => {									
                        return cat.Type === 'Option Set'									
                    })									
                }									
                else if(this.EditedTab.Countby && this.EditedTab.Countby === 'Lookup Field'){									
                    return this.EditedTab.DataCountCollection.Categories.filter(cat => {									
                        return cat.Type === 'Lookup Field'									
                    })									
                }									
                									
            }									
            									
        },									
        StoreProducts(){	
            //Loop through entities that are "Haswiki && IsStoreProduct"
            //This could actually be done from router mind you, even if router items are pushed on app load...								
            let ModelsArray = this.ModelsWikiArray.map(model => {									
                let storeobj = {									
                recordid: model.id,									
                IsDataRecord: true,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Store Product Single',									
                									
                show: false,									
                HasBGIMG: true,									
                BGIMGisIMG: true,									
                ShowsPrice: true,									
                SubHeaderFontFamily: 'Roboto',									
                SubHeaderFontSize: 18,									
                SubHeaderColor: {									
                    hex: '#FF0000FF'									
                },									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 14,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                HasActionButton: true,									
                ActionButtonName: 'Add to Cart',									
                ActionBtnDark: true,									
                ActionButtonType: 'Add Cart Item',									
                ButonColor: {									
                    hex: '#FF0000FF'									
                },									
                ButtonAlignment: 'justify-end',									
                Title: model.Model_Number,									
                Description: `<div style="text-align: center;">`+model.About+`</div>`,									
                									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 4,									
                FlexXSmall: 4,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {									
                        hex: '#ffffff',	
                        hexa: '#ffffffff',									
                        rgba: {r: 255,g: 255,b: 255}									
                }									
                }									
                if(model.Sample_Photo){									
                    storeobj.HasImage = true									
                    storeobj.IMG = model.Sample_Photo									
                }									
                if(model.Price){									
                    storeobj.Price = model.Price									
                }									
                return storeobj									
									
            })									
            return ModelsArray									
        },									
        ItemName(){									
            if(this.EditedTab){									
                if(this.EditedTab.Name === 'Web Form'){									
                    return this.EditedTab.FormName									
                }									
                else{									
                    return this.EditedTab.Title									
                }									
               									
            }									
        },									
        RenderedContentBlocks(){									
            if(this.EditedTab){									
                // if(this.EditedTab.SectionType){									
                    return this.ContentBlocks.filter(block => { 									
                        return !block.SectionType									
                        									
                    })  									
                // }     									
                // else{									
                //     return this.ContentBlocks.filter(block => { 									
                //         return block.SectionType									
                //     })									
                // }           									
                									
            }									
            else{									
                return this.ContentBlocks.filter(block => { 									
                        return block.SectionType									
                    })									
            }									
        },									
        SystemAssetsPaginated(){									
            let displayrange = 10*this.SystemAssetsPage-10									
            return this.SystemAssets.slice(displayrange, displayrange+10)       									
        },									
        FinalGalleryObjects(){									
            let arr1 = JSON.parse(JSON.stringify(this.SystemAssets))									
            let arr2 = JSON.parse(JSON.stringify(this.GalleryObjects))									
            arr1.map(asset => {									
                asset.GalleryObject = false									
                return asset									
            })									
            arr2.map(asset => {									
                asset.GalleryObject = true									
                return asset									
            })									
            return arr1.concat(arr2)									
        },									
        									
        DefaultPageFont(){									
            return `font-family: "`+this.PageContentFont+`", sans-serif;`									
        },									
        									
        BannerImageSimple(){									
            if(this.BannerImage && this.BannerImage.Path){									
                return this.BannerImage.Path									
            }									
            else if(this.BannerImage){									
                return this.BannerImage									
            }									
        },									
        WikiRouteComponents(){									
            return this.SiteRouteComponents.filter(comp => {									
                return typeof comp.meta !== 'undefined' && comp.meta.type === 'Wiki Pages'									
            })									
        },									
      SimpleBannerComponents(){									
          return this.BannerComponents.map(comp => {									
              let obj = {									
                  Name: comp.name,									
                  path: comp.path,									
                  meta: comp.meta									
              }									
              return obj									
          })									
      },									
      SimpleBannerComponentsNames(){									
          return this.BannerComponents.map(comp => {									
              let obj = {									
                  Name: comp.name,									
                  path: comp.path,									
                  meta: comp.meta									
              }									
              return obj.Name									
          })									
      },									
      userLoggedIn () {									
      return this.$store.getters.user									
    },									
      									
      									
      									
      									
									
    },									
    created(){									
      document.body.scrollTop = 0;									
      document.documentElement.scrollTop = 0;									
      setTimeout(() => {									
        }, 1000);									
      this.FocusedViewToggle()									
      this.IntranetViewToggle()									
      this.CheckUser()									
									
    },									
    									
    methods: {									
        CancelEditTestimony(){									
            this.EditedTestimony = ''									
            this.EditedTestimonyIndex = -1									
            this.EditTestimonyDialog = false									
        },									
        EditTestimony(tes,i){									
            this.EditedTestimony = tes									
            this.EditedTestimonyIndex = i									
            this.EditTestimonyDialog = true									
        },									
        AddTestimony(tab){									
            let NewTestimony = {									
                Avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',									
                Name: 'John Leider',									
                Role: 'Creator - Vuetify',									
                Description: `<div style="text-align: center;"><i>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</i></div>`									
            }									
            tab.Elements.push(NewTestimony)									
            									
        },									
        UpdateTabCount(tab){									
            let array = this[tab.DataCountCollection.ArrayName]									
            console.log(array)									
            									
            if(tab.CountOperation === 'Amount'){									
                tab.CounterInteger = array.filter(item => {									
                    if(tab.CountisFiltered){									
                        if(tab.Countby && tab.CountCategory && tab.CategoryValue){									
                            if(item[tab.CountCategory.Name]){									
                                if(tab.Countby === 'Option Set'){									
                                return item[tab.CountCategory.Name].ID === tab.CategoryValue.ID  									
                                }									
                                else if(tab.Countby === 'Lookup Field'){									
                                   									
                                return item[tab.CountCategory.Name+'id'] === tab.CategoryValue.id  									
                                }									
                            									
                            }                        									
                        }   									
                    }									
                    									
                    else if(tab.DataCountCollection){									
                        return item									
                    }									
                }).length									
                if(tab.CountisFiltered && tab.Countby && tab.CountCategory && tab.CategoryValue){									
                tab.Title = tab.CategoryValue.Name									
                }									
                else if(tab.DataCountCollection){									
                 tab.Title = tab.DataCountCollection.Name 									
                }									
            }									
									
        },									
        UpdateStoreProducts(tab){           									
        this.SelectedStoreProducts.map(product => {									
            let match = tab.Elements.find(obj => obj.recordid === product.recordid)									
            if(!match){									
                tab.Elements.push(product)  									
            }                									
        })									
        tab.Elements = tab.Elements.filter((elmnt,i) => {									
            let match = this.SelectedStoreProducts.find(obj => obj.recordid === elmnt.recordid)									
            if(match){									
                return elmnt									
            }									
        })									
        },									
        ColMY(tab){									
            if(tab.Name === 'Card Block Single'){									
                return ''									
            }									
            else{									
                return ' my-3'									
            }									
        },									
        CancelDrag(){									
            setTimeout(() => {									
                this.dragging = ''									
            }, 200);									
        },									
        MatchColor(tab){									
            if(tab.RowColor){									
                return tab.RowColor.hexa									
            }									
            									
        },									
        ShowTabElements(i){									
            setTimeout(() => {									
                console.log('showing elmnts')									
            let id = i+'ElementsConfig'									
            let elmnt = document.getElementById(id)									
            console.log(elmnt)									
            if(elmnt !== null){									
                if(elmnt.style.display === "none"){									
                    elmnt.style.display = "block"									
                    let hidebtnid = document.getElementById(i+'HideElementsConfig')									
                    let showbtnid = document.getElementById(i+'ShowElementsConfig')									
                    hidebtnid.style.display = "block"									
                    if(showbtnid !== null){									
                    showbtnid.style.display = "none"									
                    }									
                }									
            									
            }									
            }, 10);									
            									
            									
        },									
        HideTabElements(i){									
       									
            let id = i+'ElementsConfig'									
            let elmnt = document.getElementById(id)									
            elmnt.style.display = "none"									
            let hidebtnid = document.getElementById(i+'HideElementsConfig')									
            let showbtnid = document.getElementById(i+'ShowElementsConfig')									
            hidebtnid.style.display = "none"									
            if(showbtnid !== null){									
            showbtnid.style.display = "block"									
            }									
            									
        },									
        InsertTab(){									
            let newtab = {									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'blank',									
            Elements: [],									
            ShowComponents: true									
            }									
            this.PageTabs.push(newtab)									
        },									
        DeleteRow(tab,rowindex){									
            confirm('Are you sure? This will remove the row and any content added to it, there is no going back.') && this.ProcessDeleteRow(tab,rowindex)									
        },									
        ProcessDeleteRow(tab,rowindex){									
            tab.Rows.splice(rowindex,1)									
        },									
        ChangeContentType(tab){									
            let tabobj = this.ContentBlocks.find(obj => obj.Name === this.TypeChange.Name)									
          									
            console.log(tabobj)									
            									
          									
           									
           									
           this.EditedTab = Object.assign({},tabobj)									
          if(tab.SectionType){									
              this.PageTabs[this.EditedTabindex] = this.EditedTab									
           this.EditedTab.ShowComponents = false									
            setTimeout(() => {									
                this.EditedTab.ShowComponents = true									
            }, 30)									
          }									
             console.log(this.EditedTab)									
           if(!tab.SectionType){									
               console.log(this.PageTabs[this.customboxindex])									
           console.log(this.PageTabs[this.customboxindex].Elements[this.rowindex])									
                this.PageTabs[this.customboxindex].Elements[this.elementIndex] = this.EditedTab									
                 this.TypeChange = ''									
            this.PageTabs[this.customboxindex].ShowComponents = false									
             setTimeout(() => {									
                this.PageTabs[this.customboxindex].ShowComponents = true									
            }, 3)									
            }									
           									
            									
        //    console.log(tab)									
       									
        },									
        OpenContentTypesDialog(elmnt,elmntindex){									
            this.ContentTypesDialog = true									
        },									
        AdjustRowElementsAmount(tab,row,rowindex){									
           									
            let NewElmnt = {									
                Name: 'blank',									
                Cols: 8									
            }									
            tab.Rows[rowindex].Elements.push(NewElmnt)									
            									
        },									
        AdjustRowAmount(tab,tabindex){									
            if(tab.Name !== 'Card Blocks' && tab.Name !== 'Counter' && tab.Name !== 'Store Products' && tab.Name !== 'Card Row'){									
               let NewRow = {									
                Name: 'blank',									
                Elements: []									
            }   									
            tab.Elements.push(NewRow) 									
            this.PageTabs[tabindex] = tab									
            console.log(this.PageTabs)									
            }									
            									
            else if(tab.Name === 'Card Row'){									
               									
                   let NewRow = {									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Card Row Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,	
                Elevation: 2,								
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Type',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:FlexXLRG,									
                FlexLarge: FlexLarge,									
                FlexMedium: 6,									
                FlexSmall: 6,									
                FlexXSmall: 8,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,									
                									
                									
            }									
            let colors = [									
                    '#F44336',									
                    '#E91E63',									
                    '#9C27B0',									
                    '#673AB7',									
                    '#3F51B5',									
                    '#2196F3',									
                    '#03A9F4',									
                    '#00BCD4',									
                    '#009688',									
                ]									
                let ranint = Math.floor(Math.random() * Math.floor(colors.length))									
                let length = tab.Elements.length									
                let twoback = length-2									
									
                let images = [									
                    'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fweb-developer-vector-illustration-5de192c700231.png?alt=media&token=1dc9a48c-aaf4-473b-87c0-9f7ef84b827f',									
                    'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fwebsite-loading-speed-optimisation-vector-illustration-5de2455056206.png?alt=media&token=39ac7c7a-c899-4cd1-ba7b-269ad58d3962',									
                    'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fwebsite-maintenance-vector-illustration-5de194c28795e.png?alt=media&token=2071c005-5a06-4682-913c-758e4fa1e7f9',									
                    'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fwebsite-support-vector-illustration-5de193d48a370.png?alt=media&token=4f7196c4-c1e0-4010-bdd5-b3d8a952b421'									
                ]									
                let imgopt = tab.Elements.length									
                NewRow.HasImage = true									
                        									
               									
                									
                NewRow.BGColor.hex = '#ffffff'									
                tab.Elements.push(NewRow)									
                this.PageTabs[tabindex] = tab									
               									
                let   lengthint = tab.Elements.length                									
                let FlexXLRG = 0									
                let FlexLarge = 0									
                if(tab.Elements.length < 5){									
                    FlexXLRG = 10/lengthint									
                    FlexLarge = 12/lengthint									
                    NewRow.IMG = images[imgopt]									
               }									
               else{									
                   let n =  lengthint/4									
                   let decimal = n - Math.floor(n)									
                   									
                   let newlengthint = 4*decimal									
                  									
                   if(decimal === 0 || decimal === 0.75){									
                       FlexXLRG = 10/4									
                        FlexLarge = 12/4									
                        									
                   }									
                   else {									
                        FlexXLRG = 10/3									
                        FlexLarge = 12/3									
                        									
                   }									
                   									
                   									
                    									
                NewRow.IMG = images[3]									
               }									
                tab.Elements.map(elmnt => {									
                    elmnt.FlexXLRG = FlexXLRG									
                    elmnt.FlexLarge = FlexLarge									
                    elmnt.ShowComponents = false									
                    setTimeout(() => {									
                        elmnt.ShowComponents = true									
                    }, 100);									
									
                })									
                tab.ShowComponents = false									
                    setTimeout(() => {									
                        tab.ShowComponents = true									
                    }, 100);									
            console.log(this.PageTabs)									
               									
                									
                									
            }									
            else if(tab.Name === 'Card Blocks'){									
                									
                let NewRow = {									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Card Block Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Here is a Headline',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:2,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 4,									
                FlexXSmall: 4,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,									
                									
                									
            }									
            let colors = [									
                    '#F44336',									
                    '#E91E63',									
                    '#9C27B0',									
                    '#673AB7',									
                    '#3F51B5',									
                    '#2196F3',									
                    '#03A9F4',									
                    '#00BCD4',									
                    '#009688',									
                ]									
                let ranint = Math.floor(Math.random() * Math.floor(colors.length))									
                let length = tab.Elements.length									
                let twoback = length-2									
                if(length%2 != 0){									
                    if(tab.Elements[twoback] && tab.Elements[twoback].Type  && tab.Elements[twoback].Type === 1){									
                    NewRow.Title = ''									
                    NewRow.Description = '' 									
                    NewRow.HasImage = true									
                    NewRow.IMG = 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert-BG?alt=media&token=ed09e64f-d4ee-4948-b481-809495b3e236'									
                        NewRow.Type = 2									
                    }									
                    else{									
                    NewRow.Title = ''									
                    NewRow.Description = '' 									
                    NewRow.HasImage = true									
                    NewRow.IMG = 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert?alt=media&token=54d7ec67-2109-4eda-93c0-7bf98f55dbf8'									
                        NewRow.Type = 1									
                    }									
                }									
									
               									
                									
                NewRow.BGColor.hex = colors[ranint]									
                tab.Elements.push(NewRow)									
                this.PageTabs[tabindex] = tab									
            console.log(this.PageTabs)									
            }									
            else if(tab.Name === 'Counter'){									
                let counterrandint = Math.floor(Math.random() * Math.floor(256))									
                let titles = [									
                    'Number of Clients',									
                    'Amount Sold',									
                    'Years in Business',									
                    'Food for Thought'									
									
                ]									
                let titlerandint = Math.floor(Math.random() * Math.floor(titles.length))									
                let NewRow = {									
                CounterIcon: 'mdi-excavator',									
                CounterInteger: counterrandint,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Counter Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                Tile: true,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: titles[titlerandint],									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:2,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,									
                									
                									
            }									
            let colors = [									
                    'red',									
                    'pink',									
                    'purple',									
                    'deep-purple',									
                    'indigo',									
                    'blue',									
                    'light-blue',									
                    'cyan',									
                    'teal',									
                ]									
                let ranint = Math.floor(Math.random() * Math.floor(colors.length))									
                // let length = tab.Elements.length									
                // let twoback = length-2									
                // if(length%2 != 0){									
                //     if(tab.Elements[twoback] && tab.Elements[twoback].Type  && tab.Elements[twoback].Type === 1){									
                //     NewRow.Title = ''									
                //     NewRow.Description = '' 									
                //     NewRow.HasImage = true									
                //     NewRow.IMG = 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert-BG?alt=media&token=ed09e64f-d4ee-4948-b481-809495b3e236'									
                //         NewRow.Type = 2									
                //     }									
                //     else{									
                //     NewRow.Title = ''									
                //     NewRow.Description = '' 									
                //     NewRow.HasImage = true									
                //     NewRow.IMG = 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert?alt=media&token=54d7ec67-2109-4eda-93c0-7bf98f55dbf8'									
                //         NewRow.Type = 1									
                //     }									
                // }									
									
               									
                									
                NewRow.BGColor.hex = colors[ranint]									
                tab.Elements.push(NewRow)									
                this.PageTabs[tabindex] = tab									
            console.log(this.PageTabs)									
            }									
            else if(tab.Name === 'Store Products'){									
                let counterrandint = Math.floor(Math.random() * Math.floor(256))									
                let titles = [									
                    'Brazillian BlowWave',									
                    'Tummy Tuck',									
                    'Bum Lift',									
                    'SpiderVein Removal'									
									
                ]									
                let titlerandint = Math.floor(Math.random() * Math.floor(titles.length))									
                let NewRow = {									
                CounterIcon: 'mdi-excavator',									
                CounterInteger: counterrandint,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Store Product Single',									
                									
                show: false,									
                HasBGIMG: true,									
                BGIMGisIMG: true,									
                ShowsPrice: true,									
                SubHeaderFontFamily: 'Roboto',									
                SubHeaderFontSize: 18,									
                SubHeaderColor: {									
                    hex: '#FF0000FF'									
                },									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 14,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                HasActionButton: true,									
                ActionButtonName: 'Add to Cart',									
                ActionBtnDark: true,									
                ActionButtonType: 'Add Cart Item',									
                ButonColor: {									
                    hex: '#FF0000FF'									
                },									
                ButtonAlignment: 'justify-end',									
                Title: titles[titlerandint],									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {									
                        hex: '#ffffff',			
                        hexa: '#ffffffff',						
                        rgba: {r: 255,g: 255,b: 255}									
                },									
                IsDark: true,									
                									
                									
            }									
            let cards = [									
                {									
                    Title: 'Sandals',									
                    Price: 'ONLY $14.99',									
                    Description: `<div style="text-align: center;">Fun to Wear</div>`,									
                    IMG: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2FXero_Shoes_Sensori_Venture_huarache_style_barefoot_sandal_01.png?alt=media&token=bd65ff7c-0901-4340-8684-21412a8692c6'									
                },									
                {									
                    Title: 'Shirt',									
                    Price: '$10.99',									
                    Description: `<div style="text-align: center;">a Cool Summer Shirt</div>`,									
                    IMG: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fblank-1976334_640.png?alt=media&token=276078fd-21df-4dce-880d-231c7709be60'									
                },									
                {									
                    Title: 'Jeans',									
                    Price: '$108.99',									
                    Description: `<div style="text-align: center;">Empty pocket Jeans, priced to go!</div>`,									
                    IMG: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fno-money-2070384_640.jpg?alt=media&token=a48c26c5-ccb1-4a73-a386-36ccda526983'									
                },									
                {									
                    Title: 'Grafitti LaceUps',									
                    Price: '$8.99',									
                    Description: `<div style="text-align: center;">So funky they make you want to undress</div>`,									
                    IMG: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fshoe-1433925_640.jpg?alt=media&token=2e1bb030-e9bd-42ad-9854-9f412d852576'									
                }									
            ]									
  									
                let ranint = Math.floor(Math.random() * Math.floor(cards.length))									
                NewRow.HasImage = true									
                NewRow.IMG = cards[ranint].IMG									
                NewRow.Description = cards[ranint].Description									
                NewRow.Price = cards[ranint].Price									
                NewRow.Title = cards[ranint].Title									
									
               									
                									
                NewRow.BGColor.hex = '#ffffff'									
                tab.Elements.push(NewRow)									
                this.PageTabs[tabindex] = tab									
                console.log(this.PageTabs)									
            }									
            // tab.ShowComponents = false									
            //  setTimeout(() => {									
            //     tab.ShowComponents = true									
            // }, 3)									
            									
        },									
        NewTestimonialImageFileselected(){									
            this.NewTestimonialFile = event.target.files[0]									
            this.SampleTestinomialFile = URL.createObjectURL(this.NewTestimonialFile)									
        },									
        PrepareThumbnail(filename,file,storageref){									
        let vm = this									
        return new Promise(function(resolve, reject) {									
        file.tmpsrc = URL.createObjectURL(file)									
              //this.OutboundPhotosforUpload.push(file)									
              var img = document.createElement("IMG");									
                img.setAttribute('width',150)									
                img.setAttribute('src',file.tmpsrc)									
                document.body.appendChild(img)									
                									
                img.onload = function() {									
                var c = document.createElement("canvas");									
                var ctx = c.getContext("2d");									
                var canvasRatio = img.naturalHeight / img.naturalWidth									
                c.width = 400									
                c.height = c.width*canvasRatio									
                ctx.drawImage(img, 0, 0, c.width, c.height);									
                c.toBlob(blob => {									
                  let blobfile = new File([blob], filename, {									
              type: file.type,									
            });									
            console.log(blobfile)									
            var storageRef = firebase.storage().ref(storageref+'_thumb')									
                document.body.removeChild(img)									
                var uploadTask = storageRef.put(blobfile)									
                uploadTask									
                  .then(snapshot => snapshot.ref.getDownloadURL())									
                    .then((thumburl) => {									
                      console.log(thumburl)									
                      vm.EditedTestimony.Avatar = thumburl									
                      vm.CancelEditTestimony()									
                    })									
              })									
                }									
        })									
      },									
        SaveTestimonialFile(){									
            if(this.NewTestimonialFile){									
                let vm = this                									
                var storageRef = 'LandingPages/'+vm.$route.params.id+'/Testimonials/'+new Date()+'/'+vm.NewTestimonialFile.name									
                vm.PrepareThumbnail(vm.NewTestimonialFile.name,vm.NewTestimonialFile,storageRef)									
                									
            }									
        },									
        NewAssetFileselected(){									
            									
            var value = prompt("Please choose a name for future refence to this asset", "");									
                if (value != null) {									
                this.AssetName = value									
                this.NewAssetFile = event.target.files[0]									
            // this.SampleBannerImagefile = URL.createObjectURL(this.NewAssetFile)									
                }									
            									
        },									
        SaveNewsystemAsset(){									
            if(this.NewAssetFile){									
                let vm = this                									
                var storageRef = firebase.storage().ref('systemAssets/'+vm.$route.params.id+'/'+vm.NewAssetFile.name+'_thumb')									
               									
                var uploadTask = storageRef.put(this.NewAssetFile)									
                uploadTask									
                  .then(snapshot => snapshot.ref.getDownloadURL())									
                    .then(thumburl => {									
                    let Assetshorttype = vm.NewAssetFile.type.split('image/').join('')									
                    let NewAsset = {Name: vm.AssetName, Path: thumburl, ShortType: Assetshorttype, FullName: vm.AssetName.split(' ').join('_')+'.'+Assetshorttype}									
                        // this.SystemAssets.push(NewAsset)									
                        db.collection('systemassets').doc(vm.AssetName.split(' ').join('_')).set(NewAsset).then(doc => {									
                                console.log('updated asset')									
                        })									
                })									
            }									
        },									
            									
        HeaderCenterClass(tab){									
            if(tab.CenteredHeader){									
                tab.Center = 'justify-center'									
            }									
            else{									
                tab.Center = ''									
            }									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        									
        DeleteTabAsset(tab,photoindex){									
            confirm('This will remove the item from this tab, are you sure?') && this.ProcessDeleteTabAsset(tab,photoindex)									
        },									
        ProcessDeleteTabAsset(tab,photoindex){									
            tab.Assets.splice(photoindex,1)									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        AssignClippingAnimationValue(){									
            if(tab.ClippedPathOption === 'circle'){									
                if(tab.ClipAnimation === 'Spotlight Search'){									
									
                }									
            }									
        },									
        AssignClippingValue(tab){									
            if(tab.ClippedPathOption === 'polygon'){									
                tab.TopLeftCrnrX = tab.TopLeftCrnrXValue+'%'									
                tab.TopLeftCrnrY = tab.TopLeftCrnrYValue+'%'									
                tab.TopRightCrnrX = tab.TopRightCrnrXValue+'%'									
                tab.TopRightCrnrY = tab.TopRightCrnrYValue+'%'									
                tab.BottomRightCrnrX = tab.BottomRightCrnrXValue+'%'									
                tab.BottomRightCrnrY = tab.BottomRightCrnrYValue+'%'									
                tab.BottomLeftCrnrX = tab.BottomLeftCrnrXValue+'%'									
                tab.BottomLeftCrnrY = tab.BottomLeftCrnrYValue+'%'									
									
            }									
             else if(tab.ClippedPathOption === 'elipse'){									
            tab.ElipseX = tab.ElipseXValue+'%'									
            tab.EliseY = tab.ElipseYValue+'%'									
            tab.ElipseXPosition = tab.ElipseXPositionValue+'%'									
            tab.ElipseYPosition = tab.ElipseYPositionValue+'%'									
             }									
             else if(tab.ClippedPathOption === 'circle'){									
            tab.CircleRadius = tab.CircleRadiusValue+'%'									
            tab.CircleXPosition = tab.CircleXPositionValue+'%'									
            tab.CircleYPosition = tab.CircleYPositionValue+'%'									
             }									
             console.log(tab)									
             tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        AssignClipDefaults(tab){									
            //polygon(              25%                   0%,                   100%                    0%,                     75%                     100%,                       0%                      100%);									
 //return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`									
            if(tab.ClippedPathOption === 'polygon'){									
                tab.TopLeftCrnrXValue = 25									
                tab.TopLeftCrnrYValue = 0									
                tab.TopLeftCrnrX = '25%'									
                tab.TopLeftCrnrY = '0%'									
                tab.TopRightCrnrXValue = 100									
                tab.TopRightCrnrYValue = 0									
                tab.TopRightCrnrX = '100%'									
                tab.TopRightCrnrY = '0%'									
                tab.BottomRightCrnrXValue = 75									
                tab.BottomRightCrnrYValue = 100									
                tab.BottomRightCrnrX = '75%'									
                tab.BottomRightCrnrY = '100%'									
                tab.BottomLeftCrnrXValue = 0									
                tab.BottomLeftCrnrYValue = 100									
                tab.BottomLeftCrnrX = '0%'									
                tab.BottomLeftCrnrY = '100%'									
            }									
            else if(tab.ClippedPathOption === 'elipse'){									
                tab.ElipseXValue = 100									
                tab.ElipseYValue = 55									
                tab.ElipseXPositionValue = 48									
                tab.ElipseYPositionValue = 44									
                tab.ElipseX = '100%'									
                tab.EliseY = '55%'									
                tab.ElipseXPosition = '48%'									
                tab.ElipseYPosition = '44%'									
            }									
            else if(tab.ClippedPathOption === 'circle'){									
                tab.CircleRadiusValue = 50									
                tab.CircleXPositionValue = 50									
                tab.CircleYPositionValue = 50									
                tab.CircleRadius = '50%'									
                tab.CircleXPosition = '50%'									
                tab.CircleYPosition = '50%'									
            }									
            console.log(tab)									
        },									
        CheckifClipped(tab){									
        									
            if(tab.Clipped){									
                if(tab.ClippedbyPath){									
                    if(tab.ClippedPathOption){									
                        if(tab.ClippedPathOption === 'elipse'){									
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`									
                        }									
                        if(tab.ClippedPathOption === 'circle'){									
                            if(tab.ClipAnimation === 'Spotlight Search'){									
                                return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`									
                            }									
                            else{									
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);`   									
                            }									
                            									
                        }									
                        if(tab.ClippedPathOption === 'polygon'){									
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`									
                        }									
                        // if(tab.ClippedPathOption === 'beizer'){									
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 									
                        //     d="M 0,1									
						// 			L 0,0
						// 			L 1,0
						// 			L 1,1
						// 			C .65 .8, .35 .8, 0 1
						// 			Z"
                        // }									
                    }									
                    									
                }									
                else if(tab.ClippingIMG){									
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);									
                                        -webkit-mask-repeat: no-repeat;									
                                        -webkit-mask-size: contain;									
                                        -webkit-mask-position: center;`   									
                }									
                									
                									
            }									
        },									
        FormFieldsDialogActivate(){									
            this.FieldsDialogActivate = true									
        },									
									
       ActivateOptionSetDialog(field,ContentBlockObject){									
         this.EditOptionSet = field									
         if(!this.EditOptionSet.Options){									
           this.EditOptionSet.Options = []									
         }									
         this.OptionSetDialog = true									
       },									
       AddOptionSetOption(){									
      if(typeof this.EditOptionSet.lastassignedinteger === 'undefined'){									
        this.EditOptionSet.lastassignedinteger = 1000000									
        									
      }      									
       let integer = this.EditOptionSet.lastassignedinteger-1+2									
      									
      let NewOption = {Name: this.OptionName, ID: integer}									
      this.EditOptionSet.Options.push(NewOption)									
      this.OptionName = ''									
      this.EditOptionSet.lastassignedinteger = integer									
    },									
    OptionNameSelect(option){									
      this.Option = option									
    },									
    CancelOptionSetDialog(){									
									
    },									
    SaveOptionSets(){									
      let fieldobj = this.EditedTab.FormFields.find(obj => obj.Name === this.EditOptionSet.Name)									
      let fieldindex = this.EditedTab.FormFields.indexOf(fieldobj)									
      this.EditedTab.FormFields[fieldindex].Options = this.EditOptionSet.Options									
      this.OptionSetDialog = false									
      this.NewContentDialog = false									
         this.NewContentDialog = true									
    },									
        AddFieldtoWebForm(){									
         this.FieldsDialogActivate = false									
         this.FieldsDialogActivate = true									
         if(!this.EditedTab.FormFields){									
           this.EditedTab.FormFields = []									
         }									
         let NewField = {Name: 'Field',Type:'Single Line Text'}									
         this.EditedTab.FormFields.push(NewField)									
          tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
       },									
       HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },								
        HeaderColor(tabHeaderColor){									
            if(tabHeaderColor.hex){									
                return tabHeaderColor.hex									
            }									
        },									
        SetRowTransparency(tab){									
            if(tab.Transparent){									
                tab.RowTransparency = 'transparent'									
            }									
            else{									
                tab.RowTransparency = ''									
            }									
             tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
									
        },									
        AlertMe(){									
            alert('alert me')									
        },									
        									
        ImageAlignment(tab,justify){									
            tab.Justify = justify									
            this.TabDialog = false									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        BoxWrapping(tab,alignment){									
            if(tab.Name === 'Parallax'){									
                tab.LayoutValue = alignment.LayoutValue.split('fill-height ')[1]									
            }									
            else{									
                tab.LayoutValue = alignment.LayoutValue									
            }									
            									
            tab.FlexValue = alignment.FlexValue									
            this.TabDialog = false									
            this.ShowComponents = false									
            									
            setTimeout(() => {									
                this.ShowComponents = true									
            }, 10);									
            									
        },									
        DeleteTab(){									
            confirm('Are you sure? Everything on this tab will be lost forever') && this.ProcessDeleteTab()									
        },									
        ProcessDeleteTab(){									
            console.log('this one yeah',this.customboxindex,this.elementIndex,this.PageTabs)									
            if(this.elementIndex === -1){									
              console.log(this.EditedTabindex)									
            this.PageTabs.splice(this.EditedTabindex,1)									
            console.log(this.PageTabs)  									
            }									
            else{									
                console.log(this.rowindex)									
                if(this.rowindex > -1){									
                    this.PageTabs[this.customboxindex].Rows[this.rowindex].Elements.splice(this.elementIndex,1)									
                }									
                else{									
                    console.log('this one yeah',this.customboxindex,this.elementIndex,this.PageTabs)									
                   this.PageTabs[this.customboxindex].Elements.splice(this.elementIndex,1) 									
                }									
                									
            }									
            									
        },									
        ActivateTextBoxDialog(tab,tabindex){									
            									
            // this.EditedTab = tab									
            // this.EditedTabindex = tabindex									
             this.ContentTypesDialog = false									
            this.TabDialog = false									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
            this.TextBoxDialog = true									
           									
            console.log(this.TextBoxDialog)									
        },									
        AssignGalleryAssets(tab,asset){									
            console.log(tab)									
            if(this.EditedTab.Name === 'Gallery'){									
                let galleryobj = {									
                Name: asset.Name,									
                URL: asset.Path									
            }									
            if(asset.Path){									
                galleryobj.URL = asset.Path									
            }									
            if(asset.path){									
                galleryobj.URL = asset.path									
            }									
            if(asset.ThumbURL){									
                galleryobj.ThumbURL = asset.ThumbURL									
            }									
            if(!asset.ThumbURL){									
                galleryobj.ThumbURL = galleryobj.URL									
            }									
            									
            tab.Assets.push(galleryobj)									
            console.log(tab)									
             tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
            }									
            else if(this.EditedTab.Name === 'Parallax' || this.EditedTab.Name === 'Image' || this.EditedTab.HasImage){									
                this.AssignImage(tab,'IMG',asset)									
            }									
            									
            									
        },									
        AssignImage(tab,prop,asset){									
            if(asset.Path){									
             tab[prop] = asset.Path   									
            }									
            else if(asset.path){									
                tab[prop] = asset.path 									
            }									
            									
            console.log(tab)									
             tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        SavePage(){									
            confirm('This will save the page are you sure?') && this.ProcessSavePage()									
        },									
        ProcessSavePage(){									
            let CodeElmentArray = document.getElementsByClassName('CodeRenders')									
            let PageCode = ''									
            this.PageTabs.map((tab,i) => {									
                PageCode = PageCode+CodeElmentArray[i].innerHTML									
            })									
            console.log(this.PageTabs)									
            db.collection('landingpages').doc(this.$route.params.id).set({									
                PageTabs: this.PageTabs,									
                PublishType: 'Public' ,									
                PageCode: PageCode,									
                Name: this.PageName,									
                Overview: this.PageOverview,									
                PageContentFont: this.PageContentFont,									
                PageHeadersFontSize: this.PageHeadersFontSize,									
                PageHeadersFont: this.PageHeadersFont,									
            })									
        },									
        FlexClass(tab){									
            return 'lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall+' mx-2 '									
        },									
        UpdateTestimonyHTMLBox(){									
            let id = this.EditedTestimony.Name.split(' ').join('_')+'editor'									
            let contentbox = document.getElementById(id)									
            if(contentbox){									
                this.EditedTestimony.Description = contentbox.innerHTML									
            }									
           									
        },									
        UpdateHTMLBox(){									
            let id = this.EditedTab.Name.split(' ').join('_')+this.EditedTabindex+'editor'									
            let contentbox = document.getElementById(id)									
            if(contentbox){									
                this.EditedTab.Description = contentbox.innerHTML									
            }									
            console.log(this.EditedTab,id)									
        },									
        UpdateTextBoxHTMLBox(){									
            let id = this.EditedTab.Name.split(' ').join('_')+this.EditedTabindex+'editor'									
            let contentbox = document.getElementById(id)									
            if(contentbox){									
                this.EditedTab.TextBoxContent = contentbox.innerHTML									
            }									
            this.TabDialog = false									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
            console.log(this.EditedTab,id)									
        },									
        addphone(){									
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")									
         document.execCommand("CreateLink", false, 'tel:'+url);									
       },									
       addemail(){									
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")									
         document.execCommand("CreateLink", false, 'mailto:'+url);									
       },									
       link() {									
    var url = prompt("Enter the URL");									
    document.execCommand("createLink", false, url);									
    },									
        changeColor() {									
        var color = prompt("Enter your color in hex ex:#f1f233");									
        document.execCommand("foreColor", false, color);									
        },									
        copy() {									
      document.execCommand("copy", false, "");									
    },									
									
        ImageHeight(tab,value){									
            if(!tab.Height){									
                tab.Height = 50									
            }									
            if(value === 'down'){									
                tab.Height = tab.Height-50									
            }									
             if(value === 'up'){									
                tab.Height = tab.Height+50									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
            console.log(tab.Height)									
        },									
        ImageWidth(tab,value){									
            let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.Width = widthnumber-10+'%'									
            }									
             if(value === 'up'){									
                tab.Width = widthnumber+10+'%'									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        FillHeight(tab){									
            console.log(tab.isFillHeight)									
            if(tab.isFillHeight){									
                tab.FillHeight = 'fill-height'									
            }									
            else{									
                tab.FillHeight = ''									
            }									
        },									
        RowHeight(tab,value){									
            if(!tab.RowHeight){									
                tab.RowHeight = 200									
            }									
            console.log(tab.RowHeight)									
          									
            if(value === 'down'){									
                tab.RowHeight = tab.RowHeight-25									
            }									
             if(value === 'up'){									
                tab.RowHeight = tab.RowHeight+25									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        AssignImageWidth(tab){									
             let value = tab.IMGWidthValue+'%'									
            console.log(value)									
            tab.IMGWidth = value									
            console.log(tab.IMGWidth)									
            },									
        CheckCardFullHeight(tab){									
            									
            let value = tab.HeightValue+'%'									
            console.log(value)									
            tab.FullHeight = ' height: '+value+';'									
            console.log(tab.FullHeight)									
        },									
        UpdateBoxTransparency(tab){									
            console.log(tab.BoxTransparent)									
            if(tab.BoxTransparent){									
                tab.BoxTransparency = 'transparent'									
            }									
            else{									
                tab.BoxTransparency = ''									
            }									
            },									
        BoxHeight(tab,value){									
            if(!tab.BoxHeight){									
                tab.BoxHeight = 200									
            }									
            console.log(tab.BoxHeight)									
          									
            if(value === 'down'){									
                tab.BoxHeight = tab.BoxHeight-10									
            }									
             if(value === 'up'){									
                tab.BoxHeight = tab.BoxHeight+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowBottomMargin(tab,value){									
            if(!tab.MarginBottom){									
                tab.MarginBottom = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginBottom = tab.MarginBottom-10									
            }									
             if(value === 'up'){									
                tab.MarginBottom = tab.MarginBottom+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowTopMargin(tab,value){									
            if(!tab.MarginTop){									
                tab.MarginTop = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginTop = tab.MarginTop-10									
            }									
             if(value === 'up'){									
                tab.MarginTop = tab.MarginTop+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowLeftMargin(tab,value){									
            if(!tab.MarginLeft){									
                tab.MarginLeft = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginLeft = tab.MarginLeft-10									
            }									
             if(value === 'up'){									
                tab.MarginLeft = tab.MarginLeft+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowRightMargin(tab,value){									
            if(!tab.MarginRight){									
                tab.MarginRight = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginRight = tab.MarginRight-10									
            }									
             if(value === 'up'){									
                tab.MarginRight = tab.MarginRight+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        BottomMargin(tab,value){									
            if(!tab.MarginBottom){									
                tab.MarginBottom = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginBottom = tab.MarginBottom-10									
            }									
             if(value === 'up'){									
                tab.MarginBottom = tab.MarginBottom+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        TopMargin(tab,value){									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginTop = tab.MarginTop-10									
            }									
             if(value === 'up'){									
                tab.MarginTop = tab.MarginTop+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        LeftMargin(tab,value){									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginLeft = tab.MarginLeft-10									
            }									
             if(value === 'up'){									
                tab.MarginLeft = tab.MarginLeft+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RightMargin(tab,value){									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginRight = tab.MarginRight-10									
            }									
             if(value === 'up'){									
                tab.MarginRight = tab.MarginRight+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        ClassCheck(tab){									
           var newclass = ''									
           									
            if(tab.Transparent){									
                newclass = newclass+' red'									
                tab.Color = '#ffffff'									
            }									
             if(tab.Clipped){									
                newclass = newclass+' clipped'									
            }									
            return newclass									
        },									
        									
        OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex){									
            console.log(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex)									
            this.EditedTab = tab									
            this.EditedTabindex = tabindex									
            if(this.TabDialog === false){									
              this.TabDialog = true  									
            }									
            if(IsElement){									
                this.customboxindex = CustomBoxTabIndex									
                									
                this.elementIndex = tabindex									
               									
                if(isrow){									
                this.rowindex = rowindex       									
                									
                this.EditedRow = this.PageTabs[this.customboxindex].Rows[this.rowindex]            									
                console.log(this.PageTabs[this.customboxindex])   									
                }									
                else{									
                    this.EditedRow = ''									
                this.rowindex = -1									
                }									
                									
            }									
            else{									
                this.EditedRow = ''									
                this.rowindex = -1									
                this.elementIndex = -1									
                this.customboxindex = -1									
            }									
            									
            console.log('supposed to open tabdialog for this one',tab)									
									
        },									
        allowDrop(event,index){									
            if(this.dragging.SectionType){									
               // alert('dragover triggerds')									
            let elmnt = document.getElementById(index+'drop-area')									
            // console.log(elmnt)									
            elmnt.style.visibility = 'visible'									
            elmnt.style.height = '100px' 									
            }									
            									
        },									
        unallowDrop(event,index){									
            // alert('dragover triggerds')									
            let elmnt = document.getElementById(index+'drop-area')									
            // console.log(elmnt)									
            elmnt.style.visibility = 'hidden'									
            elmnt.style.height = '0px'									
        },									
        									
        Elmntdragstart(tab,tabindex,elmnt,elmntindex){									
            this.draggingtab = tab									
            this.draggingtabindex = tabindex									
            this.draggingelement = elmnt									
            this.draggingelementindex = elmntindex									
        },									
        Elmntdragfinish(to,ev){									
            // let newobj = Object.assign({},this.dragging)									
            console.log(this.draggingrow)									
            // this.EditedTab.Rows.splice(to, 1,this.draggingrow,this.EditedTab.Rows.splice(to)[0])									
            this.PageTabs[this.draggingtabindex].Elements.splice(to, 0, this.PageTabs[this.draggingtabindex].Elements.splice(this.draggingelementindex, 1)[0]);									
            // console.log(this.PageTabs)									
            //this.SiteTabs[totab].Sections[tosection].Content.splice(tocontent, 0, this.SiteTabs[fromtab].Sections[fromsection].Content.splice(fromcontent, 1)[0]);									
            this.draggingtab = ''									
            this.draggingtabindex = -1									
            this.draggingelement = ''									
            this.draggingelementindex = -1									
        },									
        Tabdragstart(row,rowindex){									
            this.draggingrow = row									
            this.draggingrowindex = rowindex									
        },									
        Tabdragfinish(to,ev){									
            // let newobj = Object.assign({},this.dragging)									
            console.log(this.draggingrow)									
            // this.EditedTab.Rows.splice(to, 1,this.draggingrow,this.EditedTab.Rows.splice(to)[0])									
            this.PageTabs.splice(to, 0, this.PageTabs.splice(this.draggingrowindex, 1)[0]);									
            // console.log(this.PageTabs)									
            //this.SiteTabs[totab].Sections[tosection].Content.splice(tocontent, 0, this.SiteTabs[fromtab].Sections[fromsection].Content.splice(fromcontent, 1)[0]);									
            this.draggingrow = ''									
            this.draggingrowindex = -1									
        },									
        rowdragstart(row,rowindex){									
            this.draggingrow = row									
            this.draggingrowindex = rowindex									
        },									
        blockdragstart(block) {									
            console.log(block)									
            this.dragging = block									
            },									
            Rowdragfinish(to,ev){									
                // let newobj = Object.assign({},this.dragging)									
                console.log(this.draggingrow)									
                // this.EditedTab.Rows.splice(to, 1,this.draggingrow,this.EditedTab.Rows.splice(to)[0])									
                this.EditedTab.Rows.splice(to, 0, this.EditedTab.Rows.splice(this.draggingrowindex, 1)[0]);									
                // console.log(this.PageTabs)									
                //this.SiteTabs[totab].Sections[tosection].Content.splice(tocontent, 0, this.SiteTabs[fromtab].Sections[fromsection].Content.splice(fromcontent, 1)[0]);									
                this.draggingrow = ''									
                this.draggingrowindex = -1									
            },									
            newcontentdropFinish(to,ev){									
                if(this.dragging.SectionType){									
                  let newobj = Object.assign({},this.dragging)									
                    this.PageTabs.splice(to, 0, newobj);									
                    console.log(this.PageTabs)  									
                }									
                									
            },									
            elementdropFinish(to,ev){									
                let newobj = Object.assign({},this.dragging)									
                console.log(this.dragging)									
                this.PageTabs[to].Elements.push(newobj);									
                									
                console.log(this.PageTabs)									
            },									
            contentdropFinish(to,ev){									
                let newobj = Object.assign({},this.dragging)									
                console.log(this.dragging)									
                this.PageTabs.splice(to-1+2, 0, newobj);									
                									
                console.log(this.PageTabs)									
            },									
            									
            tabdragFinish(to, ev) {									
                const tabindex = this.PageTabs.indexOf(ev)									
                this.tabmoveItem(this.dragging,  to);									
									
                },									
                tabmoveItem(from, to) {									
									
                // console.log(this.dragging)									
                // console.log(from)									
                // console.log(to)									
                    this.SiteTabs.splice(to, 0, this.SiteTabs.splice(from, 1)[0]);									
									
                },									
        SaveBannerAd(){									
            let BannerAdObj = {									
                BannerImage: this.BannerImageSimple,									
                ActionButtonName: this.ActionButtonName,									
                BannerLink: this.BannerLink,									
                BannerHeader: this.BannerHeader,									
                LinkType: this.LinkType,									
                BannerType: this.BannerType,									
                BannerTypeID: this.BannerType.ID,									
                BannerName: this.BannerName,									
                BannerDescription: this.BannerDescription,									
                									
                RecordSpecific: this.RecordSpecific,									
                LinkSource: this.LinkSource,									
                PublishType: this.PublishType									
            }									
            if(this.FeatureType && this.RecordSpecific){									
                BannerAdObj.FeatureType = this.FeatureType									
                BannerAdObj.BannerComponents = this.SimpleBannerComponents									
                BannerAdObj.BannerComponentsNames = this.SimpleBannerComponentsNames      									
                BannerAdObj.RecordName = this.RecordName          									
            }									
            else{									
                BannerAdObj.BannerComponents = this.SimpleBannerComponents									
                BannerAdObj.BannerComponentsNames = this.SimpleBannerComponentsNames									
            }									
            console.log(BannerAdObj)									
            db.collection('bannerads').doc(this.$route.params.id).set(BannerAdObj).then(doc => {									
                console.log('yipee set the doc '+doc.id)									
            })									
        },									
        PrepareNewBannerAd(){									
            let ref = db.collection('bannerads').doc()									
        let newdocid = ref.id									
        this.BannerAdView = true									
        this.NewBannerAdID = newdocid									
        									
        },									
        PrepareNewLandingPage(){									
            let ref = db.collection('landingpages').doc()									
        let newdocid = ref.id									
        this.LandingPageView = true									
        this.NewLandingePageID = newdocid									
        this.$router.push('/BannerAd/New/'+newdocid)									
        let LinkedfromObj = {									
                        Type: "Password Link",									
                        id: this.$route.params.id,									
                        Created_On: passwordresetdata.Created_On.toDate(),									
                        ExpiryDate: ExpiryDate,									
                        CanAction: true,									
                        ActionText: 'You can always Request another Password Reset?',									
                        ActionLink: '/Login'									
                    }									
                    console.log(LinkedfromObj)									
                    this.$router.push({ name: 'newbannerad', params: {LinkedfromObj: LinkedfromObj }})									
        									
        },									
      AssignViewbyOptions(obj){									
        console.log(obj)									
        this[obj.prop] = obj.options									
        if(obj.DefaultView){									
          this[obj.defaultprop] = obj.DefaultView									
        }									
      },									
      IntranetViewToggle(){									
        this.$emit('IntranetViewToggle',false)									
      },									
      FocusedViewToggle(){									
        this.$emit('FocusedViewToggle',false)									
      },									
      CheckUser(){									
            if(this.userLoggedIn){									
               this.UserRecord = this.userLoggedIn									
              console.log(this.UserRecord)									
              this.GetSiteGroups() 									
              this.GetSiteComponents()									
              this.GetLandingPage()									
              this.GetSystemAssets()									
              this.GetGalleryObjects()									
              this.GetCurrentCustomersWikiArray()									
              this.GetCustomerClaimsWikiArray()									
              this.GetSalesContractsWikiArray()								
            }									
        },									
        									
        GetCurrentCustomersWikiArray(){									
            db.collection('currentcustomerswiki').onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                            this.CurrentCustomersWikiArray.push({									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            })									
                          }									
                        })									
                      })									
        },											
        GetCustomerClaimsWikiArray(){									
            db.collection('customerclaimswiki').onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                            this.CustomerClaimsWikiArray.push({									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            })									
                          }									
                        })									
                      })									
        },											
        GetSalesContractsWikiArray(){									
            db.collection('salescontractswiki').onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                            this.SalesContractsWikiArray.push({									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            })									
                          }									
                        })									
                      })									
        },										
        GetGalleryObjects(){									
            									
            let GalleryPaths = this.$router.options.routes.filter(option => {return option.meta && option.meta.photolibrary})									
            console.log('GalleryPaths',GalleryPaths)									
            GalleryPaths.map(path => {									
                this.GetGalleryItems(path)									
            })									
        },									
        GetGalleryItems(path){									
            db.collection(path.name).onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                              let returnobj = {									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            }									
                            console.log(typeof this.SystemAssets)									
                            // returnobj.Library = path.meta.subcat									
                            let check = this.GalleryLibraries.find(obj => obj.name === path.meta.subcat)									
                            if(!check){									
                                let libraryobj = {									
                                    name: path.meta.subcat,									
                                    compname: path.meta.subcat.split(' ').join(''),									
                                    Assets: []									
                                }									
                                libraryobj.Assets.push(returnobj)									
                                this.GalleryLibraries.push(libraryobj)									
                            }									
                            else{									
                                check.Assets.push(returnobj)									
                            }									
                            console.log(this.GalleryLibraries)									
                            									
                          }									
                        })									
                      })									
        },									
        GetSystemAssets(){									
            db.collection('systemassets').onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                            this.SystemAssets.push({									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            })									
                          }									
                        })									
                      })									
        },									
        GetLandingPage(){									
            db.collection('landingpages').doc(this.$route.params.id).onSnapshot(snapshot => {									
                let landingpagedata = snapshot.data()									
                if(landingpagedata && landingpagedata.PageTabs){									
                    this.PageTabs = landingpagedata.PageTabs.map(tab => {									
                        tab.ShowComponents = true									
                        return tab									
                    })									
                    if(landingpagedata.Name){									
                    this.PageName = landingpagedata.Name									
                }									
                if(landingpagedata.Overview){									
                    this.PageOverview = landingpagedata.Overview									
                }									
                if(landingpagedata.PublishType){									
                    this.PublishType = landingpagedata.PublishType									
                }									
                }									
                									
            })									
        },									
        GetSiteComponents(){									
            this.SiteRouteComponents = this.$router.options.routes									
        },									
        GetSiteGroups(){									
            db.collection('groups').onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                            this.SiteGroups.push({									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            })									
                          }									
                        })									
                      })									
        },									
          DateFormatter(date){									
            if(date){									
            let yearstring = date.split('-')[0]									
              let monthstring = date.split('-')[1]									
              let daystring = date.split('-')[2]									
              let yearnumber = Number(yearstring)									
              let monthnumber = Number(monthstring)									
              let daynumber = Number(daystring)									
              return new Date(yearnumber, monthnumber-1, daynumber)									
              }									
            else{									
              return null									
            }									
          },									
									
    }    									
}									
</script>									
									
<style>									
.tabdrop-area {									
  border: 2px dashed #ccc;									
  border-radius: 20px;									
  margin: 50px auto;									
  padding: 20px;									
  background-color: rgb(235, 235, 235)									
}									
  #drop-area {									
  border: 2px dashed #ccc;									
  border-radius: 20px;									
  margin: 50px auto;									
  padding: 20px;									
  background-color: rgb(235, 235, 235)									
}									
#drop-area.highlight {									
  border-color: purple;									
}									
.stickycontent {									
  position: fixed;									
  margin-top: -100px;									
}									
.clipped{									
      mask-size: contain;									
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FQSHCimvRtK2k5IgF1edf%2FELBInvert_Window?alt=media&token=6d585c59-3127-4665-9a02-aa113f654ac1);									
  mask-repeat: no-repeat;									
  mask-position: center; 									
}									
.handles {									
  									
    position: absolute;									
    top: 0;									
    left: 0;									
    right: 0;									
    bottom: 0;									
}									
.handle {									
    height: 25px;									
  width: 25px;									
  border-radius: 50%;									
  box-shadow: inset 0 0 0 2px;									
  opacity: 0.8;									
  cursor: grab;									
  /* border-radius: 50%;									
    box-shadow: inset 0 0 0 10px;									
    opacity: 0.8;									
    -webkit-transition: opacity 0.25s;									
    transition: opacity 0.25s;									
    cursor: -webkit-grab;									
    cursor: -moz-grab;									
    cursor: grab; */									
}									
									
@keyframes spotlightsearch {									
									
    0% { clip-path: circle(50px at 0 100px); }									
    15% { clip-path: circle(15% at 100% 50%); }									
    50% { clip-path: circle(15% at 10% 80%); }									
    60% { clip-path: circle(15% at 80% 70%); }									
    75% { clip-path: circle(15% at 50% 50%); }									
    100% { clip-path: circle(75%); }									
}									
.v-treeview-node__prepend {									
    width: 100%! important;									
}									
									
</style>									
									
        									

    