          
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  
  

  
<v-dialog v-model="UploadingMultipleFileUpload1File" max-width="500px">
                <v-card min-height="300">
                <v-img
                width="500"
                src="@/assets/RABaseBG-Soft.png"
                ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                  <v-card-title class="mediumoverline white--text">
                    Uploading...
                  </v-card-title></div>
                    <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                      <v-layout row class="justify-center mx-3 my-3">
                          <ul>
                            <progress id="multiplefileupload1progressbar" max="100" value="0"></progress>
                            <p style="font-size:16px;color:blue;" id="multiplefileupload1progresstext">0% complete</p>
                          </ul>
                      </v-layout>
                    </v-row>
                    </v-img>
                 </v-card>
               </v-dialog>
<v-dialog v-model="EditingMultipleFileUpload2Dialog" max-width="500px">
  <v-card>
      <v-img
      width="500"
      src="@/assets/RABaseBG-Soft.png"
      ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
        <v-card-title class="mediumoverline white--text">
          Editing MultipleFileUpload2
        </v-card-title></div>
        <v-card-text>
        <v-select v-model="MultipleFileUpload2EditOption" :items="['Move out of Record','Destroy']">
        </v-select>
      </v-card-text>
      <v-card-actions class="white documenttypeshadowinvert">
        <v-btn  color="orange" dark class="overline" @click="CancelEditingMultipleFileUpload2Dialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
            <v-btn  color="green" dark class="overline" @click="ProcessEditingMultipleFileUpload2Dialog()">Process</v-btn>
      </v-card-actions>
      </v-img>
      </v-card>
      </v-dialog>
    
  <v-dialog width="400" v-model="ChangeStatusDialog">
          <v-card width="400">
            <v-card-title>Change Status</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Status"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
             v-if="Status"
            :items="Status.Options"
            v-model="Status_Reason"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="ViewFileDialog" fullscreen>
        <v-card tile class="white" flat>
          <v-btn @click="CloseViewFileDialog()" style="z-index:2;">Close</v-btn>
          <embed v-if="ViewFileURL" :height="ImageHeight-25" :width="ImageWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="ImageHeight-25" :width="ImageWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>

      <v-toolbar tile dark :color="Status && Status.ID === 1000001 ? 'accent':'warning'" class="stickytopbanner2" width="100%" id="recordtoolbar" v-if="!WikiMode">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item @click="ChangeStatusDialog = !ChangeStatusDialog" class="listoutline">
            <v-list-item-title  class="subtleoverline">
              {{StatusToggleName}}
            </v-list-item-title>
          <v-list-item-action>
              <v-icon class="actionicon" color="white">mdi-power</v-icon>
            </v-list-item-action>
          </v-list-item>
        <v-list-item @click="UpdateCustomerClaim()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditCustomerClaim()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="AssignProcess()"  class="listoutline">
      <v-list-item-title  class="subtleoverline">
        Assign Process
      </v-list-item-title>
    <v-list-item-action>
        <v-icon class="actionicon" color="warning">mdi-swap-vertical-variant</v-icon>
      </v-list-item-action>
    </v-list-item>
          <v-list-item @click="WikiMode = !WikiMode" v-if="!WikiMode && CanEdit && userIsWikiModerator"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Switch to Wiki
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-wikipedia</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="WikiMode = !WikiMode" v-if="WikiMode"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Switch to Data
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="accent">mdi-database</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="WikiMode" @click="PublishWikiPage()"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Publish Wiki
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-publish</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title>
   <v-list width="100%" class="transparent">
   <v-list-item>
  <v-list-item-content >
    <span class="headline"><span class=" hidden-sm-and-down">Customer Claim - </span><span class="headline font-weight-light">{{ Title  }}</span></span>                   
    <v-list-item-subtitle v-if="Status">
       {{Status.Name}}
    </v-list-item-subtitle>
  </v-list-item-content>
  </v-list-item>
    </v-list>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
     <v-parallax class="recordparallax" v-if="AppisDarkMode && !WikiMode"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
        >               
        </v-parallax>
        <v-parallax class="recordparallax" v-if="!AppisDarkMode && !WikiMode"
        height="150"
            src="@/assets/RASolidA.jpeg"
        >               
        </v-parallax>
  <LookupComponent :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
    <div v-if="!WikiMode">
    
    
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
  <v-icon style="background-color:green;" @click="UpdateCustomerClaim()" color="white" class="soloactionicon">mdi-content-save</v-icon>
  </v-layout>
  

  
  
    

  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Customer Claim help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingCustomerClaim" tile  width="100%" class="stickytopbanner3" id="recordownershipbanner1">
  <v-layout row class="justify-start" id="recordbanner">
      <v-col v-if="Owner">
        <strong>Owner: </strong>{{ Owner.Name+' '+Owner.Surname}}
      </v-col>
    </v-layout>    
</v-card>
<v-card v-if="EditingCustomerClaim" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">CustomerClaim - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Title" label="Title" />
  </v-card-text>
  </v-img>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <strong class="accent--text">Owner:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" @change="AssignBU()" v-model="Owner" return-object></v-autocomplete>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card> 
  
  
  

  

  

    <v-dialog v-model="SelectProcessDialog" max-width="400">
  <v-card>
    <v-card-title>
      Assign Process
    </v-card-title>
    <v-select return-object @change="AssignProcessConfirm()" v-model="ActiveProcess" item-text="DisplayName" :items="EntityProcesses" label="Process"></v-select>
  </v-card>
</v-dialog>
    
    <v-stepper v-model="StageID"  :dark="AppisDarkMode">

      <v-stepper-header>
        <span v-for="(stage,index) in ProcessStages" :key="stage.Number">
          <v-stepper-step  editable :step="index-1+2" :complete="IsComplete(stage)" @click="updateStatus(stage)">{{stage.Name}}
          </v-stepper-step>
          <v-divider></v-divider></span>          
      </v-stepper-header>
     <v-row class="justify-space-between mx-3" v-if="StageID && ProcessStages && ProcessStages[StageID-1] && ProcessStages[StageID].Steps-1">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(0,3)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(3,6)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(6,9)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          sm="6 "
          md="4"
          lg="3"
          xl="2"
        >
          <v-checkbox v-for="step in ProcessStages[StageID-1].Steps.slice(9,12)" :key="step.itemObjKey"
            @change="UpdateStages(step.Name,step.Prop)" v-model="step.Prop" :label="step.DisplayName"
          ></v-checkbox>
        </v-col>
    </v-row>

  </v-stepper>
  
   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
          <v-card height="80px" flat class="transparent">
          </v-card>
                
      
          <v-layout row class="justify-start mx-3"><span class="secondary--text headline" id="Basic"></span></v-layout>
 
           <div class="flex row xl12 lg12 md12 sm12" v-if="!EditingCustomerClaim">
        <v-col style="padding-bottom:40px;" class="my-5 slightmargin" cols="12" sm="12">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                <v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection1 = !EditingSection1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Title</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Title}}</h4>
                      
                      
                      
                    <span v-if="EditingSection1"><v-text-field id="Title"  v-model="Title"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Description</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Description}}</h4>
                      <v-textarea id="Description" v-if="EditingSection1" v-model="Description"></v-textarea>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Client</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <router-link :to="'/User/'+Client.id" v-if="Client"><h4 v-if="!EditingSection1" class="light-blue--text">{{Client.Full_Name}}</h4></router-link><a v-if="!Client && HasClient" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedClient.Full_Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedClient.Full_Name }}"</p></a>
                    <span v-if="EditingSection1"><v-combobox
                         id="Client"
                        v-if="Client || !Client && !HasClient"
                        v-model="Client"
                        :items="UsersArray"
                        item-text="Full_Name"
                        @change="HasClient = false"
                         label="Client"
                        small-chips
                        solo
                        flat
                        >
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingClient()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewClientDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template></v-combobox>
                        <div  class="unpermitted" v-if="!Client && HasClient">
                        <v-combobox
                         id="Client"
                        v-model="UnpermittedClient"
                        readonly
                        :items="ClientArray"
                        item-text="Full_Name"
                        hide-selected
                         label="Client"
                        small-chips
                        solo
                        flat
                        ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedClient.Full_Name }}", and therefore cannot change the linked record</p></div>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Contract</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <router-link :to="'/CurrentCustomer/'+Contract.id" v-if="Contract"><h4 v-if="!EditingSection1" class="light-blue--text">{{Contract.Name}}</h4></router-link><a v-if="!Contract && HasContract" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedContract.Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedContract.Name }}"</p></a>
                    <span v-if="EditingSection1"><v-combobox
                         id="Contract"
                        v-if="Contract || !Contract && !HasContract"
                        v-model="Contract"
                        :items="ContractArray"
                        item-text="Name"
                        @change="HasContract = false"
                         label="Contract"
                        small-chips
                        solo
                        flat
                        >
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingContract()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewContractDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template></v-combobox>
                        <div  class="unpermitted" v-if="!Contract && HasContract">
                        <v-combobox
                         id="Contract"
                        v-model="UnpermittedContract"
                        readonly
                        :items="ContractArray"
                        item-text="Name"
                        hide-selected
                         label="Contract"
                        small-chips
                        solo
                        flat
                        ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedContract.Name }}", and therefore cannot change the linked record</p></div>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Date of Symptom</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Date_of_Symptom}}</h4>
                      
                    <span v-if="EditingSection1">
                      <v-menu
                        v-model="Date_of_Symptommenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field      
                            id="Date_of_Symptom"                      
                            v-model="Date_of_Symptom"
                            label="Date of Symptom"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="Date_of_Symptom" @input="Date_of_Symptommenu = false"></v-date-picker>
                      </v-menu>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Claim Value</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Claim_Value}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field id="Claim_Value" type="number" v-model="Claim_Value"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Final Quote</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <input
                    style="display: none"
                    type="file"
                    @change="onFinal_QuoteFileselected($event)"
                    ref="Final_QuotefileInputer">                
                    <v-btn @click="onFinal_QuoteUpload()" v-if="selectedFinal_QuoteFile">Save</v-btn>
                     <v-btn @click="closeFinal_Quote()" v-if="selectedFinal_QuoteFile">Cancel</v-btn>
                     <v-btn @click="$refs.Final_QuotefileInputer.click()" v-else  color="pop" dark icon><v-icon>mdi-pencil</v-icon></v-btn><v-btn @click="GetShortLivedURL(Final_Quote)" color="secondary" dark icon><v-icon>mdi-eye</v-icon></v-btn>
                    <span v-if="EditingSection1"><v-text-field id="Final_Quote"  v-model="Final_Quote"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
            </v-card>
          </v-col>
          </div>
    
          
          
        
          
          <v-card height="80px" flat class="transparent">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="SupportingDocumentation">    
              <v-card-title class="align-center accent white--text">
                <span class="headline">Supporting Documentation</span>
              </v-card-title>
            </v-card>
             <v-card color="rgba(255,255,255,0.6)" outlined>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
            
              <v-dialog v-model="MultipleFileUpload1dialog" max-width="500px">
               <v-card>
                  <v-img
                  width="500"
                  src="@/assets/RABaseBG-Soft.png"
                  ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                    <v-card-title class="mediumoverline white--text">
                      Register Supporting Docs
                    </v-card-title></div>
                    <v-form ref="Enquiriesform" id="Enquiriesform">
                      <v-card-text>
                            <v-select  :rules="[rules.required]" :items="MultipleFileUpload1PrimaryOptions"
                            label="Select Primary Type" v-model="editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption" item-text="Name" return-object>
                            </v-select>
                            <input  v-if="!editedMultipleFileUpload1Item.fileurl"
                            type="file"
                            @change="onMultipleFileUpload1Fileselected($event)"
                            ref="MultipleFileUpload1fileInputer"
                            id="MultipleFileUpload1InputElement"
                            >
                            <v-btn icon @click="GetShortLivedURL(editedMultipleFileUpload1Item)" v-if="editedMultipleFileUpload1Item.fileurl">View
                                </v-btn>
                        </v-card-text>
                      <v-card-actions class="white documenttypeshadowinvert">                  
                      <v-btn color="orange" dark class="overline" @click="closeMultipleFileUpload1dialog()">Cancel</v-btn>
                        <v-spacer></v-spacer>
                      <v-btn color="green" dark  class="overline" @click="saveMultipleFileUpload1Doc()" v-if="selectedMultipleFileUpload1File || editedMultipleFileUpload1Item.fileurl">Save</v-btn>
                    </v-card-actions>
                    </v-form>
                    </v-img>
                </v-card>
            </v-dialog>
         
            <v-tabs background-color="transparent"  color="white" style="padding-bottom:40px;">
              <v-tab class="foldertab"><span class="hidden-sm-and-down">Supporting Docs</span><span class="hidden-md-and-up">Multiple File U</span></v-tab>
           <v-tab-item class="paddedtab">
            <v-card flat min-height="200px"  :dark="AppisDarkMode">
                <v-layout row class="justify-center mx-1">
                  <v-card flat width="100%">
                      <v-data-table 
                      :dark="AppisDarkMode"          
                      :headers="MultipleFileUpload1Headers"           
                      :items="MultipleFileUpload1withIcon"            
                      class="elevation-1" 
                      :show-select="SelectingMultipleFileUpload1"   
                      v-model="MultipleFileUpload1Selected"   
                      item-key="Name" 
                      single-expand
                      show-expand  
                      v-if="!UpdatingMultipleFileUpload1Table"
                      >           
                        <template v-slot:top>           
                          <v-toolbar flat color="blue darken-4 white--text">            
                              <v-toolbar-title>Upload</v-toolbar-title>           
                              <v-divider            
                              class="mx-4"            
                              inset           
                              vertical            
                              ></v-divider>           
                                      
                              <v-spacer></v-spacer>                                           
                              <v-menu> 
                              <template v-slot:activator="{ on }">            
                                  <v-icon  v-on="on" dark  id="NewMultipleFileUpload1Btn">mdi-dots-vertical</v-icon>            
                              </template>
                              <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
                                <v-list-item class="listoutline"  @click="MultipleFileUpload1dialog = !MultipleFileUpload1dialog"  v-if="CanCreateMultipleFileUpload1">
                                    <v-list-item-title class="subtleoverline">
                                      New Supporting Docs
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item  class="listoutline" v-if="!SelectingMultipleFileUpload1" @click="SelectingMultipleFileUpload1 = !SelectingMultipleFileUpload1">
                                    <v-list-item-title class="subtleoverline">
                                      Select to Remove
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="green">mdi-check</v-icon>
                                    </v-list-item-action>
                                  </v-list-item> 
                                  <v-list-item v-if="SelectingMultipleFileUpload1" @click="SelectingMultipleFileUpload1 = !SelectingMultipleFileUpload1,MultipleFileUpload1Selected = []">
                                  <v-list-item-title class="subtleoverline">
                                      Cancel Select
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="red">mdi-cancel</v-icon>
                                    </v-list-item-action>
                                    </v-list-item>
                                  <v-list-item  class="listoutline" v-if="SelectingMultipleFileUpload1 && MultipleFileUpload1Selected.length>0"  @click="ConfirmMultipleFileUpload1TableTransfer()">
                                    <v-list-item-title class="subtleoverline">
                                      Remove from Table
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="green">mdi-check</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>  
                              </v-list>
                             </v-menu>           
                          </v-toolbar>            
                          </template> 
                         <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding-top:10px;padding-bottom:10px;">
                            <b>File Name and Type:</b> <v-spacer></v-spacer>{{item.Name}}<br>
                             <b>File Type: </b>  <v-spacer> </v-spacer>{{item.FileType}} <br>
                            <b>Primary Classification:</b> <v-spacer></v-spacer> {{item.MultipleFileUpload1PrimarySelectedOption}}<br>
                            <b>Original Date:</b> <span v-if="item.ModifiedDate && item.ModifiedDate.toDate()">{{item.ModifiedDate.toDate()}}</span><span v-if="item.ModifiedDate && !item.ModifiedDate.toDate()">{{item.ModifiedDate}}</span> prior to upload.<br>
                            <b>Upload Details: </b> <span v-if="item.Created_By">{{item.Created_By.Full_Name}}</span> on <span v-if="item.Created_On && item.Created_On.toDate">{{item.Created_On.toDate()}}</span><span v-if="item.Created_On && !item.Created_On.toDate">{{item.Created_On}}</span><br>
                            <b>Modification Details: </b><span v-if="item.Modified_By">{{item.Modified_By.Full_Name}}</span> on <span v-if="item.Modified_On && item.Modified_On.toDate">{{item.Modified_On.toDate()}}</span><span v-if="item.Modified_On && !item.Modified_On.toDate">{{item.Modified_On}}</span>
                          </td>
                        </template>
                          <template v-slot:[`item.MultipleFileUpload1PrimarySelectedOption`]="{ item }">
                            <v-chip color="warning" v-if="item.MultipleFileUpload1PrimarySelectedOption">{{ Documentoptions(item.MultipleFileUpload1PrimarySelectedOption,MultipleFileUpload1PrimaryOptions) }}</v-chip>
                          </template>
                          <template v-slot:[`item.FileType`]="{ item }">
                            <v-chip color="secondary" v-if="item.FileType"><v-icon :color="item.FileTypeIconColor">{{item.FileTypeIcon}}</v-icon>{{ item.FileType.split('/')[1].substr(0,20) }}</v-chip>
                          </template>
                          <template v-slot:[`item.action`]="{ item }">            
                            <v-icon           
                            small           
                            class="mr-2"  
                             v-if="CanEditMultipleFileUpload1"          
                            @click="editMultipleFileUpload1Item(item)"            
                            >           
                                mdi-pencil            
                            </v-icon>           
                            <v-icon           
                                small 
                                 v-if="CanDeleteMultipleFileUpload1"          
                                @click="deleteMultipleFileUpload1Item(item)"           
                            >           
                                mdi-delete            
                            </v-icon>           
                            <v-btn x-small icon  @click="GetShortLivedURL(item)" v-if="CanGetMultipleFileUpload1">
                              <v-icon color="grey lighten-1">mdi-eye</v-icon>
                            </v-btn>            
                          </template>         
                          </v-data-table>
                    </v-card>

                </v-layout>

             </v-card>
            </v-tab-item>
              <v-tab class="foldertab"><span class="hidden-sm-and-down">Photos</span><span class="hidden-md-and-up">Multiple File U</span></v-tab>
           <v-tab-item class="paddedtab">
            <v-card flat min-height="200px"  :dark="AppisDarkMode">
            <v-btn v-if="CanEditMultipleFileUpload2 && !EditingMultipleFileUpload2" @click="EditingMultipleFileUpload2 = !EditingMultipleFileUpload2"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-pencil</v-icon></v-btn><v-btn v-if="EditingMultipleFileUpload2" @click="EditingMultipleFileUpload2 = !EditingMultipleFileUpload2,CancelEditingMultipleFileUpload2Dialog()"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-cancel</v-icon></v-btn>
                        
                      <v-dialog v-model="UploadingMultipleFileUpload2" max-width="500px">
                   <v-card width="100%" min-height="300">
                          <v-img
                        width="500"
                        src="@/assets/RABaseBG-Soft.png"
                        ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                          <v-card-title class="mediumoverline white--text">
                            Uploading...
                          </v-card-title></div>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="multiplefileupload2progressbar" style="display:none">
                            <ul id="multiplefileupload2progressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                      </v-img>
                   </v-card>
                 </v-dialog>
              <v-pagination
                v-model="MultipleFileUpload2Page"
                :length="MultipleFileUpload2ImagesUploads.length/12"
                :total-visible="7"
              ></v-pagination>
              <v-dialog v-model="MultipleFileUpload2SlideShowDialog" max-width="800">
              <v-carousel v-model="MultipleFileUpload2SlideShow">
                  <v-carousel-item
                    v-for="(card,i) in MultipleFileUpload2ImagesUploadsPagination"
                    :key="card.itemObjKey"
                  >
                    <v-sheet
                      :color="color"
                      height="100%"
                      tile
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="display-3">
                          Image {{ i + 1 }}
                          <v-img :src="card.url" contain max-height="500"/>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
            </v-dialog>
            <v-layout class="justify-center">
            <input
                style="display: none"
                type="file"
                @change="AddMultipleFileUpload2($event)"
                ref="MultipleFileUpload2fileInputer"
                :multiple="true"
                accept="image/*"
                >
                <v-btn @click="$refs.MultipleFileUpload2fileInputer.click()" outlined  small class="elevation-6">
                  <v-icon>mdi-plus-thick</v-icon>Add Images</v-btn>

              <v-btn @click="MultipleFileUpload2SlideShowDialog = true" outlined  small class="elevation-6"><v-icon>mdi-file-presentation-box</v-icon>SlideShow</v-btn>
            </v-layout>
              <v-layout row class="align-center mx-3">
                 <v-col
                v-for="(card,i) in MultipleFileUpload2ImagesUploadsPagination"
                :key="card.itemObjKey"
                class="d-flex child-flex"
                :cols="GetCols(2)"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-auto"  @click="OpenorEditingMultipleFileUpload2(card,i)"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.ThumbURL"
                    :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover && !EditingMultipleFileUpload2"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                     <div
                      v-if="hover && EditingMultipleFileUpload2"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      Edit
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
                </v-layout>

             </v-card>
            </v-tab-item>
           </v-tabs>
          </v-card>       
    
          
        
          
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
  </v-layout>
  
  </div>
          <v-layout v-if="WikiMode" style="margin-top:-50px;">
  <v-card flat width="100%">
  <v-btn @click="WikiMode = !WikiMode" class="my-1">
            <v-icon >mdi-keyboard-return</v-icon>
            Return to Data
            <v-icon color="accent">mdi-database</v-icon>
          </v-btn>
          <v-btn  @click="PublishWikiPage()" class="my-1">
            Publish Wiki
            <v-icon color="green">mdi-publish</v-icon>
          </v-btn>
       
        <v-card class="transparent" width="100%" flat>
            <v-layout row wrap class="justify-center mx-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-top:40px;">
              <v-icon @click="EditingTitle = !EditingTitle">mdi-pencil</v-icon> 
                   <v-text-field v-model="Title" label="Title"></v-text-field>    
          <p class="display-2" v-if="!EditingTitle">{{Title}}</p>
              <v-icon @click="EditingDescription = !EditingDescription">mdi-pencil</v-icon>
                 <v-textarea v-if="EditingDescription" v-model="Description" label="Description"></v-textarea>           
                <p v-if="!EditingDescription">{{Description}}</p>
               
                       
          <p v-if="Client" >{{Client.Full_Name}}</p>
               
                       
          <p v-if="Contract" >{{Contract.Name}}</p>
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%" flat>
            <v-layout row wrap class="justify-center mx-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-top:40px;">
              
              <v-layout row class="fill-height align-center mx-2">
                   <v-list two-line subheader light align="left" width="100%">                     
                      <v-list-item v-for="item in Multiple_File_Upload_1" :key="item.itemObjKey" class="basicoutlined">
                        <v-layout row class="justify-start">
                          <v-list-item-avatar>
                            <v-icon>mdi-file-document</v-icon>
                            </v-list-item-avatar>
                           <v-list-item-content>
                                <v-list-item-title>{{item.MultipleFileUpload1PrimarySelectedOption.Name}}</v-list-item-title>
                            </v-list-item-content>                             
                              <v-list-item-action>                             
                                    <v-btn icon :href="item.fileurl" target="_New">
                                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                                  </v-btn>                              
                              </v-list-item-action>                           
                          </v-layout>
                        </v-list-item>
                    </v-list>
                </v-layout>
          </v-flex>          
          </v-layout>      
      </v-card>
       
        <v-card class="transparent" width="100%" flat>
            <v-layout row wrap class="justify-center mx-3">
                <v-flex lg8 md9 sm8 xs12 class="mx-2" style="padding-top:40px;">
              
            <v-row>            
              <v-col
                v-for="(card,i) in MultipleFileUpload2ImagesUploads.slice(0, 8)"
                :key="card.itemObjKey"
                class="d-flex child-flex"
                cols="BreakPointCol(3)"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-auto" :href="card.url" target="_blank"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.ThumbURL"
                    :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-flex>          
          </v-layout>      
      </v-card>
       <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  </v-card>
     </v-layout>
    
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import LookupComponent from '@/components/LookupComponent'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
components: {
  RMAdministration,
  SystemTicketsLogging,
  LookupComponent,
},
    

  data() {
    return {
      ViewFileDialog: false,
      ViewFileURL: '',
      ViewIMGURL: '',
      ChangeStatusDialog: false,
  
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      CustomCreateRoles: [
  "Office Administrator"
],
      CustomGetRoles: [
  "Office Administrator"
],
      CustomListRoles: [
  "Office Administrator"
],
      CustomUpdateRoles: [
  "Office Administrator"
],
      
      selectedEntity: {Name: 'Customer Claims',
      PrimaryField: 'Title',
      Tables: [						
          {						
          Name: 'Multiple File Upload 1',
          PrimaryOptions: 
            [
  {
    "ID": 1000001,
    "Name": "Quotes"
  }
]
          ,	
          Type: 'Document Register'					
          },						
          {						
          Name: 'Multiple File Upload 2',	
          Type: 'Images'					
          },
          ]
      },
      selectedRecord: {},						
      CanCreateMultipleFileUpload1: false,
      CanGetMultipleFileUpload1: false,
      CanListMultipleFileUpload1: false,
      CanEditMultipleFileUpload1: false,
      CanDeleteMultipleFileUpload1: false,						
      CanCreateMultipleFileUpload2: false,
      CanGetMultipleFileUpload2: false,
      CanListMultipleFileUpload2: false,
      CanEditMultipleFileUpload2: false,
      CanDeleteMultipleFileUpload2: false,
      MultipleFileUpload2Page: 1,
      MultipleFileUpload2SlideShow: 1,
      MultipleFileUpload2SlideShowDialog: false,
      MultipleFileUpload2ImagesUploads: [],
      ClaimRequest1000001: '',
      ActiveProcess: '',
      ActiveProcessDocid: '',
      EntityProcesses: [
  {
    "IsHeader": true,
    "ProcessStages": [
      {
        "Type": "Stage",
        "Name": "Review",
        "Steps": [],
        "Number": 1000001,
        "Automations": [],
        "IsHeader": true
      },
      {
        "Number": 1000002,
        "Steps": [],
        "Name": "Processing",
        "IsHeader": true,
        "Type": "Stage",
        "Automations": []
      },
      {
        "Name": "Complete",
        "Automations": [],
        "Type": "Stage",
        "Number": 1000003,
        "Steps": [],
        "IsHeader": true
      }
    ],
    "ProcessStageInteger": 1000003,
    "DisplayName": "Claim Request",
    "Name": "Claim Request",
    "id": "1000001",
    "Number": 1000001,
    "Type": "Process",
    "Automations": []
  }
],
      SelectProcessDialog: false,
      
      BannerTabs: [
        ],
      EditingSection1: false,
      EditingMultipleFileUpload1: false,
      EditingMultipleFileUpload2: false,
      TicketLog: false,
        WikiData: '',
      WikiMode: false,
      EditingTitle: false,
      EditingDescription: false,
      step: 1,
      rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      StageID: 1,
      Title: '',
      Description: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {
  "Level": {
    "name": "Status Reason",
    "children": [],
    "FieldName": "Status_Reason",
    "id": 2
  },
  "ID": 1000003,
  "Name": "Open",
  "LevelFieldName": "Status_Reason"
},
      Created_On: '',
      Created_Onmenu : false,
      Date_of_Symptom: '',
      Date_of_Symptommenu : false,
      Client:  {id: '', Full_Name: ''},
      UnpermittedClient:  {id: '', Full_Name: ''},
      HasClient: false,
      ClientRelatedParentObj: {},
      Contract:  {id: '', Name: ''},
      UnpermittedContract:  {id: '', Name: ''},
      HasContract: false,
      ContractRelatedParentObj: {},
      Created_By:  {id: '', Full_Name: ''},
      UnpermittedCreated_By:  {id: '', Full_Name: ''},
      HasCreated_By: false,
      Created_ByRelatedParentObj: {},
      Owner:  {id: '', Full_Name: ''},
      UnpermittedOwner:  {id: '', Full_Name: ''},
      HasOwner: false,
      OwnerRelatedParentObj: {},
      Final_Quote: '',
      Claim_Value: '',
      ClientFull_Name: null,
      ContractName: null,
      Created_ByFull_Name: null,
      OwnerFull_Name: null,
      
        Final_Quotefilesnackbar: false,
        selectedFinal_QuoteFile: '',
        Final_QuotelocalURL: '',
      Created_Onmenu: false,
      Date_of_Symptommenu: false,
      
      StatusOptions: [{
  "ID": 1000001,
  "HasDeepHierarchy": true,
  "Name": "Active",
  "LevelFieldName": "Status",
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "ID": 1000003,
      "Name": "Open",
      "Level": {
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "id": 2,
        "children": []
      }
    }
  ],
  "Level": {
    "children": [
      {
        "name": "Status Reason",
        "id": 2,
        "children": [],
        "FieldName": "Status_Reason"
      }
    ],
    "id": 1,
    "name": "Status",
    "FieldName": "Status"
  }
},{
  "Options": [
    {
      "ID": 1000004,
      "Name": "Closed",
      "Level": {
        "FieldName": "Status_Reason",
        "children": [],
        "id": 2,
        "name": "Status Reason"
      },
      "LevelFieldName": "Status_Reason"
    }
  ],
  "HasDeepHierarchy": true,
  "ID": 1000002,
  "Level": {
    "FieldName": "Status",
    "id": 1,
    "name": "Status",
    "children": [
      {
        "id": 2,
        "name": "Status Reason",
        "children": [],
        "FieldName": "Status_Reason"
      }
    ]
  },
  "LevelFieldName": "Status",
  "Name": "InActive"
}],
      Statusfilter: '',
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      ClientArray: [],
      Clientfilter: {Full_Name: ''},
      
      ContractArray: [],
      Contractfilter: {Name: ''},
      
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      OwnerArray: [],
      Ownerfilter: {Full_Name: ''},
      
      EditingCustomerClaim: false,
      EditingMultipleFileUpload2Dialog: false,
      editedMultipleFileUpload2Item: '',
      editedMultipleFileUpload2ItemIndex: -1,
      MultipleFileUpload2EditOption: '',
      
      UploadingMultipleFileUpload2: false,
      MultipleFileUpload2totallist: 0,
      MultipleFileUpload2listuploadcount: 0,
      MultipleFileUpload2uploadTotal: 0,
      MultipleFileUpload2runningpercentage: 0,
      MultipleFileUpload2runningProgress: 0,
      MultipleFileUpload2uploadPercentage: 0,
      MultipleFileUpload1Headers: [
        { text: 'Name', value: 'Name', sortable: true},						
            { text: 'Class', value: 'MultipleFileUpload1PrimarySelectedOption', sortable: true},						
            { text: 'File Type', value: 'FileType', sortable: true },						
            { text: 'Action', value: 'action', sortable: true },
      ],
      SelectingMultipleFileUpload1: false,
      MultipleFileUpload1Selected: [],
      MultipleFileUpload1: [],
      Multiple_File_Upload_1: [],
      UpdatingMultipleFileUpload1Table: false,
      DefaultMultipleFileUpload1path: 'CustomerClaims/'+this.$route.params.id+'/MultipleFileUpload1/',
      MultipleFileUpload1dialog: false,

      MultipleFileUpload1PrimaryOptions: [
  {
    "ID": 1000001,
    "Name": "Quotes"
  }
],
      selectedMultipleFileUpload1File: '',
      UploadingMultipleFileUpload1File: false,
      UploadingMultipleFileUpload1FileProgress: 0,
      editedMultipleFileUpload1Item: {
      MultipleFileUpload1PrimarySelectedOption: '',
      },
      DefaultMultipleFileUpload1Item: {
      MultipleFileUpload1PrimarySelectedOption: '',
      },
      editedMultipleFileUpload1Index: -1,
      dialog: false,
      initialize: '',
      search: '',
      CustomerClaimData: {},
      headers: [
      
        
        { text: 'Title', value: 'Title'},
        
        
        { text: 'Description', value: 'Description'},
        
        
        { text: 'Status', value: 'Status.Name'},
        
        
        { text: 'Date of Symptom', value: 'Date_of_Symptom'},
        
        
        
        { text: 'Client', value: 'Client.Full_Name'},
        
        
        { text: 'Contract', value: 'Contract.Name'},
        
        
        { text: 'Owner', value: 'Owner.Full_Name'},
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedCustomerClaimsnackbar: false,
      CustomerClaimEditdialog: false,
      newCustomerClaimdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
this.GetUsers()
window.addEventListener('scroll', this.handleScroll)

  },
  
  computed: {
    
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
    ComputedStageID(){
    return this.ProcessStages[this.StageID-1].Number
  },
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
        return true
      }
    }
    else{
      return this.userIsAdmin
    }
  
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    CurrentCustomersStore(){
      return this.$store.state.CurrentCustomersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
    MultipleFileUpload1withIcon(){
      return this.MultipleFileUpload1.map(doc => {
        console.log(doc)
        if(doc.FileType.includes('pdf')){
          doc.FileTypeIcon = 'mdi-file-pdf'
          doc.FileTypeIconColor = 'red'
          //doc.FileType = doc.FileType.split('/')[1]
        }
        else{
          doc.FileTypeIcon = 'mdi-file-question-outline'
          doc.FileTypeIconColor = 'blue'
          //doc.FileType = doc.FileType.split('/')[1]
        }
        return doc
      })
    },
    MultipleFileUpload2ImagesUploadsPagination(){
      let displaylimit = 12
      let displayrange = displaylimit*this.MultipleFileUpload2Page-displaylimit
      this.MultipleFileUpload2SlideShow = displayrange
      return this.MultipleFileUpload2ImagesUploads.slice(displayrange, displayrange+displaylimit)
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },
  Date_of_SymptomObject(){
    return this.DateFormatter(this.Date_of_Symptom)
  },  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    },
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
                if(vm.UserRecord !== 'undefined'){
                vm.UserRoles = vm.UserRecord.rolesarrayQuery
                let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.GetCustomerClaim(AdminTest)
                        
              let UsersClientQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersClientArrayandLookupValue(UsersClientQuery)
                }
              }
              

                        
                  let CurrentCustomersContractQuery = db.collection('currentcustomers')
                  let CurrentCustomersListCustomSecurityRoles = [
  "Office Administrator"
]
                  if(CurrentCustomersListCustomSecurityRoles.length>0 || vm.IsAdmin){
                    vm.CustomRolesCheck(CurrentCustomersListCustomSecurityRoles).then(PermissionLevel => {
                       if(PermissionLevel === 1 || vm.IsAdmin){
                         vm.GetCurrentCustomersContractArrayandLookupValue(CurrentCustomersContractQuery)
                       }
                       else{
                         //vm.RoutetoErrorPage()
                       }
                  })
                  }
              

                        
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                        
              let UsersOwnerQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
                }
              }
              

                }
                else{
                  let GetQuery = db.collection('customerclaims')
                  vm.CustomRolesCheck(vm.CustomGetRoles).then(PermissionLevel => {
                    if(PermissionLevel === 1 || vm.IsAdmin){
                      vm.GetCustomerClaim(vm.UserRecord)
                        
              let UsersClientQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersClientArrayandLookupValue(UsersClientQuery)
                }
              }
              

                        
                  let CurrentCustomersContractQuery = db.collection('currentcustomers')
                  let CurrentCustomersListCustomSecurityRoles = [
  "Office Administrator"
]
                  if(CurrentCustomersListCustomSecurityRoles.length>0 || vm.IsAdmin){
                    vm.CustomRolesCheck(CurrentCustomersListCustomSecurityRoles).then(PermissionLevel => {
                       if(PermissionLevel === 1 || vm.IsAdmin){
                         vm.GetCurrentCustomersContractArrayandLookupValue(CurrentCustomersContractQuery)
                       }
                       else{
                         //vm.RoutetoErrorPage()
                       }
                  })
                  }
              

                        
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                        
              let UsersOwnerQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
                }
              }
              

                    }
                    else{
                      vm.RoutetoErrorPage()
                    }
                  })
                }
                
                }
              
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetCustomerClaim() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
          db.collection('customerclaimswiki').doc(this.$route.params.id).onSnapshot(snapshot => {
            let wikidata = snapshot.data()
            this.WikiData = wikidata
                //if(wikidata && wikidata.MultipleFileUpload1){
                //this.MultipleFileUpload1 = wikidata.MultipleFileUpload1
              //}
          })
             vm.CustomRolesCheck(vm.CustomUpdateRoles).then(PermissionLevel => {
              if(PermissionLevel === 1){
                vm.CanEdit = true
              }
             })
             vm.CustomRolesCheck(vm.CustomUpdateRoles).then(PermissionLevel => {
              if(PermissionLevel === 1){
                vm.CanCreate = true
              }
             })
            db.collection('customerclaims').doc(this.$route.params.id).onSnapshot(snapshot => {
            var customerclaim = snapshot.data()
            customerclaim.id = this.$route.params.id
            
            this.selectedRecord = {id: this.$route.params.id, Title: customerclaim.Title }
            this.RelatedObj = {id: this.$route.params.id, Title: customerclaim.Title,identifier: 'Title',collection: 'customerclaims',Link:'CustomerClaim' }
            this.CustomerClaimData = customerclaim
            this.Status_Reason = customerclaim.Status_Reason
            this.GetProcessData()
            this.CustomerClaimData.id = this.$route.params.id
            this.$emit('PushRecordName',this.CustomerClaimData.Title)
            if(typeof customerclaim.Owner !== 'undefined'){
              let tempOwner = customerclaim.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof customerclaim.Owner !== 'undefined'){
              this.Business_Unit = customerclaim.Business_Unit
            }        

            this.Title = customerclaim.Title
            this.editedItem.Title = customerclaim.Title
            //I think API Trigger should be here, outside the first if loop on fields
            this.Description = customerclaim.Description
            this.editedItem.Description = customerclaim.Description
            //I think API Trigger should be here, outside the first if loop on fields
            this.Status = customerclaim.Status
            this.editedItem.Status = customerclaim.Status
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
              if(customerclaim.Created_On && typeof customerclaim.Created_On !== 'string'){
            this.Created_On = format(customerclaim.Created_On.toDate(),'yyyy-MM-dd')
            this.editedItem.Created_On = customerclaim.Created_On
              }
            //I think API Trigger should be here, outside the first if loop on fields
              if(customerclaim.Date_of_Symptom && typeof customerclaim.Date_of_Symptom !== 'string'){
            this.Date_of_Symptom = format(customerclaim.Date_of_Symptom.toDate(),'yyyy-MM-dd')
            this.editedItem.Date_of_Symptom = customerclaim.Date_of_Symptom
              }
            if(customerclaim.Client){
            this.ClientRelatedParentObj = {id: customerclaim.Client.id, Title: customerclaim.Title,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Client = this.UsersStore.find(client => client.id === customerclaim.Clientid)
                
            if(typeof customerclaim.Clientid !== 'undefined' && customerclaim.Clientid !== ''){
              this.HasClient = true
              if(typeof this.Client === 'undefined'){
                this.UnpermittedClient = {id: customerclaim.Clientid,Full_Name: customerclaim.Client.Full_Name}
              }
            }
            this.editedItem.Client = customerclaim.Client
            if(customerclaim.Contract){
            this.ContractRelatedParentObj = {id: customerclaim.Contract.id, Title: customerclaim.Title,identifier: 'Name',collection: 'currentcustomers',Link: 'Current Customer' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                  this.Contract = this.ContractArray.find(contract => contract.id === customerclaim.Contractid)
                
            if(typeof customerclaim.Contractid !== 'undefined' && customerclaim.Contractid !== ''){
              this.HasContract = true
              if(typeof this.Contract === 'undefined'){
                this.UnpermittedContract = {id: customerclaim.Contractid,Name: customerclaim.Contract.Name}
              }
            }
            this.editedItem.Contract = customerclaim.Contract
            if(customerclaim.Created_By){
            this.Created_ByRelatedParentObj = {id: customerclaim.Created_By.id, Title: customerclaim.Title,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Created_By = this.UsersStore.find(created_by => created_by.id === customerclaim.Created_Byid)
                
            if(typeof customerclaim.Created_Byid !== 'undefined' && customerclaim.Created_Byid !== ''){
              this.HasCreated_By = true
              if(typeof this.Created_By === 'undefined'){
                this.UnpermittedCreated_By = {id: customerclaim.Created_Byid,Full_Name: customerclaim.Created_By.Full_Name}
              }
            }
            this.editedItem.Created_By = customerclaim.Created_By
            if(customerclaim.Owner){
            this.OwnerRelatedParentObj = {id: customerclaim.Owner.id, Title: customerclaim.Title,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
                this.Owner = this.UsersStore.find(owner => owner.id === customerclaim.Ownerid)
                
            if(typeof customerclaim.Ownerid !== 'undefined' && customerclaim.Ownerid !== ''){
              this.HasOwner = true
              if(typeof this.Owner === 'undefined'){
                this.UnpermittedOwner = {id: customerclaim.Ownerid,Full_Name: customerclaim.Owner.Full_Name}
              }
            }
            this.editedItem.Owner = customerclaim.Owner
            this.Final_Quote = customerclaim.Final_Quote
            this.editedItem.Final_Quote = customerclaim.Final_Quote
            //I think API Trigger should be here, outside the first if loop on fields
            this.Claim_Value = customerclaim.Claim_Value
            this.editedItem.Claim_Value = customerclaim.Claim_Value
            //I think API Trigger should be here, outside the first if loop on fields
    
                if(vm.IsAdmin === true){
                vm.CanCreateMultipleFileUpload2 = true
                vm.CanGetMultipleFileUpload2 = true
                vm.CanListMultipleFileUpload2 = true
                vm.CanEditMultipleFileUpload2 = true                
                vm.CanDeleteMultipleFileUpload2 = true
              }
                if(vm.CanEdit === true){
                  vm.CanDeleteMultipleFileUpload2 = true
                }
              
                if(vm.CanCreate === true){
                  vm.CanCreateMultipleFileUpload2 = true
                }
              
                if(vm.CanEdit === true){
                  vm.CanEditMultipleFileUpload2 = true
                }
              
              vm.CanGetMultipleFileUpload2 = true
              db.collection('customerclaims').doc(this.$route.params.id).collection('multiplefileupload2imagesuploads').onSnapshot(res => {
                      const changes = res.docChanges();
                      changes.forEach(change => {
                        let existing = this.MultipleFileUpload2ImagesUploads.find(obj => obj.id === change.doc.id)
                        if(!existing){
                          if (change.type === 'added') {
                            this.MultipleFileUpload2ImagesUploads.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        }
                      })
                    })
                if(vm.IsAdmin === true){
                vm.CanCreateMultipleFileUpload1 = true
                vm.CanGetMultipleFileUpload1 = true
                vm.CanListMultipleFileUpload1 = true
                vm.CanEditMultipleFileUpload1 = true                
                vm.CanDeleteMultipleFileUpload1 = true
              }
                if(vm.CanEdit === true){
                  vm.CanDeleteMultipleFileUpload1 = true
                }
              
              
                if(vm.CanCreate === true){
                  vm.CanCreateMultipleFileUpload1 = true
                }
              
                if(vm.CanEdit === true){
                  vm.CanEditMultipleFileUpload1 = true
                }
              
              vm.CanGetMultipleFileUpload1 = true
              db.collection('customerclaims').doc(this.$route.params.id).collection('multiplefileupload1').onSnapshot(res => {
                      const changes = res.docChanges();
                      changes.forEach(change => {
                        if (change.type === 'added') {
                          let match = this.MultipleFileUpload1.find(obj => obj.id === change.doc.id)
                          if(!match){
                          this.MultipleFileUpload1.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                          this.Multiple_File_Upload_1.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                          }
                        }
                        if(change.type === 'modified'){
                          let item = this.MultipleFileUpload1.find(obj => obj.id === change.doc.id)
                          let index = this.MultipleFileUpload1.indexOf(item)
                          this.MultipleFileUpload1.splice(index,1)
                          this.MultipleFileUpload1.push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                        }
                      })
                    })
            })
          
        },
      
      


    
      
      
            CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
      GetCols(int){
      if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg){
        return int
      }
      else if(this.$vuetify.breakpoint.md){
        return int*1.5
      }
      else if(this.$vuetify.breakpoint.sm){
        return int*2.5
      }
      else{
        return int*3
      }
    },
      ResetStatus(){
      this.Status = Object.assign({},this.CustomerClaimData.Status)
      this.Status_Reason = Object.assign({},this.CustomerClaimData.Status_Reason)
      this.ChangeStatusDialog = false
    },
    UpdateStatus(){
      db.collection('customerclaims').doc(this.$route.params.id).update({
        Status: this.Status,
        Status_Reason: this.Status_Reason
      })
      this.ChangeStatusDialog = false
    },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
          }
          else{
            this.ViewFileURL = result.data[0]
          }
          console.log(window.innerHeight)
          this.ViewFileDialog = true
          //window.location.assign(result.data[0])
        })
    },
      ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode')
      },
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateCustomerClaim(){
        this.UndefinedMandatoryFields = []
      const EditedCustomerClaim = {
    Title: this.Title,
    
        }
      for(var prop in EditedCustomerClaim) {
          if (EditedCustomerClaim.hasOwnProperty(prop)) {
            if(typeof EditedCustomerClaim[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      db.collection('customerclaims').doc(this.$route.params.id).update({
        Title: EditedCustomerClaim.Title,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedCustomerClaimsnackbar = true
              this.CancelCustomerClaimEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Title !== 'undefined'){                  
                  let TitleQuery = []
                  let TitleStringArray = this.Title.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(TitleStringArray.length,TitleStringArray)
                    for (p = 0; p < TitleStringArray.length; p++) {
                        buildup = buildup+ TitleStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    TitleQuery = lowertext.split(',')  
                    TitleQuery.length = TitleQuery.length-1
                   db.collection('customerclaims').doc(this.$route.params.id).update({
                     TitleQuery: TitleQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                if(typeof this.Description !== 'undefined'){
                   db.collection('customerclaims').doc(this.$route.params.id).update({
                     Description: this.Description,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Date_of_Symptom !== 'undefined'){
                   db.collection('customerclaims').doc(this.$route.params.id).update({
                     Date_of_Symptom: this.Date_of_SymptomObject,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
              if(typeof this.Client !== 'undefined'){
                db.collection('customerclaims').doc(this.$route.params.id).update({
                  Client: {Full_Name: this.Client.Full_Name,id: this.Client.id},
                  Clientid: this.Client.id,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                      })
              }
              if(typeof this.Contract !== 'undefined'){
                db.collection('customerclaims').doc(this.$route.params.id).update({
                  Contract: {Name: this.Contract.Name,id: this.Contract.id},
                  Contractid: this.Contract.id,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                      })
              }
                if(typeof this.Final_Quote !== 'undefined'){
                   db.collection('customerclaims').doc(this.$route.params.id).update({
                     Final_Quote: this.Final_Quote,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Claim_Value !== 'undefined'){
                   db.collection('customerclaims').doc(this.$route.params.id).update({
                     Claim_Value: Number(this.Claim_Value),
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.CustomerClaimsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('customerclaims').doc(item.id).delete() && this.CustomerClaimsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.CustomerClaimsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
              this.EditingMultipleFileUpload1 = false
              this.EditingMultipleFileUpload2 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelCustomerClaimEditing(){
      this.EditingCustomerClaim = false
    },
    EditCustomerClaim() {
      this.EditingCustomerClaim = !this.EditingCustomerClaim
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetUsersClient() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.ClientArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersClientArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.ClientArray = vm.UsersStore
      vm.GetClient();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.ClientArray = vm.UsersStore
                        vm.GetClient();
                        })
    }
  },

  GetClient(){
    this.Client = this.ClientArray.find(client => client.id === this.CustomerClaimData.Clientid)
  },

  //GetUsersClient(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.ClientArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersClient finished')
 //   })
 // })
//    })
//  },

  GetCurrentCustomersContract() {
  db.collection('currentcustomers').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.ContractArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetCurrentCustomersContractArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.CurrentCustomersStore.length > 0){
      //alert('got from store')
      vm.ContractArray = vm.CurrentCustomersStore
      vm.GetContract();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setCurrentCustomersArray',
                    getter: 'getCurrentCustomersArray',
                    arrayname: 'CurrentCustomersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.CurrentCustomersStore)
                        vm.ContractArray = vm.CurrentCustomersStore
                        vm.GetContract();
                        })
    }
  },

  GetContract(){
    this.Contract = this.ContractArray.find(contract => contract.id === this.CustomerClaimData.Contractid)
  },

  //GetCurrentCustomersContract(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.ContractArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetCurrentCustomersContract finished')
 //   })
 // })
//    })
//  },

  GetUsersCreated_By() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Created_ByArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersCreated_ByArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.Created_ByArray = vm.UsersStore
      vm.GetCreated_By();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.Created_ByArray = vm.UsersStore
                        vm.GetCreated_By();
                        })
    }
  },

  GetCreated_By(){
    this.Created_By = this.Created_ByArray.find(created_by => created_by.id === this.CustomerClaimData.Created_Byid)
  },

  //GetUsersCreated_By(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Created_ByArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersCreated_By finished')
 //   })
 // })
//    })
//  },

  GetUsersOwner() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.OwnerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersOwnerArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.OwnerArray = vm.UsersStore
      vm.GetOwner();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.OwnerArray = vm.UsersStore
                        vm.GetOwner();
                        })
    }
  },

  GetOwner(){
    this.Owner = this.OwnerArray.find(owner => owner.id === this.CustomerClaimData.Ownerid)
  },

  //GetUsersOwner(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.OwnerArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersOwner finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingClient(){
this.CancelAddParentDialog()
this.PrepareDataParentClient()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Client'
      this.LookupArray = this.ClientArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentClient(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Client'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Client', Prop: 'Full_Name', Propid: 'Clientid', Value: '',Array: 'ClientArray',LookupFieldName: 'Client'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Client', Prop: 'Client', Value: '',LookupFieldName: 'Client'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "Options": [
      {
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        },
        "LevelFieldName": "Status_Reason",
        "ID": 1000003,
        "Name": "Open"
      }
    ],
    "HasDeepHierarchy": true,
    "ID": 1000001,
    "Name": "Active",
    "LevelFieldName": "Status",
    "Level": {
      "name": "Status",
      "id": 1,
      "children": [
        {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      ],
      "FieldName": "Status"
    }
  },
  {
    "ID": 1000002,
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004,
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      }
    ],
    "Level": {
      "FieldName": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "name": "Status",
      "id": 1
    },
    "LevelFieldName": "Status",
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewClientDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentClient()
},


PrepareLinkExistingContract(){
this.CancelAddParentDialog()
this.PrepareDataParentContract()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Contract'
      this.LookupArray = this.ContractArray
      this.LookupArrayHeaders = [
        { text: 'Brand', value: 'Brand',class: "overline"},
        { text: 'Client Email', value: 'Client_Email',class: "overline"},
        { text: 'Client Mobile', value: 'Client_Mobile',class: "overline"},
        { text: 'Date of Purchase', value: 'Date_of_Purchase',class: "overline"},
        { text: 'Dealer', value: 'Dealer.Dealer_Name',class: "overline"},
        { text: 'Engine Number', value: 'Engine_Number',class: "overline"},
        { text: 'ID Number', value: 'ID_Number',class: "overline"},
        { text: 'Mileage', value: 'Mileage',class: "overline"},
        { text: 'Model', value: 'Model',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Owner', value: 'Owner.Full_Name',class: "overline"},
        { text: 'Price', value: 'Price',class: "overline"},
        { text: 'Registration Number', value: 'Registration_Number',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'VIN Number', value: 'VIN_Number',class: "overline"},
        { text: 'Vehicle Color', value: 'Vehicle_Color',class: "overline"},
      ]
},

PrepareDataParentContract(){
  if(!this.DealerArray || this.DealerArray.length === 0){
  let Dealerquery = db.collection('dealerships')
  Dealerquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.DealerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Contract'
  this.ParentCollection = 'currentcustomers'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Contract', Prop: 'Name', Propid: 'Contractid', Value: '',Array: 'ContractArray',LookupFieldName: 'Contract'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Contract', Prop: 'Contract', Value: '',LookupFieldName: 'Contract'}
        let BrandObj = {Type: 'Single Line Text', Name: 'Brand',Prop: 'Brand'}
  this.NewParentAdditional.push(BrandObj )
        let Client_EmailObj = {Type: 'Common Field', Name: 'Client Email',Prop: 'Client_Email'}
  this.NewParentAdditional.push(Client_EmailObj )
        let Client_MobileObj = {Type: 'Common Field', Name: 'Client Mobile',Prop: 'Client_Mobile'}
  this.NewParentAdditional.push(Client_MobileObj )
        let Date_of_PurchaseObj = {Type: 'Date', Name: 'Date of Purchase',Prop: 'Date_of_Purchase'}
  this.NewParentAdditional.push(Date_of_PurchaseObj )
        let DealerObj = {Type: 'Lookup', Name: 'Dealer',Prop: 'Dealer_Name',Array: this.DealerArray, Propid: 'Dealer_Nameid', LookupFieldName: 'Dealer_Name', Value: ''}
  this.NewParentAdditional.push(DealerObj )
        let Engine_NumberObj = {Type: 'Single Line Text', Name: 'Engine Number',Prop: 'Engine_Number'}
  this.NewParentAdditional.push(Engine_NumberObj )
        let ID_NumberObj = {Type: 'Single Line Text', Name: 'ID Number',Prop: 'ID_Number'}
  this.NewParentAdditional.push(ID_NumberObj )
        let MileageObj = {Type: 'Number Field', Name: 'Mileage',Prop: 'Mileage'}
  this.NewParentAdditional.push(MileageObj )
        let ModelObj = {Type: 'Single Line Text', Name: 'Model',Prop: 'Model'}
  this.NewParentAdditional.push(ModelObj )
        let OwnerObj = {Type: 'Lookup', Name: 'Owner',Prop: 'Full_Name',Array: this.OwnerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(OwnerObj )
        let PriceObj = {Type: 'Number Field', Name: 'Price',Prop: 'Price'}
  this.NewParentAdditional.push(PriceObj )
        let Registration_NumberObj = {Type: 'Single Line Text', Name: 'Registration Number',Prop: 'Registration_Number'}
  this.NewParentAdditional.push(Registration_NumberObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "Options": [
      {
        "ID": 1000003,
        "Name": "Open",
        "LevelFieldName": "Status_Reason",
        "Level": {
          "id": 2,
          "children": [],
          "name": "Status Reason",
          "FieldName": "Status_Reason"
        }
      }
    ],
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true,
    "Name": "Active",
    "ID": 1000001,
    "Level": {
      "FieldName": "Status",
      "id": 1,
      "name": "Status",
      "children": [
        {
          "children": [],
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason"
        }
      ]
    }
  },
  {
    "ID": 1000002,
    "LevelFieldName": "Status",
    "Level": {
      "id": 1,
      "FieldName": "Status",
      "name": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ]
    },
    "HasDeepHierarchy": true,
    "Name": "InActive",
    "Options": [
      {
        "ID": 1000004,
        "Name": "Closed",
        "Level": {
          "FieldName": "Status_Reason",
          "id": 2,
          "children": [],
          "name": "Status Reason"
        },
        "LevelFieldName": "Status_Reason"
      }
    ]
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let VIN_NumberObj = {Type: 'Single Line Text', Name: 'VIN Number',Prop: 'VIN_Number'}
  this.NewParentAdditional.push(VIN_NumberObj )
        let Vehicle_ColorObj = {Type: 'Single Line Text', Name: 'Vehicle Color',Prop: 'Vehicle_Color'}
  this.NewParentAdditional.push(Vehicle_ColorObj )     
  
},

ActivateAddNewContractDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentContract()
},


PrepareLinkExistingCreated_By(){
this.CancelAddParentDialog()
this.PrepareDataParentCreated_By()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Created By'
      this.LookupArray = this.Created_ByArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentCreated_By(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Created_By'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Created By', Prop: 'Full_Name', Propid: 'Created_Byid', Value: '',Array: 'Created_ByArray',LookupFieldName: 'Created_By'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Created By', Prop: 'Created_By', Value: '',LookupFieldName: 'Created_By'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "Options": [
      {
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        },
        "LevelFieldName": "Status_Reason",
        "ID": 1000003,
        "Name": "Open"
      }
    ],
    "HasDeepHierarchy": true,
    "ID": 1000001,
    "Name": "Active",
    "LevelFieldName": "Status",
    "Level": {
      "name": "Status",
      "id": 1,
      "children": [
        {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      ],
      "FieldName": "Status"
    }
  },
  {
    "ID": 1000002,
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004,
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      }
    ],
    "Level": {
      "FieldName": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "name": "Status",
      "id": 1
    },
    "LevelFieldName": "Status",
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewCreated_ByDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCreated_By()
},


PrepareLinkExistingOwner(){
this.CancelAddParentDialog()
this.PrepareDataParentOwner()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Owner'
      this.LookupArray = this.OwnerArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Status', value: 'Status.Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentOwner(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Owner'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Owner', Prop: 'Full_Name', Propid: 'Ownerid', Value: '',Array: 'OwnerArray',LookupFieldName: 'Owner'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Owner', Prop: 'Owner', Value: '',LookupFieldName: 'Owner'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let StatusObj = {Type: 'Option Set', Name: 'Status',Prop: 'Status',Options: [
  {
    "Options": [
      {
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        },
        "LevelFieldName": "Status_Reason",
        "ID": 1000003,
        "Name": "Open"
      }
    ],
    "HasDeepHierarchy": true,
    "ID": 1000001,
    "Name": "Active",
    "LevelFieldName": "Status",
    "Level": {
      "name": "Status",
      "id": 1,
      "children": [
        {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      ],
      "FieldName": "Status"
    }
  },
  {
    "ID": 1000002,
    "HasDeepHierarchy": true,
    "Options": [
      {
        "Name": "Closed",
        "LevelFieldName": "Status_Reason",
        "ID": 1000004,
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        }
      }
    ],
    "Level": {
      "FieldName": "Status",
      "children": [
        {
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason",
          "children": []
        }
      ],
      "name": "Status",
      "id": 1
    },
    "LevelFieldName": "Status",
    "Name": "InActive"
  }
], Value: ''}
  this.NewParentAdditional.push(StatusObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewOwnerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentOwner()
},



      
      BreakPointCol(int){
        if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
        return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return int
        }
        else{
          return int
        }
      },
    PublishWikiPage(){
      let WikiObj = {
        Title: this.Title,
        Description: this.Description,
        Client: this.Client.Full_Name,
        Clientid: this.Client.id,
        Contract: this.Contract.Name,
        Contractid: this.Contract.id,
        Multiple_File_Upload_1: this.Multiple_File_Upload_1,
        MultipleFileUpload2ImagesUploads: this.MultipleFileUpload2ImagesUploads,
      }
      if(this.Business_Unit){
        WikiObj.Location = this.Business_Unit
        WikiObj.Locationid = this.Business_Unit.id
      }
      this.UndefinedMandatoryFields = []
      let VerifyObj = Object.assign({},WikiObj)
      for(var prop in VerifyObj) {
          if (VerifyObj.hasOwnProperty(prop)) {
            if(typeof VerifyObj[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
            else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
          }
        }
      if(this.UndefinedMandatoryFields.length === 0){
        db.collection('customerclaimswiki').doc(this.$route.params.id).set(WikiObj)
          db.collection('customerclaims').doc(this.$route.params.id).update({
          Multiple_File_Upload_1: this.Multiple_File_Upload_1,
        })        
        let snackbarcontent = {
            snackbartimeout: 4000,
            snackbartext: 'Published Wiki for  - '+this.Title,
            snackbartop: true,
            snackbarpath: '/CustomerClaim-Wiki/'+this.Title
          }
          this.$emit('ActivateSnackbar',true,snackbarcontent)
      }
      else{
        this.WikiMode = false
        this.EditingSection1 = true
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        setTimeout(() => {
         this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        }) 
        }, 500);
      }      
    },
      saveTitle(){
        db.collection('customerclaims').doc(this.$route.params.id).update({
          Title: this.Title,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
      },
      saveDescription(){
        db.collection('customerclaims').doc(this.$route.params.id).update({
          Description: this.Description,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
      },
    
      
      
    
      
    
      
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          
      
     
      
      

      
      
      
      ResetFilters() {
        this.Statusfilter = ''
      },
      
      
      
      OpenorEditingMultipleFileUpload2(item,index){
      if(this.EditingMultipleFileUpload2){
        this.EditingMultipleFileUpload2Dialog = true
        this.editedMultipleFileUpload2Item = item
        this.editedMultipleFileUpload2ItemIndex = index
      }
      else{
        this.GetShortLivedURL(item)
      }
      },
      CancelEditingMultipleFileUpload2Dialog(){
      this.EditingMultipleFileUpload2Dialog = false
      this.editedMultipleFileUpload2Item = ''
      this.editedMultipleFileUpload2ItemIndex = -1
      this.MultipleFileUpload2EditOption = ''
    },
    CancelEditingMultipleFileUpload2Dialog(){
      this.EditingMultipleFileUpload2Dialog = false
      this.editedMultipleFileUpload2Item = ''
      this.editedMultipleFileUpload2ItemIndex = -1
      this.MultipleFileUpload2EditOption = ''
    },
    ProcessEditingMultipleFileUpload2Dialog(){
      console.log(this.MultipleFileUpload2EditOption)
      if(this.MultipleFileUpload2EditOption === 'Move out of Record'){
        confirm('This will move the Image out of this table, and move it to another. Are you sure you want to do this?') &&
        this.TransferMultipleFileUpload2toRMAdminTable()
      }
      else if(this.MultipleFileUpload2EditOption === 'Destroy'){
        //alert('We Will Delete')
        confirm('This will destroy all traces of the Image,are you Sure you want to do this?') && this.DeleteMultipleFileUpload2Item()
        
      }
    },
    DeleteMultipleFileUpload2Item(){
      let vm = this
      let thumstorageRef = firebase.storage().ref(vm.editedMultipleFileUpload2Item.StorageRef+'_thumb');
     
        let storageRef = firebase.storage().ref(vm.editedMultipleFileUpload2Item.StorageRef);	
              storageRef.delete().then(function() {									
                // File deleted successfully
                		console.log('actual file successfully deleted')
                    thumstorageRef.delete().then(function() {									
                      // File deleted successfully		
                      console.log('thumb file successfully deleted')
                      db.collection('customerclaims').doc(vm.$route.params.id).collection('multiplefileupload2imagesuploads').doc(vm.editedMultipleFileUpload2Item.id).delete().then(function() {
                        vm.MultipleFileUpload2ImagesUploads.splice(vm.editedMultipleFileUpload2ItemIndex,1)
                      }).catch(function(error) {									
                      // Uh-oh, an error occurred!									
                    });
                    }).catch(function(error) {									
                      // Uh-oh, an error occurred!									
                    });							
              }).catch(function(error) {									
                // Uh-oh, an error occurred!									
              });
    },
    TransferMultipleFileUpload2toRMAdminTable(){
      if(this.userIsAdmin){
    let doc = Object.assign({},this.editedMultipleFileUpload2Item)
    doc.Name = doc.title
    doc.FileType = doc.type
    doc.url = doc.path
    doc.StorageRef = 'CustomerClaims/'+this.$route.params.id+'/MultipleFileUpload2/'+ this.editedMultipleFileUpload2Item.title
    this.RemoveSingleItemfromTable(doc,'MultipleFileUpload2ImagesUploads','customerclaims')	
      }
      else{
        alert('Apologies but only Administrators can do that')
      }
  },

      AddMultipleFileUpload2(){
      this.$emit('ActivateMultipleFilesUploadNotification',true)   
      let fileList = event.target.files || e.dataTransfer.files
      this.MultipleFileUpload2runningpercentage = 0
      this.MultipleFileUpload2runningProgress = 0 
      //this.UploadingMultipleFileUpload2 = true
      setTimeout(() => {
      this.MultipleFileUpload2totallist = fileList.length
      this.MultipleFileUpload2uploadTotal = fileList.length*100
      const topelmnt = document.getElementById('uploadfilesprogressbar')
      topelmnt.style.display = "block";
      const elmnt = document.getElementById('uploadfilesprogressgroupbar') 
      let oldtotalprogresselementptag = document.getElementById("uploadfilestotalprogressbarptag")
      let oldtotalprogresselement = document.getElementById("uploadfilestotalprogressbar")
      if(oldtotalprogresselementptag){
        oldtotalprogresselementptag.remove();
      }   

      if(oldtotalprogresselement){
        oldtotalprogresselement.remove();
      }
  
      if(elmnt.childNodes[0]){
        elmnt.innerHTML = '';
      }     
      const totalprogresselementptag = document.createElement('p')
      totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
      totalprogresselementptag.setAttribute('id','uploadfilestotalprogressbarptag')
      const totalprogresselement = document.createElement('progress')
      totalprogresselement.setAttribute('id','uploadfilestotalprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
      var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.MultipleFileUpload2totallist+' files uploaded');
      totalprogresselementptag.appendChild(startingtotaltext);
      elmnt.append(totalprogresselement) 
      elmnt.append(totalprogresselementptag) 
      // this.totalelement  = document.createElement('p')
      Array.from(Array(fileList.length).keys()).map((x,index) => {
        this.PrepareMultipleFileUpload2Thumbnails(fileList[x],index,fileList.length)
      })
      }, 300);
      },
      PrepareMultipleFileUpload2Thumbnails(file,index,listlength){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.MultipleFileUpload2forUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref('CustomerClaims/'+vm.$route.params.id+'/MultipleFileUpload2/'+ filename+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      file.FileModifiedDate = new Date(file.lastModified)
                      vm.AddMultipleFileUpload2Single(file,index,listlength,thumburl)
                    })
              })
      }
      },
      AddMultipleFileUpload2Single(file,fileindex,listlength,thumburl) {
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        const totalprogresselement = document.getElementById('uploadfilestotalprogressbar')
        const totalprogresselementptag = document.getElementById('uploadfilestotalprogressbarptag')
        const elmnt = document.getElementById('uploadfilesprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)    
        let vm = this
        //const ImageArray = this.MultipleFileUpload2ImagesUploads
        var storageRef = firebase.storage().ref('CustomerClaims/'+this.$route.params.id+'/MultipleFileUpload2/'+ filename);        
        var uploadTask = storageRef.put(file);
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.MultipleFileUpload2uploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.MultipleFileUpload2uploadPercentage)
                var textnode = document.createTextNode(vm.MultipleFileUpload2uploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.MultipleFileUpload2uploadPercentage === 100){
                  vm.MultipleFileUpload2runningProgress = vm.MultipleFileUpload2runningProgress+vm.MultipleFileUpload2uploadPercentage
                  vm.MultipleFileUpload2runningpercentage = Number(((vm.MultipleFileUpload2runningProgress/vm.MultipleFileUpload2uploadTotal)*100).toFixed(2))                  
                  vm.MultipleFileUpload2listuploadcount = vm.MultipleFileUpload2listuploadcount-1+2
                  var totaltext = document.createTextNode(vm.MultipleFileUpload2runningpercentage+'% complete  - '+vm.MultipleFileUpload2listuploadcount+' of '+vm.MultipleFileUpload2totallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.MultipleFileUpload2runningpercentage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                      const newimage = {
                              Name: filename,
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Created_Byid: this.UserRecord.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.FileModifiedDate,
                              Modified_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Modified_Byid: this.UserRecord.id,
                              Modified_On: new Date(),
                              
                              StorageRef: 'CustomerClaims/'+this.$route.params.id+'/MultipleFileUpload2/'+ filename
                              }
                    //ImageArray.push(newimage)
                    db.collection('customerclaims').doc(this.$route.params.id).collection('multiplefileupload2imagesuploads').add(newimage).then(imagedoc => {
                      console.log('created new doc '+imagedoc)
                      })
                      if(this.MultipleFileUpload2runningpercentage === 100){
                      //this.UploadingMultipleFileUpload2 = false
                      this.$emit('ActivateMultipleFilesUploadNotification',false)
                      this.MultipleFileUpload2totallist = 0
                      this.MultipleFileUpload2uploadTotal = 0
                      this.MultipleFileUpload2runningpercentage = 0
                      this.MultipleFileUpload2runningProgress = 0
                      this.MultipleFileUpload2uploadPercentage = 0
                      this.MultipleFileUpload2listuploadcount = 0
                      //elmnt.style.display = "none";
                      }
                  })
      },
    ProcessDeleteItem(item,itemarrayname,collection){
      let vm = this
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(vm.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
    ConfirmMultipleFileUpload1TableTransfer(){
    confirm('This will take all the selected items out of the table. Are you sure you want to continue?') && this.ProcessTransferMultipleFileUpload1RMAdminTable()
  },
  ProcessTransferMultipleFileUpload1RMAdminTable(){
    this.MultipleFileUpload1Selected.map(doc => {
      this.TransferMultipleFileUpload1RMAdminTable(doc)
    })
  },
  TransferMultipleFileUpload1RMAdminTable(){
    let doc = Object.assign({},this.editedMultipleFileUpload1Item)

    doc.url = doc.path
    this.RemoveSingleItemfromTable(doc,'MultipleFileUpload1','customerclaims')	
  },
    ProcessRemovefromMultipleFileUpload1Table(){
    this.MultipleFileUpload1Selected.map(doc => {
      
      this.RemoveSingleItemfromTable(doc,'MultipleFileUpload1','customerclaims')
    })
  },
  RemovefromMultipleFileUpload1Table(){
    confirm('This will not remove the items, but rather move them out of the table to unsorted. Are you sure you want to continue?') && this.ProcessRemovefromMultipleFileUpload1Table()
  }, 
    onMultipleFileUpload1Fileselected(event){
      this.selectedMultipleFileUpload1File = event.target.files[0]
      this.selectedMultipleFileUpload1File.FileModifiedDate = new Date(this.selectedMultipleFileUpload1File.lastModified)
    },

    closeMultipleFileUpload1dialog (){
      this.MultipleFileUpload1dialog = false
      this.selectedMultipleFileUpload1File = ''
      this.MultipleFileUpload1PrimarySelectedOption = ''
      this.editedMultipleFileUpload1Item = this.DefaultMultipleFileUpload1Item
      this.editedMultipleFileUpload1Index = -1
      let inputelement = document.getElementById('MultipleFileUpload1InputElement')
      if(inputelement){
        inputelement.value = ''
      }
    },
    deleteMultipleFileUpload1Item (item) {
      confirm('Are You sure you want to delte this item? There is no going back') && this.ProcessDeleteItem(item,'MultipleFileUpload1','customerclaims')
    },

    editMultipleFileUpload1Item (item) {
        this.editedMultipleFileUpload1Index = this.MultipleFileUpload1.indexOf(item)
        this.editedMultipleFileUpload1Item = Object.assign({}, item)
        this.MultipleFileUpload1recordid = item.id
        let btn = document.getElementById('NewMultipleFileUpload1Btn')
        btn.click()
        this.MultipleFileUpload1dialog = true
      },

    saveMultipleFileUpload1Doc(){
      
      if (this.editedMultipleFileUpload1Index > -1) {
        let filename = this.editedMultipleFileUpload1Item.id+'_'+this.editedMultipleFileUpload1Item.Name
        let vm = this
        const path = this.DefaultMultipleFileUpload1path 
        this.editedMultipleFileUpload1Item.Modified_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}
        this.editedMultipleFileUpload1Item.Modified_Byid = this.UserRecord.id
        this.editedMultipleFileUpload1Item.Modified_On = new Date()
        let existingstorageref = this.editedMultipleFileUpload1Item.StorageRef
        this.MoveFiletoNewLocation(this.editedMultipleFileUpload1Item.fileurl, path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename).then(url => {
        let OldPath = ''
        OldPath = this.editedMultipleFileUpload1Item.StorageRef
        console.log(OldPath)
        if(existingstorageref){
            let storageRef = firebase.storage().ref(existingstorageref);																
            storageRef.delete()	
          }	
        this.editedMultipleFileUpload1Item.StorageRef = path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename
        this.editedMultipleFileUpload1Item.fileurl = url
        console.log(this.editMultipleFileUpload1Item)
        console.log(OldPath)
        this.MultipleFileUpload1[this.editedMultipleFileUpload1Index] = this.editedMultipleFileUpload1Item
        db.collection('customerclaims').doc(this.$route.params.id).collection('multiplefileupload1').doc(this.editedMultipleFileUpload1Item.id).set(this.editedMultipleFileUpload1Item).then(function() {
              console.log("Document successfully written!");
              
              vm.UpdatingMultipleFileUpload1Table = true
                setTimeout(() => {
                vm.UpdatingMultipleFileUpload1Table = false
                vm.closeMultipleFileUpload1dialog()
              }, 50);
          })
          .catch(function(error) {
              console.error("Error writing document: ", error);
          });
       //   if(this.Parent.Name !== ''){
      // db.collection('businessunits').doc(this.$route.params.id).update({
      //   Parent: {Name: this.Parent.Name,id: this.Parent.id},
      //   Parentid: this.Parent.id,
      //   Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      //   Modified_Byid: this.UserRecord.id,
      //   Modified_On: new Date()
      //         });
      // } 
                            
                            })
        
      }
      
    
      else{
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = this.DefaultMultipleFileUpload1path
        let filename = newdocid+'_'+this.selectedMultipleFileUpload1File.name
        console.log(filename)
        const MultipleFileUpload1document = { 
          MultipleFileUpload1PrimarySelectedOption: this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption,
          FileType: this.selectedMultipleFileUpload1File.type,
          Name: this.selectedMultipleFileUpload1File.name,
          Modified_By: {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Modified_Byid: this.UserRecord.id,
          Modified_On: new Date(),
          StorageRef: 'CustomerClaims/'+this.$route.params.id+'/MultipleFileUpload1/'+ filename,
          Created_By: {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Created_Byid: this.UserRecord.id,
          Created_On: new Date(),
          ModifiedDate: this.selectedMultipleFileUpload1File.FileModifiedDate
        }

        const MultipleFileUpload1TEMPARRAY = this.MultipleFileUpload1
        var storageRef = firebase.storage().ref(path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename);
        MultipleFileUpload1document.StorageRef = path+this.editedMultipleFileUpload1Item.MultipleFileUpload1PrimarySelectedOption.Name+'/'+filename
          var uploadTask = storageRef.put(this.selectedMultipleFileUpload1File);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingMultipleFileUpload1FileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingMultipleFileUpload1FileProgress)
          var textnode = document.createTextNode(vm.UploadingMultipleFileUpload1FileProgress+"% complete for "+vm.selectedMultipleFileUpload1File.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingMultipleFileUpload1FileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingMultipleFileUpload1FileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
              });
            });
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                MultipleFileUpload1document.fileurl = url
                //MultipleFileUpload1TEMPARRAY.push(MultipleFileUpload1document)
                	
                db.collection('customerclaims').doc(this.$route.params.id).collection('multiplefileupload1').doc(newdocid).set(MultipleFileUpload1document).then(doc => {
                  this.closeMultipleFileUpload1dialog()
                })
              })
                
        }, 300);
      }
      
    },
      
            GetProcessData(){
        
        if(this.CustomerClaimData.ActiveProcess){
            this.ActiveProcess = this.CustomerClaimData.ActiveProcess
            this.ClaimRequest1000001 = this.CustomerClaimData.ClaimRequest1000001
            this.ActiveProcessDocid = this.CustomerClaimData.ActiveProcessDocid
            let ProcessObj = this.EntityProcesses.find(obj => obj.Number == this.ActiveProcess.Number)
            this.ProcessStages = ProcessObj.ProcessStages
             if (typeof this.CustomerClaimData.stagenameid !== 'undefined'){
                let stageobj = this.ProcessStages.find(obj => obj.Number === this.CustomerClaimData.stagenameid)
                this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
              }
            db.collection('customerclaims').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            this.StageName = stagedata.StageName
            if(this.StageName){
              let stageobj = this.ProcessStages.find(obj => obj.Number === this.CustomerClaimData.stagenameid)
            this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
            this.ProcessStages = this.ProcessStages
            .map(stage => {
              if(stage.Steps && stage.Steps.length>0){
                stage.Steps = stage.Steps.map(step => {
                  if(typeof stagedata[step.Name] !== 'undefined'){
                    step.Prop = stagedata[step.Name]
                  }
                  return step
                })
              }
              return stage
            })
            }
            })
            }
      },
      AssignProcess(){
      this.SelectProcessDialog = true
    },
    AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
      db.collection('customerclaims').doc(this.$route.params.id).update({
        ActiveProcess: this.ActiveProcess
      })
      let processdoc = this.ActiveProcess.Name.split(' ').join('')+this.ActiveProcess.Number
      console.log(processdoc)
      console.log(typeof this[processdoc],this[processdoc])
      console.log(!this[processdoc])
      if(!this[processdoc]){
        // let ProcessDoc = 
        db.collection('customerclaims').doc(this.$route.params.id).collection('processes').add(this.ActiveProcess).then(doc => {
          db.collection('customerclaims').doc(this.$route.params.id).update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
            let Stage = this.ActiveProcess.ProcessStages[0]
            this.updateStatus(Stage)
        })
       
        this.SelectProcessDialog = false
      }
      else{
        db.collection('customerclaims').doc(this.$route.params.id).collection('processes').doc(this[processdoc]).update({
         DisplayName: this.ActiveProcess.DisplayName,
         Name: this.ActiveProcess.Name,
         ID: this.ActiveProcess.Number,
          ProcessStages: this.ActiveProcess.ProcessStages
        })
        db.collection('customerclaims').doc(this.$route.params.id).update({
            ActiveProcessDocid: this[processdoc]
          })
          this.ActiveProcessDocid = this[processdoc]
          db.collection('customerclaims').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            if(stagedata){
            this.StageName = stagedata.StageName
            if(this.StageName){
            this.StageID = stagedata.StageName.Number            
            }
            }
            this.SelectProcessDialog = false
            })
          }
    },
    IsComplete(Stage){
      if(this.StageID){
      return this.ProcessStages[this.StageID-1].Number >= Stage.Number  
      }
      
    },
    UpdateStages(Prop,value){
      db.collection('customerclaims').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).update({
        [Prop]: value
      })
    },
    updateStatus(Stage){
          setTimeout(() => {
          db.collection('customerclaims').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).update({
            StageName: Stage,
            StageID: Stage.Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
          })
          db.collection('customerclaims').doc(this.$route.params.id).update({
            stagename: Stage,
            stagenameid: Stage.Number,
          })
          
        }, 300)
        },



    
      
      
  
        
        
        
        
        
      
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    
    

    
  onFinal_QuoteUpload() {
    //singleuploadfile.FeaturedPhoto 
    let vm = this
    let filename = vm.selectedFinal_QuoteFile.name
        let storepath = 'CustomerClaims/'+vm.$route.params.id+'/Final_Quote/'+filename
        var storageRef = firebase.storage().ref(storepath);
        var uploadTask = storageRef.put(vm.selectedFinal_QuoteFile);
        vm.$emit('ActivateProcessing',true)
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let Final_QuoteObject = {
              fileurl: url,
              FileType: vm.selectedFinal_QuoteFile.type,
              Modified_By: {Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
              Modified_Byid: vm.UserRecord.id,
              Modified_On: new Date(),
              ModifiedDate: vm.selectedFinal_QuoteFile.FileModifiedDate,
              Size: vm.selectedFinal_QuoteFile.size,
              StorageRef: storepath,
              filename: filename
            }
            db.collection('customerclaims').doc(vm.$route.params.id).update({
                    Final_Quote: Final_QuoteObject,
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added Final Quote to - '+vm.Title,
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            vm.selectedFinal_QuoteFile = ''
                })
                  vm.Final_Quotefilesnackbar = true
      },
      closeFinal_Quote(){
      this.Final_QuotelocalURL = ''
      this.selectedFinal_QuoteFile = ''
    },
      onFinal_QuoteFileselected(event) {
        this.selectedFinal_QuoteFile = event.target.files[0]
        this.selectedFinal_QuoteFile.FileModifiedDate = new Date(this.selectedFinal_QuoteFile.lastModified)
         if(this.ReviseCustomerClaimDialog){
          this.RevisedCustomerClaimTitle = this.selectedFinal_QuoteFile.name
        }
        this.Final_QuotelocalURL = URL.createObjectURL(this.selectedFinal_QuoteFile)
      },

      
      
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        