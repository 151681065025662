<template>
<div>
    <v-list            
    :dark="AppisDarkMode"
    dense                
    nav    
    v-for="item in items"
    :key="item.title"
    >
    
    <v-list-item class="overline">
    <v-list-item-icon>
        <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
  
        <v-list v-if="GetMatchingStatus(item).length>0">

        <v-list-item v-for="notification in GetMatchingStatus(item)" :key="notification.itemObjKey">
            <v-list-item-content :class="item.class" >
                <v-list-item-title>{{notification.Type}}</v-list-item-title>                    
               
                <v-list-item-subtitle>
                {{notification.CreatorFullName}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{notification.Date.toDate()}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="caption">
                {{notification.Message}}
            </v-list-item-content>
        </v-list-item>
    </v-list>
    <v-list :dark="AppisDarkMode" v-else>
                <v-list-item >
                    <v-list-item-icon>
                    <v-icon color="red" small>mdi-cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title  style="font-weight:300;"><i>There are no items for this Status at the moment</i></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
    </v-list>
</div>
</template>

<script>
export default {
    props: ['query','AppisDarkMode','userLoggedIn'],
    components: {

    },
    data(){
        return {
            Notifications: [],
            items: [
                {
                    icon: 'mdi-poll-box',
                    title: 'Unread',
                    class: 'red--text'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Read',
                    
                },
            ],
        }
    },
    computed:{

    },
    created(){
        this.GetNotifications(this.query)
    },
    methods: {
        GetMatchingStatus(item){
            return this.Notifications
            .filter(not => {
                    if(item.title === 'Read'){
                        return not.Read === true
                    }
                    else{
                        return not.Read === false
                    }
            })
        },
        GetNotifications(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      this.Notifications.push(
                      {...change.doc.data(),
                      id: change.doc.id})
                      }
                  })
                })
        }
    }
}
</script>

<style>

</style>

    